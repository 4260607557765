import { Loading } from '@livecontrol/core-ui';
import type { ReactElement } from 'react';
import { Layout } from './layout';
import { Provider, useReady } from './store';

const Loader = (props: Layout.Props): ReactElement =>
  useReady() ? <Layout {...props} /> : <Loading.Delay />;

export const SelectVideo = ({
  ...props
}: Layout.Props): ReactElement => (
  <Provider>
    <Loader {...props} />
  </Provider>
);
