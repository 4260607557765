import type { UUID } from '@livecontrol/core-utils';
import { Modal } from '@livecontrol/scheduler/components';
import type { Event } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { Store } from '../../../store';

interface ModalProps {
  show?: boolean;
  callbackPath?: string;
  eventId?: Event.ID;
  uuid: UUID;
  onClose?: () => void;
}

export const YoutubePermissions = ({
  show = true,
  uuid,
  eventId,
  callbackPath,
  onClose
}: ModalProps): ReactElement => {
  const account = Store.Account.useAccount();
  const location = useLocation();

  const { data: youtubeOAuthURL } = Scheduler.Destination.useYouTubeAuthorization({
    account,
    callbackPath: callbackPath ?? `${location.pathname.slice(1)}/reconnect`
  });

  const goToYoutubeIntegration = (): void => {
    if (youtubeOAuthURL) {
      window.location.replace(
        `${youtubeOAuthURL}&state=${JSON.stringify({
          uuid,
          eventId,
          service: 'youtube'
        })}`
      );
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>YouTube Permissions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='text-center mx-auto'>
          Oops! There is something wrong with your YouTube connection.
          <br />
          To correct the issue, please reauthorize your account again.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Button variant='danger' size='lg' type='button' onClick={goToYoutubeIntegration}>
          <i className='fab fa-youtube fa-lg mr-8px' />
          <span className='align-self-center'>Reconnect</span>
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
};
