import type { Any } from '@livecontrol/core-utils';
import { Str } from '@livecontrol/core-utils';
import { Scheduler } from '@livecontrol/scheduler/store';
import Axios from 'axios';
import { useCallback } from 'react';

export namespace Helper {
  export const fileToBlob = async (file: File): Promise<string> =>
    new Promise((resolve) => {
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;

          resolve(Str.normalize(binaryStr)!);
        },
        false
      );

      reader.readAsDataURL(file);
    });

  export const useGetPresignedURL = (): ((
    name: string,
    type: string,
    path?: string | undefined
  ) => Promise<
    | {
        bucket: string;
        key: string;
        presignedURL: string;
      }
    | undefined
  >) => {
    const [mutatePutPresignedUrl] = Scheduler.Account.S3.useUpload();

    return useCallback(
      async (
        name: string,
        type: string,
        path?: string
      ): Promise<{ bucket: string; key: string; presignedURL: string } | undefined> => {
        const { bucket, key, url: presignedURL } = await mutatePutPresignedUrl(name, type, path);

        if (bucket && key && presignedURL) {
          return {
            bucket,
            key,
            presignedURL
          };
        }

        return undefined;
      },
      [mutatePutPresignedUrl]
    );
  };

  export const getS3URLFromBucket = (bucket: string, key: string): string =>
    `https://${bucket}.s3.amazonaws.com/${key}`;

  export const useUploadFile = (): ((config: {
    blob: Blob;
    name: string;
    type: string;
    path?: string | undefined;
  }) => Promise<string | undefined>) => {
    const getPresignedURL = useGetPresignedURL();

    return useCallback(
      async ({
        blob,
        name,
        type,
        path
      }: {
        blob: Blob;
        name: string;
        type: string;
        path?: string;
      }) => {
        const result = await getPresignedURL(name, type, path);

        if (!result) {
          return undefined;
        }

        const { bucket, key, presignedURL } = result;

        const options = {
          headers: {
            'Content-Type': type
          }
        };

        await Axios.put(presignedURL, blob, options);

        return getS3URLFromBucket(bucket, key);
      },
      [getPresignedURL]
    );
  };

  const createImage = async (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image();

      image.addEventListener('load', () => {
        resolve(image);
      });

      image.addEventListener('error', (error) => {
        reject(error);
      });

      image.setAttribute('crossOrigin', 'anonymous');
      image.src = url;
    });

  export const getCroppedImg = async (
    imageSrc: string,
    crop: { x: number; y: number; width: number; height: number }
  ): Promise<Blob> => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    /*
     * setting canvas width & height allows us to
     * resize from the original image resolution
     */
    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx!.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      canvas.width,
      canvas.height
    );

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob: unknown) => {
          resolve(<Any>blob);
        },
        'image/jpeg',
        0.65
      );
    });
  };
}
