import React from 'react';
import type { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import { colors } from './utils';

const Item = ({ dx = 0 }: { dx?: number }): ReactElement => (
  <>
    <rect x={`${dx + 40}`} y='0' rx='0' ry='0' width='20' height='20' />
    <rect x={`${dx + 22}`} y='25' rx='0' ry='0' width='55' height='12' />
    <rect x={`${dx + 22}`} y='40' rx='0' ry='0' width='55' height='12' />
  </>
);

const Bottom = ({ dx = 0, dy = 15 }: { dx?: number; dy?: number }): ReactElement => (
  <>
    <rect x={`${dx + 22}`} y={`${dy + 85}`} rx='0' ry='0' width='45' height='10' />
    <rect x={`${dx + 22}`} y={`${dy + 70}`} rx='0' ry='0' width='205' height='10' />
    <rect x={`${dx + 183}`} y={`${dy + 85}`} rx='0' ry='0' width='45' height='10' />
  </>
);

interface Props {
  width?: number | string;
  height?: number | string;
  style?: React.CSSProperties;
  isTablet?: boolean;
}

export const DeviceLoader = ({ style, width, height, isTablet }: Props): React.ReactElement => (
  <ContentLoader
    speed={2}
    width={width}
    height={height}
    viewBox={isTablet ? '0 0 500 65' : '0 0 250 140'}
    {...colors}
    style={style}
  >
    <Item />
    <Item dx={75} />
    <Item dx={150} />

    {isTablet ? <Bottom dx={265} dy={-55} /> : <Bottom dy={15} />}
  </ContentLoader>
);
