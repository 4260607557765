export const GraphicsSoftwareOptions = [
  'ProPresenter',
  'Media Shout',
  'Proclaim',
  'Worship Extreme',
  'PowerPoint',
  'OpenLP',
  'Paying for GFX but not set up'
];

export const Timezones = [
  {
    id: 'US/Alaska',
    name: 'US/Alaska'
  },
  {
    id: 'US/Central',
    name: 'US/Central'
  },
  {
    id: 'US/Eastern',
    name: 'US/Eastern'
  },
  {
    id: 'US/Mountain',
    name: 'US/Mountain'
  },
  {
    id: 'US/Pacific',
    name: 'US/Pacific'
  },
  {
    id: 'US/Hawaii',
    name: 'US/Hawaii'
  }
];

export const Countries = ['Canada', 'United States'];

export const CanadianStates = [
  {
    id: 'AL',
    name: 'Alberta',
    abbreviation: 'AL'
  },
  {
    id: 'BC',
    name: 'British Columbia',
    abbreviation: 'BC'
  },
  {
    id: 'MB',
    name: 'Manitoba',
    abbreviation: 'MB'
  },
  {
    id: 'NB',
    name: 'New Brunswick',
    abbreviation: 'NB'
  },
  {
    id: 'NL',
    name: 'Newfoundland and Labrador',
    abbreviation: 'NL'
  },
  {
    id: 'NT',
    name: 'Northwest Territories',
    abbreviation: 'NT'
  },
  {
    id: 'NS',
    name: 'Nova Scotia',
    abbreviation: 'NS'
  },
  {
    id: 'NU',
    name: 'Nunavut',
    abbreviation: 'NU'
  },
  {
    id: 'ON',
    name: 'Ontario',
    abbreviation: 'ON'
  },
  {
    id: 'PE',
    name: 'Prince Edward Island',
    abbreviation: 'PE'
  },
  {
    id: 'QC',
    name: 'Quebec',
    abbreviation: 'QC'
  },
  {
    id: 'SK',
    name: 'Saskatchewan',
    abbreviation: 'SK'
  },
  {
    id: 'YT',
    name: 'Yukon',
    abbreviation: 'YT'
  }
];

export const USAStates = [
  {
    id: 'AL',
    name: 'Alabama',
    abbreviation: 'AL'
  },
  {
    id: 'AK',
    name: 'Alaska',
    abbreviation: 'AK'
  },
  {
    id: 'AZ',
    name: 'Arizona',
    abbreviation: 'AZ'
  },
  {
    id: 'AR',
    name: 'Arkansas',
    abbreviation: 'AR'
  },
  {
    id: 'AS',
    name: 'American Samoa',
    abbreviation: 'AS'
  },
  {
    id: 'CA',
    name: 'California',
    abbreviation: 'CA'
  },
  {
    id: 'CO',
    name: 'Colorado',
    abbreviation: 'CO'
  },
  {
    id: 'CT',
    name: 'Connecticut',
    abbreviation: 'CT'
  },
  {
    id: 'DE',
    name: 'Delaware',
    abbreviation: 'DE'
  },
  {
    id: 'DC',
    name: 'District of Columbia',
    abbreviation: 'DC'
  },
  {
    id: 'FL',
    name: 'Florida',
    abbreviation: 'FL'
  },
  {
    id: 'GA',
    name: 'Georgia',
    abbreviation: 'GA'
  },
  {
    id: 'GU',
    name: 'Guam',
    abbreviation: 'GU'
  },
  {
    id: 'HI',
    name: 'Hawaii',
    abbreviation: 'HI'
  },
  {
    id: 'ID',
    name: 'Idaho',
    abbreviation: 'ID'
  },
  {
    id: 'IL',
    name: 'Illinois',
    abbreviation: 'IL'
  },
  {
    id: 'IN',
    name: 'Indiana',
    abbreviation: 'IN'
  },
  {
    id: 'IA',
    name: 'Iowa',
    abbreviation: 'IA'
  },
  {
    id: 'KS',
    name: 'Kansas',
    abbreviation: 'KS'
  },
  {
    id: 'KY',
    name: 'Kentucky',
    abbreviation: 'KY'
  },
  {
    id: 'LA',
    name: 'Louisiana',
    abbreviation: 'LA'
  },
  {
    id: 'ME',
    name: 'Maine',
    abbreviation: 'ME'
  },
  {
    id: 'MD',
    name: 'Maryland',
    abbreviation: 'MD'
  },
  {
    id: 'MA',
    name: 'Massachusetts',
    abbreviation: 'MA'
  },
  {
    id: 'MI',
    name: 'Michigan',
    abbreviation: 'MI'
  },
  {
    id: 'MN',
    name: 'Minnesota',
    abbreviation: 'MN'
  },
  {
    id: 'MS',
    name: 'Mississippi',
    abbreviation: 'MS'
  },
  {
    id: 'MO',
    name: 'Missouri',
    abbreviation: 'MO'
  },
  {
    id: 'MT',
    name: 'Montana',
    abbreviation: 'MT'
  },
  {
    id: 'NE',
    name: 'Nebraska',
    abbreviation: 'NE'
  },
  {
    id: 'KV',
    name: 'Nevada',
    abbreviation: 'KV'
  },
  {
    id: 'NH',
    name: 'New Hampshire',
    abbreviation: 'NH'
  },
  {
    id: 'NJ',
    name: 'New Jersey',
    abbreviation: 'NJ'
  },
  {
    id: 'NM',
    name: 'New Mexico',
    abbreviation: 'NM'
  },
  {
    id: 'NY',
    name: 'New York',
    abbreviation: 'NY'
  },
  {
    id: 'NC',
    name: 'North Carolina',
    abbreviation: 'NC'
  },
  {
    id: 'ND',
    name: 'North Dakota',
    abbreviation: 'ND'
  },
  {
    id: 'MP',
    name: 'Northern Mariana Islands',
    abbreviation: 'MP'
  },
  {
    id: 'OH',
    name: 'Ohio',
    abbreviation: 'OH'
  },
  {
    id: 'OK',
    name: 'Oklahoma',
    abbreviation: 'OK'
  },
  {
    id: 'OR',
    name: 'Oregon',
    abbreviation: 'OR'
  },
  {
    id: 'PA',
    name: 'Pennsylvania',
    abbreviation: 'PA'
  },
  {
    id: 'PR',
    name: 'Puerto Rico',
    abbreviation: 'PR'
  },
  {
    id: 'RI',
    name: 'Rhode Island',
    abbreviation: 'RI'
  },
  {
    id: 'SC',
    name: 'South Carolina',
    abbreviation: 'SC'
  },
  {
    id: 'SD',
    name: 'South Dakota',
    abbreviation: 'SD'
  },
  {
    id: 'TN',
    name: 'Tennessee',
    abbreviation: 'TN'
  },
  {
    id: 'TX',
    name: 'Texas',
    abbreviation: 'TX'
  },
  {
    id: 'TT',
    name: 'Trust Territories',
    abbreviation: 'TT'
  },
  {
    id: 'UT',
    name: 'Utah',
    abbreviation: 'UT'
  },
  {
    id: 'VT',
    name: 'Vermont',
    abbreviation: 'VT'
  },
  {
    id: 'VA',
    name: 'Virginia',
    abbreviation: 'VA'
  },
  {
    id: 'VI',
    name: 'Virgin Islands',
    abbreviation: 'VI'
  },
  {
    id: 'WA',
    name: 'Washington',
    abbreviation: 'WA'
  },
  {
    id: 'WV',
    name: 'West Virginia',
    abbreviation: 'WV'
  },
  {
    id: 'WI',
    name: 'Wisconsin',
    abbreviation: 'WI'
  },
  {
    id: 'WY',
    name: 'Wyoming',
    abbreviation: 'WY'
  }
];

export const UserRoles = [
  {
    id: 1,
    name: 'Admin'
  },
  {
    id: 2,
    name: 'Sales'
  },
  {
    id: 3,
    name: 'It'
  },
  {
    id: 4,
    name: 'Operator'
  },
  {
    id: 5,
    name: 'Supervisor'
  }
];
