import type { Account } from '@livecontrol/scheduler/model';
import type { ReactElement } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: var(--spacer-32px);
  padding-bottom: 40px;
  border-bottom: 1px solid #e4e8f0 !important;

  .plus {
    border-radius: 50%;
    border: 1px solid var(--primary) !important;
    padding: 0px 5px;
    font-size: 17px;
    font-weight: 100;
  }

  img {
    max-width: var(--spacer-24px);
  }
`;

export const PaymentMethod = ({ billing }: { billing?: Account.Billing }): ReactElement => (
  <Container>
    <h2 className='text-dark font-size-20px font-weight-bold'>Payment Method</h2>
    <div className='d-flex flex-row mt-40px'>
      <div className='d-flex flex-column'>
        {billing?.stripe.expMonth ? (
          <div className='text-dark align-middle font-size-15px font-weight-bold d-flex flex-row align-items-center'>
            <img className='mr-12px' src='assets/icons/Payment_card.svg' alt='card credit' />
            {billing.stripe.defaultCardBrand} ******{billing.stripe.defaultLast4CardNumber}
          </div>
        ) : (
          <div className='text-dark align-middle font-size-15px font-weight-bold d-flex flex-row align-items-center'>
            No payment method
          </div>
        )}

        <a
          href={billing?.stripe.url}
          className='text-primary mt-12px text-center font-size-13px font-weight-bold d-flex flex-row align-items-center'
          target='_blank'
          rel='noopener noreferrer'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            style={{ width: '13px', height: '13px' }}
            className='bi bi-plus-circle mr-4px'
            viewBox='0 0 16 16'
          >
            <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
            <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
          </svg>
          Add payment method
        </a>
      </div>

      {billing?.stripe.expMonth && (
        <span className='ml-56px font-size-12px font-weight-bold text-tertiary mt-4px'>
          Expires {billing.stripe.expMonth}/{billing.stripe.expMonth}
        </span>
      )}
    </div>
  </Container>
);
