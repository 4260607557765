import React from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import Nav from 'react-bootstrap/Nav';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const TabsContainer = styled.div`
  margin: 22px 0px 36px 0px;

  .nav-link {
    padding: 15px 8px;
    font-size: 14px;
    font-weight: 600;
    min-width: 100px;
    display: flex;
    justify-content: center;
    position: relative;
    border: none;
    color: var(--gray-light) !important;

    .selected-border {
      display: none;
      height: 4px;
      background-color: var(--primary);
      width: 100%;
      border-radius: 8px;
      position: absolute;
      top: calc(100% - 4px);
    }

    &.active {
      color: #2e384d !important;

      .selected-border {
        display: block;
      }
    }
  }
`;

export enum Tab {
  ActiveList = 'activeList',
  Inactive = 'inactive'
}

type Props = HTMLAttributes<HTMLElement> & {
  tab: Tab;
};

export const Tabber = ({ className, tab }: Props): ReactElement => {
  const history = useHistory();

  const onSelect = React.useCallback(
    (key?: string | null): void => {
      if (key) {
        history.push(`/admin-test/${key}`);
      }
    },
    [history]
  );

  return (
    <TabsContainer>
      <Nav className={className} variant='tabs' activeKey={tab} onSelect={onSelect}>
        <Nav.Link key={Tab.ActiveList} eventKey={Tab.ActiveList}>
          Active
          <div className='selected-border' />
        </Nav.Link>
        <Nav.Link key={Tab.Inactive} eventKey={Tab.Inactive}>
          Inactive
          <div className='selected-border' />
        </Nav.Link>
      </Nav>
    </TabsContainer>
  );
};
