import { Theme, Viewport } from '@livecontrol/core-ui';
import { Str } from '@livecontrol/core-utils';
import type { Sorting as T_Sorting } from '@livecontrol/store-utils';
import type { ReactElement } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import type { DropdownButtonProps } from 'react-bootstrap/DropdownButton';
import DropdownButton from 'react-bootstrap/DropdownButton';
import styled from 'styled-components';

const FilterDropwdown = styled.div`
  position:relative;
  button.btn-outline-tertiary {
    color: #0D1438;
    border-color: #8798AD;
  } 

  button.btn-outline-tertiary:hover {
    border-color: #8798AD;
  }

  .show > button.btn-outline-tertiary:hover {
    color: #fff !important;
    background-color: #8798AD;
  }

  .dropdown-menu.show {
    background-color:white;
  }

  button.dropdown-toggle {
    position:relative;
    padding:8px 11px 8px 37px;
    color: #0D1438;
    font-size:12px;
    font-weight:600;
    display:flex;
    align-items:center;
    justify-content:center;
  }  

  button.dropdown-toggle:after {
    display:none;
  }

  .icon {
    position: absolute;
    width:20px;
    height:20px;
    top:8px;
    left:11px;
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center center;
    margin-right:6px;
  }

  .filter {
    background-image:url(/assets/icons/Filter-black.svg);
  }  
`;


export function Event<T extends string>(props: Event.Props<T>): ReactElement {
  const viewport = Theme.useViewport();

  const {
    value: { selected, available },
    onChange,
    ...rest
  } = props;

  const title: string = viewport === Viewport.XS ? 'Types' : available.find(v => v.key === selected?.key)?.label ?? 'All Event Types';

  return (
    <FilterDropwdown>
      <DropdownButton
        variant={selected ? 'tint' : 'outline-tertiary'}
        title={Str.truncate(title, { length: 30 })}
        {...rest}
      >
        <Dropdown.Item
          key='all'
          onClick={(): void => {
            onChange?.(undefined);
          }}
        >
          All Event Types
        </Dropdown.Item>

        {available.map(({key, label}): ReactElement => {
          const active = key === selected?.key;

          return (
            <Dropdown.Item
              key={key}
              active={active}
              onClick={(): void => {
                onChange?.(!active ? { key } : undefined);
              }}
            >
              {label}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
      <div className='icon filter'/>
    </FilterDropwdown>
  );

}

export namespace Event {
  export interface Value<T extends string> {
    available: { key: T; label?: string }[];
    selected?: Pick<T_Sorting<T>, 'key'>;
  }

  export type Props<T extends string> = Omit<DropdownButtonProps, 'onChange' | 'title'> & {
    value: Value<T>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    events?: any;
    onChange?: (value?: Pick<T_Sorting<T>, 'key'>) => void;
  };
}
