import { Bool } from '@livecontrol/core-utils';
import cx from 'classnames';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import type { Control } from './control';
import { Utils } from './utils';

interface LikeProps {
  className?: string;
  active?: boolean;
  up?: boolean;
  isTouched?: boolean;
  onChange?: (value: boolean) => void;
}

const Thumbs = styled.span`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 3px solid transparent;
  width: 82px;
  height: 82px;

  img {
    cursor: pointer;
    opacity: 0.25;
    padding: 15px;
    border-radius: 50%;

    :hover {
      opacity: 0.8;
    }
  }

  .up {
    background-color: #83d750;
  }

  .down {
    background-color: #ff505f;
    transform: rotate(180deg);
  }

  .active {
    opacity: 1;
  }

  &.active-item {
    width: 82px;
    height: 82px;
    background-color: #fff;
    border: 3px solid var(--primary);
    border-radius: 50%;
  }

  .description {
    font-size: 12px;
    position: absolute;
    bottom: -24px;
  }
`;

const ThumbLike = ({
  className,
  active,
  onChange,
  up,
  isTouched
}: LikeProps): React.ReactElement => {
  const isActive = !isTouched ? false : active === up;

  return (
    <Thumbs
      key={active ? 'active' : 'inactive'}
      className={cx(className, isActive && 'active-item')}
      onClick={(): void => {
        onChange?.(up!);
      }}
    >
      <img
        src='/assets/icons/thumbs-up.svg'
        className={cx(isActive && 'active', up ? 'up' : 'down')}
        alt=''
      />
      <div className='description'> {up ? 'No issues!' : 'I had issues'} </div>
    </Thumbs>
  );
};

export const Like = ({ className, name }: Like.Props): React.ReactElement => {
  const field = Utils.useField(name);

  const onLikeSelect = useCallback(
    (position: boolean) => (): void => {
      field?.helpers.setTouched(true);

      if (position) {
        field?.helpers.setValue(true);
      } else {
        field?.helpers.setValue(false);
      }
    },
    [field?.helpers]
  );

  const value = Bool.normalize(field?.meta.value);
  const isTouched = field?.meta.value !== undefined;

  const likeUI = useMemo(
    () =>
      // eslint-disable-next-line lodash/prefer-lodash-chain
      _.range(2).map((v) => (
        <ThumbLike
          key={v}
          className='ml-8px'
          up={v === 0}
          active={value}
          isTouched={isTouched}
          onChange={onLikeSelect(v === 0)}
        />
      )),
    [onLikeSelect, value, isTouched]
  );

  return <label className={className}>{likeUI}</label>;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Like {
  export type Props = Omit<Control.Props, 'as'> & {
    scale?: number;
    onChange?: (value: boolean) => void;
    className?: string;
    name: string;
  };
}
