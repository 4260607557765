import { Loading } from '@livecontrol/core-ui';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { ReactElement } from 'react';
import { SectionContainer } from '../../components';
import { Store } from '../../store';
import { Billing } from './billing';

export const Subscription = (): ReactElement => {
  const account = Store.Account.useAccount();

  const { loading: loadingSubscriptionInfo } = Scheduler.Billing.useSubscriptionAccountInfo(
    account.id
  );

  const { loading } = Scheduler.Account.useBilling(account);

  return loadingSubscriptionInfo && loading ? (
    <Loading.Delay />
  ) : (
    <SectionContainer className='col-12'>
      <Billing />
    </SectionContainer>
  );
};
