import type { HTMLAttributes, ReactElement } from 'react';
import Nav from 'react-bootstrap/Nav';
import styled from 'styled-components';

const NavContainer = styled(Nav)`
.nav-link {
  padding: 10px 8px;
  color: #9c9c9c;
  font-size: 14px;
  font-weight: 600;
  min-width: 100px;
  display: flex;
  justify-content: center;
  position: relative;
  border: none;

  .selected-border {
    display: none;
    height: 4px;
    background-color: blue;
    width: 100%;
    border-radius: 8px;
    position: absolute;
    top: calc(100% - 4px);
  }

  &.active {
    color: #2e384d;

    .selected-border {
      display: block;
    }
  }
}
`;

export enum Tab {
  Upload = 'upload',
  Past = 'past'
}

type Props = HTMLAttributes<HTMLElement> & {
  tab: string;
  onChangeTab: (key?: string | null) => void;
};

export const Tabber = ({ className, tab, onChangeTab }: Props): ReactElement => (
  <NavContainer className={className} variant='tabs' activeKey={tab} onSelect={onChangeTab}>
    <Nav.Link key={Tab.Past} eventKey={Tab.Past}>
      From Past Streams
      <div className='selected-border' />
    </Nav.Link>
    <Nav.Link key={Tab.Upload} eventKey={Tab.Upload}>
      Upload Video
      <div className='selected-border' />
    </Nav.Link>
  </NavContainer>
);
