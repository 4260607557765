import { CopyCTA, Form } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import { Account } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Store, store } from '../../../store';

const ALL_LOCATIONS = 'ALL_LOCATIONS';

const ModalContent = styled.div`
  width: 100%;
  max-width: 560px;

  textarea {
    background-color: #ffffff !important;
    border: solid 1px #cbcbcb;
    color: #a3a3a3;
    font-weight: 600;
  }
`;

const Icon = styled.img`
  height: 40px;
  width: 40px;
  display: inline-grid;
  place-items: center;
  margin-botton: 16px;
`;

const ModalFooterContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;

  .close-btn {
    min-width: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px 18px;
    font-size: 12px;
    font-weight: 600;
    background-color: #ffffff;
    border: solid 1px #8798ad;
    border-radius: 4px;
    margin: 6px 10px;
  }

  .primary-btn {
    min-width: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px 18px;
    font-size: 12px;
    font-weight: 600;
    margin: 6px 10px;

    svg {
      margin-right: 8px;
    }
  }
`;

export const EmbedPlayerModal = ({
  show,
  onClose,
  endpoint
}: {
  show: boolean;
  onClose: () => void;
  endpoint: string;
}): React.ReactElement => {
  const account = Store.Account.useAccount();
  const { environment } = store.getState();
  const { locations } = Scheduler.Location.useLocations(account.id);

  const [location, setLocation] = useState<string | undefined>(undefined);

  const [embedPage, setEmbedPage] = useState<'player' | 'profile'>('profile');

  const [hasChat, setHasChat] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const embedCode = useMemo(() => {
    const options = [
      hasChat && embedPage === 'player' ? 'chat=true' : false,
      location ? `locations=${location}` : false,
      `page=${embedPage}`
    ]
      .filter(Boolean)
      .join('&');

    const webplayerUrl = Account.WebPlayer.makeURL({
      uri: environment.VENUE_URI,
      slug: endpoint
    });

    return `<script
    type="text/javascript"
    src="${webplayerUrl}/scripts/v1/embed.js">
</script>
<script>
  LiveControl.Webplayer.embed({
    source: "${webplayerUrl}/embed${options.length > 0 ? `?${options}` : ''}"
  });
</script>`;
  }, [hasChat, location, embedPage, environment.VENUE_URI, endpoint]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (isCopied) {
      timeout = setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }

    return (): void => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isCopied]);

  return (
    <Modal show={show} onHide={onClose} className='bigger-modal'>
      <Modal.Header closeButton>
        <Icon src='/assets/icons/Code-blue.svg' alt='payment-card' />
        <Modal.Title className='mb-8px pt-14px font-size-20px font-weight-bold'>
          Embed Your Player
        </Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={(e): void => {
          e.preventDefault();
        }}
        style={{ maxWidth: '100%' }}
      >
        <Modal.Body>
          <ModalContent>
            <Form.RadioAlt
              title='Profile Page'
              titleClassName='font-size-15px font-weight-bold text-dark'
              selected={embedPage === 'profile'}
              description='Display your live and upcoming events including the schedule and archive.'
              descriptionClassName='font-size-13px font-weight-bold text-gray-light'
              className='radio-section mt-20px'
              onClick={(): void => {
                setEmbedPage('profile');
              }}
            />
            <Form.RadioAlt
              title='Web Player'
              titleClassName='font-size-15px font-weight-bold text-dark'
              selected={embedPage === 'player'}
              description='Display the web player for all locations or a particular location.'
              descriptionClassName='font-size-13px font-weight-bold text-gray-light'
              className='mt-20px'
              onClick={(): void => {
                setEmbedPage('player');
              }}
            />

            {embedPage === 'player' && (
              <div className='ml-12px mt-20px'>
                <Form.Descriptor
                  className='mt-20px'
                  descriptionClassName='text-gray-light font-weight-bold'
                  title={
                    <span className='font-size-14px font-weight-bold'>Choose Your Location</span>
                  }
                  description='The embed code will only display videos for this location.'
                >
                  <Form.Control
                    as='select'
                    value={location ?? ''}
                    onChange={(e: { target: { value: string } }): void => {
                      setLocation(e.target.value === ALL_LOCATIONS ? undefined : e.target.value);
                    }}
                  >
                    <option key='all' value={ALL_LOCATIONS}>
                      All Locations
                    </option>
                    {locations?.map((v) => (
                      <option key={v.id} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Descriptor>

                <Form.SwitchField
                  abledDescription={false}
                  title={
                    <span className='font-size-14px font-weight-bold text-dark'>Enable Chat?</span>
                  }
                  checked={hasChat}
                  description='Show the chat widget on your web player.'
                  descriptionClassName='text-gray-light font-weight-bold'
                  className='mt-20px'
                  onChange={(): void => {
                    setHasChat(!hasChat);
                  }}
                  noSaveIndicator
                />
              </div>
            )}

            <Form.Descriptor
              className='mt-20px embed-code-section'
              descriptionClassName='text-gray-light font-size-13px font-weight-bold'
              title='Copy Embed Code'
              titleClassName='text-dark mb-8px font-size-15px font-weight-bold'
              description='Copy and paste the embed code below into your website’s HTML to embed your Web
              Player'
            >
              <Form.Control
                readOnly
                as='textarea'
                style={{ minHeight: 100 }}
                className='text-gray-light'
                value={embedCode}
              />
              <div
                className='text-right mt-8px font-size-12px text-success'
                style={{
                  opacity: isCopied ? 1 : 0,
                  transition: 'opacity 0.3s'
                }}
              >
                <i className='fal fa-check mr-8px' />
                Copied to Clipboard
              </div>
            </Form.Descriptor>
          </ModalContent>
        </Modal.Body>

        <Modal.Footer className='py-0px'>
          <ModalFooterContent>
            <CopyCTA
              noToast
              onCopy={(): void => {
                setIsCopied(true);
              }}
              value={embedCode}
            >
              <Modal.Button className='primary-btn'>
                <i className='fa fa-link mr-8px' />
                Copy Embed Code
              </Modal.Button>
            </CopyCTA>
            <button type='button' onClick={onClose} className='close-btn'>
              Close
            </button>
          </ModalFooterContent>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
