import _ from 'lodash';
import { validateEventConflicts } from './conflict-validator';
import { validateCuesheetURL } from './cuesheets-validator';
import { validateEventDescription } from './description-validator';
import { validateEventBackground } from './event-background-validator';
import { validateEventLeadtimeAcknowledge } from './leadtime-acknwoledge-validator';
import { validateEventLocation } from './location-validator';
import { validateEventNotes } from './notes-validator';
import { validateEventPassword } from './password-validator';
import { validateRedirectUrl } from './redirect-url-validator';
import { validateEventTitle } from './title-validator';
import { validateEventType } from './type-validator';
import type { EventValidator } from './types';
import { validateZoomDetails } from './zoom-validator';

export const validateEventUpdate = ({
  events,
  existingEvent,
  eventUpdate,
  leadTime,
  simulatedLiveAssetIsUpload,
  cueSheetURL,
  timezone,
  isAdmin,
  isProd = true
}: EventValidator.EventUpdateProps): EventValidator.Errors => {
  const errors: EventValidator.Errors = {
    ...validateEventType(eventUpdate),
    ...validateEventTitle(eventUpdate),
    ...validateEventLocation(eventUpdate),
    ...validateEventDescription(eventUpdate),
    ...validateEventBackground(eventUpdate),
    ...validateEventNotes(eventUpdate),
    ...validateZoomDetails(eventUpdate),
    ...validateEventPassword(eventUpdate),
    ...validateCuesheetURL(cueSheetURL),
    ...validateRedirectUrl(eventUpdate),
    ...validateCuesheetURL(cueSheetURL),
    ...validateEventLeadtimeAcknowledge(eventUpdate)
  };

  if (_.isEmpty(errors.date)) {
    _.merge(
      errors,
      validateEventConflicts({
        newEventInfo: eventUpdate,
        events,
        existingEvent,
        simulatedLiveAssetIsUpload,
        leadTime,
        timezone,
        isAdmin,
        isProd
      })
    );
  }

  return errors;
};
