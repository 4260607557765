import { Form } from '@livecontrol/core-ui';
import { Phone } from '@livecontrol/locale-us';
import type { Event } from '@livecontrol/scheduler/model';
import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { ContentSection, CustomInputContainer, FooterSection } from '../layout-sections';

const schema = yup.object({
  name: yup.string().trim().required("Please provide the client's name."),
  email: yup.string().trim().required("Please provide client's email address.").email(),
  phoneNumber: yup
    .string()
    .required('A valid phone number is required.')
    .test({
      message: 'Please provide a valid phone number.',
      test: (value) => !!Phone.normalize(value)
    })
});

export const ClientForm = ({
  initialValues = {},
  onSubmit
}: {
  initialValues?: Event['clientContactDetails'];
  onSubmit: (value: Event['clientContactDetails']) => void;
}): React.ReactElement => (
  <Formik
    initialValues={initialValues}
    validateOnMount
    validateOnChange
    validationSchema={schema}
    onSubmit={onSubmit}
  >
    <Form autoComplete='off'>
      <ContentSection>
        <CustomInputContainer>
          <Form.Descriptor
            title='Client Full Name'
            titleClassName='title mr-lg-3 font-size-15px font-weight-bold text-dark pb-16px'
            description=''
            className='form-section-lateral d-lg-flex align-items-center'
          >
            <Form.Group
              name='name'
              label={false}
              className='flex-grow-1'
              controlProps={{
                autoFocus: true,
                placeholder: 'Full name'
              }}
            />
          </Form.Descriptor>
        </CustomInputContainer>

        <CustomInputContainer>
          <Form.Descriptor
            title='Client Email'
            titleClassName='title mr-lg-3 font-size-15px font-weight-bold text-dark  pb-16px'
            description=''
            className='form-section-lateral d-lg-flex  align-items-center'
          >
            <Form.Group
              name='email'
              label={false}
              className='flex-grow-1'
              controlProps={{
                placeholder: 'Client Email'
              }}
            />
          </Form.Descriptor>
        </CustomInputContainer>

        <CustomInputContainer>
          <Form.Descriptor
            title='Client Phone'
            titleClassName='title mr-lg-3 font-size-15px font-weight-bold text-dark  pb-16px'
            description=''
            className='form-section-lateral d-lg-flex  align-items-center'
          >
            <Form.Group
              name='phoneNumber'
              label={false}
              className='flex-grow-1'
              controlProps={{
                as: Form.Phone
              }}
            />
          </Form.Descriptor>
        </CustomInputContainer>
        {/* <div className='footer d-flex'>
          <div className='space' />
          <div className='flex-grow-1'>
            <Form.Submit type='submit' className='btn-xwide w-100 mt-20px custom-primary-btn'>
              Continue
            </Form.Submit>
          </div>
        </div> */}
      </ContentSection>

      <FooterSection>
        <Form.Submit type='submit' className='btn-xwide w-100 mt-20px custom-primary-btn'>
          Continue
        </Form.Submit>
      </FooterSection>
    </Form>
  </Formik>
);
