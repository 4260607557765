import { Glyph } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import type { Account } from '@livecontrol/scheduler/model';
import type { ReactElement } from 'react';

interface Props {
  onClose: () => void;
  billing: Account.Billing;
}

export const PaymentFail = ({ onClose, billing }: Props): ReactElement => (
  <>
    <Modal.Header closeButton>
      <Glyph>
        <i className='far fa-exclamation-triangle' />
      </Glyph>
      <Modal.Title className='mb-16px'>
        {billing.stripe.defaultLast4CardNumber === 'last4_not_found'
          ? 'Payment Method Not Set'
          : 'Transaction Failed'}
      </Modal.Title>

      <div className='text-secondary text-center font-size-16px line-height-lg'>
        {billing.stripe.defaultLast4CardNumber === 'last4_not_found'
          ? "You don't have a payment method set. Please add a payment method and then you will be able to purchase additional credits."
          : 'Oh no, your credit purchase failed. Please update your billing details and then try again.'}
      </div>
    </Modal.Header>
    <Modal.Footer>
      <Modal.ButtonBox>
        <Modal.Button
          variant='primary'
          className='btn-wide'
          onClick={(): void => {
            onClose();
            window.open(billing.stripe.url, '_blank')?.focus();
          }}
        >
          Update Billing Details
        </Modal.Button>
        <Modal.Button variant='outline-secondary' className='btn-wide' onClick={onClose}>
          Cancel
        </Modal.Button>
      </Modal.ButtonBox>
    </Modal.Footer>
  </>
);
