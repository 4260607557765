import { Refs } from '@livecontrol/react-utils';
import { assert } from '@sindresorhus/is';
import cx from 'classnames';
import _ from 'lodash';
import type { HTMLAttributes, PropsWithChildren, ReactElement } from 'react';
import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';

// eslint-disable-next-line import/no-internal-modules
import 'video.js/dist/video-js.min.css';

export const VideoPlayer = React.forwardRef(
  (
    { className, children, onEvent, options, ...rest }: VideoPlayer.Props,
    ref: React.Ref<videojs.Player | undefined>
  ): ReactElement => {
    const videoRef = useRef<HTMLVideoElement | null>(null);

    useEffect(
      () => {
        assert.domElement(videoRef.current);

        const player = videojs(
          videoRef.current,
          _.defaults({}, options, {
            controls: true,
            preload: 'none'
          }),
          () => {
            if (onEvent) {
              const dispatch = _.partialRight(onEvent, player);

              dispatch('ready');

              ['ended', 'play', 'pause'].forEach((event) => {
                player.on(event, () => {
                  dispatch(event);
                });
              });
            }
          }
        );

        Refs.update(ref, player);

        return (): void => {
          player.dispose();
        };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    return (
      <video className={cx(className, 'video-js')} ref={videoRef} {...rest}>
        {children}
      </video>
    );
  }
);

VideoPlayer.displayName = 'VideoPlayer';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace VideoPlayer {
  export type Props = PropsWithChildren<HTMLAttributes<HTMLVideoElement>> & {
    onEvent?: (event: string, player: videojs.Player) => void;
    options?: videojs.PlayerOptions;
  };
}
