import type { Account } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { useCallback } from 'react';
import { Store } from '../../../../store';

export const useUpdateDonation = (
  isOptimistic = false
): [
  (
    value: Partial<Record<keyof Account.WebPlayer.Donation, string>>,
    isEnablingBtn: boolean
  ) => Promise<Account.WebPlayer>,
  { loading: boolean }
] => {
  const account = Store.Account.useAccount();

  const [editWebPlayer, { loading }] = Scheduler.Account.WebPlayer.useEdit();

  const mutateWebPlayer = useCallback(
    async (
      value: Partial<Record<keyof Account.WebPlayer.Donation, string>>,
      isEnablingBtn: boolean
    ) => {
      if (isEnablingBtn) {
        const customButtonInfo = {
          title: value.label,
          url: value.link
        };

        const result = await editWebPlayer(
          account,
          {
            isCustomButtonEnabled: isEnablingBtn,
            customButton: customButtonInfo,
            custom_donate_button_copy: value.label,
            donate_link: value.link
          },
          isOptimistic
        );

        return result;
      }

      const result = await editWebPlayer(
        account,
        { isCustomButtonEnabled: isEnablingBtn, donation: value },
        isOptimistic
      );

      return result;
    },
    [account, editWebPlayer, isOptimistic]
  );

  return [mutateWebPlayer, { loading }];
};
