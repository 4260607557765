import type { Any } from '@livecontrol/core-utils';
import cx from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Form } from '../../../components';

interface Props {
  initialValue?: string;
  value?: string;
  placeholder?: string;
  onFocus?: () => void;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  onSubmit?: (value: string) => Promise<Any>;
  onChange?: (value: string) => void;
  children?: React.ReactNode;
}

const StyledSubmit = styled(Form.Submit)`
  height: 46px;

  .x-viewport-sm & {
    min-width: 114px;
  }

  & ~ span {
    opacity: 0;
    transition: opacity 0.3s;

    &.saved {
      opacity: 1;
    }
  }
`;

export const TextField = ({
  initialValue = '',
  onFocus,
  loading,
  placeholder,
  className,
  onSubmit,
  children,
  onChange,
  disabled,
  value
}: Props): React.ReactElement => {
  const [textValue, setTextValue] = useState(initialValue);

  const isControlled = useMemo(() => value !== undefined, [value]);

  const [saved, setSaved] = useState(false);

  const getValue = useCallback((): string => {
    if (isControlled) {
      return value!;
    }

    return textValue;
  }, [isControlled, textValue, value]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (saved) {
      setSaved(true);

      timeout = setTimeout(() => {
        setSaved(false);
      }, 3000);
    }

    return (): void => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [saved]);

  const setValue = useCallback(
    (v: string) => {
      if (!isControlled) {
        setTextValue(v);
      }

      onChange?.(v);
    },
    [isControlled, onChange]
  );

  return (
    <Form
      onSubmit={(e): void => {
        e.preventDefault();

        onSubmit?.(getValue()).then((): void => {
          setSaved(true);
        });
      }}
      className={cx(className, 'd-flex align-items-start')}
    >
      <div className='flex-grow-1'>
        <Form.Control
          type='text'
          required
          onFocus={onFocus}
          placeholder={placeholder}
          size='lg'
          onChange={(event: { target: { value: string } }): void => {
            setValue(event.target.value);
          }}
          value={getValue()}
          aria-label='Display Name'
          aria-describedby='web-player-display-name'
        />
        {children}
      </div>
      <div className='position-relative'>
        <StyledSubmit
          disabled={
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            (value === undefined ? getValue() === initialValue : false) || loading || disabled
          }
          loading={loading}
          size='lg'
          className='btn btn-primary ml-8px font-size-12px'
          type='submit'
        >
          Save
        </StyledSubmit>
        <span
          className={cx('text-success font-weight-bold text-center font-size-12px', { saved })}
          style={{ position: 'absolute', left: 0, right: 0, top: 'calc(100% + 4px)' }}
        >
          <i className='mr-8px fa fa-check' />
          Saved
        </span>
      </div>
    </Form>
  );
};
