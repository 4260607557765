import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Bool, Obj, Str } from '@livecontrol/core-utils';
import { Account } from '@livecontrol/scheduler/model';
import { useEffect, useState } from 'react';
import type { QueryResult } from '../graphql';

interface TVariables {
  id: number;
}

interface Flag {
  key: string;
  isEnabled: boolean;
}

interface TData {
  getUserFeatureFlags?: Flag[];
}

const QUERY = gql`
  query GetFeatures($id: Float!) {
    getUserFeatureFlags(userId: $id) {
      key
      isEnabled
    }
  }
`;

export const useFeatures = (args: Account.Like): QueryResult<Account.Features, 'features'> => {
  const [error, setError] = useState<Error | undefined>();
  const [features, setFeatures] = useState<Account.Features | undefined>();

  // Parse the input arguments
  const variables = {
    id: Account.toId(args)
  };

  // Validate the input
  if (!Obj.isHydrated(variables)) {
    return { loading: false, error: Exception.KABOOM };
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  // Prepare the query
  const { loading, data } = useQuery<TData, TVariables>(QUERY, {
    variables,
    onError() {
      setError(Exception.KABOOM);
    }
  });

  // When available, parse server response
  useEffect(() => {
    let features_;
    let error_;

    if (data) {
      const { getUserFeatureFlags } = data;

      features_ = new Set<string>(
        (getUserFeatureFlags ?? [])
          .map((el: Flag): string | undefined => {
            const key = Str.normalize(el.key);
            const bool = Bool.normalize(el.isEnabled);

            return key && bool ? key.toLowerCase() : undefined;
          })
          .filter((x): x is string => !!x)
      );
    }

    setFeatures(features_);
    setError(error_);
  }, [data]);

  return { loading, error, features };
};
