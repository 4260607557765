import { Device } from '@livecontrol/core-ui';
import cx from 'classnames';
import _ from 'lodash';
import type { ReactElement } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import styled from 'styled-components';
import { Context } from '../store';

interface Props {
  icon: JSX.Element | string;
  label: string;
  target: string | (() => void);
  active: boolean;
}

const Container = styled.div`
  background-color: transparent;
  color: var(--tertiary);
  padding: var(--spacer-8px);
  margin-left: 28px;
  cursor: pointer;
  transition: var(--transition-all);
  display: none;
  align-items: center;

  .x-expanded & {
    display: flex;
  }

  .x-${Device.MOBILE} nav:hover &,
  .x-${Device.DESKSTOP} nav:hover & {
    display: flex;
  }

  &.item-active {
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    color: var(--white);
  }

  &:hover {
    color: var(--white) !important;
  }

  div {
    border-radius: var(--spacer-4px);
    width: var(--spacer-2px);
    height: var(--spacer-24px);
    transform: translate(-450%, 0%) !important;
    transition: opacity 0.2s;
  }

  .is-active {
    background-color: var(--primary) !important;
  }

  svg {
    width: 20px !important;
    height: 20px !important;
  }
`;

export const Subitem = ({ active, icon, label, target }: Props): ReactElement => {
  const [isCollapsed, setIsCollapsed] = useState(active);

  const { pathname } = useLocation();
  const history = useHistory();
  const { viewport, sidebar } = useContext(Context);
  const { isExpanded } = sidebar;

  useEffect(() => {
    if (active) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  }, [active, pathname, isExpanded]);

  const onClick = useCallback((): void => {
    // If mobile, collapse the sidebar
    if (Device.isMobile(viewport)) {
      sidebar.collapse();
    }

    if (typeof target === 'function') {
      target();
    } else {
      history.push(target);
    }
  }, [sidebar, viewport, history, target]);

  return (
    <Container className={cx(active && 'item-active')} onClick={onClick}>
      <div className={cx(active && 'is-active')} style={{ opacity: isCollapsed ? '1' : '0' }} />
      {typeof icon === 'string' ? <i className={cx('fal', `fa-${icon}`, 'fa-fw')} /> : icon}
      <label className='ml-8px font-size-13px text-nowrap font-weight-bold'>{label}</label>
    </Container>
  );
};
