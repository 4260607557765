import { Bool, Str, Time } from '@livecontrol/core-utils';
import moment from 'moment-timezone';
import { useMemo } from 'react';
import type { ReactElement } from 'react';
import { Control } from './control';

const iana = moment.tz.zonesForCountry('US');

const internationalZones = moment.tz.names();

export const Timezone = ({
  value,
  deprecated,
  international,
  ...props
}: Timezone.Props): ReactElement => {
  const tz = Str.normalize(value);

  const options = useMemo(() => {
    const all = [
      ...(Bool.normalize(deprecated) ? Time.deprecatedZones.keys() : []),
      ...(international ? internationalZones : iana).values()
    ];

    return (
      <>
        {!tz ? (
          <option key='none' disabled value=''>
            Select a time zone
          </option>
        ) : !all.includes(tz) ? (
          <option key={tz} value={tz}>
            {tz}
          </option>
        ) : null}
        {[...all].map((v) => (
          <option key={v} value={v}>
            {v}
          </option>
        ))}
      </>
    );
  }, [deprecated, international, tz]);

  return (
    <Control as='select' value={value} {...props}>
      {options}
    </Control>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Timezone {
  export type Props = Omit<Control.Props, 'as'> & { deprecated?: boolean; international?: boolean };
}
