import { Obj } from '@livecontrol/core-utils';
import type { ReactElement } from 'react';
import { Sorting as Component } from '../../../components';
import { useStore } from '../store';
import { Sorting as Sorting_ } from '../types';

export const Sorting = (props: Omit<Component.Props<Sorting_.Key>, 'value'>): ReactElement => {
  const sorting = useStore(({ model }) => model.value.sorting);

  const { keys } = Sorting_;

  const value = {
    selected: sorting.value,
    available: Obj.keys(keys).map((key) => ({
      key,
      label: keys[key]
    }))
  };

  return <Component value={value} {...props} onChange={sorting.update} />;
};
