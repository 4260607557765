import { Modal } from '@livecontrol/scheduler/components';
import React from 'react';
import styled from 'styled-components';

const Icon = styled.img`
  height: 40px;
  width: 40px;
  display: inline-grid;
  place-items: center;
  margin-botton: 16px;
`;

const Description = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #9c9c9c;
`;

export const PaidFeatureModal = ({
  show = false,
  isPaid = false,
  onClose
}: {
  show: boolean;
  isPaid?: boolean;
  onClose?: () => void;
}): React.ReactElement => {

  const showPaidFeaturesPage = (): void => {
    window.open('https://mylivecontrol.zendesk.com/hc/en-us/requests/new?ticket_form_id=12641114845207', '_blank');
  };

  return (
    <Modal show={show} onHide={onClose}>
      <div className='d-flex justify-content-center'>
        <Icon src='/assets/icons/Paid-feature.svg' alt='file' />
      </div>
      <Modal.Header className='text-center' closeButton>
        <h1>Paid Feature</h1>
      </Modal.Header>
      <Modal.Body className='text-center'>
        <Description className='mt-24px mb-24px'>
          {isPaid ? (
            <>
              Hooray! Your upgraded plan includes this feature. Please click the below button to
              request more information or reach out to your Customer Success representative.
            </>
          ) : (
            <>
              Your current plan does not include this feature. Please click the below button to
              request more information or reach out to your Customer Success representative.
            </>
          )}
        </Description>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonBox>
          <Modal.Button onClick={showPaidFeaturesPage} variant='primary'>
            Request more info
          </Modal.Button>
          <Modal.Button variant='outline-secondary' onClick={onClose} className='link-custom'>
            Nevermind
          </Modal.Button>
        </Modal.ButtonBox>
      </Modal.Footer>
    </Modal>
  );
};
