import React from 'react';
import Container from 'react-bootstrap/Container';
import { Buttons } from './buttons';
import { Components } from './components';
import { Menus } from './menus';
import { Modals } from './modals';
import { Palette } from './palette';
import { Responsive } from './responsive';
import { Tabs } from './tabs';
import { Typography } from './typography';

import 'react-datepicker/dist/react-datepicker.css';

export const Sandbox = (): React.ReactElement => (
  <Container className='pt-32px'>
    <Responsive />
    <Palette />
    <Typography />
    <Menus />
    <Modals />
    <Buttons />
    <Tabs />
    <Components />
  </Container>
);
