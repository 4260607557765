import type { Asset } from '@livecontrol/scheduler/model';
import { useState } from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';
import { EmbeddedEventModal } from './embedded-event-modal';

type Props = HTMLAttributes<HTMLDivElement> & {
  asset: Asset;
};

const ButtonContainer = styled.div`
  button {
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #cbcbcb;
    color: #0d1438;
    font-size: 12px;
    font-weight: 600;
    padding: 6px 11px !important;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    margin: 0px 0px 8px;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 6px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
    .code {
      background-image: url(/assets/icons/Code.svg);
    }
  }
`;

const StyledToggle = styled(Dropdown.Toggle)`
  &.dropdown-toggle.btn::after {
    display: none;
  }
`;

export const EmbeddedButton = ({ asset, ...props }: Props): ReactElement => {
  const [showModal, setShowModal] = useState(false);

  return (
    <ButtonContainer>
      <StyledToggle
        variant='outline-dark'
        {...props}
        onClick={(): void => {
          setShowModal(true);
        }}
      >
        <div className='icon code' />
        <div className='text'>Embed Code</div>
      </StyledToggle>

      <EmbeddedEventModal
        show={showModal}
        onClose={(): void => {
          setShowModal(false);
        }}
        password={asset.eventPassword}
        event={asset.event}
      />
    </ButtonContainer>
  );
};
