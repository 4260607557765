import { Time } from '@livecontrol/core-utils';
import { Event } from '@livecontrol/scheduler/model';
import type { Zone } from 'luxon';
import { DateTime } from 'luxon';
import type { ReactElement } from 'react';
import { Service } from '../service';

export namespace Formatter {
  export const date = (event: Event, tz: Zone): string =>
    event.start.setZone(tz).toLocaleString(DateTime.DATE_FULL);

  export const time = (event: Event, tz: Zone): string => {
    const parts = [event.start, event.end].map(
      (dt: DateTime): Time.Parts => Time.parts(dt.setZone(tz), DateTime.TIME_SIMPLE)
    );

    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    const times = parts.map((part) => `${part.get('hour')?.value}:${part.get('minute')?.value}`);
    const meridiems = parts.map((part) => part.get('dayPeriod')?.value.toLowerCase());

    // Are the start and end meridiems the same?
    return meridiems[0] === meridiems[1]
      ? `${times[0]} - ${times[1]}${meridiems[1]}`
      : `${times[0]}${meridiems[0]} - ${times[1]}${meridiems[1]}`;
  };

  export const datetime = (event: Event, tz: Zone): string =>
    `${date(event, tz)} ${time(event, tz)}`;

  export const destinations = (event: Event): ReactElement => {
    const { destinations: arr } = event;

    return (
      <span>
        {arr?.length ? (
          [...arr] // Be careful not to mutate destinations
            .sort((a, b) => a.service - b.service)
            .map((destination) => (
              <Service className='mr-4px' key={destination.id} destination={destination} />
            ))
        ) : (
          <Service.RecordOnly />
        )}
      </span>
    );
  };

  export const production = (event: Event): string =>
    Event.Production[event.production].replace('_', ' ');
}
