import { Modal } from '@livecontrol/scheduler/components';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const ModalFormSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > button {
    max-width: 482px;
  }
  .modal-form-title {
    width: 185px;
    padding-right: 5px;
    margin-top: 4px;
  }
`;

const Description = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #9c9c9c;
`;

export const RedirectModal = ({
  show,
  onHide,
  redirectString
}: {
  show: boolean;
  onHide: () => void;
  redirectString: string;
}): React.ReactElement => {

    const history = useHistory();

    const redirect = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        history.push(`${redirectString}`);
        window.scrollTo(0, 0);
    };

  return (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <h1>Are you sure you want to leave?</h1>
        </Modal.Header>
        <Modal.Body>
            <ModalFormSection>
                <Description className='mb-24px'>
                    Leaving this page will reset your current progress.
                </Description>
            </ModalFormSection>
        </Modal.Body>
        <Modal.Footer>
            <Modal.ButtonBox>
              <Modal.Button className='btn-wide' onClick={redirect}>
                Ok
              </Modal.Button>
              <Modal.Button variant='outline-secondary' onClick={onHide}>
                Cancel
              </Modal.Button>
            </Modal.ButtonBox>
          </Modal.Footer>
    </Modal>
  );
};
