import cx from 'classnames';
import React, { forwardRef } from 'react';
import type { ReactElement } from 'react';
import type { FormCheckProps } from 'react-bootstrap/FormCheck';
import FormCheck from 'react-bootstrap/FormCheck';

export const Check = forwardRef(
  (
    { className, children, isInvalid, ...rest }: Check.Props,
    ref: React.Ref<HTMLInputElement>
  ): ReactElement => (
    <FormCheck className={cx(isInvalid && 'is-invalid', className)} ref={ref} {...rest}>
      {children}
    </FormCheck>
  )
);

Check.displayName = 'Check';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Check {
  export type Props = FormCheckProps;
}
