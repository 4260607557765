import { Device, Theme } from '@livecontrol/core-ui';
import type { Account, Location } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import cx from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Store } from '../../../../store';
import { LocationFilter } from '../actions';
import { AddUser } from '../add-user';
import { DeleteUser } from '../delete-user';
import { ResendInvite } from '../resend-invite';
import { UpsertUser } from '../upsert-user';
import { Grid } from './grid';
import { Stack } from './stack';

const FilterContent = styled.div`
  .dropdown-toggle {
    padding: 8px 11px 8px 11px;

    ::after {
      content: none;
    }
  }

  button {
    display: flex;
    align-items: center;
  }
`;

const ContentButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all var(--transition);

  button {
    font-weight: bold;
    font-size: var(--font-size-12px) !important;
    padding: 0 var(--spacer-12px);
    height: 36px;
  }

  .delete {
    color: #ff0000;
    background-color: rgba(255, 0, 0, 0.1);
  }
`;

const Input = styled.input`
  padding: 12px 20px 8px 40px;
  background-image: url(assets/icons/Search.svg);
  background-position: 10px center;
  background-repeat: no-repeat;
  height: 36px;

  border-radius: var(--spacer-4px);
  border: 1px solid #8798ad;

  ::placeholder {
    color: #0d1438 !important;
    opacity: 1;
    font-size: 12px;
  }
`;

interface Props {
  account: Account;
  usersAvailable: Account.SubUser[];
  redirectWithUserID: (userId: number) => void;
}

export interface M_Location {
  available: Location[];
  selected?: Location;
  update: (value?: Location) => void;
}

export const Dashboard = ({
  account: accountProps,
  usersAvailable,
  redirectWithUserID
}: Props): React.ReactElement => {
  const [resendInvite, setResendInvite] = useState<Account.SubUser | undefined>();
  const [editing, setEditing] = useState<Account.SubUser | undefined>();
  const [users, setUsers] = useState<Account.SubUser[] | undefined>();
  const [account, setAccount] = useState<Account>(accountProps);
  const [deleting, setDeleting] = useState<Account.SubUser[] | undefined>();
  const [filteredLocation, setValue] = useState<Location>();
  const { locations } = Scheduler.Location.useLocations(account);

  const { me } = Store.User.useIdentity();

  const availableLocationsCount = locations
    ? locations.filter((location) => !location.isDisabled).length
    : 0;

  const viewport = Theme.useViewport();
  const size = Device.isMobile(viewport) ? undefined : 'lg';

  const onUpdate = (value?: Location): void => {
    setValue(value);
  };

  const isSearchingRef = useRef(false);

  const onSearchUsers = useCallback(
    (value: string): void => {
      const foundUsers = value.trim()
        ? usersAvailable.filter((user) =>
            user.fullName.toLocaleLowerCase().includes(value.trim().toLowerCase())
          )
        : usersAvailable;

      isSearchingRef.current = Boolean(value.trim());

      const newAccount = { ...account, subUsers: foundUsers };

      setAccount(newAccount);
    },
    [account, usersAvailable]
  );

  const availableLocations: M_Location = {
    available: locations ?? [],
    selected: filteredLocation,
    update: onUpdate
  };

  const onSelectUser = (accountSelected: Account.SubUser): void => {
    setUsers((oldUsers): Account.SubUser[] | undefined => {
      if (accountSelected.id === account.id) {
        return oldUsers;
      }

      const haveUser = oldUsers?.find((user) => user.id === accountSelected.id);

      if (haveUser) {
        return oldUsers?.filter((user) => user.id !== accountSelected.id);
      } else if (oldUsers) {
        return [...oldUsers, accountSelected];
      }

      return [accountSelected];
    });
  };

  const onUsersDeleted = (usersDeleted: Account.SubUser[]): void => {
    setUsers((oldUsers): Account.SubUser[] | undefined => {
      let newUsersList: Account.SubUser[] | undefined = oldUsers;
      let newAccountSubUsers: Account.SubUser[] = account.subUsers;

      usersDeleted.forEach((userDeleted) => {
        newUsersList = newUsersList?.filter((user) => user.id !== userDeleted.id);

        newAccountSubUsers = newAccountSubUsers.filter((user) => user.id !== userDeleted.id);
      });

      setAccount({ ...account, subUsers: [...newAccountSubUsers] });

      return newUsersList;
    });

    setEditing(undefined);
  };

  const onSelectAllUsers = (): void => {
    if (users) {
      if (users.length !== usersAvailable.length) {
        const userForSet = account.subUsers.filter(
          (user) => user.id !== account.id && user.id !== me?.id
        );

        setUsers(userForSet);
      } else {
        setUsers([]);
      }
    }
  };

  const deleteManyUsers = (): void => {
    setDeleting(users);
  };

  const deleteUser = (user: Account.SubUser): void => {
    setDeleting([user]);
  };

  useEffect(() => {
    setAccount({ ...accountProps });
  }, [accountProps]);

  const addUserComponent = (
    <AddUser style={{ height: 36 }} account={account} redirectWithUserID={redirectWithUserID} />
  );

  return (
    <>
      <div
        className={cx(
          'd-flex align-items-center mb-28px',
          Device.isMobile(viewport) ? 'justify-content-center' : 'justify-content-end'
        )}
      >
        <div className='d-flex align-items-center font-size-12px'>
          {users && users.length > 0 ? (
            <ContentButtons className={cx(Device.isMobile(viewport) && 'd-flex flex-column')}>
              <div className='mr-20px'>
                <button
                  className='btn text-primary bg-transparent'
                  onClick={onSelectAllUsers}
                  type='button'
                >
                  {users.length === usersAvailable.length ? 'Deselect All' : 'Select All'}
                </button>
              </div>
              <div className='d-flex align-items-center flex-row'>
                <div className='mr-20px text-gray-light'>Selecting ({users.length}) Users</div>
                <button onClick={deleteManyUsers} className='btn delete' type='button'>
                  <img src='assets/icons/Trash-red.svg' className='mr-8px' alt='trash' />
                  Delete Users
                </button>
              </div>
            </ContentButtons>
          ) : (
            <div
              className={cx(
                'd-flex align-items-center gap-8px',
                Device.isMobile(viewport) ? 'flex-column' : 'flex-row'
              )}
            >
              <div>
                <Input
                  placeholder='Search'
                  onChange={(e): void => {
                    onSearchUsers(e.target.value);
                  }}
                />
              </div>
              <div
                className={cx(
                  'd-flex align-items-center flex-row gap-8px',
                  Device.isMobile(viewport) && 'mt-8px'
                )}
              >
                <FilterContent>
                  <LocationFilter
                    style={{ height: 36 }}
                    locations={availableLocations}
                    size={size}
                  />
                </FilterContent>
                <div>{addUserComponent}</div>
              </div>
            </div>
          )}
        </div>
      </div>
      {Device.isMobile(viewport) ? (
        <Stack
          showAccountHolder={!isSearchingRef.current}
          account={account}
          onEdit={(account_: Account.SubUser): void => {
            setEditing(account_);
          }}
          onResendInvite={(account_: Account.SubUser): void => {
            setResendInvite(account_);
          }}
          onDelete={(account_: Account.SubUser): void => {
            setDeleting([account_]);
          }}
          availableLocationsCount={availableLocationsCount}
        />
      ) : (
        <Grid
          onSelectUser={(account_: Account.SubUser): void => {
            onSelectUser(account_);
          }}
          showAccountHolder={!isSearchingRef.current}
          account={account}
          onEdit={(account_: Account.SubUser): void => {
            setEditing(account_);
          }}
          onResendInvite={(account_: Account.SubUser): void => {
            setResendInvite(account_);
          }}
          filteredLocation={filteredLocation}
          users={users}
          availableLocationsCount={availableLocationsCount}
        />
      )}

      {resendInvite && (
        <ResendInvite
          subUser={resendInvite}
          onClose={(): void => {
            setResendInvite(undefined);
          }}
        />
      )}

      {editing && (
        <UpsertUser
          account={account}
          subUser={editing}
          onClose={(): void => {
            setEditing(undefined);
          }}
          deleteUser={deleteUser}
        />
      )}

      {deleting && (
        <DeleteUser
          onUsersDeleted={onUsersDeleted}
          account={account}
          subUsers={deleting}
          onClose={(): void => {
            setDeleting(undefined);
          }}
        />
      )}

      <div className='d-block d-md-none mt-32px'>{addUserComponent}</div>
    </>
  );
};
