import { Device, Theme } from '@livecontrol/core-ui';
import type { Asset } from '@livecontrol/scheduler/model';
import cx from 'classnames';
import React from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import styled from 'styled-components';
import { AccordionSection, Loaders, Progress } from './components';

type DeviceStatProps = HTMLAttributes<HTMLDivElement> & {
  icon: React.ReactNode;
  name: string;
  value: number;
};

const DeviceStats = ({ icon, name, value, className, ...props }: DeviceStatProps): ReactElement => {
  const percent = value > 0.0 && value < 1.0 ? '< 1%' : `${Math.round(value)}%`;

  return (
    <div className={cx('d-flex flex-column align-items-center flex-grow-1', className)} {...props}>
      <div className='text-tertiary'>{icon}</div>
      <div className='mt-8px font-size-12px text-tertiary'>{name}</div>
      <div className='mt-8px font-size-16px'>{percent}</div>
    </div>
  );
};

const StyledDevices = styled.div`
  .x-${Device.TABLET} & {
    flex-direction: row;

    > div {
      width: 50%;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      margin: 0px auto;
      max-width: 300px;
    }

    .divider {
      flex-grow: 0;
      width: 0;
      border-left: 1px solid #e4e8f0;
    }

    .MobileStats {
      flex-direction: column;
      margin-top: 0px;
      max-width: 250px;
    }
  }

  &,
  .x-${Device.DESKSTOP} & {
    display: flex;
    flex-direction: column;

    .MobileStats {
      margin-top: 32px;
    }
  }
`;

interface Props {
  analytics?: Asset.Analytics.WebPlayer;
  loading: boolean;
}

export const DevicesSection = ({ analytics, loading }: Props): ReactElement => {
  const viewport = Theme.useViewport();
  const { category, os } = analytics ?? {};

  return (
    <AccordionSection title='DEVICE INFO'>
      {loading ? (
        <Loaders.Devices isTablet={Device.isTablet(viewport)} />
      ) : category || os ? (
        <StyledDevices>
          <>
            {category && (
              <div className='d-flex'>
                <DeviceStats
                  icon={<i className='fa fa-mobile-android-alt' />}
                  name='Mobile'
                  value={category.mobile}
                />

                <DeviceStats
                  icon={<i className='fa fa-desktop' />}
                  name='Desktop'
                  value={category.desktop}
                />

                <DeviceStats
                  icon={<i className='fa fa-tablet-alt' />}
                  name='Tablet'
                  value={category.tablet}
                />

                <DeviceStats
                  icon={<i className='fa fa-tv' />}
                  name='Other'
                  value={category.other}
                />
              </div>
            )}

            {category && os && <div className='divider' />}

            {os && (
              <div className='MobileStats'>
                <div className='d-flex align-items-center'>
                  <div className='mr-16px'>{os.ios ?? 0}%</div>
                  <Progress className='flex-grow-1' percent={os.ios ?? 0} />
                  <div className='ml-16px'>{os.android ?? 0}%</div>
                </div>
                <div className='d-flex mt-16px'>
                  <div className='d-flex align-items-center'>
                    <i className='fab fa-apple mr-8px' /> IOS
                  </div>
                  <div className='flex-grow-1' />
                  <div className='d-flex align-items-center'>
                    Android <i className='fab fa-android ml-8px' />
                  </div>
                </div>
              </div>
            )}
          </>
        </StyledDevices>
      ) : (
        <StyledDevices>
          <>
            <div className='d-flex'>
              <DeviceStats
                icon={<i className='fa fa-mobile-android-alt' />}
                name='Mobile'
                value={0}
              />

              <DeviceStats icon={<i className='fa fa-desktop' />} name='Desktop' value={0} />

              <DeviceStats icon={<i className='fa fa-tablet-alt' />} name='Tablet' value={0} />

              <DeviceStats icon={<i className='fa fa-tv' />} name='Other' value={0} />
            </div>

            <div className='divider' />

            <div className='MobileStats'>
              <div className='d-flex align-items-center'>
                <div className='mr-16px'>0%</div>
                <Progress className='flex-grow-1' percent={0} />
                <div className='ml-16px'>0%</div>
              </div>
              <div className='d-flex mt-16px'>
                <div className='d-flex align-items-center'>
                  <i className='fab fa-apple mr-8px' /> IOS
                </div>
                <div className='flex-grow-1' />
                <div className='d-flex align-items-center'>
                  Android <i className='fab fa-android ml-8px' />
                </div>
              </div>
            </div>
          </>
        </StyledDevices>
      )}
    </AccordionSection>
  );
};
