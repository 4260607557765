import { Refs } from '@livecontrol/react-utils';
import cx from 'classnames';
import _ from 'lodash';
import type { ReactElement } from 'react';
import React, { forwardRef } from 'react';
import type { IMaskInputProps } from 'react-imask';
import { IMaskInput } from 'react-imask';
import { Utils } from './utils';

export const Phone = forwardRef(
  (
    { className, isInvalid, mask, ...props }: Phone.Props,
    ref: React.Ref<HTMLInputElement>
  ): ReactElement => {
    const field = Utils.useField(props);

    return (
      <IMaskInput
        mask={mask ?? '(000) 000-0000'}
        lazy={false}
        placeholderChar='_'
        className={cx('form-control', isInvalid && 'is-invalid', className)}
        unmask
        onAccept={(v: string): void => {
          if (field) {
            field.helpers.setValue(v);
          }
        }}
        inputRef={(el: HTMLInputElement): void => {
          Refs.update(ref, el);
        }}
        {..._.omit(props, 'onChange')}
      />
    );
  }
);

Phone.displayName = 'Phone';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Phone {
  export type Props = Omit<IMaskInputProps, 'onAccept'> &
    React.InputHTMLAttributes<HTMLInputElement> & {
      isInvalid?: boolean;
    };
}
