import { Modal } from '@livecontrol/scheduler/components';
import type { Zone } from 'luxon';
import { useCallback } from 'react';
import type { Event } from '../../store';
import { Editor } from './editor';

export const useExtend = (timezone: Zone): ((event: Event) => void) => {
  const prompt = Modal.usePrompt();

  return useCallback(
    (event): void => {
      prompt.show(
        <Editor
          event={event}
          timezone={timezone}
          onClose={prompt.hide}
          onSubmit={prompt.hide}
        />
      );
    },
    [prompt, timezone]
  );
};
