/* eslint-disable import/no-unresolved */
import { Device, Theme } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import { Contact } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { FormikProvider } from 'formik';
import { Store } from 'libs/customer-dashboard/frontend/src/store/store';
import { useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Form } from '../../../../components';
import type { Props } from './form';
import { useForm } from './form';

const { Constraints } = Contact;

const ModalStyled = styled(Modal)`
  // This query is for change the width of modal
  @media (min-width: 1024px) {
    .modal-lg,
    .modal-xl {
      max-width: 938px;
    }
  }
`;

const Body = styled(Modal.Body)`
.group {
  padding-top: var(--spacer-12px);
  padding-bottom: var(--spacer-12px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;

  .x-${Device.DESKSTOP} & {
    max-width: 880px;
  }

.label {
  min-width: 226px;
  width: 226px;
  margin-bottom: 10px;
  margin-right: 26px;
  font-weight: bold;
  color: var(--dark);
  display: flex;
  font-size: 15px;
  align-items: center;

  .x-${Device.DESKSTOP} & {
    max-width: 880px;
  }

  .subtitle {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 2px;      
    color: #8798AD;


    .x-${Device.DESKSTOP} & {
      max-width: 200px;
    }
  }
}

.extension {
  margin-bottom: 1rem;
}
`;

const Header = styled(Modal.Header)`
  align-items: flex-start !important;
  padding-left: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e4e8f0;

  h1 {
    font-size: var(--font-size-28px);
    color: var(--dark);
  }
`;

const Footer = styled(Modal.Footer)`
  .delete {
    color: #ff0000;
    font-weight: bold;
    font-size: var(--font-size-12px);
    background-color: rgba(255, 0, 0, 0.1);
  }

  .btn {
    padding: 12px 18px;
  }

  .btn-outline-dark {
    font-weight: bold;
    font-size: var(--font-size-12px);
  }

  .footer-responsive {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    button {
      margin: 10px;
    }
  }
`;

export const UpsertUser = (props: Props): ReactElement => {
  const [noPermissionsLoading, setNoPermissionsLoading] = useState(false);
  const [addPermissionsLoading, setAddPermissionsLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const { subUser, onClose, deleteUser } = props;
  const account = Store.Account.useAccount();
  const { locations } = Scheduler.Location.useLocations(account);

  const { formik, alert } = useForm({
    ...props,
    locations,
    redirect,
    setNoPermissionsLoading,
    setAddPermissionsLoading
  });

  const viewport = Theme.useViewport();

  const handleDeleteButtonClick = (): void => {
    if (subUser && deleteUser) {
      deleteUser(subUser);
    }
  };

  useEffect(() => {
    if (alert.show) {
      setAddPermissionsLoading(false);
      setNoPermissionsLoading(false);
    }
  }, [alert]);

  return (
    <ModalStyled show backdrop='static' size='lg' keyboard={false} onHide={onClose}>
      <Header closeButton>
        <Modal.Title className='font-size-20px'>{subUser ? 'View/Edit' : 'Add'} User</Modal.Title>

        <p className='text-gray-light m-0 pt-4px font-size-bold'>
          Grant user access to your account
        </p>
      </Header>

      <FormikProvider value={formik}>
        <Form>
          <Body>
            <Form.Alert show={alert.show} onClose={alert.hide}>
              {alert.message}
            </Form.Alert>

            <div className='group flex-column flex-lg-row'>
              <div className='label'> First Name </div>

              <Form.Group
                name='firstName'
                label={false}
                className='col pl-0 pr-0'
                controlProps={{
                  placeholder: 'First Name',
                  maxLength: Constraints.NAME_MAX_LENGTH
                }}
              />
            </div>

            <div className='group  flex-column flex-lg-row'>
              <div className='label'> Last Name </div>
              <Form.Group
                name='lastName'
                label={false}
                className='col pl-0 pr-0'
                controlProps={{
                  placeholder: 'Last Name',
                  maxLength: Constraints.NAME_MAX_LENGTH
                }}
              />
            </div>

            <div className='group flex-column flex-lg-row'>
              <div className='label'> Email </div>
              <Form.Group
                name='email'
                label={false}
                className='col pl-0 pr-0'
                controlProps={{
                  placeholder: 'Email Address',
                  maxLength: Constraints.EMAIL_MAX_LENGTH
                }}
              />
            </div>

            <div className='group flex-column flex-lg-row'>
              <div className='label'>
                <div>Mobile Phone</div>
              </div>
              <Form.Group
                name='phoneNumber'
                label={false}
                className='col pl-0 pr-0'
                controlProps={{ as: Form.Phone }}
              />
            </div>

            <div className='group flex-column flex-lg-row'>
              <div className='label'> Landline Phone </div>

              <Form.Group
                name='landline'
                className='phoneGroup col-lg-4 col-xl-5 pl-0 pr-0 pr-lg-16px'
                label={false}
                controlProps={{ as: Form.Phone }}
              />
              <Form.Group
                style={{
                  marginBottom: Device.isMobile(viewport)
                    ? '24px'
                    : Device.isTablet(viewport)
                    ? '12px'
                    : ''
                }}
                name='landlineExtension'
                className='extension col pl-0 pr-0'
                label={false}
                controlProps={{ placeholder: 'Extension' }}
              />
            </div>

            <div className='group flex-column flex-lg-row'>
              <div className='label'> Preferred Contact Method </div>
              <Form.Group
                name='preferredContactMethod'
                label={false}
                className='col pl-0 pr-0'
                controlProps={{
                  as: 'select'
                }}
              >
                <option value={0} hidden>
                  {' '}
                  Selected preferred contact method
                </option>
                <option value='email'>Email</option>
                <option value='call'>Call</option>
                <option value='text'>Text</option>
              </Form.Group>
            </div>

            <div className='group flex-column flex-lg-row'>
              <div className='label'>
                <div>Additional Information</div>
              </div>

              <Form.Group
                name='additionalInformation'
                label={false}
                className='col pl-0 pr-0'
                controlProps={{
                  as: 'textarea',
                  placeholder: 'Notes',
                  style: {
                    minHeight: 95,
                    backgroundColor: 'white'
                  }
                }}
              />
            </div>
          </Body>

          <Footer>
            {Device.isDesktop(viewport) ? (
              <Modal.ButtonBox>
                {subUser ? null : (
                  <Modal.Submit
                    className='font-weight-bold font-size-12px'
                    disabled={noPermissionsLoading}
                    onClick={(): void => {
                      if(formik.isValid){
                        setAddPermissionsLoading(true);
                        setRedirect(true);
                      }
                    }}
                    loading={addPermissionsLoading}
                  >
                    Add User Permissions
                  </Modal.Submit>
                )}

                <Modal.Submit
                  disabled={addPermissionsLoading}
                  variant='outline-primary'
                  className='font-weight-bold font-size-12px'
                  loading={noPermissionsLoading}
                  onClick={(): void => {
                    if(formik.isValid){
                      setNoPermissionsLoading(true);
                    }
                  }}
                >
                  {subUser ? 'Save Changes' : 'Create User Without Permissions'}
                </Modal.Submit>

                <Modal.Button variant='outline-dark' onClick={onClose}>
                  Cancel
                </Modal.Button>

                {subUser ? (
                  <Modal.Button variant='btn delete' onClick={handleDeleteButtonClick}>
                    Delete
                  </Modal.Button>
                ) : null}
              </Modal.ButtonBox>
            ) : (
              <Modal.ButtonBox>
                <div className='d-flex flex-direction-column footer-responsive'>
                  {subUser ? null : (
                    <Modal.Submit
                      className='font-weight-bold font-size-12px'
                      disabled={noPermissionsLoading}
                      onClick={(): void => {
                        if(formik.isValid){
                          setAddPermissionsLoading(true);
                          setRedirect(true);
                        }
                      }}
                      loading={addPermissionsLoading}
                    >
                      Add User Permissions
                    </Modal.Submit>
                  )}

                  <Modal.Submit
                    disabled={addPermissionsLoading}
                    variant='outline-primary'
                    className='font-weight-bold font-size-12px'
                    loading={noPermissionsLoading}
                    onClick={(): void => {
                      if(formik.isValid){
                        setNoPermissionsLoading(true);
                      }
                    }}
                  >
                    {subUser ? 'Save Changes' : 'Create User Without Permissions'}
                  </Modal.Submit>

                  <Modal.Button variant='outline-dark' onClick={onClose}>
                    Cancel
                  </Modal.Button>

                  {subUser ? (
                    <Modal.Button variant='btn delete' onClick={handleDeleteButtonClick}>
                      Delete
                    </Modal.Button>
                  ) : null}
                </div>
              </Modal.ButtonBox>
            )}
          </Footer>
        </Form>
      </FormikProvider>
    </ModalStyled>
  );
};
