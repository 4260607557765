import type { Account } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { useCallback } from 'react';
import { Store } from '../../../../store';

export const useUpdateCustomButton = (
  isOptimistic = true
): [
  (
    value: Partial<Record<keyof Account.WebPlayer.CustomButton, string>>
  ) => Promise<Account.WebPlayer>,
  { loading: boolean }
] => {
  const account = Store.Account.useAccount();

  const [editWebPlayer, { loading }] = Scheduler.Account.WebPlayer.useEdit();

  const mutateWebPlayer = useCallback(
    async (value: Partial<Record<keyof Account.WebPlayer.CustomButton, string>>) =>
      editWebPlayer(account, { customButton: value }, isOptimistic),
    [account, editWebPlayer, isOptimistic]
  );

  return [mutateWebPlayer, { loading }];
};
