import React, { Component } from 'react';
import type { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { Body as Body_ } from './body';
import { Footer as Footer_ } from './footer';
import { Row as Row_ } from './row';

const Container = styled.li`
  margin-bottom: var(--spacer-16px);
  padding: var(--spacer-24px);

  .x-light-theme & {
    border: 1px solid var(--light);
  }

  .x-dark-theme & {
    border: 1px solid var(--white15);
  }
`;

// eslint-disable-next-line react/prefer-stateless-function
export class Card extends Component<Card.Props> {
  public override render(): ReactNode {
    return React.createElement(Container, this.props);
  }
}

export namespace Card {
  export type Props = PropsWithChildren<HTMLAttributes<HTMLLIElement>>;

  export const Body = Body_;
  export const Footer = Footer_;
  export const Row = Row_;
}
