import type { ApolloError, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Nix, Obj, Str } from '@livecontrol/core-utils';
import { Account, Asset } from '@livecontrol/scheduler/model';
import { useCallback, useState } from 'react';
import { Errors } from '../graphql';
import type { MutationResult } from '../graphql';
import * as Dbx from './dbx';

interface TVariables {
  asset: number;
  hidden?: boolean;
  wasEverPublic?: boolean;
  description?: string;
  scheduledEventPassword?: string | null;
  title?: string;
  account: number;
}

interface TData {
  updateAsset: Dbx.Record;
}

interface Args {
  asset: Asset.Like;
  account: Account.Like;
  changes: Partial<Pick<Asset, 'description' | 'eventPassword' | 'title' | 'visibility'>>;
}

const MUTATION = gql`
  mutation UpdateAsset(
    $asset: Int!
    $account: Int!
    $title: String
    $description: String
    $eventPassword: String
    $hidden: Boolean
    $wasEverPublic: Boolean
  ) {
    updateAsset(
      assetId: $asset
      clientId: $account
      title: $title
      description: $description
      scheduledEventPassword: $eventPassword
      hidden: $hidden
      wasEverPublic: $wasEverPublic
    ) {
      ...BaseAssetResponse
    }
  }
  ${Dbx.BaseAssetResponse}
`;

export const useEdit = (): [
  (args: Args) => Promise<Asset | undefined>,
  MutationResult<Asset, 'asset'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [asset, setAsset] = useState<Asset | undefined>();

  return [
    useCallback(
      async (args: Args): Promise<Asset | undefined> => {
        let asset_: Asset | undefined;

        try {
          const { changes } = args;

          // Parse the input arguments
          const required = {
            asset: Asset.toId(args.asset),
            account: Account.toId(args.account)
          };

          if (!Obj.isHydrated(required)) {
            throw Errors.badRequest();
          }

          const variables = {
            ...required,
            title: Str.normalize(changes.title),
            description: Str.normalize(changes.description),
            eventPassword: Str.normalize(changes.eventPassword) ?? '',
            hidden: Nix.isNotNil(changes.visibility)
              ? changes.visibility === Asset.Visibility.Private
              : undefined,
            wasEverPublic: Nix.isNotNil(changes.visibility) && changes.visibility !== Asset.Visibility.Private
              ? true
              : undefined
          };

          // Execute the GraphQL mutation
          const response = await mutation({ variables })
            .then(({ data }: FetchResult<TData>) => data?.updateAsset)
            .catch((_error: ApolloError) => {
              throw Errors.serverError();
            });

          // Parse the server response
          asset_ = Dbx.normalize(response);

          if (!asset_) {
            throw Errors.serverError();
          }
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setAsset(asset_);

        return asset_;
      },
      [mutation, setError, setAsset]
    ),
    {
      asset,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
