import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Obj } from '@livecontrol/core-utils';
import { User } from '@livecontrol/scheduler/model';
import type { QueryResult } from '@livecontrol/scheduler/store';
import { useEffect, useState } from 'react';
import * as Dbx from './dbx';

interface TVariables {
  id: number;
}

interface TData {
  getUser?: Dbx.Record;
}

const QUERY = gql`
  query GetUser($id: Float!) {
    getUser(input: { id: $id }) {
      ...StandardUserResponse
    }
  }
  ${Dbx.StandardUserResponse}
`;

export const useUser = (args: User.Like): QueryResult<User, 'user'> => {
  const [error, setError] = useState<Error | undefined>();
  const [user, setUser] = useState<User | undefined>();

  // Parse the input arguments
  const variables = {
    id: User.toId(args)
  };

  // Validate the input
  if (!Obj.isHydrated(variables)) {
    return { loading: false, error: Exception.KABOOM };
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  // Prepare the query
  const { loading, data } = useQuery<TData, TVariables>(QUERY, {
    variables,
    onError() {
      setError(Exception.KABOOM);
    },
    fetchPolicy: 'network-only'
  });

  // When available, parse server response
  useEffect(() => {
    let user_;
    let error_;

    if (data) {
      user_ = Dbx.normalize(data.getUser);

      if (!user_) {
        error_ = Exception.NOT_FOUND;
      }
    }

    setUser(user_);
    setError(error_);
  }, [data]);

  return { loading, error, user };
};
