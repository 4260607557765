import React from 'react';
import { Form } from '../../../../components';

export const ZoomDetails = ({
  className
}: React.HTMLAttributes<HTMLDivElement>): React.ReactElement => (
  <div className={className}>
    <Form.Group
      name='zoomDetails.meetingId'
      label='Zoom Meeting ID'
      controlProps={{
        placeholder: 'Meeting ID',
        autoSelect: true
      }}
    />
    <Form.Group
      name='zoomDetails.password'
      label='Zoom Meeting Password'
      controlProps={{
        placeholder: 'Password'
      }}
    />
  </div>
);
