import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Obj } from '@livecontrol/core-utils';
import type { Log } from '@livecontrol/scheduler/model';
import { Account } from '@livecontrol/scheduler/model';
import { useEffect, useState } from 'react';
import type { QueryResult } from '../graphql';
import * as Dbx from './dbx';

interface TVariables {
  customer_id: number;
}

interface TData {
  getCustomerLogs?: {
    success: boolean;
    error: string;
    result: Dbx.Record[];
  };
}

export const QUERY = gql`
  query GetLogs($customer_id: Float!) {
    getCustomerLogs(customer_id: $customer_id) {
      ...StandardLogResponse
    }
  }
  ${Dbx.StandardLogResponse}
`;

export const useLogs = (args: Account.Like): QueryResult<Log[], 'logs'> => {
  const [error, setError] = useState<Error | undefined>();
  const [logs, setLogs] = useState<Log[] | undefined>();

  // Parse the input arguments
  const variables = {
    customer_id: Account.toId(args)
  };

  // Validate the input
  if (!Obj.isHydrated(variables)) {
    return { loading: false, error: Exception.KABOOM };
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  // Prepare the query
  const { loading, data } = useQuery<TData, TVariables>(QUERY, {
    variables,
    onError() {
      setError(Exception.KABOOM);
    },
    fetchPolicy: 'network-only'
  });

  // When available, parse server response
  useEffect(() => {
    let logs_;
    let error_;

    if (data) {
      logs_ = (data.getCustomerLogs?.result ?? [])
        .map((record: Dbx.Record) => Dbx.normalize(record))
        .filter((log: Log | undefined): log is Log => !!log);
    }

    setLogs(logs_);
    setError(error_);
  }, [data]);

  return { loading, error, logs };
};
