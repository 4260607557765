/* eslint-disable unicorn/no-null */
import type { ApolloError, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Num, Obj, Str } from '@livecontrol/core-utils';
import { Account, Destination, Event, Location } from '@livecontrol/scheduler/model';
import { useCallback, useState } from 'react';
import type { PartialDeep } from 'type-fest';
import { Errors } from '../graphql';
import type { MutationResult } from '../graphql';
import * as DbxGet from './dbx';
import * as Dbx from './dbx-edit';
import { QUERY } from './use-events';

interface TVariables {
  event: Omit<Dbx.Record, 'id' | 'location'> & { id: string; location?: string };
}

interface TData {
  updateEvent: Dbx.Record;
}

interface Args {
  event: Event.Like;
  eventLocation?: Location;
  account: Account.Like;

  replacement: Partial<
    Omit<Event, 'id' | 'location'> & {
      location: Location.Like;
      simulatedLiveDetails?: {
        assetId?: number;
        fileName?: string;
        assetUrl?: string;
      };
    }
  >;
}

const MUTATION = gql`
  mutation EditEvent($event: EventUpdateInput!) {
    updateEvent(event: $event) {
      ...StandardEventResponse
    }
  }
  ${DbxGet.StandardEventResponse}
`;

export const useEdit = (): [
  (args: Args) => Promise<Event | undefined>,
  MutationResult<Event, 'event'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [event, setEvent] = useState<Event | undefined>();

  return [
    useCallback(
      async (args: Args): Promise<Event | undefined> => {
        let event_: Event | undefined;

        try {
          const { replacement } = args;

          // Parse the input arguments
          const required = {
            id: Event.toId(args.event)?.toString() // @fixme - API type mismatch
          };

          if (!Obj.isHydrated(required)) {
            throw Errors.badRequest();
          }

          let technical_contact_id: number | null | undefined = Num.normalize(
            replacement.technicalContactSettings?.contactId
          );

          if (
            !(
              technical_contact_id &&
              technical_contact_id > 0 &&
              replacement.technicalContactSettings?.enabled
            )
          ) {
            technical_contact_id = null;
          }

          const variables: TVariables = {
            event: {
              ...required,
              name: Str.normalize(replacement.title),
              is_operated: replacement.production === Event.Production.Produced,
              is_simulated_live: replacement.production === Event.Production.Simulated_Live,
              /*
               * is_mobile: replacement.production === Event.Production.Mobile,
               * is_produced: replacement.production === Event.Production.Produced,
               * is_static:replacement.production === Event.Production.Static,
               */
              is_mobile_kit: replacement.production === Event.Production.Mobile_Kit,
              is_mobile: replacement.production === Event.Production.Mobile,
              status: replacement.phase?.toString(),
              start_time: replacement.start?.toISO(),
              end_time: replacement.end?.toISO(),
              location: Location.toId(replacement.location),
              stream_youtube: !!replacement.destinations?.find(
                (destination) => destination.service === Destination.Service.YouTube
              ),
              stream_facebook: !!replacement.destinations?.find(
                (destination) => destination.service === Destination.Service.Facebook
              ),
              hidden: !replacement.destinations?.length,
              record_only: false,
              stream_live: true,
              operator_notes: replacement.operatorNotes,
              description: replacement.description,
              background_url: Str.normalize(replacement.backgroundURL) ?? null,
              stream_zoom: replacement.zoomDetails?.streamZoom,
              zoom_meeting_id: replacement.zoomDetails?.meetingId,
              zoom_password: replacement.zoomDetails?.password,
              simulated_live_asset_id: replacement.simulatedLiveDetails?.assetId,
              simulated_live_filename: replacement.simulatedLiveDetails?.fileName,
              asset_url: replacement.simulatedLiveDetails?.assetUrl,
              event_password:
                !replacement.destinations || replacement.destinations.length === 0
                  ? ''
                  : Str.normalize(replacement.password) ?? null,
              registration: replacement.registration,
              require_registration: replacement.registration
                ? replacement.requireRegistration
                : false,
              live_chat: replacement.liveChat,
              show_viewers:
                replacement.registration || replacement.liveChat ? replacement.showViewers : false,
              redirect_url: replacement.redirectUrl ?? '',
              cue_sheet_url: Str.normalize(replacement.cueSheetURL) ?? null,
              receive_iso_recordings: replacement.receiveIsoRecordings
                ? replacement.receiveIsoRecordings
                : false,
              use_intro_and_outro_slides: replacement.useIntroAndOutroSlides
                ? replacement.useIntroAndOutroSlides
                : false,
              client_booking_customer_name: Str.normalize(replacement.clientContactDetails?.name),
              client_booking_customer_email: Str.normalize(replacement.clientContactDetails?.email),
              client_booking_customer_phone: Str.normalize(
                replacement.clientContactDetails?.phoneNumber
              ),
              type: <string | undefined>undefined,
              technical_contact_id,
              integrations:
                replacement.destinations
                  ?.map(
                    (
                      destination: PartialDeep<Destination> | undefined
                    ):
                      | {
                          integration: string;
                          type: string;
                        }
                      | undefined =>
                      destination?.id && destination.service
                        ? {
                            integration: destination.id,
                            type:
                              destination.service === Destination.Service.LiveControl
                                ? 'livecontrol'
                                : destination.service === Destination.Service.Facebook
                                ? 'facebook'
                                : destination.service === Destination.Service.YouTube
                                ? 'youtube'
                                : 'rtmp'
                          }
                        : undefined
                  )
                  .filter(
                    (destination) =>
                      !!destination?.integration && destination.type !== 'livecontrol'
                  ) ?? []
            }
          };

          if (!variables.event.hidden) {
            variables.event.was_ever_public = true;
          }

          variables.event.type =
            replacement.production === Event.Production.Mobile
              ? 'mobile'
              : replacement.production === Event.Production.Test
              ? 'test'
              : 'regular';

          if (args.eventLocation?.simulatedLive) {
            variables.event.is_simulated_live = true;
          } else if (!args.eventLocation?.simulatedLive) {
            variables.event.is_simulated_live = false;
            delete variables.event.simulated_live_filename;
            delete variables.event.simulated_live_asset_id;
            delete variables.event.asset_url;
          }

          if (variables.event.name && replacement.production === Event.Production.Test) {
            variables.event.name = `Test - ${variables.event.name}`;
          }

          // Execute the GraphQL mutation
          const accountId = Account.toId(args.account)!;

          const response = await mutation({
            variables,
            refetchQueries: [
              {
                query: QUERY,
                variables: {
                  clientAsFloat: accountId,
                  clientAsInt: accountId,
                  clientAsStr: accountId.toString()
                }
              }
            ]
          })
            .then(({ data }: FetchResult<TData>) => data?.updateEvent)
            .catch((_error: ApolloError) => {
              throw Errors.serverError();
            });

          // Parse the server response
          event_ = Dbx.normalize(response);

          if (!event_) {
            throw Errors.serverError();
          }
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setEvent(event_);

        return event_;
      },
      [mutation, setError, setEvent]
    ),
    {
      event,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
