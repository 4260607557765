import { Glyph, Loading } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import type { ReactElement } from 'react';

export const PaymentProcessing = (): ReactElement => (
  <>
    <Modal.Header closeButton={false}>
      <Glyph>
        <i className='far fa-coins' />
      </Glyph>
      <Modal.Title className='mb-16px'>Processing Your Transaction</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Loading size='50' />
    </Modal.Body>
    <Modal.Footer>
      <div className='text-secondary text-center font-size-16px line-height-lg'>
        Please wait while we process your transaction. This might take several minutes, so please do
        not hit the back button or refresh the page.
      </div>
    </Modal.Footer>
  </>
);
