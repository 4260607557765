/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable unicorn/prefer-module */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import cx from 'classnames';
import Lottie from 'lottie-react';
import React from 'react';
import { Delay } from './delay';
import { Gears } from './gears';

export const Loading = ({
  className,
  isOldVersion,
  ...props
}: Loading.Props): React.ReactElement => {
  if (isOldVersion) {
    return (
      <div className={cx('d-grid', 'place-items-center', 'w-100', 'h-100', className)}>
        <Gears {...props} /> <span className='sr-only'>Loading...</span>
      </div>
    );
  }

  return <Lottie animationData={require('./loading.json')} loop style={{ height: 200 }} />;
};

Loading.Delay = Object.assign(
  ({ delay, ...props }: Loading.Props & Pick<Delay.Props, 'delay'>): React.ReactElement => (
    <Delay delay={delay}>
      <Loading {...props} />
    </Delay>
  ),
  { displayName: 'Loading.Delay' }
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Loading {
  export type Props = Gears.Props & { className?: string; isOldVersion?: boolean };
}
