import type { Viewport } from '@livecontrol/core-ui';
import type { ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Matrix } from './matrix';

import G = Matrix.Grid;
import H = G.Header;
import C = G.Row.Cell;

interface Header {
  title: number | string ;
  id: number | string ;
  action?: JSX.Element;
}

interface Body {
  text: number | string ;
  id: number | string ;
  action?: JSX.Element;
}

export const TableGrid = ({
  headers,
  bodys,
  gridTemplateColumns,
  viewport
}: TableGrid.Props): ReactElement => (
  <G viewport={viewport} gridTemplateColumns={gridTemplateColumns}>
    <G.Row>
      {headers.map((header) => (
        <H viewport={viewport} key={header.id}>
          {header.action ?? header.title}
        </H>
      ))}
    </G.Row>
    {bodys.map((body) => (
      <G.Row key={uuidv4()}>
        {body.map((b) => (
          <C className='cell' key={b.id}>
            {b.action ?? b.text}
          </C>
        ))}
      </G.Row>
    ))}
  </G>
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace TableGrid {
  export interface Props {
    headers: Header[];
    bodys: Body[][];
    gridTemplateColumns: string;
    viewport: Viewport;
  }
}
