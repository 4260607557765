import { Form } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import type { Asset } from '@livecontrol/scheduler/model';
import cx from 'classnames';
import { Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { useForm } from './form';

const Icon = styled.img`
  height: 40px;
  width: 40px;
  display: inline-grid;
  place-items: center;
  margin-botton: 16px;
`;

const ModalFormSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > button {
    max-width: 482px;
  }

  .modal-form-title {
    width: 185px;
    padding-right: 5px;
    margin-top: 4px;
  }

  .isNotShow {
    display: block;
    opacity: 0;
    min-height: 1em;
    transition: opacity 250ms linear;
  }
`;

export const PasswordModal = ({
  asset,
  show,
  onHide
}: {
  asset: Asset;
  show: boolean;
  onHide: () => void;
}): React.ReactElement => {
  const { formik, alert, loading } = useForm({ asset, onClose: onHide });

  return (
    <Modal show={show} onHide={onHide}>
      <Formik {...formik} key={show ? 'one' : 'two'}>
        {({ values }): React.ReactElement => (
          <Form>
            <div className='d-flex justify-content-center'>
              <Icon src='/assets/icons/Lock-blue.svg' alt='file' />
            </div>
            <Modal.Header closeButton>
              <h1>Password Protection</h1>
            </Modal.Header>
            <Modal.Body>
              <Form.Alert show={alert.show} onClose={alert.hide}>
                {alert.message}
              </Form.Alert>

              <ModalFormSection>
                {/* <Form.SwitchToggle title='Password Protect' name='enablePassword' /> */}
                <Form.SwitchCustomField
                  abledDescription={false}
                  title='Password Protect'
                  description=''
                  name='enablePassword'
                  className='align-items-center justify-content-start mt-24px'
                  titleClassName='modal-form-title text-dark mb-4px mr-lg-3 font-size-15px font-weight-bold'
                  descriptionClassName='font-size-13px text-secondary font-weight-bold text-gray-light mb-8px'
                  spaceInBetween={false}
                />
                  <div className={cx(!values.enablePassword && 'isNotShow', 'mt-20px w-100')} >
                    <Form.Descriptor
                      title='New Password'
                      titleClassName='modal-form-title mt-16px mr-lg-3 font-size-15px font-weight-bold text-dark'
                      description=''
                      descriptionClassName='font-size-13px text-secondary font-weight-bold text-gray-light'
                      className='form-section-lateral d-lg-flex'
                    >
                      <Form.Group label={false} name='password' className='w-100' />
                    </Form.Descriptor>
                  </div>
              </ModalFormSection>
            </Modal.Body>
            <Modal.Footer>
              <Modal.ButtonBox>
                <Form.Submit loading={loading}>Save</Form.Submit>
              </Modal.ButtonBox>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
