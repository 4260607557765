import parseUrl from 'parse-url';

// @fixme: Create a robust core-utils URL module
export namespace UrlHelper {
  export const cleanUrl = (value: string): string => {
    try {
      if (value) {
        const { hash, protocols, resource, pathname, port, search } = parseUrl(value);

        return `${protocols.length > 0 ? protocols[0] : 'http'}://${resource}${
          port ? `:${port}` : ''
        }${pathname}${search ? `?${search}` : ''}${hash ? `#${hash}` : ''}`;
      }

      return value;
    } catch {
      return value;
    }
  };
}
