import type { Account } from '@livecontrol/scheduler/model';
import { Event } from '@livecontrol/scheduler/model';
import type { Interval } from 'luxon';
import { EventValidator } from './types';

export const validateCreditAvailability = (
  availableCredits: number,
  newEventInfo: EventValidator.EventInfo,
  recurrences: Interval[],
  billing?: Account.Billing
): EventValidator.Errors => {
  const errors: EventValidator.Errors = {};
  const type = EventValidator.ProductionType.normalize(newEventInfo.type);

  const { start, end } = newEventInfo;

  let creditCost = 0;

  const eventDuration = start && end && end.diff(start);

  if (eventDuration && type === EventValidator.ProductionType.PRODUCED) {
    creditCost = billing !== undefined ? Event.Production.computeCreditsChargedForProducedEvent({
        duration: eventDuration,
        overTimeLimitCharge: billing.credits.over_time_limit_cost,
        overTimeLimitInMinutes: billing.credits.first_credit_time_limit
      }) : 1;
  }

  if (type === EventValidator.ProductionType.PRODUCED && availableCredits <= (recurrences.length * creditCost)) {
    errors.repeatSettings = { repeat: EventValidator.ErrorMessages.REPEAT_INSUFFICIENT_CREDITS };
  }

  return errors;
};
