import { Enum } from '@livecontrol/core-utils';
import type { Any } from '@livecontrol/core-utils';
import cx from 'classnames';
import React, { lazy, Suspense, useMemo } from 'react';
import type { PropsWithChildren, ReactElement } from 'react';
import { Device } from '../device';
import { Viewport } from '../viewport';
import { Spinner } from './spinner';
import type { Variant } from './types';
import { useViewport } from './use-viewport';

const ViewportWrapper = ({ children }: PropsWithChildren<unknown>): ReactElement => {
  const viewport = useViewport();

  const viewportClassName = useMemo(() => {
    const viewports = Enum.keys(Viewport).map((v) => v.toLowerCase());

    const baseViewPortIndex = viewports.indexOf(viewport);

    if (baseViewPortIndex === -1) {
      return '';
    }

    return viewports.reduce(
      (result, v, i) => (i <= baseViewPortIndex ? `${result} x-viewport-${v}` : result),
      ''
    );
  }, [viewport]);

  return (
    <div className={cx(`x-device-${Device.from(viewport)}`, viewportClassName)}>{children}</div>
  );
};

export const Provider = ({
  children,
  themeName,
  loaders,
  fallback,
  ...rest
}: Provider.Props): ReactElement => {
  // Lazy-load the theme
  const Lazy = useMemo(() => lazy(loaders[themeName ?? 'base'].component), [loaders, themeName]);

  return (
    <Suspense
      fallback={
        fallback ?? (
          <Spinner
            style={
              {
                'display': 'flex',
                'alignItems': 'center',
                'justifyContent': 'center',
                'minWidth': '100vw',
                'minHeight': '100vh',

                '--fa-primary-color': '#999',
                '--fa-secondary-color': '#888'
              } as Record<string, string>
            }
          />
        )
      }
      {...rest}
    >
      <Lazy>
        <ViewportWrapper>{children}</ViewportWrapper>
      </Lazy>
    </Suspense>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Provider {
  export interface Loader {
    component: () => Promise<Any>;
    variant: Variant;
  }

  export type Props = PropsWithChildren<{
    themeName?: string;
    loaders: Record<string, Loader>;
    fallback?: () => React.ReactElement;
  }>;
}
