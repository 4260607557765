import type { Location, M_Filters, Phase, Store, T_Period } from './types';

export namespace Filters {
  export const make = ({
    locations,
    phase,
    store
  }: {
    locations: readonly Location[];
    phase: Phase;
    store: Store;
  }): M_Filters => {
    const { produce } = store.getState();

    return {
      keyword: {
        value: undefined,
        update(value: string | undefined): void {
          produce(({ model }) => {
            model.value[phase].filters.keyword.value = value;
          });
        }
      },

      location: {
        available: locations,
        selected: undefined,
        update(value: Location | Location[] | undefined): void {
          produce(({ model }) => {
            model.value[phase].filters.location.selected = value;
          });
        }
      },

      period: {
        value: undefined,

        update(value?: T_Period): void {
          produce(({ model }) => {
            model.value[phase].filters.period.value = value;
          });
        }
      },

      reset(): void {
        const { filters } = store.getState().model.value[phase];

        filters.keyword.update(undefined);
        filters.location.update(undefined);
        filters.period.update(undefined);
      }
    };
  };
}
