import type { Calendar } from '@livecontrol/core-utils';
import { Enum } from '@livecontrol/core-utils';
import type { Event, Location } from '@livecontrol/scheduler/model';
import type { DateTime, Zone } from 'luxon';

export namespace EventValidator {
  export interface NewEventProps {
    availableCredits: number;
    timezone: Zone;
    events: ScheduledEvent[];
    newEventInfo: EventInfo;
    simulatedLiveAssetIsUpload: boolean;
    cueSheetURL?: string;
    isAdmin?: boolean;
    isProd?: boolean;
  }

  export interface EventUpdateProps {
    events: ScheduledEvent[];
    existingEvent: {
      id: number;
      start: DateTime;
      end: DateTime;
    };
    eventUpdate: EventInfo;
    simulatedLiveAssetIsUpload: boolean;
    cueSheetURL?: string;
    leadTime: number;
    timezone: Zone;
    isAdmin?: boolean;
    isProd?: boolean;
  }

  export interface ScheduledEvent {
    id: number;
    start: DateTime;
    end: DateTime;
    location: Location;
  }

  export namespace SchedulerDurations {
    export const EXISTING_EVENT_LEAD_TIME = { minutes: 10 };
    export const NEW_EVENT_LEAD_TIME = { minutes: 15 };
    export const SIMULATED_LIVE_FROM_UPLOAD_LEAD_TIME = { hours: 2 };
    export const TEST_EVENT_DURATION = { minutes: 30 };
    export const DEFAULT_SIMULATED_LIVE_DURATION = { hours: 3 };
    export const DEFAULT_EVENT_DURATION = { hours: 2 };
  }

  export enum ProductionType {
    PRODUCED = 'Produced Event',
    STATIC = 'Static Event',
    MOBILE = 'Mobile Event',
    SIMULATED = 'Simulated Live Event',
    CLIENT_BOOKING = 'Client Booking',
    TEST = 'Test Event',
    MOBILE_KIT = 'Mobile Kit',
    PRODUCED_AND_MOBILE_KIT = 'Produced and Mobile Kit'
  }

  export namespace ProductionType {
    export const normalize = (value: unknown): ProductionType | undefined =>
      Enum.normalize(ProductionType, value);
  }

  export enum ClientBookingPaymentMethod {
    PAY_NOW = 'Pay Now',
    PAY_LATER = 'Pay Later',
    CLIENT_PAYS = 'Client Pays'
  }

  export namespace ClientBookingPaymentMethod {
    export const normalize = (value: unknown): ClientBookingPaymentMethod | undefined =>
      Enum.normalize(ClientBookingPaymentMethod, value);
  }

  export interface EventInfo {
    type?: ProductionType;
    location?: string;
    title?: string;
    description?: string;
    backgroundURL?: string;
    operatorNotes?: string;
    start?: DateTime;
    end?: DateTime;
    cueSheetURL?: string;
    clientContactDetails?: Event.ClientContactDetails;
    chatSettings?: EventInfo.ChatSettings;
    passwordSettings?: EventInfo.PasswordSettings;
    repeatSettings?: EventInfo.RepeatSettings;
    zoomDetails?: Event.ZoomDetails;
    leadtimeAcknowledge?: boolean;
    receiveIsoRecordings?: boolean;
    useIntroAndOutroSlides?: boolean;
    redirectUrlSettings?: EventInfo.RedirectUrlSettings;
    mobileKitData?: EventInfo.MobileKitData;
    technicalContactSettings?: EventInfo.TechnicalContactSettings;
  }

  export interface EventTechnicalContact {
    id: number;
    locationId: string;
    name?: string;
    email?: string;
    onsiteTechnicalPriority: number;
  }

  export namespace EventInfo {
    export interface RepeatFrequency {
      repeatEvery?: number;
      unit?: Calendar.Unit;
      daysOfWeek?: Set<Calendar.Weekday>;
    }

    export enum RepeatEndCondition {
      ON_DATE = 'ON',
      AFTER_OCCURRENCES = 'AFTER'
    }

    export interface PasswordSettings {
      enabled?: boolean;
      password?: string;
    }

    export interface RedirectUrlSettings {
      enabled?: boolean;
      url?: string;
    }

    export interface TechnicalContactSettings {
      enabled?: boolean;
      contactId?: number;
    }

    export interface RepeatSettings {
      repeat?: boolean;
      repeatUntil?: { stopRepeatingOn?: DateTime; numRecurrences?: number };
      repeatFrequency?: RepeatFrequency;
      repeatEndCondition?: RepeatEndCondition;
    }

    export interface ChatSettings {
      requireRegistration?: boolean;
      showViewers?: boolean;
      registration?: boolean;
      liveChat?: boolean;
    }

    export interface MobileKitData {
      title?: string;
      description?: string;
      location?: string;
      start?: DateTime;
      end?: DateTime;
    }
  }

  export interface Errors {
    type?: ErrorMessages.TYPE_INVALID;
    location?: ErrorMessages.LOCATION_MISSING;
    title?:
      | ErrorMessages.TITLE_MISSING
      | ErrorMessages.TITLE_TOO_LONG
      | ErrorMessages.TITLE_TOO_SHORT;
    description?: ErrorMessages.DESCRIPTION_TOO_LONG;
    backgroundURL?: ErrorMessages.BACKGROUND_INVALID | ErrorMessages.BACKGROUND_UPLOADING;
    operatorNotes?: ErrorMessages.OPERATOR_NOTES_TOO_LONG;
    date?: ErrorMessages.SCHEDULED_EVENT_CONFLICT | ErrorMessages.START_DATE_ALREADY_PASSED;
    startTime?:
      | ErrorMessages.INVALID_TIME_FORMAT
      | ErrorMessages.START_DATE_REQUIRED
      | ErrorMessages.START_TIME_CONFLICT
      | ErrorMessages.START_TIME_LEAD_TIME_CLIENT_BOOKING_LIMIT
      | ErrorMessages.START_TIME_LEAD_TIME_LIMIT
      | ErrorMessages.START_TIME_MOBILE_CONFLICT
      | ErrorMessages.START_TIME_TOO_SOON_2H
      | ErrorMessages.START_TIME_TOO_SOON_10M
      | ErrorMessages.START_TIME_TOO_SOON_15M;
    endTime?:
      | ErrorMessages.END_TIME_BEFORE_START
      | ErrorMessages.END_TIME_CONFLICT
      | ErrorMessages.END_TIME_LEAD_TIME_LIMIT
      | ErrorMessages.END_TIME_REQUIRED
      | ErrorMessages.EVENT_TOO_LONG
      | ErrorMessages.INVALID_TIME_FORMAT;
    passwordSettings?: {
      password?: ErrorMessages.EVENT_PASSWORD_REQUIRED;
    };
    repeatSettings?: {
      repeat?: ErrorMessages.REPEAT_END_REQUIRED | ErrorMessages.REPEAT_INSUFFICIENT_CREDITS;
      repeatFrequency?: {
        repeatEvery?: ErrorMessages.REPEAT_FREQUENCY_INVALID;
        daysOfWeek?: ErrorMessages.REPEAT_WEEKDAY_REQUIRED;
        unit?: ErrorMessages.REPEATING_EVENT_CONFLICT;
      };
      repeatUntil?: {
        stopRepeatingOn?:
          | ErrorMessages.REPEAT_STOP_DATE_INVALID
          | ErrorMessages.TOO_MANY_RECURRENCES;
        numRecurrences?: ErrorMessages.NUM_RECURRENCES_INVALID | ErrorMessages.TOO_MANY_RECURRENCES;
      };
    };
    zoomDetails?: {
      meetingId?: ErrorMessages.ZOOM_MEETING_ID_INVALID;
      password?: ErrorMessages.ZOOM_PASSWORD_INVALID;
    };
    cueSheetURL?: ErrorMessages.INVALID_CUE_SHEET_URL;
    leadtimeAcknowledge?: ErrorMessages.LEADTIME_ACKNOWLEDGE;
    redirectUrlSettings?: {
      url?: ErrorMessages.EVENT_REDIRECT_REQUIRED | ErrorMessages.INVALID_REDIRECT_URL;
    };
  }

  export enum ErrorMessages {
    TYPE_INVALID = 'Production type is required and must be either `produced`, `static`, `mobile`, `client booking` or `simulated`',
    LOCATION_MISSING = 'Location is required',

    TITLE_MISSING = 'Title is required',
    TITLE_TOO_SHORT = 'Minimum Title length is 3 characters',
    TITLE_TOO_LONG = 'Max Title length is 100 characters',

    DESCRIPTION_TOO_LONG = 'Max Description length is 5,000 characters',

    BACKGROUND_UPLOADING = 'Event background upload is in progress.',
    BACKGROUND_INVALID = 'Invalid background URL.',

    OPERATOR_NOTES_TOO_LONG = 'Max Operator Notes length is 5,000 characters',

    INVALID_TIME_FORMAT = 'Please use the following format: 01:30 PM',
    START_DATE_REQUIRED = 'A valid start time is required',
    START_DATE_ALREADY_PASSED = 'Start date must not have already passed',
    START_TIME_TOO_SOON_2H = 'Start time must be at least 2 hours from now',
    START_TIME_TOO_SOON_15M = 'Start time must be at least 15 minutes from now',
    START_TIME_TOO_SOON_10M = 'Start time must be at least 10 minutes from now',
    START_TIME_LEAD_TIME_LIMIT = 'You can only change the start time of this event by up to 2 hours',
    START_TIME_LEAD_TIME_CLIENT_BOOKING_LIMIT = 'Start time must be at least 1 hour from now',
    START_TIME_CONFLICT = 'This event starts less than 10 minutes from the previous scheduled event',
    SCHEDULED_EVENT_CONFLICT = 'There is already an event scheduled at this location during this time slot',
    START_TIME_MOBILE_CONFLICT = 'This mobile event starts before the produced event',

    END_TIME_REQUIRED = 'A valid end time is required',
    END_TIME_BEFORE_START = 'End time must be after the start time',
    EVENT_TOO_LONG = 'An event cannot be longer than 6 hours',
    END_TIME_LEAD_TIME_LIMIT = 'You can only change the end time of this event by up to 2 hours',
    END_TIME_CONFLICT = 'This event ends less than 10 minutes from the next scheduled event',

    REPEAT_INSUFFICIENT_CREDITS = 'There are not enough credits for all of the recurrences of this event',
    REPEAT_FREQUENCY_INVALID = 'Must be a positive number',
    REPEATING_EVENT_CONFLICT = 'A recurring event overlaps with another event scheduled at this location',
    REPEAT_WEEKDAY_REQUIRED = 'Weekdays must be specified for weekly recurring events',
    REPEAT_END_REQUIRED = 'Recurring events must provide an end condition',
    REPEAT_STOP_DATE_INVALID = 'Recurrence must end after the start date',
    NUM_RECURRENCES_INVALID = 'Must be a positive number',
    TOO_MANY_RECURRENCES = 'A maximum of 10 recurrences is allowed per event',

    EVENT_PASSWORD_REQUIRED = 'This is required in order to protect this event',
    REDIRECT_URL_REQUIRED = 'This is required in order to redirect this event',

    ZOOM_MEETING_ID_INVALID = 'This Meeting ID is invalid. A Meeting ID is a 9 to 11 digit number',
    ZOOM_PASSWORD_INVALID = 'This Password is invalid. The password is too short',

    INVALID_CUE_SHEET_URL = 'Invalid Cue Sheet URL.',

    LEADTIME_ACKNOWLEDGE = 'Lead time warning must be acknowledged.',

    EVENT_REDIRECT_REQUIRED = 'URL is required to redirect.',
    INVALID_REDIRECT_URL = 'Invalid Redirect URL.'
  }
}

export namespace ContactValidator {
  export interface Contact {
    id: number;
    name?: string;
    email?: string;
    phoneNumber?: string;
  }
}
