import { Glyph } from '@livecontrol/core-ui';
import { UUID } from '@livecontrol/core-utils';
import { Modal } from '@livecontrol/scheduler/components';
import type { FieldProps } from 'formik';
import { Field, Formik } from 'formik';
import { useRef } from 'react';
import type { ReactElement } from 'react';
import { Form } from '../../../../components';
import type { Props } from './form';
import { REPORT_TYPE, useForm } from './form';

export const Radio = (
  props: FieldProps & { id: string; label: string; value: string; disabled?: boolean }
): ReactElement => {
  const { id, field, label, value, disabled } = props;

  return (
    <Form.Check
      id={id}
      disabled={disabled ?? false}
      name={field.name}
      type='radio'
      label={label}
      value={value}
      checked={field.value === value}
      onChange={field.onChange}
    />
  );
};

export const Exporter = (props: Props): ReactElement => {
  const { formik, loading, alert } = useForm(props);
  const { users, onClose } = props;

  const uuid = useRef(UUID.prefix(UUID.make())).current;

  return (
    <Modal size='sm' show onHide={onClose}>
      <Modal.Header closeButton>
        <Glyph>
          <i className='fas fa-analytics' />
        </Glyph>

        <Modal.Title>Export Analytics</Modal.Title>

        <p className='text-center text-secondary mt-8px'>
          Your report will be downloaded in Comma-Separated Value (
          <a
            className='text-reset text-decoration-underline'
            target='_blank'
            href='https://en.wikipedia.org/wiki/Comma-separated_values'
            rel='noreferrer'
          >
            CSV
          </a>
          ) format.
        </p>
      </Modal.Header>

      <Formik {...formik}>
        <Form>
          <Modal.Body className='pb-20px'>
            <Form.Alert show={alert.show} onClose={alert.hide}>
              {alert.message}
            </Form.Alert>

            <Form.Label className='w-100 text-center'>Select Your Report</Form.Label>

            <Field
              id={`${uuid}-x`}
              name='report'
              value={REPORT_TYPE.ONLY_THIS_ASSET}
              label='Only this asset.'
              component={Radio}
            />

            <Field
              id={`${uuid}-y`}
              name='report'
              value={REPORT_TYPE.PAST_90_DAYS_ASSETS}
              label='All assets in the past 90 days.'
              component={Radio}
            />
            <Field
              id={`${uuid}-z`}
              disabled={!users.length}
              name='report'
              value={REPORT_TYPE.VIEWERS}
              label='The registered viewers from the event'
              component={Radio}
            />
          </Modal.Body>

          <Modal.Footer>
            <Modal.ButtonBox>
              <Modal.Submit className='btn-wide' loading={loading}>
                Export
              </Modal.Submit>
              <Modal.Button variant='outline-secondary' onClick={onClose}>
                Cancel
              </Modal.Button>
            </Modal.ButtonBox>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  );
};
