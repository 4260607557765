import type { ApolloError } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Obj, Str } from '@livecontrol/core-utils';
import { Account } from '@livecontrol/scheduler/model';
import { useCallback, useState } from 'react';
import { Errors } from '../../graphql';
import type { MutationResult } from '../../graphql';

interface TVariables {
  clientId: string;
  code: string;
  callbackPath?: string;
}

interface TData {
  facebookAuthorizationCallback: { authorization: unknown };
}

interface Args {
  account: Account.Like;
  code: string;
  callbackPath?: string;
}

const MUTATION = gql`
  mutation facebookAuthorizationCallback(
    $clientId: String!
    $code: String!
    $callbackPath: String
  ) {
    facebookAuthorizationCallback(clientId: $clientId, code: $code, callbackPath: $callbackPath) {
      authorization {
        id
        client_id
        account_id
        display_name
      }
    }
  }
`;

export const useFacebookAuthorizationCallback = (): [
  (args: Args) => Promise<TData | null | undefined>,
  MutationResult<TData | null>
] => {
  const [mutation, { data, called, loading }] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();

  return [
    useCallback(
      async (args: Args): Promise<TData | null | undefined> => {
        try {
          // Parse the input arguments
          const required = {
            clientId: Account.toId(args.account)?.toString(),
            code: Str.normalize(args.code)
          };

          if (!Obj.isHydrated(required)) {
            throw Errors.badRequest();
          }

          return await mutation({
            variables: {
              ...required,
              callbackPath: Str.normalize(args.callbackPath)
            }
          })
            .then(({ data: v }) => v)
            .catch((_error: ApolloError) => {
              throw Errors.serverError();
            });
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        return undefined;
      },
      [mutation, setError]
    ),
    {
      data,
      error,
      called,
      loading
    }
  ];
};
