import { gql } from '@apollo/client';

export const AddCard = gql`
  mutation AssignCard(
    $number: String!
    $expMonth: String!
    $expYear: String!
    $cvc: String!
    $customerId: String!
    $currency: String!
    $name: String!
  ) {
    assignCard(
      assignCardInput: {
        number: $number
        expMonth: $expMonth
        expYear: $expYear
        cvc: $cvc
        customerId: $customerId
        currency: $currency
        name: $name
        default: true
      }
    ) {
      id
      brand
      last4
      exp_month
      exp_year
      name
    }
  }
`;

export const DeleteCard = gql`
  mutation DeleteCard($cardId: String!, $customerId: String!) {
    deleteCard(input: { cardId: $cardId, customerId: $customerId }) {
      id
      brand
      deleted
    }
  }
`;

export const PayInvoice = gql`
  mutation PayInvoice($id: Float!) {
    payInvoice(input: { id: $id }) {
      id
      paid
      lines {
        data {
          price {
            recurring {
              interval
            }
          }
        }
      }
      subscription
    }
  }
`;

export const EditProduct = gql`
  mutation EditProduct(
    $id: Float!
    $type: Float!
    $description: String!
    $name: String!
    $price: Float!
  ) {
    updateLiveControlProduct(
      input: { id: $id, type: $type, description: $description, name: $name, price: $price }
    ) {
      id
      description
      price
      name
      type {
        id
        type
        description
      }
    }
  }
`;

export const ListCards = gql`
  query GetGQLListCards($userToken: String!) {
    listCards(listCardsInput: { customerId: $userToken }) {
      list {
        id
        brand
        name
        last4
      }
    }
  }
`;
