import { gql, useMutation } from '@apollo/client';
import { Str } from '@livecontrol/core-utils';
import { Account } from '@livecontrol/scheduler/model';
import { assert } from '@sindresorhus/is';
import { useCallback } from 'react';
import type { MutationResult } from '../../graphql';
import type { Record } from './types';
import * as Utils from './utils';

import S3 = Account.S3;

interface T_Data {
  signS3: Record;
}

interface T_Variables {
  filename: string;
  filetype: string;
  path?: string;
}

const MUTATION = gql`
  mutation signS3($filename: String!, $filetype: String!, $path: String) {
    signS3(filename: $filename, filetype: $filetype, path: $path) {
      url
      bucket
      key
    }
  }
`;

export const useUpload = (): [
  (filename: string, filetype: string, path?: string) => Promise<S3>,
  MutationResult
] => {
  const [fn, result] = useMutation<T_Data, T_Variables>(MUTATION);

  return [
    useCallback(
      async (filename: string, filetype: string, path?: string): Promise<S3> => {
        const canonicalFilename = Str.normalize(filename);
        const canonicalFiletype = Str.normalize(filetype);

        assert.string(canonicalFilename);
        assert.string(canonicalFiletype);

        const canonicalPath = Str.normalize(path);

        const mutation = await fn({
          variables: {
            filename: canonicalFilename,
            filetype: canonicalFiletype,
            path: canonicalPath
          }
        });

        const signedS3 = Utils.normalize(mutation.data?.signS3);

        return signedS3!;
      },
      [fn]
    ),
    result
  ];
};
