import { DateTime as DateTime_ } from './date-time';
import { Destinations as Destinations_ } from './destinations';
import { Header } from './header';
import { Sortable as Sortable_ } from './sortable';

/* eslint-disable */
declare module './header' {
  export namespace Header {
    export import DateTime = DateTime_;
    export import Destinations = Destinations_;
    export import Sortable = Sortable_;
  }
}
/* eslint-enable */

Object.assign(Header, {
  DateTime: DateTime_,
  Destinations: Destinations_,
  Sortable: Sortable_
});

export { Header };
