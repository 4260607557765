import { gql, useLazyQuery } from '@apollo/client';
import { Obj } from '@livecontrol/core-utils';
import { Event } from '@livecontrol/scheduler/model';
import { useCallback } from 'react';

interface TVariables {
  eventID: Event.Like;
}

interface TResult {
  loading: boolean;
  called: boolean;
}

const QUERY = gql`
  query resendClientNotification($eventID: Float!) {
    resendClientNotification(event_id: $eventID)
  }
`;

export type QueryLazyResult<T, P extends string = 'data'> = Readonly<{
  error?: Error;
  loading: boolean;
  called: boolean;
}> & {
  readonly [key in P]?: T;
};

export const useResendClientNotification = (): [
  (event: TVariables) => void,
  QueryLazyResult<TResult>
] => {
  const [query, { loading, error, called }] = useLazyQuery<TResult, TVariables>(QUERY, {
    fetchPolicy: 'no-cache'
  });

  const resendNotification = useCallback(
    (event: TVariables): void => {
      const required = {
        eventID: Event.toId(event.eventID)
      };

      if (!Obj.isHydrated(required)) {
        throw new Error('EventID/ClientID is required.');
      }

      query({
        variables: {
          eventID: event.eventID
        }
      });
    },
    [query]
  );

  return [resendNotification, { loading, error, called }];
};
