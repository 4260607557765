import type { ApolloCache, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Num } from '@livecontrol/core-utils';
import { Account } from '@livecontrol/scheduler/model';
import { assert } from '@sindresorhus/is';
import { useCallback } from 'react';
import type { PartialDeep } from 'type-fest';
import type { MutationResult } from '../../graphql';
import * as Dbx from '../dbx';
import type { Record } from './types';
import * as Utils from './utils';

import WebPlayer = Account.WebPlayer;

type Updates = PartialDeep<WebPlayer>;

interface T_Data {
  updateWebPlayerClientData: Record;
}

interface T_Variables {
  updateWebPlayerArgs: {
    id: number;
  };
}

const MUTATION = gql`
  mutation UpdateWebPlayerClientData($updateWebPlayerArgs: UpdateWebPlayerArgs!) {
    updateWebPlayerClientData(updateWebPlayerArgs: $updateWebPlayerArgs) {
      id
      slug
      name
      about
      theme
      logo_url
      donate_link
      has_autoplay
      has_archive
      has_chat
      has_profile
      has_schedule
      custom_placeholder_image
      custom_donate_button_copy
      custom_button_enabled
      custom_button {
        title
        url
      }
      intro_url
      outro_url
      links {
        type
        url
      }
    }
  }
`;

export const useEdit = (): [
  (accountId: Account.Like, updates: Updates, isOptimistic?: boolean) => Promise<WebPlayer>,
  MutationResult
] => {
  const [fn, result] = useMutation<T_Data, T_Variables>(MUTATION);

  return [
    useCallback(
      async (account: Account.Like, updates: Updates, isOptimistic = false): Promise<WebPlayer> => {
        const accountId = Account.toId(account);
        const canonicalId = Num.normalize(accountId, { positive: true });
        const webPlayerMutationVariables = Utils.normalizeForMutation(updates);

        assert.number(canonicalId);
        assert.object(webPlayerMutationVariables);

        const mutation = await fn({
          variables: {
            updateWebPlayerArgs: {
              id: canonicalId,
              ...webPlayerMutationVariables
            }
          },
          optimisticResponse: isOptimistic
            ? {
                updateWebPlayerClientData: {
                  id: canonicalId,
                  __typename: 'WebPlayerClientData',
                  ...webPlayerMutationVariables
                }
              }
            : undefined,
          update(cache: ApolloCache<unknown>, { data }: FetchResult<T_Data>): void {
            const webplayerClient = data?.updateWebPlayerClientData;

            if (typeof webplayerClient?.logo_url === 'string') {
              cache.modify({
                id: `${Dbx.__typename}:${canonicalId}`,
                fields: {
                  profile_picture(): string {
                    return webplayerClient.logo_url ?? '';
                  }
                }
              });
            }
          }
        });

        const webplayer = Utils.normalize(mutation.data?.updateWebPlayerClientData);

        assert.plainObject(webplayer);

        return webplayer;
      },
      [fn]
    ),
    result
  ];
};
