import { Scheduler } from '@livecontrol/scheduler/store';
// import type { MutationResult } from '@livecontrol/scheduler/store';
import type { FormikContextType } from 'formik';
import { useFormik } from 'formik';
import { useRef } from 'react';
import * as Yup from 'yup';
import { Alert, Store } from '../../../store';
import type { Event } from '../store';

export interface Props {
  events: Event[];
  ids: number[];
  onClose: () => void;
}

export const reasons: Record<number, string> = {
  1: 'Event time has changed',
  2: 'In-person event canceled',
  3: 'No longer need stream/recording',
  4: 'Other'
};

interface Values {
  reason: string;
  other: string;
}

interface Form {
  formik: FormikContextType<Values>;
  result: unknown; // actually is MutationResult<Summary, "summary">
  alert: Alert;
}

const validationSchema = Yup.object({
  reason: Yup.string().trim().required('Please select a reason'),
  other: Yup.string().trim('Reason should not start with a space').when('reason', {
    is: (reason: string) => reason === '4',
    then: Yup.string().required('Please provide the reason').min(5, 'Minimum of 5 characters')
  })
});

export const useForm = ({ ids, onClose }: Props): Form => {
  const [mutation, result] = Scheduler.Event.useDelete();

  const alert = Alert.useAlert(result.error);
  const account = Store.Account.useAccount();

  const initialValues = useRef({
    reason: '1',
    other:  ''
  }).current;

  const formik = useFormik<Values>({
    initialValues,
    validationSchema,
    validateOnMount: true,

    validate(): void {
      alert.hide();
    },

    async onSubmit(data: Values): Promise<void> {
      alert.hide();

      if (result.loading) {
        return;
      }

      const deleteEventsResult = await mutation({
        ids,
        reason: data.reason !== '4' ? reasons[Number(data.reason)] : data.other,
        account
      });

      if (deleteEventsResult) {
        onClose();
      }
    }
  });

  return {
    formik,
    result,
    alert
  };
};
