import cx from 'classnames';
import type { HTMLAttributes, ReactElement } from 'react';
import styled from 'styled-components';

const Container = styled.button`
  width: 54px;
  height: 54px;
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  &:hover {
    opacity: 0.6;
  }

  .icon {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .play-icon {
    background-image: url(/assets/icons/Play.svg);
  }

  .pause-icon {
    background-image: url(/assets/icons/Pause.svg);
  }
`;

export const PlayPause = ({ playing, className, ...props }: PlayPause.Props): ReactElement => {
  const icon = playing ? 'pause-icon' : 'play-icon';

  return (
    <Container className={cx(className)} {...props}>
      <div className={cx('icon', `${icon}`)} />
    </Container>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace PlayPause {
  export type Props = HTMLAttributes<HTMLButtonElement> & { playing?: boolean };
}
