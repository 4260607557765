/* eslint-disable @typescript-eslint/no-unnecessary-qualifier */
import { Enum, Obj, Str } from '@livecontrol/core-utils';

export interface Destination {
  id: Destination.ID;
  name: string;
  service: Destination.Service;
  rtmp?: Destination.RTMP;
  deauthorized: boolean;
}

export namespace Destination {
  export type ID = string;
  export type Like = Destination | ID;

  export const toId = (destination?: Like): ID | undefined =>
    Str.normalize(Obj.isObject<Destination>(destination) ? destination.id : destination);

  export enum Service {
    LiveControl = 1,
    Facebook = 2,
    YouTube = 3,
    Zoom = 4,
    RTMP = 5
  }

  export namespace Service {
    export const normalize = (value: unknown): Service | undefined =>
      Str.normalize(value)?.includes('facebook')
        ? Service.Facebook
        : Str.normalize(value)?.includes('youtube')
        ? Service.YouTube
        : Enum.normalize(Service, value);

    export const stringValues = new Map<Service, string>([
      [Service.LiveControl, 'LiveControl'],
      [Service.Facebook, 'Facebook'],
      [Service.YouTube, 'YouTube'],
      [Service.Zoom, 'Zoom'],
      [Service.RTMP, 'RTMP']
    ]);

    export const toString = (service: Service): string => stringValues.get(service)!;
  }

  export interface RTMP {
    streamKey: string;
    destination: string;
  }
}
