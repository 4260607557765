import cx from 'classnames';
import React from 'react';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

/* eslint-disable react/no-danger */

export interface PageProps {
  className?: string;
  code: number;
  heading: string;
  message: string;
  reset?: () => void;
}

const Container = styled.div`
  svg {
    --fa-secondary-color: var(--primary);
  }
`;

const Code = styled.div`
  font-size: 4.5rem;
`;

export const Page = ({
  className,
  code,
  heading,
  message,
  reset
}: PageProps): React.ReactElement => {
  const history = useHistory();

  const handler = (event: React.MouseEvent): void => {
    event.preventDefault();

    // Reset the error boundary
    reset?.();

    history.push('/home');
  };

  return (
    <Container className={cx('d-flex', 'flex-column', 'align-items-center', className)}>
      <Code className='text-light font-weight-bold pb-32px'>{code}</Code>

      <i className='fad fa-3x fa-camera-movie' />

      <div className='pt-12px pb-8px font-size-24px'>{heading}</div>

      <p
        className='pb-20px text-center text-secondary'
        dangerouslySetInnerHTML={{ __html: message }}
      />

      <Button className='btn-wide' size='lg' onClick={handler}>
        &nbsp;Back to Dashboard
      </Button>
    </Container>
  );
};
