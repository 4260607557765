import type { Location } from '@livecontrol/scheduler/model';
import React from 'react';
import { Form } from '../../../../components';

export const EventLocation = ({
  options,
  disabled,
  errors
}: EventLocationProps): React.ReactElement =>
  options.length > 1 ? (
    <Form.Group
      name='location'
      label='Event Location'
      className='custom-input location-input'
      error={
        errors?.location ? (
          errors.location
        ) : disabled ? (
          <p className='text-muted font-size-12px font-weight-normal'>
            Location cannot be changed so close to the event start time.
          </p>
        ) : undefined
      }
      controlProps={{
        as: 'select',
        disabled
      }}
      wrapperClass = 'input-wrapper'
      rightIconClass = 'icon chevron-down'
    >
      {options
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((location) => (
          <option key={location.id} value={location.id}>
            {location.name}
          </option>
        ))}
    </Form.Group>
  ) : options.length === 1 ? (
    <Form.Group
      name='location'
      label='Event Location'
      className='custom-input location-input'
      control={
        <div className='input-wrapper disabled'>
          <Form.Control as='select' disabled>
            <option value={options[0].id}>{options[0].name}</option>
          </Form.Control>
          <div className='icon-section'>
            <div className='icon chevron-down' />
          </div>
        </div>
      }
    />
  ) : (
    <div className='input-wrapper'>
      <Form.Group
        name='location'
        label='Event Location'
        className='custom-input location-input'
        control={
          <div className='input-wrapper disabled'>
            <Form.Control as='select' disabled>
              <option value={undefined}>No Available Locations</option>
            </Form.Control>
            <div className='icon-section'>
              <div className='icon chevron-down' />
            </div>
          </div>
        }
      />
    </div>
  );

export interface EventLocationProps {
  options: Location[];
  disabled?: boolean;
  errors?: { location?: string };
}
