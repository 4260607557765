import { Device, Theme } from '@livecontrol/core-ui';
import { ScaffoldHeader } from '@livecontrol/scheduler/components';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Store } from '../../store';
import { Credits } from './credits';
import { User } from './user';

const Separator = styled.div`
  background: var(--tint-ø1);
  height: 2rem;
  width: 1px;
`;

export const Header = (): ReactElement => {
  const viewport = Theme.useViewport();
  const account = Store.Account.useAccount();
  const { billing } = Scheduler.Account.useBilling(account);

  return (
    <ScaffoldHeader logo={<img src='assets/entrance-dark.svg' alt='LiveControl Logo' />}>
      {!Device.isMobile(viewport) && billing && (
        <>
          <Credits billing={billing} />
          <div className='mx-16px'>
            <Separator />
          </div>
        </>
      )}

      {billing && <User billing={billing} viewport={viewport} />}
    </ScaffoldHeader>
  );
};
