import { Str } from '@livecontrol/core-utils';
import type { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { ZendeskAPI } from 'react-zendesk';
import { SectionContainer } from '../../components';
import { getZendeskToken } from '../../store';
import { Header } from './header';
import { Past } from './past';
import { Pending } from './pending';
import { Tab, useStore } from './store';
import { Tabber } from './tabber';

export const Layout = (): ReactElement => {
  const tabset = useStore(({ model }) => model.value.tabset);
  const slug = Str.normalize(useParams<{ slug?: string }>().slug);

  const tab = slug === 'past' && tabset.has(Tab.Past) ? Tab.Past : Tab.Pending;
  const Content = tab === Tab.Pending ? Pending : Past;

  const zendeskToken = getZendeskToken();

  if (zendeskToken) {
    ZendeskAPI('messenger', 'loginUser', (callback: (arg0: string) => void) => {
      ZendeskAPI('messenger:set', 'zIndex', 9999999);
      callback(zendeskToken);
    });
  }

  return (
    <SectionContainer className='col-12'>
      <Header />
      <Tabber tab={tab} />
      <Content />
    </SectionContainer>
  );
};
