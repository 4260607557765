import type { Asset } from '@livecontrol/scheduler/model';
import React, { useCallback, useContext, useState } from 'react';
import { EventContextStore } from '../event-store';
import type { EventContext } from '../event-store';
import { PastStreams } from './past-streams';
import { Tab, Tabber } from './tabber';
import { UploadVideo } from './upload-video';

export const Layout = ({ onContinue, onBack }: Layout.Props): React.ReactElement => {
  const { store }: EventContext = useContext<EventContext>(EventContextStore);

  const [activeTab, setActiveTab] = useState<string>(
    store.simulatedLiveAssetIsUpload ? Tab.Upload : Tab.Past
  );

  const onChangeTab = useCallback((key?: string | null): void => {
    if (key) {
      setActiveTab(key);
    }
  }, []);

  const onChooseVideo = useCallback(
    (assetDetails: Asset.MuxUploadedAsset, isUpload = false) => {
      store.setSimulatedLiveAssetDetails(assetDetails);
      store.setSimulatedLiveAssetIsUpload(isUpload);

      if (!isUpload) {
        store.setSimulatedLiveFileUpload(undefined);
      }

      onContinue();
    },
    [onContinue, store]
  );

  return (
    <>
      <p className='mt-12px font-size-20px font-weight-bold text-dark'>
        Select Video for Simulated Live
      </p>
      <Tabber tab={activeTab} onChangeTab={onChangeTab} />

      <div className='mt-8px'>
        {activeTab === Tab.Past ? (
          <PastStreams onContinue={onChooseVideo} onBack={onBack} />
        ) : (
          <UploadVideo onContinue={onChooseVideo} onBack={onBack} />
        )}
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Layout {
  export interface Props {
    title?: string;
    onContinue: () => void;
    onBack: () => void;
  }
}
