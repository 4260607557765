import type { HTMLAttributes, PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content:center;
  transition: padding linear var(--transition-duration);
  padding-top: 70px;
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;

  .x-dark-theme & {
    display: block;
  }
`;

export const Content = ({
  children,
  ...rest
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>): ReactElement =>
  (
    <Container className='container-fluid row' {...rest}>
      {children}
    </Container>
  );

