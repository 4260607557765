import { Phone } from '@livecontrol/locale-us';
import type { Account } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { FormikContextType } from 'formik';
import { useFormik } from 'formik';
import { useCallback, useRef } from 'react';
import * as Yup from 'yup';
import { Alert } from '../../../store';

export interface Props {
  account: Account;
  onClose: () => void;
}

interface Values {
  address: string;
  organizationPhoneNumber: string;
}

interface Form {
  formik: FormikContextType<Values>;
  loading: boolean;
  alert: Alert;
  handleClose: () => void;
}

const validationSchema = Yup.object({ 
  organizationPhoneNumber: Yup.string().trim().required('Please specify the organization phone number where your venue is located.').test({
    message: 'Please provide a valid phone number.',
    test: (value) => value ? !!Phone.normalize(value) : true
  }),
  address: Yup.string()
    .trim()
    .required('Please specify the address where your venue is located.')
});

export const useForm = ({ account, onClose }: Props): Form => {
  const [mutation, { loading, error }] = Scheduler.Account.useEdit();
  const alert = Alert.useAlert(error);

  const initialValues = useRef({
    organizationPhoneNumber: account.organizationPhoneNumber?.format({separator: ''}) ?? '',
    address: account.address ?? ''
  }).current;

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const formik = useFormik<Values>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,

    validate(): void {
      alert.hide();
    },

    async onSubmit(data: Values): Promise<void> {
      alert.hide();

      // If not loading, execute the mutation
      if (!loading && (await mutation({ id: account, ...data }))) {
        handleClose();
      }
    }
  });

  return {
    formik,
    loading,
    alert,
    handleClose
  };
};
