import { Device } from '@livecontrol/core-ui';
import cx from 'classnames';
import React, { useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Blueprint } from '../blueprint';
import { Context } from '../store';
import { Item } from './item';
import { Underlay } from './underlay';

type Target = string | (() => void);

interface Descriptor {
  key: string;
  accent?: boolean;
  icon: JSX.Element | string;
  label: string;
  target: Target;
  subitems?: Subitem[];
}

interface Subitem {
  key: string;
  icon: JSX.Element | string;
  label: string;
  target: string | (() => void);
}

const Wrapper = styled.nav`
  --shadow: 0 0 20px rgba(0, 0, 0, 0.25);

  height: 100vh;
  overflow: hidden;
  position: fixed;
  transition: var(--transition);
  border-bottom-right-radius: var(--spacer-8px);
  border-top-right-radius: var(--spacer-8px);

  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  .x-${Device.MOBILE} &,
  .x-${Device.DESKSTOP} & {
    &.closing,
    &.closing:hover {
      pointer-events: none !important;
    }
  }

  .x-${Device.MOBILE} &,
  .x-${Device.DESKSTOP} & {
    &:hover {
      width: ${Blueprint.Sidebar.width.expanded}px !important;

      label {
        opacity: 1 !important;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .x-light-theme & {
    background-color: #00102d;
  }

  .x-dark-theme & {
    background-color: var(--beta);
  }

  .x-${Device.MOBILE} & {
    transform: translateX(-${Blueprint.Sidebar.width.expanded}px);
    width: ${Blueprint.Sidebar.width.expanded}px;

    &.x-expanded {
      box-shadow: var(--shadow);
      transform: translateX(0);
    }
  }

  .x-${Device.TABLET} &,
  .x-${Device.DESKSTOP} & {
    width: ${Blueprint.Sidebar.width.collapsed}px;

    label {
      opacity: 0;
    }

    &.x-expanded {
      width: ${Blueprint.Sidebar.width.expanded}px;

      label {
        opacity: 1;
      }
    }
  }
`;

export const Sidebar = ({
  children,
  Header,
  descriptors,
  className,
  ...rest
}: React.PropsWithChildren<
  React.HTMLAttributes<HTMLDivElement> & {
    descriptors: Descriptor[];
    Header?: ({ clientId }: { clientId?: number }) => React.ReactElement;
  }
>): React.ReactElement => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { viewport, sidebar } = useContext(Context);
  const { isExpanded } = sidebar;

  const handler = useCallback(
    (target: Target): void => {
      // If mobile, collapse the sidebar
      if (Device.isMobile(viewport)) {
        sidebar.collapse();
      }

      if (typeof target === 'function') {
        target();
      } else {
        history.push(target);
      }
    },
    [sidebar, viewport, history]
  );

  const expand = (): void => {
    if (Device.isTablet(viewport) && !isExpanded) {
      sidebar.expand();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div style={{ zIndex: 10 }} onClick={expand}>
      {Device.isMobile(viewport) && isExpanded && <Underlay onClose={sidebar.collapse} />}

      <Wrapper
        className={cx(className, isExpanded && 'x-expanded', sidebar.isClosing && 'closing')}
        {...rest}
      >
        {Header && <Header />}
        {descriptors.map((descriptor: Descriptor) => {
          const { key, target, icon, label, subitems, accent } = descriptor;

          const active =
            typeof target === 'string' &&
            (pathname.endsWith(target) || pathname.startsWith(target));

          const passthru = {
            icon,
            label,
            active,
            accent,
            subitems,
            onClick(): void {
              handler(target);
            }
          };

          return <Item key={key} {...passthru} />;
        })}
        {children}
      </Wrapper>
    </div>
  );
};
