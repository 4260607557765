import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import type { JaySON } from '@livecontrol/core-utils';
import { Obj } from '@livecontrol/core-utils';
import type { Asset } from '@livecontrol/scheduler/model';
import { Account } from '@livecontrol/scheduler/model';
import { useEffect, useState } from 'react';
import type { QueryResult } from '../graphql';
import * as Analytics from './analytics';

interface TVariables {
  clientId: number;
  eventSlugIds?: string[];
}

interface TData {
  getCapturedEmailForManyEvents: {
    success?: boolean;
    body?: {
      message?: string;
      metrics?: JaySON.Object;
    };
  };
  result: unknown;
}

export const QUERY = gql`
  query GetCapturedEmailForManyEvents(
    $clientId: Float!
    $eventSlugIds: [String!]!
  ) {
    getCapturedEmailForManyEvents(client_id: $clientId, event_slug_ids: $eventSlugIds) {
      body {
        message
        metrics {
          capturedEmails
          registeredViewers
          users {
            email
            last_name
            name
          }
        }
      }
      success
    }
  }
`;

interface Args {
  account: Account.Like;
  eventSlugIds?: string[];
}

export const useCapturedEmail = (args: Args): QueryResult<Asset.Analytics.Viewers, 'capturedEmail'> => {
  const [error, setError] = useState<Error | undefined>();
  const [capturedEmail, setCapturedEmail] = useState<Asset.Analytics.Viewers | undefined>(undefined);

  // Parse the input arguments
  const variables = {
    clientId: Account.toId(args.account),
    eventSlugIds: args.eventSlugIds
  };

  // Validate the input
  if (!Obj.isHydrated(variables)) {
    return { loading: false, error: Exception.KABOOM };
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  // Prepare the query
  const { loading, data } = useQuery<TData, TVariables>(QUERY, {
    variables,
    onError() {
      setError(Exception.KABOOM);
    }
  });

  // When available, parse server response
  useEffect(() => {

    let viewers_: Asset.Analytics.Viewers | undefined = {
        capturedEmails: 0,
        registeredViewers: 0,
        users: []
    };

    let error_;

    if (data?.getCapturedEmailForManyEvents.success) {
      viewers_ = Analytics.toViewers(data.getCapturedEmailForManyEvents.body?.metrics);
    }

    setCapturedEmail(viewers_);
    setError(error_);
  }, [data]);

  return { error, loading, capturedEmail };
};
