import { Refs } from '@livecontrol/react-utils';
import cx from 'classnames';
import _ from 'lodash';
import type { ReactElement } from 'react';
import React, { forwardRef } from 'react';
import type { IMaskInputProps } from 'react-imask';
import { IMaskInput } from 'react-imask';
import { Utils } from './utils';

const styleRadioB = {
  width: '50%',
  display: 'flex',
  overflow: 'clip'
};

const styleInput = {
  width: '50%'
};

const styleHolder = {
  display: 'flex'
};

export const PhoneWithSelector = forwardRef(
  (
    { className, isInvalid, onChangePhoneType, selectedPhoneType, mask, ...props }: PhoneWithSelector.Props,
    ref: React.Ref<HTMLInputElement>
  ): ReactElement => {
    const field = Utils.useField(props);


    return (
      <div style={styleHolder}>
        <div style={styleRadioB}>
          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='radio'
              name='operatedService'
              onChange={onChangePhoneType}
              defaultChecked={ selectedPhoneType === 'Mobile'}
              id='operatedServiceOption1'
              value='Mobile'
            />
            <label className='form-check-label' htmlFor='operatedServiceOption1'>
              Mobile
            </label>
          </div>
          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='radio'
              name='operatedService'
              onChange={onChangePhoneType}
              defaultChecked={ selectedPhoneType === 'Landline'}
              id='operatedServiceOption2'
              value='Landline'
            />
            <label className='form-check-label' htmlFor='operatedServiceOption2'>
              <span>Landline</span>
            </label>
          </div>
        </div>

        <div style={styleInput}>
          <IMaskInput
            mask={mask ?? '(000) 000-0000'}
            lazy={false}
            placeholderChar='_'
            className={cx('form-control', isInvalid && 'is-invalid', className)}
            unmask
            onAccept={(v: string): void => {
              if (field) {
                field.helpers.setValue(v);
              }
            }}
            inputRef={(el: HTMLInputElement): void => {
              Refs.update(ref, el);
            }}
            {..._.omit(props, 'onChange')}
          />
        </div>
      </div>
    );
  }
);

PhoneWithSelector.displayName = 'PhoneWithSelector';


// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace PhoneWithSelector {
  export type Props = Omit<IMaskInputProps, 'onAccept'> &
    React.InputHTMLAttributes<HTMLInputElement> & {
      isInvalid?: boolean;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChangePhoneType?: (value?: any) => void;
      selectedPhoneType?: string;
    };
}
