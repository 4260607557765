import cx from 'classnames';
import _ from 'lodash';
import React from 'react';
import type { AlertProps } from 'react-bootstrap/Alert';
import B_Alert from 'react-bootstrap/Alert';

export const Alert = (props: Alert.Props): React.ReactElement => {
  const { className, children, ...rest } = props;

  return (
    <B_Alert
      className={cx('text-center', className)}
      {..._.defaults(rest, {
        variant: 'danger',
        dismissible: true,
        show: false
      })}
    >
      {children}
    </B_Alert>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Alert {
  export type Props = AlertProps;
}
