/* eslint-disable react-hooks/exhaustive-deps */
import { Device, Form, Loading, Theme } from '@livecontrol/core-ui';
import type { Account, Location } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { FormikProvider } from 'formik';
import type { Location as UrlLocation } from 'history';
import type { ReactElement } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { PermissionsDropdown } from '../account-permissions';
import { TablePermissions } from '../components';
import { FormWarning } from '../form-warning';
import { useForm } from '../permissions-user';

interface Props {
  account: Account;
  usersAvailable: Account.SubUser[];
}

export const PermissionsLocation = ({ account, usersAvailable }: Props): ReactElement => {
  const { locations } = Scheduler.Location.useLocations(account);

  const [locationSelected, setLocationSelected] = useState<Location | undefined>(locations?.[0]);
  const [initialValuesLoaded, setInitialValuesLoaded] = useState<boolean>(false);
  const [formikIsDirty, setFormikIsDirty] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<UrlLocation | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const sortedLocations = useMemo(
    () => locations?.sort((a, b) => a.name.localeCompare(b.name)),
    [locations]
  );

  const history = useHistory();
  const viewport = Theme.useViewport();
  const { loading: loadingBilling } = Scheduler.Account.useBilling(account);

  const [saved, setSaved] = useState(false);

  const handleSubmitSuccess = (): void => {
    setSaved(true);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (saved) {
      setSaved(true);

      timeout = setTimeout(() => {
        setSaved(false);
      }, 3000);
    }

    return (): void => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [saved]);

  const { formik, loading, loadingAccount } = useForm({
    account,
    subUser: usersAvailable,
    permisionByUser: false,
    handleSubmitSuccess
  });

  const setFormik = (): void => {
    usersAvailable.forEach((subuser) => {
      const permissions = subuser.sub_user_locations?.find(
        (locationPermission) => locationSelected?.id === locationPermission.location.id
      );

      formik.setFieldValue(
        `${subuser.id}.eventManagment`,
        permissions ? permissions.eventManagment : false
      );

      formik.setFieldValue(
        `${subuser.id}.viewAndDownload`,
        permissions ? permissions.viewAndDownload : false
      );

      formik.setFieldValue(
        `${subuser.id}.contentManagment`,
        permissions ? permissions.contentManagment : false
      );

      formik.setFieldValue(
        `${subuser.id}.productionNotes`,
        permissions ? permissions.productionNotes : false
      );

      if (permissions?.id) {
        formik.setFieldValue(`${subuser.id}.id`, Number(permissions.id));
      }
    });
  };

  useEffect(() => {
    if (!loading && !loadingAccount && !loadingBilling && !initialValuesLoaded) {
      setInitialValuesLoaded(true);
    }
  }, [loading, loadingAccount, loadingBilling]);

  useEffect(() => {
    const isFormTouched = Object.keys(formik.touched)
      .filter((key) => key !== 'locationUser')
      .some((key) => formik.touched[key]);

    if (initialValuesLoaded && isFormTouched) {
      setFormikIsDirty(true);
      setConfirmedNavigation(false);
    } else {
      setFormikIsDirty(false);
    }
  }, [initialValuesLoaded, formik.touched]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      const searchParams = new URLSearchParams(lastLocation.search);
      const locationSelectedFromUrl = searchParams.get('location_selected');

      if (locationSelectedFromUrl !== null) {
        const locationURLSelected = locations?.find(
          (location) => location.id === locationSelectedFromUrl
        );

        if (locationURLSelected) {
          formik.setFieldValue('id', locationSelectedFromUrl);
          setLocationSelected(locationURLSelected);
          setFormik();
        }
      }

      history.push(lastLocation.pathname + lastLocation.search);

      setFormikIsDirty(false);
      setModalVisible(false);
      setConfirmedNavigation(true);
      formik.setTouched({});
    }
  }, [confirmedNavigation, history, lastLocation]);

  useEffect(() => {
    if (locationSelected === undefined) {
      setLocationSelected(locations?.[0]);

      return;
    }

    history.push(`/account/users/permissions-location?location_selected=${locationSelected.id}`);

    if (!formikIsDirty) {
      formik.setFieldValue('id', locationSelected.id);
      setFormik();
    }
  }, [locationSelected, locations]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const locationSelectedFromUrl = searchParams.get('location_selected');

    if (locationSelectedFromUrl !== null) {
      formik.values.locationUser = 'location';

      if (locationSelectedFromUrl !== locationSelected?.id) {
        const subUserSelected = locations?.find(
          (subuser) => subuser.id === locationSelectedFromUrl
        );

        if (subUserSelected) {
          setLocationSelected(subUserSelected);
        }
      }
    }
  }, [account, locations]);

  useEffect(() => {
    setFormik();
  }, [locations, account]);

  const handleBlockedNavigation = (nextLocation: UrlLocation): boolean => {
    if (!confirmedNavigation) {
      setModalVisible(true);
      setLastLocation(nextLocation);

      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = (): void => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  const handleSubmit = async (): Promise<void> => {
    await formik.submitForm();

    formik.setTouched({});

    setFormikIsDirty(false);
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  const handleCloseModal = (): void => {
    setModalVisible(false);
    setConfirmedNavigation(true);

    const searchParams = new URLSearchParams(location.search);
    const locationSelectedFromUrl = searchParams.get('location_selected');

    if (locationSelectedFromUrl !== null) {
      const subLocationSelected = locations?.find(
        (location) => location.id === locationSelectedFromUrl
      );

      if (subLocationSelected) {
        setLocationSelected(subLocationSelected);
      }
    }
  };

  return (
    <div className='mt-56px'>
      {locations ? (
        <FormikProvider value={formik}>
          <Form>
            <div className='d-flex flex-row justify-content-between mb-12px'>
              <div className='d-flex flex-column font-weight-bold text-dark' style={{ width: 250 }}>
                <h1 className='font-size-28px'>Users By Location</h1>
                <div className='font-size-12px mb-28px'>
                  Easily change permission on a per location basis. To change Admin Settings, please
                  update the user individually (Permissions by User).
                </div>
              </div>

              <div className='position-relative'>
                <button
                  type='button'
                  className='btn btn-primary ml-12px mt-8px'
                  style={{ height: '42px' }}
                  onClick={handleSubmit}
                  disabled={loading || loadingAccount}
                >
                  {(loading || loadingAccount) && (
                    <span>
                      <i className='fad fa-spinner-third fa-spin mr-8px' />
                    </span>
                  )}
                  Save Changes
                </button>
                {saved && (
                  <span
                    className='text-success font-weight-bold text-center font-size-12px'
                    style={{ position: 'absolute', left: '50px', top: '54px' }}
                  >
                    <i className='mr-8px fa fa-check' />
                    Saved
                  </span>
                )}
              </div>
            </div>

            <div className='position-relative'>
              <div
                className='position-absolute bg-white font-weight-bold'
                style={{
                  width: Device.isMobile(viewport) ? '125px' : '300px',
                  height: 140,
                  zIndex: 1
                }}
              >
                <PermissionsDropdown
                  truncate
                  isLocation='location'
                  valueSelected={locationSelected}
                  setValueLocation={setLocationSelected}
                  locations={sortedLocations}
                />
              </div>
            </div>

            <div>
              <TablePermissions subUsers={usersAvailable} formik={formik} />
            </div>

            <Prompt when={formikIsDirty} message={handleBlockedNavigation} />
            <FormWarning
              show={modalVisible}
              loading={loading || loadingAccount}
              onClose={(): void => {
                handleCloseModal();
              }}
              onRevert={handleConfirmNavigationClick}
              onSave={handleSubmit}
            />
          </Form>
        </FormikProvider>
      ) : (
        <Loading.Delay />
      )}
    </div>
  );
};
