import { Event } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { EventValidator } from '@livecontrol/scheduler/validator';
import { DateTime, Duration } from 'luxon';
import React, { useMemo } from 'react';
import { Form } from '../../../../components';
import { Store } from '../../../../store';

export const EndTime = ({
  errors,
  disabled,
  startTime,
  endTime,
  startDate,
  timezone,
  eventType,
  disabledReason
}: EndTimeProps): React.ReactElement => {
  const account = Store.Account.useAccount();
  const { billing } = Scheduler.Account.useBilling(account);

  const overTimeDuration = Duration.fromMillis(
    (billing?.credits.first_credit_time_limit ?? 0) * 60 * 1000
  );

  const creditsCharged = useMemo(() => {
    let eventDuration = startTime && endTime && endTime.diff(startTime);

    if (eventDuration?.milliseconds && eventDuration.milliseconds < 0) {
      const positiveMillis = 86400000 + eventDuration.milliseconds;

      eventDuration = Duration.fromMillis(positiveMillis);
    }

    if (!billing || !eventDuration) {
      return 0;
    }

    if (eventType === EventValidator.ProductionType.PRODUCED) {
      return Event.Production.computeCreditsChargedForProducedEvent({
        duration: eventDuration,
        overTimeLimitCharge: billing.credits.over_time_limit_cost,
        overTimeLimitInMinutes: billing.credits.first_credit_time_limit
      });
    }

    return 0;
  }, [billing, endTime, startTime, eventType]);

  return (
    <Form.Group
      name='endTime'
      label={
        <label>
          End Time{' '}
          {timezone && <span>({DateTime.local().setZone(timezone).toFormat('ZZZZ')})</span>}
        </label>
      }
      className={`d-flex flex-column custom-input time-input ${errors.endTime ? 'has-errors' : ''}`}
      error={
        <>
          {startTime && endTime && endTime < startTime && (
            <p className='text-danger font-size-12px font-weight-normal'>
              This event ends on {startDate?.plus({ days: 1 }).toFormat('EEEE, MMMM d')}
            </p>
          )}
          {errors.endTime ? (
            <p className='text-danger font-size-12px font-weight-normal'>{errors.endTime}</p>
          ) : creditsCharged > 1 ? (
            <p className='text-danger font-size-12px font-weight-normal'>
              Events over {overTimeDuration.toFormat("h'hr' m'm'")} are charged {creditsCharged}{' '}
              Credits
            </p>
          ) : disabled ? (
            <p className='text-muted font-size-12px font-weight-normal'>{disabledReason}</p>
          ) : undefined}
        </>
      }
      controlProps={{
        disabled
      }}
      control={Form.Time}
      wrapperClass='input-wrapper'
      rightIconClass='icon clock'
    />
  );
};

export interface EndTimeProps {
  startTime?: DateTime;
  endTime?: DateTime;
  startDate?: DateTime;
  timezone?: string;
  errors: { endTime?: string; leadTime?: string };
  disabled?: boolean;
  disabledReason?: string;
  eventType?: EventValidator.ProductionType;
}
