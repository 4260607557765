import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import styled from 'styled-components';
import { Form } from '../../../../components';

const RoundedLeft = styled(InputGroup.Text)`
  border-radius: var(--border-radius-sm) 0 0 var(--border-radius-sm);
`;

const RoundedRight = styled(Form.Group)`
  .form-control {
    border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0;
  }
`;

interface Props {
  prefix?: string;
}

export const Title = ({ prefix }: Props): React.ReactElement => (
  <div>
    <Form.Descriptor
      title='Event Title'
      titleClassName='title mr-lg-3 font-size-15px font-weight-bold text-dark required'
      description='Add a title that describes your event.'
      descriptionClassName='font-size-13px text-secondary font-weight-bold text-gray-light'
      className='form-section-lateral d-lg-flex'
    >
      <InputGroup className='align-items-start'>
        {prefix ? (
          <>
            <RoundedLeft className='text-muted'>{prefix}</RoundedLeft>
            <RoundedRight
              name='title'
              className='flex-grow-1'
              label={false}
              controlProps={{
                type: 'text',
                placeholder: 'Add a title that describes your event'
              }}
            />
          </>
        ) : (
          <Form.Group
            name='title'
            className='flex-grow-1'
            label={false}
            controlProps={{
              type: 'text',
              placeholder: 'Add a title that describes your event'
            }}
          />
        )}
      </InputGroup>
    </Form.Descriptor>
  </div>
);
