import { gql } from '@apollo/client';
import { Bool, Num, Obj, Str } from '@livecontrol/core-utils';
import type { AddOn, Location, ProductionNotes } from '@livecontrol/scheduler/model';
import { DateTime } from 'luxon';

export type LocationRecord = Partial<{
  id: string;
  secondary_id: number;
  name: string;
  zoom: boolean;
  time_zone: string;
  screenshare: boolean;
  is_simulated_live: boolean;
  is_disabled: boolean;
  is_static: boolean;
  is_produced: boolean;
  is_client_booking: boolean;
  is_mobile: boolean;
  is_mobile_kit: boolean;
  is_test: boolean;
  cue_sheet_enabled: boolean;
  production_notes?: {
    id?: number;
    notes?: (UpdateProductionNotes | undefined)[];
  };
  onboarding_location_address: Location.OnboardingLocationAddress;
}>;

interface UpdateProductionNotes {
  category?: string;
  content?: string;
  option?: number;
}

export const normalize = (record: Partial<LocationRecord>): Location | undefined => {
  const productionNotes: ProductionNotes = {};

  if (record.production_notes) {
    productionNotes.locationId = record.id;
  }

  record.production_notes?.notes?.forEach((note) => {
    if (note?.category === 'camera_movements_preference') {
      productionNotes.camera_movements_preference = note.option;
    }

    if (note?.category === 'audience_shots') {
      productionNotes.audience_shots = note.option;
    }

    if (note?.category === 'close_up_shots') {
      productionNotes.close_up_shots = note.option;
    }

    if (note?.category === 'wide_venue_shots') {
      productionNotes.wide_venue_shots = note.option;
    }

    if (note?.category === 'detailed_venue_shots') {
      productionNotes.detailed_venue_shots = note.option;
    }

    if (note?.category === 'event_ending_preference') {
      productionNotes.event_ending_preference = note.option;
    }
  });

  const candidate = {
    id: Str.normalize(record.id),
    secondaryId: Num.normalize(record.secondary_id),
    name: Str.normalize(record.name),
    zoom: Bool.normalize(record.zoom),
    timezone: Str.normalize(record.time_zone),
    simulatedLive: Bool.normalize(record.is_simulated_live),
    screenshare: Bool.normalize(record.screenshare),
    isDisabled: Bool.normalize(record.is_disabled),
    isStatic: Bool.normalize(record.is_static),
    isProduced: Bool.normalize(record.is_produced),
    isClientBooking: Bool.normalize(record.is_client_booking),
    isMobile: Bool.normalize(record.is_mobile),
    isMobileKit: Bool.normalize(record.is_mobile_kit),
    isTest: Bool.normalize(record.is_test),
    isCueSheetEnabled: Bool.normalize(record.cue_sheet_enabled),
    productionNotes,
    onboardingLocationAddress: record.onboarding_location_address
      ? record.onboarding_location_address
      : {}
  };

  return Obj.isHydrated(candidate) ? candidate : undefined;
};

export const StandardLocationResponse = gql`
  fragment StandardLocationResponse on Location {
    id
    secondary_id
    name
    zoom
    time_zone
    screenshare
    is_simulated_live
    is_disabled
    is_static
    is_produced
    is_client_booking
    is_mobile
    is_mobile_kit
    is_test
    cue_sheet_enabled
    production_notes {
      id
      notes {
        option
        content
        category
      }
    }
    onboarding_location_address {
      shipping_city
      shipping_state
      shipping_street_address_1
      shipping_street_address_2
      shipping_zip_code
    }
  }
`;

const UTC = { zone: 'utc' };

interface onboardingAddress {
  id?: string;
  installation_street_address_1?: string;
  installation_street_address_2?: string;
  installation_city?: string;
  installation_state?: string;
  installation_zip_code?: string;
  shipping_street_address_1?: string;
  shipping_street_address_2?: string;
  shipping_city?: string;
  shipping_state?: string;
  shipping_zip_code?: string;
  handler_installation?: string;
  install_date_request?: string;
  ideal_site_visit_date?: string;
  ideal_first_stream_date?: string;
  additional_information?: string;
  shipping_contact_id?: string;
  onboarding_main_contactId?: string;
  onboarding_technical_contact_id?: string;
}

export type RecordWithOnboarding = Partial<{
  id: string;
  secondary_id: number;
  name: string;
  zoom: boolean;
  time_zone: string;
  screenshare: boolean;
  is_simulated_live: boolean;
  is_disabled: boolean;
  is_static: boolean;
  is_produced: boolean;
  is_client_booking: boolean;
  is_mobile: boolean;
  is_mobile_kit: boolean;
  is_test: boolean;
  cue_sheet_enabled: boolean;
  production_notes?: {
    id?: number;
    notes?: (UpdateProductionNotes | undefined)[];
  };
  onboarding_location_address: onboardingAddress;
  shipping_zip_code: string;
  shipping_street_address_2: string;
  shipping_street_address_1: string;
  shipping_state: string;
  shipping_name: string;
  shipping_country: string;
  shipping_city: string;
  physical_zip_code: string;
  physical_street_address_2: string;
  physical_street_address_1: string;
  physical_state: string;
  physical_city: string;
  physical_country: string;
  physical_name: string;
  addOns: { addon: { id: string; name: string; type: string } }[];
  addon_notes: string;
  equipment_notes: string;
  is_refundable_equipment: boolean;
  graphics_software: string;
  mux_stream_id: string;
  mux_stream_key: string;
  mux_stream_url: string;
  jwt: string;
  is_static_plus: boolean;
  external_encoder: boolean;
}>;

export const normalizeWithOnboarding = (
  record: Partial<RecordWithOnboarding>
): Location | undefined => {
  let onboardingAddress;

  if (record.onboarding_location_address) {
    const installDateRequest = record.onboarding_location_address.install_date_request
      ? DateTime.fromISO(record.onboarding_location_address.install_date_request, UTC)
      : undefined;

    const idealSiteVisitDate = record.onboarding_location_address.ideal_site_visit_date
      ? DateTime.fromISO(record.onboarding_location_address.ideal_site_visit_date, UTC)
      : undefined;

    const idealFirstStreamDate = record.onboarding_location_address.ideal_first_stream_date
      ? DateTime.fromISO(record.onboarding_location_address.ideal_first_stream_date, UTC)
      : undefined;

    onboardingAddress = {
      id: record.onboarding_location_address.id ?? undefined,
      installationStreetAddress:
        record.onboarding_location_address.installation_street_address_1 ?? undefined,
      installationStreetAddress2:
        record.onboarding_location_address.installation_street_address_2 ?? undefined,
      installationCity: record.onboarding_location_address.installation_city ?? undefined,
      installationState: record.onboarding_location_address.installation_state ?? undefined,
      installationZipCode: record.onboarding_location_address.installation_zip_code ?? undefined,
      shippingStreetAddress:
        record.onboarding_location_address.shipping_street_address_1 ?? undefined,
      shippingStreetAddress2:
        record.onboarding_location_address.shipping_street_address_2 ?? undefined,
      shippingCity: record.onboarding_location_address.shipping_city ?? undefined,
      shippingState: record.onboarding_location_address.shipping_state ?? undefined,
      shippingZipCode: record.onboarding_location_address.shipping_zip_code ?? undefined,
      handlerInstallation: record.onboarding_location_address.handler_installation ?? undefined,
      installDateRequest,
      idealSiteVisitDate,
      idealFirstStreamDate,

      additionalInformation: record.onboarding_location_address.additional_information ?? undefined,
      shippingContactId: Number(record.onboarding_location_address.shipping_contact_id),
      onboardingMainContactId: Number(record.onboarding_location_address.onboarding_main_contactId),
      onboardingTechnicalContactId: Number(
        record.onboarding_location_address.onboarding_technical_contact_id
      ),
      locationId: record.id ?? undefined
    };
  }

  const productionNotes: ProductionNotes = {};

  if (record.production_notes) {
    productionNotes.locationId = record.id;
  }

  record.production_notes?.notes?.forEach((note) => {
    if (note?.category === 'camera_movements_preference') {
      productionNotes.camera_movements_preference = note.option;
    }

    if (note?.category === 'audience_shots') {
      productionNotes.audience_shots = note.option;
    }

    if (note?.category === 'close_up_shots') {
      productionNotes.close_up_shots = note.option;
    }

    if (note?.category === 'wide_venue_shots') {
      productionNotes.wide_venue_shots = note.option;
    }

    if (note?.category === 'detailed_venue_shots') {
      productionNotes.detailed_venue_shots = note.option;
    }

    if (note?.category === 'event_ending_preference') {
      productionNotes.event_ending_preference = note.option;
    }
  });

  const locationAddons: AddOn[] = [];

  if (record.addOns) {
    record.addOns.forEach((element) => {
      locationAddons.push(element.addon);
    });
  }

  const candidate = {
    id: Str.normalize(record.id),
    secondaryId: Num.normalize(record.secondary_id),
    name: Str.normalize(record.name),
    zoom: Bool.normalize(record.zoom),
    timezone: Str.normalize(record.time_zone),
    simulatedLive: Bool.normalize(record.is_simulated_live),
    screenshare: Bool.normalize(record.screenshare),
    isDisabled: Bool.normalize(record.is_disabled),
    isStatic: Bool.normalize(record.is_static),
    isProduced: Bool.normalize(record.is_produced),
    isClientBooking: Bool.normalize(record.is_client_booking),
    isMobile: Bool.normalize(record.is_mobile),
    isMobileKit: Bool.normalize(record.is_mobile_kit),
    isTest: Bool.normalize(record.is_test),
    isCueSheetEnabled: Bool.normalize(record.cue_sheet_enabled),
    productionNotes,
    onboardingLocationAddress: onboardingAddress ? onboardingAddress : {},
    isStaticPlus: Bool.normalize(record.is_static_plus),
    externalEncoder: Bool.normalize(record.external_encoder),
    ...(record.shipping_zip_code && { shippingZipCode: record.shipping_zip_code }),
    ...(record.shipping_street_address_1 && {
      shippingStreetAddress1: record.shipping_street_address_1
    }),
    ...(record.shipping_street_address_2 && {
      shippingStreetAddress2: record.shipping_street_address_2
    }),
    ...(record.shipping_state && { shippingState: record.shipping_state }),
    ...(record.shipping_name && { shippingName: record.shipping_name }),
    ...(record.shipping_country && { shippingCountry: record.shipping_country }),
    ...(record.shipping_city && { shippingCity: record.shipping_city }),
    ...(record.physical_zip_code && { physicalZipCode: record.physical_zip_code }),
    ...(record.physical_street_address_1 && {
      physicalStreetAddress1: record.physical_street_address_1
    }),
    ...(record.physical_street_address_2 && {
      physicalStreetAddress2: record.physical_street_address_2
    }),
    ...(record.physical_state && { physicalState: record.physical_state }),
    ...(record.physical_city && { physicalCity: record.physical_city }),
    ...(record.physical_country && { physicalCountry: record.physical_country }),
    ...(record.physical_name && { physicalName: record.physical_name }),
    ...(record.addon_notes && { addonNotes: record.addon_notes }),
    ...(record.addOns && { addOns: locationAddons }),
    ...(record.equipment_notes && { equipmentNotes: record.equipment_notes }),
    ...(record.is_refundable_equipment && {
      isRefundableEquipment: record.is_refundable_equipment
    }),
    ...(record.graphics_software && { graphicsSoftware: record.graphics_software }),
    ...(record.mux_stream_id && { muxStreamId: record.mux_stream_id }),
    ...(record.mux_stream_key && { muxStreamKey: record.mux_stream_key }),
    ...(record.mux_stream_url && { muxStreamUrl: record.mux_stream_url }),
    ...(record.jwt && { jwt: record.jwt })
    /*
     * shippingZipCode: record.shipping_zip_code ? Str.normalize(record.shipping_zip_code) : '',
     * shippingStreetAddress2: Str.normalize(record.shipping_street_address_1),
     * shippingStreetAddress1: Str.normalize(record.shipping_street_address_2),
     * shippingState: Str.normalize(record.shipping_state),
     * shippingName: Str.normalize(record.shipping_name),
     * shippingCountry: Str.normalize(record.shipping_country),
     * shippingCity: Str.normalize(record.shipping_city),
     * physicalZipCode: Str.normalize(record.physical_zip_code),
     * physicalStreetAddress2: Str.normalize(record.physical_street_address_1),
     * physicalStreetAddress1: Str.normalize(record.physical_street_address_2),
     * physicalState: Str.normalize(record.physical_state),
     * physicalCity: Str.normalize(record.physical_city),
     * physicalCountry: Str.normalize(record.physical_country),
     * physicalName: Str.normalize(record.physical_name),
     * addOns: locationAddons,
     * addonNotes:Str.normalize(record.addon_notes)
     */
  };

  return Obj.isHydrated(candidate) ? candidate : undefined;
};

export const StandardLocationResponseWithOnboarding = gql`
  fragment StandardLocationResponse on Location {
    id
    secondary_id
    name
    zoom
    time_zone
    screenshare
    is_simulated_live
    is_disabled
    is_static
    is_produced
    is_client_booking
    is_mobile
    is_mobile_kit
    is_test
    cue_sheet_enabled
    production_notes {
      id
      notes {
        option
        content
        category
      }
    }
    shipping_zip_code
    shipping_street_address_2
    shipping_street_address_1
    shipping_state
    shipping_name
    shipping_country
    shipping_city
    physical_zip_code
    physical_street_address_2
    physical_street_address_1
    physical_state
    physical_city
    physical_country
    jwt
    addOns {
      addon {
        id
        name
        type
      }
    }
    addon_notes
    equipment_notes
    is_refundable_equipment
    graphics_software
    mux_stream_id
    mux_stream_key
    mux_stream_url
    is_static_plus
    external_encoder
    onboarding_location_address {
      id
      installation_street_address_1
      installation_street_address_2
      installation_city
      installation_state
      installation_zip_code
      shipping_street_address_1
      shipping_street_address_2
      shipping_city
      shipping_state
      shipping_zip_code
      handler_installation
      install_date_request
      ideal_site_visit_date
      ideal_first_stream_date
      additional_information
    }
    contactRoles {
      onboarding_main
      onboarding_technical
      shipping
    }
  }
`;
