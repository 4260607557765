import { Device } from '@livecontrol/core-ui';
import deepFreeze from 'deep-freeze';

export const Blueprint = deepFreeze({
  Header: {
    height: {
      [Device.MOBILE]: 64,
      [Device.DESKSTOP]: 80
    }
  },
  Sidebar: {
    width: {
      collapsed: 60,
      expanded: 200
    }
  }
});
