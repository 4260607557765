import type { Asset } from '@livecontrol/scheduler/model';
import cx from 'classnames';
import { useState } from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';
import { Event } from '../../../../components';

type Props = HTMLAttributes<HTMLDivElement> & {
  asset: Asset;
};

const StyledToggle = styled(Dropdown.Toggle)`
  &.dropdown-toggle.btn::after {
    display: none;
  }
`;

export const ShareLink = ({ className, asset, ...props }: Props): ReactElement => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <StyledToggle
        variant='outline-dark'
        {...props}
        onClick={(): void => {
          setShowModal(true);
        }}
        className={cx(
          'py-0px px-8px mt-8px align-self-start',
          'font-size-12px text-uppercase',
          className
        )}
      >
        <i className='far fa-share-alt' />
        <span>Share Link</span>
      </StyledToggle>

      <Event.ShareLinkModal
        showModal={showModal}
        onHide={(): void => {
          setShowModal(false);
        }}
        password={asset.eventPassword}
        event={asset.event}
      />
    </>
  );
};
