import { Num } from '@livecontrol/core-utils';
import type { HTMLAttributes, PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: ${(props: AspectRatio.Props): string =>
    `calc(1 / ${Num.normalize(props.ratio, { positive: true }) ?? 1} * 100%)`};

  > div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

export const AspectRatio = ({ children, ...rest }: AspectRatio.Props): ReactElement => (
  <Container {...rest}>
    <div>{children}</div>
  </Container>
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace AspectRatio {
  export type Props = PropsWithChildren<HTMLAttributes<HTMLDivElement> & { ratio?: number }>;
}
