import { Theme } from '@livecontrol/core-ui';
import { Matrix } from '@livecontrol/scheduler/components';
import type { Log } from '@livecontrol/scheduler/model';
import type { Zone } from 'luxon';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Action } from './action';

import G = Matrix.Grid;
import H = G.Header;
import C = G.Row.Cell;

const iana = ['HST', 'AKST', 'MST', 'CST', 'EST', 'PST'];

const GridContainer = styled.div`
  .header {
    color: #8798ad;
  }

  .row {
    div {
      border-color: #d5daea !important;
    }
  }
`;

const StyledSelect = styled.select`
  height: 25px;
  border-color: #8798ad;
  border-radius: 4px;
  outline: #8798ad auto 1.5px;
  color: #8798ad;
  font-weight: 600;
`;

export const Grid = ({ logs, timezone }: { logs: Log[]; timezone: Zone }): React.ReactElement => {
  const viewport = Theme.useViewport();

  const getTimezoneAbbr = (timezone_: Zone): string => {
    let timeZoneAbbr = DateTime.local().set({ month: 1 }).setZone(timezone_).toFormat('ZZZZ');

    if (timeZoneAbbr === 'HAST') {
      timeZoneAbbr = 'HST';
    }
    return timeZoneAbbr;
  };

  const [selected, setSelected] = useState(getTimezoneAbbr(timezone));

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setSelected(event.target.value);
  };

  return (
    <GridContainer>
      <G viewport={viewport} gridTemplateColumns='2fr 4fr 2fr 2fr 1fr 2fr 2fr'>
        {/* Header */}
        <G.Row className='header'>
          <H viewport={viewport} className='font-size-12px font-weight-bold'>
            Action
          </H>
          <H viewport={viewport} className='font-size-12px font-weight-bold'>
            Message
          </H>
          <H viewport={viewport} className='font-size-12px font-weight-bold'>
            Performed by
          </H>
          <H viewport={viewport} className='font-size-12px font-weight-bold'>
            Credits Change
          </H>
          <H viewport={viewport} className='font-size-12px font-weight-bold'>
            Balance
          </H>
          <H viewport={viewport} className='font-size-12px font-weight-bold'>
            Date
          </H>
          <H viewport={viewport} className='font-size-12px font-weight-bold'>
            <StyledSelect value={selected} onChange={handleChange}>
              {iana.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </StyledSelect>
          </H>
        </G.Row>

        {
          /* Content */
          logs.map(
            (log, index): React.ReactElement => (
              // eslint-disable-next-line react/no-array-index-key
              <G.Row key={index} className='row'>
                <C className='rounded-left font-size-12px font-weight-bold text-dark'>
                  {log.action ? <Action action={log.action} /> : null}
                </C>
                <C className='font-size-12px font-weight-bold text-dark'>{log.message}</C>
                <C className='font-size-12px font-weight-bold text-dark'>{log.performedBy}</C>
                <C className='font-size-12px font-weight-bold text-dark'>{log.credits_used}</C>
                <C className='font-size-12px font-weight-bold text-dark'>{log.current_balance}</C>
                <C className='rounded-right font-size-12px font-weight-bold text-dark'>
                  {log.date
                    ?.setZone(selected === 'AKST' ? 'America/Anchorage' : 
                              selected === 'PST' ? 'America/Los_Angeles' :
                              selected === 'CST' ? 'America/Chicago' : selected)
                    .toLocaleString(DateTime.DATE_SHORT)}{' '}
                  {log.date
                    ?.setZone(selected === 'AKST' ? 'America/Anchorage' : 
                              selected === 'PST' ? 'America/Los_Angeles' :
                              selected === 'CST' ? 'America/Chicago' : selected)
                    .toLocaleString(DateTime.TIME_SIMPLE)}
                </C>
                <C className='rounded-right font-size-12px font-weight-bold text-dark'>
                  {selected === 'AKST' ? 'AKST' : 
                  selected === 'PST' ? 'PST' :
                  selected === 'CST' ? 'CST' : 
                  log.date?.setZone(selected).toFormat('z')}
                </C>
              </G.Row>
            )
          )
        }
      </G>
    </GridContainer>
  );
};
