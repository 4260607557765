import { Str } from '@livecontrol/core-utils';
import _ from 'lodash';
import type { Asset, Event, EventType, Location, Period } from './types';
import { Sorting } from './types';

export namespace Utils {
  export const sort = (assets: readonly Asset[], sorting: Sorting): readonly Asset[] => {
    const { key, direction } = sorting;

    const sorted = _.sortBy([...assets], (asset): number | string =>
      key === 'title'
        ? asset.title.toLowerCase()
        : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        key === 'timestamp'
        ? asset.timestamp.toSeconds()
        : asset.id
    );

    if (direction === Sorting.Direction.DESCENDING) {
      sorted.reverse();
    }

    return sorted;
  };

  export const filterByKeyword = (
    assets: readonly Asset[],
    value: string | undefined
  ): readonly Asset[] => {
    const keyword = Str.normalize(value)?.toLowerCase();

    return keyword
      ? assets.filter((asset: Asset): boolean => {
          const thumbprint = [asset.id, asset.description, asset.title, asset.event]
            .filter((µ) => !!µ)
            .join(' ')
            .toLowerCase();

          return thumbprint.includes(keyword);
        })
      : assets;
  };

  export const filterByPeriod = (
    assets: readonly Asset[],
    value: Period | undefined
  ): readonly Asset[] => {
    const { min, max } = value ?? {};

    return min && max
      ? assets.filter((asset: Asset): boolean => {
          const t = asset.timestamp.toJSDate();

          return t >= min && t <= max;
        })
      : assets;
  };

  export const filterByEventType = (
    assets: readonly Asset.withEvent[],
    value: EventType | undefined
  ): readonly Asset.withEvent[] => {
    const filteredAssets = value
      ? assets.filter((asset) => asset.eventData?.type === value.key)
      : assets;

    return filteredAssets;
  };

  export const filterByLocation = (
    assets: readonly Asset.withEvent[],
    value: Location | Location[] | undefined
  ): readonly Asset.withEvent[] => {
    let filteredAssets = assets;

    if (Array.isArray(value)) {
      const assetsfiltered: Asset.withEvent[] = [];

      value.forEach((location) => {
        assets.forEach((asset) => {
          if (asset.eventData?.location.id === location.id) {
            assetsfiltered.push(asset);
          }
        });
      });

      filteredAssets = assetsfiltered;
    } else {
      filteredAssets = value
        ? assets.filter((asset) => asset.eventData?.location.id === value.id)
        : assets;
    }

    return filteredAssets;
  };

  export const getUniqueLocations = (events: readonly Event[]): Location[] => {
    const locations = new Map<string, Location>();

    events.forEach((event: Event): void => {
      const { location } = event;

      if (!locations.has(location.id)) {
        locations.set(location.id, location);
      }
    });

    return [...locations.values()];
  };
}
