import * as Dbx_ from './dbx';
import * as Plaid_ from './plaid';
import * as S3_ from './s3';
import * as Stripe_ from './stripe';
import * as UseAccount from './use-account';
import * as UseBilling from './use-billing';
import * as UseClientBookingPayment from './use-client-booking-payment';
import * as UseEdit from './use-edit';
import * as UseFeatures from './use-features';
import * as UseInvoice from './use-invoice';
import * as UsePurchase from './use-purchase';
import * as UserManagement_ from './user-management';
import * as WebPlayer_ from './web-player';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export import Dbx = Dbx_;

export const Plaid = Plaid_;
export const S3 = S3_;
export const Stripe = Stripe_;
export const WebPlayer = WebPlayer_;
export const UserManagement = UserManagement_;
export const { useAccount: useFetchAccount } = UseAccount;
export const { useBilling } = UseBilling;
export const { useBillingForced } = UseBilling;
export const { useClientBookingPayment } = UseClientBookingPayment;
export const { useEdit } = UseEdit;
export const { useFeatures } = UseFeatures;
export const { useInvoice } = UseInvoice;
export const { usePurchase } = UsePurchase;
