// import { Str } from '@livecontrol/core-utils';
import type { Location as T_Location } from '@livecontrol/scheduler/model';
import type { ReactElement } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import type { DropdownButtonProps } from 'react-bootstrap/DropdownButton';
import DropdownButton from 'react-bootstrap/DropdownButton';

export const UserRestrictedLocation = (props: UserRestrictedLocation.Props): ReactElement => {
  const {
    value: { selected, available },
    onChange,
    ...rest
  } = props;

  const title = selected?.name ?? (
    <>
      <img className='mr-8px' src='assets/icons/filter.svg' alt='filter' />
      <span className='font-weight-bold font-size-13px'>All Locations</span>
    </>
  );

  return (
    <DropdownButton
      variant={selected ? 'tint' : 'outline-tertiary'}
      disabled={available.length < 2}
      title={title}
      {...rest}
    >
      <Dropdown.Item
        key='all'
        onClick={(): void => {
          onChange?.(undefined);
        }}
      >
        All Locations
      </Dropdown.Item>

      {[...available]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((location: T_Location): ReactElement => {
          const active = location.id === selected?.id;

          return (
            <Dropdown.Item
              key={location.id}
              active={active}
              onClick={(): void => {
                onChange?.(!active ? location : undefined);
              }}
            >
              {location.name}
            </Dropdown.Item>
          );
        })}
    </DropdownButton>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace UserRestrictedLocation {
  export interface Value {
    available: readonly T_Location[];
    selected?: T_Location;
  }

  export type Props = Omit<DropdownButtonProps, 'onChange' | 'title'> & {
    value: Value;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    locations?: any;
    onChange?: (value?: T_Location) => void;
  };
}
