import { Glyph } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import { Scheduler } from '@livecontrol/scheduler/store';
import nextTick from 'next-tick';
import { useCallback } from 'react';
import type { Event } from '../store';

export const useStop = (): ((event: Event) => void) => {
  const [mutation] = Scheduler.Event.useStop();

  const prompt = Modal.usePrompt();

  return useCallback(
    (event: Event): void => {
      prompt.confirm({
        title: 'Stop Live Event?',
        content: 'This event is currently in progress. Are you sure you want to stop it?',
        icon: (
          <Glyph className='text-white bg-danger'>
            <i className='fas fa-exclamation' />
          </Glyph>
        ),
        okay: {
          label: 'Stop Event',
          variant: 'danger'
        },
        onAnswer: (answer: boolean): void => {
          if (answer) {
            nextTick(() => {
              void mutation({
                event
              });
            });
          }
        }
      });
    },
    [mutation, prompt]
  );
};
