import { Bool, Keyed, Num, Time } from '@livecontrol/core-utils';
import type { Any } from '@livecontrol/core-utils';
import EventEmitter from 'eventemitter3';

/**
 * Used to detect when one or more resources has not responded within a given time interval (the `timeout`).
 * When started, this class starts a timer that fires every `period` seconds.  When the timer fires,
 * the class checks that the `tick` method has been called within the specified `timeout`.
 * If not, the class assumes that the monitored resource has stalled and a `timed-out` event is emitted.
 */
export class Heartbeat extends EventEmitter<'polling' | 'timeout'> {
  private readonly period: Time.Encoding.MILLISECONDS;
  private readonly timeout: Time.Encoding.MILLISECONDS;

  // NodeJS.setInterval and window.setInterval return different types - https://bit.ly/3sF6g27
  private timerId: Any | undefined;

  protected resources = new Map<Keyed.Key, Time.Encoding.MILLISECONDS>();

  /**
   * Constructs a heartbeat object.
   *
   * `period` - The heartbeat timer polling period (in milliseconds - default 30s).  A `polling` event is
   * emitted each time the heartbeat timer is triggered.
   *
   * `timeout` - The maximum length of time (in milliseconds) between heartbeats whereby a `timeout` event
   * will be emitted. By default the timeout is 2.2 times the polling period.
   *
   * @param args - See above.
   */
  public constructor(
    args?:
      | Partial<{
          period: Time.Encoding.MILLISECONDS;
          timeout: Time.Encoding.MILLISECONDS;
          autoStart: boolean;
        }>
      | undefined
  ) {
    super();

    this.period = Num.toInteger(args?.period, { positive: true }) ?? Heartbeat.DEFAULT_PERIOD;

    this.timeout =
      Num.toInteger(args?.timeout, { positive: true }) ?? this.period * Heartbeat.TIMEOUT_FACTOR;

    if (Bool.normalize(args?.autoStart)) {
      this.start();
    }
  }

  /**
   * Start the heartbeat timer.  By default, the heartbeat timer is not automatically started until the first
   * resource is monitored.  Call this method manually if you want to restart a stopped timer or to initially
   * start the timer even if no resources are being monitored.
   *
   * When started, the each monitored resource is ticked.
   */
  public start(): void {
    // Is the interval already running?
    if (!this.isRunning) {
      this.timerId = setInterval(this.onPolling.bind(this), this.period);
    }

    // Ticks all of the monitored resources.
    this.resources.forEach((_: unknown, key: Keyed.Key) => {
      this.tick(key);
    });
  }

  /**
   * Stop the heartbeat timer.  The timer will remain stopped until a) the `start` method is called or
   * 2) a new resource is monitored.
   */
  public stop(): void {
    if (this.isRunning) {
      clearInterval(this.timerId);

      this.timerId = undefined;
    }
  }

  /**
   * Indicates if the heartbeat timer is running.
   *
   * @returns `true` if the heartbeat timer is running, `false` otherwise.
   */
  public get isRunning(): boolean {
    return !!this.timerId;
  }

  /**
   * Called by the client when a heartbeat is detected for the specified resource.
   * If the specified resource has not been explicitly monitored (via a call to `monitor`),
   * then no operation is performed.
   *
   * @param resource - The key of the resource to be ticked.
   * @returns `true` if the resource is being monitored and was ticked.
   */
  public tick(resource: Keyed.Like): boolean {
    const { resources } = this;

    let b = false;

    const key = Keyed.toKey(resource);

    if (resources.has(key)) {
      this.resources.set(key, Time.now().epochMillis);

      b = true;
    }

    return b;
  }

  /**
   * Adds a resource to be monitored.  If the resource was previously added, its timestamp
   * is updated.  If the timer is not already running, it will automatically be started.
   *
   * @param resource - The key of the resource to be monitored.
   */
  public monitor(resource: Keyed.Like): void {
    this.resources.set(Keyed.toKey(resource), Time.now().epochMillis);
  }

  /**
   * Unmonitors the specified resource.  When no more resources are being monitored, the timer
   * is automatically stopped.
   *
   * @param resource - The key of the resource to be unmonitored.
   * @returns `true` if the resource was successfully unmonitored.
   */
  public unmonitor(resource: Keyed.Like): boolean {
    const b = this.resources.delete(Keyed.toKey(resource));

    // Stop the timer if there is nothing left to monitor
    if (!this.resources.size) {
      this.stop();
    }

    return b;
  }

  /**
   * Resets the heartbeat.  All monitored resources will be removed and the timer will be stopped.
   */
  public reset(): void {
    this.resources.clear();
    this.stop();
  }

  protected onPolling(): void {
    const { resources, timeout } = this;

    this.emit('polling');

    // Check each resource for timeouts
    resources.forEach((timestamp: Time.Encoding.EPOCH_MILLIS, key: Keyed.Key) => {
      // Have we received a keepalive within the timeout?
      if (timestamp > Time.now().epochMillis + Math.round(timeout)) {
        this.emit('timeout', key);

        // Reset the timestamp
        this.tick(key);
      }
    });
  }
}

class Unary_ extends EventEmitter<'timeout'> {
  private readonly heartbeat: Heartbeat;

  private static readonly PROXY = '__interval';

  public constructor(...args: ConstructorParameters<typeof Heartbeat>) {
    super();

    this.heartbeat = new Heartbeat(...args);

    // Forward any timeout events
    this.on('timeout', () => {
      this.emit('timeout');
    });
  }

  public start(): void {
    this.heartbeat.start();
    this.heartbeat.monitor(Unary_.PROXY);
  }

  public stop(): void {
    this.heartbeat.stop();
  }

  public get isRunning(): boolean {
    return this.heartbeat.isRunning;
  }

  public tick(): void {
    this.heartbeat.tick(Unary_.PROXY);
  }
}

export namespace Heartbeat {
  export const DEFAULT_PERIOD = 3e4; // 30 seconds
  export const TIMEOUT_FACTOR = 2.25;

  /** Utility class to monitor a single resource */
  export class Unary extends Unary_ {}
}
