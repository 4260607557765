import { User } from '@livecontrol/scheduler/model';
import type { FormikContextType } from 'formik';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { Alert, Store } from '../../../../store';

const { Constraints } = User;

export interface Values {
  newPassword: string;
  oldPassword: string;
  passwordConfirmation: string;
  id: number;
}

interface Form {
  formik: FormikContextType<Values>;
  loading: boolean;
  success: boolean;
  alert: Alert;
}

interface Props {
  user: User;
}

const validationSchema = Yup.object({
    oldPassword: Yup.string()
    .trim()
    .min(
      Constraints.PASSWORD_MIN_LENGTH,
      `Your password must be at least ${Constraints.PASSWORD_MIN_LENGTH} characters long.`
    )
    .required('A valid password is required.'),
   newPassword: Yup.string()
    .trim()
    .min(
      Constraints.PASSWORD_MIN_LENGTH,
      `Your password must be at least ${Constraints.PASSWORD_MIN_LENGTH} characters long.`
    )
    .required('A valid password is required.'),
    passwordConfirmation: Yup.string()
    .trim()
    .required('Please confirm your password.')
    .equals([Yup.ref('newPassword')], 'Your passwords do not match.')
});

export const useForm = ({  user }: Props): Form => {
  const [updatePassword, { loading, error }] = Store.User.useUpdatePassword();
  const alert = Alert.useAlert(error);
  const [success, setSuccess] = useState(false);

  const formik = useFormik<Values>({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      passwordConfirmation: '',
      id: user.id
    },

    validationSchema,
    validateOnMount: true,

    validate(): void {
      alert.hide();
    },

    async onSubmit(data: Values): Promise<void> {
        setSuccess(await updatePassword(data));
    }

  });

  return {
    formik,
    loading,
    success,
    alert
  };
};
