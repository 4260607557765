import { Theme } from '@livecontrol/core-ui';
import { TableGrid } from '@livecontrol/scheduler/components';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const TableStyledWrapped = styled.div`

  ol {
    grid-row-gap: 10px;
  }

  .th {
    color: #8798ad;
    font-weight: 600;
    font-size: var(--font-size-15px);
  }

  .cell {
    color: var(--dark);
    font-weight: 600;
    font-size: var(--font-size-15px);

    border-width: 1px 0;
    border-style: solid;
    border-color: #D5DAEA;

    &:first-child {
        border-left-width: 1px;
        padding-left: var(--matrix-padding-x);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    &:last-child {
        justify-content: flex-end;
        border-right-width: 1px;
        padding-right: var(--matrix-padding-x);
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
  }


`;

interface Body {
  text: string;
  id: string;
  action?: JSX.Element;
}

export const Active = (): React.ReactElement => {
  const viewport = Theme.useViewport();

  const showMessage = (id: string): void => {
    console.log('this is a function', id);
  };

  const headers = [
    {
      title: 'CID',
      id: '1'
    },
    {
      title: 'Organization',
      id: '2'
    },
    {
      title: 'Account Owner',
      id: '3'
    },

    {
      title: '',
      id: '4'
    }
  ];

  const bodys: Body[][] = [
    [
      {
        text: '6',
        id: '5'
      },
      {
        text: 'Temple Judea',
        id: '6'
      },
      {
        text: 'Sherri Holzer',
        id: '7'
      }
    ],
    [
      {
        text: '7',
        id: '9'
      },
      {
        text: 'Temple Judea',
        id: '10'
      },
      {
        text: 'Sherri Holzer',
        id: '11'
      }
    ],
    [
      {
        text: '8',
        id: '13'
      },
      {
        text: 'Temple Judea',
        id: '14'
      },
      {
        text: 'Sherri Holzer',
        id: '15'
      }
    ]
  ];

  const [bodyList, setBodyList] = useState(bodys);

  useEffect(() => {

    setBodyList((oldState): Body[][] => oldState.map((body): Body[] => {
        body.push({
          id: uuidv4(),
          text: '',
          action: (
            <button type='button' onClick={(): void => {
              showMessage(uuidv4());
            }} className='btn btn-primary'>
            test
          </button>
          )
        });

        return body;
      }));

  }, []);


  const gridTemplateColumns = 'repeat(3, 1fr) 400px';

  return (
    <TableStyledWrapped>

      <TableGrid
        viewport={viewport}
        gridTemplateColumns={gridTemplateColumns}
        bodys={bodyList}
        headers={headers}
      />

    </TableStyledWrapped>
  );
};
