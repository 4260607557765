import { Modal } from '@livecontrol/scheduler/components';
import { Asset } from '@livecontrol/scheduler/model';
import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

const Icon = styled.img`
  height: 40px;
  width: 40px;
  display: inline-grid;
  place-items: center;
  margin-botton: 16px;
`;

const ModalFormSection = styled.div`
  display: flex;
  justify-content: center;
  justify-content: center;
  & > div {
    max-width: 425px;
  }
`;

const Description = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #9c9c9c;
`;


const { Public } = Asset.Visibility;

interface Props {
  visibility: Asset.Visibility;
  onConfirm: (commit: boolean) => void;
}

export const Confirm = ({ visibility, onConfirm }: Props): React.ReactElement => {
  const [from, to] =
    visibility === Public ? ['private', 'public', 'eye'] : ['public', 'private', 'eye-slash'];

  return (

    <Modal show okay={`Make ${_.upperFirst(to)}`} onHide={_.partial(onConfirm, false)} onOkay={ _.partial(onConfirm, true)} onCancel={_.partial(onConfirm, false)} >
      <div className='d-flex justify-content-center'>
        {
          visibility === Asset.Visibility.Public ?
            <Icon src='/assets/icons/Eye-blue.svg' alt='file' />
          : <Icon src='/assets/icons/Eye-slash-blue.svg' alt='file' />
        }
      </div>
      <Modal.Header closeButton>
        <h1>{`Make your video ${to}?`}</h1>
      </Modal.Header>
      <Modal.Body>
        <ModalFormSection>
          <Description className='mb-24px'>
            By making your video {visibility === Public ? 'public anyone' : 'private no one'} will be able to see your video. You can always change your video back to {from} at anytime.
            Please allow a few minutes for this privacy setting change to take effect.
          </Description>
        </ModalFormSection>

      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonBox>
          <Modal.Button onClick={ _.partial(onConfirm, true)} variant='primary'>
            {`Make ${_.upperFirst(to)}`}
          </Modal.Button>
          <Modal.Button variant='outline-secondary' onClick={ _.partial(onConfirm, false)}>
            Cancel
          </Modal.Button>
        </Modal.ButtonBox>
      </Modal.Footer>


  </Modal>
  );
};

