import { Num } from '@livecontrol/core-utils';
import { Modal } from '@livecontrol/scheduler/components';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { FormikConfig } from 'formik';
import * as Yup from 'yup';

export interface Props {
  feedbackID?: number;
  onSuccess: (value: Value) => void;
  initialValues?: Value;
}

export interface Value {
  issuesVideographer?: string[];
  videographer_thumb?: boolean;
  issuesTech?: string[];
  technical_thumb?: boolean;
  videographer_extra_comment?: string;
  technical_extra_comment?: string;
}

interface Form {
  formik: FormikConfig<Value>;
  loading: boolean;
}

const validationSchema = Yup.object({
  videographer_thumb: Yup.boolean().required('Please select a option'),
  technical_thumb: Yup.boolean().required('Please select a option'),
  issuesVideographer: Yup.array(),
  issuesTech: Yup.array(),
  videographer_extra_comment: Yup.string(),
  technical_extra_comment: Yup.string()
});

export const useForm = ({ feedbackID, onSuccess, initialValues }: Props): Form => {
  const [upsertFeedback, { loading }] = Scheduler.Event.useUpsertFeedback();
  const { error } = Modal.usePrompt();

  const formik = {
    initialValues: { ...initialValues },
    validationSchema,
    validateOnMount: true,

    onSubmit(value: Value): void {
      const issuesVideo = value.issuesVideographer?.reduce((a, v) => ({ ...a, [v]: true }), {});
      const issuesTech = value.issuesTech?.reduce((a, v) => ({ ...a, [v]: true }), {});

      const data = {
        ...issuesVideo,
        ...issuesTech,
        videographer_thumb: value.videographer_thumb,
        technical_thumb: value.technical_thumb,
        videographer_extra_comment: value.videographer_extra_comment,
        technical_extra_comment: value.technical_extra_comment
      };

      if (!loading) {
        void upsertFeedback({
          ...data,
          event_id: Num.normalize(feedbackID)
        })
          .then(() => {
            onSuccess(value);
          })
          .catch(() => {
            error({
              title: 'Oops!',
              content: 'Something went wrong. Please try again later'
            });
          });
      }
    }
  };

  return {
    formik,
    loading
  };
};
