import { ClickableCard } from '@livecontrol/core-ui';
import cx from 'classnames';
import React, { useCallback } from 'react';
import styled from 'styled-components';

const ModalBody = styled.div`
  display: flex;
  flex-wrap: wrap;

  .labels-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 767.98px) {
      .label-entry {
        width: 100% !important;
      }
    }

    .label-entry {
      width: 44%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 14px;
      border: 1px solid #d5daea;

      &.selected {
        border: 2px solid #2e5bff;
      }

      .btn-secondary {
        background-color: #d5daea;
      }

      .btn {
        min-width: 122px;
        min-height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
      }
    }
  }
`;

export const DONATION_LABELS = {
  GIVE: 'Give',
  DONATE: 'Donate',
  CUSTOM: 'Custom'
};

const Label = ({
  label,
  selected,
  className,
  onClick
}: {
  label: string;
  selected?: boolean;
  className?: string;
  onClick?: () => void;
}): React.ReactElement => (
  <ClickableCard.Button
    status={selected ? ClickableCard.Status.Active : undefined}
    className={cx(className, selected ? 'selected' : '')}
    onClick={onClick}
  >
    <div
      className={cx('py-32px m-0 d-flex flex-column justify-content-center align-items-center')}
      style={{ width: 250 }}
    >
      <div className={cx('btn', selected ? 'btn-primary' : 'btn-secondary')}>{label}</div>
    </div>
  </ClickableCard.Button>
);

export const DonationLabels = ({
  label = DONATION_LABELS.GIVE,
  onChange
}: {
  label?: string;
  onChange?: (label: string) => void;
}): React.ReactElement => {
  // console.log("label 2: ",label);
  const onDonationLabelUpdate = useCallback(
    (value: string) => (): void => {
      onChange?.(value);
    },
    [onChange]
  );

  return (
    <ModalBody>
      <div className='labels-container justify-content-center justify-content-sm-start'>
        <Label
          className='label-entry'
          onClick={onDonationLabelUpdate(DONATION_LABELS.GIVE)}
          label='Give'
          selected={label === DONATION_LABELS.GIVE}
        />
        <Label
          className='label-entry'
          onClick={onDonationLabelUpdate(DONATION_LABELS.DONATE)}
          label='Donate'
          selected={label === DONATION_LABELS.DONATE}
        />
        <Label
          className='label-entry'
          onClick={onDonationLabelUpdate(DONATION_LABELS.CUSTOM)}
          label='Custom Button'
          selected={label !== DONATION_LABELS.GIVE && label !== DONATION_LABELS.DONATE}
        />
      </div>
    </ModalBody>
  );
};
