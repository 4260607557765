import type { ApolloError, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { EmailAddress, Num, Obj, Str } from '@livecontrol/core-utils';
import { Phone } from '@livecontrol/locale-us';
import { Account, Contact, Location } from '@livecontrol/scheduler/model';
import { useCallback, useState } from 'react';
import { Errors } from '../graphql';
import type { MutationResult } from '../graphql';
import * as Dbx from './dbx';

interface TVariables {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  user: number;
  notes?: string;
  priority?: number;
  phone_type?: string;
  preferred_contact_method?: string;
  location?: string;
}

interface TData {
  updateContact: Dbx.Record;
}

type Args = Pick<Contact, 'email' | 'firstName' | 'id' | 'lastName' | 'location' | 'notes' | 'phone' | 'phoneType' | 'preferredContactMethod' | 'priority'> & {
  account: Account.Like;
};

const MUTATION = gql`
  mutation EditContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      ...StandardContactResponse
    }
  }
  ${Dbx.StandardContactResponse}
`;

export const useEdit = (): [
  (args: Args) => Promise<Contact | undefined>,
  MutationResult<Contact, 'contact'>
] => {
  const [mutation, result] = useMutation<TData, { input: TVariables }>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [contact, setContact] = useState<Contact | undefined>();

  return [
    useCallback(
      async (args: Args): Promise<Contact | undefined> => {
        let contact_: Contact | undefined;

        try {
          // Parse the input arguments
          const input = {
            id: Contact.toId(args.id),
            first_name: Str.normalize(args.firstName),
            last_name: Str.normalize(args.lastName),
            email: EmailAddress.normalize(args.email),
            phone_number: Phone.normalize(args.phone)?.format(),
            user: Account.toId(args.account)
          };

          if (!Obj.isHydrated(input)) {
            throw Errors.badRequest();
          }

          // Execute the GraphQL mutation
          const response = await mutation({
            variables: {
              input: {
                ...input,
                notes: Str.normalize(args.notes) ?? '',
                location:Location.toId(args.location?.id),
                phone_type: Str.normalize(args.phoneType),
                preferred_contact_method: Str.normalize(args.preferredContactMethod),
                priority: Num.normalize( args.priority)
              }
            }
          })
            .then(({ data }: FetchResult<TData>) => data?.updateContact)
            .catch((_error: ApolloError) => {
              throw Errors.serverError();
            });

          // Parse the server response
          contact_ = Dbx.normalize(response);

          if (!contact_) {
            throw Errors.serverError();
          }
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setContact(contact_);

        return contact_;
      },
      [mutation, setError, setContact]
    ),
    {
      contact,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
