import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Actions } from './actions';

const HeaderBar = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  margin-top: 6px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d5daea;
  padding-bottom: 12px;

  .title {
    display: flex;
    font-size: 28px;
    font-weight: 700;
    margin-right: 14px;
    margin-bottom: 10px;

    h1 {
      margin: 0px;
    }
  }
  @media (max-width: 575px) { 
    flex-direction:column;
  }
`;

export const Header = (): ReactElement => (
  <HeaderBar>
    <div className='title'>
      <h1>Web Player Preferences</h1>
    </div>
    <Actions />
  </HeaderBar>
);
