import cx from 'classnames';
import React from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import { Device } from '../../device';
import { Theme } from '../../theme';
import { Grid } from '../grid';
import { Ornamentation } from './ornamentation';

export const Layout = ({ className, children, version }: Layout.Props): ReactElement => {
  const viewport = Theme.useViewport();
  const isDesktop = Device.isDesktop(viewport);

  return (
    <Grid
      className={cx('min-vh-100', 'bg-light', className)}
      gridTemplateColumns={isDesktop ? '.6fr .4fr' : '1fr'}
    >
      <div className='d-flex flex-column'>
        <div className='p-32px my-auto'>
          <img
            style={{ width: '160px' }}
            className='d-block d-lg-none mx-auto mb-24px'
            src='assets/entrance-dark.svg'
            alt='LiveControl Logo'
          />

          {children}

          <div className='mt-48px text-center'>
            <a className='text-secondary font-size-13px' href='https://www.livecontrol.io'>
              &laquo; Return to the LiveControl Home Page.
            </a>
          </div>
        </div>
        {version && <div className='pl-16px pb-16px'>
          <span style={{ color: '#9B9B9B' }}>v{ version }</span>
        </div>}
      </div>
      {isDesktop && <Ornamentation />}
    </Grid>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Layout {
  export type Props = React.PropsWithChildren<HTMLAttributes<HTMLDivElement>> & { version?: string };
}
