import { Button } from '@livecontrol/core-ui';
import type { Event } from '@livecontrol/scheduler/model';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FooterSection, HeaderSection } from '../layout-sections';
import { ClientForm } from './form';

export const ClientContact = ({
  skippable,
  onUpdate,
  onBack,
  onSkip,
  initialValue
}: {
  skippable?: boolean;
  onSkip?: () => void;
  onBack: () => void;
  onUpdate: (v?: Event.ClientContactDetails) => void;
  initialValue?: Event.ClientContactDetails;
}): React.ReactElement => {
  const currentLocation = useLocation();

  return (
    <>
      <HeaderSection>
        <div className='title'>
          <div className='text'>Client contact information </div>
        </div>
        <div className='description'>
          Fill out the form below if you would like us to send the event link and details to your
          client’s preferred point of contact.
        </div>
      </HeaderSection>

      <ClientForm initialValues={initialValue} onSubmit={onUpdate} />

      <FooterSection>
        {skippable ? (
          <div className='links-section'>
            <Link
              to={currentLocation.pathname}
              className='text-secondary custom-primary-link'
              onClick={onBack}
            >
              Go Back
            </Link>
            <Link
              to={currentLocation.pathname}
              className='text-secondary custom-primary-link'
              onClick={onSkip}
            >
              Skip
            </Link>
          </div>
        ) : (
          <Button className='mt-32px text-secondary w-100' variant='link' onClick={onBack}>
            Go back
          </Button>
        )}
      </FooterSection>
    </>
  );
};
