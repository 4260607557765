import { Device } from '@livecontrol/core-ui';
import { Num } from '@livecontrol/core-utils';
import cx from 'classnames';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Context } from '../../store';
import { Desktop } from './desktop';
import { Mobile } from './mobile';

const transition = (property: string): string => `${property} 500ms cubic-bezier(0.4, 0, 0.2, 1)`;

const Button = styled.button`
  border: none;
  margin-left: -10px;
  padding: 0;
  opacity: 0.5;
  transition: var(--transition-fade);

  svg path {
    fill: none;
    stroke-dasharray: 60 60;
    stroke-width: 6;
    transition: ${transition('stroke-dasharray')}, ${transition('stroke-dashoffset')};

    .x-dark-theme & {
      stroke: var(--white);
    }

    .x-light-theme & {
      stroke: var(--black);
    }
  }

  &:hover {
    opacity: 0.75;
  }
`;

export const Hamburger = (props: { className?: string; size?: number }): React.ReactElement => {
  const { viewport, sidebar } = useContext(Context);

  const size = Num.normalize(props.size) ?? 32;

  return (
    <Button
      className={cx('x-pseudo', props.className)}
      type='button'
      aria-expanded={sidebar.isExpanded}
      aria-label='Toggle Main Menu'
      onClick={sidebar.toggle}
    >
      {React.createElement(Device.isMobile(viewport) ? Mobile : Desktop, {
        className: cx(sidebar.isExpanded && 'x-expanded'),
        width: size,
        height: size,
        viewBox: '0 0 100 100'
      })}
    </Button>
  );
};
