import { gql } from '@apollo/client';

export const GetUserInvoices = gql`
  query GetUserInvoices($user: Float!, $status: String) {
    getLiveControlInvoices(input: { user: $user, status: $status }) {
      id
      name
      price
      status
      recurrence
      currency
      user {
        payment_token
      }
      created_at
    }
  }
`;

export const ShowInvoice = gql`
  query GetUserInvoices($invoice: Float!) {
    getLiveControlInvoices(input: { id: $invoice }) {
      id
      name
      price
      status
      recurrence
      currency
      products {
        id
        name
        price
        type {
          id
          type
        }
      }
    }
  }
`;

export const GetInvoicesStripe = gql`
  query GetInvoicesStripe($token: String!, $status: String) {
    getInvoicesStripe(input: { customer: $token, limit: 4, status: $status }) {
      error
      message
      # status
      data {
        status
        id
        number
        currency
        total
        created
        hosted_invoice_url
        charge_detail {
          payment_method_details {
            card {
              brand
              last4
            }
          }
        }
        lines {
          data {
            price {
              recurring {
                interval
              }
              product_detail {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const CreateInvoice = gql`
  mutation CreateLiveControlInvoice(
    $name: String!
    # $currency: String!
    # $trialPeriodDays: Float!
    $description: String!
    $price: Float!
    $recurrence: String!
    # status: String!
    $user: Float!
    $products: [Float!]!
  ) {
    createLiveControlInvoice(
      input: {
        name: $name
        currency: "usd"
        trial_period_days: 0
        description: $description
        recurrence: $recurrence
        status: "draft"
        user: $user
        price: $price
        products: $products
      }
    ) {
      id
      name
      description
      price
      status
      recurrence
      currency
      trial_period_days
      user {
        id
        email
        first_name
        last_name
        role {
          id
          role
        }
      }
      products {
        id
        name
        price
        description
      }
    }
  }
`;

export const SendClientInvoice = gql`
  mutation UpdateLiveControlInvoice(
    $name: String!
    $invoice: Float!
    $price: Float!
    $products: [Float!]!
  ) {
    updateLiveControlInvoice(
      input: { id: $invoice, name: $name, price: $price, products: $products, status: "open" }
    ) {
      id
      name
      description
      price
      status
      recurrence
      currency
      trial_period_days
      user {
        id
        email
        first_name
        last_name
        role {
          id
          role
        }
      }
      products {
        id
        name
        price
        description
      }
    }
  }
`;

export const UpdateClientInvoice = gql`
  mutation UpdateLiveControlInvoice(
    $name: String!
    $invoice: Float!
    $price: Float!
    $products: [Float!]!
  ) {
    updateLiveControlInvoice(
      input: { id: $invoice, name: $name, price: $price, products: $products }
    ) {
      id
      name
      description
      price
      status
      recurrence
      currency
      trial_period_days
      user {
        id
        email
        first_name
        last_name
        role {
          id
          role
        }
      }
      products {
        id
        name
        price
        description
      }
    }
  }
`;

export const DeleteClientInvoice = gql`
  mutation DeleteLiveControlInvoice($invoice: Float!) {
    deleteLiveControlInvoice(input: { id: $invoice }) {
      id
    }
  }
`;

export const GetUserSubscriptions = gql`
  query GetUserSubscriptions($token: String!) {
    subscriptions(input: { customer: $token, status: "active" }) {
      data {
        id
        status
        items {
          data {
            price {
              product_detail {
                name
              }
            }
          }
        }
        latest_invoice_detail {
          number
          hosted_invoice_url
          charge_detail {
            payment_method_details {
              card {
                brand
                last4
              }
            }
          }
        }
        plan {
          amount
          created
          currency
          interval
          billing_scheme
        }
      }
    }
  }
`;
