import type { Viewport } from '@livecontrol/core-ui';
import { Sorting } from '@livecontrol/store-utils';
import { useCallback } from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import { Header } from './header';

export const Sortable = <K extends string>(props: Sortable.Props<K>): ReactElement => {
  const { k: key, onSort, label, sorting, ...rest } = props;

  const handleChange = useCallback((): void => {
    const direction =
      key === sorting.key ? Sorting.Direction.flip(sorting.direction) : Sorting.Direction.ASCENDING;

    onSort({ key, direction });
  }, [key, sorting, onSort]);

  const active = key === sorting.key;
  const icon = sorting.direction === Sorting.Direction.ASCENDING ? 'up' : 'down';

  return (
    <Header {...rest}>
      <button
        className='x-pseudo'
        type='button'
        onClick={handleChange}
        aria-label={`Sort ${key} ${icon}`}
      >
        {label ?? key}{' '}
        {active && (
          <span key={icon}>
            <i className={`fad fa-sort-${icon}`} />
          </span>
        )}
      </button>
    </Header>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Sortable {
  export type Props<K extends string> = HTMLAttributes<HTMLDivElement> & {
    k: K;
    sorting: Sorting<K>;
    label?: string;
    onSort: (value: Sorting<K>) => void;
    viewport: Viewport;
  };
}
