import { gql, useMutation } from '@apollo/client';
import { Bool, Calendar, Num, Obj, Str } from '@livecontrol/core-utils';
import type { Asset, Event, Location } from '@livecontrol/scheduler/model';
import { Account, Destination } from '@livecontrol/scheduler/model';
import { EventValidator } from '@livecontrol/scheduler/validator';
import { useCallback, useState } from 'react';
import type { MutationResult } from '../graphql';
import { Errors } from '../graphql';
import { QUERY } from './use-events';

const CREATE_EVENT_MUTATION = gql`
  mutation CreateEvent(
    $name: String!
    $location: String!
    $youtube: Boolean!
    $facebook: Boolean!
    $hidden: Boolean!
    $wasEverPublic: Boolean!
    $recordOnly: Boolean!
    $startTime: DateTime!
    $endTime: DateTime!
    $isOperated: Boolean!
    $isMobileKit: Boolean!
    $streamLive: Boolean!
    $redirectUrl: String
    $recurrence: String
    $recurrenceEndDate: DateTime
    $recurrenceDaysOfWeek: [String!]
    $recurrenceEndOn: Float
    $description: String
    $backgroundURL: String
    $operatorNotes: String
    $requireRegistration: Boolean
    $registration: Boolean
    $showViewers: Boolean
    $liveChat: Boolean
    $integrations: [EventIntegrationsCreateInput!]!
    $streamZoom: Boolean
    $zoomMeetingId: String
    $zoomPassword: String
    $isSimulatedLive: Boolean!
    $assetId: Float
    $simulatedLiveFilename: String
    $assetUrl: String
    $eventPassword: String
    $cueSheetURL: String
    $type: EventTypes
    $clientBookingBillLater: Boolean
    $clientBookingCustomerName: String
    $clientBookingCustomerEmail: String
    $clientBookingCustomerPhone: String
    $receiveIsoRecordings: Boolean
    $useIntroAndOutroSlides: Boolean
    $technicalContactId: Float
  ) {
    scheduleEvent(
      event: {
        name: $name
        location: $location
        stream_youtube: $youtube
        stream_facebook: $facebook
        hidden: $hidden
        was_ever_public: $wasEverPublic
        record_only: $recordOnly
        start_time: $startTime
        end_time: $endTime
        redirect_url: $redirectUrl
        recurrence_end_date: $recurrenceEndDate
        recurrence_days_of_week: $recurrenceDaysOfWeek
        recurrence: $recurrence
        recurrence2: $recurrence
        recurrence_end_on: $recurrenceEndOn
        is_operated: $isOperated
        is_mobile_kit: $isMobileKit
        stream_live: $streamLive
        description: $description
        background_url: $backgroundURL
        operator_notes: $operatorNotes
        require_registration: $requireRegistration
        registration: $registration
        show_viewers: $showViewers
        live_chat: $liveChat
        integrations: $integrations
        stream_zoom: $streamZoom
        zoom_meeting_id: $zoomMeetingId
        zoom_password: $zoomPassword
        is_simulated_live: $isSimulatedLive
        simulated_live_asset_id: $assetId
        simulated_live_filename: $simulatedLiveFilename
        asset_url: $assetUrl
        event_password: $eventPassword
        cue_sheet_url: $cueSheetURL
        type: $type
        client_booking_bill_later: $clientBookingBillLater
        client_booking_customer_name: $clientBookingCustomerName
        client_booking_customer_email: $clientBookingCustomerEmail
        client_booking_customer_phone: $clientBookingCustomerPhone
        receive_iso_recordings: $receiveIsoRecordings
        use_intro_and_outro_slides: $useIntroAndOutroSlides
        technical_contact_id: $technicalContactId
      }
    ) {
      id
    }
  }
`;

interface T_Variables {
  name: string;
  location: string;
  youtube: boolean;
  facebook: boolean;
  hidden: boolean;
  wasEverPublic: boolean;
  recordOnly: boolean;
  startTime: Date;
  endTime: Date;
  isOperated: boolean;
  streamLive: boolean;
  recurrence: string;
  recurrenceEndDate: Date;
  recurrenceDaysOfWeek: string[];
  recurrenceEndOn: number;
  description: string;
  backgroundURL?: string;
  operatorNotes: string;
  requireRegistration: boolean;
  registration: boolean;
  showViewers: boolean;
  liveChat: boolean;
  streamZoom: boolean;
  zoomMeetingId: string;
  zoomPassword: string;
  isSimulatedLive: boolean;
  isStatic: boolean;
  isProduced: boolean;
  isMobile: boolean;
  isMobileKit: boolean;
  assetId: number;
  simulatedLiveFilename: string;
  assetUrl: string;
  integrations: {
    type: string;
    integration: string;
  }[];
  eventPassword: string;
  cueSheetURL?: string;

  type?: string;
  clientBookingBillLater?: boolean;
  clientBookingCustomerName?: string;
  clientBookingCustomerEmail?: string;
  clientBookingCustomerPhone?: string;
  receiveIsoRecordings?: boolean;
  useIntroAndOutroSlides?: boolean;
  redirectUrl?: string;
  technicalContactId?: number;
}

interface T_Arguments {
  eventType?: EventValidator.ProductionType;
  eventInfo?: EventValidator.EventInfo;
  eventLocation?: Location;
  clientContactDetails?: Event.ClientContactDetails;
  eventDestinations?: Destination[];
  clientBookingPaymentMethod?: EventValidator.ClientBookingPaymentMethod;
  account: Account.Like;
  simulatedLiveAssetIsUpload?: boolean;
  simulatedLiveAssetDetails?: Asset.MuxUploadedAsset;
  simulatedLiveFileUpload?: Asset.MuxUpload & { fileName?: string };
}

interface T_Data {
  id: string;
}

export const useCreate = (): [
  (args: T_Arguments) => Promise<(number | undefined)[]>,
  MutationResult
] => {
  const [error, setError] = useState<Error | undefined>();

  const [mutation, result] = useMutation<{ scheduleEvent: T_Data[] }, T_Variables>(
    CREATE_EVENT_MUTATION
  );

  const formatDaysOfWeek = (
    daysOfWeek: Set<Calendar.Weekday> | undefined
  ): string[] | undefined => {
    if (daysOfWeek) {
      return [...daysOfWeek].map((day) => day.toUpperCase().slice(0, 2));
    }

    return undefined;
  };

  return [
    useCallback(
      async (args: T_Arguments): Promise<(number | undefined)[]> => {
        const variables: Partial<T_Variables> = {
          name: Str.normalize(args.eventInfo?.title),
          location: Str.normalize(args.eventInfo?.location),
          youtube: !!args.eventDestinations?.find(
            (destination) => destination.service === Destination.Service.YouTube
          ),
          facebook: !!args.eventDestinations?.find(
            (destination) => destination.service === Destination.Service.Facebook
          ),
          hidden: !args.eventDestinations?.length,
          wasEverPublic: !!args.eventDestinations?.length,
          recordOnly: false,
          startTime: args.eventInfo?.start?.toJSDate(),
          endTime: args.eventInfo?.end?.toJSDate(),
          isOperated:
            args.eventType === EventValidator.ProductionType.PRODUCED ||
            args.eventType === EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT,
          isSimulatedLive: args.eventType === EventValidator.ProductionType.SIMULATED,
          isStatic: args.eventType === EventValidator.ProductionType.STATIC,
          isProduced: args.eventType === EventValidator.ProductionType.PRODUCED,
          isMobile: args.eventType === EventValidator.ProductionType.MOBILE,
          isMobileKit: args.eventType === EventValidator.ProductionType.MOBILE_KIT,
          streamLive: true,
          liveChat: !!args.eventInfo?.chatSettings?.liveChat,
          showViewers:
            args.eventInfo?.chatSettings?.registration || args.eventInfo?.chatSettings?.liveChat
              ? !!args.eventInfo.chatSettings.showViewers
              : false,
          registration: !!args.eventInfo?.chatSettings?.registration,
          requireRegistration: args.eventInfo?.chatSettings?.registration
            ? !!args.eventInfo.chatSettings.requireRegistration
            : false,
          receiveIsoRecordings: args.eventInfo?.receiveIsoRecordings
            ? args.eventInfo.receiveIsoRecordings
            : false,
          useIntroAndOutroSlides: args.eventInfo?.useIntroAndOutroSlides
            ? args.eventInfo.useIntroAndOutroSlides
            : false,

          // Record Only (private) events cannot be password protected
          eventPassword:
            args.eventDestinations &&
            args.eventDestinations.length > 0 &&
            Bool.normalize(args.eventInfo?.passwordSettings?.enabled)
              ? Str.normalize(args.eventInfo?.passwordSettings?.password) ?? ''
              : '',
          technicalContactId: Bool.normalize(args.eventInfo?.technicalContactSettings?.enabled)
            ? Num.normalize(args.eventInfo?.technicalContactSettings?.contactId) ?? 0
            : 0,
          integrations:
            args.eventDestinations
              ?.map(
                (
                  destination: Destination
                ): {
                  integration: string;
                  type: string;
                } => ({
                  integration: destination.id,
                  type:
                    destination.service === Destination.Service.LiveControl
                      ? 'livecontrol'
                      : destination.service === Destination.Service.Facebook
                      ? 'facebook'
                      : destination.service === Destination.Service.YouTube
                      ? 'youtube'
                      : 'rtmp'
                })
              )
              .filter((integration) => integration.type !== 'livecontrol') ?? [],
          clientBookingCustomerName: args.clientContactDetails?.name,
          clientBookingCustomerEmail: args.clientContactDetails?.email,
          clientBookingCustomerPhone: args.clientContactDetails?.phoneNumber,
          redirectUrl: Bool.normalize(args.eventInfo?.redirectUrlSettings?.enabled)
            ? Str.normalize(args.eventInfo?.redirectUrlSettings?.url) ?? ''
            : ''
        };

        variables.clientBookingCustomerEmail = Str.normalize(variables.clientBookingCustomerEmail);

        variables.clientBookingCustomerName = Str.normalize(variables.clientBookingCustomerName);

        variables.clientBookingCustomerPhone = Str.normalize(variables.clientBookingCustomerPhone);

        if (args.eventType === EventValidator.ProductionType.CLIENT_BOOKING) {
          if (
            args.clientBookingPaymentMethod ===
              EventValidator.ClientBookingPaymentMethod.CLIENT_PAYS ||
            args.clientBookingPaymentMethod === EventValidator.ClientBookingPaymentMethod.PAY_LATER
          ) {
            variables.type = 'cb1';
          } else if (args.eventLocation?.isMobile) {
            variables.type = 'cb2';
          } else {
            variables.type = 'cb3';
          }

          if (
            args.clientBookingPaymentMethod === EventValidator.ClientBookingPaymentMethod.PAY_LATER
          ) {
            variables.clientBookingBillLater = true;
          }
        } else {
          variables.type =
            args.eventType === EventValidator.ProductionType.MOBILE
              ? 'mobile'
              : args.eventType === EventValidator.ProductionType.TEST
              ? 'test'
              : 'regular';
        }

        if (!variables.clientBookingCustomerEmail) {
          delete variables.clientBookingCustomerEmail;
        }

        if (!variables.clientBookingCustomerName) {
          delete variables.clientBookingCustomerName;
        }

        if (!variables.clientBookingCustomerPhone) {
          delete variables.clientBookingCustomerPhone;
        }

        if (
          args.eventInfo?.repeatSettings?.repeat &&
          args.eventInfo.repeatSettings.repeatFrequency?.unit
        ) {
          variables.recurrence =
            args.eventInfo.repeatSettings.repeatFrequency.unit === Calendar.Unit.WEEKS
              ? 'WEEKLY'
              : 'MONTHLY';
        }

        if (
          args.eventInfo?.repeatSettings?.repeatEndCondition ===
            EventValidator.EventInfo.RepeatEndCondition.ON_DATE &&
          args.eventInfo.repeatSettings.repeatUntil?.stopRepeatingOn
        ) {
          variables.recurrenceEndDate =
            args.eventInfo.repeatSettings.repeatUntil.stopRepeatingOn.toJSDate();
        }

        if (
          args.eventInfo?.repeatSettings?.repeatEndCondition ===
            EventValidator.EventInfo.RepeatEndCondition.AFTER_OCCURRENCES &&
          args.eventInfo.repeatSettings.repeatUntil?.numRecurrences
        ) {
          variables.recurrenceEndOn = Num.normalize(
            args.eventInfo.repeatSettings.repeatUntil.numRecurrences
          );
        }

        if (args.eventInfo?.repeatSettings?.repeatFrequency?.daysOfWeek) {
          variables.recurrenceDaysOfWeek = formatDaysOfWeek(
            args.eventInfo.repeatSettings.repeatFrequency.daysOfWeek
          );
        }

        if (args.eventInfo?.operatorNotes) {
          variables.operatorNotes = args.eventInfo.operatorNotes;
        }

        if (args.eventInfo?.description) {
          variables.description = args.eventInfo.description;
        }

        if (args.eventInfo?.backgroundURL) {
          variables.backgroundURL = args.eventInfo.backgroundURL;
        }

        if (args.eventInfo?.zoomDetails?.meetingId) {
          variables.zoomMeetingId = Str.normalize(args.eventInfo.zoomDetails.meetingId);
        }

        if (args.eventInfo?.zoomDetails?.password) {
          variables.zoomPassword = Str.normalize(args.eventInfo.zoomDetails.password);
        }

        if (args.eventInfo?.zoomDetails?.streamZoom) {
          variables.streamZoom = Bool.normalize(args.eventInfo.zoomDetails.streamZoom);
        }

        if (variables.isSimulatedLive) {
          variables.assetId = Num.normalize(args.simulatedLiveAssetDetails?.assetId);
          variables.assetUrl = Str.normalize(args.simulatedLiveAssetDetails?.muxAssetUrl);

          if (args.simulatedLiveAssetIsUpload) {
            variables.simulatedLiveFilename = Str.normalize(args.simulatedLiveFileUpload?.fileName);
          }
        }

        if (args.eventLocation?.simulatedLive) {
          variables.isSimulatedLive = true;
        } else if (!args.eventLocation?.simulatedLive) {
          variables.isSimulatedLive = false;
          delete variables.simulatedLiveFilename;
          delete variables.assetId;
          delete variables.assetUrl;
        }

        if (!Obj.isHydrated(variables)) {
          throw Errors.badRequest();
        }

        if (args.eventType === EventValidator.ProductionType.TEST) {
          variables.name = `Test - ${variables.name}`;
        }

        const cuesheetURL = Str.normalize(args.eventInfo?.cueSheetURL);

        if (cuesheetURL) {
          variables.cueSheetURL = cuesheetURL;
        }

        try {
          const accountId = Account.toId(args.account)!;

          const response = (
            await mutation({
              variables,
              refetchQueries: [
                {
                  query: QUERY,
                  variables: {
                    clientAsFloat: accountId,
                    clientAsInt: accountId,
                    clientAsStr: accountId.toString()
                  }
                }
              ]
            })
          ).data?.scheduleEvent;

          const ids = response?.map((newEvent) => Num.normalize(newEvent.id));

          if (!ids) {
            throw Errors.serverError();
          }

          return ids;
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        return [];
      },
      [mutation]
    ),
    {
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
