import { EventValidator } from '@livecontrol/scheduler/validator';
import { DateTime } from 'luxon';
import React from 'react';
import { Form } from '../../../../components';
import { Store } from '../../../../store';

export const Date = ({ errors, eventType, disabled, timezone }: DateProps): React.ReactElement => {
  const account = Store.Account.useAccount();
  const isAdmin = Store.User.useIsAdmin();

  return (
    <Form.Group
      name='date'
      label='Date'
      className={` d-flex flex-column custom-input date-input ${errors?.date ? 'has-errors' : ''}`}
      error={
        errors?.date ? (
          errors.date
        ) : disabled ? (
          <p className='text-muted font-size-12px font-weight-normal'>
            Date cannot be changed so close to the event start time.
          </p>
        ) : undefined
      }
      controlProps={{
        disabled,
        placeholderText: 'Choose Date',
        timezone: timezone ?? account.timezone,
        minDate: (!isAdmin && eventType === EventValidator.ProductionType.PRODUCED
          ? DateTime.utc().plus({ days: account.leadTime })
          : !isAdmin && eventType === EventValidator.ProductionType.CLIENT_BOOKING
          ? DateTime.utc().plus({ hours: 1 })
          : DateTime.utc()
        )
          .startOf('hour')
          .setZone(timezone ?? account.timezone)
          .toJSDate(),
        dateFormat: 'EEEE, MMMM d',
        defaultDate: DateTime.utc()
          .plus({ days: 2 })
          .setZone(timezone ?? account.timezone)
          .toJSDate()
      }}
      control={Form.DateSelector}
      wrapperClass='input-wrapper'
      rightIconClass='icon calendar'
    />
  );
};

export interface DateProps {
  errors?: { date?: string };
  eventType?: EventValidator.ProductionType;
  disabled?: boolean;
  timezone?: string;
}
