/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import type { FormikContextType } from 'formik';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form } from '../../../../components';
import type { Values } from './form';

const Container = styled.div`
  .arrow {
    width: 8px;
    cursor: pointer;
  }

  .top {
    transform: rotatex(180deg);
  }

  button {
    visibility: visible !important;
    padding: 4px 0px;
  }

  .counter {
    margin: 8px 0px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    width: 18px;
    height: 18px;
    background-color: var(--primary);
    border-radius: 4px;
    border: 0px solid transparent;
  }
`;

interface Props {
  formName: string;
  formik: FormikContextType<Values>;
}

export const Counter = ({ formName, formik }: Props): React.ReactElement => {
  const [countPriority, setcountPriority] = useState(1);
  const [active, setActive] = useState(false);

  useEffect(() => {
    const nameSplit = formName.split('.');
    const haveUser = formik.values[nameSplit[0]];

    if (haveUser) {
      const haveValue = haveUser[nameSplit[1] as keyof typeof haveUser];

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (haveValue) {
        setActive(haveValue['value' as keyof typeof haveValue]);
        setcountPriority(haveValue['priority' as keyof typeof haveValue]);
      }
    }
  }, [formik]);

  const setCount = (value: number): void => {
    const newValue = Math.max(countPriority + value, 1);

    setcountPriority(newValue);

    formik.setFieldValue(`${formName}.priority`, newValue);
  };

  const formSelected = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    formik.setFieldValue(`${formName}.priority`, 1);

    const value = (event.target as HTMLInputElement).checked;

    setActive(value);
  };

  return (
    <>
      <Form.Group
        onClick={(event): void => {
          formSelected(event);
        }}
        name={`${formName}.value`}
        label=''
        className='mb-0'
        controlProps={{
          type: 'checkbox'
        }}
      />
      <Container
        className='ml-8px flex-column align-items-center'
        style={{ display: active ? 'flex' : 'none' }}
      >
        <button
          type='button'
          onClick={(): void => {
            setCount(1);
          }}
          className='btn top'
          style={{ zIndex: 0 }}
        >
          <img className='arrow' alt='arrow' src='assets/icons/arrow.svg' />
        </button>
        <div className='counter'> {countPriority} </div>
        <button
          className='btn'
          type='button'
          onClick={(): void => {
            setCount(-1);
          }}
        >
          <img className='arrow' alt='arrow' src='assets/icons/arrow.svg' />
        </button>
      </Container>
    </>
  );
};
