import { Nix } from '@livecontrol/core-utils';
import { Token } from '../model';

export const TOKEN_KEY = '__lc_token';
export const ZENDESK_TOKEN_KEY = '__lc_zendesk_token';
export const MASQ_KEY = '__lc_masquerade';

export interface Options {
  persistent?: boolean;
}

export const getItem = (key: string): string | undefined => {
  const v = sessionStorage.getItem(key) ?? localStorage.getItem(key);

  return Nix.isNotNil(v) ? v : undefined;
};

export const removeItem = (key: string): void => {
  sessionStorage.removeItem(key);
  localStorage.removeItem(key);
};

export const setItem = (
  key: string,
  value: string,
  options: Options = { persistent: true }
): void => {
  const storage = options.persistent ? localStorage : sessionStorage;

  storage.setItem(key, value);
};

export const getToken = (): Token | undefined => Token.decode(getItem(TOKEN_KEY));
export const getZendeskToken = (): string | undefined => getItem(ZENDESK_TOKEN_KEY);

export const setToken = (value: Token | undefined, options?: Options): void => {
  // Remove existing value
  removeItem(TOKEN_KEY);

  if (value) {
    setItem(TOKEN_KEY, value.jwt, options);
  }
};

export const setZendeskToken = (value: Token | undefined, options?: Options): void => {
  // Remove existing value
  removeItem(ZENDESK_TOKEN_KEY);

  if (value?.zendesk_token) {
    setItem(ZENDESK_TOKEN_KEY, value.zendesk_token, options);
  }
};
