import { Viewport } from '@livecontrol/core-ui';
import type { ReactElement } from 'react';
import { Header } from './header';
import { Sortable } from './sortable';

export const DateTime = <K extends string>({
  viewport,
  ...props
}: DateTime.Props<K>): ReactElement => (
  <>
    <Sortable
      label={viewport === Viewport.XL ? 'Date' : 'Date/Time'}
      viewport={viewport}
      {...props}
    />
    {viewport === Viewport.XL && <Header viewport={viewport}>Time</Header>}
  </>
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace DateTime {
  export type Props<K extends string> = Omit<Sortable.Props<K>, 'label'> & { viewport: Viewport };
}
