import cx from 'classnames';
import type { StandardLonghandProperties, StandardShorthandProperties } from 'csstype';
import _ from 'lodash';
import { Component } from 'react';
import type { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';

// eslint-disable-next-line react/prefer-stateless-function
export class Grid extends Component<Grid.Props> {
  public override render(): ReactNode {
    const { className, children, style, ...props } = this.props;

    const picked = _.pick(
      props,
      'alignContent',
      'alignItems',
      'columnGap',
      'gap',
      'grid',
      'gridAutoColumns',
      'gridAutoFlow',
      'gridAutoRows',
      'gridTemplateAreas',
      'gridTemplateColumns',
      'gridTemplateRows',
      'justifyContent',
      'justifyItems',
      'placeContent',
      'placeItems',
      'rowGap'
    );

    const passthru = _.omit(props, Object.keys(picked));

    return (
      <div className={cx('d-grid', className)} style={{ ...picked, ...style }} {...passthru}>
        {children}
      </div>
    );
  }
}

export namespace Grid {
  export type Props = PropsWithChildren<
    HTMLAttributes<HTMLDivElement> &
      Pick<
        StandardLonghandProperties,
        | 'alignContent'
        | 'alignItems'
        | 'columnGap'
        | 'gridAutoColumns'
        | 'gridAutoFlow'
        | 'gridAutoRows'
        | 'gridTemplateAreas'
        | 'gridTemplateColumns'
        | 'gridTemplateRows'
        | 'justifyContent'
        | 'justifyItems'
        | 'placeContent'
        | 'rowGap'
      > &
      Pick<StandardShorthandProperties, 'gap' | 'grid' | 'placeItems'>
  >;
}
