import _ from 'lodash';
import type { Interval } from 'luxon';
import { validateEventDates } from './dates-validator';
import { validateRepeatingEvent } from './repeat-validator';
import type { EventValidator } from './types';

interface Props {
  newEventInfo: EventValidator.EventInfo;
  isAdmin?: boolean;
  isProd: boolean;
  simulatedLiveAssetIsUpload: boolean;
}

export const getAllRecurrences = ({
  newEventInfo,
  simulatedLiveAssetIsUpload,
  isAdmin,
  isProd
}: Props): { recurrences?: Interval[]; errors?: EventValidator.Errors } => {
  const errors = {
    ...validateEventDates({ newEventInfo, simulatedLiveAssetIsUpload, isAdmin, isProd }),
    ...validateRepeatingEvent(newEventInfo)
  };

  if (_.isEmpty(errors)) {
    const { repeatSettings } = newEventInfo;
    const recurrences: Interval[] = [];

    if (repeatSettings?.repeat) {
      return { recurrences };
    }
  }

  return { errors };
};
