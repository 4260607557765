/* eslint-disable react-hooks/rules-of-hooks */
import { Loading } from '@livecontrol/core-ui';
import { User } from '@livecontrol/scheduler/model';
import type { Destination } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Store } from '../../store';
import { EditProduceMobileKitWorkflow } from './edit-produce-mobile-kit-workflow';
import { EventContextProvider } from './event-store';
import { MobileKitContextProvider } from './mobile-kit-store';

export const EditProduceMobileKitEvent = (): React.ReactElement => {
  const me = Store.User.useMe();
  const account = Store.Account.useAccount();
  const { locations, loading: locationsLoading } = Scheduler.Location.useLocations(account);

  const locationsWithPermission = Store.User.useLocationWithPermission(
    User.LocationPermissionsNames.EventManagment,
    locations
  );

  if (!me.permissions.someLocationHasEventManagmentEnabled) {
    return <Redirect to='/schedule' />;
  }

  const { destinations } = Scheduler.Destination.useDestinations(account);

  const { id } = useParams<{ id: string }>();

  const { mobile } = useParams<{ mobile: string }>();

  const { event, loading } = Scheduler.Event.useEvent({ event: id, account });

  const isFirstEventProduced = !event?.isMobileKit;

  const { event: secondEvent, loading: secondLoading } = Scheduler.Event.useEvent({
    event: mobile,
    account
  });

  if (isFirstEventProduced && secondEvent && event?.phase && event.phase !== 1) {
    const redirectString = `/schedule/edit/${secondEvent.id}`;

    return <Redirect to={redirectString} />;
  } else if (!isFirstEventProduced && secondEvent?.phase === 2) {
    const redirectString = `/schedule/edit/${event.id}`;

    return <Redirect to={redirectString} />;
  }

  if (loading || !event || !destinations || secondLoading || !secondEvent) {
    return <Loading.Delay />;
  }

  if ((!locationsLoading && locations?.length) && (
    !locationsWithPermission.some((location) => location.id === event.location.id) ||
    !locationsWithPermission.some((location) => location.id === secondEvent.location.id))
  ) {
    return <Redirect to='/schedule' />;
  }

  return (
    // produce + mobile kit flow
    isFirstEventProduced ? (
      <EventContextProvider
        existingEvent={{
          ...event,
          destinations: event.destinations
            ?.map(
              (eventDestination) =>
                destinations.find((destination) => eventDestination.name === destination.id) ??
                eventDestination
            )
            .filter((destination): destination is Destination => !!destination)
        }}
      >
        <MobileKitContextProvider
          existingEvent={{
            ...secondEvent,
            destinations: secondEvent.destinations
              ?.map(
                (eventDestination) =>
                  destinations.find((destination) => eventDestination.name === destination.id) ??
                  eventDestination
              )
              .filter((destination): destination is Destination => !!destination)
          }}
        >
          <EditProduceMobileKitWorkflow />
        </MobileKitContextProvider>
      </EventContextProvider>
    ) : (
      <EventContextProvider
        existingEvent={{
          ...secondEvent,
          destinations: secondEvent.destinations
            ?.map(
              (eventDestination) =>
                destinations.find((destination) => eventDestination.name === destination.id) ??
                eventDestination
            )
            .filter((destination): destination is Destination => !!destination)
        }}
      >
        <MobileKitContextProvider
          existingEvent={{
            ...event,
            destinations: event.destinations
              ?.map(
                (eventDestination) =>
                  destinations.find((destination) => eventDestination.name === destination.id) ??
                  eventDestination
              )
              .filter((destination): destination is Destination => !!destination)
          }}
        >
          <EditProduceMobileKitWorkflow />
        </MobileKitContextProvider>
      </EventContextProvider>
    )
  );
};
