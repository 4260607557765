import { Device, Theme, Tooltip } from '@livecontrol/core-ui';
import { Phone } from '@livecontrol/locale-us';
import { Matrix } from '@livecontrol/scheduler/components';
import type { Account, Location } from '@livecontrol/scheduler/model';
import cx from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Row } from './row';

import G = Matrix.Grid;
import H = G.Header;

const Cell = styled(Matrix.Grid.Row.Cell)<{ active?: boolean }>`
  border-color: ${({ active }): string =>
    `${active ? '#2E5BFF !important' : '#D5DAEA !important'}`};

  &:first-child {
    border-top-left-radius: var(--spacer-4px);
    border-bottom-left-radius: var(--spacer-4px);
  }
  &:last-child {
    border-top-right-radius: var(--spacer-4px);
    border-bottom-right-radius: var(--spacer-4px);
  }

  .bottom-12 {
    bottom: var(--spacer-12px) !important;
  }
  .account-holder-label {
    cursor: default;
  }
`;

interface Props {
  account: Account;
  users: Account.SubUser[] | undefined;
  onEdit: (subUser: Account.SubUser) => void;
  onSelectUser: (subUser: Account.SubUser) => void;
  onResendInvite: (subUser: Account.SubUser) => void;
  filteredLocation?: Location;
  availableLocationsCount: number;
  showAccountHolder: boolean;
}

export const Grid = ({
  account,
  users,
  onEdit,
  onSelectUser,
  onResendInvite,
  filteredLocation,
  availableLocationsCount,
  showAccountHolder
}: Props): React.ReactElement => {
  const { subUsers } = account;
  const viewport = Theme.useViewport();

  interface SubUserInfo {
    subUserInfo: Account.SubUser;
    locationsNamesToText?: string;
  }

  const subUsersInfo: SubUserInfo[] = [];

  if (subUsers.length) {
    let subUserLocationsToText = '';

    subUsers.forEach((aSubUser) => {
      const locations: string[] = [];

      if (
        aSubUser.permissions.billing ||
        aSubUser.permissions.webplayer ||
        aSubUser.permissions.destinations ||
        aSubUser.permissions.organizationAccountInfo ||
        aSubUser.permissions.admin
      ) {
        subUserLocationsToText = 'All Locations';
      } else if (aSubUser.sub_user_locations?.length) {
        aSubUser.sub_user_locations.forEach((aLocation) => {
          if (
            !aLocation.location.is_disabled &&
            ( aLocation.contentManagment ||
              aLocation.eventManagment ||
              aLocation.productionNotes ||
              aLocation.viewAndDownload) &&
            !locations.includes(aLocation.location.name)
          ) {
            locations.push(aLocation.location.name);
          }
        });

        subUserLocationsToText =
          locations.length === availableLocationsCount ? 'All Locations' : locations.join(', ');
      }

      subUsersInfo.push({ subUserInfo: aSubUser, locationsNamesToText: subUserLocationsToText });
    });
  }

  return (
    <G viewport={viewport} gridTemplateColumns='28px repeat(5, 1fr) min-content'>
      {/* Header */}

      <H className='text-gray-light font-weight-bold' viewport={viewport}>
        &nbsp;
      </H>
      <G.Row>
        <H className='text-gray-light font-weight-bold' viewport={viewport}>
          Name
        </H>
        <H className='text-gray-light font-weight-bold' viewport={viewport}>
          Email
        </H>
        <H className='text-gray-light font-weight-bold' viewport={viewport}>
          Phone
        </H>
        <H className='text-gray-light font-weight-bold' viewport={viewport}>
          Contact Method
        </H>
        <H className='text-gray-light font-weight-bold' viewport={viewport}>
          Locations
        </H>
        <H className='text-gray-light font-weight-bold' viewport={viewport}>
          &nbsp;
        </H>
      </G.Row>

      {/* Content */}
      {showAccountHolder && (
        <>
        <div className='text-gray-light font-weight-bold'>&nbsp;</div>
        <G.Row className='text-dark text-weight-bold font-size-13px'>
          <Cell
            className={cx(
              'position-relative',
              Device.isDesktop(viewport) ? 'font-weight-bold' : 'font-weight-light'
            )}
          >
            <div>{account.accountOwnerName}</div>
            <span className='position-absolute bottom-12 badge badge-pill text-white badge-gray-light mt-4px font-size-8px account-holder-label'>
              <Tooltip
                backgroundColor='#0C3192'
                tip={
                  <div>
                    <div className='font-weight-bold font-size-11px'>
                      Reach out to Customer Success to update the Main Account Holder
                    </div>
                  </div>
                }
              >
                <span>Account Holder</span>
              </Tooltip>
            </span>
          </Cell>
          <Cell className={cx(Device.isDesktop(viewport) ? 'font-weight-bold' : 'font-weight-light')}>
            {account.accountOwnerEmail}
          </Cell>
          <Cell className={cx(Device.isDesktop(viewport) ? 'font-weight-bold' : 'font-weight-light')}>
            {Phone.normalize(account.phone)?.format({ parenthetical: true, separator: '-' }) ??
              account.phone}
          </Cell>
          <Cell
            className={cx(
              Device.isDesktop(viewport) ? 'font-weight-bold' : 'font-weight-light',
              'text-capitalize'
            )}
          >
            {account.preferredContactMethod}
          </Cell>
          <Cell className={cx(Device.isDesktop(viewport) ? 'font-weight-bold' : 'font-weight-light')}>
            All Locations
          </Cell>
          <Cell>&nbsp;</Cell>
        </G.Row>
        </>
      )}

      {subUsersInfo.map((subUser) => (
        <Row
          users={users}
          key={subUser.subUserInfo.id}
          subUser={subUser.subUserInfo}
          onEdit={onEdit}
          onSelectUser={onSelectUser}
          onResendInvite={onResendInvite}
          filteredLocation={filteredLocation}
          locationsFormatedText={subUser.locationsNamesToText}
        />
      ))}
    </G>
  );
};
