import { Modal } from '@livecontrol/scheduler/components';
import { Asset } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import cx from 'classnames';
import { useState } from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Store } from '../../../../store';
import { Confirm } from './confirm';

import Viz = Asset.Visibility;

type Props = HTMLAttributes<HTMLDivElement> & {
  asset: Asset;
};

export const Visibility = ({ asset, className, ...props }: Props): ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const [editAsset, { loading }] = Scheduler.Asset.useEdit();
  const account = Store.Account.useAccount();
  const prompt = Modal.usePrompt();
  const me = Store.User.useMe();

  const { visibility } = asset;

  const opposite = visibility === Viz.Public ? Viz.Private : Viz.Public;

  const icon = loading
    ? 'fad fa-spinner-third'
    : `far fa-${visibility === Viz.Public ? 'eye' : 'eye-slash'}`;

  const doChangeVisibility = (commit: boolean): void => {
    // Close the modal window
    setShowModal(false);

    // Did the user confirm the change?
    if (commit && !loading) {
      editAsset({ asset, account, changes: { ...asset, visibility: opposite } })
        .catch((error: Error) => {
          prompt.error({ content: error.message });
        });
    }
  };

  return (
    <div {...props}>
      <Dropdown
        onSelect={(): void => {
          // Show the confirmation modal (if not loading)
          if (!loading) {
            setShowModal(true);
          }
        }}
      >
        <Dropdown.Toggle
          className={cx('py-0', className)}
          size='sm'
          variant='outline-dark'
          disabled={loading || (!me.permissions.admin && me.permissions.readOnlyArchive)}
        >
          <span key={icon}>
            <i className={cx(icon, 'fa-fw', loading && 'fa-spin')} />
          </span>

          <span>{Viz[visibility].toUpperCase()}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item>Make {Viz[opposite]}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {showModal && <Confirm visibility={opposite} onConfirm={doChangeVisibility} />}
    </div>
  );
};
