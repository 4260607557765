/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Device, Theme, Tooltip } from '@livecontrol/core-ui';
import { Matrix } from '@livecontrol/scheduler/components';
import type { Account, Location } from '@livecontrol/scheduler/model';
import type { FormikContextType } from 'formik';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import type { Values } from '../permissions-user';

import G = Matrix.Grid;
import H = G.Header;

interface Props {
  formik: FormikContextType<Values>;
  locations?: Location[];
  subUsers?: Account.SubUser[];
  setIsHeaderHovered: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const TableHeader = ({ formik, locations, subUsers, setIsHeaderHovered }: Props): ReactElement => {
  const [headerSelected, setHeaderSelected] = useState([false, false, false, false]);

  const viewport = Theme.useViewport();

  const selectColumn = (active: boolean, permissions: string, index: number): void => {
    setHeaderSelected((oldState) => oldState.map((state, i) => (i === index ? active : state)));

    if (formik.values.locationUser === 'user') {
      locations?.forEach((location) => {
        formik.setFieldValue(`${location.id}.${permissions}`, active);
        formik.setTouched({touched: true});
      });
    } else {
      subUsers?.forEach((subuser) => {
        formik.setFieldValue(`${subuser.id}.${permissions}`, active);
        formik.setTouched({touched: true});
      });
    }
  };

  useEffect(() => {
    const { values } = formik;

    const notHavePermissions: boolean[] = [false, false, false, false];

    if (formik.values.locationUser === 'user') {
      locations?.forEach((location) => {
        const valuesPermissions = values[`${location.id}` as keyof typeof values];

        if (valuesPermissions) {
          if (valuesPermissions['eventManagment' as keyof typeof valuesPermissions]) {
            notHavePermissions[0] = true;
          }

          if (valuesPermissions['viewAndDownload' as keyof typeof valuesPermissions]) {
            notHavePermissions[1] = true;
          }

          if (valuesPermissions['contentManagment' as keyof typeof valuesPermissions]) {
            notHavePermissions[2] = true;
          }

          if (valuesPermissions['productionNotes' as keyof typeof valuesPermissions]) {
            notHavePermissions[3] = true;
          }

        }
      });

      setHeaderSelected(notHavePermissions);

    } else {
      subUsers?.forEach((subuser) => {
        const valuesPermissions = values[`${subuser.id}` as keyof typeof values];

        if (valuesPermissions) {
          if (valuesPermissions['eventManagment' as keyof typeof valuesPermissions]) {
            notHavePermissions[0] = true;
          }

          if (valuesPermissions['viewAndDownload' as keyof typeof valuesPermissions]) {
            notHavePermissions[1] = true;
          }

          if (valuesPermissions['contentManagment' as keyof typeof valuesPermissions]) {
            notHavePermissions[2] = true;
          }

          if (valuesPermissions['productionNotes' as keyof typeof valuesPermissions]) {
            notHavePermissions[3] = true;
          }

        }
      });

      setHeaderSelected(notHavePermissions);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  return (
    <G.Row className='position-sticky d-flex' style={{ top: 0 }}>
      <H
        className='bg-white column font-weight-bold '
        viewport={viewport}
        style={{ left: 0, width: Device.isMobile(viewport) ? '125px' : '300px' }}
      >
        <span className='badge rounded-pill bg-primary text-white mb-8px' style={{ opacity: 0 }}>
          Event Schedule
        </span>

        <div style={{ width: Device.isMobile(viewport) ? '125px' : '300px', opacity: 0 }}>
          Event Schedule
        </div>
      </H>
      <H
        className='column text-center font-weight-bold '
        style={{ width: 140, maxWidth: 140, minWidth: 140 }}
        viewport={viewport}
        onMouseEnter={(): void => {
          setIsHeaderHovered(2);
        }}
        onMouseLeave={(): void => {
          setIsHeaderHovered(undefined);
        }}
      >
        <span className='badge rounded-pill bg-info text-white font-size-9px mb-8px'>
          Event Schedule
        </span>
        <div className='font-size-12px' style={{ width: 118, marginBottom: 43 }}>
          Manage & Edit
          <Tooltip
            backgroundColor='#0C3192'
            tip={
              <div>
                <div className='font-weight-bold font-size-11px'>
                  Access to create, edit and delete events. All users can view the event schedule.
                </div>
              </div>
            }
          >
            <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
          </Tooltip>
        </div>
        {!headerSelected[0] ? (
          <button
            onClick={(): void => {
              selectColumn(true, 'eventManagment', 0);
            }}
            type='button'
            className='btn p-0 bg-transparent text-primary font-weight-bold font-size-12px'
          >
            Select All
          </button>
        ) : (
          <button
            onClick={(): void => {
              selectColumn(false, 'eventManagment', 0);
            }}
            type='button'
            className='btn p-0 bg-transparent text-primary font-weight-bold font-size-12px'
          >
            Deselect All
          </button>
        )}
      </H>
      <H
        className='column text-center font-weight-bold'
        style={{ width: 140, maxWidth: 140, minWidth: 140 }}
        viewport={viewport}
        onMouseEnter={(): void => {
          setIsHeaderHovered(3);
        }}
        onMouseLeave={(): void => {
          setIsHeaderHovered(undefined);
        }}
      >
        <span className='badge rounded-pill bg-admin text-white font-size-9px mb-8px'>
          Content Library
        </span>
        <div className='font-size-12px' style={{ width: 118, marginBottom: 43 }}>
          View & Download
          <Tooltip
            backgroundColor='#0C3192'
            tip={
              <div>
                <div className='font-weight-bold font-size-11px'>
                  Access to only view and download past videos in the Content Library. Access to view and download individual and global stream analytics. 
                </div>
              </div>
            }
          >
            <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
          </Tooltip>
        </div>
        {!headerSelected[1] ? (
          <button
            onClick={(): void => {
              selectColumn(true, 'viewAndDownload', 1);
            }}
            type='button'
            className='btn p-0 bg-transparent text-primary font-weight-bold font-size-12px'
          >
            Select All
          </button>
        ) : (
          <button
            onClick={(): void => {
              selectColumn(false, 'viewAndDownload', 1);
            }}
            type='button'
            className='btn p-0 bg-transparent text-primary font-weight-bold font-size-12px'
          >
            Deselect All
          </button>
        )}
      </H>
      <H
        className='column text-center font-weight-bold'
        style={{ width: 140, maxWidth: 140, minWidth: 140 }}
        viewport={viewport}
        onMouseEnter={(): void => {
          setIsHeaderHovered(4);
        }}
        onMouseLeave={(): void => {
          setIsHeaderHovered(undefined);
        }}
      >
        <span className='badge rounded-pill font-size-9px bg-admin text-white mb-8px'>
          Content Library
        </span>
        <div className='font-size-12px' style={{ width: 118, marginBottom: 43 }}>
          Manage & Edit
          <Tooltip
            backgroundColor='#0C3192'
            tip={
              <div>
                <div className='font-weight-bold font-size-11px'>
                  Access to view, download, edit, and delete past videos in the Content Library. Access to view and download individual and global stream analytics.
                </div>
              </div>
            }
          >
            <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
          </Tooltip>
        </div>
        {!headerSelected[2] ? (
          <button
            onClick={(): void => {
              selectColumn(true, 'contentManagment', 2);
            }}
            type='button'
            className='btn p-0 bg-transparent text-primary font-weight-bold font-size-12px'
          >
            Select All
          </button>
        ) : (
          <button
            onClick={(): void => {
              selectColumn(false, 'contentManagment', 2);
            }}
            type='button'
            className='btn p-0 bg-transparent text-primary font-weight-bold font-size-12px'
          >
            Deselect All
          </button>
        )}
      </H>
      <H
        className='column text-center font-weight-bold'
        style={{ width: 140, maxWidth: 140, minWidth: 140 }}
        viewport={viewport}
        onMouseEnter={(): void => {
          setIsHeaderHovered(5);
        }}
        onMouseLeave={(): void => {
          setIsHeaderHovered(undefined);
        }}
      >
        <span className='badge rounded-pill bg-purplue text-white font-size-9px mb-8px'>
          Stream Settings
        </span>
        <div className='font-size-12px' style={{ width: 118, marginBottom: 43 }}>
          Production Notes
          <Tooltip
            backgroundColor='#0C3192'
            tip={
              <div>
                <div className='font-weight-bold font-size-11px'>
                  Access to update account Production Notes.
                </div>
              </div>
            }
          >
            <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
          </Tooltip>
        </div>
        {!headerSelected[3] ? (
          <button
            onClick={(): void => {
              selectColumn(true, 'productionNotes', 3);
            }}
            type='button'
            className='btn p-0 bg-transparent text-primary font-weight-bold font-size-12px'
          >
            Select All
          </button>
        ) : (
          <button
            onClick={(): void => {
              selectColumn(false, 'productionNotes', 3);
            }}
            type='button'
            className='btn p-0 bg-transparent text-primary font-weight-bold font-size-12px'
          >
            Deselect All
          </button>
        )}
      </H>
    </G.Row>
  );
};
