import _ from 'lodash';
import React, { Component } from 'react';
import type { ComponentPropsWithRef, ReactNode } from 'react';

import B_Collapse from 'react-bootstrap/Collapse';
import type { CollapseProps } from 'react-bootstrap/Collapse';

// eslint-disable-next-line react/prefer-stateless-function
export class Collapse extends Component<Collapse.Props> {
    public override render(): ReactNode {
      return React.createElement(B_Collapse, _.defaults({}, this.props));
    }
}

export namespace Collapse {
    export type Props = CollapseProps & ComponentPropsWithRef<'div'>;
}
