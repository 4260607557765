import { Auth } from '@livecontrol/core-ui';
import { Str } from '@livecontrol/core-utils';
import { User } from '@livecontrol/scheduler/model';
import { FormikProvider } from 'formik';
import qs from 'qs';
import type { ReactElement } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Form } from '../../components';
import { Store } from '../../store';
import { useForm } from './form';

const Content = (): ReactElement => {
  const { formik, loading, alert } = useForm();

  return (
    <>
      <Form.Alert show={alert.show} onClose={alert.hide}>
        {alert.message}
      </Form.Alert>

      <FormikProvider value={formik}>
        <Form>
          <Form.Group
            name='email'
            label='Email Address'
            controlProps={{
              autoFocus: true,
              maxLength: User.Constraints.EMAIL_MAX_LENGTH
            }}
          />

          <Form.Group
            name='password'
            label='Password'
            controlProps={{
              type: 'password',
              maxLength: User.Constraints.PASSWORD_MAX_LENGTH
            }}
          />

          <Form.Group
            name='remember'
            label={false}
            control={Form.Check}
            controlProps={{
              label: 'Keep me logged in.',
              type: 'checkbox'
            }}
          />

          <div className='d-flex align-items-center justify-content-between'>
            <Form.Submit className='btn-wide' loading={loading} size='lg'>
              Login
            </Form.Submit>

            <Link className='font-size-13px' to='/forgot-password'>
              Forgot Password
            </Link>
          </div>
        </Form>
      </FormikProvider>
    </>
  );
};

const Page = (): ReactElement => (
  <Auth.Layout>
    <Auth.Panel title='Login'>
      <Content />
    </Auth.Panel>

    {/* temporarily hidden - https://livecontrol.atlassian.net/browse/WEB-565
    <Auth.Linkbox>
      Don’t have an account?{' '}
      <Link to='/signup'>
        <strong>Sign up here!</strong>
      </Link>
    </Auth.Linkbox>
    */}
  </Auth.Layout>
);

export const Login = (): ReactElement => {
  const { me, thee } = Store.User.useIdentity();
  const role = (thee ?? me)?.role;

  const redirectTo =
    Str.normalize(qs.parse(window.location.search, { ignoreQueryPrefix: true }).redirect) ?? '/';

  // Is the client already authenticated?
  return role && [User.Role.Client, User.Role.SubUser].includes(role) ? (
    <Redirect to={redirectTo} />
  ) : (
    <Page />
  );
};
