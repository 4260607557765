import { Matrix } from '@livecontrol/scheduler/components';
import type { Account } from '@livecontrol/scheduler/model';
import type { ReactElement } from 'react';
import styled from 'styled-components';

const Cell = styled(Matrix.Grid.Row.Cell)`
  border-color: #d5daea;
  justify-content: center;
  padding: 14px 0px;

  &:first-child {
    border-top-left-radius: var(--spacer-4px);
    border-bottom-left-radius: var(--spacer-4px);
  }
  &:last-child {
    border-top-right-radius: var(--spacer-4px);
    border-bottom-right-radius: var(--spacer-4px);
    padding: 14px 0px;
  }
`;

const Row = styled(Matrix.Grid.Row)`
  position: relative;

  .users {
    position: sticky;
    left: 0;
  }

  img {
    cursor: not-allowed;
  }

  button {
    visibility: hidden;
    display: flex !important;
  }

  &:hover {
    .column {
      border-color: #2e5bff !important;
    }
`;

interface Props {
  account: Account;
}

export const AccountHolder = ({ account }: Props): ReactElement => (
  <Row>
    <Cell className='column users justify-content-start'>
      <div className='d-flex flex-column pr-4px'>
        <div className='d-flex flex-column text-dark font-weight-bold font-size-12px'>
          {account.accountOwnerName}
          <span
            style={{ width: 'fit-content' }}
            className='bottom-12 badge badge-pill text-white badge-gray-light mt-4px font-size-8px'
          >
            Account Holder
          </span>
          <button
            type='button'
            className='btn p-0 bg-transparent text-primary font-weight-bold font-size-12px'
          >
            Select All
          </button>
        </div>
      </div>
    </Cell>
    <Cell className='column'>
      <img alt='able' src='assets/icons/checkbox-selected.svg' />
    </Cell>
    <Cell className='column'>
      <img alt='able' src='assets/icons/checkbox-selected.svg' />
    </Cell>
    <Cell className='column'>
      <img alt='able' src='assets/icons/checkbox-selected.svg' />
    </Cell>
    <Cell className='column'>
      <img alt='able' src='assets/icons/checkbox-selected.svg' />
    </Cell>
    <Cell className='column'>
      <img alt='able' src='assets/icons/checkbox-selected.svg' />
    </Cell>
    <Cell className='column'>
      <img alt='able' src='assets/icons/checkbox-selected.svg' />
    </Cell>
    <Cell className='column'>
      <img alt='able' src='assets/icons/checkbox-selected.svg' />
    </Cell>
    <Cell className='column'>
      <img alt='able' src='assets/icons/checkbox-selected.svg' />
    </Cell>
    <Cell className='column'>
      <img alt='able' src='assets/icons/checkbox-selected.svg' />
    </Cell>
    <Cell className='column'>
      <img alt='able' src='assets/icons/checkbox-selected.svg' />
    </Cell>
  </Row>
);
