import cx from 'classnames';
import type { ReactElement } from 'react';

interface Props {
  className?: string;
  onReset: () => void;
}

export const NoMatch = ({ className, onReset }: Props): ReactElement => (
  <div className={cx('d-flex', 'justify-content-center', 'align-items-center', className)}>
    <div className='alert alert-secondary w-100 text-center font-size-15x'>
      No assets match your filter criteria. Click{' '}
      <button type='button' className='x-pseudo text-primary' onClick={onReset}>
        here
      </button>{' '}
      to reset your criteria.
    </div>
  </div>
);
