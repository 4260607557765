import { Str } from '@livecontrol/core-utils';
import { EventValidator } from './types';

export const validateEventNotes = (
  newEventInfo: EventValidator.EventInfo
): EventValidator.Errors => {
  const errors: EventValidator.Errors = {};
  const operatorNotes = Str.normalize(newEventInfo.operatorNotes);

  if (!!operatorNotes && operatorNotes.length > 5000) {
    errors.operatorNotes = EventValidator.ErrorMessages.OPERATOR_NOTES_TOO_LONG;
  }

  return errors;
};
