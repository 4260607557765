import { Button, Device, Loading, Theme } from '@livecontrol/core-ui';
import { Scheduler } from '@livecontrol/scheduler/store';
import cx from 'classnames';
import { useState } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { SectionContainer } from '../../../components';
import { Store } from '../../../store';
import { Editor } from './editor';
import { Locations } from './locations';

const BorderInfo = styled.div`
  border-top: 1px solid #d5daea;
  border-bottom: 1px solid #d5daea;
  padding-top: 28px;
  padding-bottom: 60px;

  .border-mobile {
    border-top: 1px solid #d5daea;
  }

  .border-tablet {
    border-top: 1px solid #d5daea;
  }

  .border-desktop {
    border-left: 1px solid #d5daea;
  }

  .white-button {
    color: #0d1438;

    :hover {
      background-color: #8798ad;
    }
  }
`;

export const Organization = (): ReactElement => {
  const account = Store.Account.useAccount();
  const { billing } = Scheduler.Account.useBilling(account);
  const { locations, loading: loadingLocation } = Scheduler.Location.useLocations(account);
  const me = Store.User.useMe();

  const viewport = Theme.useViewport();

  const [showModal, setShowModal] = useState(false);

  return (
    <SectionContainer className='col-12'>
      <h1 className='mb-28px'>Organization</h1>
      <BorderInfo
        className={cx(
          'd-flex',
          Device.isMobile(viewport)
            ? 'flex-column'
            : Device.isTablet(viewport)
            ? 'flex-column'
            : 'flex-row'
        )}
      >
        <div className='d-flex row col col-xl-9'>
          <div className='font-size-13px font-weight-bold p-24px col-xl-3 col-lg-6 '>
            <div className='text-gray-light font-size-13px'>Organization Name</div>
            <div className='text-dark mt-8px'>{account.name} </div>
          </div>
          <div className='font-size-13px font-weight-bold p-24px col-xl-3 col-lg-6'>
            <div className='text-gray-light font-size-13px'>Address</div>
            <div className='text-dark mt-8px'>{account.address} </div>
          </div>
          <div className='font-size-13px font-weight-bold p-24px col-xl-3 col-lg-6'>
            <div className='text-gray-light font-size-13px'>Organization Phone Number</div>
            <div className='text-dark mt-8px'>
              {account.organizationPhoneNumber
                ? account.organizationPhoneNumber.format({ parenthetical: true, separator: '-' })
                : '-'}
            </div>
          </div>
          <div className='font-size-13px font-weight-bold p-24px col-xl-3 col-lg-6'>
            <div className='text-gray-light font-size-13px'>Time Zone</div>
            <div className='text-dark mt-8px'>{account.timezone.name} </div>
          </div>
          <div className='font-size-13px font-weight-bold p-24px col-xl-3 col-lg-6'>
            <div className='text-gray-light font-size-13px'>Account Holder</div>
            <div className='text-dark mt-8px'>{account.accountOwnerName} </div>
          </div>
          <div className='font-size-13px font-weight-bold p-24px col-xl-3 col-lg-6'>
            <div className='text-gray-light font-size-13px'>Account Holder Email</div>
            <div className='text-dark mt-8px'>{account.accountOwnerEmail} </div>
          </div>
          <div className='font-size-13px font-weight-bold p-24px col-xl-3 col-lg-6'>
            <div className='text-gray-light font-size-13px'>Account Holder Phone</div>
            <div className='text-dark mt-8px'>
              {account.phone?.format({ parenthetical: true, separator: '-' })}{' '}
            </div>
          </div>
        </div>
        <div className='d-flex row col col-xl-3'>
          <div
            className={cx(
              'font-size-13px font-weight-bold col-lg-12',
              Device.isMobile(viewport)
                ? 'border-mobile p-24px'
                : Device.isTablet(viewport)
                ? 'border-tablet p-24px'
                : 'border-desktop pl-40px pt-24px'
            )}
          >
            <div className='text-gray-light font-size-13px'>Credit Length</div>
            <div className='text-dark mt-8px'>
              {billing?.credits.first_credit_time_limit &&
                (billing.credits.first_credit_time_limit / 60).toFixed(2)}{' '}
              hours/credit
            </div>
          </div>
          <div
            className={cx(
              'font-size-13px font-weight-bold p-24px col-lg-12',
              Device.isMobile(viewport)
                ? ''
                : Device.isTablet(viewport)
                ? 'border-tablet p-24px'
                : 'border-desktop pl-40px pt-24px'
            )}
          >
            <div className='text-gray-light font-size-13px'>Produced Event Lead Time</div>
            <div className='text-dark mt-8px'>{account.leadTime * 24} hours </div>
          </div>
          <div className='d-none d-lg-block d-xl-none col-lg-12' />
        </div>
        {me.permissions.organizationAccountInfo && (
          <Button
            className={cx(
              'justify-self-start p-8px font-weight-bold white-button',
              Device.isTablet(viewport) && 'ml-16px',
              Device.isMobile(viewport) && 'mt-16px',
              Device.isDesktop(viewport) && 'mt-16px'
            )}
            style={{ height: 'fit-content', width: 'fit-content' }}
            size='sm'
            variant='outline-dark'
            onClick={(): void => {
              setShowModal(true);
            }}
          >
            <img className='mr-8px' src='assets/icons/Edit-pencil.svg' alt='edit black' />
            Edit
          </Button>
        )}

        {showModal && (
          <Editor
            account={account}
            onClose={(): void => {
              setShowModal(false);
            }}
          />
        )}
      </BorderInfo>
      <h1 className='font-size-20px text-dark mt-24px'>Locations</h1>
      {loadingLocation ? <Loading.Delay /> : <Locations me={me} locations={locations} />}
    </SectionContainer>
  );
};
