import { User } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Store } from '../../store';

const Wrapper = styled.div`
    --radius: 10px;
    border-radius: var(--radius);

    header {
        position: relative;
        background-color: #0D1438;
        overflow: hidden;
        min-height: 100px;
        border-radius: var(--radius);
    }

    .dot {
        --size: 100px;
        position: absolute;
        border-radius: 50%;
        width: var(--size);
        height: var(--size);

        &.large {
            --size: 170px;
        }
        &.medium {
            --size: 150px;
        }
        &.small {
            --size: 80px;
        }
        &.dot-1 {
            left: 50%;
            top: 15px;
        }
        &.dot-2 {
            left: calc(50% - 20px);
            top: -10px;
        }
        &.dot-3 {
            left: calc(50% + 120px);
            top: 60px;
        }
        &.dot-4 {
            left: calc(50% - 50px);
            top: 75px;
        }
    }

    .blue-dot {
        background-color: #2E5BFF;
    }
    .red-dot {
        background-color: #FF505F;
    }
    .outline-dot {
        border: 1px solid white;
    }

    img {
        height: 60px;
        z-index: 1;
    }

    .code {
        background-color: #0C3192;
        border-radius: 8px;
        color: white;
        width: min-content;
        font-weight: 200;
        letter-spacing: 2rem;
        padding-left: 3rem!important;
        font-weight: 700;
        height: 64px;
        width: 300px;
    }

    .x-viewport-md & {
        header {
            min-height: 150px;
        }
        .dot.large {
            --size: 250px
        }
        .dot.medium {
            --size: 200px
        }
        .dot.small {
            --size: 110px
        }
        .dot.dot-1 {
            left: 50%;
            top: 20px;
        }
        .dot.dot-2 {
            left: calc(50% - 20px);
            top: -10px;
        }
        .dot.dot-3 {
            left: calc(50% + 170px);
            top: 90px;
        }
        .dot.dot-4 {
            left: calc(50% - 60px);
            top: 120px;
        }
        img {
            height: 70px;
        }
    }
    .x-viewport-lg & {
        header {
            min-height: 200px;
        }

        .dot.large {
            --size: 300px;
        }
        .dot.medium {
            --size: 250px;
        }
        .dot.small {
            --size: 130px;
        }
        .dot.dot-1 {
            left: 50%;
            top: 20px;
        }
        .dot.dot-2 {
            left: calc(50% - 20px);
            top: -10px;
        }
        .dot.dot-3 {
            left: calc(50% + 180px);
            top: 130px;
        }
        .dot.dot-4 {
            left: calc(50% - 80px);
            top: 160px;
        }
        img {
            height: 80px;
        }
    }
`;

export const Link = (): React.ReactElement | null => {
    const FIVE_SECONDS = 5000;
    const account = Store.Account.useAccount();
    const me = Store.User.useMe();
    const { role } = me;
    const history = useHistory();
    const isAdmin = Store.User.useIsAdmin();
    const isValidUser = [User.Role.Client, User.Role.Admin, User.Role.SubUser].includes(role) || isAdmin;
    const { refetch, link } = Scheduler.LinkCode.useLink(isAdmin ? account.id : undefined);

    useEffect(() => {
        let interval: number | undefined;

        if (isValidUser) {
             interval = window.setInterval(() => {
                void refetch?.();
            }, FIVE_SECONDS);

        } else {
            history.push('/');
        }

        return (): void => {
            if (interval) {
                window.clearInterval(interval);
            }
        };
    }, [refetch, isValidUser, history, role]);

    return (isValidUser ? (
    <Wrapper className='bg-white pb-32px h-100 w-100'>
            <header className='d-flex'>
                <img className='my-auto ml-32px position-relative' src="/assets/entrance-light.svg" alt="Live Control logo" />
                <div className="dot blue-dot large dot-1" />
                <div className="dot outline-dot small dot-2" />
                <div className="dot red-dot medium dot-3" />
                <div className="dot red-dot small dot-4" />
            </header>
            <div className="text-center my-16px mt-48px">
                <h2 className='font-size-24px'>Link Mobile Kit</h2>
                <p>To link your account, please enter the 4-digit code on the mobile kit.</p>
                <div className='code p-16px font-size-28px mx-auto mb-12px'>
                    <span>{link?.code}</span>
                </div>
                <span>Code expires every minute</span>
            </div>
        </Wrapper>
    ) : null
    );
};
