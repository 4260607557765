import { Str } from '@livecontrol/core-utils';
import type { Location } from '@livecontrol/scheduler/model';
import type { Dispatch, SetStateAction } from 'react';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components';

interface Props {
  locations?: Location[];
  setLocation: Dispatch<SetStateAction<Location | undefined>>;
  locationSelected?: Location;
}

const Toggle = styled(Dropdown.Toggle)`
  background-color: white !important;
  color: var(--dark) !important;
  border: 1.5px solid var(--dark);
  border-radius: 4px;
  margin: 0 !important;

  background-image: url(assets/icons/arrow.svg);
  background-repeat: no-repeat;
  background-size: 14px auto;
  background-position: right 14px center;

  &::after {
    content: none;
  }

  div {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 50px;
  }
`;

const Menu = styled(Dropdown.Menu)`
  margin: 0px !important;
`;

export const DropdownLocations = ({
  locations,
  setLocation,
  locationSelected
}: Props): React.ReactElement => (
    <Dropdown>
      <Toggle>
        <div className='d-flex align-items-center font-weight-bold'>
          {locationSelected ? Str.truncate(locationSelected.name, { length: 32 }) : 'loading...'}
        </div>
      </Toggle>

      <Menu>
        {locations?.map((location) => (
          <Dropdown.Item
            key={location.id}
            onClick={(): void => {
              setLocation(location);
            }}
          >
            { location.name}
          </Dropdown.Item>
        ))}
      </Menu>
    </Dropdown>
  );

