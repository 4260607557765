import type { ReactElement } from 'react';

interface props {
  cost: string;
  description: string;
}

export const Service = ({ cost, description }: props): ReactElement => (
  <div className='d-flex flex-column font-weight-bold '>
    <div className='text-dark font-size-15px '>
      <img className='mr-12px' src='assets/icons/Video-blue.svg' alt='video' />
      {description}
    </div>
    <span className='mt-4px ml-32px text-tertiary font-size-13px mb-20px'> {cost} </span>
  </div>
);
