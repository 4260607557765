import { ClickableCard, Device, Glyph, Grid, Loading, Theme } from '@livecontrol/core-ui';
import { Scheduler } from '@livecontrol/scheduler/store';
import { EventValidator } from '@livecontrol/scheduler/validator';
import cx from 'classnames';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';
import { Store } from '../../../store';
import type { EventContext } from '../event-store';
import { EventContextStore } from '../event-store';
import { PaymentsModal } from './payment-modal';

// Transient Props: https://stackoverflow.com/questions/57586654/styled-component-attrs-react-does-not-recognize-prop
const StyledBoxSelect = styled(Grid)<{ $isMobile: boolean; $numColumns: number }>`
  --min-width: ${({ $isMobile }): string => ($isMobile ? '300px' : '220px')};
  --num-columns: ${({ $numColumns }): number => $numColumns};
  --gap: 1rem;

  margin: 0 auto;
  max-width: calc(
    (var(--num-columns) * var(--min-width) + (var(--gap) * (var(--num-columns) - 1)))
  );
  gap: var(--gap);
  ${({ $isMobile }): SimpleInterpolation => {
    if ($isMobile) {
      return css`
        grid-template-columns: var(--min-width);
        text-align: left;
      `;
    }

    return css`
      grid-template-columns: repeat(var(--num-columns), var(--min-width));
    `;
  }}
`;

interface Option {
  disabled?: boolean;
  value: EventValidator.ClientBookingPaymentMethod;
  icon: React.ReactElement;
  label: string;
  description: string;
}

export const ClientBookingPayment = ({
  onBack,
  onContinue,
  title = 'Production options for this service'
}: ClientBookingPayment.Props): React.ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const account = Store.Account.useAccount();
  const { billing } = Scheduler.Account.useBilling(account);
  const { locations } = Scheduler.Location.useLocations(account);
  const { store }: EventContext = useContext<EventContext>(EventContextStore);

  const isMobile = Device.isMobile(Theme.useViewport());

  const onSubmit = useCallback(() => {
    if (store.clientBookingPaymentMethod === EventValidator.ClientBookingPaymentMethod.PAY_NOW) {
      setShowModal(true);
    } else {
      onContinue();
    }
  }, [onContinue, store]);

  const eventLocation = useMemo(
    () => locations?.find((location) => location.id === store.eventInfo.location),
    [locations, store.eventInfo.location]
  );

  const price = useMemo(
    () =>
      eventLocation?.isMobile
        ? billing?.client_booking.cb2_host_price
        : billing?.client_booking.cb3_host_price,
    [billing, eventLocation]
  );

  if (!billing || !price) {
    return <Loading.Delay />;
  }

  const options: Option[] = [];

  if (billing.client_booking.pay_now_enabled) {
    const disablePayNow =
      !eventLocation?.isMobile &&
      store.eventInfo.start &&
      store.eventInfo.start.diffNow().as('hours') < 24;

    options.push({
      disabled: disablePayNow,
      value: EventValidator.ClientBookingPaymentMethod.PAY_NOW,
      icon: <i className='fal fa-hand-holding-usd' />,
      label: 'Book Premium Stream',
      description: disablePayNow
        ? 'In order to use this option, your event needs to start more than 24 hours from now.'
        : `You'll cover the $${price} charge with your credit card on file now and will invoice your client later.`
    });
  }

  if (billing.client_booking.pay_later_enabled) {
    options.push({
      value: EventValidator.ClientBookingPaymentMethod.PAY_LATER,
      icon: <i className='fal fa-file-invoice-dollar' />,
      label: 'Book Standard Stream',
      description:
        "We'll send your client their stream upgrade options. If they upgrade, your credit card on file will be charged and you'll invoice them later."
    });
  }

  if (billing.client_booking.client_pays_enabled) {
    options.push({
      value: EventValidator.ClientBookingPaymentMethod.CLIENT_PAYS,
      icon: <i className='fal fa-credit-card' />,
      label: 'Book Standard Stream',
      description:
        "We'll send your client their stream upgrade options. If they upgrade, they'll pay via credit card."
    });
  }

  return (
    <>
      <h1 className='text-center'>{title}</h1>
      <p className='mt-12px text-secondary text-center'>Select one and confirm.</p>
      <StyledBoxSelect
        className='pt-16px'
        $isMobile={isMobile}
        $numColumns={Math.min(options.length, 3)}
        justifyContent='center'
      >
        {options.map((option) => (
          <ClickableCard.Button
            key={option.value}
            className='d-flex'
            disabled={option.disabled}
            status={
              store.clientBookingPaymentMethod === option.value
                ? ClickableCard.Status.Active
                : undefined
            }
            variant='tint-ø2'
            onClick={(): void => {
              if (!option.disabled) {
                store.setClientBookingPaymentMethod(option.value);
              }
            }}
          >
            <div
              key={option.value}
              className={cx(
                'd-flex align-items-center',
                isMobile
                  ? 'text-left flex-row pt-16px pb-16px'
                  : 'text-center flex-column py-32px px-16px'
              )}
            >
              <div className={cx(isMobile ? 'mx-20px' : '')}>
                <Glyph>{option.icon}</Glyph>
              </div>
              <div
                className={cx(
                  isMobile
                    ? 'd-flex flex-column justify-content-center flex-align-items-start mr-20px'
                    : 'mt-12px'
                )}
              >
                <strong>{option.label}</strong>
                <p
                  className={cx(
                    'mt-8px mb-0',
                    option.disabled ? 'font-weight-bold' : 'font-weight-normal',
                    option.disabled ? 'text-danger' : 'text-secondary',
                    isMobile ? 'text-left' : 'text-center'
                  )}
                >
                  {option.description}
                </p>
              </div>
            </div>
          </ClickableCard.Button>
        ))}
      </StyledBoxSelect>
      <div className='mt-48px d-flex flex-column align-items-center'>
        <Button
          className='btn-xwide'
          disabled={!store.clientBookingPaymentMethod}
          onClick={onSubmit}
        >
          {store.clientBookingPaymentMethod === EventValidator.ClientBookingPaymentMethod.PAY_NOW
            ? 'Confirm and pay'
            : 'Confirm'}
        </Button>
        <Button className='mt-32px text-secondary w-100' variant='link' onClick={onBack}>
          Go back
        </Button>

        {showModal && (
          <PaymentsModal
            billing={billing}
            onClose={(): void => {
              setShowModal(false);
            }}
            onSuccess={onContinue}
            price={price}
          />
        )}
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace ClientBookingPayment {
  export interface Props {
    title?: string;
    onBack: () => void;
    onContinue: () => void;
  }
}
