import cx from 'classnames';
import React from 'react';
import styled from 'styled-components';

const StyledClickableCard = styled.button<{ status?: ClickableCard.Status; variant?: string }>`
  &.x-pseudo {
    border: 2px solid
      ${({ status, variant }): string =>
        status ? `var(--${status})` : variant ? `var(--${variant})` : 'transparent'};
    border-radius: var(--border-radius-sm);
    position: relative;

    .indicator {
      position: absolute;
      top: -10px;
      right: -10px;
      margin-right: 0;
    }
  }
`;

type Props = React.PropsWithChildren<{
  className?: string;
  status?: ClickableCard.Status;
  variant?: string;
  disabled?: boolean;
  onClick?: () => void;
}>;

export namespace ClickableCard {
  export enum Status {
    Error = 'danger',
    Active = 'primary',
    Success = 'success'
  }

  export const Button = ({ className, children, status, ...rest }: Props): React.ReactElement => (
    <StyledClickableCard
      className={cx(className, 'x-pseudo', 'grid-item')}
      status={status}
      {...rest}
    >
      {status === Status.Error ? (
        <span>
          <i className='fas fa-exclamation-circle indicator bg-white text-danger font-size-20px' />
        </span>
      ) : status === Status.Success ? (
        <span>
          <i className='fas fa-check-circle indicator bg-white text-success font-size-20px' />
        </span>
      ) : (
        status === Status.Active && (
          <span>
            <i className='fas fa-check-circle indicator bg-white text-primary font-size-20px' />
          </span>
        )
      )}
      {children}
    </StyledClickableCard>
  );
}
