import { Device } from '@livecontrol/core-ui';
import { ImageUploader } from '@livecontrol/scheduler/components';
import type { Account } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { ReactElement } from 'react';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Store } from '../../../store';
import { DeleteModal } from '../page-design';

const PlayerBackgroundContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 34px;
  width: 100%;

  .text-section {
    min-width: 300px;
    width: 300px;
    margin-bottom: 20px;

    .title {
      font-weight: 600;
      font-size: 15px;
      color: #2e384d;
      margin-bottom: 2px;
    }
    .description {
      font-weight: 600;
      font-size: 13px;
      color: #9c9c9c;
      word-wrap: break-word;
      max-width: 500px;
    }
  }

  > .background-uploader.cursor-pointer {
    width: 231px !important;
    min-height: 70px;
    max-height: 144px;
  }

  .player-background-button {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 4px 11px;
    border: solid 1px #cbcbcb;
    border-radius: 4px;
    background-color: #ffffff;
    margin-top: 12px;

    .icon {
      width: 20px;
      min-width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      margin-right: 6px;
    }
    .upload {
      background-image: url('/assets/icons/Upload-black.svg');
    }
    .text {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #0d1438;
    }
  }

  .x-${Device.MOBILE} &, .x-${Device.TABLET} & {
    display: flex;
    align-items: center;

    .text-section {
      width: 100%;
      text-align: center;
      margin-right: 0px;
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }
`;

export const PlayerBackgroundSection = ({
  webplayer,
  onUpdate
}: {
  webplayer: Account.WebPlayer;
  onUpdate?: (url?: string) => void;
}): ReactElement => {
  const account = Store.Account.useAccount();
  const accountId = account.id;

  const uploadFile = ImageUploader.Helper.useUploadFile();
  const [editWebPlayer] = Scheduler.Account.WebPlayer.useEdit();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onChange = useCallback(
    async ({ blob, type }: { blob: Blob; type: string }): Promise<void> => {
      const url = await uploadFile({
        blob,
        type,
        name: 'background'
      });

      onUpdate?.(url);
      await editWebPlayer(accountId, { background: url });
    },
    [accountId, editWebPlayer, onUpdate, uploadFile]
  );

  const clickOnBtn = (): void => {
    const playerLogoPreviewBtn = document.querySelector('.background-uploader');

    if (playerLogoPreviewBtn instanceof HTMLElement) {
      playerLogoPreviewBtn.click();
    }
  };

  return (
    <PlayerBackgroundContainer>
      <div className='text-section'>
        <div className='title mb-4px'>Player Background</div>
        <p className='description'>
          For the best results on all devices, use an image that’s at least 1920 x 1080 pixels and
          6MB or less.
        </p>
      </div>
      <ImageUploader.Uploader
        style={{ width: '100%' }}
        name='Background'
        aspectRatio={16 / 9}
        aspectRatioLabel='16:9'
        src={webplayer.background}
        onChange={onChange}
        onRemove={(): void => {
          setShowDeleteModal(true);
        }}
        className='background-uploader'
      />
      <button className='player-background-button ' type='button' onClick={clickOnBtn}>
        <div className='icon upload' /> <div className='text'>Change background</div>
      </button>
      <DeleteModal
        show={showDeleteModal}
        onClose={(): void => {
          setShowDeleteModal(false);
        }}
        onDelete={(): void => {
          setShowDeleteModal(false);
          onUpdate?.();
        }}
        name='background'
      />
    </PlayerBackgroundContainer>
  );
};
