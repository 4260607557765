import { Modal } from '@livecontrol/scheduler/components';
import type { Account } from '@livecontrol/scheduler/model';
import type { ReactElement } from 'react';
import styled from 'styled-components';

interface Props {
  onClose: () => void;
  billing: Account.Billing;
}

const Icon = styled.img`
  height: 40px;
  width: 40px;
  display: inline-grid;
  place-items: center;
  margin-botton: 16px;
`;

const Wrapper = styled.div`
  color: var(--gray-light);
`;

export const CreditsFail = ({ onClose, billing }: Props): ReactElement => (
  <>
    <Modal.Header closeButton>
      <Icon src='/assets/icons/add-payment-card.svg' alt='payment-card' />
      <Modal.Title className='mb-8px font-size-20px font-weight-bold'>
        {billing.stripe.defaultLast4CardNumber === 'last4_not_found'
          ? 'Add Payment Method'
          : 'Transaction Failed'}
      </Modal.Title>

      <Wrapper className='text-center font-size-15px font-weight-bold line-height-lg'>
        {billing.stripe.defaultLast4CardNumber === 'last4_not_found'
          ? 'You don’t have a payment method set. Please add a payment method and then you will be able to purchase additional credits.'
          : 'Oh no, your credit purchase failed. Please update your billing details and then try again.'}
      </Wrapper>
    </Modal.Header>
    <Modal.Footer style={{paddingLeft: '80px', paddingRight: '80px'}}>
      <Modal.ButtonBox>
        <Modal.Button
          variant='primary'
          className='btn-wide font-size-14px font-weight-bold'
          onClick={(): void => {
            onClose();
            window.open(billing.stripe.url, '_blank')?.focus();
          }}
        >
          Update payment method
        </Modal.Button>
        <Modal.Button
          variant='bg-transparent'
          className='btn-wide font-size-14px font-weight-bold text-secondary'
          onClick={onClose}
        >
          Cancel
        </Modal.Button>
      </Modal.ButtonBox>
    </Modal.Footer>
  </>
);
