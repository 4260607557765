import { Button } from '@livecontrol/core-ui';
import { Num } from '@livecontrol/core-utils';
import { Modal } from '@livecontrol/scheduler/components';
import type { Account } from '@livecontrol/scheduler/model';
import React from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import type { CreditPurchaseModalProps } from './credits-modal';

interface Props {
  onClose: () => void;
  modalProps: CreditPurchaseModalProps;
  updatePriceAndAmount: (price: number, amount: number) => void;
  billing: Account.Billing;
}

const Icon = styled.img`
  height: 40px;
  width: 40px;
  display: inline-grid;
  place-items: center;
  margin-botton: 16px;
`;

const Wrapper = styled.div`
  color: var(--gray-light);

  h1 {
    font-size: 64px;
  }

  .mb-28px {
    margin-bottom: 28px;
  }

  .dark-text {
    color: var(--dark) !important;
  }

  .brand {
    text-transform: capitalize;
  }
`;

export const CreditsSelect = ({
  modalProps,
  onClose,
  updatePriceAndAmount,
  billing
}: Props): ReactElement => {
  const { price } = billing.credits;
  const [amount, setAmount] = React.useState(modalProps.amount ?? 1);

  return (
    <>
      <Modal.Header closeButton>
        <Icon src='/assets/icons/dollar-coin.svg' alt='dollar coin' />

        <Modal.Title className='mb-8px font-size-20px font-weight-bold'>Add Credits</Modal.Title>

        <Wrapper className='text-center font-weight-bold font-size-16px line-height-lg'>
          Select the number of credits you wish to purchase.
        </Wrapper>
      </Modal.Header>

      <Modal.Body>
        <Wrapper>
          <div className='d-flex flex-row justify-content-center align-items-center'>
            <Button
              className='btn-round mr-48px'
              variant='outline-secondary'
              onClick={(): void => {
                setAmount(amount - 1);
              }}
              disabled={amount <= 1}
            >
              <i className='fas fa-minus font-size-12px' />
            </Button>
            <h1 className='text-primary font-weight-bold'>{amount}</h1>
            <Button
              className='btn-round ml-48px'
              variant='outline-primary'
              onClick={(): void => {
                setAmount(amount + 1);
              }}
              disabled={amount >= 100}
            >
              <i className='fas fa-plus font-size-12px' />
            </Button>
          </div>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div className='mt-8px font-weight-bold font-size-15px font-weight-bold'>{`$${price} per Credit`}</div>
          </div>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div className='mt-48px dark-text mb-8px font-size-18px font-weight-bold '>
              {`${Num.Format.us$(price * amount)!} Total`}
            </div>
          </div>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div className='font-size-15px mb-4px font-weight-bold'>Paying with</div>
            <div className='font-size-15px font-weight-bold'>
              <img className='mr-4px' src='/assets/icons/payment-card.svg' alt='payment-card' />
              <span className='brand'>{billing.stripe.defaultCardBrand}</span> *****
              {billing.stripe.defaultLast4CardNumber}
            </div>
          </div>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div className='font-weight-bold text-primary mt-16px mb-28px'>
              <a
                className='font-size-15px font-weight-bold text-primary '
                href={`${billing.stripe.url!}`}
                target='_blank'
                rel='noreferrer'
                onClick={onClose}
              >
                Change your payment method
              </a>
            </div>
          </div>
        </Wrapper>
      </Modal.Body>

      <Modal.Footer>
        <Modal.ButtonBox>
          <Modal.Button
            type='submit'
            className='btn-wide font-size-16px font-weight-bold'
            onClick={(): void => {
              updatePriceAndAmount(price, amount);
            }}
          >
            Purchase Credits
          </Modal.Button>
          <Modal.Button
            variant='bg-transparent'
            className='btn-wide font-size-16px font-weight-bold text-secondary'
            onClick={onClose}
          >
            Cancel
          </Modal.Button>
        </Modal.ButtonBox>
      </Modal.Footer>
    </>
  );
};
