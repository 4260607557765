import type { ApolloCache, ApolloError, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Str } from '@livecontrol/core-utils';
import { useCallback, useState } from 'react';
import type { MutationResult } from '../graphql';
import { Errors } from '../graphql';

interface TVariables {
  location: string;
}

interface TData {
  deleteLocation: {
    id: string;
  };
}

const MUTATION = gql`
  mutation DeleteLocation($location: String!) {
    deleteLocation(location: $location) 
  }
`;

interface Args {
  location: string;
}

export const useDelete = (): [
  (args: Args) => Promise<boolean>,
  MutationResult<boolean, 'success'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [success, setSuccess] = useState<boolean>();

  return [
    useCallback(
      async (args: Args): Promise<boolean> => {
        let success_ = false;

        try {
          // Parse the input arguments
          const {location} = args;

          if (!location) {
            throw Errors.badRequest();
          }

          // Execute the GraphQL mutation
          await mutation({
            variables: { location },
            update(cache: ApolloCache<unknown>, { data }: FetchResult<TData>): void {
              const orphan = data?.deleteLocation;

              // Evict the deleted contact from the cache
              if (orphan) {
                const identity = cache.identify({ location, __typename: 'Location' });

                if (!identity || !cache.evict({ id: identity })) {
                  // eslint-disable-next-line no-console
                  console.error(`Unable to evict contact: ${identity ?? Str.NULL}`);
                }
              }
            }
          })
            .then(({ data }: FetchResult<TData>) => data?.deleteLocation)
            .catch((_error: ApolloError) => {
              throw Errors.serverError();
            });

          success_ = true;
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setSuccess(success_);

        return success_;
      },
      [mutation, setError, setSuccess]
    ),
    {
      success,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
