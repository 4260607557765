import type { Obj } from '@livecontrol/core-utils';
import { Num, Time } from '@livecontrol/core-utils';
import { Asset } from '@livecontrol/scheduler/model';
import type { FormikConfig } from 'formik';
import nextTick from 'next-tick';
import qs from 'qs';
import { useRef, useState } from 'react';
import { Alert, Store, store } from '../../../../store';

type Users = Asset.Analytics.Viewers['users'];

export interface Props {
  asset: Asset.Like;
  users: Users;
  onClose: () => void;
}

export const REPORT_TYPE = {
  ONLY_THIS_ASSET: '0',
  PAST_90_DAYS_ASSETS: '90',
  VIEWERS: 'users'
} as const;

interface Values {
  report: string;
}

interface Form {
  formik: FormikConfig<Values>;
  loading: boolean;
  alert: Alert;
}

function download(blob: Blob): void {
  const a = document.createElement('a');

  a.href = window.URL.createObjectURL(blob);
  a.download = 'export.csv';
  document.body.append(a);

  a.click();
  a.remove();
}

function csvmaker(users: Users): string | undefined {
  if (users.length <= 0) {
    return undefined;
  }

  const csvRows: string[] = [];
  const change: Record<string, string> = { name: 'Name', lastName: 'Last Name', email: 'Email' };
  const headers = Object.keys(users[0]).map((header) => change[header]);

  csvRows.push(headers.join(','));

  users.forEach((user) => {
    const userCopy = { ...user };

    userCopy.email = user.email ? user.email : ' ';

    const values = Object.values(user)
      .filter((value) => typeof value !== 'object')
      .join(',');

    csvRows.push(values);
  });

  return csvRows.join('\n');
}

export const useForm = ({ asset, users, onClose }: Props): Form => {
  const account = Store.Account.useAccount();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  const alert = Alert.useAlert(error);

  const initialValues = useRef({ report: REPORT_TYPE.ONLY_THIS_ASSET }).current;

  const formik = {
    initialValues,

    validate(): void {
      alert.hide();
    },

    async onSubmit(data: Values): Promise<void> {
      alert.hide();

      if (data.report === REPORT_TYPE.VIEWERS) {
        const csv = csvmaker(users);

        if (csv) {
          const blob = new Blob([csv], { type: 'text/csv' });

          download(blob);
        }

        return;
      }

      // If not loading, execute the mutation
      if (!loading) {
        setLoading(true);

        const { environment, token } = store.getState();

        const url = ((): string => {
          let u = `${environment.REST_URI}/api/v1/asset/${account.id}`;

          let args: Obj.Rec = {
            tz: account.timezone.name
          };

          const lookback = Math.abs(Num.normalize(data.report) ?? 0);

          if (lookback === 0) {
            // Export just this asset
            u += `/${Asset.toId(asset)!}`;
          } else {
            const now = Time.now();

            args = {
              ...args,
              min: now.timestamp.minus({ day: lookback }).toISO(),
              max: now.iso
            };
          }

          return `${u}/export?${qs.stringify(args)}`;
        })();

        await fetch(url, {
          headers: {
            Authorization: `Bearer ${token!.jwt}`
          }
        })
          .then(async (response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }

            const blob = await response.blob();

            download(blob);

            nextTick(() => {
              setLoading(false);
              onClose();
            });
          })
          .catch((_error: Error) => {
            setError(new Error('Unable to export analytics.  Please try again later.'));
            setLoading(false);
          });
      }
    }
  };

  return {
    formik,
    loading,
    alert
  };
};
