import React from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import styled from 'styled-components';
import { Device } from '../device';
import { Theme } from '../theme';
import type { Viewport } from '../viewport';

const Container = styled.div<{ viewport: Viewport }>`
  background-color: var(--white);
  border: 2px solid var(--tint-ø2);
  border-radius: var(--border-radius-sm);
  padding: ${({ viewport }): string => `var(--spacer-${Device.isMobile(viewport) ? 16 : 32}px)`};
`;

export const Panel = ({
  children,
  ...rest
}: React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>): ReactElement => {
  const viewport = Theme.useViewport();

  return (
    <Container {...rest} viewport={viewport}>
      {children}
    </Container>
  );
};
