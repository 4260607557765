import React, { Component } from 'react';
import type { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { Card as Card_ } from './card';
import { Collapsible as Collapsible_ } from './collapsible';

const Container = styled.ol`
  line-height: var(--line-height-sm);
`;

// eslint-disable-next-line react/prefer-stateless-function
export class Stack extends Component<Stack.Props> {
  public override render(): ReactNode {
    return React.createElement(Container, this.props);
  }
}

export namespace Stack {
  export type Props = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

  /* eslint-disable @typescript-eslint/no-unused-vars */
  export import Card = Card_;
  export import Collapsible = Collapsible_;
}
