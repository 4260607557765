import { Device, Theme } from '@livecontrol/core-ui';
import { Contact, User } from '@livecontrol/scheduler/model';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Form } from '../../../components';

const { Constraints } = Contact;

const Body = styled.div`
  .divider {
    border-top: 1px solid #d5daea;
  }

  .group {
    padding-top: var(--spacer-24px);
    padding-bottom: var(--spacer-12px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;

    .x-${Device.DESKSTOP} & {
      max-width: 880px;
    }

  .label {
    min-width: 285px;
    width: 285px;
    margin-bottom: 10px;
    margin-right: 26px;
    font-weight: bold;
    color: var(--dark);
    font-size: 15px;

    .x-${Device.DESKSTOP} & {
      max-width: 880px;
    }

    .subtitle {
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      margin-top: 2px;      
      color: #8798AD;


      .x-${Device.DESKSTOP} & {
        max-width: 225px;
      }
    }
  }

  .extension {
    margin-bottom: 1rem;
  }  

`;

export const Information = ({ me }: { me: User }): ReactElement => {
  const viewport = Theme.useViewport();

  return (
    <div className='mt-56px'>
      <Body>
        {/* <Form.Alert show={alert.show} onClose={alert.hide}>
                  {alert.message}
                </Form.Alert> */}

        <div className='divider' />
        <div className='group flex-column flex-lg-row'>
          <div className='label'> First Name </div>

          <Form.Group
            name='firstName'
            label={false}
            className='col pl-0 pr-0'
            controlProps={{
              placeholder: 'First Name',
              maxLength: Constraints.NAME_MAX_LENGTH
            }}
          />
        </div>

        <div className='divider' />
        <div className='group  flex-column flex-lg-row'>
          <div className='label'> Last Name </div>
          <Form.Group
            name='lastName'
            label={false}
            className='col pl-0 pr-0'
            controlProps={{
              placeholder: 'Last Name',
              maxLength: Constraints.NAME_MAX_LENGTH
            }}
          />
        </div>

        <div className='divider' />
        <div className='group flex-column flex-lg-row flex-nowrap'>
        <div className='label'> Email 

          {me.role === User.Role.Client && (
            <div className='subtitle'>
              Please reach out to Customer Success at{' '}
              <a href='mailto:customersuccess@livecontrol.io' rel='noopener noreferrer'>
                customersuccess@livecontrol.io
              </a>{' '}
              to update the Main Account Holder email address.
            </div>
          )}
        </div>
          <Form.Group
            name='email'
            label={false}
            className='col pl-0 pr-0'
            controlProps={{
              placeholder: 'Email Address',
              maxLength: Constraints.EMAIL_MAX_LENGTH,
              disabled: true
            }}
          />
        </div>

        <div className='divider' />
        <div className='group flex-column flex-lg-row'>
          <div className='label'>
            <div>Mobile Phone</div>
            <div className='subtitle'>Receive text messages</div>
          </div>
          <Form.Group
            name='phoneNumber'
            label={false}
            className='col pl-0 pr-0'
            controlProps={{
              as: Form.Phone
            }}
          />
        </div>

        <div className='divider' />
        <div className='group flex-column flex-lg-row'>
          <div className='label'> Landline Phone </div>

          <Form.Group
            name='landline'
            className='phoneGroup col-lg-4 col-xl-5 pl-0 pr-0 pr-lg-16px'
            label={false}
            controlProps={{ as: Form.Phone }}
          />
          <Form.Group
            style={{
              marginBottom: Device.isMobile(viewport)
                ? '24px'
                : Device.isTablet(viewport)
                ? '12px'
                : ''
            }}
            name='landlineExtension'
            className='extension col pl-0 pr-0'
            label={false}
            controlProps={{ placeholder: 'Extension' }}
          />
        </div>

        <div className='divider' />
        <div className='group flex-column flex-lg-row'>
          <div className='label'> Preferred Contact Method </div>
          <Form.Group
            name='preferredContactMethod'
            label={false}
            className='col pl-0 pr-0'
            controlProps={{
              as: 'select'
            }}
          >
            <option value='' />
            <option value='email'>Email</option>
            <option value='call'>Call</option>
            <option value='text'>Text</option>
          </Form.Group>
        </div>

        <div className='divider' />
        <div className='group flex-column flex-lg-row'>
          <div className='label'>
            <div>Additional Information</div>
            <div className='subtitle'>Help our support team contact the right person</div>
          </div>

          <div className='divider' />
          <Form.Group
            name='additionalInformation'
            label={false}
            className='col pl-0 pr-0'
            controlProps={{
              as: 'textarea',
              placeholder: 'Additional Information',
              style: {
                minHeight: 95,
                backgroundColor: 'white'
              }
            }}
          />
        </div>
        <div className='divider' />
      </Body>
    </div>
  );
};
