/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Filters } from '@livecontrol/scheduler/components';

import type { ReactElement } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import A = Filters.Action;

export const Action = ({ actions, ...props }: Omit<A.Props, 'value'>): ReactElement | null => (
  <A value={actions} {...props} onChange={actions.update} as={ButtonGroup} />
);
