import { Form } from '@livecontrol/core-ui';
import type { User } from '@livecontrol/scheduler/model';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { EventDetailsForm } from '../event-details';
import type { EventContext } from '../event-store';
import { EventContextStore } from '../event-store';
import { ContentSection, CustomInputContainer } from '../layout-sections';
import { PaidFeatureModal } from '../modals';

const CustomTitle = styled.div`
  display: flex;
  .new-feature-tag {
    margin-left: 8px;
    width: 33px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff505f;
    border-radius: 20px;
    color: #ffffff;
    font-size: 9px;
  }
  .paid-feature-section {
    width: 25px;
    height: 15px;
    overflow: visible;
    margin-left: 6px;
    position: relative;
    .icon {
      width: 25px;
      height: 25px;
      background-image: url(/assets/icons/Paid-feature.svg);
      background-size: cover;
      background-position: center center;
      position: absolute;
      top: -7px;
    }
  }
`;

const CustomDescription = styled.div`
  .link-to-article {
    display: inline-block;
    margin-top: 1px;

    .icon {
      margin-left: 2px;
      width: 12px;
      height: 12px;
      background-image: url(/assets/icons/Info-article-blue.svg);
      background-size: cover;
      background-position: center center;
    }
  }
`;

const StyledInputSection = styled.div`
  width: 100%;
  .form-group {
    max-width: 440px;
    label {
      font-size: 15px;
      font-weight: 600;
      color: #2e384d;
    }
    input::placeholder,
    textarea::placeholder {
      font-style: normal;
    }
  }
`;

export const VideographerFeatures = ({
  isPayingFeatures = true,
  showZoomInput = false,
  slidesOn = false,
  accountSettings
}: {
  isPayingFeatures?: boolean;
  showZoomInput?: boolean;
  slidesOn?: boolean;
  accountSettings: User.admin;
}): React.ReactElement => {
  const { store }: EventContext = useContext<EventContext>(EventContextStore);

  const [showPaidFeatureModal, setShowPaidFeatureModal] = useState(false);

  const showISORecordings =
    accountSettings.userAccountDetails?.accountType?.name.toLowerCase() === 'pro' ||
    accountSettings.userAccountDetails?.accountType?.name.toLowerCase() === 'channel pro';

  const showCueSheetsField = accountSettings.userGlobalSettings.cueSheet;

  if (
    !showCueSheetsField &&
    !showISORecordings &&
    !slidesOn &&
    !showZoomInput &&
    !store.eventInfo.zoomDetails?.streamZoom
  ) {
    return <div />;
  }

  return (
    <ContentSection style={{ maxWidth: '870px', marginTop: '50px' }}>
      <CustomInputContainer>
        <Form.Descriptor
          title='Videographer Features'
          titleClassName='title mr-lg-3 font-size-15px font-weight-bold text-dark mt-24px'
          description='Add features to your event. '
          className='form-section-lateral d-lg-flex'
          descriptionClassName='font-size-13px text-secondary font-weight-bold text-gray-light mb-8px'
        >
          <div className='d-flex flex-column w-100'>
            {/* CUE Sheets */}
            {showCueSheetsField && (
              <div>
                <EventDetailsForm.CueSheets
                  setShowPaidModal={(): void => {
                    setShowPaidFeatureModal(true);
                  }}
                />
              </div>
            )}

            {/* ISO Recording */}
            {showISORecordings && (
              <Form.SwitchCustomField
                abledDescription={false}
                className='mt-24px'
                title={
                  <CustomTitle>
                    Receive ISO Recordings <div className='new-feature-tag'>New</div>
                    <div
                      className='paid-feature-section'
                      role='button'
                      tabIndex={0}
                      onClick={(e): void => {
                        e.stopPropagation();
                        setShowPaidFeatureModal(true);
                      }}
                      onKeyDown={(e): void => {
                        e.stopPropagation();
                        setShowPaidFeatureModal(true);
                      }}
                    >
                      <div className='icon' />
                    </div>
                  </CustomTitle>
                }
                titleClassName='title text-dark mb-4px mr-lg-3 font-size-15px font-weight-bold'
                description={
                  <CustomDescription>
                    Individual recordings from each camera, available for download after the event.
                    <a
                      className='link-to-article'
                      href='https://mylivecontrol.zendesk.com/hc/en-us/articles/18595144850199'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <div className='icon' />
                    </a>
                  </CustomDescription>
                }
                descriptionClassName='text-gray-light font-size-13px text-secondary font-weight-bold'
                name='receiveIsoRecordings'
              />
            )}

            {/* Slides */}
            {slidesOn && (
              <Form.SwitchCustomField
                abledDescription={false}
                className='mt-24px'
                title={
                  <CustomTitle>
                    Use Intro / Outro Slides <div className='new-feature-tag'>New</div>
                    <div
                      className='paid-feature-section'
                      role='button'
                      tabIndex={0}
                      onClick={(e): void => {
                        e.stopPropagation();
                        setShowPaidFeatureModal(true);
                      }}
                      onKeyDown={(e): void => {
                        e.stopPropagation();
                        setShowPaidFeatureModal(true);
                      }}
                    >
                      <div className='icon' />
                    </div>
                  </CustomTitle>
                }
                titleClassName='title text-dark mb-4px mr-lg-3 font-size-15px font-weight-bold'
                description={
                  <CustomDescription>
                    The videographer will activate the pre-installed slides.
                    <Link
                      className='d-none text-link'
                      to='/stream-settings/web-player'
                      target='_blank'
                      rel='noreferrer noopener'
                    >
                      &nbsp;Stream Preferences tab.
                    </Link>
                    <a
                      className='link-to-article'
                      href='https://mylivecontrol.zendesk.com/hc/en-us/articles/18612610035863'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <div className='icon' />
                    </a>
                  </CustomDescription>
                }
                descriptionClassName='text-gray-light font-size-13px text-secondary font-weight-bold'
                name='useIntroAndOutroSlides'
              />
            )}

            {/* Join Zoom */}
            {showZoomInput && (
              <Form.SwitchCustomField
                abledDescription={false}
                className='mt-24px'
                title={
                  <CustomTitle>
                    Join Zoom Event{' '}
                    <div
                      className='paid-feature-section'
                      role='button'
                      tabIndex={0}
                      onClick={(e): void => {
                        e.stopPropagation();
                        setShowPaidFeatureModal(true);
                      }}
                      onKeyDown={(e): void => {
                        e.stopPropagation();
                        setShowPaidFeatureModal(true);
                      }}
                    >
                      <div className='icon' />
                    </div>
                  </CustomTitle>
                }
                titleClassName='title text-dark mb-4px mr-lg-3 font-size-15px font-weight-bold'
                description={
                  <CustomDescription>
                    Stream your event to Zoom. You’ll need to enter your Meeting ID & Password.
                    <a
                      className='link-to-article'
                      href='https://mylivecontrol.zendesk.com/hc/en-us/articles/11775480693143-Zoom-Add-On'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <div className='icon' />
                    </a>
                  </CustomDescription>
                }
                descriptionClassName='text-gray-light font-size-13px text-secondary font-weight-bold'
                name='zoomDetails.streamZoom'
              />
            )}

            {showZoomInput && store.eventInfo.zoomDetails?.streamZoom && (
              <StyledInputSection className='ml-28px mt-20px'>
                <Form.Group
                  name='zoomDetails.meetingId'
                  label='Zoom Meeting ID'
                  controlProps={{
                    type: 'text',
                    placeholder: 'Meeting ID',
                    className: 'mr-8px',
                    autoSelect: true
                  }}
                />
                <Form.Group
                  name='zoomDetails.password'
                  label='Zoom Meeting Password'
                  controlProps={{
                    type: 'text',
                    className: 'mr-8px',
                    placeholder: 'Password'
                  }}
                />
              </StyledInputSection>
            )}
          </div>
        </Form.Descriptor>
        <PaidFeatureModal
          show={showPaidFeatureModal}
          isPaid={isPayingFeatures}
          onClose={(): void => {
            setShowPaidFeatureModal(false);
          }}
        />
      </CustomInputContainer>
    </ContentSection>
  );
};
