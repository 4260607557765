import type { PropsWithChildren, ReactElement } from 'react';
import type { DropEvent, FileRejection } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const ACCEPTED_FORMATS = [
  'application/pdf', // .pdf
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // .xlsx
];

const StyledDiv = styled.div`
  max-width: 30rem;
  margin: 0 auto;

  background: var(--tint-ø2);
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--tint-ø1);
  display: inline;
`;

export const FileDrop = ({ children, className, onDrop }: FileDrop.Props): ReactElement => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ACCEPTED_FORMATS.join(',')
  });

  return (
    <StyledDiv className={className} {...getRootProps()}>
      <input type='file' {...getInputProps()} multiple={false} />
      {children}
    </StyledDiv>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace FileDrop {
  export type Props = PropsWithChildren<{
    className?: string;
    onDrop?: <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => void;
  }>;
}
