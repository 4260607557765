import { Modal } from '@livecontrol/scheduler/components';
import type { Account } from '@livecontrol/scheduler/model';
import React from 'react';
import type { ReactElement } from 'react';
import { CreditsConfirm } from './credits-confirm';
import { CreditsFail } from './credits-fail';
import { CreditsSelect } from './credits-select';
import { CreditsSuccess } from './credits-success';

interface Props {
  onClose: () => void;
  billing: Account.Billing;
}

export interface CreditPurchaseModalProps {
  price?: number;
  amount?: number;
  step: 'Confirm' | 'Fail' | 'Select' | 'Success';
}

export const CreditsModal = (props: Props): ReactElement => {
  const { onClose, billing } = props;

  const [modalProps, setModalProps] = React.useState<CreditPurchaseModalProps>({
    price: undefined,
    amount: 1,
    step: billing.stripe.defaultLast4CardNumber === 'last4_not_found' ? 'Fail' : 'Select'
  });

  const updatePriceAndAmount = (price: number, amount: number): void => {
    setModalProps({
      price,
      amount,
      step: 'Confirm'
    });
  };

  const confirmTotal = (result: 'Fail' | 'Success'): void => {
    setModalProps({
      ...modalProps,
      step: result
    });
  };

  return (
      <Modal className='credits-modal' show backdrop='static' keyboard={false} onHide={onClose} >
        {modalProps.step === 'Confirm' ? (
          <CreditsConfirm
            billing={billing}
            modalProps={modalProps}
            onClose={onClose}
            confirmTotal={confirmTotal}
          />
        ) : modalProps.step === 'Success' ? (
          <CreditsSuccess onClose={onClose} />
        ) : modalProps.step === 'Fail' ? (
          <CreditsFail billing={billing} onClose={onClose} />
        ) : (
          <CreditsSelect
            billing={billing}
            modalProps={modalProps}
            onClose={onClose}
            updatePriceAndAmount={updatePriceAndAmount}
          />
        )}

      </Modal>
  );
};
