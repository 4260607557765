import { Matrix } from '@livecontrol/scheduler/components';
import type { Location, User } from '@livecontrol/scheduler/model';
import { useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';

import G = Matrix.Grid;

const Row = styled(Matrix.Grid.Row)`
  position: relative;

  .users {
    position: sticky;
    left: 0;
  }

  img {
    cursor: default;
  }

  button {
    visibility: hidden;
    display: flex !important;
  }

  &:hover {
    .column {
      border-color: #2e5bff !important;
    }

    button {
      visibility: visible !important;
      display: flex !important;
    }
  }
`;

const CellNoBorder = styled(Matrix.Grid.Row.Cell)`
  border: 0px solid transparent;
  justify-content: center;
  padding: 14px 0px;

  &:first-child {
    position: sticky;
    left: 0;
  }

  &:last-child {
    padding: 5px 0px !important;
  }
`;

const Cell = styled(Matrix.Grid.Row.Cell)`
  border-color: #d5daea;
  justify-content: center;
  padding: 25px 0px;

  &:first-child {
    border-top-left-radius: var(--spacer-4px);
    border-bottom-left-radius: var(--spacer-4px);
    position: sticky;
    left: 0;
  }
  &:last-child {
    border-top-right-radius: var(--spacer-4px);
    border-bottom-right-radius: var(--spacer-4px);
    padding: 14px 0px;
  }
`;

interface Props {
  location: Location;
  me: User;
}

export const RowLocation = ({ location, me }: Props): ReactElement => {
  const [locationPermissions, setLocationPermissions] = useState<
    User.LocationPermissions | undefined
  >();

  useEffect(() => {

    if(me.role === 6) {

      setLocationPermissions( {
        id: '',
        location,
        eventManagment: true,
        viewAndDownload: true,
        contentManagment: true,
        activityLog: true,
        productionNotes: true
      });

    } else if (me.role === 7 && me.permissions.admin) {

      setLocationPermissions( {
        id: '',
        location,
        eventManagment: true,
        viewAndDownload: true,
        contentManagment: true,
        activityLog: true,
        productionNotes: true
      });

    } else if (me.sub_user_locations && me.sub_user_locations.length > 0) {
      const locationFinded = me.sub_user_locations.find(
        (subuserlocation) => subuserlocation.location.id === location.id
      );

      setLocationPermissions(locationFinded);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  return (
    <>
      <G.Row>
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
      </G.Row>
      <Row>
        <Cell className='column justify-content-start'>
          <div className='d-flex flex-column pr-4px'>
            <div className='text-dark font-weight-bold font-size-12px'>{location.name}</div>
          </div>
        </Cell>
        <Cell className='column' />
        <Cell className='column' />
        <Cell className='column' />
        <Cell className='column'>
          {me.permissions.admin || locationPermissions?.eventManagment ? (
            <img alt='able' src='assets/icons/check-blue.svg' />
          ) : (
            <img alt='able' src='assets/icons/close.svg' />
          )}
        </Cell>
        <Cell className='column'>
          {me.permissions.admin || locationPermissions?.viewAndDownload ? (
            <img alt='able' src='assets/icons/check-blue.svg' />
          ) : (
            <img alt='able' src='assets/icons/close.svg' />
          )}
        </Cell>
        <Cell className='column'>
          {me.permissions.admin || locationPermissions?.contentManagment ? (
            <img alt='able' src='assets/icons/check-blue.svg' />
          ) : (
            <img alt='able' src='assets/icons/close.svg' />
          )}
        </Cell>
        <Cell className='column'>
          {me.permissions.admin || locationPermissions?.productionNotes ? (
            <img alt='able' src='assets/icons/check-blue.svg' />
          ) : (
            <img alt='able' src='assets/icons/close.svg' />
          )}
        </Cell>
      </Row>
    </>
  );
};
