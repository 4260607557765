import { AspectRatio } from '@livecontrol/core-ui';
import React, { useState } from 'react';
import { Modal } from '../modal';
import { ImageCropper } from './image-cropper';

interface Props {
  show: boolean;
  src: string;
  aspectRatio: number;
  aspectRatioLabel: string;
  imageName?: string;
  onHide?: () => void;
  onUpdate?: (img: Blob) => void;
}

export const CropperModal = ({
  show,
  src,
  aspectRatio,
  aspectRatioLabel,
  imageName = 'Image',
  onHide,
  onUpdate
}: Props): React.ReactElement => {
  const [blob, setBlob] = useState<Blob | null>(null);

  return (
    <Modal show={show} backdrop='static' onHide={onHide}>
      <Modal.Header
        closeButton
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        <h1>{`Upload Your ${imageName}`}</h1>

        <p
          className='p-8px mt-20px'
          style={{
            backgroundColor: 'rgba(46,91,255,0.03)',
            border: '1px solid rgba(46, 91, 255, 0.08)'
          }}
        >
          Click, drag, and zoom (mouse wheel) on the image below to crop it. Your crop area is fixed
          to an aspect ratio of {aspectRatioLabel}.
        </p>
      </Modal.Header>
      <Modal.Body
        style={{
          boxShadow: '0px 0px 10px 3px #cfcfcf'
        }}
      >
        <AspectRatio ratio={aspectRatio}>
          <ImageCropper setBlob={setBlob} src={src} aspectRatio={aspectRatio} />
        </AspectRatio>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonBox>
          <Modal.Submit
            onClick={(): void => {
              onHide?.();

              if (blob) {
                onUpdate?.(blob);
              }
            }}
          >
            Save & Close
          </Modal.Submit>
          <Modal.Button onClick={onHide} variant='light'>
            Close
          </Modal.Button>
        </Modal.ButtonBox>
      </Modal.Footer>
    </Modal>
  );
};
