/* eslint-disable @typescript-eslint/no-use-before-define */
import { Form } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import { Scheduler } from '@livecontrol/scheduler/store';
import { validateCuesheetURL } from '@livecontrol/scheduler/validator';
import Axios from 'axios';
import cx from 'classnames';
import React, { useCallback, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import styled from 'styled-components';
import { FileDrop } from './file-drop';

const CustomTitle = styled.div`
  display: flex;
  .new-feature-tag {
    margin-left: 8px;
    width: 33px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff505f;
    border-radius: 20px;
    color: #ffffff;
    font-size: 9px;
  }
  .paid-feature-section {
    width: 25px;
    height: 15px;
    overflow: visible;
    margin-left: 6px;
    position: relative;
    .icon {
      width: 25px;
      height: 25px;
      background-image: url(/assets/icons/Paid-feature.svg);
      background-size: cover;
      background-position: center center;
      position: absolute;
      top: -7px;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  max-width: 560px;

  textarea {
    background-color: #ffffff !important;
    border: solid 1px #cbcbcb;
    color: #a3a3a3;
    font-weight: 600;
  }
  .modalTab {
    font-weight: 600;
    font-size: 12px;
    line-height: 32px;
    color: #9c9c9c;
    padding: 6px 20px;
    &.active {
      font-weight: 600;
      font-size: 12px;
      line-height: 32px;
      color: #2e384d;
    }
  }
  .uploadDescription {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #9c9c9c;
  }
  .fileDropSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > div {
      background-color: #f7fafc;
      border: solid 2px #2e5bff;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      max-width: 290px;
      width: 90%;
      min-height: 110px;
      .altBg {
        width: 100%;
        height: 100%;
        background-color: #f7fafc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .previewIconSection {
        width: 54px;
        height: 54px;
        background: rgba(46, 91, 255, 0.1);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        .iconSection {
          width: 40px;
          height: 40px;
          background-size: cover;
          background-position: center;
          background-image: url(/assets/icons/Upload-cloud-blue.svg);
        }
      }
      .displayText {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: #2e384d;
      }
    }
  }
`;

const Icon = styled.img`
  height: 40px;
  width: 40px;
  display: inline-grid;
  place-items: center;
  margin-botton: 16px;
`;

const MAX_FILE_SIZE_IN_MB = 10;
const getFileSizeInMB = (size: number): number => size / 1024 / 1024;

interface Props {
  className?: string;
  setShowPaidModal?: () => void;
}

export const CueSheets = ({ className, setShowPaidModal }: Props): React.ReactElement => {
  const field = Form.Utils.useField<string | undefined>('cueSheetURL');

  const [showModal, setShowModal] = useState(false);

  const onToggleCuesheet = useCallback(
    (toggle: boolean) => {
      if (toggle) {
        setShowModal(true);
      } else {
        field?.helpers.setValue('');
      }
    },
    [field?.helpers]
  );

  const onUpdate = useCallback(
    (value: string) => {
      field?.helpers.setValue(value);
      setShowModal(false);
    },
    [field?.helpers]
  );

  return (
    <div className={cx('mb-8px mt-20px', className)}>
      <Form.SwitchField
        abledDescription={false}
        className='mt-24px'
        title={
          <CustomTitle>
            Upload Cue Sheet{' '}
            {setShowPaidModal && (
              <div
                className='paid-feature-section'
                role='button'
                tabIndex={0}
                onClick={(e): void => {
                  e.stopPropagation();
                  setShowPaidModal();
                }}
                onKeyDown={(e): void => {
                  e.stopPropagation();
                  setShowPaidModal();
                }}
              >
                <div className='icon' />
              </div>
            )}
          </CustomTitle>
        }
        titleClassName='title text-dark mb-4px mr-lg-3 font-size-15px font-weight-bold'
        description='Add a cue sheet that tells us the shots you want during your event.'
        descriptionClassName='text-gray-light font-size-13px text-secondary font-weight-bold'
        checked={!!field?.meta.value}
        onChange={onToggleCuesheet}
        noSaveIndicator={false}
      />

      <CueSheetModal
        show={showModal}
        fieldValue={field?.meta.value}
        setShowModal={setShowModal}
        onUpdate={onUpdate}
      />
    </div>
  );
};

function CueSheetModal({
  show,
  fieldValue,
  setShowModal,
  onUpdate
}: {
  show: boolean;
  setShowModal: (show: boolean) => void;
  fieldValue?: string;
  onUpdate: (value: string) => void;
}): React.ReactElement {
  type Tab = 'link' | 'upload';

  const [uploadedFile, setUploadedFile] = useState<{ url: string; name: string }>();
  const [link, setLink] = useState<string | undefined>(fieldValue);

  const [tab, setTab] = useState<Tab>(fieldValue ? 'link' : 'upload');

  const onClose = useCallback(() => {
    // Restore tab on closing the modal.
    if (fieldValue === uploadedFile?.url) {
      setTab('upload');
    } else if (fieldValue === link) {
      setTab('link');
    }

    setShowModal(false);
  }, [fieldValue, link, setShowModal, uploadedFile?.url]);

  const value = tab === 'link' ? link : uploadedFile?.url;
  const isValid = value ? validateCuesheetURL(value).cueSheetURL === undefined : false;

  const onSave = useCallback(() => {
    if (value && isValid) {
      onUpdate(value);
    }
  }, [isValid, onUpdate, value]);

  return (
    <div>
      {fieldValue && (
        <p
          className='text-secondary overflow-hidden mt-4px'
          style={{
            marginTop: -14,
            lineHeight: '1.5em',
            maxHeight: '3.0em',
            wordBreak: 'break-all'
          }}
        >
          <button
            type='button'
            onClick={(): void => {
              setShowModal(true);
            }}
            className='x-pseudo cursor-pointer'
          >
            <i className='far fa-edit' />
          </button>
          <a
            href={fieldValue}
            target='_blank'
            className='ml-8px text-secondary'
            style={{ textDecoration: 'underline' }}
            rel='noreferrer'
          >
            {fieldValue === uploadedFile?.url ? uploadedFile.name : fieldValue}
          </a>
        </p>
      )}
      <Modal show={show} onHide={onClose} keyboard={false} backdrop='static'>
        <div className='d-flex justify-content-center'>
          <Icon src='/assets/icons/File-blue.svg' alt='file' />
        </div>
        <Modal.Header closeButton>
          <h1>Upload Cue Sheet</h1>
        </Modal.Header>
        <Modal.Body>
          <ModalContent>
            <div className='text-secondary font-size-12px'>
              <Nav
                className='font-size-16px d-flex justify-content-center'
                variant='tabs'
                activeKey={tab}
                onSelect={(v): void => {
                  if (v) {
                    setTab(v as Tab);
                  }
                }}
              >
                <Nav.Link className='mr-8px modalTab' eventKey='upload'>
                  Upload File
                </Nav.Link>
                <Nav.Link className='ml-8px modalTab' eventKey='link'>
                  URL Link
                </Nav.Link>
              </Nav>
              <div style={{ minHeight: 110 }}>
                <Upload
                  onChange={setUploadedFile}
                  className={cx({ 'd-none': tab !== 'upload' })}
                  value={uploadedFile}
                  onRemove={(): void => {
                    setUploadedFile(undefined);
                  }}
                />

                <Link
                  value={link}
                  onChange={setLink}
                  className={cx({ 'd-none': tab !== 'link' })}
                />
              </div>
            </div>
          </ModalContent>
        </Modal.Body>
        <Modal.Footer>
          <Modal.ButtonBox>
            <Modal.Button disabled={!isValid} onClick={onSave}>
              Save Changes
            </Modal.Button>
            <Modal.Button variant='outline-secondary' onClick={onClose} className='link-custom'>
              Cancel
            </Modal.Button>
          </Modal.ButtonBox>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

function Link({
  value = '',
  className,
  onChange
}: {
  value?: string;
  className?: string;

  onChange: (value: string) => void;
}): React.ReactElement {
  const isValid = value ? validateCuesheetURL(value).cueSheetURL === undefined : true;

  return (
    <div className={cx('text-center mt-12px', className)}>
      <div className='uploadDescription mt-24px mb-24px'>
        Please paste a link to your Cue Sheet below, and make sure that the sharing option is
        enabled.
      </div>
      <Form.Control
        className='text-left font-size-16px'
        value={value}
        onChange={(e: { target: { value: string } }): void => {
          onChange(e.target.value);
        }}
      />
      {!isValid && (
        <div className='text-danger text-left mt-4px font-size-12px'>Please enter a valid url.</div>
      )}
    </div>
  );
}

function Upload({
  onRemove,
  value,
  onChange,
  className
}: {
  onRemove: () => void;
  value?: { name: string; url: string };
  className?: string;
  onChange: (value: { name: string; url: string }) => void;
}): React.ReactElement {
  const [getSignedS3URL] = Scheduler.Account.S3.useUpload();

  const [uploading, setUploading] = useState(false);

  const { error } = Modal.usePrompt();

  const onUpload = useCallback(
    async (acceptedFiles: File[]): Promise<void> => {
      if (uploading) {
        return;
      }

      onRemove();

      try {
        const [acceptedFile] = acceptedFiles;
        const { name, type } = acceptedFile;

        if (getFileSizeInMB(acceptedFile.size) > MAX_FILE_SIZE_IN_MB) {
          error({
            content: `File size should be less than ${MAX_FILE_SIZE_IN_MB}MB`,
            title: 'Upload Failed'
          });

          return;
        }

        const {
          url: presignedURL,
          bucket,
          key
        } = await getSignedS3URL(name, type, 'client-cue-sheet');

        if (presignedURL && bucket && key) {
          const options = {
            headers: {
              'Content-Type': type
            }
          };

          const uploadedFileURL = `https://${bucket}.s3.amazonaws.com/${encodeURIComponent(key)}`;

          setUploading(true);
          await Axios.put(presignedURL, acceptedFile, options);
          setUploading(false);

          onChange({ url: uploadedFileURL, name: acceptedFile.name });
        } else {
          throw new Error('Unknown error.');
        }
      } catch {
        setUploading(false);

        error({
          content: 'Oops! Something went wrong.',
          title: 'Upload Failed'
        });
      }
    },
    [error, getSignedS3URL, onChange, onRemove, uploading]
  );

  return (
    <div className={cx('text-center', className)}>
      <div className='uploadDescription mt-24px mb-24px'>
        Supported File Formats: PDF, DOC, DOCX, XLS, XLSX
      </div>
      <div className='fileDropSection'>
        <FileDrop className='cursor-pointer' onDrop={onUpload}>
          <div className='altBg'>
            <div className='previewIconSection'>
              <div className='iconSection' />
            </div>
            <div className='displayText mt-16px'>Upload your file</div>
          </div>
        </FileDrop>
        {value && (
          <p className='d-flex mt-20px align-items-center justify-content-center'>
            <a href={value.url} target='_blank' className='text-secondary' rel='noreferrer'>
              <i className='fa fa-link mr-4px' /> {value.name}
            </a>
            <button type='button' onClick={onRemove} className='x-pseudo cursor-pointer ml-8px'>
              <i className='fa fa-times' />
            </button>
          </p>
        )}
      </div>
    </div>
  );
}
