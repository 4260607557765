import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import type { JaySON } from '@livecontrol/core-utils';
import { Obj } from '@livecontrol/core-utils';
import { Account, Asset } from '@livecontrol/scheduler/model';
import { useEffect, useState } from 'react';
import type { QueryResult } from '../graphql';
import * as Analytics from './analytics';

interface TVariables {
  assetId: number;
  clientId: number;
  slug?: string;
}

interface TData {
  getAnalytics: {
    success?: boolean;
    result?: JaySON.Object;
  };
  getCapturedEmail: {
    success?: boolean;
    body?: {
      message?: string;
      metrics?: JaySON.Object;
    };
  };
  result: unknown;
}

export const QUERY = gql`
  query GetAnalytics($assetId: Float!, $clientId: Float!, $eventSlug: String!) {
    getAnalytics(assetId: $assetId, clientId: $clientId) {
      success
      result
    }
    getCapturedEmail(event_slug: $eventSlug, client_id: $clientId) {
      success
      body {
        metrics {
          users {
            name
            last_name
            email
          }
          registeredViewers
          capturedEmails
        }
        message
      }
    }
  }
`;

interface Args {
  asset: Asset.Like;
  account: Account.Like;
  eventSlug: string;
}

export const useAnalytics = (args: Args): QueryResult<Asset.Analytics, 'analytics'> => {

  const [error, setError] = useState<Error | undefined>();
  const [analytics, setAnalytics] = useState<Asset.Analytics | undefined>(undefined);

  // Parse the input arguments
  const variables = {
    assetId: Asset.toId(args.asset),
    clientId: Account.toId(args.account),
    eventSlug: args.eventSlug
  };

  // Validate the input
  if (!Obj.isHydrated(variables)) {
    return { loading: false, error: Exception.KABOOM };
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  // Prepare the query
  const { loading, data } = useQuery<TData, TVariables>(QUERY, {
    variables,
    onError() {
      setError(Exception.KABOOM);
    }
  });

  // When available, parse server response
  useEffect(() => {
    let analytics_: Asset.Analytics | undefined;
    let viewers_: Asset.Analytics.Viewers | undefined;
    let error_;

    if (data?.getAnalytics.success) {
      console.log("success");
      // Normalize the (first) event
      analytics_ = Analytics.toAnalytics(
        data.getAnalytics.result
      );
    }

    if (data?.getCapturedEmail.success) {
      viewers_ = Analytics.toViewers(data.getCapturedEmail.body?.metrics);

      if (analytics_) {
        analytics_.viewers = viewers_;
      }else{
        analytics_ = {
          viewers: viewers_,
          platforms: {}
        };
      }
    }

    console.log("analytics_: ", analytics_);

    setAnalytics(analytics_);
    setError(error_);
  }, [data]);

  return { error, loading, analytics };
};
