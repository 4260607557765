import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Obj } from '@livecontrol/core-utils';
import type { Destination } from '@livecontrol/scheduler/model';
import { Account } from '@livecontrol/scheduler/model';
import { useEffect, useState } from 'react';
import type { QueryResult } from '../graphql';
import * as Dbx from './dbx';

interface TVariables {
  id: string;
}

export interface TData {
  integrations?: Dbx.Record[];
}

export const QUERY = gql`
  query GetDestination($id: String!, $validate: Boolean) {
    integrations(clientId: $id, validate: $validate) {
      ...StandardDestinationResponse
    }
  }
  ${Dbx.StandardDestinationResponse}
`;

export const useDestinations = (
  args: Account.Like,
  validate = false
): QueryResult<Destination[], 'destinations'> => {
  const [error, setError] = useState<Error | undefined>();
  const [destinations, setDestinations] = useState<Destination[] | undefined>();

  // Parse the input arguments
  const variables = {
    id: Account.toId(args)?.toString(), // @fixme - API type mismatch
    validate
  };

  // Validate the input
  if (!Obj.isHydrated(variables)) {
    return { loading: false, error: Exception.KABOOM };
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  // Prepare the query
  const { loading, data } = useQuery<TData, TVariables>(QUERY, {
    variables,
    notifyOnNetworkStatusChange: true,
    onError() {
      setError(Exception.KABOOM);
    }
  });

  // When available, parse server response
  useEffect(() => {
    let destinations_;
    let error_;

    if (data) {
      destinations_ = (data.integrations ?? [])
        .map((record: Dbx.Record) => Dbx.normalize(record))
        .filter(
          (destination: Destination | undefined): destination is Destination => !!destination
        );
    }

    setDestinations(destinations_);
    setError(error_);
  }, [data]);

  return { loading, error, destinations };
};
