import { Auth } from '@livecontrol/core-ui';
import { User } from '@livecontrol/scheduler/model';
import { FormikProvider } from 'formik';
import React from 'react';
import type { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Form } from '../../components';
import { useForm } from './form';

const Content = (): ReactElement => {
  const { formik, loading, alert } = useForm();

  return (
    <>
      <Form.Alert show={alert.show} onClose={alert.hide}>
        {alert.message}
      </Form.Alert>

      <FormikProvider value={formik}>
        <Form>
          <Form.Group
            name='email'
            label='Email Address'
            controlProps={{
              autoFocus: true,
              maxLength: User.Constraints.EMAIL_MAX_LENGTH
            }}
          />

          <div className='d-grid place-items-center pt-16px'>
            <Form.Submit className='btn-xwide' loading={loading} minimum='md'>
              Send Reset Link
            </Form.Submit>
          </div>
        </Form>
      </FormikProvider>
    </>
  );
};

export const ForgotPassword = (): React.ReactElement => (
  <Auth.Layout>
    <Auth.Panel title='Forgot Password'>
      <Content />
    </Auth.Panel>

    <Auth.Linkbox>
      Remembered your password? <Link to='/login'>Login here!</Link>
    </Auth.Linkbox>
  </Auth.Layout>
);
