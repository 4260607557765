import { Device, Theme } from '@livecontrol/core-ui';
import { Num } from '@livecontrol/core-utils';
import { Asset } from '@livecontrol/scheduler/model';
import _ from 'lodash';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Loaders } from './components';
import { ExportButton } from './export';

import Analytics = Asset.Analytics;

const ViewershipContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px 5px;
  }
  .content {
    width: 100%;
    .divider {
      width: 100%;
      height: 1px;
      background-color: #e4e8f0;
      margin: 10px 0px;
    }

    .stats-row {
      display: flex;
      justify-content: space-between;
    }

    .stat-section {
      width: 116px;
      padding: 0px 4px;
      margin: 10px 0px;

      .stat {
        font-size: 16px;
        font-weight: 600;
        color: #031b4e;
        padding-bottom: 0.5rem;
      }

      .name {
        color: #68798e;
        font-size: 11px;
        font-weight: 600;
      }
    }
  }

  .x-${Device.DESKSTOP} & {
    .header,
    .content {
      max-width: 258px;
    }
  }
`;

interface Props {
  analytics?: Analytics.WebPlayer;
  viewers?: Analytics.Viewers;
  asset: Asset.Like;
  loading: boolean;
}

export const ViewershipSection = ({ analytics, viewers, asset, loading }: Props): ReactElement => {
  const viewport = Theme.useViewport();

  let avgWatchTime = '0';

  if (analytics?.retention) {
    avgWatchTime = ((): string => {
      const { average } = analytics.retention;
      const hours = average.as('hours');
      const minutes = average.as('minutes');

      return hours > 0
        ? `${average.toFormat("h'h' mm'm'")}`
        : minutes >= 1
        ? `${average.toFormat("m'm'")}`
        : '< 1 min';
    })();
  }

  const capturedEmails = viewers?.capturedEmails ?? 0;
  const registeredViewers = viewers?.registeredViewers ?? 0;
  const users = viewers?.users ?? [];

  return (
    <ViewershipContainer>
      <div className='header'>
        <div className='export-button-section'>
          {analytics && <ExportButton asset={asset} users={users} />}
        </div>
      </div>
      {loading ? (
        <Loaders.Viewership isTablet={Device.isTablet(viewport)} />
      ) : (
        <div className='content'>
          <div className='divider' />
          <div className='stats-row'>
            <div className='stat-section'>
              <div className='stat'>{`${Num.Format.integer(analytics?.views?.total ?? 0)}`}</div>
              <div className='name'>Video Views</div>
            </div>
            <div className='stat-section'>
              <div className='stat'>{`${Num.Format.integer(analytics?.views?.unique ?? 0)}`}</div>
              <div className='name'>Unique Views</div>
            </div>
          </div>
          <div className='stats-row'>
            <div className='stat-section'>
              <div className='stat'>{avgWatchTime}</div>
              <div className='name'>Avg Watched</div>
            </div>
            <div className='stat-section'>
              <div className='stat'>{`${analytics?.retention?.percent ?? 0}%`}</div>
              <div className='name'>% Watched</div>
            </div>
          </div>
          <div className='divider' />
          <div className='stats-row'>
            <div className='stat-section'>
              <div className='stat'>{registeredViewers}</div>
              <div className='name'>Registered Viewers</div>
            </div>
            <div className='stat-section'>
              <div className='stat'>{capturedEmails}</div>
              <div className='name'>Emails Captured</div>
            </div>
          </div>
        </div>
      )}
    </ViewershipContainer>
  );
};
