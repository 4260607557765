import { Form, Loading } from '@livecontrol/core-ui';
import cx from 'classnames';
import loadjs from 'loadjs';
import _ from 'lodash';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import FormControl from 'react-bootstrap/FormControl';
import PlacesAutocomplete from 'react-places-autocomplete';
import { store } from '../../store';

const loadMaps = _.once(
  async () =>
    new Promise<void>((resolve) => {
      loadjs(
        `https://maps.googleapis.com/maps/api/js?key=${
          store.getState().environment.GCLOUD_MAPS_API_KEY
        }&libraries=places`,
        resolve
      );
    })
);

export const AddressSelector = forwardRef(
  (
    { isInvalid, handleSelect, ...props }: AddressSelector.AddressSelectorProps,
    ref: React.Ref<HTMLInputElement>
  ): React.ReactElement | null => {
    const field = Form.Utils.useField(props);
    const [scriptLoading, setScriptLoading] = useState(true);

    useEffect(() => {
      void loadMaps().then(() => {
        setScriptLoading(false);
      });
    }, []);

    const [value, setValue] = useState('');

    useEffect(() => {
      setValue(field?.meta.initialValue);
    }, [field?.meta.initialValue]);

    const handleChange = useCallback(
      (val: string) => {
        setValue(val);
        field?.helpers.setValue(val);
      },
      [setValue, field]
    );

    const itemClassName = 'p-12px py-8px my-0 cursor-pointer';

    return scriptLoading ? (
      <Loading size={15} />
    ) : (
      <PlacesAutocomplete
        value={value}
        onChange={setValue}
        onSelect={(v, placeId): void => {
          handleSelect?.(placeId);
          handleChange(v);
        }}
        searchOptions={{
          componentRestrictions: { country: 'us' },
          types: ['address']
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }): React.ReactElement => (
          <>
            <FormControl
              {...getInputProps({ placeholder: 'Enter Address' })}
              ref={ref}
              autoComplete='off'
              className={cx({ 'is-invalid': isInvalid })}
            />
            <div style={{ position: 'relative', zIndex: 10 }}>
              {suggestions.length > 0 ? (
                <ListGroup
                  as='ul'
                  style={{ position: 'absolute', top: '100%', left: 0, right: 0 }}
                  className='bg-white border border-2px'
                >
                  {loading && <div className={itemClassName}>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const classNam = suggestion.active ? 'text-primary' : 'text-gray';

                    const itemProps = getSuggestionItemProps(suggestion, {
                      className: cx(classNam, itemClassName)
                    });

                    return (
                      <p {...itemProps} key={suggestion.placeId}>
                        {suggestion.description}
                      </p>
                    );
                  })}
                </ListGroup>
              ) : null}
            </div>
          </>
        )}
      </PlacesAutocomplete>
    );
  }
);

AddressSelector.displayName = 'Address';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace AddressSelector {
  export type AddressSelectorProps = React.InputHTMLAttributes<HTMLInputElement> & {
    isInvalid?: boolean;
    handleSelect?: (placeId: string) => void;
  };
}
