import { Grid, Viewport } from '@livecontrol/core-ui';
import cx from 'classnames';
import type { Zone } from 'luxon';
import { DateTime } from 'luxon';
import type { HTMLAttributes, ReactElement } from 'react';
import type { Event } from '../../store';

export const Title = (props: Title.Props): ReactElement => {
  const { event, className, timezone, viewport } = props;
  const { title, start } = event;

  return (
    <Grid
      className={cx('align-items-center', 'column-gap-8px', className)}
      gridTemplateColumns='1fr max-content'
    >
      <div className='line-height-sm'>
        {event.password && <i className='far fa-lock-alt mx-12px' />}
        {title}
      </div>

      <time className='font-size-13px text-secondary'>
        {start
          .setZone(timezone)
          .toLocaleString(viewport === Viewport.XS ? DateTime.DATE_MED : DateTime.DATETIME_MED)}
      </time>
    </Grid>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Title {
  export type Props = HTMLAttributes<HTMLDivElement> & {
    event: Event;
    timezone: Zone;
    viewport: Viewport;
  };
}
