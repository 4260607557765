import { Device, Theme } from '@livecontrol/core-ui';
import cx from 'classnames';
import { DateTime } from 'luxon';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Service } from '../components';
import type { SubscriptionAccountInfo } from './types';

const Container = styled.div`
  margin-top: var(--spacer-32px);
  padding-bottom: var(--spacer-20px);
  border-bottom: 1px solid #e4e8f0 !important;

  img {
    max-width: var(--spacer-24px);
  }
`;

export const Subscription = ({
  subscription
}: {
  subscription?: SubscriptionAccountInfo.Subscription;
}): ReactElement => {
  const viewport = Theme.useViewport();

  return (
    <Container>
      <h2 className='text-dark font-size-20px font-weight-bold'>Subscription</h2>
      <div className='d-flex flex-column flex-lg-row mt-28px row-gap-16px'>
        {subscription?.nextBillingDate ? (
          <>
            <div
              className={cx(
                'd-flex flex-column font-weight-bold column-gap-16px',
                Device.isMobile(viewport) ? 'mr-20px' : 'mr-56px'
              )}
            >
              <span className='text-dark font-size-15px'>
                <img className='mr-12px' src='assets/icons/Payment_card.svg' alt='card credit' />
                Next Billing Date
              </span>
              <span className='mt-4px text-tertiary font-size-13px ml-32px'>
                {DateTime.fromISO(subscription.nextBillingDate).toLocaleString(DateTime.DATE_FULL)}
              </span>
            </div>
            <div className='d-flex flex-column'>
              {subscription.items.map((detail) => (
                <Service
                  key={detail.id}
                  cost={(detail.cost/100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  description={detail.description}
                />
              ))}
            </div>
          </>
        ) : (
          <span className='font-size-12px font-weight-bold text-tertiary'>No Subscription</span>
        )}
      </div>
    </Container>
  );
};
