import cx from 'classnames';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import type { Control } from './control';
import { Utils } from './utils';

const StyledCheckbox = styled.label`
  color: #2e384d;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 1rem;

  input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 2px;
    width: 16px;
    height: 16px;
    border: 1px solid #ccc;
    outline: none;
  }

  input[type='checkbox']:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
  }

  input:checked[type='checkbox'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
`;

export const Checkboxes = ({
  className,
  style,
  checkboxes,
  checkboxClassName,
  name
}: Checkboxes.Props): React.ReactElement => {
  const field = Utils.useField(name);
  const valueSet = useMemo(() => new Set(field?.meta.value ?? []), [field?.meta.value]);

  const onCheckboxesSelect = useCallback(
    (checkbox: string) => (): void => {
      if (valueSet.has(checkbox)) {
        valueSet.delete(checkbox);
      } else {
        valueSet.add(checkbox);
      }

      field?.helpers.setValue([...valueSet]);
    },
    [field?.helpers, valueSet]
  );

  const checkboxesUI = useMemo(
    () =>
      checkboxes.map(({ value: checkboxValue, label }) => (
        <StyledCheckbox key={checkboxValue}>
          <input
            className={cx(
              'mr-12px cursor-pointer',
              {
                'bg-primary text-white': valueSet.has(checkboxValue)
              },
              checkboxClassName
            )}
            onClick={onCheckboxesSelect(checkboxValue)}
            type='checkbox'
            id={label}
            readOnly
            checked={valueSet.has(checkboxValue)}
          />
          {label ?? checkboxValue}
        </StyledCheckbox>
      )),
    [onCheckboxesSelect, checkboxClassName, checkboxes, valueSet]
  );

  return (
    <div className={className} style={style}>
      {checkboxesUI}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Checkboxes {
  export type Props = Omit<Control.Props, 'as'> & {
    checkboxes: { value: string; label?: string }[];
    value?: string[];
    onChange?: (value: number) => void;
    className?: string;
    style?: React.CSSProperties;
    checkboxClassName?: string;
    name: string;
  };
}
