import { Num } from '@livecontrol/core-utils';
import { Modal } from '@livecontrol/scheduler/components';
import type { Account } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import pluralize from 'pluralize';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Store } from '../../../store';
import type { CreditPurchaseModalProps } from './credits-modal';
import { CreditsProcessing } from './credits-processing';

interface Props {
  onClose: () => void;
  modalProps: CreditPurchaseModalProps;
  confirmTotal: (result: 'Fail' | 'Success') => void;
  billing: Account.Billing;
}

const Icon = styled.img`
  height: 40px;
  width: 40px;
  display: inline-grid;
  place-items: center;
  margin-botton: 16px;
`;

const Wrapper = styled.div`
  color: var(--gray-light);

  .mb-28px {
    margin-bottom: 28px;
  }

  .dark-text {
    color: var(--dark) !important;
  }

  .brand {
    text-transform: capitalize;
  }
`;

export const CreditsConfirm = ({
  modalProps,
  onClose,
  confirmTotal,
  billing
}: Props): ReactElement => {
  const account = Store.Account.useAccount();
  const [purchaseMutation, purchaseResult] = Scheduler.Account.usePurchase();

  const [invoiceMutation, invoiceResult] = Scheduler.Account.useInvoice(
    billing.credits.total,
    billing.credits.id!
  );

  /*
   * Confirm
   * const brandIcon =
   *   billing.stripe.defaultCardBrand === 'Visa' ? (
   *     <i className='fab fa-cc-visa font-size-24px' />
   *   ) : billing.stripe.defaultCardBrand === 'MasterCard' ? (
   *     <i className='fab fa-cc-mastercard font-size-24px' />
   *   ) : billing.stripe.defaultCardBrand === 'Discover' ? (
   *     <i className='fab fa-cc-discover font-size-24px' />
   *   ) : billing.stripe.defaultCardBrand === 'American Express' ? (
   *     <i className='fab fa-cc-amex font-size-24px' />
   *   ) : (
   *     <i className='fas fa-credit-card font-size-24px' />
   *   );
   */

  return (
    <div>
      {!purchaseResult.called ? (
        <>
          <Modal.Header closeButton>
            <Icon src='/assets/icons/dollar-coin.svg' alt='dollar coin' />

            <Modal.Title className='mb-8px mt-8px font-size-20px font-weight-bold'>
              Confirm Payment
            </Modal.Title>
            <Wrapper className='text-center font-weight-bold font-size-16px line-height-lg'>
              {`You will be charged ${Num.Format.us$(
                modalProps.price! * modalProps.amount!
              )!} for the purchase of ${modalProps.amount!} ${pluralize(
                'credit',
                modalProps.amount
              )}.`}
            </Wrapper>
          </Modal.Header>
          <Modal.Body>
            <Wrapper>
              <div className='d-flex flex-column justify-content-center align-items-center'>
                <div className='mt-12px dark-text mb-8px font-size-18px font-weight-bold '>
                  {`${Num.Format.us$(modalProps.price! * modalProps.amount!)!} Total`}
                </div>
              </div>
              <div className='text-center line-height-lg'>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <div className='font-size-16px mb-4px font-weight-bold'>Paying with</div>
                  <div className='font-size-16px font-weight-bold'>
                    <img
                      className='mr-4px'
                      src='/assets/icons/payment-card.svg'
                      alt='payment-card'
                    />
                    <span className='brand'>{billing.stripe.defaultCardBrand}</span> *****
                    {billing.stripe.defaultLast4CardNumber}
                  </div>
                </div>
                <div className='mt-8px mb-48px'>
                  <a
                    className='font-size-14px font-weight-bold text-primary '
                    href={`${billing.stripe.url!}`}
                    target='_blank'
                    rel='noreferrer'
                    onClick={onClose}
                  >
                    Change your payment method
                  </a>
                </div>
              </div>
            </Wrapper>
          </Modal.Body>
          <Modal.Footer>
            <Modal.ButtonBox>
              <Modal.Button
                type='submit'
                disabled={purchaseResult.loading || invoiceResult.loading}
                className='btn-wide font-size-16px font-weight-bold'
                onClick={async (): Promise<void> => {
                  let paymentConfirmation: Account.Purchase | undefined;
                  let checkInvoice: Account.Invoice | undefined;

                  if (!purchaseResult.loading) {
                    paymentConfirmation = await purchaseMutation(account, modalProps.amount!);
                  }

                  if (!paymentConfirmation?.paid) {
                    confirmTotal('Fail');
                  }

                  if (paymentConfirmation?.paid && !purchaseResult.loading) {
                    const checkingInvoiceStatus = setInterval(async () => {
                      if (!invoiceResult.loading) {
                        checkInvoice = await invoiceMutation(paymentConfirmation!);
                      }

                      if (checkInvoice?.status === 'paid') {
                        clearInterval(checkingInvoiceStatus);
                        confirmTotal('Success');
                      } else if (checkInvoice?.status === 'void') {
                        clearInterval(checkingInvoiceStatus);
                        confirmTotal('Fail');
                      }
                    }, 2000);
                  }
                }}
              >
                Confirm and Pay
              </Modal.Button>
              <Modal.Button
                variant='bg-transparent'
                className='btn-wide font-size-16px font-weight-bold text-secondary'
                onClick={onClose}
                disabled={purchaseResult.loading || invoiceResult.loading}
              >
                Cancel
              </Modal.Button>
            </Modal.ButtonBox>
          </Modal.Footer>
        </>
      ) : (
        <CreditsProcessing />
      )}
    </div>
  );
};
