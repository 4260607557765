import { Enum, Hash, Num, Obj, Str } from '@livecontrol/core-utils';
import type { Phone } from '@livecontrol/locale-us';
import base64 from 'base-64';
import type { IANAZone } from 'luxon';
import urlencode from 'urlencode';
import type { Asset } from './asset';
import { Event } from './event';
import type { User } from './user';

export interface Account {
  id: Account.ID;
  name: string;
  address?: string;
  phone?: Phone;
  timezone: IANAZone;
  avatar: string;
  leadTime: number;
  industry?: Account.Industry;
  subUsers: Account.SubUser[];
  accountOwnerName: string;
  organizationPhoneNumber?: Phone;
  accountOwnerEmail: string;
  preferredContactMethod?: string;
  contactRole: User.ContactRole[];
}

export namespace Account {
  export type ID = number;
  export type Like = Account | ID | string;

  export const toId = (account?: Like): ID | undefined =>
    Num.normalize(Obj.isObject<Account>(account) ? account.id : account, { positive: true });

  export enum Industry {
    House_Of_Worship = 1,
    Event_Venue = 2,
    Comedy = 3,
    Fitness = 4,
    Conference = 5,
    Content_Creator = 6,
    Funeral = 7,
    Government = 8,
    Education = 9
  }

  export namespace Industry {
    export const normalize = (value: unknown): Industry | undefined =>
      Enum.normalize(Industry, value);
  }

  export interface Billing {
    stripe: {
      url?: string;
      token?: string;
      defaultLast4CardNumber?: string;
      defaultCardBrand?: string;
      expMonth?: string;
      expYear?: string;
    };
    credits: {
      total: number;
      price: number;
      id?: number;
      first_credit_time_limit: number;
      over_time_limit_cost: number;
      recurrence: string;
      recurrence_amount: number;
    };
    client_booking: {
      cb2_price: number;
      cb3_price: number;
      cb2_host_price: number;
      cb3_host_price: number;
      client_pays_enabled: boolean;
      pay_now_enabled: boolean;
      pay_later_enabled: boolean;
    };
  }

  export interface Purchase {
    stripeInvoiceId?: string;
    paid: boolean;
    amount: number;
  }

  export interface Invoice {
    id: number;
    clientId?: number;
    status?: string;
    type?: string;
    datePaid?: string;
    stripeInvoiceId: string;
    oneTimeCreditAmount?: number;
  }

  export namespace Invoice {
    /* eslint-disable @typescript-eslint/no-shadow */
    export type Like = Invoice | Purchase | string;

    export const toId = (invoice: Like): string | undefined =>
      Str.normalize(Obj.isObject<Invoice | Purchase>(invoice) ? invoice.stripeInvoiceId : invoice);
    /* eslint-enable @typescript-eslint/no-shadow */
  }

  export type SubUser = Omit<User, 'role' | 'status'> & {
    permissions: SubUser.Permissions;
    hasAllLocations: boolean;
    contactRole?: SubUser.ContactRole[];
    sub_user_locations?: SubUser.LocationPermissions[];
  };

  export namespace SubUser {
    export type Permissions = User.Permissions & {
      id: number;
    };
    export type ContactRole = User.ContactRole;
    export type LocationPermissions = User.LocationPermissions;
  }

  export type Features = Set<string>;

  export const FeatureKeys = {
    ASSET_ANALYTICS: 'asset:analytics'
  };

  export interface Contact {
    id: string;
    name: string;
    email: string;
    phone: string;
  }

  export interface WebPlayer {
    endpoint?: string;
    displayName?: string;
    about?: string;
    theme?: string;
    logo?: string;
    background?: string;
    donation?: WebPlayer.Donation;
    tabs?: WebPlayer.Tabs;
    followUsLinks?: WebPlayer.FollowUsLinks;
    intro?: string;
    outro?: string;
    isCustomButtonEnabled?: boolean;
    customButton?: WebPlayer.CustomButton;
    custom_donate_button_copy?: string;
    donate_link?: string;
  }

  export namespace WebPlayer {
    export interface CustomButton {
      title?: string;
      url?: string;
    }

    export interface Donation {
      label?: string;
      link?: string;
    }

    export interface Tabs {
      archive?: boolean;
      schedule?: boolean;
      chat?: boolean;
      profile?: boolean;
    }

    export interface FollowUsLinks {
      facebook?: string;
      instagram?: string;
      website?: string;
      youtube?: string;
    }

    export const Constraints = {
      ABOUT_MAX_LENGTH: 500
    };

    export const makeURL = (args: {
      uri: string;
      slug: string;
      event?: Event.Like;
      password?: string;
    }): string => {
      const { uri, slug, ...rest } = args;
      const event = Event.toId(rest.event);
      const password = rest.password ? urlencode(base64.encode(rest.password)) : undefined;

      return [
        uri.replace('{slug}', slug),
        event ? `/${Hash.digest(event.toString())}` : undefined,
        password ? `?pwd=${password}` : undefined
      ]
        .filter((µ) => !!µ)
        .join('');
    };

    export const makeWebplayerUrl = (args: {
      uri: string;
      slug: string;
      event?: Event.Like;
      password?: string;
    }): string => {
      const { uri, slug, ...rest } = args;
      const event = Event.toId(rest.event);
      const password = rest.password ? urlencode(base64.encode(rest.password)) : undefined;

      return [
        uri.replace('{slug}', slug),
        event ? `/${Hash.digest(event.toString())}?` : undefined,
        password ? `&pwd=${password}` : undefined
      ]
        .filter((µ) => !!µ)
        .join('');
    };

    export const makeEventSlug = (args: { eventId: number | undefined }): string => {
      const { eventId } = args;

      if (eventId) {
        return `${Hash.digest(eventId.toString())}`;
      }

      return '';
    };

    export const makeAssetURL = ({
      venueURI,
      slug,
      asset
    }: {
      venueURI: string;
      slug: string;
      asset: Asset;
    }): string => {
      if (asset.event) {
        return makeURL({ uri: venueURI, slug, event: asset.event });
      }

      const muxAssetID = asset.mux?.assetId;

      if (!muxAssetID) {
        return '';
      }

      return `${venueURI.replace('{slug}', slug)}/${Hash.digest(muxAssetID)}`;
    };
  }

  export namespace ClientBooking {
    export const makeClientBookingURL = ({
      uri,
      slug,
      eventId
    }: {
      uri: string;
      slug: string;
      eventId: number;
    }): string => `${uri.replace('{slug}', slug)}/${eventId}`;
  }

  export interface S3 {
    url?: string;
    bucket?: string;
    key?: string;
  }

  export interface SlugCheck {
    slug_valid: boolean;
  }

  export namespace Plaid {
    export type Token = string;
    export type Account = string;
  }

  // Force Typescript to export this as both a type and value.
  export const __unused = false;
}
