import cx from 'classnames';
import type { PropsWithChildren, ReactElement } from 'react';
import shallow from 'zustand/shallow';
import { NoEvents } from './common';
import { Live } from './live';
import { Phase, useStore } from './store';
import { Upcoming } from './upcoming';

const Section = ({ children }: PropsWithChildren<unknown>): ReactElement => (
  <h2 className='mb-12px pb-12px border-bottom'>{children}</h2>
);

export const Pending = (): ReactElement => {
  const [live, upcoming] = useStore(
    ({ model }) => [model.value[Phase.Live].events, model.value[Phase.Upcoming].events],
    shallow
  );

  return live.length + upcoming.length ? (
    <>
      {!!live.length && (
        <div>
          {!!upcoming.length && <Section>Live Now</Section>}
          <Live events={live} />
        </div>
      )}

      {!!upcoming.length && (
        <div className={cx(!!live.length && 'mt-32px')}>
          {!!live.length && <Section>Upcoming</Section>}
          <Upcoming events={upcoming} />
        </div>
      )}
    </>
  ) : (
    <NoEvents />
  );
};
