import type { ApolloError, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Obj, Str } from '@livecontrol/core-utils';
import { Destination } from '@livecontrol/scheduler/model';
import { useCallback, useState } from 'react';
import type { MutationResult } from '../graphql';
import { Errors } from '../graphql';
import * as Dbx from './dbx';

interface TVariables {
  clientId: string;
  destinationId: Destination.Like;
}

type Args = TVariables;

interface TData {
  deleteIntegration?: string;
}

const MUTATION = gql`
  mutation DeleteIntegration($clientId: String!, $destinationId: String!) {
    deleteIntegration(clientId: $clientId, integrationId: $destinationId)
  }
`;

export const useDelete = (): [(args: Args) => Promise<void>, MutationResult<void>] => {
  const [error, setError] = useState<Error | undefined>();

  // Prepare the query
  const [mutate, { loading, called }] = useMutation<TData, TVariables>(MUTATION, {
    onError() {
      setError(Exception.KABOOM);
    }
  });

  return [
    useCallback(
      async (args: Args): Promise<void> => {
        try {
          // Parse the input arguments
          const input = {
            clientId: Str.normalize(args.clientId),
            destinationId: Destination.toId(args.destinationId)
          };

          if (!Obj.isHydrated(input)) {
            throw Errors.badRequest();
          }

          // Execute the GraphQL mutation
          await mutate({
            variables: input,
            update(cache, { data }) {
              if (data?.deleteIntegration) {
                cache.evict({
                  id: cache.identify({ __typename: Dbx.__typename, id: args.destinationId })
                });
              }
            }
          })
            .then(({ data }: FetchResult<TData>) => data?.deleteIntegration)
            .catch((_error: ApolloError) => {
              throw Errors.serverError();
            });
        } catch (error_: unknown) {
          setError(<Error>error_);
        }
      },
      [mutate, setError]
    ),

    {
      loading,
      error,
      called
    }
  ];
};
