import type { QueryResult as ApolloQueryResult } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Bool, Num, Obj, Str } from '@livecontrol/core-utils';
import { Account } from '@livecontrol/scheduler/model';
import { useEffect, useState } from 'react';
import type { QueryResult } from '../graphql';

interface TVariables {
  id: number;
}

interface TData {
  getAccount?: {
    stripeBillingPortal?: string;
    payment_token?: string;
  };
  getCredits: {
    current_amount: number;
    price: number;
    id?: number;
    first_credit_time_limit: number;
    over_time_limit_cost: number;
    recurrence: string;
    recurrence_amount: number;
    client_booking: {
      cb2_price: number;
      cb3_price: number;
      cb2_host_price: number;
      cb3_host_price: number;
      client_pays_enabled: boolean;
      pay_now_enabled: boolean;
      pay_later_enabled: boolean;
    };
  }[];
  getCardInfoByUserId: {
    last4: string;
    brand: string;
    exp_year: string;
    exp_month: string;
  };
}

const QUERY = gql`
  query GetBilling($id: Float!) {
    getAccount(input: { id: $id }) {
      id
      stripeBillingPortal
      payment_token
    }
    getCredits(search: { user: $id }) {
      id
      current_amount
      price
      first_credit_time_limit
      over_time_limit_cost
      recurrence
      recurrence_amount
      client_booking {
        cb2_price
        cb3_price
        cb2_host_price
        cb3_host_price
        client_pays_enabled
        pay_now_enabled
        pay_later_enabled
      }
    }
    getCardInfoByUserId(id: $id) {
      id
      last4
      brand
      exp_month
      exp_year
    }
  }
`;

export const useBilling = (
  args: Account.Like
): Partial<Pick<ApolloQueryResult, 'refetch'>> & QueryResult<Account.Billing, 'billing'> => {
  const [error, setError] = useState<Error | undefined>();
  const [billing, setBilling] = useState<Account.Billing | undefined>();

  // Parse the input arguments
  const variables = {
    id: Account.toId(args)
  };

  // Validate the input
  if (!Obj.isHydrated(variables)) {
    return { loading: false, error: Exception.KABOOM };
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  // Prepare the query
  const { loading, data, refetch } = useQuery<TData, TVariables>(QUERY, {
    variables,
    onError() {
      setError(Exception.KABOOM);
    }
  });

  // When available, parse server response
  useEffect(() => {
    let billing_;
    let error_;

    if (data) {
      const { getAccount, getCardInfoByUserId } = data;
      const [getCredits] = [...data.getCredits];

      billing_ = {
        stripe: {
          url: Str.normalize(getAccount?.stripeBillingPortal),
          token: Str.normalize(getAccount?.payment_token),
          defaultLast4CardNumber: Str.normalize(getCardInfoByUserId.last4),
          defaultCardBrand: Str.normalize(getCardInfoByUserId.brand),
          expMonth: Str.normalize(getCardInfoByUserId.exp_month),
          expYear: Str.normalize(getCardInfoByUserId.exp_year)
        },
        credits: {
          total: Num.normalize(getCredits.current_amount) ?? 0,
          price: Num.normalize(getCredits.price) ?? 199,
          id: Num.normalize(getCredits.id) ?? undefined,
          first_credit_time_limit: Num.normalize(getCredits.first_credit_time_limit) ?? 0,
          over_time_limit_cost: Num.normalize(getCredits.over_time_limit_cost) ?? 0,
          recurrence: Str.normalize(getCredits.recurrence) ?? '',
          recurrence_amount: Num.normalize(getCredits.recurrence_amount) ?? 0
        },
        client_booking: {
          cb2_price: Num.normalize(getCredits.client_booking.cb2_price) ?? 99,
          cb3_price: Num.normalize(getCredits.client_booking.cb3_price) ?? 299,
          cb2_host_price: Num.normalize(getCredits.client_booking.cb2_host_price) ?? 49,
          cb3_host_price: Num.normalize(getCredits.client_booking.cb3_host_price) ?? 149,
          client_pays_enabled: Bool.normalize(getCredits.client_booking.client_pays_enabled),
          pay_now_enabled: Bool.normalize(getCredits.client_booking.pay_now_enabled),
          pay_later_enabled: Bool.normalize(getCredits.client_booking.pay_later_enabled)
        }
      };
    }

    setBilling(billing_);
    setError(error_);
  }, [data]);

  return { loading, error, billing, refetch };
};

export const useBillingForced = (
  args: Account.Like
): Partial<Pick<ApolloQueryResult, 'refetch'>> & QueryResult<Account.Billing, 'billing'> => {
  const [error, setError] = useState<Error | undefined>();
  const [billing, setBilling] = useState<Account.Billing | undefined>();

  // Parse the input arguments
  const variables = {
    id: Account.toId(args)
  };

  // Validate the input
  if (!Obj.isHydrated(variables)) {
    return { loading: false, error: Exception.KABOOM };
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  // Prepare the query
  const { loading, data, refetch } = useQuery<TData, TVariables>(QUERY, {
    variables,
    onError() {
      setError(Exception.KABOOM);
    },
    fetchPolicy: 'network-only'
  });

  // When available, parse server response
  useEffect(() => {
    let billing_;
    let error_;

    if (data) {
      const { getAccount, getCardInfoByUserId } = data;
      const [getCredits] = [...data.getCredits];

      billing_ = {
        stripe: {
          url: Str.normalize(getAccount?.stripeBillingPortal),
          token: Str.normalize(getAccount?.payment_token),
          defaultLast4CardNumber: Str.normalize(getCardInfoByUserId.last4),
          defaultCardBrand: Str.normalize(getCardInfoByUserId.brand),
          expMonth: Str.normalize(getCardInfoByUserId.exp_month),
          expYear: Str.normalize(getCardInfoByUserId.exp_year)
        },
        credits: {
          total: Num.normalize(getCredits.current_amount) ?? 0,
          price: Num.normalize(getCredits.price) ?? 199,
          id: Num.normalize(getCredits.id) ?? undefined,
          first_credit_time_limit: Num.normalize(getCredits.first_credit_time_limit) ?? 0,
          over_time_limit_cost: Num.normalize(getCredits.over_time_limit_cost) ?? 0,
          recurrence: Str.normalize(getCredits.recurrence) ?? '',
          recurrence_amount: Num.normalize(getCredits.recurrence_amount) ?? 0
        },
        client_booking: {
          cb2_price: Num.normalize(getCredits.client_booking.cb2_price) ?? 99,
          cb3_price: Num.normalize(getCredits.client_booking.cb3_price) ?? 299,
          cb2_host_price: Num.normalize(getCredits.client_booking.cb2_host_price) ?? 49,
          cb3_host_price: Num.normalize(getCredits.client_booking.cb3_host_price) ?? 149,
          client_pays_enabled: Bool.normalize(getCredits.client_booking.client_pays_enabled),
          pay_now_enabled: Bool.normalize(getCredits.client_booking.pay_now_enabled),
          pay_later_enabled: Bool.normalize(getCredits.client_booking.pay_later_enabled)
        }
      };
    }

    setBilling(billing_);
    setError(error_);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.getCredits]);

  return { loading, error, billing, refetch };
};
