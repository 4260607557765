import type { ApolloCache, ApolloError, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Str } from '@livecontrol/core-utils';
import { Account } from '@livecontrol/scheduler/model';
import { useCallback, useState } from 'react';
import { Errors } from '../graphql';
import type { MutationResult } from '../graphql';
import * as Dbx from './dbx';

interface TVariables {
  input: Partial<{
    lead_time: number;
    organization_address: string;
    organization_name: string;
    organization_zip_code: string;
    organization_phone_number: string;
    phone_number: string;
    profile_picture: string;
    time_zone: string;
  }> & {
    id: number;
  };
}

interface TData {
  updateAccount?: Dbx.Record;
}

type Args = Partial<{
  name: string;
  address?: string;
  organizationPhoneNumber: string;
  phone: string;
  timezone: string;
  avatar: string;
  leadTime: number;
}> & {
  id: Account.Like;
};

const MUTATION = gql`
  mutation EditUser($input: UpdateUser!) {
    updateAccount(input: $input) {
      ...StandardAccountResponse
    }
  }
  ${Dbx.StandardAccountResponse}
`;

export const useEdit = (): [
  (args: Args) => Promise<Account | undefined>,
  MutationResult<Account, 'account'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [account, setAccount] = useState<Account | undefined>();

  return [
    useCallback(
      async (args: Args): Promise<Account | undefined> => {
        let account_: Account | undefined;

        try {
          // Parse the input arguments
          const id = Account.toId(args.id);

          if (!id) {
            throw Errors.badRequest();
          }

          const variables = {
            input: {
              id,
              organization_phone_number: Str.normalize(args.organizationPhoneNumber),
              organization_address: Str.normalize(args.address),
              phone_number: Str.normalize(args.phone)
            }
          };

          // Execute the GraphQL mutation
          const response = await mutation({
            variables,
            update(cache: ApolloCache<unknown>, { data: fetchData }: FetchResult<TData>): void {
              const identify = cache.identify({
                id: fetchData?.updateAccount?.id,
                __typename: 'Account'
              });

              if (identify) {
                cache.modify({
                  id: identify,
                  fields: {
                    organization_address(): string {
                      return fetchData?.updateAccount?.organization_address ?? '';
                    },
                    time_zone(): string {
                      return fetchData?.updateAccount?.time_zone ?? '';
                    },
                    organization_phone_number(): string {
                      return fetchData?.updateAccount?.organization_phone_number ?? '';
                    }
                  }
                });
              }
            }
          })
            .then(({ data }: FetchResult<TData>) => data?.updateAccount)
            .catch((_error: ApolloError) => {
              throw Errors.serverError();
            });

          // Parse the server response
          account_ = Dbx.normalize(response);

          if (!account_) {
            throw Errors.serverError();
          }
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setAccount(account_);

        return account_;
      },
      [mutation, setError, setAccount]
    ),
    {
      account,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
