import { Str } from '@livecontrol/core-utils';
import { Account, User } from '@livecontrol/scheduler/model';

export interface XRef {
  user: User.ID;
  account: Account.ID;
}

export namespace XRef {
  export const decode = (value: unknown): XRef | undefined => {
    let xref;

    const tokens = Str.tokenize(Str.normalize(value), /[,:|]/);

    if (tokens.length === 2) {
      const account = Account.toId(tokens[0]);
      const user = User.toId(tokens[1]);

      if (account && user) {
        xref = { account, user };
      }
    }

    return xref;
  };

  export const encode = (xref: XRef): string => `${xref.account}:${xref.user}`;
}
