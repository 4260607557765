import { FormikContext } from 'formik';
import type { ComponentPropsWithRef, ReactElement, ReactNode } from 'react';
import React, { Component, useContext } from 'react';
import type { FormProps } from 'react-bootstrap/Form';
import B_Form from 'react-bootstrap/Form';
import { Alert as Alert_ } from './alert';
import { Attribute as Attribute_ } from './attribute';
import { Check as Check_ } from './check';
import { Checkboxes as Checkboxes_ } from './checkboxes';
import { Control as Control_ } from './control';
import { CountryCode as CountryCode_ } from './country-code';
import { DateSelector as DateSelector_ } from './date-selector';
import { Descriptor as Descriptor_ } from './descriptor';
import { DropdownSelector as DropdownSelector_ } from './dropdown-selector';
import { Feedback as Feedback_ } from './feedback';
import { Group as Group_ } from './group';
import { Like as Like_ } from './like';
import { Phone as Phone_ } from './phone';
import { PhoneWithSelector as PhoneWithSelector_ } from './phone-with-selector';
import { Radio as Radio_ } from './radio';
import { RadioAlt as RadioAlt_ } from './radio-alt';
import { Rating as Rating_ } from './rating';
import { Submit as Submit_ } from './submit';
import { SwitchCustomField as SwitchCustomField_ } from './switch-custom-field';
import { SwitchField as SwitchField_ } from './switch-field';
import { SwitchToggle as SwitchToggle_ } from './switch-toggle';
import { Tags as Tags_ } from './tags';
import { Time as Time_ } from './time';
import { Timezone as Timezone_ } from './timezone';
import { Utils as Utils_ } from './utils';
import { Weekday as Weekday_ } from './weekdays';

const FormImpl = (props: Form.Props): ReactElement => {
  const formik = useContext(FormikContext);

  return React.createElement(B_Form, {
    ...props,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    ...(formik
      ? {
          onSubmit: formik.handleSubmit,
          noValidate: true
        }
      : {})
  });
};

// eslint-disable-next-line react/prefer-stateless-function
export class Form extends Component<Form.Props> {
  public override render(): ReactNode {
    return React.createElement(FormImpl, this.props);
  }
}

export namespace Form {
  export type Props = ComponentPropsWithRef<'form'> & FormProps;

  export const { File, Switch, Label, Text } = B_Form;

  /* eslint-disable */
  export import Alert = Alert_;
  export import Attribute = Attribute_;
  export import Check = Check_;
  export import Checkboxes = Checkboxes_;
  export import Control = Control_;
  export import CountryCode = CountryCode_;
  export import DateSelector = DateSelector_;
  export import Feedback = Feedback_;
  export import Group = Group_;
  export import Like = Like_;
  export import DropdownSelector = DropdownSelector_;
  export import PhoneWithSelector = PhoneWithSelector_;
  export import Phone = Phone_;
  export import Submit = Submit_;
  export import Time = Time_;
  export import Timezone = Timezone_;
  export import Utils = Utils_;
  export import Weekday = Weekday_;
  export import Rating = Rating_;
  export import Tags = Tags_;
  export import SwitchToggle = SwitchToggle_;
  export import SwitchField = SwitchField_;
  export import Radio = Radio_;
  export import RadioAlt = RadioAlt_;
  export import Descriptor = Descriptor_;
  export import SwitchCustomField = SwitchCustomField_;
}
