import React, { Component } from 'react';
import type { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { Cell as Cell_ } from './cell';
import { DateTime as DateTime_ } from './datetime';

// @fixme: Josh - Remove references to --tint
const Container = styled.li`
  display: contents;

  &.x-selected > div {
    background-color: var(--tint-ø2);
  }
`;

// eslint-disable-next-line react/prefer-stateless-function
export class Row extends Component<Row.Props> {
  public override render(): ReactNode {
    return React.createElement(Container, this.props);
  }
}

export namespace Row {
  export type Props = PropsWithChildren<HTMLAttributes<HTMLLIElement>>;

  export const Cell = Cell_;
  export const DateTime = DateTime_;
}
