import cx from 'classnames';
import React from 'react';
import type { HTMLAttributes, PropsWithChildren, ReactElement } from 'react';
import { isElement } from 'react-is';
import { Panel } from '../../../../components';

type Props = HTMLAttributes<HTMLDivElement> &
  PropsWithChildren<{
    header: React.ReactNode;
    children: React.ReactNode;
  }>;

export function Section({ children, className, header, ...props }: Props): ReactElement {
  return (
    <Panel className={cx('p-0px', className)} {...props}>
      <div className='p-20px'>
        {isElement(header) ? header : <Section.Heading>{header}</Section.Heading>}
      </div>

      {children}
    </Panel>
  );
}

export namespace Section {
  export const Heading = ({
    className,
    children,
    ...props
  }: PropsWithChildren<HTMLAttributes<HTMLHeadingElement>>): ReactElement => (
    <h2 className={cx('text-uppercase', 'font-size-14px', 'm-0px', className)} {...props}>
      {children}
    </h2>
  );

  export const Content = ({
    className,
    children,
    ...props
  }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>): ReactElement => (
    <div className={cx('px-20px pb-20px', className)} {...props}>
      <hr className='mt-0px' />
      {children}
    </div>
  );
}
