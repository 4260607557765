import { Device } from '@livecontrol/core-ui';
import styled from 'styled-components';

export const HeaderSection = styled.div`
  .title {
    width: 100%;
    padding: 25px 0px;
    display: flex;
    justify-content: space-between;
    font-weight: 600;

    .text {
      color: #000000;
      font-size: 20px;
    }

    .lead-time {
      font-size: 12px;
      color: #8798ad;
      margin-top: 4px;
    }
    .pre-text {
      font-size: 13px;
      color: #8798ad;
      width: 100%;
    }
    .produced-mobile {
      margin-top: 16px;
    }
  }
  .description {
    color: #9c9c9c;
    font-size: 13px;
    max-width: 450px;
    margin-bottom: 40px;
    line-height: 18px;
    font-weight: 600;
  }

  .divider {
    width: 100%;
    border-top: solid 1px #d5daea;
  }
`;

export const FooterSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;

  .custom-primary-btn {
    font-size: 12px;
    font-weight: 600;
    min-width: 114px;
    text-align: center;
    min-height: 46px;
    &.btn-xwide {
      width: 90%;
      max-width: 426px;
    }
  }

  .custom-primary-link {
    font-size: 12px;
    font-weight: 600;
    margin-left: 14px;
    color: #8798ad;
  }
  .links-section {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    max-width: 426px;
  }
`;

export const ContentSection = styled.div`
  width: 100%;
  max-width: 800px;

  .error, .has-errors {
    .custom-input input {
      border: solid 2px red !important;

      :focus-visible {
        border: solid 2px red !important;
      }
    }

    .is-invalid {
      border: 0px;
    }

    .calendar {
      background-image: url(/assets/icons/calendar-red.svg) !important;
    }

    .clock {
      background-image: url(/assets/icons/clock-red.svg) !important;
    }

    
  }

  .custom-form-group {
    max-width: 615px;

    .times-section {
      width: 100%;
      max-width: 360px;
      padding-right: 20px;

      .x-${Device.MOBILE} & {
        padding-right: 0px;
      }
    }

    .custom-input {
      margin: 25px 0px;

      .x-${Device.MOBILE} & {
        margin: 0px 0px;
      }

      label,
      input,
      select {
        color: #2e384d;
        font-size: 15px;
      }

      .input-wrapper {
        position: relative;
      }

      label {
        margin-bottom: 14px;
      }
    }

    .custom-input input,
    select {
      min-height: 46px;
      border-radius: 5px;
      border: solid 2px #2e5bff;
      letter-spacing: -0.5px;
      background-color: #ffffff;
      padding-right: 56px;
      position: relative;

      .icon {
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .custom-input.has-errors input {
      border: solid 2px #ff0000;
    }
    .custom-input.has-errors .invalid-feedback {
      opacity: 1;
    }
    .invalid-feedback {
      opacity: 1;
    }
    .custom-input input:disabled,
    select:disabled {
      background-color: #e9ecef;
      color: #586071;
    }

    .was-validated .form-control:invalid,
    .form-control.is-invalid {
      padding-right: 0px;
    }

    .icon-section {
      padding: 5px 16px;
      background-color: #ffffff;
      position: absolute;
      top: 50%;
      right: 3px;
      border-radius: 5px;
      transform: translateY(-50%);
      pointer-events: none;
    }

    .location-input {
      width: 100%;
      max-width: 350px;
      padding-right: 15px;

      .x-${Device.MOBILE} & {
        padding-right: 0px;
        max-width: initial;
      }

      select {
        padding-right: 38px;
        border: solid 2px #2e384d;
      }

      .chevron-down {
        background-image: url(/assets/icons/Chevron-down-black.svg);
      }
      
      .icon-section {
        padding: 5px 6px;
      }
    }

    .technical-input {
      width: 100%;
      max-width: 250px;
      padding-right: 15px;

      select {
        padding-right: 38px;
        border: solid 2px #2e384d;
      }

      .chevron-down {
        background-image: url(/assets/icons/Chevron-down-black.svg);
      }

      .icon-section {
        padding: 5px 6px;
      }
    }

    .date-input {
      width: 100%;
      max-width: 237px;

      .x-${Device.MOBILE} & {
        max-width: initial;
      }
    }

    .times-section {
      width: 100%;
      max-width: 360px;
      padding-right: 20px;

      
      .x-${Device.MOBILE} & {
        max-width: initial;
     } 
    }

    .time-input {
      width: 100%;
      max-width: 152px;

      .x-${Device.MOBILE} & {
        max-width: initial;
      }

      .rc-time-picker {
        height: auto;
      }
    }

    .disabled .icon-section {
      background-color: #e9ecef;
    }

    .icon {
      width: 24px;
      height: 24px;
      background-size: cover;
      background-position: center center;
    }

    .calendar {
      background-image: url(/assets/icons/Calendar-blue.svg);
    }

    .clock {
      background-image: url(/assets/icons/Clock-blue.svg);
    }
  }

  .technical-group {
    display: flex;
    button {
      width: auto !important;
      margin-right: 24px;
      .flex-grow-1,
      .text-success {
        display: none !important;
      }
    }

    .invalid-feedback {
      display: none;
    }
  }
  .custom-radio-check {
    .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
      background-color: var(--primary);
      border-color: var(--primary);
    }

    .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
      color: #fff;
      font-family: 'Font Awesome 5 Free';
      content: '\f00c';
      font-weight: 900;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--primary);
      background-image: none;
      border-radius: 50%;
    }
  }
  .custom-week-days {
    button {
      background-color: #d5deff;
      border: none;
      color: #2e5bff !important;
      font-weight: 600;
      font-size: 15px;
    }
    button.active {
      background-color: #2e5bff !important;
      color: #fff !important;

  .footer {
    .space {
      width: 300px;
      max-width: 300px;
    }
  }
`;

export const CustomInputContainer = styled.div`
  padding-bottom: 24px;

  .form-control,
  span.text-muted {
    height: 46px;
  }

  textarea.form-control {
    height: 98px;
  }
  .form-section-lateral > div:first-of-type {
    max-width: 323px;
    min-width: 323px;
    width: 543px;
    padding-right: 17px;

    .x-${Device.MOBILE} & {
      max-width: initial;
      width: 100%;
    }
  }
`;
