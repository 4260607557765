import type { ReactElement } from 'react';
import styled from 'styled-components';

interface props {
  img: string;
  router: string;
  owerShip: string;
  qty: number;
  location: string;
}

const Container = styled.div`
  border-top: 1px solid #e4e8f0 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;

  div {
    min-width: 130px;
    padding: 20px;
  }

  .qty {
    min-width: 70px !important;
  }

  .router {
    min-width: 300px !important;
  }
`;

const Image = styled.img`
  max-width: 84px;
`;

export const Item = ({ img, router, owerShip, qty, location }: props): ReactElement => (
  <Container>
    <Image src={img} alt='equipment' />
    <div className='d-flex flex-column font-weight-bold font-size-12px router'>
      <span className='text-tertiary'>Router</span>
      <span className='text-dark mt-8px'>{router}</span>
    </div>
    <div className='d-flex flex-column font-weight-bold font-size-12px'>
      <span className='text-tertiary'>Ownnership</span>
      <span className='text-dark mt-8px'>{owerShip}</span>
    </div>
    <div className='d-flex flex-column font-weight-bold font-size-12px qty'>
      <span className='text-tertiary'>QTY</span>
      <span className='text-dark mt-8px'>{qty}</span>
    </div>
    <div className='d-flex flex-column font-weight-bold font-size-12px'>
      <span className='text-tertiary'>Location</span>
      <span className='text-dark mt-8px'>{location}</span>
    </div>
  </Container>
);
