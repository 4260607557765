import cx from 'classnames';
import type { PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  a {
    margin-left: var(--spacer-4px);
  }
`;

export const Linkbox = ({ className, children }: Linkbox.Props): ReactElement => (
  <Container className={cx('text-center', 'mt-20px', className)}>{children}</Container>
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Linkbox {
  export type Props = PropsWithChildren<{
    className?: string;
  }>;
}
