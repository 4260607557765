import { Device } from '@livecontrol/core-ui';
import type { Account } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { useState } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Form } from '../../../components';
import { Store } from '../../../store';
import { TextField } from '../common';

const DisplayNameContainer = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid #d5daea;

  .x-${Device.DESKSTOP} & {
    .form-section-lateral > div:first-of-type {
      min-width: 285px;
      width: 285px;
      margin-bottom: 10px;
      margin-right: 26px;
    }
  }

  .form-control {
    height: 46px;
  }
`;

export const DisplayNameSection = ({
  webplayer
}: {
  webplayer: Account.WebPlayer;
}): ReactElement => {
  const account = Store.Account.useAccount();
  const [editDisplayName, { loading: displayNameLoading }] = Scheduler.Account.WebPlayer.useEdit();
  const [displayName, setDisplayName] = useState(webplayer.displayName ?? '');

  return (
    <DisplayNameContainer>
      <Form.Descriptor
        title='Display Name'
        titleClassName='title mr-lg-3 font-size-15px font-weight-bold text-dark'
        description='You can change your web player display name'
        descriptionClassName='font-size-13px text-secondary font-weight-bold text-gray-light'
        className='form-section-lateral d-lg-flex'
      >
        <TextField
          loading={displayNameLoading}
          className='w-100'
          placeholder='Display Name'
          value={displayName}
          onChange={(value): void => {
            setDisplayName(value);
          }}
          disabled={webplayer.displayName === displayName}
          onSubmit={async (): Promise<Account.WebPlayer> =>
            editDisplayName(account.id, { displayName: displayName.trim() })
          }
        />
      </Form.Descriptor>
    </DisplayNameContainer>
  );
};
