import { Device, Theme, Viewport } from '@livecontrol/core-ui';
import { Enum } from '@livecontrol/core-utils';
import cx from 'classnames';
import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Callout, Section } from './common';

const Grid = styled.div`
  display: grid;
  gap: var(--spacer-8px);
  margin: var(--spacer-8px) auto;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  width: 100%;

  > div {
    border-radius: var(--border-radius-sm);
    border: 1px solid var(--light);
    color: var(--tertiary);
    padding: 12px;
    text-align: center;

    &.x-active {
      color: var(--dark);
      border-color: var(--dark);
    }

    &.x-selected {
      color: var(--white);
      background-color: var(--primary);
    }
  }
`;

const ViewportWidth = (): React.ReactElement => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const listener = (): void => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', listener);

    return (): void => {
      window.removeEventListener('resize', listener);
    };
  }, [setWidth]);

  return (
    <span>
      The viewport is <strong>{width}</strong> pixels wide.
    </span>
  );
};

export const Responsive = withTheme(({ theme }: Theme.Props): React.ReactElement => {
  const viewport = Theme.useViewport();

  return (
    <Section name='Responsive Design'>
      <h2>Breakpoints</h2>

      <Callout>
        <code>
          theme.breakpoints = {'{'}
          <br />
          {Enum.keys(theme.breakpoints).map((k, index: number) => (
            <span key={k}>
              {index > 0 && (
                <>
                  ,<br />
                </>
              )}
              &nbsp;&nbsp;&nbsp;{k}: &quot;{theme.breakpoints[k]}&quot;
            </span>
          ))}
          <br />
          {'}'}
        </code>
      </Callout>

      <h2>Viewport</h2>

      <Callout>
        <code>const viewport = Theme.useViewport();</code>
      </Callout>

      <div className='d-flex mb-16px p-16px flex-column align-items-center text-center border'>
        <div
          className={cx(
            'font-size-8px', // Should not get applied
            'font-size-xs-14px',
            'font-size-sm-16px',
            'font-size-md-20px',
            'font-size-lg-24px',
            'font-size-xl-28px',
            'mb-20px'
          )}
        >
          <ViewportWidth />
        </div>

        <Grid>
          {[Viewport.XS, Viewport.SM, Viewport.MD, Viewport.LG, Viewport.XL].map((el: Viewport) => (
            <div
              className={cx(el === viewport && 'x-selected', el < viewport && 'x-active')}
              key={el}
            >
              {el}
            </div>
          ))}
        </Grid>
      </div>

      <h2>Device</h2>

      <Callout>
        <code>
          const isMobile = Device.isMobile(viewport);
          <br />
          const isTablet = Device.isTable(viewport);
          <br />
          const isDesktop = Device.isDesktop(viewport);
        </code>
      </Callout>

      <Grid>
        <div className={cx(Device.isMobile(viewport) && 'x-selected')}>Mobile</div>
        <div className={cx(Device.isTablet(viewport) && 'x-selected')}>Tablet</div>
        <div className={cx(Device.isDesktop(viewport) && 'x-selected')}>Desktop</div>
      </Grid>
    </Section>
  );
});
