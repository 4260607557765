import { Button } from '@livecontrol/core-ui';
import type { Location, User } from '@livecontrol/scheduler/model';
import type { ReactElement } from 'react';
import styled from 'styled-components';

interface Props {
  location?: Location;
  me: User;
}

const Image = styled.img`
  width: 38px;
  height: 38px;
`;

// const Photo = styled.img``;

const BorderStyle = styled.div`
  border-bottom: 1px solid #d5daea;
  padding-top: 40px;
  padding-bottom: 16px;

  .white-button {
    color: #0d1438;

    :hover {
      background-color: #8798ad;
    }
  }
`;

export const LocationInfo = ({ location, me }: Props): ReactElement => (
  <BorderStyle>
    <div className='row'>
      <div className='col-xl-10 col-lg'>
        <div className='row'>
          <div className='font-size-13px font-weight-bold p-24px col-lg-5 col-xl-4 d-flex flex-column flex-lg-row column-gap-8px row-gap-8px'>
            <Image src='assets/icons/location-plate.svg' alt='location' />
            <div>
              <div className='text-gray-light font-size-13px'>Location Name</div>
              <div className='text-dark mt-8px'>
                {location?.name.replaceAll('-', '- ').replaceAll('/', '/ ')}{' '}
              </div>
            </div>
          </div>
          <div className='font-size-13px font-weight-bold p-24px col-lg-4 col-xl-4'>
            <div className='text-gray-light font-size-13px'>Location & Shipping Address</div>
            <div className='text-dark mt-8px'>
              {location?.onboardingLocationAddress?.shippingStreetAddress ?? '-'}
            </div>
            {location?.onboardingLocationAddress?.shippingZipCode ?? '-'}
            {location?.onboardingLocationAddress?.shippingState ?? '-'}
          </div>
          <div className='font-size-13px font-weight-bold p-24px col-lg-3 col-xl-4'>
            <div className='text-gray-light font-size-13px'>Time Zone</div>
            <div className='text-dark mt-8px'>
              {location?.timezone.replaceAll('-', '- ').replaceAll('/', '/ ')}{' '}
            </div>
          </div>
          {/* <div className='font-size-13px font-weight-bold p-24px col-lg-3 col-xl-3'>
            <div className='text-gray-light font-size-13px'>Venue Photos</div>
            <div className='text-dark mt-8px'>
              <Photo src='assets/icons/photo/img-1.svg' alt='foto 1' className='mr-4px mb-4px' />
              <Photo src='assets/icons/photo/img-2.svg' alt='foto 2' className='mr-4px mb-4px' />
              <Photo src='assets/icons/photo/img-3.svg' alt='foto 3' className='mr-4px mb-4px' />
            </div>
          </div> */}
        </div>
      </div>

      <div className='col-xl-2 col-lg-1 pt-24px d-flex justify-content-lg-end'>
        {me.permissions.accountInfo && (
          <Button
            className='p-8px font-weight-bold white-button d-none'
            style={{ height: 'fit-content' }}
            size='sm'
            variant='outline-dark'
          >
            <img className='mr-8px' src='assets/icons/Edit-pencil.svg' alt='edit black' />
            Edit
          </Button>
        )}
      </div>
    </div>
  </BorderStyle>
);
