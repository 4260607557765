import { Nix, Obj, Str } from '@livecontrol/core-utils';
import { assert } from '@sindresorhus/is';

export class State {
  public readonly abbr: State.Abbr;

  public constructor(abbr: State.Abbr) {
    assert.truthy(Nix.isNotEmpty(abbr) && Nix.isNotEmpty(State.names[abbr]));

    this.abbr = abbr;
  }

  public get name(): string {
    return State.names[this.abbr];
  }

  public equals(value: State.Like): boolean {
    const v = State.normalize(value);

    return !!v && this.abbr === v.abbr;
  }

  public toString(): string {
    return this.abbr;
  }
}

const normalize_ = (value: unknown): State | undefined => {
  let state: State | undefined;

  if (value) {
    if (value instanceof State) {
      state = value;
    } else {
      const stringVal = Str.normalize(value);
      const { names } = State;

      if (stringVal) {
        // Is this a state name or abbreviation?
        const upperValue = stringVal.toUpperCase();

        // Were we handed a USPS abbreviation?
        const abbr = names.hasOwnProperty(upperValue)
          ? upperValue
          : Obj.keys(names).find(
              (item: State.Abbr): boolean => names[item].toUpperCase() === upperValue
            );

        state = abbr ? new State(<State.Abbr>abbr) : undefined;
      }
    }
  }

  return state;
};

/* istanbul ignore next */
export namespace State {
  export type Abbr =
    | 'AK'
    | 'AL'
    | 'AR'
    | 'AS'
    | 'AZ'
    | 'CA'
    | 'CO'
    | 'CT'
    | 'DC'
    | 'DE'
    | 'FL'
    | 'GA'
    | 'GU'
    | 'HI'
    | 'IA'
    | 'ID'
    | 'IL'
    | 'IN'
    | 'KS'
    | 'KY'
    | 'LA'
    | 'MA'
    | 'MD'
    | 'ME'
    | 'MI'
    | 'MN'
    | 'MO'
    | 'MP'
    | 'MS'
    | 'MT'
    | 'NC'
    | 'ND'
    | 'NE'
    | 'NH'
    | 'NJ'
    | 'NM'
    | 'NV'
    | 'NY'
    | 'OH'
    | 'OK'
    | 'OR'
    | 'PA'
    | 'PR'
    | 'RI'
    | 'SC'
    | 'SD'
    | 'TN'
    | 'TX'
    | 'UT'
    | 'VA'
    | 'VI'
    | 'VT'
    | 'WA'
    | 'WI'
    | 'WV'
    | 'WY';

  export const names: Record<Abbr, string> = Object.freeze({
    AK: 'Alaska',
    AL: 'Alabama',
    AR: 'Arkansas',
    AS: 'American Samoa',
    AZ: 'Arizona',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District of Columbia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MP: 'Northern Mariana Islands',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    VI: 'Virgin Islands',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming'
  });

  export const Alaska = new State('AK');
  export const Alabama = new State('AL');
  export const Arkansas = new State('AR');
  export const American_Samoa = new State('AS');
  export const Arizona = new State('AZ');
  export const California = new State('CA');
  export const Colorado = new State('CO');
  export const Connecticut = new State('CT');
  export const Delaware = new State('DE');
  export const District_of_Columbia = new State('DC');
  export const Florida = new State('FL');
  export const Georgia = new State('GA');
  export const Guam = new State('GU');
  export const Hawaii = new State('HI');
  export const Idaho = new State('ID');
  export const Illinois = new State('IL');
  export const Indiana = new State('IN');
  export const Iowa = new State('IA');
  export const Kansas = new State('KS');
  export const Kentucky = new State('KY');
  export const Louisiana = new State('LA');
  export const Maine = new State('ME');
  export const Maryland = new State('MD');
  export const Massachusetts = new State('MA');
  export const Michigan = new State('MI');
  export const Minnesota = new State('MN');
  export const Northern_Mariana_Islands = new State('MP');
  export const Mississippi = new State('MS');
  export const Missouri = new State('MO');
  export const Montana = new State('MT');
  export const Nebraska = new State('NE');
  export const Nevada = new State('NV');
  export const New_Hampshire = new State('NH');
  export const New_Jersey = new State('NJ');
  export const New_Mexico = new State('NM');
  export const New_York = new State('NY');
  export const North_Carolina = new State('NC');
  export const North_Dakota = new State('ND');
  export const Ohio = new State('OH');
  export const Oklahoma = new State('OK');
  export const Oregon = new State('OR');
  export const Pennsylvania = new State('PA');
  export const Puerto_Rico = new State('PR');
  export const Rhode_Island = new State('RI');
  export const South_Carolina = new State('SC');
  export const South_Dakota = new State('SD');
  export const Tennessee = new State('TN');
  export const Texas = new State('TX');
  export const Utah = new State('UT');
  export const Vermont = new State('VT');
  export const Virginia = new State('VA');
  export const Virgin_Islands = new State('VI');
  export const Washington = new State('WA');
  export const West_Virginia = new State('WV');
  export const Wisconsin = new State('WI');
  export const Wyoming = new State('WY');

  export type Like = Abbr | State;

  export const normalize = normalize_;
}
