import { Device, Theme } from '@livecontrol/core-ui';
// eslint-disable-next-line import/no-unresolved
import { Keyword } from 'libs/scheduler/components/src/filters';
import styled from 'styled-components';
import { SectionContainer } from '../../../components';
import { Active } from './active';
import { Inactive } from './inactive';
import { Tab, Tabber } from './tabber';

const Container = styled.div`
.search-section {
    border: solid 1px #8798ad;
    background-color: white;
    width: 348px;
    height: 36px;

    input {
      font-size: 12px;
      &::placeholder,
      &::-ms-input-placeholder {
        color: #0d1438 !important;
        opacity: 1;
        font-size: 12px;
      }
  
      &::placeholder {
        color: #0d1438 !important;
        opacity: 1;
        font-size: 12px;
      }

    }

    
  }
`;

export const CustomerList = (): React.ReactElement => {

  const viewport = Theme.useViewport();
  const size = Device.isMobile(viewport) ? 'sm' : 'lg';


    const { pathname } = window.location;

    const tab =
      pathname.includes('activeList')
      ? Tab.ActiveList
      : Tab.Inactive;

  const Content =
      tab === Tab.ActiveList
      ? Active
      : Inactive;

  return (
    <SectionContainer className='col-12'>
      <Container className='d-flex justify-content-between'>
        <h1 className='m-0'>Customer List</h1>
        <Keyword type='text' size={size} className='search-section' placeholder='Search CID, Organization, Account Owner' />
      </Container>
      <Tabber tab={tab} />
      <Content />
    </SectionContainer>
  );
};
