/* eslint-disable react/no-unescaped-entities */
import type { EventValidator } from '@livecontrol/scheduler/validator';
import React, { useState } from 'react';
import { Form } from '../../../../components';
import { RedirectModal } from './redirect-modal';

export const TechnicalContact = ({
  contacts,
  showTechnicalContacts = false,
  selectedLocationId = ''
}: {
  contacts: EventValidator.EventTechnicalContact[] | undefined;
  showTechnicalContacts: boolean;
  selectedLocationId: string;
}): React.ReactElement => {
  const contactsByLocation = contacts?.filter(
    (contact) => contact.locationId === selectedLocationId
  );
  
  contactsByLocation?.sort((a, b) => a.onsiteTechnicalPriority - b.onsiteTechnicalPriority);

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = (): void => {
    setModalOpen(true);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLSpanElement>): void => {
    if (event.key === 'Enter' || event.key === ' ') {
      openModal();
    }
  };

  return (
    <Form.Descriptor
      title='Assign an On-Site Technical Contact'
      titleClassName='title mr-lg-3 font-size-15px font-weight-bold text-dark'
      description={
        <>
          If you'd like to assign a specific technical contact for this event, please choose from
          your existing On-Site Technical Contacts.
          <div>
            Update or add a new On-Site Technical Contact under
            <span
              onClick={openModal}
              onKeyPress={handleKeyPress}
              style={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }}
              tabIndex={0} // Añade un índice de tabulación para que el elemento sea enfocable
              role='button' // Añade un atributo de rol para indicar que es un elemento interactivo
            >
              {' '}
              Account Info here.
            </span>
          </div>
        </>
      }
      descriptionClassName='font-size-13px text-secondary font-weight-bold text-gray-light line-height-18px'
      className='form-section-lateral d-lg-flex'
    >
      <div className='custom-form-group technical-group'>
        <Form.SwitchCustomField
          className=''
          title=''
          titleClassName=''
          description=''
          descriptionClassName=''
          name='technicalContactSettings.enabled'
          noSaveIndicator={false}
        />
        {showTechnicalContacts &&
          (contactsByLocation ? (
            <Form.Group
              name='technicalContactSettings.contactId'
              label={false}
              className='custom-input technical-input'
              controlProps={{
                as: 'select',
                disabled: contactsByLocation.length === 0
              }}
              wrapperClass='input-wrapper'
              rightIconClass='icon chevron-down'
            >
              {!contactsByLocation.length && <option value={0}>No contacts</option>}
              {contactsByLocation.map((contact) => (
                <option key={contact.id} value={contact.id}>
                  {contact.name ? contact.name : contact.email}
                </option>
              ))}
            </Form.Group>
          ) : (
            <Form.Group
              name='technicalContact'
              label={false}
              className='custom-input technical-input'
              control={
                <div className='input-wrapper disabled'>
                  <Form.Control as='select' disabled>
                    <option value={undefined}>No Available Contacts</option>
                  </Form.Control>
                  <div className='icon-section'>
                    <div className='icon chevron-down' />
                  </div>
                </div>
              }
            />
          ))}
      </div>
      <RedirectModal
        redirectString='/account/users/assigned-contacts?role_selected=on_site_technical'
        show={isModalOpen}
        onHide={(): void => {
          setModalOpen(false);
        }}
      />
    </Form.Descriptor>
  );
};
