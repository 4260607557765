import { Scheduler } from '@livecontrol/scheduler/store';
import type { FormikContextType } from 'formik';
import { useFormik } from 'formik';
import { useCallback, useRef } from 'react';
import { Alert, Store } from '../../../../store';
import type { Event } from '../../store';

export interface Props {
  event: Event;
  onClose: () => void;
}

interface Values {
  passwordEnabled: boolean;
  password?: string;
}

interface Form {
  formik: FormikContextType<Values>;
  loading: boolean;
  alert: Alert;
}

export const useForm = ({ event, onClose }: Props): Form => {
  const account = Store.Account.useAccount();
  const [mutation, { loading, error }] = Scheduler.Event.useEdit();

  const alert = Alert.useAlert(error);

  const initialValues = useRef({
    passwordEnabled: !!event.password,
    password: event.password
  }).current;

  const validate = useCallback(
    (values: Values): Record<string, string> => {
      alert.hide();

      const errs: Record<string, string> = {};

      if (values.passwordEnabled && (!values.password || values.password.length === 0)) {
        errs.password = 'Please specify a password for this event.';
      }

      return errs;
    },
    [alert]
  );

  const formik = useFormik<Values>({
    initialValues,
    validate,
    validateOnMount: true,
    validateOnBlur: true,

    async onSubmit(values: Values) {
      // If not loading, execute the mutation
      if (!loading) {
        await mutation({
          account,
          event,
          replacement: {
            ...event,
            password: values.passwordEnabled ? values.password : undefined
          },
          eventLocation: event.location
        });

        onClose();
      }
    }
  });

  return {
    formik,
    loading,
    alert
  };
};
