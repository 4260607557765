/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Filters } from '@livecontrol/scheduler/components';
import type { ReactElement } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import L = Filters.UserRestrictedLocation;

export const LocationFilter = ({
  locations,
  ...props
}: Omit<L.Props, 'value'>): ReactElement | null => (
  <L value={locations} {...props} onChange={locations.update} as={ButtonGroup} />
);
