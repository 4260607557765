import { Grid } from '@livecontrol/core-ui';
import cx from 'classnames';
import type { ReactElement } from 'react';
import styled from 'styled-components';

const ButtonSection = styled.div`
  .link-custom {
    padding: 0px !important;
    border: none;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    color: #8798ad;
    &:hover {
      background-color: white;
      border: none;
      color: #515d82;
    }
  }
`;

export const ButtonBox = ({ children, className, ...props }: ButtonBox.Props): ReactElement => {
  const gap =
    !className || className.search(/gap-((xs|sm|md|lg|xl)-)?\d+px/) === -1
      ? 'gap-8px gap-lg-16px'
      : undefined;

  return (
    <ButtonSection>
      <Grid.Symmetric className={cx(className, gap)} {...props}>
        {children}
      </Grid.Symmetric>
    </ButtonSection>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace ButtonBox {
  export type Props = Grid.Symmetric.Props;
}
