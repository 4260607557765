import { Device, Loading, Theme, Viewport } from '@livecontrol/core-ui';
import { Calendar } from '@livecontrol/core-utils';
import { Event } from '@livecontrol/scheduler/model';
import type { Location, User } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { EventValidator, validateNewEventDetails } from '@livecontrol/scheduler/validator';
import cx from 'classnames';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import _ from 'lodash';
import { DateTime, Duration, IANAZone } from 'luxon';
import type { Moment } from 'moment';
import moment from 'moment';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Form } from '../../../components';
import { store, Store } from '../../../store';
import type { EventContext } from '../event-store';
import { EventContextStore } from '../event-store';
import {
  ContentSection,
  CustomInputContainer,
  FooterSection,
  HeaderSection
} from '../layout-sections';
import { EventDetailsForm } from './event-details-form';
import { EventDetailsUtils } from './event-details-utils';

const LogisticsFormSection = styled.div`
  .input-wrapper {
    span {
      border: none !important;
    }
  }

  .info-section {
    width: 228px;
    padding: 8px 28px;

    .x-${Device.MOBILE} & {
      padding: 14px 0px;
    }

    .title,
    .text {
      color: #2e384d;
      font-size: 15px;
      font-weight: 600;
    }

    .title {
      margin-bottom: 16px;
    }

    .text {
      margin-top: 4px;
    }

    .credits-number {
      width: 32px;
      height: 32px;
      border-radius: 16px;
      background-color: #2e5bff;
      color: #ffffff;
    }
  }

  .lead-time-error {
    max-width: 900px;
    font-size: 15px;
    margin-bottom: 42px;

    .error-text,
    .error-text a {
      color: #ff0000;
      margin-bottom: 24px;
      line-height: 23px;
      a {
        text-decoration: underline;
        color: #2e5bff;
      }
    }

    .invalid-feedback {
      display: none;
    }

    input {
      width: 16px;
      cursor: pointer;
    }

    .form-check {
      display: flex;
      align-items: center;

      .form-group {
        margin-bottom: 0px;
        margin-right: 16px;
      }
    }

    .form-check-label {
      font-weight: 600;
    }
  }
`;

function usePrevious<T>(value: T): T | undefined {
  const ref = React.useRef<T>();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export const EventDetails = ({
  onBack,
  onContinue,
  title,
  isMobileInfo = false,
  locations,
  accountSettings,
  contacts
}: {
  onBack: () => void;
  onContinue: () => void;
  title?: string;
  isMobileInfo?: boolean;
  locations: Location[] | undefined;
  contacts: EventValidator.EventTechnicalContact[] | undefined;
  accountSettings: User.admin;
}): React.ReactElement => {
  const { production } = store.environment;
  const account = Store.Account.useAccount();
  const { billing } = Scheduler.Account.useBilling(account);
  const isAdmin = Store.User.useIsAdmin();
  const { events: allEvents } = Scheduler.Event.useEvents(account);
  const { store: eventDetailsContext }: EventContext = useContext<EventContext>(EventContextStore);
  const viewport = Theme.useViewport();

  const showOnSiteContact =
    eventDetailsContext.eventType !== EventValidator.ProductionType.TEST &&
    eventDetailsContext.eventType !== EventValidator.ProductionType.SIMULATED &&
    eventDetailsContext.eventType !== EventValidator.ProductionType.MOBILE &&
    accountSettings.userGlobalSettings.onSiteEventContact;

  const showVideographerNotes = accountSettings.userGlobalSettings.operatorEventNotes;

  const [fixedDuration, setFixedDuration] = useState('-:-');

  // Set this to the event type in the context to refresh the UI elements
  if (eventDetailsContext.eventInfo.type) {
    eventDetailsContext.eventInfo.type = eventDetailsContext.eventType;
  }

  const eventTypeForLocations =
    eventDetailsContext.eventType === EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT
      ? EventValidator.ProductionType.PRODUCED
      : eventDetailsContext.eventType;

  const availableLocations = useMemo(
    (): Location[] =>
      EventDetailsUtils.getAvailableLocations(locations, eventTypeForLocations).sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    [locations, eventTypeForLocations]
  );

  const timezone: string | undefined = useMemo(() => {
    const selected = availableLocations.find(
      (location) => location.id === eventDetailsContext.eventInfo.location
    );

    const timezone_ = selected
      ? selected.timezone
      : availableLocations.length
      ? availableLocations[0].timezone
      : undefined;

    return timezone_;
  }, [availableLocations, eventDetailsContext.eventInfo.location]);

  const isProduced =
    eventDetailsContext.eventType === EventValidator.ProductionType.PRODUCED ||
    (eventDetailsContext.eventType === EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT &&
      !isMobileInfo);

  const isProducedAndMobileFlow =
    eventDetailsContext.eventType === EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT;

  const isMobileFlow = eventDetailsContext.eventType === EventValidator.ProductionType.MOBILE_KIT;

  const isSimulated = eventDetailsContext.eventType === EventValidator.ProductionType.SIMULATED;

  const isTest = eventDetailsContext.eventType === EventValidator.ProductionType.TEST;

  const initialDates = useMemo(() => {
    const date =
      eventDetailsContext.eventInfo.start?.setZone(timezone ?? account.timezone) ??
      DateTime.utc()
        .plus(
          isProduced || isMobileFlow ? { days: account.leadTime, hours: 2 } : { days: 0, hours: 2 }
        )
        .startOf('hour')
        .setZone(timezone ?? account.timezone);

    const startTime = date.toFormat('hhmm a');

    /*
     * If event is simulated live, use either the duration of the uploaded file or the
     * duration of the asset, using 3 hours as a fallback if the duration is not present.
     */
    const endTime =
      eventDetailsContext.eventInfo.end?.setZone(timezone ?? account.timezone).toFormat('hhmm a') ??
      date
        .plus(
          eventDetailsContext.eventType === EventValidator.ProductionType.SIMULATED &&
            eventDetailsContext.simulatedLiveAssetIsUpload &&
            eventDetailsContext.simulatedLiveFileUpload?.videoDuration
            ? eventDetailsContext.simulatedLiveFileUpload.videoDuration
            : eventDetailsContext.eventType === EventValidator.ProductionType.SIMULATED &&
              eventDetailsContext.simulatedLiveAssetIsUpload
            ? EventValidator.SchedulerDurations.DEFAULT_SIMULATED_LIVE_DURATION
            : eventDetailsContext.eventType === EventValidator.ProductionType.SIMULATED &&
              eventDetailsContext.simulatedLiveAssetDetails?.duration
            ? eventDetailsContext.simulatedLiveAssetDetails.duration
            : eventDetailsContext.eventType === EventValidator.ProductionType.SIMULATED
            ? EventValidator.SchedulerDurations.DEFAULT_SIMULATED_LIVE_DURATION
            : eventDetailsContext.eventType === EventValidator.ProductionType.TEST
            ? EventValidator.SchedulerDurations.TEST_EVENT_DURATION
            : EventValidator.SchedulerDurations.DEFAULT_EVENT_DURATION
        )
        .setZone(timezone ?? account.timezone)
        .toFormat('hhmm a');

    return {
      date,
      startTime,
      endTime
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone]);

  const displayTitle = title?.length ? title : 'Logistics';

  const contactsByLocation = contacts?.filter(
    (contact) => contact.locationId === (availableLocations.length ? availableLocations[0].id : '')
  );

  contactsByLocation?.sort((a, b) => a.onsiteTechnicalPriority - b.onsiteTechnicalPriority);

  const isSelectedContactValid = !!(
    eventDetailsContext.eventInfo.technicalContactSettings?.contactId &&
    contactsByLocation?.some(
      (contact) => contact.id === eventDetailsContext.eventInfo.technicalContactSettings?.contactId
    )
  );

  const selectedTechnicalContact = isSelectedContactValid
    ? eventDetailsContext.eventInfo.technicalContactSettings?.contactId
    : contactsByLocation?.length
    ? contactsByLocation[0].id
    : 0;

  const selectedLocation =
    eventDetailsContext.eventInfo.location &&
    availableLocations.some((location) => location.id === eventDetailsContext.eventInfo.location)
      ? eventDetailsContext.eventInfo.location
      : availableLocations.length
      ? availableLocations[0].id
      : '';

  const formLayout = useCallback(
    (
      values: EventDetailsForm.Values,
      errors: EventDetailsForm.Errors,
      setErrors: FormikHelpers<EventDetailsForm.Values>['setErrors']
    ): React.ReactElement => {
      const { startTime, endTime } = EventDetailsUtils.calculateEventTimes({ ...values, timezone });
      let eventDuration = startTime && endTime && endTime.diff(startTime, 'minutes');

      let displayDuration = '-:-';

      const beforeLeadTime = isAdmin
        ? false
        : (startTime?.diffNow().as('days') ?? account.leadTime + 1) < account.leadTime;

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const previousValues = usePrevious(values);

      let dateHasChanged = false;

      if (
        values.date &&
        previousValues?.date &&
        values.startTime &&
        previousValues.startTime &&
        values.endTime &&
        previousValues.endTime &&
        (!values.date.equals(previousValues.date) ||
          !values.startTime.isSame(previousValues.startTime) ||
          !values.endTime.isSame(previousValues.endTime))
      ) {
        dateHasChanged = true;
      }

      if (isProduced || isMobileFlow) {
        if (!beforeLeadTime) {
          values.leadtimeAcknowledge = undefined;
        } else if (values.leadtimeAcknowledge === undefined) {
          values.leadtimeAcknowledge = false;
        }
      } else {
        values.leadtimeAcknowledge = true;
      }

      if (values.leadtimeAcknowledge && dateHasChanged) {
        values.leadtimeAcknowledge = false;
      }

      if (eventDuration && eventDuration.minutes > 0) {
        const durationHours = Math.floor(eventDuration.minutes / 60);
        const durationMinutes = eventDuration.minutes - durationHours * 60;

        displayDuration = `${durationHours}h ${durationMinutes}m`;
      } else if (eventDuration && eventDuration.minutes < 0) {
        const positiveMinutes = 1440 + eventDuration.minutes;
        const durationHours = Math.floor(positiveMinutes / 60);
        const durationMinutes = positiveMinutes - durationHours * 60;

        displayDuration = `${durationHours}h ${durationMinutes}m`;

        eventDuration = Duration.fromMillis(positiveMinutes * 60 * 1000);
      }

      if ((isSimulated || isTest) && displayDuration !== '-:-' && fixedDuration === '-:-') {
        setFixedDuration(displayDuration);
      }

      let creditsForEvent = 0;

      if (
        eventDetailsContext.eventType === EventValidator.ProductionType.MOBILE_KIT ||
        eventDetailsContext.eventType === EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT
      ) {
        creditsForEvent = 1;
      } else if (isProduced && eventDuration && billing) {
        creditsForEvent = Event.Production.computeCreditsChargedForProducedEvent({
          duration: eventDuration,
          overTimeLimitCharge: billing.credits.over_time_limit_cost,
          overTimeLimitInMinutes: billing.credits.first_credit_time_limit
        });
      }

      if (beforeLeadTime && isProducedAndMobileFlow) {
        values.leadtimeAcknowledge = false;

        if (Object.keys(errors).length < 1) {
          setErrors({ ...errors, leadtimeAcknowledge: 'Lead time warning must be acknowledged.' });
        }
      } else if (!beforeLeadTime && isProducedAndMobileFlow && errors.leadtimeAcknowledge) {
        const updatedErrors = { ...errors };

        delete updatedErrors.leadtimeAcknowledge;
        setErrors(updatedErrors);
      }

      return (
        <>
          <HeaderSection>
            <div className='title'>
              <div className='text'>{displayTitle} </div>
            </div>
          </HeaderSection>

          <ContentSection>
            <LogisticsFormSection>
              <div
                className={cx(
                  'd-flex',
                  'custom-form-group',
                  'justify-content-between',
                  'flex-wrap',
                  !isAdmin && isProduced && beforeLeadTime && 'error'
                )}
              >
                <EventDetailsForm.EventLocation options={availableLocations} errors={errors} />
                <EventDetailsForm.Date
                  timezone={timezone}
                  eventType={eventDetailsContext.eventType}
                  errors={errors}
                />
              </div>

              <div className='custom-form-group d-flex justify-content-between flex-wrap'>
                <div
                  className={cx(
                    'd-flex',
                    'times-section',
                    'justify-content-between',
                    'flex-wrap',
                    !isAdmin && isProduced && beforeLeadTime && 'error'
                  )}
                >
                  <EventDetailsForm.StartTime timezone={timezone} errors={errors} />
                  {eventDetailsContext.eventType === EventValidator.ProductionType.SIMULATED ? (
                    <Form.Group
                      name='fixedEndTime'
                      className='d-flex flex-column custom-input time-input'
                      label={
                        <label>
                          End Time{' '}
                          {timezone && (
                            <span style={{ color: '#0a4b86', fontWeight: 800 }}>
                              ({DateTime.local().setZone(timezone).toFormat('ZZZZ')})
                            </span>
                          )}
                        </label>
                      }
                      error={
                        <p className='text-muted font-size-12px font-weight-normal'>
                          End time is calculated based on recorded video duration.
                        </p>
                      }
                      controlProps={{
                        as: 'select',
                        defaultValue: 1,
                        disabled: true
                      }}
                    >
                      <option value={1}>
                        {(
                          eventDetailsContext.eventInfo.end
                            ?.setZone(timezone ?? account.timezone)
                            .toFormat('h:mm a') ??
                          DateTime.fromFormat(initialDates.endTime, 'hhmm a', {
                            zone: timezone ?? account.timezone
                          }).toFormat('h:mm a')
                        ).toLowerCase()}
                      </option>
                    </Form.Group>
                  ) : !isAdmin &&
                    eventDetailsContext.eventType === EventValidator.ProductionType.TEST ? (
                    <Form.Group
                      name='fixedEndTime'
                      className='d-flex flex-column custom-input time-input'
                      label={
                        <label>
                          End Time{' '}
                          {timezone && (
                            <span style={{ color: '#0a4b86', fontWeight: 800 }}>
                              ({DateTime.local().setZone(timezone).toFormat('ZZZZ')})
                            </span>
                          )}
                        </label>
                      }
                      error={
                        <p className='text-muted font-size-12px font-weight-normal'>
                          Test events are{' '}
                          {EventValidator.SchedulerDurations.TEST_EVENT_DURATION.minutes} minutes
                        </p>
                      }
                      controlProps={{
                        as: 'select',
                        defaultValue: 1,
                        disabled: true
                      }}
                    >
                      <option value={1}>
                        {(
                          eventDetailsContext.eventInfo.end
                            ?.setZone(timezone ?? account.timezone)
                            .toFormat('h:mm a') ??
                          DateTime.fromFormat(initialDates.endTime, 'hhmm a', {
                            zone: timezone ?? account.timezone
                          }).toFormat('h:mm a')
                        ).toLowerCase()}
                      </option>
                    </Form.Group>
                  ) : (
                    <EventDetailsForm.EndTime
                      timezone={timezone}
                      errors={errors}
                      startTime={startTime}
                      endTime={endTime}
                      startDate={values.date}
                      eventType={eventDetailsContext.eventType}
                    />
                  )}
                </div>

                {viewport !== Viewport.XL &&
                  viewport !== Viewport.MD &&
                  viewport !== Viewport.LG && (
                    <div className='lead-time-error'>
                      {!isAdmin && isProduced && beforeLeadTime && !isProducedAndMobileFlow && (
                        <>
                          <div className='error-text'>
                            You are attempting to schedule or edit the date/time of a Produced event
                            outside of your lead time - {account.leadTime * 24} hours. This event
                            will default to a Static event. Please finish scheduling the event, then
                            call Support if you would like to change it to Produced. Refer to our{' '}
                            <a
                              href='https://mylivecontrol.zendesk.com/hc/en-us/articles/13287638920471-Last-Minute-Event-Policy-Late-Scheduling'
                              target='_blank'
                              rel='noreferrer noopener'
                            >
                              Late Scheduling Policy here
                            </a>{' '}
                            . Late requests are not guaranteed.
                          </div>
                          <div className='form-check'>
                            <Form.Group
                              name='leadtimeAcknowledge'
                              className=''
                              label={false}
                              controlProps={{
                                type: 'checkbox'
                              }}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='leadtimeAcknowledge'
                              style={{ cursor: 'default' }}
                            >
                              Acknowledge
                            </label>
                          </div>
                        </>
                      )}
                      {!isAdmin && isProduced && beforeLeadTime && isProducedAndMobileFlow && (
                        <div className='error-text'>
                          You are attempting to schedule or edit the date/time of a Produced +
                          Mobile Kit event outside of your lead time - {account.leadTime * 24}{' '}
                          hours. This event cannot be scheduled or edited. Please call Support if
                          you would like to schedule or modify this event. Late requests are not
                          guaranteed. For more information, you may refer to our{' '}
                          <a
                            href='https://mylivecontrol.zendesk.com/hc/en-us/articles/13287638920471-Last-Minute-Event-Policy-Late-Scheduling'
                            target='_blank'
                            rel='noreferrer noopener'
                          >
                            Late Scheduling Policy here.
                          </a>
                        </div>
                      )}
                      {!isAdmin && isMobileFlow && beforeLeadTime && (
                        <div className='error-text'>
                          You are attempting to schedule or edit the date/time of a Mobile Kit event
                          outside of your lead time - {account.leadTime * 24} hours. Please call our
                          Support team to make any scheduling changes to this event. Refer to our{' '}
                          <a
                            href='https://mylivecontrol.zendesk.com/hc/en-us/articles/13287638920471-Last-Minute-Event-Policy-Late-Scheduling'
                            target='_blank'
                            rel='noreferrer noopener'
                          >
                            Late Scheduling Policy here
                          </a>{' '}
                          . Late requests are not guaranteed.
                        </div>
                      )}
                    </div>
                  )}

                <div className='info-section d-flex justify-content-between'>
                  <div className='duration d-flex flex-column custom-input'>
                    <div className='title'>Duration</div>
                    {isSimulated || isTest ? (
                      <div className='text'>{fixedDuration}</div>
                    ) : (
                      <div className='text'>{displayDuration}</div>
                    )}
                  </div>
                  <div className='credits d-flex flex-column align-items-center custom-input'>
                    <div className='title'>Credit(s)</div>
                    <div className='credits-number d-flex align-items-center justify-content-center'>
                      {creditsForEvent}
                    </div>
                  </div>
                </div>

                {viewport !== Viewport.XS && viewport !== Viewport.SM && (
                  <div className='lead-time-error'>
                    {!isAdmin && isProduced && beforeLeadTime && !isProducedAndMobileFlow && (
                      <>
                        <div className='error-text'>
                          You are attempting to schedule or edit the date/time of a Produced event
                          outside of your lead time - {account.leadTime * 24} hours. This event will
                          default to a Static event. Please finish scheduling the event, then call
                          Support if you would like to change it to Produced. Refer to our{' '}
                          <a
                            href='https://mylivecontrol.zendesk.com/hc/en-us/articles/13287638920471-Last-Minute-Event-Policy-Late-Scheduling'
                            target='_blank'
                            rel='noreferrer noopener'
                          >
                            Late Scheduling Policy here
                          </a>{' '}
                          . Late requests are not guaranteed.
                        </div>
                        <div className='form-check'>
                          <Form.Group
                            name='leadtimeAcknowledge'
                            className=''
                            label={false}
                            controlProps={{
                              type: 'checkbox'
                            }}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='leadtimeAcknowledge'
                            style={{ cursor: 'default' }}
                          >
                            Acknowledge
                          </label>
                        </div>
                      </>
                    )}
                    {!isAdmin && isProduced && beforeLeadTime && isProducedAndMobileFlow && (
                      <div className='error-text'>
                        You are attempting to schedule or edit the date/time of a Produced + Mobile
                        Kit event outside of your lead time - {account.leadTime * 24} hours. This
                        event cannot be scheduled or edited. Please call Support if you would like
                        to schedule or modify this event. Late requests are not guaranteed. For more
                        information, you may refer to our{' '}
                        <a
                          href='https://mylivecontrol.zendesk.com/hc/en-us/articles/13287638920471-Last-Minute-Event-Policy-Late-Scheduling'
                          target='_blank'
                          rel='noreferrer noopener'
                        >
                          Late Scheduling Policy here.
                        </a>
                      </div>
                    )}
                    {!isAdmin && isMobileFlow && beforeLeadTime && (
                      <div className='error-text'>
                        You are attempting to schedule or edit the date/time of a Mobile Kit event
                        outside of your lead time - {account.leadTime * 24} hours. Please call our
                        Support team to make any scheduling changes to this event. Refer to our{' '}
                        <a
                          href='https://mylivecontrol.zendesk.com/hc/en-us/articles/13287638920471-Last-Minute-Event-Policy-Late-Scheduling'
                          target='_blank'
                          rel='noreferrer noopener'
                        >
                          Late Scheduling Policy here
                        </a>{' '}
                        . Late requests are not guaranteed.
                      </div>
                    )}
                  </div>
                )}
              </div>
            </LogisticsFormSection>
          </ContentSection>

          <HeaderSection>
            <div className='divider' />
            <div className='title'>
              <div className='text'>Information </div>
            </div>
          </HeaderSection>

          <ContentSection>
            <CustomInputContainer>
              <EventDetailsForm.Title
                prefix={
                  eventDetailsContext.eventType === EventValidator.ProductionType.TEST
                    ? 'Test \u2013 '
                    : undefined
                }
              />
            </CustomInputContainer>

            {eventDetailsContext.eventType !== EventValidator.ProductionType.TEST && (
              <>
                <CustomInputContainer>
                  <EventDetailsForm.Description />
                </CustomInputContainer>

                {showOnSiteContact && (
                  <CustomInputContainer>
                    <EventDetailsForm.TechnicalContact
                      contacts={contacts}
                      showTechnicalContacts={
                        eventDetailsContext.eventInfo.technicalContactSettings?.enabled ?? false
                      }
                      selectedLocationId={selectedLocation}
                    />
                  </CustomInputContainer>
                )}
                {isProduced && showVideographerNotes && (
                  <CustomInputContainer>
                    <EventDetailsForm.OperatorNotes />
                  </CustomInputContainer>
                )}
              </>
            )}
          </ContentSection>

          <FooterSection>
            <EventDetailsForm.Submit onBack={onBack} />
          </FooterSection>
        </>
      );
    },
    [
      timezone,
      isAdmin,
      account.leadTime,
      account.timezone,
      isProduced,
      isMobileFlow,
      isSimulated,
      isTest,
      fixedDuration,
      eventDetailsContext.eventType,
      eventDetailsContext.eventInfo.end,
      eventDetailsContext.eventInfo.technicalContactSettings?.enabled,
      selectedLocation,
      billing,
      isProducedAndMobileFlow,
      displayTitle,
      availableLocations,
      initialDates.endTime,
      viewport,
      showOnSiteContact,
      contacts,
      showVideographerNotes,
      onBack
    ]
  );

  if (!allEvents || !billing || availableLocations.length < 1) {
    return <Loading.Delay />;
  }

  const formik = {
    initialValues: {
      type: eventDetailsContext.eventType,
      location: selectedLocation,
      date: initialDates.date,
      timezone,
      startTime: moment(initialDates.startTime, 'hhmm a'),
      endTime: moment(
        eventDetailsContext.eventInfo.end
          ?.setZone(timezone ?? account.timezone)
          .toFormat('hhmm a') ?? initialDates.endTime,
        'hhmm a'
      ),
      passwordSettings: {
        enabled: false,
        password: ''
      },
      redirectUrl: {
        enabled: false,
        url: ''
      },
      chatSettings: {
        registration: false,
        requireRegistration: false,
        liveChat: true,
        showViewers: false
      },
      title: '',
      repeatSettings: {
        repeat: false,
        repeatFrequency: {
          repeatEvery: 1,
          unit: Calendar.Unit.WEEKS,
          daysOfWeek: new Set<Calendar.Weekday>()
        },
        repeatUntil: {
          numRecurrences: 3,
          stopRepeatingOn: DateTime.utc()
            .plus({ weeks: 3, days: 2 })
            .startOf('hour')
            .setZone(timezone ?? account.timezone)
        },
        repeatEndCondition: EventValidator.EventInfo.RepeatEndCondition.ON_DATE
      },
      zoomDetails: {
        streamZoom: false,
        meetingId: '',
        password: ''
      },
      technicalContactSettings: {
        enabled:
          isSelectedContactValid && eventDetailsContext.eventInfo.technicalContactSettings?.enabled,
        contactId: selectedTechnicalContact
      },
      ..._.omit(eventDetailsContext.eventInfo, ['start', 'end'])
    },
    validate: (
      values: Exclude<EventValidator.EventInfo, { start: DateTime; end: DateTime }> & {
        date?: DateTime;
        startTime?: Moment;
        endTime?: Moment;
        leadTime?: boolean;
        leadtimeAcknowledge?: boolean;
      }
    ): EventValidator.Errors => {
      let startDate = _.cloneDeep(values.date)?.setZone(timezone ?? account.timezone);
      let endDate = _.cloneDeep(values.date)?.setZone(timezone ?? account.timezone);

      if (startDate && values.startTime) {
        const start = DateTime.fromFormat(values.startTime.format('hhmm a'), 'hhmm a', {
          zone: timezone ?? account.timezone
        });

        if (!start.isValid) {
          return {
            startTime: EventValidator.ErrorMessages.INVALID_TIME_FORMAT
          };
        }

        startDate = startDate.set({ hour: start.hour, minute: start.minute }).toUTC();
      }

      if (endDate?.isValid && values.endTime) {
        const end = DateTime.fromFormat(values.endTime.format('hhmm a'), 'hhmm a', {
          zone: timezone ?? account.timezone
        });

        if (!end.isValid) {
          return {
            endTime: EventValidator.ErrorMessages.INVALID_TIME_FORMAT
          };
        }

        endDate =
          eventDetailsContext.eventType === EventValidator.ProductionType.SIMULATED
            ? startDate?.plus(
                eventDetailsContext.simulatedLiveAssetDetails?.duration ??
                  eventDetailsContext.simulatedLiveFileUpload!.videoDuration
              )
            : eventDetailsContext.eventType === EventValidator.ProductionType.TEST
            ? startDate?.plus(EventValidator.SchedulerDurations.TEST_EVENT_DURATION)
            : endDate.set({ hour: end.hour, minute: end.minute }).toUTC();

        if (startDate && endDate && endDate < startDate) {
          endDate = endDate.plus({ day: 1 });
        }
      }

      const { cueSheetURL } = values;

      const errors = validateNewEventDetails(
        {
          availableCredits: billing.credits.total,
          events: allEvents,
          cueSheetURL: EventDetailsUtils.isCueSheetEnabled(availableLocations, values.location)
            ? cueSheetURL
            : undefined,
          newEventInfo: {
            type: eventDetailsContext.eventType,
            start: startDate?.setZone(timezone ?? account.timezone),
            end: endDate?.setZone(timezone ?? account.timezone),
            zoomDetails: {
              meetingId: values.zoomDetails?.meetingId,
              password: values.zoomDetails?.password
            },
            ..._.omit(values, [
              'startTime',
              'endTime',
              'date',
              'passwordSettings',
              'redirectUrlSettings',
              'repeatSettings'
            ])
          },
          simulatedLiveAssetIsUpload: eventDetailsContext.simulatedLiveAssetIsUpload,
          timezone: timezone ? new IANAZone(timezone) : account.timezone,
          isAdmin,
          isProd: production
        },
        billing
      );

      eventDetailsContext.setEventInfo({
        ...eventDetailsContext.eventInfo,
        start: startDate,
        end: endDate,
        ..._.omit(values, ['startTime', 'endTime', 'date']),
        isValid: !!values.title && _.isEmpty(errors)
      });

      return errors;
    },

    onSubmit(): void {
      onContinue();
    }
  };

  // Remove previous Zoom Data if switched to Static
  if (formik.initialValues.type === EventValidator.ProductionType.STATIC) {
    formik.initialValues.zoomDetails = {
      streamZoom: false,
      meetingId: '',
      password: ''
    };
  }

  return (
    <div className='mt-28px'>
      <Formik {...formik}>
        {({ values, errors, setErrors }): React.ReactElement => (
          <EventDetailsForm.Wrapper $isMobile={viewport === Viewport.XS}>
            {formLayout(values, errors, setErrors)}
          </EventDetailsForm.Wrapper>
        )}
      </Formik>
    </div>
  );
};
