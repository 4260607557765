import { Lazy } from '@livecontrol/async-utils';
import type { Any } from '@livecontrol/core-utils';
import type { Location } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { freeze, produce as produce_ } from 'immer';
import { createContext, createElement, useContext, useEffect, useRef } from 'react';
import type { PropsWithChildren, ReactElement } from 'react';
import type { EqualityChecker, SetState, StateSelector } from 'zustand';
import create from 'zustand';
import { Store as X_Store } from '../../store';
import type { Draft, Period, State, Store } from './types';
import { Sorting } from './types';
import { Utils } from './utils';

const Context = createContext<Store>(<Any>undefined);

// Construct a provider
export const Provider = ({ children }: PropsWithChildren<unknown>): ReactElement => {
  const account = X_Store.Account.useAccount();
  const { assets } = Scheduler.Asset.useAssets(account);

  const store = useRef<Store>(
    create<State>((set: SetState<State>) => {
      const produce = (fn: (draft: Draft) => void): void => {
        set(produce_(fn));
      };

      return freeze({
        model: new Lazy(),
        timezone: account.timezone,
        produce
      });
    })
  ).current;

  useEffect((): void => {
    if (assets) {
      const { produce } = store.getState();

      const events = assets
        .filter((asset) => asset.eventData !== undefined)
        .map((asset) => asset.eventData!);
      // Construct the store

      produce((x) => {
        x.model.setValue({
          assets,
          filters: {
            keyword: {
              value: undefined,

              update(value?: string): void {
                produce(({ model }) => {
                  model.value.filters.keyword.value = value;
                });
              }
            },

            period: {
              value: undefined,

              update(value?: Period): void {
                produce(({ model }) => {
                  model.value.filters.period.value = value;
                });
              }
            },

            location: {
              available: Utils.getUniqueLocations(events),
              selected: undefined,
              update(value: Location | Location[] | undefined): void {
                produce(({ model }) => {
                  model.value.filters.location.selected = value;
                });
              }
            },
            event: {
              value: undefined,
              update(value): void {
                produce(({ model }) => {
                  model.value.filters.event.value = value;
                });
              }
            },
            reset(): void {
              const { filters: criteria } = store.getState().model.value;

              criteria.location.update(undefined);
              criteria.event.update(undefined);
              criteria.keyword.update(undefined);
              criteria.period.update(undefined);
            }
          },
          sorting: {
            value: {
              key: 'timestamp',
              direction: Sorting.Direction.DESCENDING
            },

            update(value: Sorting): void {
              produce(({ model }) => {
                model.value.sorting.value = value;
              });
            }
          }
        });
      });
    }
  }, [assets, store]);

  // Pass the context down
  return createElement(
    Context.Provider,
    {
      value: store
    },
    children
  );
};

export const useReady = (): boolean => useContext(Context)(({ model }) => model.isResolved);

export function useStore(): Store;

export function useStore<U>(selector: StateSelector<State, U>, equalityFn?: EqualityChecker<U>): U;

export function useStore<U>(
  selector?: StateSelector<State, U>,
  equalityFn?: EqualityChecker<U>
): Store | U {
  const store = useContext(Context);

  return selector ? store(selector, equalityFn) : store;
}
