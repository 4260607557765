import { Num, Obj, Str } from '@livecontrol/core-utils';
import { User } from '@livecontrol/scheduler/model';
import type { Account } from '@livecontrol/scheduler/model';
import jwtDecode from 'jwt-decode';

import Role = User.Role;

export interface Token {
  account: Account.ID;
  user: User.ID;
  role: User.Role;
  zendesk_token?: Token.JWT;
  jwt: Token.JWT;
}

export namespace Token {
  export type JWT = string;

  /**
   * Decode the specified token.
   *
   * @param value - JWT to be decoded.
   * @returns Decoded token
   */
  export const decode = (value?: unknown): Token | undefined => {
    const jwt = Str.normalize(value);

    let token;

    if (jwt) {
      try {
        const obj = jwtDecode<Obj.Rec>(jwt);

        const candidate = {
          account: Num.normalize(obj.account_id),
          user: Num.normalize(obj.user_id),
          role: Role.normalize(obj.role_id),
          jwt
        };

        if (Obj.isHydrated(candidate)) {
          token = candidate;
        }
      } catch {
        // ignore
      }
    }

    return token;
  };
}
