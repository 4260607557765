import { Modal } from '@livecontrol/scheduler/components';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { ReactElement } from 'react';
import { Store } from '../../../store';

export const PermissionsModal = ({
  show = true,
  handleClose
}: {
  show: boolean;
  handleClose?: () => void;
}): ReactElement => {
  const account = Store.Account.useAccount();

  const { data: facebookOAuthURL } = Scheduler.Destination.useFacebookAuthorization({
    account,
    reauth: true
  });

  const goToFacebookIntegration = (): void => {
    if (facebookOAuthURL) {
      window.location.replace(facebookOAuthURL);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Facebook Permissions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='text-center mx-auto'>
          You need to grant permissions to connect a Facebook Page. You'll also need to grant
          permission to your timeline. Posts will only go to the page you connect.
          <br />
          Please keep in mind that Facebook requires all users and pages to have accounts 60 days
          old, 100 followers and specific permissions in order to stream. Click{' '}
          <a href='https://mylivecontrol.zendesk.com/hc/en-us/articles/24116146308119-Facebook-Streaming-Policies' rel='noopener noreferrer' target='_blank'>
            here
          </a>{' '}
          for more information.
        </p>
      </Modal.Body>
      <Modal.Footer className='modal-footer mt-3 mb-3'>
        <Modal.Button type='button' size='lg' onClick={goToFacebookIntegration}>
          <img className='img-fluid' width='30' src='assets/integration/facebook.png' alt='' />
          <span className='ml-8px'>Continue with Facebook</span>
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
};
