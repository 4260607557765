import { gql, useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import type { MutationResult } from '../../graphql';

interface TData {
  input: locationPermision[];
}

interface locationPermision {
  locationId: string;
  permissions: LocationNotifications[];
}

interface LocationNotifications {
    connection_issues: boolean;
    customer_feedback: boolean;
    event_reminder: boolean;
    event_scheduling: boolean;
    location_id: string;
    id?: number;
}

const MUTATION = gql`
mutation UpsertEmailLocationNotifications($input: [UpsertEmaiLocationNotificationsInput!]!, $userId: Float!) {
    upsertEmailLocationNotifications(input: $input, user_id: $userId) {
      connection_issues
      customer_feedback
      event_reminder
      event_scheduling
      id
    }
  }
`;

interface Args {
   userId: number;
   input: LocationNotifications[];
}

export const useUpdateForEmailLocationsNotifications = (): [
  (args: Args) => Promise<void>,
  MutationResult<TData, 'locations'>
] => {
  const [mutation, result] = useMutation<TData>(MUTATION);

  const [error, setError] = useState<Error | undefined>();

  return [
    useCallback(
      async (args: Args): Promise<void> => {
        try {
          await mutation({
            variables: args
          });

        } catch (error_: unknown) {
          setError(<Error>error_);
        }

      },
      [mutation]
    ),
    {
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
