import { Loading } from '@livecontrol/core-ui';
import { User } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { ReactElement } from 'react';
import { SectionContainer } from '../../components';
import { Store } from '../../store';
import { FormContent } from './components';

export const ProductionNotes = (): ReactElement => {
  const account = Store.Account.useAccount();

  let { locations } = Scheduler.Location.useLocations(account);

  locations = Store.User.useLocationWithPermission(
    User.LocationPermissionsNames.ProductionNotes,
    locations
  );

  const locationsProduced = locations.filter((location) => location.isProduced);

  let locationsToSend = locations;

  if (locationsProduced.length) {
    locationsToSend = locationsProduced;
  }

  return locations.length ? (
    <SectionContainer className='col-12'>
      <div className='pb-12px' style={{borderBottom: '1px solid #d5daea'}}>
        <h1 className='font-size-28px font-weight-bold text-dark'>Production Notes</h1>
        <div className='text-dark font-size-13px font-weight-light line-height-18px' style={{maxWidth: 639}}>
          Our videographers are trained to make the best production choices throughout your events, and will use their discretion when employing your production preferences. Please choose your general production preferences for each location below (Produced events only).
        </div>
        <div className='text-tertiary font-size-13px font-weight-bold font-italic mt-32px line-height-18px' style={{maxWidth: 639}}>
          *For individual event notes, please use the “videographer event notes” section on the scheduling flow (these will overrule the below preferences if applicable.)
        </div>
      </div>
      <FormContent locations={locationsToSend} />
    </SectionContainer>
  ) : (
    <Loading.Delay />
  );
};
