import cx from 'classnames';
import type { HTMLAttributes, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Store } from '../../../store';

const NoUpcomingEventsContainer = styled.div`
  margin-top: 14px;
  font-size: 11px;
  color: #9c9c9c;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;

  .image-section {
    width: 344px;
    height: 263px;
    .image {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      background-image: url(/assets/Event-img.svg);
    }
  }

  .big-text {
    font-weight: 700;
    font-size: 24px;
    color: #2e384d;
    margin-top: 30px;
    text-align: center;
  }

  .small-text {
    font-weight: 400;
    font-size: 13px;
    color: #2e384d;
    margin-top: 18px;
    text-align: center;
  }

  .link-button {
    margin-top: 8px;
    color: white;
    border-radius: 4px;
    background-color: #2e5bff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    width: 166px;
    height: 46px;
  }

  .link-button {
    background-color: #083dff;
  }
  @media (max-width: 767px) {
    .image-section {
      width: 172px;
      height: 132px;
    }

    .big-text {
      font-size: 18px;
    }
  }
`;

export const NoEvents = ({ className, ...props }: HTMLAttributes<HTMLDivElement>): ReactElement => {
  const me = Store.User.useMe();

  return (
    <NoUpcomingEventsContainer
      className={cx(
        className,
        'd-flex flex-column align-items-center text-align-center text-center'
      )}
      {...props}
    >
      <div className='image-section'>
        <div className='image' />
      </div>
      <div className='big-text font-size-24px text-dark'>Uh oh, you have no upcoming events!</div>
      {me.permissions.someLocationHasEventManagmentEnabled && (
        <>
          <div className='small-text mt-8px font-size-13px mb-16px'>Ready to start streaming?</div>
          <Link
            className='link-button btn btn-primary pt-13px pb-13px font-size-12px font-weight-bold'
            to='/schedule/create'
          >
            Add New Event Now
          </Link>
        </>
      )}
    </NoUpcomingEventsContainer>
  );
};
