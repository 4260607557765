import type { DateTime } from 'luxon';

export interface Onboarding {
  targetFirstEvent?: DateTime;
  subscriptionStartDate?: DateTime;
  progress: number;
  modules?: ModuleInfo[];
}

export interface ModuleInfo {
  id: string;
  isRequired: boolean;
  title: string;
  subtitle: string;
  description: string;
  timeInMinutes: string;
  tasks: Task[];
}

export interface Task {
  id: string;
  isRequired: boolean;
  name: string;
  description: string;
  isVisibleInProgress: boolean;
  type: Task.TaskType;
  assets: Task.Asset[];
  linkedElementsUIs?: string[];
  isCompleted: boolean;
  toolkit?: Task.Toolkit;
}

export namespace Task {
  export const enum TaskType {
    TOOLKIT = 'toolkit',
    VIDEO = 'video',
    FORM = 'form',
    URL = 'url'
  }

  export interface Toolkit {
    id: string;
    title: string;
    items: Toolkit.ToolkitItem[];
  }

  export namespace Toolkit {
    export interface ToolkitItem {
      url: string;
      name: string;
    }
  }

  export interface Asset {
    name: string;
    url: string;
    type: Asset.AssetType;
  }

  export namespace Asset {
    export const enum AssetType {
      IMAGE = 'image',
      VIDEO = 'video'
    }
  }
}
