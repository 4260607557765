import { gql } from '@apollo/client';

export const CreateLocation = gql`
  mutation CreateLocation(
    $name: String!
    $user: Float!
    $zoom: Boolean
    $mux_stream_id: String
    $mux_stream_key: String
    $mux_stream_url: String
  ) {
    createLocation(
      location: {
        name: $name
        user: $user
        zoom: $zoom
        mux_stream_id: $mux_stream_id
        mux_stream_key: $mux_stream_key
        mux_stream_url: $mux_stream_url
      }
    ) {
      id
      name
      user {
        id
        first_name
        last_name
        role {
          id
          role
        }
      }
    }
  }
`;

export const UpdateLocation = gql`
  mutation CreateLocation(
    $id: String!
    $name: String!
    $user: Float!
    $zoom: Boolean
    $mux_stream_id: String
    $mux_stream_key: String
    $mux_stream_url: String
  ) {
    updateLocation(
      location: {
        id: $id
        name: $name
        user: $user
        zoom: $zoom
        mux_stream_id: $mux_stream_id
        mux_stream_key: $mux_stream_key
        mux_stream_url: $mux_stream_url
      }
    ) {
      id
      name
    }
  }
`;
export const DeleteLocation = gql`
  mutation DeleteLocation($id: String!) {
    deleteLocation(location: $id)
  }
`;

export const ShowLocation = gql`
  query GetLocation($locationId: String!) {
    getLocation(location: { id: $locationId }) {
      id
      name
      zoom
      mux_stream_id
      mux_stream_key
      mux_stream_url
      user {
        id
        first_name
      }
      events {
        id
        name
      }
    }
  }
`;
