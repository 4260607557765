import { Nix } from '@livecontrol/core-utils';

export namespace Storage {
  export interface Options {
    persistent?: boolean;
  }

  export const getItem = (key: string): string | undefined => {
    const v = sessionStorage.getItem(key) ?? localStorage.getItem(key);

    return Nix.isNotNil(v) ? v : undefined;
  };

  export const removeItem = (key: string): void => {
    sessionStorage.removeItem(key);
    localStorage.removeItem(key);
  };

  export const setItem = (
    key: string,
    value: string,
    options: Options = { persistent: true }
  ): void => {
    const storage = options.persistent ? localStorage : sessionStorage;

    storage.setItem(key, value);
  };
}
