import type { Asset, Event } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { FormikConfig } from 'formik';
import { useRef } from 'react';
import * as Yup from 'yup';
import { Alert, Store } from '../../../../store';

export interface Props {
  asset: Asset;
  event?: Event;
  onClose: () => void;
}

interface Values {
  title: string;
  description?: string;
  backgroundURL?: string;
}

interface Form {
  formik: FormikConfig<Values>;
  loading: boolean;
  alert: Alert;
}

const validationSchema = Yup.object({
  title: Yup.string().trim().required('Please specify a title for this event.'),
  description: Yup.string().trim(),
  backgroundURL: Yup.string().url().not(['loading'])
});

export const useForm = ({ asset, event, onClose }: Props): Form => {
  const account = Store.Account.useAccount();

  const [mutation, { loading, error }] = Scheduler.Asset.useEdit();
  const [editEvent] = Scheduler.Event.useEdit();

  const alert = Alert.useAlert(error);

  const initialValues = useRef({
    title: asset.title,
    description: asset.description
  }).current;

  const formik = {
    initialValues,
    validationSchema,
    validateOnMount: true,

    validate(): void {
      alert.hide();
    },

    async onSubmit({ title, description, backgroundURL }: Values): Promise<void> {
      alert.hide();

      // If not loading, execute the mutation

      await Promise.all([
        mutation({
          asset,
          account,
          changes: {
            ...asset,
            title,
            description
          }
        }),
        event && backgroundURL !== event.backgroundURL
          ? editEvent({
              account,
              event,
              replacement: {
                ...event,
                backgroundURL
              }
            })
          : undefined
      ]).then(() => {
        onClose();
      });
    }
  };

  return {
    formik,
    loading,
    alert
  };
};
