import type { ApolloQueryResult } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Obj } from '@livecontrol/core-utils';
import { Account, Event } from '@livecontrol/scheduler/model';
import { useCallback, useEffect, useState } from 'react';
import * as AssetStore from '../asset';
import * as DestinationStore from '../destination';
import type { QueryResult } from '../graphql';
import * as E_Dbx from './dbx';

interface TVariables {
  clientAsInt: number;
  clientAsStr: string;
  eventAsStr: string;
  eventAsFloat: number;
}

interface TData {
  events?: E_Dbx.Record[];
  listAssets?: AssetStore.Dbx.Record[];
  integrations?: DestinationStore.Dbx.Record[];
}

export const QUERY = gql`
  query GetEvent(
    $eventAsStr: String!
    $eventAsFloat: Float!
    $clientAsInt: Int!
    $clientAsStr: String!
  ) {
    events(event: { id: $eventAsStr }) {
      ...StandardEventResponse
    }

    listAssets(scheduledEventId: $eventAsFloat, clientId: $clientAsInt) {
      ...FullAssetResponse
    }

    integrations(clientId: $clientAsStr) {
      ...StandardDestinationResponse
    }
  }
  ${E_Dbx.StandardEventResponse}
  ${AssetStore.Dbx.FullAssetResponse}
  ${DestinationStore.Dbx.StandardDestinationResponse}
`;

interface Args {
  event: Event.Like;
  account: Account.Like;
  lazy?: boolean;
}

interface Refetch {
  refetch?: (event: Event.Like) => Promise<ApolloQueryResult<TData>>;
}

export const useEvent = (args: Args): QueryResult<Event, 'event'> & Refetch => {
  const [error, setError] = useState<Error | undefined>();
  const [event, setEvent] = useState<Event | undefined>();

  const eventId = Event.toId(args.event);
  const accountId = Account.toId(args.account);

  // Parse the input arguments
  const variables = {
    eventAsStr: eventId?.toString(), // @fixme - API type mismatch
    eventAsFloat: eventId,
    clientAsInt: accountId,
    clientAsStr: accountId?.toString()
  };

  // Validate the input
  if (!Obj.isHydrated(variables)) {
    return { loading: false, error: Exception.KABOOM };
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  // Prepare the query
  const { loading, data, refetch } = useQuery<TData, TVariables>(QUERY, {
    skip: args.lazy,
    variables,
    onError() {
      setError(Exception.KABOOM);
    }
  });

  const refetchQuery = useCallback(
    async (e: Event.Like) => {
      const id = Event.toId(e);

      return refetch({
        eventAsStr: id?.toString(), // @fixme - API type mismatch
        eventAsFloat: id,
        clientAsInt: accountId,
        clientAsStr: accountId?.toString()
      });
    },
    [accountId, refetch]
  );

  // When available, parse server response
  useEffect(() => {
    let event_;
    let error_;

    if (data) {
      // Normalize the (first) event
      const record = data.events?.length ? data.events[0] : undefined;

      event_ = record ? E_Dbx.normalize(record) : undefined;

      if (record && event_) {
        // Augment the event with assets/destinations
        event_.assets = AssetStore.Dbx.toEventMap(data.listAssets).get(event_.id);

        event_.destinations = E_Dbx.correlate(
          record,
          DestinationStore.Dbx.toMap(data.integrations)
        );

      } else {
        error_ = Exception.NOT_FOUND;
      }

    }

    setEvent(event_);
    setError(error_);
  }, [data]);

  return { error, loading, event, refetch: refetchQuery };
};
