import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: rgba(0, 0, 0, var(--modal-backdrop-opacity));
  height: 100%;
  position: fixed;
  width: 100%;
`;

const Button = styled.button`
  color: var(--white);
  opacity: 0.75;
  padding: var(--spacer-8px) !important;
  position: absolute;
  right: 1rem;
  top: 1rem;
  transition: var(--transition-fade);

  &:hover {
    opacity: 1;
  }
`;

export const Underlay = ({ onClose }: { onClose: () => void }): React.ReactElement => (
  <Container onClick={onClose}>
    <Button type='button' className='x-pseudo' onClick={onClose}>
      <i className='fal fa-times fa-2x' />
    </Button>
  </Container>
);
