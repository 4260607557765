import cx from 'classnames';
import type { HTMLAttributes, ReactElement } from 'react';
import styled from 'styled-components';

const SupportTypesPanel = styled.div`
  margin-top: var(--spacer-4px);
`;

const SupportTypeSection = styled.div`
  padding: 36px 10px 0px;
  display: flex;
  flex-direction: row;

  a {
    color: var(--dark) !important;
  }

  .title {
    font-weight: 600;
    color: var(--dark);
    font-size: var(--font-size-20px);
    margin-bottom: 9px;
  }

  .subtitle {
    font-weight: 600;
    color: var(--gray-light);
    font-size: var(--font-size-13px);
    margin-bottom: 11px;
  }

  .description-section {
    font-weight: 600;
    color: var(--dark);
    font-size: var(--font-size-11px);
    margin-bottom: 24px;

    ul {
      list-style-type: disc;
      -webkit-padding-start: 20px;
      padding-inline-start: 20px;

      li {
        margin-bottom: 4px;
      }
    }
  }

  .info-entry {
    display: flex;
    margin-bottom: 20px;

    .icon-section {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border: 1px solid #d5daea;
      border-radius: var(--border-radius-sm);
      margin-right: 16px;

      .icon {
        width: 20px;
        height: 20px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }

      .clock {
        background-image: url(/assets/icons/Clock.svg);
      }

      .email {
        background-image: url(/assets/icons/Email.svg);
      }

      .phone {
        background-image: url(/assets/icons/Phone.svg);
      }
    }

    .title {
      font-weight: 600;
      color: var(--gray-light);
      font-size: var(--font-size-13px);
      margin-bottom: 2px;
    }

    .text-entry {
      display: flex;
      font-weight: 600;
      color: var(--dark);
      font-size: var(--font-size-12px);
      flex-wrap: wrap;

      .limited-text {
        max-width: 169px;
      }

      .small-text {
        display: flex;
        font-weight: 600;
        color: #9c9c9c;
        font-size: 9px;
        margin-top: 2px;
        margin-left: 8px;
      }
    }
  }
`;

export const SupportTypes = ({
  className
}: React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>): ReactElement => (
  <SupportTypesPanel
    className={cx('d-flex justify-content-space-between align-items-center', className)}
  >
    <div className='w-100'>
      <div className='row'>
        <SupportTypeSection className='d-flex flex-column col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4'>
          <div className='title'>Account Support</div>
          <div className='subtitle'>Non-urgent, account-related matters</div>

          <div className='description-section'>
            <ul>
              <li>
                <div className='descriptionEntry'>Change my plan</div>
              </li>
              <li>
                <div className='descriptionEntry'>Help with billing</div>
              </li>
              <li>
                <div className='descriptionEntry'>Scheduling issues</div>
              </li>
            </ul>
          </div>

          <div className='info-entry'>
            <div className='icon-section'>
              <div className='icon clock' />
            </div>

            <div className='textSection'>
              <div className='title'>Working hours</div>
              <div className='text-entry'>M-F, 9a-5p PT</div>
            </div>
          </div>

          <div className='info-entry'>
            <div className='icon-section'>
              <div className='icon phone' />
            </div>

            <div className='textSection'>
              <div className='title'>Call</div>
              <div className='text-entry'>
                <a href='tel:tel:310-361-4169'>310-361-4169</a>
                <div className='small-text'>Press 2 - Account Support</div>
              </div>
            </div>
          </div>

          <div className='info-entry'>
            <div className='icon-section'>
              <div className='icon email' />
            </div>

            <div className='textSection'>
              <div className='title'>Email</div>
              <div className='text-entry'>
                <a href='mailto:customersuccess@livecontrol.io'>customersuccess@livecontrol.io</a>
              </div>
            </div>
          </div>
        </SupportTypeSection>

        <SupportTypeSection className='d-flex flex-column col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4'>
          <div className='title'>Live Event Support</div>
          <div className='subtitle'>For urgent live event support</div>

          <div className='description-section'>
            <ul>
              <li>
                <div className='descriptionEntry'>Our live event has issues</div>
              </li>
              <li>
                <div className='descriptionEntry'>There is no audio on the live stream</div>
              </li>
              <li>
                <div className='descriptionEntry'>Immediate help required</div>
              </li>
            </ul>
          </div>

          <div className='info-entry'>
            <div className='icon-section'>
              <div className='icon clock' />
            </div>

            <div className='textSection'>
              <div className='title'>Working hours</div>
              <div className='text-entry'>
                <div className='limited-text'>Available during all scheduled Produced Events</div>
              </div>
            </div>
          </div>

          <div className='info-entry'>
            <div className='icon-section'>
              <div className='icon phone' />
            </div>

            <div className='textSection'>
              <div className='title'>Call</div>
              <div className='text-entry'>
                <a href='tel:310-361-4169'>310-361-4169</a>
                <div className='small-text'>Press 3 - Urgent Event-Related Support</div>
              </div>
            </div>
          </div>

          <div className='info-entry'>
            <div className='icon-section'>
              <div className='icon email' />
            </div>

            <div className='textSection'>
              <div className='title'>Email</div>
              <div className='text-entry'>
                <a href='mailto:support@livecontrol.io'>support@livecontrol.io</a>
              </div>
            </div>
          </div>
        </SupportTypeSection>

        <SupportTypeSection className='d-flex flex-column col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4'>
          <div className='title'>Technical Support</div>
          <div className='subtitle'>Non-urgent, technical matters</div>

          <div className='description-section'>
            <ul>
              <li>
                <div className='descriptionEntry'>Need to adjust lighting for upcoming event</div>
              </li>
              <li>
                <div className='descriptionEntry'>We want to test our new sound board</div>
              </li>
              <li>
                <div className='descriptionEntry'>Need to set up new graphics computer</div>
              </li>
            </ul>
          </div>

          <div className='info-entry'>
            <div className='icon-section'>
              <div className='icon clock' />
            </div>

            <div className='textSection'>
              <div className='title'>Working hours</div>
              <div className='text-entry'>M-F, 6a-5p PT</div>
            </div>
          </div>

          <div className='info-entry'>
            <div className='icon-section'>
              <div className='icon phone' />
            </div>

            <div className='textSection'>
              <div className='title'>Call</div>
              <div className='text-entry'>
                <a href='tel:310-361-4169'>310-361-4169</a>
                <div className='small-text'>Press 4 - Non-Urgent Technical Support</div>
              </div>
            </div>
          </div>

          <div className='info-entry'>
            <div className='icon-section'>
              <div className='icon email' />
            </div>

            <div className='textSection'>
              <div className='title'>Email</div>
              <div className='text-entry'>
                <a href='mailto:support@livecontrol.io'>support@livecontrol.io</a>
              </div>
            </div>
          </div>
        </SupportTypeSection>
      </div>
    </div>
  </SupportTypesPanel>
);
