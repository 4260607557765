import { Viewport } from '@livecontrol/core-ui';
import cx from 'classnames';
import { Component } from 'react';
import type { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

const StyledHeader = styled.div<{ viewport: Viewport }>`
  --cell-padding-x: var(--spacer-${({ viewport }): number => (viewport < Viewport.XL ? 8 : 16)}px);

  --matrix-padding-x: var(
    --spacer-${({ viewport }): number => (viewport < Viewport.XL ? 16 : 24)}px
  );

  border: 0;
  align-self: center;
  padding: 0 var(--cell-padding-x);
  background-color: transparent;
  text-transform: capitalize;
  font-weight: normal;

  &:first-child {
    border-left-width: 1px;
    padding-left: var(--matrix-padding-x);
  }

  .x-dark-theme & {
    color: var(--white60);
  }
`;

// eslint-disable-next-line react/prefer-stateless-function
export class Header extends Component<Header.Props> {
  public override render(): ReactNode {
    const { className, children, viewport, ...props } = this.props;

    return (
      <StyledHeader viewport={viewport} className={cx('th', className)} {...props}>
        {children}
      </StyledHeader>
    );
  }
}

export namespace Header {
  export type Props = PropsWithChildren<HTMLAttributes<HTMLDivElement>> & { viewport: Viewport };
}
