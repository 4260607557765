import { Filters } from '@livecontrol/scheduler/components';
import type { ReactElement } from 'react';
import { Store } from '../../../store';
import { useStore } from '../store';

import L = Filters.Location;

export const Location = ({ ...props }: Omit<L.Props, 'value'>): ReactElement | null => {
  const locationsData = useStore(({ model }) => model.value.filters.location);

  const locationsWithpermission = locationsData.available.filter((location) =>
    Store.User.hasContentPemissionForLocation(location.id)
  );

  const locationsDataCopy = { ...locationsData };

  locationsDataCopy.available = locationsWithpermission;

  return <L value={locationsDataCopy} {...props} onChange={locationsData.update} />;
};
