import { Glyph } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import { Scheduler } from '@livecontrol/scheduler/store';
import React, { useCallback } from 'react';
import { Store } from '../../../../store';

export const DeleteModal = ({
  show = false,
  name,
  onClose,
  onDelete
}: {
  show: boolean;
  name: 'background' | 'intro' | 'logo' | 'outro';
  onClose?: () => void;
  onDelete?: () => void;
}): React.ReactElement => {
  const account = Store.Account.useAccount();
  const [editWebPlayer, { loading }] = Scheduler.Account.WebPlayer.useEdit();

  const onRemove = useCallback(() => {
    void editWebPlayer(account, {
      [name]: ''
    }).finally(onDelete);
  }, [account, editWebPlayer, name, onDelete]);

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header className='text-center' closeButton>
        <Glyph>
          <i className='far fa-image' />
        </Glyph>
      </Modal.Header>
      <Modal.Body className='text-center'>
        <p className='font-size-24px font-weight-bold'>
          Are you sure you want to remove your player {name} image?
        </p>
        <p>Removing this image will permanantly remove it.</p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonBox>
          <Modal.Submit loading={loading} onClick={onRemove} variant='primary'>
            Remove Image
          </Modal.Submit>
          <Modal.Button variant='outline-secondary' onClick={onClose}>
            Cancel
          </Modal.Button>
        </Modal.ButtonBox>
      </Modal.Footer>
    </Modal>
  );
};
