import { Obj, Str } from '@livecontrol/core-utils';
import { Account } from '@livecontrol/scheduler/model';
import type { Record } from './types';

import S3 = Account.S3;

export const normalize = (record?: Record): S3 | undefined => {
  let signedS3;

  if (record) {
    const canditate: S3 = {
      url: Str.normalize(record.url),
      bucket: Str.normalize(record.bucket),
      key: Str.normalize(record.key)
    };

    if (Obj.isHydrated(canditate)) {
      signedS3 = canditate;
    }
  }

  return signedS3;
};
