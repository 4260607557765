import nextTick from 'next-tick';
import React, { useCallback, useState } from 'react';
import type { PropsWithChildren, ReactElement } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';
import { Exception } from './exception';
import { Kaboom as DefaultKaboom } from './kaboom';
import { NotFound as DefaultNotFound } from './not-found';
import type { PageProps } from './page';

interface FallbackOptionalProps {
  notFound?: (props: Pick<PageProps, 'className' | 'reset'>) => React.ReactElement;
  kaboom?: (props: Pick<PageProps, 'className' | 'reset'>) => React.ReactElement;
}

const Fallback = ({
  error,
  notFound = DefaultNotFound,
  kaboom = DefaultKaboom,
  resetErrorBoundary
}: FallbackOptionalProps & FallbackProps): ReactElement | null => {
  const location = useLocation();

  // Keep track of the path we mounted on
  const [mountPath] = useState(location.pathname);

  // If the path has changed, reset the error boundary
  if (mountPath !== location.pathname) {
    nextTick(() => {
      resetErrorBoundary();
    });
  }

  // Render the appropriate error page
  const E = error === Exception.NOT_FOUND ? notFound : kaboom;

  return <E className='h-100 justify-content-center' reset={resetErrorBoundary} />;
};

export const Boundary = ({
  children,
  notFound,
  kaboom
}: PropsWithChildren<FallbackOptionalProps>): React.ReactElement => {
  const fallback = useCallback(
    (props: FallbackProps): React.ReactElement => (
      <Fallback {...props} notFound={notFound} kaboom={kaboom} />
    ),
    [notFound, kaboom]
  );

  return <ErrorBoundary FallbackComponent={fallback}>{children}</ErrorBoundary>;
};
