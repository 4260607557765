import { Loading } from '@livecontrol/core-ui';
import { Nix } from '@livecontrol/core-utils';
import { Modal } from '@livecontrol/scheduler/components';
import type { Asset } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { UpChunk } from '@mux/upchunk';
import { Duration } from 'luxon';
import pRetry from 'p-retry';
import pTimeout from 'p-timeout';
import { useCallback, useContext, useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Form } from '../../../../components';
import { Store } from '../../../../store';
import type { EventContext } from '../../event-store';
import { EventContextStore } from '../../event-store';
import { FileDrop } from './file-drop';

const UploadContainer = styled.div`
  max-width: 30rem;
  margin: 0 auto;

  .simulated-live-upload {
    background: var(--tint-ø2);
    border-radius: var(--border-radius-sm);
    border: 1px solid var(--tint-ø1);
    display: inline;
  }
`;

interface Alert {
  message: string;
  variant?: string;
  clear: () => void;
}

export const UploadVideo = ({ onContinue, onBack }: UploadVideo.Props): ReactElement => {
  const account = Store.Account.useAccount();
  const { store }: EventContext = useContext<EventContext>(EventContextStore);
  const currentLocation = useLocation();

  const [
    createUpload,
    { progress, error: createUploadError, loading: loadingUpload, uploading, onAbort }
  ] = Scheduler.Asset.useMuxUpload();

  const [getAsset, { error: getAssetError, loading: loadingAsset }] = Scheduler.Asset.useMuxAsset();

  const [fileName, setFileName] = useState<string | undefined>(
    store.simulatedLiveFileUpload?.fileName
  );

  const [uploadDetails, setUploadDetails] = useState<
    (Asset.MuxUpload & { activeUpload?: UpChunk; videoDuration: Duration }) | undefined
  >();

  const [alert, setAlert] = useState<Alert | undefined>();
  const [assetDetails, setAssetDetails] = useState<Asset.MuxUploadedAsset | undefined>();
  const [finishedUploading, setFinishedUploading] = useState(store.simulatedLiveAssetIsUpload);
  const [waitingForAsset, setWaitingForAsset] = useState(false);
  const [waitingForVideoMetadata, setWaitingForVideoMetadata] = useState(false);

  const prompt = Modal.usePrompt();

  const clear = (): void => {
    setAlert(undefined);
  };

  useEffect((): void => {
    if (createUploadError) {
      setAlert({
        message: createUploadError.message,
        clear
      });
    } else if (getAssetError) {
      setAlert({
        message: getAssetError.message,
        clear
      });
    }
  }, [createUploadError, getAssetError]);

  const onFileChange = useCallback(
    async (files: File[]) => {
      if (files.length) {
        alert?.clear();
        setWaitingForVideoMetadata(true);
        setFileName(files[0].name);

        const videoDuration: Duration = await pTimeout(
          new Promise((resolve) => {
            const video = document.createElement('video');

            video.addEventListener('loadedmetadata', () => {
              URL.revokeObjectURL(video.src);
              resolve(Duration.fromObject({ seconds: video.duration }));
            });

            video.src = URL.createObjectURL(files[0]);
          }),
          1000,
          () => {
            setAlert({
              message:
                'Successfully uploaded. However, you uploaded a file type in which we cannot read the length of the video. We changed the default length of this video to 3 hours. To avoid this, please upload the file in an .mp4, WebM, or OGG format.',
              variant: 'primary',
              clear
            });

            return Duration.fromObject({ hours: 3 });
          }
        );

        const muxUpload = await createUpload({
          account: account.id,
          file: files[0]
        });

        setWaitingForVideoMetadata(false);

        if (muxUpload) {
          setUploadDetails({
            activeUpload: muxUpload.activeUpload,
            videoDuration,
            ...muxUpload.uploadDetails
          });
        }
      }
    },
    [alert, createUpload, account.id]
  );

  const onSuccess = useCallback(async () => {
    if (uploadDetails?.uploadId) {
      try {
        const asset = await pRetry(
          async (): Promise<
            | {
                assetDetails?: Asset.MuxUploadedAsset | undefined;
              }
            | undefined
          > => {
            const candidate = await getAsset({
              id: account.id,
              uploadId: uploadDetails.uploadId!,
              title: fileName!,
              description: 'Simulated Live File Upload'
            });

            if (candidate) {
              return candidate;
            }

            throw new Error('Could not retrieve asset url');
          },
          {
            retries: 10,
            maxTimeout: 3000
          }
        );

        setWaitingForAsset(false);

        if (asset?.assetDetails) {
          setAssetDetails(asset.assetDetails);
          store.setSimulatedLiveAssetDetails(asset.assetDetails);
          store.setSimulatedLiveFileUpload({ ...uploadDetails, fileName: fileName! });
          setFinishedUploading(true);
        }
      } catch {
        setAlert({
          message: 'Oops! Something went wrong while processing the upload. Please try again.',
          clear
        });

        setWaitingForAsset(false);
      }
    }
  }, [fileName, getAsset, account.id, store, uploadDetails]);

  const onCancel = useCallback(() => {
    onAbort();
    setFinishedUploading(false);
    setFileName(undefined);
  }, [onAbort]);

  useEffect(() => {
    // subscribe to events
    if (uploadDetails?.activeUpload) {
      uploadDetails.activeUpload.on('success', onSuccess);
    }
  }, [onSuccess, uploadDetails?.activeUpload]);

  useEffect(() => {
    if (createUploadError || getAssetError) {
      prompt.error('Oops! An error occurred while uploading your video. Please try again.');
    }
  }, [createUploadError, getAssetError, prompt]);

  if (loadingUpload || loadingAsset || waitingForAsset || waitingForVideoMetadata) {
    return <Loading />;
  }

  return (
    <>
      {!uploading && (
        <>
          <Form.Alert show={!!alert} onClose={alert?.clear} variant={alert?.variant}>
            {alert?.message}
          </Form.Alert>
          <p className='text-muted font-weight-bold text-center pt-12px mb-0'>
            Please give us 2 hours to process your video before you can schedule your event.
          </p>
          <p className='text-muted font-weight-bold text-center'>
            For best results, .mp4 files are recommended
          </p>
        </>
      )}
      <UploadContainer className='p-32px text-center'>
        {uploading ? (
          <>
            <div className='text-primary'>
              <i className='fad fa-spin fa-spinner-third fa-2x' />
            </div>
            <p className='font-size-28px text-dark m-0'>{progress.toFixed(0)}%</p>
            <p className='text-muted'>Uploading File...</p>
            <ProgressBar animated now={progress} />
            <p className='text-muted mt-16px'>{fileName}</p>
            <Button variant='outline-secondary' onClick={onCancel}>
              Cancel Upload
            </Button>
          </>
        ) : (
          <>
            <FileDrop
              className='simulated-live-upload text-muted px-24px py-12px'
              onDrop={onFileChange}
            >
              <i className='fas fa-image fa-lg mr-12px' />
              {Nix.isNil(fileName) ? 'ADD YOUR FILE' : 'CHOOSE A DIFFERENT FILE'}
            </FileDrop>
            <p className='text-muted mt-32px'>{fileName}</p>
            <div className='mt-64px d-flex flex-column align-items-center'>
              <Button
                className='btn-xwide'
                disabled={!finishedUploading && !store.simulatedLiveAssetDetails}
                onClick={(): void => {
                  onContinue(assetDetails ?? store.simulatedLiveAssetDetails!, true);
                }}
              >
                Continue
              </Button>
              <Link
                className='mt-32px text-tertiary'
                to={currentLocation.pathname}
                onClick={onBack}
              >
                Go Back
              </Link>
            </div>
          </>
        )}
      </UploadContainer>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace UploadVideo {
  export interface Props {
    onContinue: (assetDetails: Asset.MuxUploadedAsset, isUpload: boolean) => void;
    onBack: () => void;
  }
}
