import { Str } from '@livecontrol/core-utils';
import { assert } from '@sindresorhus/is';
import _ from 'lodash';
import type { DateTime, Zone } from 'luxon';
import { Interval } from 'luxon';
import { validateEventDates } from './dates-validator';
import { EventValidator } from './types';

interface Props {
  newEventInfo: EventValidator.EventInfo;
  existingEvent?: {
    id: number;
    start: DateTime;
    end: DateTime;
  };
  simulatedLiveAssetIsUpload: boolean;
  events: EventValidator.ScheduledEvent[];
  timezone: Zone;
  recurrences?: Interval[];
  leadTime?: number;
  isAdmin?: boolean;
  isProd?: boolean;
}

export const validateEventConflicts = ({
  newEventInfo,
  existingEvent,
  simulatedLiveAssetIsUpload,
  events,
  timezone,
  recurrences,
  leadTime,
  isAdmin,
  isProd
}: Props): EventValidator.Errors => {
  const errors: EventValidator.Errors = {
    ...validateEventDates({
      newEventInfo,
      simulatedLiveAssetIsUpload,
      existingEvent,
      leadTime,
      isAdmin,
      isProd
    })
  };

  const location = Str.normalize(newEventInfo.location);

  if (_.isEmpty(errors)) {
    const start = newEventInfo.start?.setZone(timezone);
    const end = newEventInfo.end?.setZone(timezone);

    // These values are validated above
    assert.object(start);
    assert.object(end);

    let conflictingEvent = events.find(
      (event) =>
        event.id !== existingEvent?.id &&
        event.location.id === location &&
        Interval.fromDateTimes(start, end).overlaps(Interval.fromDateTimes(event.start, event.end))
    );

    if (conflictingEvent) {
      errors.date = EventValidator.ErrorMessages.SCHEDULED_EVENT_CONFLICT;
    } else {
      recurrences?.forEach((recurrence) => {
        conflictingEvent = events.find(
          (event) =>
            event.location.id === location &&
            recurrence.overlaps(Interval.fromDateTimes(event.start, event.end))
        );

        if (conflictingEvent) {
          errors.date = EventValidator.ErrorMessages.SCHEDULED_EVENT_CONFLICT;
        }
      });
    }

    if (!conflictingEvent) {
      let conflictingEndTime = events.find(
        (event) =>
          event.id !== existingEvent?.id &&
          event.location.id === location &&
          Interval.fromDateTimes(start, end.plus({ minutes: 10 })).overlaps(
            Interval.fromDateTimes(event.start.setZone(timezone), event.end.setZone(timezone))
          )
      );

      if (conflictingEndTime) {
        errors.endTime = EventValidator.ErrorMessages.END_TIME_CONFLICT;
      } else {
        recurrences?.forEach((recurrence) => {
          conflictingEndTime = events.find(
            (event) =>
              event.location.id === location &&
              recurrence.overlaps(
                Interval.fromDateTimes(
                  event.start.setZone(timezone).minus({ minutes: 10 }),
                  event.end.setZone(timezone)
                )
              )
          );

          if (conflictingEndTime) {
            _.merge(errors, {
              repeatSettings: {
                repeatFrequency: {
                  unit: EventValidator.ErrorMessages.REPEATING_EVENT_CONFLICT
                }
              }
            });
          }
        });
      }

      let conflictingStartTime = events.find(
        (event) =>
          event.id !== existingEvent?.id &&
          event.location.id === location &&
          Interval.fromDateTimes(start.minus({ minutes: 10 }), end).overlaps(
            Interval.fromDateTimes(event.start.setZone(timezone), event.end.setZone(timezone))
          )
      );

      if (conflictingStartTime) {
        errors.startTime = EventValidator.ErrorMessages.START_TIME_CONFLICT;
      } else {
        recurrences?.forEach((recurrence) => {
          conflictingStartTime = events.find(
            (event) =>
              event.location.id === location &&
              recurrence.overlaps(
                Interval.fromDateTimes(
                  event.start.setZone(timezone),
                  event.end.setZone(timezone).plus({ minutes: 10 })
                )
              )
          );

          if (conflictingStartTime) {
            _.merge(errors, {
              repeatSettings: {
                repeatFrequency: {
                  unit: EventValidator.ErrorMessages.REPEATING_EVENT_CONFLICT
                }
              }
            });
          }
        });
      }
    }
  }

  return errors;
};
