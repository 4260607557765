import { Button } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import type { Account } from '@livecontrol/scheduler/model';
import cx from 'classnames';
import pluralize from 'pluralize';
import React from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import { Store } from '../../store';
import { CreditsModal } from './credits-modal';

interface Props {
  className?: HTMLAttributes<HTMLDivElement>;
  billing: Account.Billing;
}

export const Credits = ({ className, billing }: Props): ReactElement => {
  const [showModal, setShowModal] = React.useState(false);
  const me = Store.User.useMe();

  const prompt = Modal.usePrompt();

  return (
    <div className={cx('d-flex', 'align-items-center', className)}>
      <div className='badge bg-primary text-white font-size-12px mr-8px cursor-default' style={{padding: 11}}>
        {billing.credits.total} {pluralize('Credit', billing.credits.total)}
      </div>

      {me.permissions.billing && (
        <>
          <Button
            className='btn-round'
            variant='outline-primary'
            onClick={(): void => {
              if (!billing.credits.price) {
                prompt.message({
                  title: 'Credit Purchasing Not Enabled',
                  icon: 'far fa-coins',
                  content: (
                    <p className='text-center'>
                      Please contact your dedicated Account Manager. Your account needs to be
                      enabled for purchasing one-time credits.
                    </p>
                  )
                });
              } else {
                setShowModal(true);
              }
            }}
          >
            <i className='fas fa-plus font-size-12px' />
          </Button>

          {showModal && (
            <CreditsModal
              billing={billing}
              onClose={(): void => {
                setShowModal(false);
              }}
            />
          )}
        </>
      )}
    </div>
  );
};
