import cx from 'classnames';
import _ from 'lodash';
import type { ReactElement } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';
import { Collapse } from '../../collapse';
import { Subitem } from './subitem';

interface Props {
  active: boolean;
  onClick?: () => void;
  icon: JSX.Element | string;
  label: string;
  subitems?: SubitemProps[];
  accent?: boolean;
}

interface SubitemProps {
  key: string;
  icon: JSX.Element | string;
  label: string;
  target: string | (() => void);
}

const Container = styled.button`
  align-items: center;
  display: flex;
  height: var(--spacer-48px);
  padding: var(--spacer-8px) 14.2px !important;
  margin: 6.5px;
  width: calc(100% - 14px);
  color: var(--tertiary);
  justify-content: start;

  nav:hover & {
    width: 93%;

    label {
      display: flex !important;
      justify-content: flex-start !important;
    }
  }

  .x-light-theme & {
    color: var(--tertiary) !important;
    color: rgba(255, 255, 255, 0.7) !important;
    font-weight: bold;

    &.x-active,
    &:hover {
      color: var(--white) !important;
      font-weight: bold;
    }

    &.x-active {
      background-color: var(--primary) !important;
      border-radius: var(--spacer-4px);
      color: white;
      cursor: pointer;
    }
  }

  .x-dark-theme & {
    color: var(--white60) !important;
    border-left: solid transparent;

    &.x-active,
    &:hover {
      color: var(--white) !important;
      font-weight: bold;
    }

    &.x-active {
      background-color: var(--tint-ø2);
      border-left: solid var(--coral) !important;
      cursor: default;
    }
  }

  label {
    transition: var(--transition-fade);
    cursor: inherit;
  }

  span {
    font-size: 10px;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  &.accent {
    background-color: var(--accent);
    border-radius: var(--spacer-4px);
    height: var(--spacer-48px);
    color: white !important;
    cursor: pointer;
  }

  &.accent span {
    margin-left: 20px;
    font-size: 9px;
    font-weight: 600;
  }

  .fa-chevron-down {
    width: 14px !important;
    height: 14px !important;
  }

  .x-expanded & {
    justify-content: initial;
    padding-left: 12px;
  }
`;

export const Item = ({ subitems, active, icon, onClick, label, accent }: Props): ReactElement => {
  const [isCollapsed, setIsCollapsed] = useState(active);

  const { pathname } = useLocation();

  const isIconString = typeof icon === 'string';

  return (
    <>
      <Container
        className={cx('x-pseudo', active && 'x-active', accent && 'accent')}
        type='button'
        data-toggle='collapse'
        aria-expanded={isCollapsed}
        aria-controls='collapseSubitem'
        onClick={(): void => {
          if (subitems) {
            setIsCollapsed(!isCollapsed);
          } else {
            onClick!();
          }
        }}
      >
        {isIconString ? <i className={cx('fal', `fa-${icon}`, 'fa-fw')} /> : icon}
        <label className='ml-8px font-size-13px text-nowrap mr-8px'>{label}</label>
        {accent && <span>PENDING</span>}
        {subitems && (
          <div
            style={{
              transform: isCollapsed ? 'rotate(180deg)' : ''
            }}
          >
            <i className={cx('fal', 'fa-chevron-down', 'fa-fw')} />
          </div>
        )}
      </Container>

      {subitems && (
        <Collapse in={isCollapsed}>
          <div>
            {subitems.map((subitem: SubitemProps) => {
              const activeSubItem =
                typeof subitem.target === 'string' && pathname.includes(subitem.target);

              const { key, ...passthru } = subitem;

              return <Subitem key={key} active={activeSubItem} {...passthru} />;
            })}
          </div>
        </Collapse>
      )}
    </>
  );
};
