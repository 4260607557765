import { ClickableCard, Glyph } from '@livecontrol/core-ui';
import { Icons } from '@livecontrol/scheduler/components';
import { Destination } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { EventValidator } from '@livecontrol/scheduler/validator';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { EventContextStore } from '../event-store';
import type { EventContext } from '../event-store';

const StyledFormLayout = styled.div`
  --min-width: 18rem;
  --num-columns: 3;
  --gap: 1rem;
  > div {
    margin: 0 auto;
    max-width: calc(
      (var(--num-columns) * var(--min-width) + (var(--gap) * (var(--num-columns) - 1)))
    );
  }
`;

const StyledBoxSelect = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(var(--min-width), 1fr));
  width: 100%;

  .destination-label {
    max-width: calc(100% - 6.5rem);
  }
`;

export const DestinationForm = ({
  onChange,
  destinations,
  disabled = false
}: {
  destinations: Destination[];
  onChange: (destination: Destination) => void;
  disabled?: boolean;
}): React.ReactElement => {
  const { store }: EventContext = useContext<EventContext>(EventContextStore);

  const destinationOptions = useMemo(
    (): Destination[] => [Scheduler.Destination.LC_WEBPLAYER, ...destinations],
    [destinations]
  );

  return (
    <>
      <p className='mt-12px text-muted text-center'>Step 3: Choose Your Destinations</p>
      <StyledFormLayout className='mt-16px'>
        <div>
          <h2 className='font-size-24px'>Live Stream</h2>
          <p className='text-muted'>
            Choose where you would like your event to be streamed. We allow a maximum of 4
            additional destinations.
          </p>
          <StyledBoxSelect>
            {destinationOptions.map((destination) => {
              const hasError = destination.deauthorized;

              const isActive =
                destination.service === Destination.Service.LiveControl
                  ? !!store.eventDestinations.length
                  : store.eventDestinations.some(
                      (eventDestination) => eventDestination.id === destination.id
                    );

              return (
                <ClickableCard.Button
                  key={destination.id}
                  status={
                    hasError
                      ? ClickableCard.Status.Error
                      : isActive
                      ? ClickableCard.Status.Active
                      : undefined
                  }
                  variant='tint-ø2'
                  disabled={disabled}
                  onClick={(): void => {
                    if (
                      !disabled &&
                      (destination.service !== Destination.Service.LiveControl ||
                        !store.eventDestinations.length)
                    ) {
                      onChange(destination);
                    }
                  }}
                >
                  <div className='d-flex flex-row p-16px align-items-center'>
                    <Glyph size='lg' className='bg-tint-ø2'>
                      {destination.service === Destination.Service.LiveControl ? (
                        <Icons.LcIcon duoTone size='28' />
                      ) : destination.service === Destination.Service.Facebook ? (
                        <Icons.FbLiveIcon scale={1.25} />
                      ) : destination.service === Destination.Service.YouTube ? (
                        <i className='text-danger font-size-28px fab fa-youtube' />
                      ) : (
                        <i className='text-secondary fal fa-signal-stream' />
                      )}
                    </Glyph>
                    <div className='mx-16px text-left destination-label'>
                      <strong>{Destination.Service[destination.service]}</strong>
                      {hasError ? (
                        <p className='mb-0 text-danger text-truncate font-size-13px font-weight-normal'>
                          Connection Error
                        </p>
                      ) : (
                        <p className='mb-0 text-secondary text-truncate font-size-13px font-weight-normal'>
                          {destination.name}
                        </p>
                      )}
                    </div>
                  </div>
                </ClickableCard.Button>
              );
            })}
          </StyledBoxSelect>
          {store.eventType !== EventValidator.ProductionType.TEST && (
            <>
              <h2 className='mt-32px font-size-24px'>Private Event</h2>
              <p className='text-muted'>
                This event will only be recorded and it will be private. It will not be
                live-streamed online.
              </p>
              <StyledBoxSelect>
                <ClickableCard.Button
                  status={
                    store.eventDestinations.length === 0 ? ClickableCard.Status.Active : undefined
                  }
                  variant='tint-ø2'
                  disabled={disabled}
                  onClick={(): void => {
                    if (!disabled) {
                      store.setEventDestinations([]);
                    }
                  }}
                >
                  <div className='d-flex flex-row p-16px align-items-center'>
                    <Glyph size='lg' className='bg-tint-ø2'>
                      <i className='text-secondary fal fa-eye-slash' />
                    </Glyph>
                    <div className='mx-16px text-left destination-label'>
                      <strong>Record Only</strong>
                    </div>
                  </div>
                </ClickableCard.Button>
              </StyledBoxSelect>
              {store.eventInfo.passwordSettings?.enabled &&
                store.eventDestinations.length === 0 && (
                  <p className='text-danger'>
                    This event will not be password protected, since it will not be live-streamed
                    online.
                  </p>
                )}
            </>
          )}
        </div>
      </StyledFormLayout>
    </>
  );
};
