import type { ApolloQueryResult} from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Coordinates } from '@livecontrol/gis-utils';
import type { Asset } from '@livecontrol/scheduler/model';
import { Account } from '@livecontrol/scheduler/model';
import type { DateTime} from 'luxon';
import { Duration } from 'luxon';
import { useEffect, useState } from 'react';
import type { Record as EventRecord } from '../account';
// eslint-disable-next-line import/no-internal-modules
import { normalize as normalizeEvent, StandardEventResponse } from '../event/dbx';
import type { QueryResult } from '../graphql';

interface filters {
  dates?: {
    start_time?: DateTime;
    end_time?: DateTime;
  };
  event_type_category?: string;
  location_ids?: string[] | string;
}

export type TResponse = Partial<{
  getCapturedEmailForManyEvents: {
    body: {
      metrics: {
        capturedEmails: number;
        registeredViewers: number;
        users: {
          email: string;
          last_name: string;
          name: string;
        };
      };
    };
  };
  getEventAnalytics: {
    total: number;
    page: number;
    result: {
      analytics?: {
        analytics_report: {
          avg_watched_duration: number;
          emails_captured: number;
          percentage_watched?: number;
          registered_views: number;
          sum_watch_time: number;
          total_views?: number;
          unique_views?: number;
        };
        concurrent_views?: {
          timestamp_minutes: number;
          count: number;
          timestamp_seconds: number;
        }[];
        device_report: {
          device_summary: {
            percentage_desktop?: number;
            percentage_mobile?: number;
            percentage_other?: number;
            percentage_tablet?: number;
          };
          os_summary: {
            counts: {
              count: number;
              os: string;
            };
            percentage_android?: number;
            percentage_ios?: number;
          };
        };
        locations_report?: {
          latitude: number;
          location_name: string;
          longitude: number;
          total_count: number;
        }[];
      };
      event?: EventRecord;
    }[];
  };
}>;

interface TVariables {
  input: {
    client_id?: number;
    page?: number;
    end_time?: DateTime;
    start_time?: DateTime;
    limit?: number;
    event_types?: string;
    location_ids?:  string[] | string;
  };
  clientId?: number;
  eventSlugIds?: string[];
}

export enum EventTypeCategories {
  STATIC = 'static',
  PRODUCED = 'produced',
  SIMULATED = 'simulated',
  TEST = 'test',
  MOBILE_KIT = 'mobile_kit'
}

interface Event {
  averageWatchTime: number;
  deviceInfo?: Asset.Analytics.WebPlayer | undefined;
  id?: number;
  percentageWatchTime: number;
  totalViews: number;
  uniqueViews: number;
  haveAnalytics: boolean;
}

// dates: { start_time: "2022-05-19T23:29:03", end_time: "2022-07-31T23:29:03" }

export const QUERY = gql`
  query GetEventAnalytics(
    $input: ServerlessAnalyticsInput!

  ) {

    getEventAnalytics(input: $input) {
      error {
        code
        message
      }
      has_more
      limit
      page
      total
      success
      result {
        analytics {
          analytics_report {
            avg_watched_duration
            emails_captured
            percentage_watched
            registered_views
            sum_watch_time
            total_views
            unique_views
          }
          concurrent_views {
            timestamp_minutes
            count
            timestamp_seconds
          }
          device_report {
            device_summary {
              percentage_desktop
              percentage_mobile
              percentage_other
              percentage_tablet
            }
            os_summary {
              counts {
                count
                os
              }
              percentage_android
              percentage_ios
            }
          }
          locations_report {
            latitude
            location_name
            longitude
            total_count
          }
        }
        event {
          ...StandardEventResponse
        }
      }
    }
  }
  ${StandardEventResponse}
`;

interface Args {
  account: Account.Like;
  eventSlugIds?: string[];
  filters?: filters;
  page?: number;
  limit?: number;
}

interface Response {
  refetch?: (variables?: Partial<Args> | undefined) => Promise<ApolloQueryResult<TResponse>>;
}

export const useGlobalAnalytics = (args: Args): QueryResult<Asset.Analytics, 'analytics'> & Response => {
  const [error, setError] = useState<Error | undefined>();
  const [analytics, setAnalytics] = useState<Asset.Analytics | undefined>(undefined);

  const filters = {
    dates: args.filters?.dates,
    event_type_category: args.filters?.event_type_category,
    location_ids: Array.isArray(args.filters?.location_ids)
      ? args.filters?.location_ids
      : args.filters?.location_ids
  };

  // Parse the input arguments
  const variables = {
    input: {
      client_id: Account.toId(args.account),
      page: args.page,
      end_time: filters.dates?.end_time,
      start_time: filters.dates?.start_time,
      limit: args.limit,
      event_types: filters.event_type_category,
      location_ids: filters.location_ids
    },
    clientId: Account.toId(args.account),
    eventSlugIds: args.eventSlugIds,
    filters
  };


  // Prepare the query
  const { loading, data, refetch } = useQuery<TResponse, TVariables>(QUERY, {
    variables,
    onError() {
      setError(Exception.KABOOM);
    }
  });

  // When available, parse server response
  useEffect(() => {
    let analytics_: Asset.Analytics | undefined;
    let error_;

    if (data?.getEventAnalytics?.result) {
      const dbLocationsGlobal: { name: string; coordinates: Coordinates; views: number }[] = [];
      const eventAnalytics: Event[] = [];

      if(data.getEventAnalytics.result.length !== 0 ) {
        data.getEventAnalytics.result.forEach((analytic) => {

          let dbLocations: { name: string; coordinates: Coordinates; views: number }[] = [];

          analytic.analytics?.locations_report?.forEach((location) => {
            const index = dbLocationsGlobal.findIndex(
              (keylocation) => keylocation.name === location.location_name
            );

            if (index >= 0) {
              dbLocationsGlobal[index].views += 1;
            } else {
              dbLocationsGlobal.push({
                name: location.location_name,
                coordinates: {
                  latitude: location.latitude,
                  longitude: location.longitude
                },
                views: 1
              });
            }
          });

          if (analytic.analytics?.locations_report) {
            dbLocations = [...analytic.analytics.locations_report]
              .sort((a, z) => {
                const r = z.total_count - a.total_count;

                // Sort by viewers, then alphabetically by city
                return r !== 0 ? r - 1 : a.location_name.localeCompare(z.location_name);
              })
              .slice(0, 5)
              .map(({ location_name, longitude, latitude, total_count: views }) => ({
                coordinates: Coordinates.normalize({ latitude, longitude })!,
                name: `${location_name}, ${location_name}`,
                views
              }));
          }


          const eventNormilized = normalizeEvent(analytic.event);

          const analyticsReport = {
            averageWatchTime: analytic.analytics?.analytics_report.avg_watched_duration ?? 0,
            percentageWatchTime: analytic.analytics?.analytics_report.percentage_watched ?? 0,
            totalViews: analytic.analytics?.analytics_report.total_views ?? 0,
            haveAnalytics: !!analytic.analytics,
            uniqueViews: analytic.analytics?.analytics_report.unique_views ?? 0,
            viewers: {
              capturedEmails: analytic.analytics?.analytics_report.emails_captured ?? 0,
              registeredViewers: analytic.analytics?.analytics_report.registered_views ?? 0
            },
            deviceInfo: {
              views: {
                total: analytic.analytics?.analytics_report.total_views ?? 0,
                unique: analytic.analytics?.analytics_report.unique_views ?? 0
              },
              retention: {
                average: analytic.analytics?.analytics_report.avg_watched_duration
                ? Duration.fromMillis(analytic.analytics.analytics_report.avg_watched_duration * 6000)
                : Duration.fromMillis(0),
                percent: analytic.analytics?.analytics_report.percentage_watched ?? 0
              },
              category: {
                mobile: analytic.analytics?.device_report.device_summary.percentage_mobile ?? 0,
                desktop: analytic.analytics?.device_report.device_summary.percentage_desktop ?? 0,
                tablet: analytic.analytics?.device_report.device_summary.percentage_tablet ?? 0,
                other: analytic.analytics?.device_report.device_summary.percentage_other ?? 0
              },
              os: {
                ios: analytic.analytics?.device_report.os_summary.percentage_ios ?? 0,
                android: analytic.analytics?.device_report.os_summary.percentage_android ?? 0
              },
              locations: dbLocations
            },
            event: eventNormilized
          };

          eventAnalytics.push(analyticsReport);
        });
      }

      analytics_ = {
        events: eventAnalytics,
        total:  data.getEventAnalytics.total,
        page: data.getEventAnalytics.page,
        platforms: {
        }
      };
    }

    setAnalytics(analytics_);
    setError(error_);
  }, [data]);

  return { error, loading, analytics, refetch };
};
