import type { Location } from '@livecontrol/scheduler/model';
import { EventValidator } from '@livecontrol/scheduler/validator';
import { DateTime } from 'luxon';
import type { Moment } from 'moment';
import { Store } from '../../../store';

export namespace EventDetailsUtils {
  export const getAvailableLocations = (
    locations: Location[] | undefined,
    eventType: EventValidator.ProductionType | undefined
  ): Location[] =>
    locations?.filter((location) =>
      eventType === EventValidator.ProductionType.SIMULATED
        ? location.simulatedLive
        : eventType === EventValidator.ProductionType.PRODUCED
        ? !location.simulatedLive && location.isProduced
        : eventType === EventValidator.ProductionType.CLIENT_BOOKING
        ? location.isClientBooking
        : eventType === EventValidator.ProductionType.MOBILE
        ? location.isMobile
        : eventType === EventValidator.ProductionType.MOBILE_KIT
        ? location.isMobileKit
        : eventType === EventValidator.ProductionType.TEST
        ? location.isTest
        : !location.simulatedLive && location.isStatic
    ) ?? [];

  export const isCueSheetEnabled = (
    availableLocations: Location[],
    selectedLocationId?: string
  ): boolean =>
    !!availableLocations.find((location) => location.id === selectedLocationId)?.isCueSheetEnabled;

  export const calculateEventTimes = (values: {
    startTime?: Moment;
    endTime?: Moment;
    date?: DateTime;
    timezone?: string;
  }): { startTime?: DateTime; endTime?: DateTime } => {
    const account = Store.Account.useAccount();

    let startTime = values.startTime
      ? DateTime.fromFormat(values.startTime.format('hmm a'), 'hmm a', { zone: values.timezone ?? account.timezone })
      : undefined;

    let endTime = values.endTime
      ? DateTime.fromFormat(values.endTime.format('hmm a'), 'hmm a', { zone: values.timezone ?? account.timezone })
      : undefined;

    if (values.date?.isValid && endTime) {
      endTime = values.date.set({ hour: endTime.hour, minute: endTime.minute }).toUTC();
    }

    if (values.date?.isValid && startTime) {
      startTime = values.date.set({ hour: startTime.hour, minute: startTime.minute }).toUTC();
    }

    return { startTime, endTime };
  };
}
