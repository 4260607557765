import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Obj } from '@livecontrol/core-utils';
import type { Event } from '@livecontrol/scheduler/model';
import { Account } from '@livecontrol/scheduler/model';
import { useEffect, useState } from 'react';
import * as AssetStore from '../asset';
import * as DestinationStore from '../destination';
import type { QueryResult } from '../graphql';
import * as E_Dbx from './dbx';

import A_Dbx = AssetStore.Dbx;
import D_Dbx = DestinationStore.Dbx;

interface TVariables {
  clientAsFloat: number;
  clientAsInt: number;
  clientAsStr: string;
}

interface TData {
  events?: E_Dbx.Record[];
  listAssets?: A_Dbx.Record[];
  integrations?: D_Dbx.Record[];
}

export const QUERY = gql`
  query GetEvents($clientAsFloat: Float!, $clientAsInt: Int!, $clientAsStr: String!) {
    events(event: { extra_filters: { client_id: $clientAsFloat } }) {
      ...StandardEventResponse
    }

    listAssets(clientId: $clientAsInt) {
      ...FullAssetResponse
    }

    integrations(clientId: $clientAsStr) {
      ...StandardDestinationResponse
    }
  }
  ${E_Dbx.StandardEventResponse}
  ${A_Dbx.FullAssetResponse}
  ${D_Dbx.StandardDestinationResponse}
`;

type Args = Account.Like;

export const useEvents = (args: Args): QueryResult<Event[], 'events'> => {
  const [error, setError] = useState<Error | undefined>();
  const [events, setEvents] = useState<Event[] | undefined>();

  const accountId = Account.toId(args);

  // Parse the input arguments
  const variables = {
    clientAsFloat: accountId,
    clientAsInt: accountId,
    clientAsStr: accountId?.toString()
  };

  // Validate the input
  if (!Obj.isHydrated(variables)) {
    return { loading: false, error: Exception.KABOOM };
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  // Prepare the query
  const { loading, data } = useQuery<TData, TVariables>(QUERY, {
    variables,
    onError() {
      setError(Exception.KABOOM);
    }
  });

  // When available, parse server response
  useEffect(() => {
    let events_;
    let error_;

    if (data) {
      // Build mapping of the destinations/assets
      const assets = A_Dbx.toEventMap(data.listAssets);
      const destinations = D_Dbx.toMap(data.integrations);

      events_ = data.events
        ?.map((record: E_Dbx.Record) => {
          const event = E_Dbx.normalize(record);

          if (event) {
            // Augment the event with assets/destinations
            event.assets = assets.get(event.id);
            event.destinations = E_Dbx.correlate(record, destinations);
          }

          return event;
        })
        .filter((event: Event | undefined): event is Event => !!event)
        .sort((a, b) => b.start.valueOf() - a.start.valueOf());
    }

    setEvents(events_);
    setError(error_);
  }, [data]);

  return { loading, error, events };
};
