import { Button } from '@livecontrol/core-ui';
import type { Asset } from '@livecontrol/scheduler/model';
import { useState } from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import { Store } from '../../../../store';
import { Editor } from './editor';

type Props = HTMLAttributes<HTMLButtonElement> & {
  asset: Asset;
};

export const Edit = ({ asset, ...props }: Props): ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const me = Store.User.useMe();

  return (
    <>
      <Button
        {...props}
        variant='outline-primary'
        onClick={(): void => {
          setShowModal(true);
        }}
        disabled={!me.permissions.admin && me.permissions.readOnlyArchive}
      >
        <i className='fas fa-pencil' />
        Edit
      </Button>

      {showModal && (
        <Editor
          asset={asset}
          onClose={(): void => {
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};
