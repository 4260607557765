import { Modal } from '@livecontrol/scheduler/components';
import type { Account } from '@livecontrol/scheduler/model';
import React from 'react';
import type { ReactElement } from 'react';
import { PaymentConfirm } from './payment-confirm';
import { PaymentFail } from './payment-fail';

interface Props {
  onSuccess: () => void;
  onClose: () => void;
  billing: Account.Billing;
  price: number;
}

enum Step {
  CONFIRM = 'Confirm',
  FAIL = 'Fail',
  SUCCESS = 'Success'
}

export interface PaymentsModalProps {
  price: number;
  step: Step;
}

export const PaymentsModal = (props: Props): ReactElement => {
  const { onClose, onSuccess, billing, price } = props;

  const [modalProps, setModalProps] = React.useState<PaymentsModalProps>({
    price,
    step: billing.stripe.defaultLast4CardNumber === 'last4_not_found' ? Step.FAIL : Step.CONFIRM
  });

  const confirmTotal = (result: Step.FAIL | Step.SUCCESS): void => {
    if (result === Step.SUCCESS) {
      onSuccess();
    } else {
      setModalProps({
        ...modalProps,
        step: result
      });
    }
  };

  return (
    <Modal show backdrop='static' keyboard={false} onHide={onClose}>
      {modalProps.step === Step.CONFIRM ? (
        <PaymentConfirm
          billing={billing}
          modalProps={modalProps}
          onClose={onClose}
          confirmTotal={confirmTotal}
        />
      ) : (
        <PaymentFail billing={billing} onClose={onClose} />
      )}
    </Modal>
  );
};
