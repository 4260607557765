import React from 'react';
import type { PageProps } from './page';
import { Page } from './page';

export const Kaboom = (props: Pick<PageProps, 'className' | 'reset'>): React.ReactElement => (
  <Page
    code={500}
    heading='Sorry. It’s not you. It’s us.'
    message='We’re experiencing an internal server problem.<br />Please try again later.'
    {...props}
  />
);
