import { Device, Theme } from '@livecontrol/core-ui';
import type { Location } from '@livecontrol/scheduler/model';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Item } from './item';

const Container = styled.div`
  margin-top: 16px;

  &.overflow {
    overflow-x: auto;
    max-width: 450px;
  }

  &.overflowMobile {
    overflow-x: auto;
    max-width: 300px;
  }

  &.overflowHidden {
    overflow-x: auto;
  }
`;

interface props {
  equipments: Equipment[];
  locationSelected: Location | number;
}

interface Equipment {
  img: string;
  router: string;
  owerShip: string;
  qty: number;
  location: string;
  id: number;
}

export const List = ({ equipments, locationSelected }: props): ReactElement => {
  const [equipmentSelected, setEquipmentSelected] = useState(equipments);

  useEffect(() => {
    if (typeof locationSelected === 'number') {
      setEquipmentSelected(equipments);
    } else {
      setEquipmentSelected(() => {
        const newState = equipments.filter(
          (equipment) => equipment.location === locationSelected.name
        );

        return newState;
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationSelected]);

  const viewport = Theme.useViewport();

  return (
    <Container
      className={cx(
        Device.isDesktop(viewport)
          ? 'overflowHidden'
          : Device.isMobile(viewport)
          ? 'overflowMobile'
          : 'overflow'
      )}
    >
      {equipmentSelected.map((equipment: Equipment) => (
        <Item {...equipment} key={equipment.id} />
      ))}
    </Container>
  );
};
