import cx from 'classnames';
import type { HTMLAttributes, ReactElement } from 'react';
import { NoMatch } from '../common';
import { EventsSection } from '../event-rows';
import { Hooks } from '../hooks';
import type { Event } from '../store';
import { Phase } from '../store';

type Props = HTMLAttributes<HTMLDivElement> & { events: readonly Event[] };

const PHASE = Phase.Live;

export const Live = ({ events, className, ...props }: Props): ReactElement => {

  // Transform the events
  const xform = Hooks.useTransform(PHASE, events);

  return (
    <div className={cx('mt-20px', className)} {...props}>
      {xform.length ? <EventsSection transformed={xform} /> : <NoMatch phase={PHASE} />}
    </div>
  );
};
