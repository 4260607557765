/* eslint-disable @typescript-eslint/no-unnecessary-condition */ import {
  Device,
  Loading,
  Theme
} from '@livecontrol/core-ui';
import { Matrix } from '@livecontrol/scheduler/components';
import type { Account } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { FormikProvider } from 'formik';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { Form } from '../../../../components';
import { Counter } from './counter';
import { useForm } from './form';
import { Header } from './header';
import { RoleContacts } from './role-contacts';
import { RowUser } from './row';

import G = Matrix.Grid;

const Grid = styled(Matrix.Grid)<{ columnsWithErrors?: number[] }>`
  grid-row-gap: 0 !important;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 500px;

  .border-top {
    border-top: 1px solid #e4e8f0;
  }

  .badge {
    font-size: 8px;
  }

  .bg-purplue {
    background-color: #7b61ff;
  }

  .bg-green {
    background-color: #37b34a;
  }

  .bg-blue {
    background-color: #00c1c1;
  }

  .column:nth-child(2n + 0) {
    background-color: #f0f6ff !important;
  }

  .column:nth-child(2n + 1) {
    background-color: white;
  }

  .header{
    top: 0;
  }

  ${({ columnsWithErrors }): string => {
    let style = '';

    columnsWithErrors?.forEach((column) => {
      // eslint-disable-next-line unicorn/prefer-spread
      style = style.concat(`
            li > .column:nth-child(${column}) {
              border-left: 1px solid red !important;
              border-right: 1px solid red !important;
            }

            li > div:last-child  {
              border-top-right-radius: 0px !important;
              border-bottom-right-radius: 0px !important;
            }

            li:first-child > div:nth-child(${column}) {
              border-top:  1px solid red;
            }

            li:last-child > div:nth-child(${column}) {
              border-bottom:  1px solid red;
            }

          `);
    });

    return style;
  }};

  .height-10px {
    height: 10px !important;
    padding: 0px !important;
  }

  .th {
    margin: 0 !important;
    padding: 20px 18px;
    font-weight: 600;
    font-size: var(--font-size-12px);
    color: var(--dark);
    z-index: 1000:
  }

  .form-group {
    flex: 0 0 0 !important;
  }

  .form-control {
    padding: 0px;
  }

  input[type='checkbox'] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 5px;
    width: 16px;
    height: 16px;
    border: 1px solid #0d1438;
    outline: none;
  }

  input[type='checkbox']:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
  }

  input:checked[type='checkbox'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
`;

const Row = styled(Matrix.Grid.Row)`
  position: relative;
  z-index: 1;

  .users {
    position: sticky;
    left: 0;
    z-index: 2;
  }

  button {
    visibility: hidden;
    display: flex !important;
  }

  &:hover {
    .column {
      border-color: #2e5bff !important;
    }

    button {
      visibility: visible !important;
      display: flex !important;
    }
  }
`;

const CellNoBorder = styled(Matrix.Grid.Row.Cell)`
  border: 0px solid transparent;
  justify-content: center;
  padding: 14px 0px;

  &.transparent {
    background-color: transparent !important;
  }

  &.boder-bottom {
    border-bottom: 1px solid #e4e8f0 !important;
  }

  &:last-child {
    border-right: 0px solid transparent !important;
    padding: 5px 0px !important;
  }

  &:first-child {
    border-left: 0px solid transparent !important;
    position: sticky;
    left: 0;
  }
`;

const Cell = styled(Matrix.Grid.Row.Cell)`
  border-color: #d5daea;
  justify-content: center;
  padding: 14px 0px;

  &.height {
    height: 90px;
  }

  &:first-child {
    border-top-left-radius: var(--spacer-4px);
    border-bottom-left-radius: var(--spacer-4px);
  }
  &:last-child {
    border-top-right-radius: var(--spacer-4px);
    border-bottom-right-radius: var(--spacer-4px);
    padding: 14px 0px;
  }
`;

const ContainerBlock = styled.div``;

interface Props {
  account: Account;
}

enum ContactRole {
  ONBOARDING_MAIN = 1,
  ONBOARDING_TECHNICAL = 2,
  PRIMARY_BILLING = 3,
  SHIPPING = 4,
  ONSITE_TECHINCAL = 5
}

type RoleMapping = Record<string, number>;

export const AssignedContacts = ({ account }: Props): ReactElement => {
  const { loading: loadingBilling } = Scheduler.Account.useBilling(account);

  const viewport = Theme.useViewport();
  const { locations } = Scheduler.Location.useLocations(account);
  const [columnWithErrors, setColumnWithErrors] = useState<number[]>([]);

  const filteredLocations = locations?.filter((location) => !location.simulatedLive);

  const locationURL = useLocation();
  const searchParams = new URLSearchParams(locationURL.search);
  const roleSelectedFromUrl = searchParams.get('role_selected');

  const roleMapping: RoleMapping = {
    onboarding_main: 1,
    onboarding_technical: 2,
    primary_billing: 3,
    shipping: 4,
    on_site_technical: 5
  };

  let dropdownDefaultValue = 5;

  if (roleSelectedFromUrl !== null) {
    dropdownDefaultValue = roleMapping[roleSelectedFromUrl];
  }

  const [saved, setSaved] = useState(false);

  const handleSubmitSuccess = (): void => {
    setSaved(true);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (saved) {
      setSaved(true);

      timeout = setTimeout(() => {
        setSaved(false);
      }, 3000);
    }

    return (): void => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [saved]);

  const { formik, loading, contactRoleResponse } = useForm({
    account,
    locations: filteredLocations,
    errors: columnWithErrors,
    handleSubmitSuccess
  });

  const [errorsMessage, setErrorsMessage] = useState<string[]>([]);
  const [roleContact, setRoleContact] = useState(dropdownDefaultValue);
  const [rowSelected, setRowSelected] = useState(false);
  const [accountValues, setAccountValues] = useState(account);

  useEffect(() => {
    if (contactRoleResponse && contactRoleResponse.length > 0) {
      const { values } = formik;

      if (values.contactRole === 'onboarding_main' || values.contactRole === 'primary_billing') {
        const idsValues = Object.getOwnPropertyNames(values);
        const allValues = Object.values(values ? values : {});

        let newAccount: Account = { ...accountValues };

        allValues.forEach((value, index) => {
          const userId = Number(idsValues[index].replace('_', ''));

          const currentUser =
            userId === accountValues.id
              ? accountValues
              : accountValues.subUsers.find((user) => user.id === userId);

          if (!currentUser) {
            return;
          }

          const updatedContactRoles = currentUser.contactRole?.map((role) => {
            if (role?.location?.id === undefined) {
              const newRole = { ...role };

              if (typeof value === 'boolean') {
                newRole.onboarding_main =
                  values.contactRole === 'onboarding_main' ? value : role.onboarding_main;

                newRole.primary_billing =
                  values.contactRole === 'primary_billing' ? value : role.primary_billing;
              }

              return newRole;
            }

            return role;
          });

          if (userId === accountValues.id && updatedContactRoles) {
            newAccount = { ...newAccount, contactRole: updatedContactRoles };
          } else if (updatedContactRoles && 'sub_user_locations' in currentUser) {
            const updatedUser = { ...currentUser, contactRole: updatedContactRoles };

            newAccount = {
              ...newAccount,
              subUsers: newAccount.subUsers.map((user) => (user.id === userId ? updatedUser : user))
            };
          }
        });

        setAccountValues(newAccount);

        // tiene locación
      } else {
        const idsLocationValues = Object.getOwnPropertyNames(values);
        const subuserValues = Object.values(values ? values : {});

        let newAccount: Account = { ...accountValues };

        idsLocationValues.forEach((id, indexLocations) => {
          if (subuserValues[indexLocations] === values.contactRole) {
            return;
          }

          const subuserIds = Object.getOwnPropertyNames(subuserValues[indexLocations]);
          const allValues = Object.values(subuserValues[indexLocations] as Record<string, boolean>);

          allValues.forEach((value, index) => {
            const userId = Number(subuserIds[index].replace('_', ''));

            const currentUser =
              userId === newAccount.id
                ? newAccount
                : newAccount.subUsers.find((user) => user.id === userId);

            const updatedContactRoles = currentUser?.contactRole?.map((role) => {
              if (role?.location?.id === id) {
                const newRole = { ...role };

                if (typeof value === 'object') {
                  const valueOnSiteTechnical = value as { value: boolean; priority: number };

                  newRole.onsite_technical_priority = valueOnSiteTechnical.priority;
                  newRole.onsite_technical = valueOnSiteTechnical.value;
                } else if (typeof value === 'boolean') {
                  newRole.onboarding_technical =
                    values.contactRole === 'onboarding_technical'
                      ? value
                      : role.onboarding_technical;

                  newRole.shipping = values.contactRole === 'shipping' ? value : role.shipping;
                }

                return newRole;
              }

              return role;
            });

            if (updatedContactRoles) {
              if (userId === newAccount.id) {
                newAccount = { ...newAccount, contactRole: updatedContactRoles };

                setAccountValues(newAccount);
              } else if ('sub_user_locations' in currentUser!) {
                const updatedUser = { ...currentUser, contactRole: updatedContactRoles };

                newAccount = {
                  ...newAccount,
                  subUsers: newAccount.subUsers.map((user) =>
                    user.id === userId ? updatedUser : user
                  )
                };

                setAccountValues(newAccount);
              }
            }
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactRoleResponse]);

  /*
   *   useEffect(() => {
   *   if (contactRoleResponse && contactRoleResponse.length > 0) {
   *    contactRoleResponse.forEach((role) => {
   *      setAccountValues((oldState): Account => {
   *        let newContactRole: User.ContactRole[] = oldState.contactRole;
   *
   *        if (Number(role.user.id) === Number(oldState.id)) {
   *          newContactRole = oldState.contactRole.map((contact) => {
   *            if (contact.id === role.id) {
   *              return role;
   *            }
   *
   *            return contact;
   *          });
   *        }
   *
   *        const newSubUser = oldState.subUsers.map((user) => {
   *          if (Number(role.user.id) === Number(user.id)) {
   *            const newContactRoleUser = user.contactRole?.map((contact) => {
   *              if (contact.id === role.id) {
   *                return role;
   *              }
   *
   *              return contact;
   *            });
   *
   *            return { ...user, contactRole: newContactRoleUser };
   *          }
   *
   *          return user;
   *        });
   *
   *        const newState = {
   *          ...oldState,
   *          contactRole: newContactRole,
   *          subUsers: newSubUser
   *        };
   *
   *        return newState;
   *      });
   *    });
   *   }
   *   }, [contactRoleResponse]);
   */

  const fillAssignedContacts = (): void => {
    switch (roleContact) {
      case ContactRole.ONBOARDING_MAIN:
        accountValues.contactRole.forEach((contact) => {
          const fieldName = `${accountValues.id}`;
          const fieldValue = contact.onboarding_main;

          if (contact.onboarding_main) {
            formik.setFieldValue(fieldName, fieldValue);
          }
        });

        accountValues.subUsers.forEach((subuser) => {
          subuser.contactRole?.forEach((contact) => {
            if (contact.onboarding_main) {
              const fieldName = `${subuser.id}`;
              const fieldValue = contact.onboarding_main;

              formik.setFieldValue(fieldName, fieldValue);
            }
          });
        });

        break;

      case ContactRole.ONBOARDING_TECHNICAL:
        accountValues.contactRole.forEach((contact) => {
          if (contact.location && contact.onboarding_technical) {
            formik.setFieldValue(
              `${contact.location.id}._${accountValues.id}`,
              contact.onboarding_technical
            );
          }
        });

        accountValues.subUsers.forEach((subuser) => {
          subuser.contactRole?.forEach((contact) => {
            if (contact.location && contact.onboarding_technical) {
              formik.setFieldValue(
                `${contact.location.id}._${subuser.id}`,
                contact.onboarding_technical
              );
            }
          });
        });

        break;

      case ContactRole.PRIMARY_BILLING:
        accountValues.contactRole.forEach((contact) => {
          if (contact.primary_billing) {
            formik.setFieldValue(`${accountValues.id}`, contact.primary_billing);
          }
        });

        accountValues.subUsers.forEach((subuser) => {
          subuser.contactRole?.forEach((contact) => {
            if (contact.primary_billing) {
              formik.setFieldValue(`${subuser.id}`, contact.primary_billing);
            }
          });
        });

        break;

      case ContactRole.SHIPPING:
        accountValues.contactRole.forEach((contact) => {
          if (contact.location && contact.shipping) {
            formik.setFieldValue(`${contact.location.id}._${accountValues.id}`, contact.shipping);
          }
        });

        accountValues.subUsers.forEach((subuser) => {
          subuser.contactRole?.forEach((contact) => {
            if (contact.location && contact.shipping) {
              formik.setFieldValue(`${contact.location.id}._${subuser.id}`, contact.shipping);
            }
          });
        });

        break;

      default:
        accountValues.contactRole.forEach((contact) => {
          if (contact.location && contact.onsite_technical) {
            setRowSelected(true);

            formik.setFieldValue(
              `${contact.location.id}._${accountValues.id}.value`,
              contact.onsite_technical
            );

            formik.setFieldValue(
              `${contact.location.id}._${accountValues.id}.priority`,
              contact.onsite_technical_priority
            );
          }
        });

        accountValues.subUsers.forEach((subuser) => {
          subuser.contactRole?.forEach((contact) => {
            if (contact.location && contact.onsite_technical) {
              formik.setFieldValue(
                `${contact.location.id}._${subuser.id}.value`,
                contact.onsite_technical
              );

              formik.setFieldValue(
                `${contact.location.id}._${subuser.id}.priority`,
                contact.onsite_technical_priority
              );
            }
          });
        });

        break;
    }
  };

  useEffect(() => {
    fillAssignedContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleContact, accountValues]);

  const selectRow = (): void => {
    filteredLocations?.forEach((location): void => {
      const fieldNamePrefix = `${location.id}._${accountValues.id}`;

      if (roleContact === ContactRole.ONSITE_TECHINCAL) {
        formik.setFieldValue(`${fieldNamePrefix}.priority`, 1);
        formik.setFieldValue(`${fieldNamePrefix}.value`, !rowSelected);
      } else {
        formik.setFieldValue(fieldNamePrefix, !rowSelected);
      }
    });

    setRowSelected(!rowSelected);
  };

  const checkSelectedAll = (): void => {
    const { values: locationsWithSelectedUser } = formik;
    let isSelectedAll = true;

    for (const location of filteredLocations ?? []) {
      const locationId = String(location.id);

      const locationWithSelectedUser = locationsWithSelectedUser[locationId];
      const accountValuesKey = `_${accountValues.id}` as keyof typeof locationWithSelectedUser;
      const fieldValue = locationWithSelectedUser?.[accountValuesKey];

      let hasRole = fieldValue !== undefined && fieldValue;

      if (ContactRole.ONSITE_TECHINCAL === roleContact) {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        hasRole = hasRole && fieldValue?.['value'];
      }

      if (!hasRole) {
        isSelectedAll = false;

        break;
      }
    }

    setRowSelected(isSelectedAll);
  };

  useEffect(() => {
    checkSelectedAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  const handleDuplicateError = (index: number, usersSelected: unknown[]): void => {
    const isDuplicateLocation = new Set(usersSelected).size !== usersSelected.length;

    if (isDuplicateLocation && roleContact === 5) {
      setErrorsMessage((oldErrors) => [...oldErrors, 'itemDuplicated']);
    }

    setColumnWithErrors((oldState) => {
      const newState = isDuplicateLocation
        ? [...oldState, index + 2]
        : oldState.filter((state) => state !== index + 2);

      const mapIds = new Map<number, number>();

      newState.forEach((obj) => {
        mapIds.set(obj, obj);
      });

      const newStateCleaned = [...mapIds.values()];

      return newStateCleaned;
    });
  };

  const handleAllLocationError = (indexUser: number): void => {
    setColumnWithErrors((oldState): number[] => {
      const newState = [...oldState, indexUser + 2];
      const isStateDuplicated = new Set(newState).size !== newState.length;

      const mapIds = new Map<number, number>();

      newState.forEach((obj) => {
        mapIds.set(obj, obj);
      });

      const newStateCleaned = [...mapIds.values()];

      return isStateDuplicated ? oldState : newStateCleaned;
    });

    if (roleContact === 5) {
      setErrorsMessage((oldState): string[] => [...oldState, 'noLocation']);
    }
  };

  const saveChanges = (): void => {
    setErrorsMessage([]);

    formik.setFieldValue(
      'contactRole',
      roleContact === 1
        ? 'onboarding_main'
        : roleContact === 2
        ? 'onboarding_technical'
        : roleContact === 3
        ? 'primary_billing'
        : roleContact === 4
        ? 'shipping'
        : 'onsite_technical'
    );

    if (roleContact === 1 || roleContact === 3) {
      const user = Object.values(formik.values);
      const userSelected = user.filter((item) => item === true);

      if (userSelected.length !== 1) {
        setColumnWithErrors([2]);

        return;
      }

      setColumnWithErrors([]);

      return;
    }

    filteredLocations?.forEach((location, index) => {
      const haveLocation = formik.values[location.id];

      if (haveLocation) {
        const user = Object.values(haveLocation);
        const userSelected = user.filter((item) => item);

        if (userSelected.length === 0) {
          handleAllLocationError(index);

          return;
        }

        if (roleContact === 5) {
          const haveLocationPriority = formik.values[`${location.id}`];
          const userPriority = Object.values(haveLocationPriority ? haveLocationPriority : {});
          const userSelecteduserPriority: number[] = [];

          userPriority.forEach((item: { priority: number; value: boolean }) => {
            if (item.value) {
              userSelecteduserPriority.push(item.priority);
            }
          });

          if (userSelecteduserPriority.length === 0) {
            handleAllLocationError(index);
          } else {
            handleDuplicateError(index, userSelecteduserPriority);
          }
        } else {
          handleDuplicateError(index, userSelected);
        }
      } else {
        handleAllLocationError(index);
      }
    });
  };

  useEffect(() => {
    saveChanges();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  return (
    <div className='position-relative'>
      <h1 className='mb-16px'>Assigned Contacts</h1>
      <div className='' style={{ maxWidth: '500px', paddingBottom: '20px' }}>
        Specify who we should contact for Technical Issues, Billing, Shipping, etc. The Main Account
        Holder is set as the default contact.
      </div>

      {loadingBilling ? (
        <Loading.Delay />
      ) : (
        <FormikProvider value={formik}>
          <Form>
            <div className='d-flex justify-content-end mb-32px'>
              <div className='position-relative'>
                <Form.Submit
                  loading={loading}
                  type='submit'
                  className='btn btn-primary'
                  onClick={saveChanges}
                >
                  Save Changes
                </Form.Submit>
                {saved && (
                  <span
                    className='text-success font-weight-bold text-center font-size-12px'
                    style={{ position: 'absolute', left: '40px', top: '46px' }}
                  >
                    <i className='mr-8px fa fa-check' />
                    Saved
                  </span>
                )}
              </div>
            </div>

            <ContainerBlock
              className='position-relative bg-white'
              style={{ width: Device.isMobile(viewport) ? '206px' : '296px' }}
            >
              <div
                className='position-absolute bg-white'
                style={{
                  width: Device.isMobile(viewport) ? '191px' : '280px',
                  minHeight: 100,
                  zIndex: 3
                }}
              >
                <RoleContacts
                  formik={formik}
                  setErrors={setColumnWithErrors}
                  setRoleContact={setRoleContact}
                  defaultValue={`${dropdownDefaultValue}`}
                />
              </div>
            </ContainerBlock>

            <Grid
              columnsWithErrors={columnWithErrors}
              id='gridScroll'
              viewport={viewport}
              className='position-relative'
              gridTemplateColumns={`${Device.isMobile(viewport) ? '190px' : '280px'} ${
                roleContact === 1 || roleContact === 3
                  ? '141px'
                  : `repeat( ${filteredLocations ? filteredLocations.length : 1}, 141px)  `
              } `}
            >
              {/* Header */}
              <Header
                errorsMessages={errorsMessage}
                errors={columnWithErrors}
                roleContact={roleContact}
                locations={filteredLocations}
              />

              {/* Account Holder */}
              <G.Row>
                <CellNoBorder className='column height-10px' />
                {roleContact === 1 || roleContact === 3 ? (
                  <CellNoBorder className='column height-10px' />
                ) : (
                  filteredLocations?.map((location) => (
                    <CellNoBorder key={`${location.id}`} className='column height-10px' />
                  ))
                )}
              </G.Row>
              <Row>
                <Cell className='column users justify-content-start'>
                  <div className='d-flex flex-column pr-4px'>
                    <div className='text-dark font-weight-bold font-size-12px'>
                      {accountValues.accountOwnerName}
                      <span className='ml-8px bottom-12 badge badge-pill text-white badge-gray-light mt-4px font-size-8px'>
                        Account Holder
                      </span>
                    </div>
                    {roleContact === 2 || roleContact === 4 || roleContact === 5 ? (
                      <button
                        onClick={(): void => {
                          selectRow();
                        }}
                        type='button'
                        className='btn p-0 bg-transparent text-primary font-weight-bold font-size-12px'
                      >
                        {!rowSelected ? 'Select All' : 'Deselect All'}
                      </button>
                    ) : (
                      <div className='d-none' />
                    )}
                  </div>
                </Cell>

                {roleContact === 1 || roleContact === 3 ? (
                  <Cell key={`${accountValues.id}`} className='column'>
                    <Form.Group
                      name={`${accountValues.id}`}
                      label=''
                      className='mb-0'
                      controlProps={{
                        type: 'checkbox'
                      }}
                    />
                  </Cell>
                ) : (
                  filteredLocations?.map((location) =>
                    roleContact === 5 ? (
                      <Cell key={`${location.id}${accountValues.id}`} className='column height'>
                        <Counter formik={formik} formName={`${location.id}._${accountValues.id}`} />
                      </Cell>
                    ) : (
                      <Cell key={`${location.id}${accountValues.id}`} className='column'>
                        <Form.Group
                          name={`${location.id}._${accountValues.id}`}
                          label=''
                          className='mb-0'
                          controlProps={{
                            type: 'checkbox'
                          }}
                        />
                      </Cell>
                    )
                  )
                )}
              </Row>

              {accountValues.subUsers.map((subuser) => (
                <RowUser
                  key={subuser.id}
                  formik={formik}
                  roleContact={roleContact}
                  locations={filteredLocations}
                  subuser={subuser}
                />
              ))}
            </Grid>
          </Form>
        </FormikProvider>
      )}
    </div>
  );
};
