import { Loading } from '@livecontrol/core-ui';
import type { Any } from '@livecontrol/core-utils';
import { UUID } from '@livecontrol/core-utils';
import { Modal } from '@livecontrol/scheduler/components';
import { Destination, Event } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Link, useLocation } from 'react-router-dom';
import { Storage, Store } from '../../../store';
import type { EventContext } from '../event-store';
import { EventContextStore } from '../event-store';
import { FacebookPermissions, YoutubePermissions } from '../modals';
import { DestinationForm } from './destination-form';

export const ChooseEventDestinations = ({
  onBack,
  onSchedule
}: {
  onBack: () => void;
  onSchedule: () => void;
}): React.ReactElement => {
  const { store }: EventContext = useContext<EventContext>(EventContextStore);
  const currentLocation = useLocation();
  const modalPrompt = Modal.usePrompt();
  const [showYoutubePermissionsModal, setShowYoutubePermissionsModal] = useState(false);
  const [showFacebookPermissionsModal, setShowFacebookPermissionsModal] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const uuid = useMemo(UUID.make, []);

  const onChangeSelection = useCallback(
    (selected: Destination): void => {
      if (!selected.deauthorized) {
        if (store.eventDestinations.some((destination) => destination.id === selected.id)) {
          store.setEventDestinations(
            store.eventDestinations.filter((destination) => destination.id !== selected.id)
          );
        } else if (store.eventDestinations.length === 0) {
          store.setEventDestinations(
            selected.service === Destination.Service.LiveControl
              ? [selected]
              : [Scheduler.Destination.LC_WEBPLAYER, selected]
          );
        } else if (store.eventDestinations.length <= Event.Constraints.MAX_DESTINATIONS) {
          store.setEventDestinations([...store.eventDestinations, selected]);
        } else {
          modalPrompt.error({
            title: 'Maximum Destinations',
            content: `Oops! We allow a maximum of ${Event.Constraints.MAX_DESTINATIONS} external destinations. To stream to this destination, you will need to deselect one of the others first.`
          });
        }
      } else {
        Storage.setItem(
          uuid,
          JSON.stringify(store, (_key, value: Any): Any => {
            if (typeof value === 'object' && value instanceof Set) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              return [...value];
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return value;
          }),
          { persistent: false }
        );

        if (selected.service === Destination.Service.YouTube) {
          setShowYoutubePermissionsModal(true);
        } else if (selected.service === Destination.Service.Facebook) {
          setShowFacebookPermissionsModal(true);
        }
      }
    },
    [modalPrompt, store, uuid]
  );

  const account = Store.Account.useAccount();
  const { destinations, loading } = Scheduler.Destination.useDestinations(account, false);

  return loading ? (
    <Loading />
  ) : (
    <>
      <h1 className='text-center'>Schedule an Event</h1>
      <DestinationForm onChange={onChangeSelection} destinations={destinations ?? []} />
      {showYoutubePermissionsModal && (
        <YoutubePermissions
          uuid={uuid}
          onClose={(): void => {
            setShowYoutubePermissionsModal(false);
          }}
        />
      )}
      {showFacebookPermissionsModal && (
        <FacebookPermissions
          uuid={uuid}
          onClose={(): void => {
            setShowFacebookPermissionsModal(false);
          }}
        />
      )}
      <div className='mt-48px d-flex flex-column align-items-center'>
        <Button className='btn-xwide' onClick={onSchedule}>
          Schedule
        </Button>
        <Link className='mt-32px text-secondary' to={currentLocation.pathname} onClick={onBack}>
          Go Back
        </Link>
      </div>
    </>
  );
};
