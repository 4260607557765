import { Button } from '@livecontrol/core-ui';
import type { Asset } from '@livecontrol/scheduler/model';
import { useState } from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import styled from 'styled-components';
import { Editor } from './editor';

type Props = HTMLAttributes<HTMLButtonElement> & {
  asset: Asset;
};

const ButtonContainer = styled.div`
  button {
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #cbcbcb;
    color: #0d1438;
    font-size: 12px;
    font-weight: 600;
    padding: 6px 11px !important;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    margin: 0px 0px 8px;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 6px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
    .pencil {
      background-image: url(/assets/icons/Edit-black.svg);
    }
  }
`;

export const EditButton = ({ asset, ...props }: Props): ReactElement => {
  const [showModal, setShowModal] = useState(false);

  return (
    <ButtonContainer>
      <Button
        {...props}
        variant='outline-primary'
        onClick={(): void => {
          setShowModal(true);
        }}
      >
        <div className='icon pencil' />
        <div className='text'>Edit Event</div>
      </Button>

      {showModal && (
        <Editor
          asset={asset}
          onClose={(): void => {
            setShowModal(false);
          }}
        />
      )}
    </ButtonContainer>
  );
};
