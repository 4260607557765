import { Str } from '@livecontrol/core-utils';
import { assert } from '@sindresorhus/is';

export class Phone {
  public readonly areaCode: string;
  public readonly exchange: string;
  public readonly lineNumber: string;

  public constructor(args: Phone.Constructor) {
    this.areaCode = Str.normalize(args.areaCode)!;
    this.exchange = Str.normalize(args.exchange)!;
    this.lineNumber = Str.normalize(args.lineNumber)!;

    assert.string(this.areaCode && this.exchange && this.lineNumber);
  }

  public format(options: Phone.Format = {}): string {
    const { areaCode, exchange, lineNumber } = this;

    return options.parenthetical
      ? `(${areaCode}) ${[exchange, lineNumber].join(options.separator ?? '-')}`
      : [areaCode, exchange, lineNumber].join(options.separator ?? '-');
  }

  public toString(): string {
    return this.format();
  }

  public static normalize(value: unknown): Phone | undefined {
    let phone: Phone | undefined;

    if (value) {
      if (value instanceof Phone) {
        phone = value;
      } else {
        const stringVal = Str.normalize(value);

        if (stringVal) {
          const canonical = stringVal.replace(/\D/g, '');

          if (canonical.length >= 10) {
            phone = new Phone({
              areaCode: canonical.slice(0, 3),
              exchange: canonical.slice(3, 6),
              lineNumber: canonical.slice(6)
            });
          }
        }
      }
    }

    return phone;
  }
}

export namespace Phone {
  export type Constructor = Readonly<{
    areaCode: string;
    exchange: string;
    lineNumber: string;
  }>;

  export interface Format {
    readonly separator?: string | undefined;
    readonly parenthetical?: boolean | undefined;
  }
}
