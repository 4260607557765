import React from 'react';
import type { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import { colors } from './utils';

interface Props {
  width?: number | string;
  height?: number | string;
  style?: React.CSSProperties;
  isTablet?: boolean;
}

export const ViewershipLoader = ({ width, height, style, isTablet }: Props): ReactElement => (
  <ContentLoader
    speed={2}
    width={width}
    height={height}
    viewBox={isTablet ? '0 0 516 127' : '0 0 258 254'}
    {...colors}
    style={style}
  >
    {isTablet ? (
      <>
        <rect x='0' y='18' rx='0' ry='0' width='42' height='15' />
        <rect x='0' y='40' rx='0' ry='0' width='66' height='12' />
        <rect x='140' y='18' rx='0' ry='0' width='42' height='15' />
        <rect x='140' y='40' rx='0' ry='0' width='66' height='12' />
        <rect x='0' y='73' rx='0' ry='0' width='42' height='15' />
        <rect x='0' y='95' rx='0' ry='0' width='66' height='12' />
        <rect x='140' y='73' rx='0' ry='0' width='42' height='15' />
        <rect x='140' y='95' rx='0' ry='0' width='66' height='12' />
        <rect x='278' y='0' rx='0' ry='0' width='258' height='130' />
      </>
    ) : (
      <>
        <rect x='0' y='0' rx='0' ry='0' width='42' height='15' />
        <rect x='0' y='22' rx='0' ry='0' width='66' height='12' />
        <rect x='140' y='0' rx='0' ry='0' width='42' height='15' />
        <rect x='140' y='22' rx='0' ry='0' width='66' height='12' />
        <rect x='0' y='55' rx='0' ry='0' width='42' height='15' />
        <rect x='0' y='77' rx='0' ry='0' width='66' height='12' />
        <rect x='140' y='55' rx='0' ry='0' width='42' height='15' />
        <rect x='140' y='77' rx='0' ry='0' width='66' height='12' />
        <rect x='0' y='120' rx='0' ry='0' width='258' height='130' />
      </>
    )}
  </ContentLoader>
);
