/* eslint-disable @typescript-eslint/no-unused-vars */
import { Layout as Layout_ } from './layout';
import { Linkbox as Linkbox_ } from './linkbox';
import { Ornamentation as Ornamentation_ } from './ornamentation';
import { Panel as Panel_ } from './panel';

export namespace Auth {
  export import Layout = Layout_;
  export import Linkbox = Linkbox_;
  export import Ornamentation = Ornamentation_;
  export import Panel = Panel_;
}
