import { Device, Theme } from '@livecontrol/core-ui';
import { Sidebar as Sidebar_ } from '@livecontrol/scheduler/components';
import { Scheduler } from '@livecontrol/scheduler/store';
import { assert } from '@sindresorhus/is';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { store, Store } from '../../store';
import { Header } from './header';
import {
  // BarChartIcon,
  BrowserCodeIcon,
  CalendarStarredIcon,
  DollarCoinIcon,
  ForwardEmailIcon,
  HelpIcon,
  HistoryIcon,
  // LayerCheckIcon,
  NoteIcon,
  PaymentCardIcon,
  SettingsIcon,
  SuitCaseIcon,
  SupportIcon,
  TravelBagIcon,
  UploadLaptopIcon,
  UserCircleIcon,
  UserGroupIcon,
  VideoIcon,
  VideoPlaylistIcon
} from './icons';

export const Sidebar = (): React.ReactElement => {
  const account = Store.Account.useAccount();
  const { billing, refetch } = Scheduler.Account.useBilling(account);
  const me = Store.User.useMe();

  assert.function_(refetch);

  const streamSettingsEntries: { key: string; icon: JSX.Element; label: string; target: string }[] =
    [];

  if (me.permissions.destinations || me.permissions.webplayer) {
    streamSettingsEntries.push({
      key: 'destinations',
      icon: <UploadLaptopIcon />,
      label: 'Destinations',
      target: '/stream-settings/destinations'
    });
  }

  if (me.permissions.destinations || me.permissions.webplayer) {
    streamSettingsEntries.push({
      key: 'web-player',
      icon: <BrowserCodeIcon />,
      label: 'Web Player',
      target: '/stream-settings/web-player'
    });
  }

  if (me.permissions.someLocationHasCustomizeProductionNotesEnabled) {
    streamSettingsEntries.push({
      key: 'production-notes',
      icon: <NoteIcon />,
      label: 'Production Notes',
      target: '/stream-settings/production-notes'
    });
  }

  const contentLibraryEntries: { key: string; icon: JSX.Element; label: string; target: string }[] =
    [];

  contentLibraryEntries.push(
    {
      key: 'library',
      icon: <VideoIcon />,
      label: 'Library',
      target: '/content-library/library'
    }
    /*
     * {
     *   key: 'aggregate-analytics',
     *   icon: <BarChartIcon />,
     *   label: 'Global Analytics',
     *   target: '/content-library/analytics'
     * }
     */
  );

  const accountInfoEntries: { key: string; icon: JSX.Element; label: string; target: string }[] =
    [];

  accountInfoEntries.push({
    key: 'profile',
    icon: <UserCircleIcon />,
    label: 'Profile',
    target: '/account/profile'
  });

  if (me.permissions.admin) {
    accountInfoEntries.push({
      key: 'users',
      icon: <UserGroupIcon />,
      label: 'Users',
      target: '/account/users'
    });
  }

  if (me.permissions.admin) {
    accountInfoEntries.push({
      key: 'organization',
      icon: <TravelBagIcon />,
      label: 'Organization',
      target: '/account/organization'
    });
  }

  if (me.permissions.admin || me.permissions.billing) {
    accountInfoEntries.push({
      key: 'activity-log',
      icon: <HistoryIcon />,
      label: 'Activity Log',
      target: '/account/activity-log'
    });
  }

  const subscriptionEntries: { key: string; icon: JSX.Element; label: string; target: string }[] =
    [];

  subscriptionEntries.push({
    key: 'plan',
    icon: <DollarCoinIcon />,
    label: 'Plan',
    target: '/subscription/plan'
  });

  if ((me.permissions.admin || me.permissions.billing) && billing?.stripe) {
    subscriptionEntries.push({
      key: 'billing',
      icon: <SuitCaseIcon />,
      label: 'Billing',
      target: '/subscription/billing'
    });
  }

  const descriptors = useMemo(
    () =>
      _.compact([
        /*
         * {
         *   key: 'onboarding',
         *   icon: <LayerCheckIcon />,
         *   label: 'Onboarding',
         *   target: '/onboarding',
         *   accent: true
         * },
         */
        {
          key: 'event-schedule',
          icon: <CalendarStarredIcon />,
          label: 'Event Schedule',
          target: '/schedule'
        },
        me.permissions.admin
          ? {
              key: 'content-library',
              icon: <VideoPlaylistIcon />,
              label: 'Content Library',
              target: '/content-library',
              subitems: [
                {
                  key: 'library',
                  icon: <VideoIcon />,
                  label: 'Library',
                  target: '/content-library/library'
                }
                /*
                 * {
                 *   key: 'aggregate-analytics',
                 *   icon: <BarChartIcon />,
                 *   label: 'Global Analytics',
                 *   target: '/content-library/analytics'
                 * }
                 */
              ]
            }
          : {
              key: 'content-library',
              icon: <VideoPlaylistIcon />,
              label: 'Content Library',
              target: '/content-library',
              subitems: contentLibraryEntries
            },
        streamSettingsEntries.length > 0 && {
          key: 'stream-settings',
          icon: <SettingsIcon />,
          label: 'Stream Settings',
          target: '/stream-settings',
          subitems: streamSettingsEntries
        },
        {
          key: 'account-info',
          icon: <UserCircleIcon />,
          label: 'Account Info',
          target: '/account',
          subitems: accountInfoEntries
        },
        subscriptionEntries.length > 0 && {
          key: 'subscription',
          icon: <PaymentCardIcon />,
          label: 'Subscription',
          target: '/subscription',
          subitems: subscriptionEntries
        },
        {
          key: 'support',
          icon: <SupportIcon />,
          label: 'Support',
          target: '/support',
          subitems: [
            {
              key: 'help',
              icon: <HelpIcon />,
              label: 'Help Center',
              target(): void {
                window.open('https://mylivecontrol.zendesk.com/hc/en-us', '_blank')?.focus();
              }
            },
            {
              key: 'contact',
              icon: <ForwardEmailIcon />,
              label: 'Contact Us',
              target: '/support/contact-us'
            }
          ]
        }
      ]),
    [
      accountInfoEntries,
      billing?.stripe,
      contentLibraryEntries,
      me.permissions.admin,
      me.permissions.billing,
      streamSettingsEntries
    ]
  );

  const viewport = Theme.useViewport();
  const { environment } = store();

  return (
    <Sidebar_ descriptors={descriptors} Header={Header}>
      <label
        className='d-flex flex-column align-items-center text-nowrap transition-fade'
        style={{
          bottom: Device.isMobile(viewport) ? 64 : 80
        }}
      >
        <a href='https://airtable.com/appsgbVUNNRNtZf76/shrquPA8KcEDa7YTJ' target='_blank' rel='noreferrer'>
          <img
            style={{ width: 163, padding: '12px 0' }}
            src='assets/learn-more.png'
            alt='Welcome to the future'
          />
        </a>
        <span style={{ color: '#9B9B9B', fontSize: 11 }}>v{environment.VERSION}</span>
      </label>
    </Sidebar_>
  );
};
