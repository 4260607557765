import { immerable } from 'immer';

export class Lazy<V> {
  public [immerable] = true;

  protected _value: V | undefined;

  public constructor(value?: V | undefined) {
    this.setValue(value);
  }

  public get isResolved(): boolean {
    return this.getValue() !== undefined;
  }

  public get value(): V {
    if (!this.isResolved) {
      throw new Error('Value has not been resolved.');
    }

    return this.getValue()!;
  }

  public getValue(): V | undefined {
    return this._value;
  }

  public setValue(value: V | undefined): V | undefined {
    this._value = value;

    return value;
  }
}
