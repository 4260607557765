import { Device, Theme } from '@livecontrol/core-ui';
import cx from 'classnames';
import type { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Panel } from '../../../components';
import type { SubscriptionAccountInfo } from './types';

const Container = styled.div`
  margin-top: var(--spacer-32px);
  padding-bottom: var(--spacer-20px);

  .title div {
    min-width: 160px;
    max-width: 160px;
    text-align: center;
    font-weight: 600;
    font-size: var(--font-size-13px);
    color: var(--tertiary);
  }

  .body div {
    min-width: 160px;
    max-width: 160px;
    text-align: center;
    font-weight: 600;
    font-size: var(--font-size-12px);
    color: var(--dark);
  }

  button {
    margin-left: 50px;
    height: 24px;
    background-color: rgba(46, 91, 255, 0.1);
    padding: 4px 11px 4px 11px;
  }

  .scrollable {
    overflow: auto;
  }

  .mobile {
    width: fit-content;
  }
`;

export const BillingContact = ({
  billingContact
}: {
  billingContact?: SubscriptionAccountInfo.BillingContact;
}): ReactElement => {
  const viewport = Theme.useViewport();

  return (
    <Container>
      <h2 className='text-dark font-size-20px font-weight-bold'>Billing Contact</h2>
      <div className='text-gray-light font-size-13px font-weight-bold'>
        To update the billing contact, please navigate to
        <Link
          to='/account/users/assigned-contacts?role_selected=primary_billing'
          className='ml-4px'
          onClick={(): void => {
            window.scroll(0, 0);
          }}
        >
          Account Info | Users | Assigned Contacts.
        </Link>
      </div>
      <div className={cx('d-flex flex-column mt-28px scrollable')}>
        <div
          className={cx(
            'd-flex flex-row title mt-28px font-size-13 width-title',
            Device.isMobile(viewport) || Device.isTablet(viewport) ? 'width-title' : ''
          )}
        >
          <div className=''> Name </div>
          <div className=''> Email </div>
          <div className=''> Phone </div>
          <div className=''> Contact Method </div>
        </div>

        <Panel
          className={cx(
            'd-flex flex-row body mt-8px pl-0px pr-0px text-center font-size-13 ',
            Device.isMobile(viewport) || Device.isTablet(viewport) ?  'mobile' : ''
          )}
        >
          {billingContact?.id ? (
            <>
              <div className=''> {billingContact.firstName} </div>
              <div className=''> {billingContact.email} </div>
              <div className=''> {billingContact.phoneNumber?.format({parenthetical: true, separator: '-' })} </div>
              <div className='text-capitalize'> {billingContact.preferredContactMethod} </div>
            </>
          ) : (
            <div className='col-lg-12 col-sm-6'>No Billing Contact</div>
          )}
        </Panel>
      </div>
    </Container>
  );
};
