import Faker from 'faker';
import React from 'react';
import Badge from 'react-bootstrap/Badge';
import styled from 'styled-components';
import { Section } from './common';

const H2 = styled.h2`
  padding-top: var(--spacer-32px);
`;

const B = styled(Badge).attrs(() => ({ variant: 'dark' }))`
  margin-right: 12px;
  width: 125px;
`;

const lorem = {
  get sentence(): string {
    return Faker.lorem.sentence(8);
  },
  get paragraph(): string {
    return Faker.lorem.paragraph(4);
  }
};

export const Typography = (): React.ReactElement => (
  <Section name='Typography'>
    <h1>H1 - 24px</h1>
    <h2>H2 - 16px</h2>
    <h3>H3-H6 - Do Not Use</h3>

    <H2>Paragraph</H2>

    <p>{lorem.paragraph}</p>
    <p className='text-secondary'>[text-secondary] {lorem.paragraph}</p>
    <p className='text-tertiary'>[text-tertiary] {lorem.paragraph}</p>

    <H2>Font Weight</H2>

    {['light', 'normal', 'bold'].map((weight: string) => {
      const cssClass = `font-weight-${weight}`;

      return (
        <div key={weight} className='d-flex align-items-center mb-16px'>
          <B>{cssClass}</B> <span className={cssClass}>{lorem.sentence}</span>
        </div>
      );
    })}

    <H2>Font Size</H2>

    <div>
      {[
        '9px',
        '10px',
        '11px',
        '12px',
        '13px',
        '14px',
        '15px',
        '16px',
        '18px',
        '20px',
        '22px',
        '24px',
        '28px',
        '32px'
      ].map((size: string) => {
        const cssClass = `font-size-${size}`;

        return (
          <div key={size} className='d-flex align-items-center mb-16px'>
            <B>{cssClass}</B> <span className={cssClass}>{lorem.sentence}</span>
          </div>
        );
      })}
    </div>
  </Section>
);
