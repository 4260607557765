/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Matrix } from '@livecontrol/scheduler/components';
import type { Account, Location } from '@livecontrol/scheduler/model';
import type { FormikContextType } from 'formik';
import { useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Form } from '../../../../components';
import type { AccountUsersPermission } from './account-permissions';
import type { Values } from './form';

import G = Matrix.Grid;

interface Props {
  subUser: Account.SubUser;
  accountUserPermissionSelected: AccountUsersPermission[];
  location: Location;
  formik: FormikContextType<Values>;
}

const Cell = styled(Matrix.Grid.Row.Cell)`
  border-color: #d5daea;
  justify-content: center;
  padding: 14px 0px;

  &:first-child {
    border-top-left-radius: var(--spacer-4px);
    border-bottom-left-radius: var(--spacer-4px);
  }
  &:last-child {
    border-top-right-radius: var(--spacer-4px);
    border-bottom-right-radius: var(--spacer-4px);
    padding: 14px 0px;
  }
`;

const CellNoBorder = styled(Matrix.Grid.Row.Cell)`
  border: 0px solid transparent;
  justify-content: center;
  padding: 14px 0px;

  &.transparent {
    background-color: transparent !important;
  }

  &.boder-bottom {
    border-bottom: 1px solid #e4e8f0 !important;
  }

  &:last-child {
    padding: 5px 0px !important;
  }

  &:first-child {
    border-left: 0px solid transparent !important;
    position: sticky;
    left: 0;
  }
`;

const Row = styled(Matrix.Grid.Row)`
  position: relative;

  .users {
    position: sticky;
    left: 0;
  }

  img {
    cursor: not-allowed;
  }

  button {
    visibility: hidden;
    display: flex !important;
  }
  &:hover {
    .column {
      border-color: #2e5bff !important;
    }

    button {
      visibility: visible !important;
      display: flex !important;
    }
  }
`;

export const LocationSection = ({
  accountUserPermissionSelected,
  subUser,
  location,
  formik
}: Props): ReactElement => {
  const [rowSelected, setRowSelected] = useState(false);

  useEffect(() => {
    const permissions_ = [false, false, false, false, false, false];

    const selectedPermissionsByUser = accountUserPermissionSelected.filter(
      (permissions) => permissions.userId === `${subUser.id}`
    );

    selectedPermissionsByUser.forEach((permissions) => {
      permissions_[permissions.columnPermission] = true;
    });
  }, [accountUserPermissionSelected, subUser.id]);

  const selectRow = (active: boolean): void => {
    setRowSelected(active);

    formik.setFieldValue(`${location.id}._${subUser.id}.eventManagment`, active);
    formik.setFieldValue(`${location.id}._${subUser.id}.viewAndDownload`, active);
    formik.setFieldValue(`${location.id}._${subUser.id}.contentManagment`, active);
    formik.setFieldValue(`${location.id}._${subUser.id}.activityLog`, active);
    formik.setFieldValue(`${location.id}._${subUser.id}.productionNotes`, active);
  };

  useEffect(() => {
    const { values } = formik;
    const valuesLocations = values[`${location.id}` as keyof typeof values];

    if (valuesLocations) {
      const subUserValue = valuesLocations[`_${subUser.id}` as keyof typeof valuesLocations];

      if (subUserValue) {
        if (subUserValue['eventManagment' as keyof typeof subUserValue]) {
          setRowSelected(true);
        }

        if (subUserValue['viewAndDownload' as keyof typeof subUserValue]) {
          setRowSelected(true);
        }

        if (subUserValue['contentManagment' as keyof typeof subUserValue]) {
          setRowSelected(true);
        }

        if (subUserValue['activityLog' as keyof typeof subUserValue]) {
          setRowSelected(true);
        }

        if (
          !subUserValue['eventManagment' as keyof typeof subUserValue] &&
          !subUserValue['viewAndDownload' as keyof typeof subUserValue] &&
          !subUserValue['contentManagment' as keyof typeof subUserValue] &&
          !subUserValue['activityLog' as keyof typeof subUserValue]
        ) {
          setRowSelected(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  return (
    <>
      <G.Row>
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
      </G.Row>
      <Row>
        <Cell className='column users justify-content-start'>
          <div className='d-flex flex-column pr-4px'>
            <div className='text-dark font-weight-bold font-size-12px'>{location.name}</div>
            {!rowSelected ? (
              <button
                onClick={(): void => {
                  selectRow(true);
                }}
                type='button'
                className='btn p-0 bg-transparent text-primary font-weight-bold font-size-12px'
              >
                Select All
              </button>
            ) : (
              <button
                onClick={(): void => {
                  selectRow(false);
                }}
                type='button'
                className='btn p-0 bg-transparent text-primary font-weight-bold font-size-12px'
              >
                Deselect All
              </button>
            )}
          </div>
        </Cell>
        <Cell className='column' />
        <Cell className='column'>
          <Form.Group
            name={`${location.id}._${subUser.id}.eventManagment`}
            label={false}
            className='mb-0'
            controlProps={{
              type: 'checkbox'
            }}
          />
        </Cell>
        <Cell className='column' />
        <Cell className='column' />
        <Cell className='column'>
          <Form.Group
            name={`${location.id}._${subUser.id}.productionNotes`}
            label={false}
            className='mb-0'
            controlProps={{
              type: 'checkbox'
            }}
          />
        </Cell>
        <Cell className='column' />
        <Cell className='column'>
          <Form.Group
            name={`${location.id}._${subUser.id}.viewAndDownload`}
            label={false}
            className='mb-0'
            controlProps={{
              type: 'checkbox'
            }}
          />
        </Cell>
        <Cell className='column'>
          <Form.Group
            name={`${location.id}._${subUser.id}.contentManagment`}
            label={false}
            className='mb-0'
            controlProps={{
              type: 'checkbox'
            }}
          />
        </Cell>
        <Cell className='column' />
        <Cell className='column'>
          <Form.Group
            name={`${location.id}._${subUser.id}.activityLog`}
            label={false}
            className='mb-0'
            controlProps={{
              type: 'checkbox'
            }}
          />
        </Cell>
      </Row>
    </>
  );
};
