import { Str } from '@livecontrol/core-utils';
import _ from 'lodash';
import nextTick from 'next-tick';
import qs from 'qs';
import type { ReactElement } from 'react';
import { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { NotFound } from '../components';
import { Token, XRef } from '../model';
import { Store } from '../store';

import Storage = Store.Token.Storage;

const AutoLogin = ({
  xref,
  token,
  redirect
}: {
  xref: XRef;
  token: Token;
  redirect?: string;
}): ReactElement => {
  useEffect(
    () => {
      // Logout the current user
      Store.User.logout();

      nextTick(() => {
        const options = { persistent: false };

        // Temporarily login as this admin
        Storage.setItem(Storage.MASQ_KEY, XRef.encode(xref), options);

        Store.User.login(token, options);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <Redirect to={redirect ?? '/home'} />;
};

export const MasqueradeClient = (): ReactElement => {
  const location = useLocation();

  const params = qs.parse(_.trim(location.hash, '?#/'));

  const xref = XRef.decode(params.xref);
  const token = Token.decode(params.token);
  const redirect = Str.normalize(params.redirect);

  // Validate input
  if (!xref || !token) {
    return <NotFound className='h-100 justify-content-center' />;
  }

  return <AutoLogin xref={xref} token={token} redirect={redirect} />;
};
