import cx from 'classnames';
import type { HTMLAttributes, ReactElement } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: inline-block;
  font-size: var(--font-size-13px);

  transition: var(--transition) transform;
  transform-origin: center;

  &.x-expanded {
    transform: rotate(180deg);
  }
`;

export const Chevron = ({ expanded, className, ...props }: Chevron.Props): ReactElement => (
  <Wrapper className={cx(expanded && 'x-expanded', 'cursor-pointer', className)} {...props}>
    <i className='fas fa-chevron-down' />
  </Wrapper>
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Chevron {
  export type Props = HTMLAttributes<HTMLDivElement> & { expanded?: boolean };
}
