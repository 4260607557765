import { CopyCTA, Form } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import { Account } from '@livecontrol/scheduler/model';
import type { Event } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import React, { useEffect, useMemo, useState } from 'react';
import { Store, store } from '../../../../store';

export const EmbeddedEventModal = ({
  show,
  onClose,
  event,
  password
}: {
  show: boolean;
  onClose: () => void;
  event?: Event.Like;
  password?: string;
}): React.ReactElement => {
  const account = Store.Account.useAccount();

  const { webPlayer } = Scheduler.Account.WebPlayer.useView(account);
  const { VENUE_URI } = store.getState().environment;

  const [showPasswordProtectedURL, setShowPasswordProtectedURL] = useState(false);

  const [isCopied, setIsCopied] = useState(false);

  const embedCode = useMemo(() => {
    if (!webPlayer?.endpoint) {
      return '';
    }

    const { endpoint } = webPlayer;

    const webplayerUrl = Account.WebPlayer.makeWebplayerUrl({
      uri: VENUE_URI,
      slug: endpoint
    });

    const eventUrl = Account.WebPlayer.makeWebplayerUrl({
      uri: VENUE_URI,
      slug: endpoint,
      event,
      password: showPasswordProtectedURL ? password : undefined
    });

    return `<script
      type="text/javascript"
      src="${webplayerUrl}/scripts/v1/embed.js">
      </script>
      <script>
        LiveControl.Webplayer.embed({
          source: "${eventUrl}&embed"
        });
      </script>`;
  }, [webPlayer, VENUE_URI, event, showPasswordProtectedURL, password]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (isCopied) {
      timeout = setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }

    return (): void => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isCopied]);

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <h1>Embed Your Player</h1>
      </Modal.Header>
      <Form
        onSubmit={(e): void => {
          e.preventDefault();
        }}
      >
        <Modal.Body className='px-20px'>
          {password && (
            <>
              <Form.Radio
                className='mt-16px'
                selected={!showPasswordProtectedURL}
                onClick={(): void => {
                  setShowPasswordProtectedURL(false);
                }}
                title='Web Player Without Password'
                description='This link will prompt users to enter the password you set'
              />
              <Form.Radio
                className='mt-8px'
                selected={showPasswordProtectedURL}
                onClick={(): void => {
                  setShowPasswordProtectedURL(true);
                }}
                title='Web Player With Password in the URL'
                description='This link contains the password, so users will not have to type in the password'
              />
            </>
          )}

          <Form.Descriptor
            className='mt-20px descriptor-title'
            descriptionClassName='text-secondary'
            title='Copy Embed Code'
            description='Copy and paste the embed code below into your website’s HTML to embed your Web Player'
            titleClassName='font-size-16px mb-12px'
            titleStyle={{ fontWeight: 600 }}
          >
            <Form.Control readOnly as='textarea' style={{ minHeight: 100 }} value={embedCode} />
            <div
              className='text-right mt-8px font-size-12px text-success'
              style={{
                opacity: isCopied ? 1 : 0,
                transition: 'opacity 0.3s'
              }}
            >
              <i className='fal fa-check mr-8px' />
              Copied to Clipboard
            </div>
          </Form.Descriptor>
        </Modal.Body>

        <Modal.Footer className='py-0px'>
          <CopyCTA
            noToast
            onCopy={(): void => {
              setIsCopied(true);
            }}
            value={embedCode}
          >
            <Modal.Button size='sm' variant='outline-primary' style={{ fontWeight: 500 }}>
              <i className='fa fa-link mr-8px' />
              Copy Embed Code
            </Modal.Button>
          </CopyCTA>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
