import type { Destination } from '@livecontrol/scheduler/model';
import type { ReactElement } from 'react';
import styled from 'styled-components';

const DestinationsSection = styled.div`
  margin: 0px 16px 0px 0px;
  width: 85px;
  justify-content: center;
  flex-wrap: wrap;

  .text {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: #2e384d;
  }

  .custom-icon {
    margin-right: 6px;
    color: #2e5bff;
  }

  .destination-entry {
    position: relative;

    .custom-tooltip {
      display: none;
      justify-content: center;
      align-items: center;
      padding: 14px 15px;
      background: #0c3192;
      box-shadow: 0px 16px 34px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      width: auto;
      max-width: 176px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: calc(100% + 10px);
      z-index: 11;
      text-align: center;
      min-width: 120px;

      .text {
        font-size: 11px;
        font-weight: 600;
        color: #ffffff;
        text-transform: initial;
        line-height: 14px;
      }

      .arrow {
        width: 16px;
        height: 8px;
        background: #0c3192;
        clip-path: polygon(100% 0, 0 0, 50% 100%);
        position: absolute;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        bottom: calc(100% + 10px);
        margin-left: auto;
        margin-right: auto;
      }
    }
    &:hover .custom-tooltip {
      display: flex;
      flex-direction: column;
    }
  }
`;

interface Props {
  destinations?: Destination[];
}

export const Destinations = ({ destinations }: Props): ReactElement => (
  <DestinationsSection className='d-flex align-items-center destinations-content'>
    {destinations?.map((aDestination) => {
      let destinationIcon = '';

      switch (aDestination.service) {
        case 1:
          destinationIcon = 'LiveControl';

          return (
            <div
              key={aDestination.id}
              className={`destination-entry custom-icon  ${destinationIcon}`}
            >
              <img
                src='/assets/icons/destinations/LC-black-blue.svg'
                className='w-100'
                alt='Livecontrol'
              />
              {aDestination.name.length ? (
                <div className='custom-tooltip'>
                  <div className='text'>{aDestination.name}</div>
                  <div className='arrow' />
                </div>
              ) : null}
            </div>
          );

          break;

        case 2:
          destinationIcon = 'Facebook';

          return (
            <div
              key={aDestination.id}
              className={`destination-entry custom-icon  ${destinationIcon}`}
            >
              <i className='fab fa-facebook' />
              {aDestination.name.length ? (
                <div className='custom-tooltip'>
                  <div className='text'>Facebook</div>
                  <div className='text'>{aDestination.name}</div>
                  <div className='arrow' />
                </div>
              ) : null}
            </div>
          );

          break;

        case 3:
          destinationIcon = 'YouTube';

          return (
            <div
              key={aDestination.id}
              className={`destination-entry custom-icon  ${destinationIcon}`}
            >
              <i className='fab fa-youtube' />
              {aDestination.name.length ? (
                <div className='custom-tooltip'>
                  <div className='text'>Youtube</div>
                  <div className='text'>{aDestination.name}</div>
                  <div className='arrow' />
                </div>
              ) : null}
            </div>
          );

          break;

        case 4:
          destinationIcon = 'Zoom';

          return (
            <div
              key={aDestination.id}
              className={`destination-entry custom-icon  ${destinationIcon}`}
            >
              <i className='fal fa-signal-stream' />
              {aDestination.name.length ? (
                <div className='custom-tooltip'>
                  <div className='text'>Zoom</div>
                  <div className='text'>{aDestination.name}</div>
                  <div className='arrow' />
                </div>
              ) : null}
            </div>
          );

          break;

        case 5:
          destinationIcon = 'RTMP';

          return (
            <div
              key={aDestination.id}
              className={`destination-entry custom-icon  ${destinationIcon}`}
            >
              <i className='fal fa-signal-stream' />
              {aDestination.name.length ? (
                <div className='custom-tooltip'>
                  <div className='text'>RTMP</div>
                  <div className='text'>{aDestination.name}</div>
                  <div className='arrow' />
                </div>
              ) : null}
            </div>
          );

          break;

        default:
          destinationIcon = 'RTMP';

          return <i className='fal fa-signal-stream' />;

          break;
      }
    })}

    {destinations?.length === 0 || (!destinations &&
      <div
        className="destination-entry custom-icon"
        style={{color: 'black'}}
      >
        <i className='fal fa-eye-slash' />
          <div className='custom-tooltip'>
            <div className='text'>Record Only</div>
            <div className='arrow' />
          </div>
      </div>
    )}
  </DestinationsSection>
);
