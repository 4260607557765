import React from 'react';
import type { ReactElement } from 'react';

interface Props {
  percent: number;
  className?: string;
  style?: React.CSSProperties;
}

export const Progress = ({ percent, className, style }: Props): ReactElement => (
  <div
    style={{ height: 8, ...style, backgroundColor: '#B0BAC9', position: 'relative' }}
    className={className}
  >
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        width: `${percent}%`,
        backgroundColor: '#3491F8'
      }}
    />
  </div>
);
