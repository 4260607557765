import { Glyph, Grid } from '@livecontrol/core-ui';
import type { ReactElement } from 'react';

export const None = (): ReactElement => (
  <Grid className='justify-items-center text-center py-16px'>
    <div className='mb-28px'>
      <Glyph>
        <i className='fal fa-user-headset' />
      </Glyph>
    </div>

    <h1 className='p-0'>Activity Log</h1>

    <p style={{ maxWidth: '40ch' }}>
      If we detect a prolonged network connectivity issue, we can contact the people that you
      specify.
    </p>

  </Grid>
);
