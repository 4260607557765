import type { ApolloCache, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Num, Str } from '@livecontrol/core-utils';
import { Account } from '@livecontrol/scheduler/model';
import { assert } from '@sindresorhus/is';
import { useCallback } from 'react';
import type { MutationResult } from '../graphql';

interface TVariables {
  invoiceId: string;
}

interface TData {
  checkInvoice?: {
    success: boolean;
    error?: string;
    result?: {
      id: number;
      clientId?: number;
      status?: 'deleted' | 'draft' | 'open' | 'paid' | 'uncollectible' | 'void';
      type?: 'one-time' | 'recurring';
      created_at?: Date;
      updated_at?: Date;
      paid_at?: Date;
      stripe_invoice_id: string;
      has_credit_product?: boolean;
      invoice_json?: string;
      one_time_credit?: number;
    };
  };
}

const MUTATION = gql`
  mutation CheckInvoice($invoiceId: String!) {
    checkInvoice(invoiceId: $invoiceId) {
      success
      error
      result {
        id
        clientId
        status
        type
        created_at
        updated_at
        paid_at
        stripe_invoice_id
        has_credit_product
        invoice_json
        one_time_credit
      }
    }
  }
`;

export const useInvoice = (
  currentAmountOfCredits: number,
  creditTypeId: number
): [(invoice: Account.Invoice.Like) => Promise<Account.Invoice>, MutationResult] => {
  const [fn, result] = useMutation<TData, TVariables>(MUTATION);

  return [
    useCallback(
      async (invoice: Account.Invoice.Like): Promise<Account.Invoice> => {
        const normalizedInvoiceId = Account.Invoice.toId(invoice);

        assert.string(normalizedInvoiceId);

        const mutation = await fn({
          variables: {
            invoiceId: normalizedInvoiceId
          },
          update(cache: ApolloCache<unknown>, { data }: FetchResult<TData>): void {
            const invoiceData = data?.checkInvoice;

            if (invoiceData?.result && invoiceData.result.status === 'paid') {
              cache.modify({
                id: `CreditType:${creditTypeId}`,

                fields: {
                  current_amount(): number {
                    return currentAmountOfCredits + invoiceData.result!.one_time_credit!;
                  }
                }
              });
            }
          }
        });

        const { data } = mutation;

        const normalizedInvoice: Account.Invoice = {
          id: Num.normalize(data?.checkInvoice?.result?.id)!,
          clientId: Num.normalize(data?.checkInvoice?.result?.clientId),
          status: Str.normalize(data?.checkInvoice?.result?.status),
          type: Str.normalize(data?.checkInvoice?.result?.type),
          datePaid: Str.normalize(data?.checkInvoice?.result?.paid_at),
          stripeInvoiceId: Str.normalize(data?.checkInvoice?.result?.stripe_invoice_id)!,
          oneTimeCreditAmount: Num.normalize(data?.checkInvoice?.result?.one_time_credit)
        };

        return normalizedInvoice;
      },
      [creditTypeId, currentAmountOfCredits, fn]
    ),
    result
  ];
};
