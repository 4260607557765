import { Modal } from '@livecontrol/scheduler/components';
import type { Location, User } from '@livecontrol/scheduler/model';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { AccountPermissions } from '../user-management';

interface Props {
  me: User;
  locations: Location[] | undefined;
  locationLoading: boolean;
  onClose: () => void;
  show?: boolean;
}

const ModalContent = styled(Modal)`
  .modal-dialog {
    max-width: 1400px !important;
  }
`;

export const ViewPermissions = ({
  me,
  show,
  locations,
  locationLoading,
  onClose
}: Props): ReactElement => (
  <ModalContent
    show={show}
    onHide={(): void => {
      onClose();
    }}
  >
    <Modal.Header className='text-center mb-0' closeButton>
      <img src='assets/icons/key-blue.svg' alt='key blue' />
      <h1 className='font-size-20px font-weight-bold mt-16px mb-16px'>
        View Permissions
      </h1>
      <div className='text-light-gray font-size-12px font-weight-bold mb-20px'>
        To change your permissions, please ask your account admin.
      </div>
    </Modal.Header>
    <AccountPermissions me={me} locations={locations} locationLoading={locationLoading} />

    <div className='d-flex justify-content-center mt-24px'>
      <button
        type='button'
        onClick={onClose}
        className='btn bg-white text-tertiary font-size-14px font-weight-bold'
      >
        {' '}
        Close{' '}
      </button>
    </div>
  </ModalContent>
);
