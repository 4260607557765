import { Modal } from '@livecontrol/scheduler/components';
import { FormikProvider } from 'formik';
import type { ReactElement } from 'react';
import { Form } from '../../../../components';
import type { Props } from './form';
import { useForm } from './form';

export const Editor = (props: Props): ReactElement => {
  const { formik, loading, alert } = useForm(props);
  const { onClose } = props;

  return (
    <Modal show backdrop='static' keyboard={false} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit End Time</Modal.Title>
      </Modal.Header>

      <FormikProvider value={formik}>
        <Form>
          <Modal.Body>
            <Form.Alert show={alert.show} onClose={alert.hide}>
              {alert.message}
            </Form.Alert>

            <Form.Group name='end' label='Specify a new end time' control={Form.Time} />
          </Modal.Body>

          <Modal.Footer>
            <Modal.ButtonBox>
              <Modal.Submit loading={loading}>Save</Modal.Submit>
              <Modal.Button variant='outline-secondary' onClick={onClose}>
                Cancel
              </Modal.Button>
            </Modal.ButtonBox>
          </Modal.Footer>
        </Form>
      </FormikProvider>
    </Modal>
  );
};
