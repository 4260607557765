import { Theme, Tooltip } from '@livecontrol/core-ui';
import { Matrix } from '@livecontrol/scheduler/components';
import type { Location } from '@livecontrol/scheduler/model';
import { useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';

import H = Matrix.Grid.Header;

const TooltipContainer = styled(Tooltip)`
  &.tooltip {
    z-index: 1 !important;
  }

  .tooltip-inner {
    max-width: 140px;
  }
`;

interface Props {
  location: Location;
  errors: number[];
  roleContact: number;
  index: number;
  errorsMessages: string[];
}

export const HeaderLocation = ({
  location,
  errors,
  roleContact,
  index,
  errorsMessages
}: Props): ReactElement => {
  const viewport = Theme.useViewport();

  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    errors
      .sort((a, b) => a - b)
      .forEach((errorIndex, i) => {
        if (errorIndex - 1 === index + 1) {
          setIsError(true);
          setError(errorsMessages[i]);
        }
      });
  }, [errors, errorsMessages, index]);

  return (
    <H style={{minWidth: 141, minHeight: 100}}  className='column header text-center text-dark font-weight-bold' viewport={viewport}>
      {isError ? (
        <TooltipContainer
          show
          backgroundColor='#FF505F'
          tip={
            <div>
              <div className='font-weight-bold font-size-11px'>
                {roleContact === 5 && error === 'itemDuplicated'
                  ? 'Priorities must be unique.'
                  : 'You must choose one contact.'}
              </div>
            </div>
          }
        >
          <div>{location.name}</div>
        </TooltipContainer>
      ) : (
        `${location.name}`
      )}
    </H>
  );
};
