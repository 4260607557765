import { CopyCTA, Form } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import type { Event } from '@livecontrol/scheduler/model';
import { Account } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import React, { useMemo, useState } from 'react';
import { Store, store } from '../../store';

export const ShareLinkModal = ({
  password,
  onHide,
  event,
  showModal
}: {
  showModal: boolean;
  onHide: () => void;
  event?: Event.Like;
  password?: string;
}): React.ReactElement => {
  const account = Store.Account.useAccount();

  const [showPasswordProtectedURL, setShowPasswordProtectedURL] = useState(false);

  const { webPlayer } = Scheduler.Account.WebPlayer.useView(account);
  const { VENUE_URI } = store.getState().environment;

  const venueURL = useMemo((): string => {
    if (!webPlayer?.endpoint) {
      return '';
    }

    const { endpoint } = webPlayer;

    return Account.WebPlayer.makeURL({
      uri: VENUE_URI,
      slug: endpoint,
      event,
      password: showPasswordProtectedURL ? password : undefined
    });
  }, [webPlayer, VENUE_URI, event, showPasswordProtectedURL, password]);

  return (
    <Modal
      show={showModal}
      onHide={(): void => {
        onHide();
      }}
    >
      <Modal.Header closeButton>
        <h1>Web Player Link</h1>
      </Modal.Header>
      <Modal.Body>
        <Form.Descriptor
          title='Web Player Link'
          titleClassName='font-weight-normal'
          descriptionClassName='text-secondary'
          description='Share this link to access your live event'
        >
          {password && (
            <>
              <Form.Radio
                className='mt-16px'
                selected={!showPasswordProtectedURL}
                onClick={(): void => {
                  setShowPasswordProtectedURL(false);
                }}
                title='Web Player Without Password'
                description='This link will prompt users to enter the password you set'
              />
              <Form.Radio
                className='mt-8px'
                selected={showPasswordProtectedURL}
                onClick={(): void => {
                  setShowPasswordProtectedURL(true);
                }}
                title='Web Player With Password in the URL'
                description='This link contains the password, so users will not have to type in the password'
              />
            </>
          )}

          <div className='d-flex mt-12px'>
            <Form.Control value={venueURL} readOnly className='mr-8px' />
            <CopyCTA value={venueURL} toastStyle={{ marginTop: 18 }}>
              <Form.Submit size='md' style={{ minWidth: 150 }}>
                <i className='fa fa-link mr-8px' />
                Copy
              </Form.Submit>
            </CopyCTA>
          </div>
        </Form.Descriptor>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonBox>
          <Modal.Button
            variant='outline-secondary'
            onClick={(): void => {
              onHide();
            }}
          >
            Close
          </Modal.Button>
        </Modal.ButtonBox>
      </Modal.Footer>
    </Modal>
  );
};
