import type { Item, Location } from '@livecontrol/scheduler/model';
import { useState } from 'react';

export const cameraMovementsPreference: Item[] = [
  { identifier: '1', label: 'Videographer Discretion (Recommended)', name: 'camera_movements_preference' },
  { identifier: '2', label: 'Avoid', name: 'camera_movements_preference' }
];

export const audienceShots: Item[] = [
  { identifier: '1', label: 'Videographer Discretion (Recommended)', name: 'audience_shots' },
  { identifier: '2', label: 'Only if “the action” moves into the audience', name: 'audience_shots' },
  { identifier: '3', label: 'Avoid', name: 'audience_shots' }
];

export const closeUpShots: Item[] = [
  { identifier: '1', label: 'Videographer Discretion (Recommended)', name: 'close_up_shots' },
  { identifier: '2', label: 'Avoid', name: 'close_up_shots' }
];

export const wideVenueShots: Item[] = [
  { identifier: '1', label: 'Videographer Discretion (Recommended)', name: 'wide_venue_shots' },
  { identifier: '2', label: 'Avoid', name: 'wide_venue_shots' }
];

export const detailedVenueShots: Item[] = [
  { identifier: '1', label: 'Videographer Discretion (Recommended)', name: 'detailed_venue_shots' },
  { identifier: '2', label: 'Avoid', name: 'detailed_venue_shots' }
];

export const eventEndingPreference: Item[] = [
  { identifier: '1', label: 'Videographer Discretion (Recommended)', name: 'event_ending_preference' },
  { identifier: '2', label: 'Client will end event manually on dashboard', name: 'event_ending_preference' },
  {
    identifier: '3',
    label:
      'Client will speak an audible end cue during your event by including the words "live stream" and a "goodbye phrase"'
      , name: 'event_ending_preference'}
];

interface returnTypes {
  selectedCameraMovementsPreference: Item | undefined;
  selectedAudienceShots: Item | undefined;
  selectedCloseUpShots: Item | undefined;
  selectedWideVenueShots: Item | undefined;
  selectedDetailedVenueShots: Item | undefined;
  selectedEventEnding: Item | undefined;
  setCameraMovementsPreference: React.Dispatch<React.SetStateAction<Item | undefined>>;
  setAudienceShots: React.Dispatch<React.SetStateAction<Item | undefined>>;
  setCloseUpShots: React.Dispatch<React.SetStateAction<Item | undefined>>;
  setWideVenueShots: React.Dispatch<React.SetStateAction<Item | undefined>>;
  setDetailedVenueShots: React.Dispatch<React.SetStateAction<Item | undefined>>;
  setEventEnding: React.Dispatch<React.SetStateAction<Item | undefined>>;
}

export const useProductionNotes = (locations: Location[]): returnTypes => {
  const findLabelOption = (identifier: string, options: Item[]): string => {
    const optionSelected = options.find((option) => option.identifier === identifier);

    return String(optionSelected?.label);
  };

  const haveProductionNote = Object.keys(locations[0].productionNotes).length;

  const [selectedCameraMovementsPreference, setCameraMovementsPreference] = useState<
    Item | undefined
  >(
    haveProductionNote
      ? {
          identifier: String(locations[0].productionNotes.camera_movements_preference),
          label: locations[0].productionNotes.camera_movements_preference ? findLabelOption(
            String(locations[0].productionNotes.camera_movements_preference),
            cameraMovementsPreference
          ) : undefined,
          name: 'camera_movements_preference'
        }
      : undefined
  );

  const [selectedAudienceShots, setAudienceShots] = useState<Item | undefined>(
    haveProductionNote
      ? {
          identifier: String(locations[0].productionNotes.audience_shots),
          label: locations[0].productionNotes.audience_shots ? findLabelOption(String(locations[0].productionNotes.audience_shots), audienceShots) : undefined,
          name: 'audience_shots'
        }
      : undefined
  );

  const [selectedCloseUpShots, setCloseUpShots] = useState<Item | undefined>(
    haveProductionNote
      ? {
          identifier: String(locations[0].productionNotes.close_up_shots),
          label: locations[0].productionNotes.close_up_shots ? findLabelOption(String(locations[0].productionNotes.close_up_shots), closeUpShots) : undefined,
          name: 'close_up_shots'
        }
      : undefined
  );

  const [selectedWideVenueShots, setWideVenueShots] = useState<Item | undefined>(
    haveProductionNote
      ? {
          identifier: String(locations[0].productionNotes.wide_venue_shots),
          label: locations[0].productionNotes.wide_venue_shots ?  findLabelOption(
            String(locations[0].productionNotes.wide_venue_shots),
            wideVenueShots
          ) : undefined,
          name: 'wide_venue_shots'
        }
      : undefined
  );

  const [selectedDetailedVenueShots, setDetailedVenueShots] = useState<Item | undefined>(
    haveProductionNote
      ? {
          identifier: String(locations[0].productionNotes.detailed_venue_shots),
          label: locations[0].productionNotes.detailed_venue_shots ? findLabelOption(
            String(locations[0].productionNotes.detailed_venue_shots),
            detailedVenueShots
          ) : undefined,
          name: 'detailed_venue_shots'
        }
      : undefined
  );

  const [selectedEventEnding, setEventEnding] = useState<Item | undefined>(
    haveProductionNote
      ? {
          identifier: String(locations[0].productionNotes.event_ending_preference),
          label: locations[0].productionNotes.event_ending_preference ? findLabelOption(
            String(locations[0].productionNotes.event_ending_preference),
            eventEndingPreference
          ) : undefined,
          name: 'event_ending_preference'
        }
      : undefined
  );

  return {
    selectedCameraMovementsPreference,
    selectedAudienceShots,
    selectedCloseUpShots,
    selectedWideVenueShots,
    selectedDetailedVenueShots,
    selectedEventEnding,
    setCameraMovementsPreference,
    setAudienceShots,
    setCloseUpShots,
    setWideVenueShots,
    setDetailedVenueShots,
    setEventEnding
  };
};
