import { Viewport } from '@livecontrol/core-ui';
import type { ReactElement } from 'react';
import { Header } from './header';

export const Destinations = ({ viewport, ...props }: Destinations.Props): ReactElement => (
  <Header viewport={viewport} {...props}>
    {viewport >= Viewport.XL ? 'Destination' : 'Dest.'}
  </Header>
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Destinations {
  export type Props = Omit<Header.Props, 'label'> & { viewport: Viewport };
}
