import { Filters } from '@livecontrol/scheduler/components';
import React, { useCallback } from 'react';
import type { ReactElement } from 'react';
import type { Phase } from '../store';
import { useStore } from '../store';

export const Keyword = ({
  phase,
  ...props
}: Filters.Keyword.Props & { phase: Phase }): ReactElement => {
  const { value, update } = useStore(({ model }) => model.value[phase].filters.keyword);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      update(event.target.value);
    },
    [update]
  );

  return (
    <Filters.Keyword
      placeholder='Search'
      onChange={onChange}
      value={value}
      htmlSize={30}
      {...props}
    />
  );
};
