import Faker from 'faker';
import { useCallback, useState } from 'react';
import type { ReactElement } from 'react';
import B_Tab from 'react-bootstrap/Tab';
import B_Tabs from 'react-bootstrap/Tabs';
import { Section } from './common';

const Placeholder = (): ReactElement => (
  <div className='py-8px'>
    <p>{Faker.lorem.paragraphs(4)}</p>
  </div>
);

export const Tabs = (): ReactElement => {
  const [activeTab, setActiveTab] = useState('alpha');

  const onSelect = useCallback(
    (tab: string | null): void => {
      if (tab) {
        setActiveTab(tab);
      }
    },
    [setActiveTab]
  );

  return (
    <Section name='Tabs'>
      <B_Tabs activeKey={activeTab} onSelect={onSelect}>
        <B_Tab eventKey='alpha' title='Alpha'>
          <Placeholder />
        </B_Tab>
        <B_Tab eventKey='beta' title='Beta'>
          <Placeholder />
        </B_Tab>
        <B_Tab eventKey='gama' title='Gamma' disabled>
          <Placeholder />
        </B_Tab>
      </B_Tabs>
    </Section>
  );
};
