import { Button, Device, Form } from '@livecontrol/core-ui';
import { Obj } from '@livecontrol/core-utils';
import type { Account } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { FormikProvider, useFormik } from 'formik';
import _ from 'lodash';
import { useState } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Store } from '../../../store';
import { UrlHelper } from '../common';

const FollowUsContainer = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 34px;
  border-bottom: 1px solid #d5daea;
  padding-top: 24px;

  .text-section {
    min-width: 300px;
    width: 300px;
    margin-bottom: 10px;
    margin-right: 26px;
  }

  form {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .form-section {
    margin-bottom: 10px;
    width: 100%;
    max-width: 584px;

    .form-control {
      width: 100%;
      max-width: 446px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 14px 20px;
      border: solid 1.5px #cbcbcb;
      font-size: 15px;
      background-color: #ffffff;
      min-height: 46px;
    }

    .bottom-form-section {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 14px;
    }
  }

  button[type='button'].btn.btn-primary.btn-lg,
  button[type='submit'].btn.btn-primary {
    min-width: 114px !important;
    padding: 13px 20px;
    margin-left: 0px !important;
    color: #ffffff;
    background-color: #2e5bff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
  }

  .btn-submit-section {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .link-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 16px;

    .link-title {
      width: 100%;
      font-weight: 600;
      font-size: 13px;
      margin-bottom: 10px;
    }

    .link-actions {
      .form-group {
        width: 100%;
        max-width: 446px;
      }

      .link-action-button {
        margin: 11px 0px 11px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        height: 24px;
        width: 24px;
        border: solid 1px #d5daea;
        border-radius: 4px;

        .text {
          color: #0d1438;
          font-size: 12px;
          font-weight: 600;
          margin-left: 8px;
        }
      }
    }
  }

  .icon {
    width: 20px;
    min-width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .edit {
    background-image: url('/assets/icons/Edit-black.svg');
  }

  .delete {
    background-image: url('/assets/icons/Delete-black.svg');
  }

  .x-${Device.TABLET} & {
    flex-direction: column;
    align-items: center;

    .text-section {
      text-align: center;
      margin-right: 0px;
      width: 100%;
    }

    .form-section {
      width: 100%;
      justify-content: center;
    }
  }

  .x-${Device.MOBILE} & {
    .form-section {
      align-items: center;
    }

    .link-actions {
      flex-direction: column;

      .link-action-button {
        margin-top: 10px;
      }
    }
  }
`;

type Values = Account.WebPlayer.FollowUsLinks;

const urlValidation = Yup.string()
  .trim()
  .transform(UrlHelper.cleanUrl)
  .url('Please enter a valid URL.');

const validationSchema = Yup.object({
  facebook: urlValidation,
  instagram: urlValidation,
  youtube: urlValidation,
  website: urlValidation
});

const fields = [
  { name: 'Website', value: '' },
  { name: 'YouTube', value: '' },
  { name: 'Instagram', value: '' },
  { name: 'Facebook', value: '' }
];

export const FollowUsSection = ({ webplayer }: { webplayer: Account.WebPlayer }): ReactElement => {
  const links = webplayer.followUsLinks;
  const account = Store.Account.useAccount();
  const [editFollowuslinks, { loading }] = Scheduler.Account.WebPlayer.useEdit();

  const [saved, setSaved] = useState(false);

  const formik = useFormik<Values>({
    initialValues: links ?? {},
    validateOnMount: true,
    validateOnChange: true,
    validationSchema,

    validate: async (formValues: Values): Promise<Values> => {
      const parsedValues: Values = {};

      Obj.keys(formValues).forEach((key) => {
        const value = formValues[key];

        parsedValues[key] = value ? UrlHelper.cleanUrl(value) : undefined;
      });

      try {
        await validationSchema.validate(parsedValues);

        return {};
      } catch (error: unknown) {
        return error as Values;
      }
    },

    onSubmit(data: Values): void {
      const parsedValues: Values = {};

      // Apply same transformation from validation schema
      Obj.keys(data).forEach((key) => {
        const value = data[key];

        parsedValues[key] = value ? UrlHelper.cleanUrl(value) : '';
      });

      void editFollowuslinks(account.id, {
        followUsLinks: parsedValues
      }).then(() => {
        setSaved(true);

        setTimeout(() => {
          setSaved(false);
        }, 3000);
      });
    }
  });

  const OnDeleteText = (formFieldName: string): void => {
    void formik.setFieldValue(formFieldName, '');
  };

  return (
    <FollowUsContainer className='flex-column flex-lg-row align-items-center align-items-lg-start'>
      <div className='text-section'>
        <div className='title text-dark font-size-15px font-weight-bold'>Follow Us Links</div>
        <p className='description font-size-13px text-secondary font-weight-bold text-gray-light mb-8px'>
          Add links to the sites you want to share with your viewers.
        </p>
      </div>

      <FormikProvider value={formik}>
        <Form className='form-section d-flex align-items-start w-100 flex-column' id='followUsForm'>
          {fields.map(({ name }) => (
            <div className='link-section' key={name}>
              <div className='link-title text-dark font-size-13px font-weight-bold mb-10px'>
                {name}
              </div>
              <div className='link-actions d-flex w-100 flex-row'>
                <Form.Group name={name} label={false} />
                <Button
                  variant='link'
                  className='link-action-button'
                  type='button'
                  onClick={(): void => {
                    OnDeleteText(name);
                  }}
                >
                  <div className='icon delete' />
                </Button>
              </div>
            </div>
          ))}
          <div className='btn-submit-section'>
            <div className='position-relative'>
              <Form.Submit
                loading={loading}
                disabled={!(formik.isValid && formik.dirty)}
                className=''
              >
                Save
              </Form.Submit>
              {saved && (
                <span
                  className='text-success font-weight-bold text-center font-size-12px'
                  style={{ position: 'absolute', left: 0, right: 0, top: 'calc(100% + 4px)' }}
                >
                  <i className='mr-8px fa fa-check' />
                  Saved
                </span>
              )}
            </div>
          </div>
        </Form>
      </FormikProvider>
    </FollowUsContainer>
  );
};
