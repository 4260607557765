import { Str } from '@livecontrol/core-utils';
import _ from 'lodash';
import { DateTime } from 'luxon';
import type { Location, T_Period } from './types';
import { Event, T_Sorting } from './types';

export namespace Utils {
  export const getUniqueLocations = (events: readonly Event[]): Location[] => {
    const locations = new Map<string, Location>();

    events.forEach((event: Event): void => {
      const { location } = event;

      if (!locations.has(location.id)) {
        locations.set(location.id, location);
      }
    });

    return [...locations.values()];
  };

  export const filterByKeyword = (
    events: readonly Event[],
    value: string | undefined
  ): readonly Event[] => {
    const keyword = Str.normalize(value)?.toLowerCase();

    return keyword
      ? events.filter((event: Event): boolean => {
          const thumbprint = [event.id, event.description, event.title, event.operatorNotes]
            .filter((µ) => !!µ)
            .join(' ')
            .toLowerCase();

          return thumbprint.includes(keyword);
        })
      : events;
  };

  export const filterByPeriod = (
    events: readonly Event[],
    value: T_Period | undefined
  ): readonly Event[] => {
    const { min, max } = value ?? {};

    return min && max
      ? events.filter((event: Event): boolean => {
          const start = event.start.toJSDate();

          return start >= min && start <= max;
        })
      : events;
  };

  export const filterByLocation = (
    events: readonly Event[],
    value: Location | Location[] | undefined
  ): readonly Event[] => {
    if (Array.isArray(value)) {
      const eventIds: Event[] = [];

      value.forEach((location) => {
        events.forEach((event) => {
          if (event.location.id === location.id) {
            eventIds.push(event);
          }
        });
      });

      return eventIds;
    }

    return value
      ? events.filter((event: Event): boolean => event.location.id === value.id)
      : events;
  };

  export const sort = (events: readonly Event[], sorting: T_Sorting): readonly Event[] => {
    const { key, direction } = sorting;

    const sorted = _.sortBy([...events], (event): number | string =>
      key === 'title'
        ? event.title.toLowerCase()
        : key === 'location'
        ? event.location.name.toLowerCase()
        : key === 'date'
        ? event.start.toSeconds()
        : key === 'production'
        ? Event.Production[event.production]
        : event.id
    );

    if (direction === T_Sorting.Direction.DESCENDING) {
      sorted.reverse();
    }

    return sorted;
  };

  export const isEditable = (
    event: Event,
    isAdmin: boolean = false,
    isProd: boolean = true
  ): boolean =>
    event.phase === Event.Phase.Upcoming &&
    (!isProd || isAdmin || event.start.diffNow().as('minutes') > 10);

  export const isStoppable = (event: Event): boolean =>
    event.phase === Event.Phase.Live &&
    (event.production === Event.Production.Static ||
      event.production === Event.Production.Mobile ||
      event.production === Event.Production.Test ||
      event.production === Event.Production.Produced ||
      event.production === Event.Production.Client_Booking ||
      event.production === Event.Production.Mobile_Kit);

  export const isExtendable = (event: Event): boolean =>
    isStoppable(event) &&
    (event.production === Event.Production.Static ||
      event.production === Event.Production.Mobile) &&
    event.end > DateTime.utc();
}
