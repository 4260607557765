import { ClickableCard, Grid, Theme, Viewport } from '@livecontrol/core-ui';
import { Nix, Unicode } from '@livecontrol/core-utils';
import { Asset as AssetUI } from '@livecontrol/scheduler/components';
import type { Asset } from '@livecontrol/scheduler/model';
import { DateTime } from 'luxon';
import { useContext, useMemo, useState } from 'react';
import type { ReactElement } from 'react';
import Button from 'react-bootstrap/Button';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import shallow from 'zustand/shallow';
import type { EventContext } from '../../event-store';
import { EventContextStore } from '../../event-store';
import { useStore } from '../store';

const Matrix = styled(Grid.AutoRepeat)`
  > button {
    max-width: 800px;
  }

  a {
    color: var(--dark);
  }
`;

const Timestamp = styled.div`
  font-size: var(--font-size-13px);
  color: var(--secondary);
  line-height: 1.6;
  margin-top: -2px;
  text-align: left;
`;

export const PastStreams = ({ onContinue, onBack }: PastStreams.Props): ReactElement => {
  const { store }: EventContext = useContext<EventContext>(EventContextStore);
  const videoStore = useStore();
  const currentViewport = Theme.useViewport();
  const currentLocation = useLocation();
  const [pagingIndex, setPagingIndex] = useState(0);

  const [selectedVideo, setSelectedVideo] = useState<Asset.MuxUploadedAsset | undefined>(
    !store.simulatedLiveAssetIsUpload ? store.simulatedLiveAssetDetails : undefined
  );

  const [assets, timezone] = videoStore((state) => {
    const m = state.model.value;

    return [m.assets, state.timezone];
  }, shallow);

  const pageSize = useMemo(
    () =>
      currentViewport > Viewport.LG
        ? 10
        : currentViewport > Viewport.MD
        ? 8
        : currentViewport > Viewport.SM
        ? 6
        : 4,
    [currentViewport]
  );

  const assetPage = useMemo(
    () => assets.slice(pagingIndex, pagingIndex + pageSize),
    [assets, pageSize, pagingIndex]
  );

  return (
    <>
      {assets.length ? (
        <>
          <Grid gridTemplateColumns='1fr 1fr'>
            <div className='d-flex flex-justify-content-end p-16px'>
              <Button
                className='btn-round p-8px'
                variant='outline-dark'
                disabled={pagingIndex === 0}
                onClick={(): void => {
                  setPagingIndex(Math.max(0, pagingIndex - pageSize));
                }}
              >
                <i className='fa fa-arrow-left' />
              </Button>
            </div>
            <div className='d-flex flex-justify-content-start p-16px'>
              <Button
                className='btn-round p-8px'
                variant='outline-dark'
                disabled={pagingIndex + pageSize > assets.length}
                onClick={(): void => {
                  setPagingIndex(Math.min(assets.length - 1, pagingIndex + pageSize));
                }}
              >
                <i className='fa fa-arrow-right' />
              </Button>
            </div>
          </Grid>
          <Matrix
            className={`d-grid ${currentViewport > Viewport.SM ? 'gap-32px' : 'gap-8px'}`}
            method='auto-fit'
            min='20rem'
          >
            {assetPage.map((asset) => {
              const { id, title, duration, mux } = asset;
              const timestamp = asset.timestamp.setZone(timezone);

              return mux?.streamUrl ? (
                <ClickableCard.Button
                  key={id}
                  className='p-8px'
                  status={id === selectedVideo?.assetId ? ClickableCard.Status.Active : undefined}
                  onClick={(): void => {
                    setSelectedVideo({
                      assetId: id,
                      muxAssetUrl: mux.streamUrl!,
                      duration
                    });
                  }}
                >
                  <AssetUI.Thumbnail asset={asset} />

                  <div className='d-flex justify-content-start align-items-center mt-16px'>
                    <div className='overflow-hidden'>
                      <AssetUI.Title title={title} />
                    </div>
                  </div>

                  <Timestamp>
                    {timestamp.toLocaleString(DateTime.DATE_FULL)}
                    <br />
                    {timestamp.toLocaleString(DateTime.TIME_SIMPLE)}
                  </Timestamp>
                </ClickableCard.Button>
              ) : undefined;
            })}
          </Matrix>
        </>
      ) : (
        <div className='d-flex flex-column align-items-center w-100 p-16px'>
          <div className='font-size-40px pb-28px'>{Unicode.HAND_WAVING}</div>
          <div className='font-size-20px'>Uh oh, you do not have any streams yet!</div>
        </div>
      )}
      <div className='mt-48px d-flex flex-column align-items-center'>
        <Button
          className='btn-xwide'
          disabled={Nix.isNil(selectedVideo)}
          onClick={(): void => {
            onContinue(selectedVideo!);
          }}
        >
          Continue
        </Button>
        <Link className='mt-32px text-secondary' to={currentLocation.pathname} onClick={onBack}>
          Go Back
        </Link>
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace PastStreams {
  export interface Props {
    onContinue: (assetDetails: Asset.MuxUploadedAsset) => void;
    onBack: () => void;
  }
}
