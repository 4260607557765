import { Calendar, Num } from '@livecontrol/core-utils';
import _ from 'lodash';
import { EventValidator } from './types';

export const validateRepeatingEvent = (
  newEventInfo: EventValidator.EventInfo
): EventValidator.Errors => {
  const errors: EventValidator.Errors = {};
  const { start, repeatSettings } = newEventInfo;

  if (repeatSettings?.repeat) {
    // repeatUntil is required
    if (!repeatSettings.repeatEndCondition || !repeatSettings.repeatUntil) {
      _.merge(errors, {
        repeatSettings: { repeat: EventValidator.ErrorMessages.REPEAT_END_REQUIRED }
      });
    } else if (
      repeatSettings.repeatEndCondition ===
      EventValidator.EventInfo.RepeatEndCondition.AFTER_OCCURRENCES
    ) {
      const numRecurrences = Num.normalize(repeatSettings.repeatUntil.numRecurrences);

      // numRecurrences is required if 'After' is chosen
      if (!numRecurrences || numRecurrences < 1) {
        // No valid end condition provided
        _.merge(errors, {
          repeatSettings: {
            repeatUntil: { numRecurrences: EventValidator.ErrorMessages.NUM_RECURRENCES_INVALID }
          }
        });
      }
    } else {
      const { stopRepeatingOn } = repeatSettings.repeatUntil;

      // A valid end condition is required
      if (
        !stopRepeatingOn ||
        !stopRepeatingOn.isValid ||
        (start?.isValid && stopRepeatingOn < start)
      ) {
        // No valid end condition provided
        _.merge(errors, {
          repeatSettings: {
            repeatUntil: { stopRepeatingOn: EventValidator.ErrorMessages.REPEAT_STOP_DATE_INVALID }
          }
        });
      }
    }

    const repeatEvery = Num.normalize(repeatSettings.repeatFrequency?.repeatEvery);

    // repeatEvery must be a positive integer
    if (!repeatEvery || repeatEvery < 1) {
      _.merge(errors, {
        repeatSettings: {
          repeatFrequency: { repeatEvery: EventValidator.ErrorMessages.REPEAT_FREQUENCY_INVALID }
        }
      });
    }

    const repeatFrequencyUnit = Calendar.Unit.normalize(repeatSettings.repeatFrequency?.unit);
    const daysOfWeek = repeatSettings.repeatFrequency?.daysOfWeek;

    if (repeatFrequencyUnit === Calendar.Unit.WEEKS && (!daysOfWeek || daysOfWeek.size < 1)) {
      // daysOfWeek must be a non-empty set of weekdays
      _.merge(errors, {
        repeatSettings: {
          repeatFrequency: { daysOfWeek: EventValidator.ErrorMessages.REPEAT_WEEKDAY_REQUIRED }
        }
      });
    }
  }

  return errors;
};
