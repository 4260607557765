import { Button, Grid } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import nextTick from 'next-tick';
import { useState } from 'react';
import type { ReactElement } from 'react';
import { Callout, Section } from './common';

export const Modals = (): ReactElement => {
  const prompt = Modal.usePrompt();

  const [showPrompt, setShowPrompt] = useState(false);

  return (
    <Section name='Modals'>
      <h2>Prompts</h2>

      <Callout>
        <code>
          const prompt = Modal.usePrompt();
          <br />
          <br />
          prompt.message(...);
          <br />
          prompt.error(...);
          <br />
          prompt.confirm(...)
          <br />
        </code>
      </Callout>

      <Modal.Prompt
        show={showPrompt}
        onCancel={(): void => {
          setShowPrompt(false);
        }}
        onOkay={(): void => {
          setShowPrompt(false);
        }}
        icon='fal fa-stars'
        title='This is a sample prompt.'
      >
        <p className='text-center'>It does not actually do anything, but it could.</p>
      </Modal.Prompt>

      <Grid.AutoRepeat className='gap-8px' method='auto-fit' min='100'>
        <Button
          onClick={(): void => {
            setShowPrompt(true);
          }}
        >
          Prompt
        </Button>

        <Button
          onClick={(): void => {
            prompt.message('This is just an information message.');
          }}
        >
          Message
        </Button>

        <Button
          onClick={(): void => {
            prompt.error();
          }}
        >
          Error
        </Button>

        <Button
          onClick={(): void => {
            prompt.confirm({
              title: 'Are you sure?',
              content:
                'Are you sure you want to do that thing you just asked me to do?  Sure, you just asked me to do it, but maybe you did not mean it.',
              onAnswer(value: boolean) {
                nextTick(() => {
                  prompt.message({ title: `You answered ${value ? 'yes' : 'no'}.`, icon: false });
                });
              }
            });
          }}
        >
          Confirm
        </Button>
      </Grid.AutoRepeat>
    </Section>
  );
};
