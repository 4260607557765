import classnames from 'classnames';
import type { ReactElement } from 'react';
import { usePagination } from './use-pagination';

interface props {
  onPageChange: (page: number) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  className: string;
}

export const Pagination = ({
  className,
  currentPage,
  onPageChange,
  pageSize,
  siblingCount = 1,
  totalCount
}: props): ReactElement | null => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = (): void => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = (): void => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <nav
      className={classnames('pagination-container mt-16px', { [className]: className })}
      aria-label='Pagination'
    >
      <ul className='pagination d-flex justify-content-center'>
        <li className='page-item mr-4px'>
          <button
            type='button'
            aria-label='Previous'
            className={classnames('btn btn-sm bg-transparent', {
              disabled: currentPage === 1
            })}
            disabled={currentPage === 1}
            onClick={onPrevious}
          >
            <img
              alt='arrow-left'
              style={{ width: 14, height: 11 }}
              src='/assets/icons/left-arrow.svg'
            />
          </button>
        </li>
        {paginationRange.map((pageNumber) => (
          <li className='page-item mx-4px' key={pageNumber}>
            {pageNumber === -1 ? (
              <button type='button' className='btn btn-sm'>
                &#8230;
              </button>
            ) : (
              <button
                type='button'
                className={classnames('btn btn-sm, font-size-12px', {
                  'btn-primary rounded-circle': pageNumber === currentPage,
                  'bg-transparent': pageNumber !== currentPage
                })}
                style={{
                  color: pageNumber === currentPage ? 'white' : '#393A3A',
                  width: '30px',
                  height: '30px',
                  padding: '6px 6px',
                  fontWeight: 400,
                  borderColor: pageNumber === currentPage ? '#2E5BFF' : 'transparent'
                }}
                onClick={(): void => {
                  onPageChange(pageNumber);
                }}
              >
                {pageNumber}
              </button>
            )}
          </li>
        ))}
        <li className='page-item ml-4px'>
          <button
            type='button'
            aria-label='Next'
            className={classnames('btn btn-sm bg-transparent', {
              disabled: currentPage === lastPage
            })}
            disabled={currentPage === lastPage}
            onClick={onNext}
          >
            <img
              alt='arrow-right'
              style={{ width: 14, height: 11 }}
              src='/assets/icons/right-arrow.svg'
            />
          </button>
        </li>
      </ul>
    </nav>
  );
};
