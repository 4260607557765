import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Section } from './common';

const Items = (): React.ReactElement => (
  <>
    <Dropdown.Item key='1' href='#/action-1'>
      Item 1
    </Dropdown.Item>
    <Dropdown.Item key='2' href='#/action-2'>
      Item 2
    </Dropdown.Item>
    <Dropdown.Item key='3' href='#/action-3'>
      Item 3
    </Dropdown.Item>
  </>
);

export const Menus = (): React.ReactElement => (
  <Section name='Menu'>
    <h2>Dropdown Buttons</h2>

    <div className='d-grid justify-items-start gap-16px'>
      <DropdownButton title='Small' size='sm'>
        <Items />
      </DropdownButton>

      <DropdownButton title='Standard' variant='outline-primary btn-tint'>
        <Items />
      </DropdownButton>

      <Dropdown>
        <Dropdown.Toggle variant='dark' size='lg'>
          Dropdown Button
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Items />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  </Section>
);
