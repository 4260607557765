import { Auth, Button, Form } from '@livecontrol/core-ui';
import { Num, Str } from '@livecontrol/core-utils';
import { Modal } from '@livecontrol/scheduler/components';
import qs from 'qs';
import type { ReactElement } from 'react';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Store } from '../../../store';
import { preferenceMap } from '../email-settings';

const Content = (): ReactElement => {
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const email = Str.normalize(params.email);
  const groupId = Num.normalize(params.groupId);
  const categoryText = preferenceMap.find((v) => v.groupId === groupId)?.title;

  const [success, setSuccess] = useState(false);

  const { error } = Modal.usePrompt();

  const [unsubscribe, { loading }] = Store.User.Preferences.Notification.useUnsubscribe();

  const onUnsubscribe = useCallback(
    (e: React.FormEvent<HTMLFormElement>): void => {
      e.preventDefault();

      if (typeof email !== 'string' || typeof groupId !== 'number') {
        error({ content: 'Error.' });

        return;
      }

      void unsubscribe(groupId, email)
        .then(() => {
          setSuccess(true);
        })
        .catch(() => {
          error({
            title: 'Error',
            content: 'Unable to unsubscribe. Please try again.'
          });
        });
    },
    [email, error, groupId, unsubscribe]
  );

  return (
    <Auth.Panel title={!success ? 'Unsubscribe' : 'Unsubscribed'}>
      <div className='text-center'>
        {success ? (
          <p>You&apos;ve successfully unsubscribed from LiveControl {categoryText}.</p>
        ) : (
          <div>
            <p className='text-secondary'>
              You are currently receiving LiveControl {categoryText}.
            </p>
            <p className='font-weight-bold text-secondary'>
              Would you like to unsubscribe from these emails?
            </p>
            <Form onSubmit={onUnsubscribe}>
              <div className='d-flex flex-column flex-sm-row mt-16px'>
                <Link to='/' className='flex-grow-1 d-flex'>
                  <Button size='lg' variant='outline-secondary' className='flex-grow-1'>
                    Cancel
                  </Button>
                </Link>
                <Form.Submit
                  loading={loading}
                  size='lg'
                  className='flex-grow-1 mt-8px mt-sm-0px ml-sm-8px'
                >
                  Unsubscribe
                </Form.Submit>
              </div>
            </Form>
          </div>
        )}
      </div>
    </Auth.Panel>
  );
};

export const Unsubscribe = (): React.ReactElement | null => (
  <Auth.Layout>
    <Content />
  </Auth.Layout>
);
