import { Loading } from '@livecontrol/core-ui';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { ReactElement } from 'react';
import { Redirect } from 'react-router';
import { Store } from '../../../store';
import { Layout } from './layout';

export const Preferences = (): ReactElement => {
  const me = Store.User.useMe();

  if (!me.permissions.webplayer) {
    return <Redirect to='/schedule' />;
  }

  const account = Store.Account.useAccount();
  const { loading, webPlayer } = Scheduler.Account.WebPlayer.useView(account.id);

  return webPlayer && !loading ? <Layout webplayer={webPlayer} /> : <Loading.Delay />;
};
