import { gql, useMutation } from '@apollo/client';
import type { ApolloCache, ApolloError, FetchResult } from '@apollo/client';
import { Obj } from '@livecontrol/core-utils';
import type { Asset, Event } from '@livecontrol/scheduler/model';
import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import { Errors } from '../graphql';
import type { MutationResult } from '../graphql';
import * as Dbx from './dbx';

interface TVariables {
  id: number;
  emitWebsocket: boolean;
  location: string;
  assetIDs: Asset.ID[];
}

interface TData {
  stopEvent?: boolean;
}

const MUTATION = gql`
  mutation StopEvent(
    $id: Float!
    $location: String!
    $emitWebsocket: Boolean!
    $assetIDs: [Float!]!
  ) {
    stopEvent(
      eventToStop: {
        event: $id
        location: $location
        emitWebsocket: $emitWebsocket
        assetIDs: $assetIDs
      }
    )
  }
`;

interface Args {
  event: Event;
}

export const useStop = (): [
  (args: Args) => Promise<boolean>,
  MutationResult<boolean, 'success'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION, {});

  const [error, setError] = useState<Error | undefined>();
  const [success, setSuccess] = useState<boolean>();

  return [
    useCallback(
      async ({ event }: Args): Promise<boolean> => {
        let success_ = false;

        try {
          // Parse the input arguments
          const variables = {
            id: event.id,
            location: event.location.id,
            emitWebsocket: true,
            assetIDs: event.assets?.map((asset) => asset.id) ?? []
          };

          if (!Obj.isHydrated(variables)) {
            throw Errors.badRequest();
          }

          const response = await mutation({
            variables,
            update(cache: ApolloCache<unknown>) {
              cache.modify({
                id: `${Dbx.__typename}:${event.id}`,
                fields: {
                  end_time(): string {
                    return DateTime.utc().toISO();
                  }
                }
              });
            }
          })
            .then(({ data }: FetchResult<TData>) => data?.stopEvent)
            .catch((_error: ApolloError) => {
              throw Errors.serverError();
            });

          success_ = response ?? false;
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setSuccess(success_);

        return success_;
      },
      [mutation, setError]
    ),
    {
      success,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
