import { Theme, Viewport } from '@livecontrol/core-ui';
import type { HTMLAttributes, PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';

type Props = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    centered?: boolean;
  }
>;

// @fixme: Josh - Remove references to --tint
const StyledCell = styled.div<{ viewport: Viewport }>`
  --cell-padding-x: var(--spacer-${({ viewport }): number => (viewport < Viewport.XL ? 8 : 16)}px);

  --matrix-padding-x: var(
    --spacer-${({ viewport }): number => (viewport < Viewport.XL ? 16 : 24)}px
  );

  display: flex;
  align-items: center;
  padding: var(--spacer-32px) var(--cell-padding-x);

  border-width: 1px 0;
  border-style: solid;

  &:first-child {
    border-left-width: 1px;
    padding-left: var(--matrix-padding-x);

    .x-dark-theme & {
      border-top-left-radius: var(--border-radius-lg);
      border-bottom-left-radius: var(--border-radius-lg);
    }
  }

  &:last-child {
    border-right-width: 1px;
    padding-right: var(--matrix-padding-x);

    .x-dark-theme & {
      border-top-right-radius: var(--border-radius-lg);
      border-bottom-right-radius: var(--border-radius-lg);
    }
  }

  .x-light-theme & {
    background-color: var(--white);
    border-color: var(--tint-ø2);
  }

  .x-dark-theme & {
    color: white;
    background-color: var(--white5);
    border-color: var(--white10);
  }
`;

export const Cell = ({ children, ...rest }: Props): ReactElement => {
  const viewport = Theme.useViewport();

  return (
    <StyledCell viewport={viewport} {...rest}>
      {children}
    </StyledCell>
  );
};
