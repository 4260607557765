import type { Token } from '../model';
import { store } from './factory';
import * as Storage_ from './storage';
import type { Store } from './store';
import type { Draft } from './types';

export import Storage = Storage_;

/**
 * React hook that returns the current authentication token.
 *
 * @returns Authentication token or `undefined` if no authentication token has been set.
 */
export const useToken = (): Token | undefined => store((state: Store.State) => state.token);

/**
 * Non-reactive method that returns the authentication token from the store.
 *
 * @returns The authentication token or `undefined` if a token has not been set.
 */
export const getToken = (): Token | undefined => store.getState().token;

/**
 * Sets the specified token in both the store and in local storage.
 *
 * @param token - Authentication token or `undefined` to unset the existing token.
 * @param options - Options
 */
export const setToken = (token: Token | undefined, options?: Storage.Options): void => {
  Storage.setToken(token, options);
  Storage.setZendeskToken(token, options);

  // Update the store
  store.produce((draft: Draft) => {
    draft.token = token;
    draft.zendesk_token = token?.zendesk_token;
    draft.me = undefined; // Will get reinitialized by the store reactions
    draft.thee = undefined;
    draft.account = undefined;
  });
};
