import type { HTMLAttributes, PropsWithChildren } from 'react';
import React, { useState } from 'react';
import styled from 'styled-components';

/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 5px;
    cursor: pointer;
    .text {
      color: #2e384d;
      font-size: 13px;
      font-weight: 600;
    }
    .icon {
    }
  }
  .content {
    width: 100%;
    display: none;
    .divider {
      width: 100%;
      height: 1px;
      background-color: #e4e8f0;
      margin: 10px 0px 12px;
    }
  }
  .content.active {
    display: block;
  }
`;

type Props = HTMLAttributes<HTMLDivElement> &
  PropsWithChildren<{
    title: string;
  }>;

export const AccordionSection = ({ title, children, ...props }: Props): React.ReactElement => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <AccordionContainer {...props}>
      <div
        className='header'
        onClick={(): void => {
          setIsExpanded(!isExpanded);
        }}
      >
        <div className='text'>{title}</div>
        <div className='icon'>
          {isExpanded ? <i className='fa fa-angle-down' /> : <i className='fa fa-angle-up' />}
        </div>
      </div>
      <div className={`content ${isExpanded ? 'active' : ''}`}>
        <div className='divider' />
        {children}
      </div>
    </AccordionContainer>
  );
};
