import { Obj, Str } from '@livecontrol/core-utils';

export interface EventFeedback {
    cameraFrameIssue: boolean;
    cameraMovementIssue: boolean;
    graphicsAndScreenSharingIssue: boolean;
    lightingAndExposureIssue: boolean;
    liveStreamQualityIssue: boolean;
    soundQualityIssue: boolean;
    technicalExtraComment: string;
    videographerAttentionIssue: boolean;
    videographerExtraComment: string;
    videographerThumb: boolean;
    technicalThumb: boolean;
    videographerMisunderstandingIssue: boolean;
    videographerNotesIssue: boolean;
    id: number;
}

export namespace EventFeedback {
  export type ID = string;
  export type Like = EventFeedback | ID | string;

  export const toId = (feedback?: Like): ID | undefined =>
    Str.normalize(Obj.isObject<EventFeedback>(feedback) ? feedback.id : feedback);
}
