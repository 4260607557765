import { Num, Str, Time } from '@livecontrol/core-utils';
import { assert } from '@sindresorhus/is';
import EventEmitter from 'eventemitter3';
import pEvent from 'p-event';
import pTimeout from 'p-timeout';

class Item<V = never> extends EventEmitter<'reject' | 'trigger'> {
  public timestamp: Time.Encoding.EPOCH_MILLIS;
  public readonly key: Awaiter.Key;

  private _value: V | undefined;
  private _triggered: boolean;

  public constructor(key: Awaiter.Key) {
    super();

    this.key = key;
    this.timestamp = Time.now().epochMillis;

    this._triggered = false;
    this._value = undefined;
  }

  public get isTriggered(): boolean {
    return this._triggered;
  }

  public trigger(value: V): void {
    this.timestamp = Time.now().epochMillis;
    this._value = value;
    this._triggered = true;

    this.emit('trigger', value);
  }

  public reject(error: Error): void {
    this.emit('reject', error);
  }

  public get value(): V | undefined {
    return this._value;
  }
}

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export class Awaiter<V = void> {
  private readonly ttl: Time.Encoding.MILLISECONDS | false;
  private readonly cache = new Map<Awaiter.Key, Item<V>>();

  public constructor(args?: Awaiter.ConstructorArgs) {
    this.ttl = ((): Time.Encoding.MILLISECONDS | false => {
      let ttl: Time.Encoding.MILLISECONDS | false = false;

      if (args?.ttl !== false) {
        ttl = Num.toInteger(args?.ttl, { positive: true, nonZero: true }) ?? 36e5;
      }

      return ttl;
    })();

    if (this.ttl) {
      setInterval(() => {
        this.prune();
      }, this.ttl);
    }
  }

  /**
   * Wait for the specified key to be triggered. If the specified key has already been
   * triggered, this method resolves immediately. Otherwise, the method waits for the key to be
   * triggered via a subsequent call to the `trigger` method.
   *
   * If the awaited key is not triggered within the specified timeout (default 60 seconds),
   * the promise is rejected.
   *
   * @param key - The key/identifier to be awaited.
   * @param options - Awaiting options.
   * @returns `true` if the specified key was triggered before the timeout, `false` otherwise.
   */
  public async waitFor(key: Awaiter.Key, options?: Awaiter.WaitForOptions): Promise<V> {
    const item = this.ensure(key);

    // Has this item already been triggered?
    return !item.isTriggered
      ? // Wait for the item to be triggered or rejected
        pTimeout(
          pEvent(item, 'trigger', { rejectionEvents: ['reject'] }),
          Num.normalize(options?.timeout, { positive: true, nonZero: true }) ?? 15e3,
          new pTimeout.TimeoutError(`Timed out waiting for \`${key}\` to be triggered.`)
        )
      : // Return the previous trigger value
        item.value;
  }

  public trigger(key: Awaiter.Key, value: V): void {
    this.ensure(key).trigger(value);
  }

  public reject(key: Awaiter.Key, error: Error): void {
    this.ensure(key).reject(error);
  }

  private ensure(key: Awaiter.Key): Item<V> {
    const { cache } = this;

    const k = Str.normalize(key)?.toLowerCase();

    assert.string(k);

    let item = cache.get(key);

    if (!item) {
      item = new Item(key);

      cache.set(k, item);
    }

    return item;
  }

  private prune(): void {
    const { cache, ttl } = this;

    assert.number(ttl);

    const now = Time.now();

    const toBePurged = [...cache.entries()].filter(
      ([, item]: [Awaiter.Key, Item<V>]) => item.timestamp <= now.epochMillis - ttl
    );

    toBePurged.forEach(([key]: [Awaiter.Key, Item<V>]) => cache.delete(key));
  }

  // @private - testing only
  public __has(key: Awaiter.Key): boolean {
    return this.cache.has(key);
  }
}

export namespace Awaiter {
  export type Key = string;

  export interface ConstructorArgs {
    /** Amount of time (in ms) to keep awaited items in the cache. (default: 60 minutes) */
    ttl?: Time.Encoding.MILLISECONDS | false;
  }

  export interface WaitForOptions {
    /** Amount of time (in ms) to wait for an item to be triggered (default: 15 seconds) */
    timeout?: Time.Encoding.MILLISECONDS;
  }
}
