import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Obj } from '@livecontrol/core-utils';
import type { ContactValidator } from '@livecontrol/scheduler/validator';
import { useEffect, useState } from 'react';
import type { QueryResult } from '../graphql';

export const QUERY = gql`
  query GetContactRoles($input: GetContactRolesInput!) {
    getContactRoles(input: $input) {
      id
      user {
        id
        first_name
        last_name
        email
        phone_number
      }
    }
  }
`;

interface TVariables {
  input: {
    client_id?: number;
    location_id?: string;
    onboarding_main?: boolean;
    onboarding_technical?: boolean;
    shipping?: boolean;
  };
}

interface TData {
  getContactRoles: [
    {
      id: number;
      user: { id: number; email: string; first_name: string; last_name: string; phone_number: string };
    }
  ];
}

interface Args {
  clientId?: number;
  locationId?: string;
  onboardingMain?: boolean;
  onboardingTechnical?: boolean;
  shipping?: boolean;
}

export const useGetContacts = (args: Args): QueryResult<ContactValidator.Contact[], 'contacts'> => {
  const [error, setError] = useState<Error | undefined>();
  const [contacts, setContacts] = useState<ContactValidator.Contact[] | undefined>();

  /* eslint-disable react-hooks/rules-of-hooks */

  const variables = {
    input: {
      client_id: args.clientId ?? undefined,
      location_id: args.locationId ?? undefined,
      onboarding_main: args.onboardingMain ?? undefined,
      onboarding_technical: args.onboardingTechnical ?? undefined,
      shipping: args.shipping ?? undefined
    }
  };

  // Validate the input
  if (!Obj.isHydrated(variables)) {
    return { loading: false, error: Exception.KABOOM };
  }

  // Prepare the query
  const { loading, data } = useQuery<TData, TVariables>(QUERY, {
    variables,
    onError() {
      setError(Exception.KABOOM);
    }
  });

  // When available, parse server response
  useEffect(() => {
    const contacts_: ContactValidator.Contact[] = [];
    let error_;

    if (data) {
      data.getContactRoles.forEach((element) => {
        const aContact: ContactValidator.Contact = {
          id: element.user.id,
          name: `${element.user.first_name} ${element.user.last_name}`,
          email: element.user.email,
          phoneNumber: element.user.phone_number
        };

        contacts_.push(aContact);
      });
    }

    setContacts(contacts_);
    setError(error_);
  }, [data]);

  return { loading, error, contacts };
};
