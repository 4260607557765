import { Device, Theme } from '@livecontrol/core-ui';
import type { Log } from '@livecontrol/scheduler/model';
import React, { useMemo, useState } from 'react';
import { Store } from '../../../../store';
// eslint-disable-next-line import/no-internal-modules
import { Pagination } from '../pagination/pagination';
import { Grid } from './grid';
import { Stack } from './stack';

interface Props {
  logs: Log[];
}

const pageSize = 15;

export const Logs = ({ logs }: Props): React.ReactElement => {
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState(1);

  const { timezone } = Store.Account.useAccount();

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;

    return logs.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, logs]);

  if (firstRender) {
    setFirstRender(false);
  }

  const viewport = Theme.useViewport();

  return (
    <>
      {Device.isMobile(viewport) ? (
        <Stack logs={currentTableData} timezone={timezone} />
      ) : (
        <Grid logs={currentTableData} timezone={timezone} />
      )}

      <Pagination
        className='pagination-bar'
        currentPage={currentPage}
        totalCount={logs.length}
        pageSize={pageSize}
        onPageChange={(page: number): void => {
          setCurrentPage(page);
        }}
      />
    </>
  );
};
