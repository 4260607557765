import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Obj } from '@livecontrol/core-utils';
import { Account, Asset } from '@livecontrol/scheduler/model';
import { useEffect, useState } from 'react';
import type { QueryResult } from '../graphql';
import * as Dbx from './dbx';

interface TVariables {
  asset: number;
  account: number;
}

interface TData {
  getAsset?: Dbx.Record;
}

export const QUERY = gql`
  query GetAssets($asset: Int!, $account: Int!) {
    getAsset(assetId: $asset, clientId: $account) {
      ...FullAssetResponse
    }
  }
  ${Dbx.FullAssetResponse}
`;

interface Args {
  asset: Asset.Like;
  account: Account.Like;
}

export const useAsset = (args: Args): QueryResult<Asset, 'asset'> => {
  const [error, setError] = useState<Error | undefined>();
  const [asset, setAsset] = useState<Asset | undefined>();

  // Parse the input arguments
  const variables = {
    asset: Asset.toId(args.asset),
    account: Account.toId(args.account)
  };

  // Validate the input
  if (!Obj.isHydrated(variables)) {
    return { loading: false, error: Exception.KABOOM };
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  const { loading, data } = useQuery<TData, TVariables>(QUERY, {
    variables,
    onError() {
      setError(Exception.KABOOM);
    }
  });

  // When available, parse server response
  useEffect(() => {
    let asset_;
    let error_;

    if (data) {
      asset_ = Dbx.normalize(data.getAsset);

      if (!asset_) {
        error_ = Exception.NOT_FOUND;
      }
    }

    setAsset(asset_);
    setError(error_);
  }, [data]);

  return { loading, error, asset };
};
