import { gql } from '@apollo/client';

/* Stuff below here is OLD */

export const CreateUser = gql`
  mutation CreateUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $passwordConfirmation: String!
    $role: ID!
    $phoneNumber: String!
    $timeZone: String!
    $organizationName: String!
    $organizationZipCode: String! # $industry: String!
  ) {
    createUser(
      input: {
        first_name: $firstName
        last_name: $lastName
        email: $email
        password: $password
        passwordConfirmation: $passwordConfirmation
        role: $role
        phone_number: $phoneNumber
        time_zone: $timeZone
        organization_name: $organizationName
        organization_zip_code: $organizationZipCode
        industry: 1
      }
    ) {
      token
    }
  }
`;

export const EditUser = gql`
  mutation UpdateUser(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $role: Float
    $phoneNumber: String!
    $timeZone: String
    $organzationName: String
    $organzationAddress: String
  ) {
    updateUser(
      input: {
        id: $id
        first_name: $firstName
        last_name: $lastName
        role: $role
        phone_number: $phoneNumber
        time_zone: $timeZone
        organization_name: $organzationName
        organization_address: $organzationAddress
        industry: 1
      }
    ) {
      id
      first_name
      last_name
      role {
        id
        role
      }
      contacts {
        id
        phone_number
      }
    }
  }
`;

export const GetUserSummary = gql`
  query GetUserSummary($id: Float!) {
    getUser(input: { id: $id }) {
      id
      status {
        id
      }
      first_name
      last_name
      email
      time_zone
      role {
        id
      }
      lead_time
      credit {
        current_amount
      }
    }
  }
`;

export const GetUser = gql`
  query GetUser($id: Float!) {
    getUser(input: { id: $id }) {
      # subscription_status
      profile_picture
      status {
        id
        status
      }
      id
      stripeBillingPortal
      first_name
      last_name
      email
      payment_token
      phone_number
      time_zone
      created_at
      organization_name
      organization_address
      lead_time
      updated_at
      status {
        id
      }
      role {
        id
        role
      }
      contacts {
        id
        first_name
        last_name
        phone_number
        address
        created_at
        updated_at
        email
      }
      credit {
        current_amount
      }
      locations {
        id
        name
        events {
          status

          id
          name
          start_time
          end_time
          is_operated
          stream_youtube
          stream_facebook
          integrations {
            integration
            type
          }
          record_only
          location {
            name
            id
          }
        }
      }
    }
  }
`;

export const GetUsers = gql`
  query GetUsers($role: ID!) {
    getUsers(input: { role: $role }) {
      # subscription_status
      status {
        id
        status
      }
      id
      first_name
      last_name
      email
      # payment_token
      phone_number
      time_zone
      created_at
      updated_at
      locations {
        id
        name
      }
      role {
        id
        role
      }
      contacts {
        id
        phone_number
        created_at
        updated_at
        email
      }
      locations {
        id
        name
        events {
          status
          id
          name
          start_time
          end_time
          is_operated
          stream_youtube
          stream_facebook
          record_only
          location {
            name
            id
            mux_stream_id
            user {
              id
            }
          }
        }
      }
    }
  }
`;

export const ChangePassword = gql`
  mutation UpdatePassword(
    $id: ID!
    $oldPassword: String!
    $newPassword: String!
    $passwordConfirmation: String!
  ) {
    updatePassword(
      input: {
        id: $id
        oldPassword: $oldPassword
        newPassword: $newPassword
        passwordConfirmation: $passwordConfirmation
      }
    ) {
      id
    }
  }
`;

export const ResetPassword = gql`
  mutation ResetPassword($token: String!, $newPassword: String!, $passwordConfirmation: String!) {
    resetPassword(
      input: {
        newPassword: $newPassword
        newPasswordConfirmation: $passwordConfirmation
        token: $token
      }
    ) {
      id
    }
  }
`;

export const RequestResetPassword = gql`
  mutation RequestResetPassword($email: String!) {
    requestResetPassword(input: { email: $email }) {
      id
    }
  }
`;

export const VerifyEmail = gql`
  mutation VerifyEmail($email: String!, $token: String!) {
    verifyEmail(input: { email: $email, token: $token }) {
      id
    }
  }
`;

export const GetUserProfile = gql`
  query GetUserProfile($id: Float!) {
    getUser(input: { id: $id }) {
      id
      email
      first_name
      profile_picture
      last_name
      phone_number
      time_zone
      created_at
      updated_at
      organization_name
      organization_zip_code
      organization_address
      notes
      status {
        id
        status
      }
      role {
        id
        role
      }
      contacts {
        id
        phone_number
        email
        first_name
        last_name
      }
      industry {
        id
        industry
      }
    }
  }
`;

export const GetUserLocations = gql`
  query GetUserLocations($id: Float!) {
    getLocation(location: { user: $id }) {
      id
      name
      zoom
      mux_stream_id
      mux_stream_key
      mux_stream_url
    }
  }
`;

export const UpdateUserStatus = gql`
  mutation UpdateUser($id: ID!, $status: Float) {
    updateUser(input: { id: $id, status: $status }) {
      id
      status {
        id
      }
    }
  }
`;

export const GetUserPaymentToken = gql`
  query GetUserToken($id: Float!) {
    getUser(input: { id: $id }) {
      id
      payment_token
    }
  }
`;

export const UpdateUserProfile = gql`
  mutation UpdateUser(
    $id: ID!
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $email: String
    $organization_name: String
    $organization_zip_code: String
    $organization_address: String
    $notes: String
    $timeZone: String
  ) {
    updateUser(
      input: {
        id: $id
        first_name: $firstName
        last_name: $lastName
        phone_number: $phoneNumber
        email: $email
        organization_name: $organization_name
        organization_zip_code: $organization_zip_code
        organization_address: $organization_address
        notes: $notes
        time_zone: $timeZone
      }
    ) {
      id
      first_name
      last_name
      email
      phone_number
      organization_name
      organization_zip_code
      contacts {
        id
        phone_number
        email
        first_name
        last_name
      }
    }
  }
`;

export const UpdateUserContact = gql`
  mutation UpdateContact(
    $id: ID!
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $address: String
    $email: String
  ) {
    updateContact(
      input: {
        id: $id
        first_name: $firstName
        last_name: $lastName
        phone_number: $phoneNumber
        email: $email
        address: $address
      }
    ) {
      id
      first_name
      last_name
      email
      phone_number
    }
  }
`;

export const CreateUserContact = gql`
  mutation CreateContact(
    $user: Float!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $email: String!
    $address: String!
    $phoneType: String!
  ) {
    createContact(
      input: {
        user: $user
        first_name: $firstName
        last_name: $lastName
        phone_number: $phoneNumber
        email: $email
        address: $address
        phone_type: $phoneType
      }
    ) {
      id
      first_name
      last_name
      email
      phone_number
      address
      phone_type
    }
  }
`;

export const GetUserContact = gql`
  query getContact($contact: Float!) {
    getContact(input: { contactId: $contactId }) {
      id
      first_name
      last_name
      email
      phone_number
      address
      location{
        id
        name
      }
    }
  }
`;

export const DeleteUserContact = gql`
  mutation DeleteContact($contact: Float!) {
    deleteContact(input: { contactId: $contact }) {
      id
    }
  }
`;
