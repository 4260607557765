import { App } from '@livecontrol/customer-dashboard/frontend';
import { enableMapSet } from 'immer';
import ReactDOM from 'react-dom';
import { environment } from './env';

// Configure immer
enableMapSet();

// Render the application
ReactDOM.render(<App environment={environment} />, document.querySelector('#root'));
