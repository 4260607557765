import cx from 'classnames';
import type { HTMLAttributes, ReactElement } from 'react';
import type { Phase } from '../store';
import { useStore } from '../store';

type Props = HTMLAttributes<HTMLDivElement> & {
  phase: Phase;
};

export const NoMatch = ({ className, phase, ...props }: Props): ReactElement => {
  const reset = useStore(({ model }) => model.value[phase].filters.reset);

  return (
    <div
      className={cx('d-flex', 'justify-content-center', 'align-items-center', className)}
      {...props}
    >
      <div className='alert alert-secondary w-100 text-center font-size-15x'>
        No events match your filter criteria. Click{' '}
        <button type='button' className='x-pseudo text-primary' onClick={reset}>
          here
        </button>{' '}
        to reset your criteria.
      </div>
    </div>
  );
};
