import type { Account } from '@livecontrol/scheduler/model';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { InvoicesList } from '../components';
import type { SubscriptionAccountInfo } from './types';

const Container = styled.div`
  margin-top: var(--spacer-32px);

  .border-top {
    border-top: 1px solid #e4e8f0 !important;
  }
`;

export const Invoices = ({
  invoices,
  account
}: {
  invoices: SubscriptionAccountInfo.Invoice[];
  account: Account;
}): ReactElement => (
  <Container>
    <h2 className='text-dark font-size-20px font-weight-bold'>Invoices</h2>
    <div className='ml-4px position-relative'>
      <div className='mt-28px mb-8px font-size-13px font-weight-bold'> INVOICE HISTORY </div>
      <InvoicesList invoices={invoices} account={account} />
    </div>
  </Container>
);
