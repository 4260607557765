import { Device, Theme } from '@livecontrol/core-ui';
import type { Asset } from '@livecontrol/scheduler/model';
import { point } from '@turf/helpers';
import { scaleLinear } from 'd3-scale';
import _ from 'lodash';
import mapboxgl from 'mapbox-gl';
import type { ReactElement } from 'react';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { store } from '../../../store';
import { AccordionSection, Loaders, Progress } from './components';

// eslint-disable-next-line import/no-internal-modules
import 'mapbox-gl/dist/mapbox-gl.css';

interface Props {
  locations: { name: string; views: number; percent: number }[];
  className?: string;
}

const TopLocations = ({ locations, className }: Props): ReactElement => (
  <div className={className}>
    {locations.map((location) => (
      <div key={location.name} className='mt-12px d-flex'>
        <div className='flex-grow-1 text-tertiary font-size-12px'>{location.name}</div>
        <div className='d-flex justify-content-end align-items-center'>
          <div className='mr-16px font-size-12px'>{location.views}</div>
          <Progress
            percent={location.percent}
            style={{
              height: 5,
              minWidth: 60
            }}
          />
        </div>
      </div>
    ))}
  </div>
);

const StyledLocations = styled.div``;

export const LocationsSection = ({
  analytics,
  loading
}: {
  analytics?: Asset.Analytics.WebPlayer;
  loading: boolean;
}): ReactElement => {
  const ref = useRef<HTMLDivElement | null>(null);
  const viewport = Theme.useViewport();
  const { environment } = store.getState();
  const { locations } = analytics ?? {};

  useEffect(() => {
    if (!ref.current || !locations?.length) {
      return;
    }

    const min = _.minBy(locations, 'views')?.views;
    const max = _.maxBy(locations, 'views')?.views;

    const scale = scaleLinear()
      .domain([min ?? 0, max ?? 0])
      .range([5, 15]);

    const points = locations.map(({ views, coordinates }) =>
      point([coordinates.longitude, coordinates.latitude], {
        radius: scale(views)
      })
    );

    const bounds = new mapboxgl.LngLatBounds();

    locations.forEach(({ coordinates }) => {
      bounds.extend([coordinates.longitude, coordinates.latitude]);
    });

    mapboxgl.accessToken = environment.MAPBOX_ACCESS_TOKEN;

    const map = new mapboxgl.Map({
      container: ref.current,
      style: 'mapbox://styles/livecontrol/cklwf330c5alt17qnicb3v7r7', // style URL
      bounds,
      fitBoundsOptions: {
        padding: 20,
        maxZoom: 8
      },
      minZoom: 0,
      attributionControl: false
    });

    map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'top-right');

    map.on('load', () => {
      map.addSource('circleData', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: points
        }
      });

      // add a layer that displays the data
      map.addLayer({
        id: 'data',
        type: 'circle',
        source: 'circleData',
        paint: {
          'circle-color': 'rgba(52,145,248,0.9)',
          'circle-radius': ['to-number', ['get', 'radius']],
          'circle-stroke-width': 10,
          'circle-stroke-color': 'rgba(52,145,248,0.3)'
        }
      });
    });
  }, [locations, environment]);

  return (
    <AccordionSection title='TOP LOCATIONS'>
      {loading ? (
        <Loaders.Locations isTablet={Device.isTablet(viewport)} />
      ) : locations?.length ? (
        <StyledLocations>
          <div ref={ref} className='mapbox-map' style={{ height: 200 }} />

          <TopLocations
            className='mt-24px top-locations'
            locations={locations.map(({ name, views }) => ({
              name,
              views,
              percent: scaleLinear()
                .domain([0, locations.reduce((sum, v) => sum + v.views, 0)])
                .range([0, 100])(views)
            }))}
          />
        </StyledLocations>
      ) : null}
    </AccordionSection>
  );
};
