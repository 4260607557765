import { Num } from '@livecontrol/core-utils';
import cx from 'classnames';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import type { Control } from './control';
import { Utils } from './utils';

interface RatingStarProps {
  className?: string;
  active?: boolean;
  onChange?: (value: boolean) => void;
}

const StyledStar = styled.span`
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

const RatingStar = ({ className, active, onChange }: RatingStarProps): React.ReactElement => (
  <StyledStar
    key={active ? 'active' : 'inactive'}
    className={className}
    onClick={(): void => {
      onChange?.(!active);
    }}
  >
    <i className={cx({ far: !active, fas: active }, 'fa-star', 'text-primary font-size-24px')} />
  </StyledStar>
);

export const Rating = ({ scale = 5, className, name }: Rating.Props): React.ReactElement => {
  const field = Utils.useField(name);

  const onRatingSelect = useCallback(
    (rating: number) => (): void => {
      field?.helpers.setValue(rating + 1);
    },
    [field?.helpers]
  );

  const value = Num.normalize(field?.meta.value);

  const ratingsUI = useMemo(
    () =>
      // eslint-disable-next-line lodash/prefer-lodash-chain
      _.range(scale).map((v) => (
        <RatingStar
          className={cx({ 'ml-8px': v !== 0 })}
          key={v}
          active={value ? v < value : false}
          onChange={onRatingSelect(v)}
        />
      )),
    [onRatingSelect, scale, value]
  );

  return <label className={className}>{ratingsUI}</label>;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Rating {
  export type Props = Omit<Control.Props, 'as'> & {
    scale?: number;
    onChange?: (value: number) => void;
    className?: string;
    name: string;
  };
}
