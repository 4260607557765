import type { Account } from '@livecontrol/scheduler/model';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports, lodash/import-scope
import _ from 'lodash';
import { validateEventConflicts } from './conflict-validator';
import { validateCreditAvailability } from './credit-validator';
import { validateCuesheetURL } from './cuesheets-validator';
import { validateEventBackground } from './event-background-validator';
import { validateMaxRecurrences } from './max-recurrence-validator';
import { validateEventPassword } from './password-validator';
import { getAllRecurrences } from './recurrence-calculator';
import { validateEventRedirect } from './redirect-validator';
import type { EventValidator } from './types';
import { validateZoomDetails } from './zoom-validator';

export const validateNewEventStep4 = (
  {
    availableCredits,
    timezone,
    events,
    newEventInfo,
    simulatedLiveAssetIsUpload,
    cueSheetURL,
    isAdmin,
    isProd = true
  }: EventValidator.NewEventProps,
  billing?: Account.Billing
): EventValidator.Errors => {
  const errors: EventValidator.Errors = {
    ...validateEventBackground(newEventInfo),
    ...validateZoomDetails(newEventInfo),
    ...validateEventPassword(newEventInfo),
    ...validateEventRedirect(newEventInfo),
    ...(cueSheetURL && validateCuesheetURL(cueSheetURL))
  };

  const { recurrences, errors: repeatingEventErrors } = getAllRecurrences({
    newEventInfo,
    simulatedLiveAssetIsUpload,
    isAdmin,
    isProd
  });

  if (repeatingEventErrors) {
    _.merge(errors, repeatingEventErrors);
  } else if (recurrences?.length) {
    _.merge(errors, validateMaxRecurrences(newEventInfo, recurrences));

    _.merge(
      errors,
      validateCreditAvailability(availableCredits, newEventInfo, recurrences, billing)
    );
  }

  if (_.isEmpty(errors.date)) {
    _.merge(
      errors,
      validateEventConflicts({
        newEventInfo,
        simulatedLiveAssetIsUpload,
        events,
        timezone,
        recurrences,
        isAdmin,
        isProd
      })
    );
  }

  return errors;
};
