import { Bool, Obj } from '@livecontrol/core-utils';

export interface Record {
  id: string;
  customer_feedback: boolean;
  event_scheduling: boolean;
  event_reminder: boolean;
  connection_issues: boolean;
}

export interface NotificationPreferences {
  customerFeedback: boolean;
  eventScheduling: boolean;
  eventReminder: boolean;
  connectionIssues: boolean;
}

export const normalize = (record: Partial<Record>): NotificationPreferences | undefined => {
  const preferences = {
    customerFeedback: Bool.normalize(record.customer_feedback),
    eventScheduling: Bool.normalize(record.event_scheduling),
    eventReminder: Bool.normalize(record.event_reminder),
    connectionIssues: Bool.normalize(record.connection_issues)
  };

  return Obj.normalize(preferences);
};
