import { Grid } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import { FormikProvider } from 'formik';
import type { ReactElement } from 'react';
import { Form } from '../../../../components';
import type { Props } from './form';
import { useForm } from './form';

export const Editor = (props: Props): ReactElement => {
  const { formik, loading, alert } = useForm(props);
  const { onClose } = props;

  return (
    <Modal show backdrop='static' keyboard={false} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Password</Modal.Title>
      </Modal.Header>

      <FormikProvider value={formik}>
        <Form>
          <Modal.Body>
            <Form.Alert show={alert.show} onClose={alert.hide}>
              {alert.message}
            </Form.Alert>

            <Grid gridTemplateColumns='1fr 2rem' alignItems='center'>
              <Form.Label className='large-form-label' htmlFor='passwordEnabled'>
                Password Protect
              </Form.Label>
              <Form.Group
                name='passwordEnabled'
                label=''
                aria-label='Password Protect'
                controlProps={{
                  type: 'checkbox'
                }}
                control={Form.Switch}
              />
            </Grid>
            {formik.values.passwordEnabled && (
              <Form.Group name='password' controlProps={{ placeholder: 'Enter password' }} />
            )}
          </Modal.Body>

          <Modal.Footer>
            <Modal.ButtonBox>
              <Modal.Submit loading={loading}>Save</Modal.Submit>
              <Modal.Button variant='outline-secondary' onClick={onClose}>
                Cancel
              </Modal.Button>
            </Modal.ButtonBox>
          </Modal.Footer>
        </Form>
      </FormikProvider>
    </Modal>
  );
};
