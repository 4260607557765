import type { ReactElement } from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  .primary {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-right: 14px;
    background-color: #2e5bff;
    border-radius: 4px;
    min-width: 134px;
    padding: 8px 11px;
    border: none;

    .text {
      color: #ffffff;
      font-size: var(--font-size-12px);
      font-weight: 600;
    }

    .icon {
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      margin-right: 8px;
    }

    .code {
      background-image: url(/assets/icons/Code-white.svg);
    }
  }

  @media (max-width: 575px) {
    .primary {
      margin-right: 0px;
    }
  }
`;

interface props {
  setShowEmbedModal: (state: boolean) => void;
}

export const EmbedPlayerButton = ({ setShowEmbedModal }: props): ReactElement => (
  <ButtonContainer>
    <button
      type='button'
      className='primary'
      onClick={(): void => {
        setShowEmbedModal(true);
      }}
    >
      <div className='icon code' />
      <div className='text'>Embed Player</div>
    </button>
  </ButtonContainer>
);
