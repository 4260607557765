import { Button } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import type { Asset } from '@livecontrol/scheduler/model';
import cx from 'classnames';
import { useState } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Transfer } from './transfer';

type Props = Button.Props & {
  asset: Asset;
};

const ButtonContainer = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(46, 91, 255, 0.1);
    border: none;
    color: #2e5bff;
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 6px 11px !important;
    border-radius: 4px;
    margin-right: 8px;

    &:hover {
      background-color: #2e5bff;
      color: #ffffff;
    }

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 6px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }

    .download {
      background-image: url(/assets/icons/Download-blue.svg);
    }

    &:hover .download {
      background-image: url(/assets/icons/Download.svg);
    }
  }
`;

export const DownloadButton = (props: Props): ReactElement => {
  const { asset, className, ...rest } = props;

  const prompt = Modal.usePrompt();
  const [showModal, setShowModal] = useState(false);

  const isReady = !!asset.mux?.downloadUrl;

  const doDownload = (): void => {
    // If the download ready?
    if (!isReady) {
      prompt.message({
        title: 'Download Not Ready.',
        icon: 'far fa-clock',
        content:
          'This video is still being generated and is not yet available for downloading.  Please check back at a later time.'
      });
    } else {
      setShowModal(true);
    }
  };

  return (
    <ButtonContainer>
      <Button
        className={cx(!isReady && 'disabled', className)}
        {...rest}
        variant='outline-primary'
        onClick={doDownload}
      >
        <div className='icon download' />
        Download {isReady ? 'Video' : 'Processing...'}
      </Button>

      {showModal && (
        <Transfer
          asset={asset}
          onClose={(): void => {
            setShowModal(false);
          }}
        />
      )}
    </ButtonContainer>
  );
};
