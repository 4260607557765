import { Length } from '@livecontrol/react-utils';
import React from 'react';
import type { ReactElement } from 'react';
import { Grid } from './grid';

export const AutoRepeat = ({
  children,
  method,
  min,
  max,
  ...props
}: AutoRepeat.Props): ReactElement => (
  <Grid
    gridTemplateColumns={`repeat(${method ?? 'auto-fill'}, minmax(${Length.normalize(min) ?? 0}, ${
      Length.normalize(max) ?? '1fr'
    }))`}
    {...props}
  >
    {children}
  </Grid>
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace AutoRepeat {
  export type Props = React.PropsWithChildren<
    Grid.Props & {
      method?: 'auto-fill' | 'auto-fit';
      min: number | string;
      max?: number | string;
    }
  >;
}
