import { Button } from '@livecontrol/core-ui';
import type { Asset } from '@livecontrol/scheduler/model';
import { useState } from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import styled from 'styled-components';
import { Exporter } from './exporter';

const ExportButtonSection = styled.div`
  button {
    padding: 4px 14px;
    background-color: white;
    border-radius: 4px;
    border: solid 1px #2e5bff;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      width: 20px;
      height: 20px;
      background-size: cover;
      bacground-position: center center;
      background-repet: no-repeat;
      margin-right: 12px;
    }
    .export {
      background-image: url(/assets/icons/Download-blue.svg);
    }
    .text {
      font-size: 14px;
      font-weight: 600;
      color: #2e5bff;
    }
  }
  button:hover {
    .text {
      color: white;
    }
    .export {
      background-image: url(/assets/icons/Download.svg);
    }
  }
`;

type Props = HTMLAttributes<HTMLDivElement> & {
  asset: Asset.Like;
  users: Asset.Analytics.Viewers['users'];
};

export const ExportButton = ({ asset, users, ...props }: Props): ReactElement => {
  const [showModal, setShowModal] = useState(false);

  return (
    <ExportButtonSection {...props}>
      <Button
        onClick={(): void => {
          setShowModal(true);
        }}
      >
        <div className='icon export' />
        <div className='text'> Export</div>
      </Button>

      {showModal && (
        <Exporter
          users={users}
          asset={asset}
          onClose={(): void => {
            setShowModal(false);
          }}
        />
      )}
    </ExportButtonSection>
  );
};
