import { Str } from '@livecontrol/core-utils';
import { Modal } from '@livecontrol/scheduler/components';
import { User } from '@livecontrol/scheduler/model';
import type { FormikContextType } from 'formik';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Alert, Store } from '../../store';

const { Constraints } = User;

interface Params {
  token?: string;
}

interface Values {
  password: string;
  confirmation: string;
}

interface Form {
  formik: FormikContextType<Values>;
  loading: boolean;
  alert: Alert;
}

const validationSchema = Yup.object({
  password: Yup.string()
    .trim()
    .min(
      Constraints.PASSWORD_MIN_LENGTH,
      `Your password must be at least ${Constraints.PASSWORD_MIN_LENGTH} characters long.`
    )
    .required('A valid password is required.'),
  confirmation: Yup.string()
    .trim()
    .required('Please confirm your password.')
    .equals([Yup.ref('password')], 'Your passwords do not match.')
});

export const useForm = (): Form => {
  const [resetPassword, { loading, error }] = Store.User.useResetPassword();
  const alert = Alert.useAlert(error);
  const prompt = Modal.usePrompt();

  const token = Str.normalize(useParams<Params>().token);
  const history = useHistory();

  const formik = useFormik<Values>({
    initialValues: {
      password: '',
      confirmation: ''
    },

    validationSchema,
    validateOnMount: true,

    validate(): void {
      alert.hide();
    },

    async onSubmit(data: Values): Promise<void> {
      alert.hide();

      // Execute the mutation (if applicable)
      if (!loading && token && (await resetPassword({ ...data, token }))) {
        prompt.message({
          title: 'Your password has been changed.',
          content: 'You will automatically be redirected to the login page.',
          okay: false
        });

        setTimeout(() => {
          history.push('/login');
        }, 3e3);
      }
    }
  });

  return {
    formik,
    loading,
    alert
  };
};
