import { Preview } from '@livecontrol/scheduler/components';
import type { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import type { Event } from '../store';

type Props = Preview.Props & { event: Event };

const Wrapper = styled(Preview)`
  width: 8rem;
`;

export const Thumbnail = ({ event, ...props }: Props): ReactElement => {
  const asset = event.assets?.length ? event.assets[0] : undefined;

  return asset ? (
    <Link to={`/archive/view/${asset.id}`}>
      <Wrapper imageUrl={event.backgroundURL ?? asset.mux?.thumbnailUrl} {...props}>
        <Preview.Badge className='font-size-11px' variant='danger' corner='top-left' offset={8}>
          LIVE
        </Preview.Badge>
      </Wrapper>
    </Link>
  ) : (
    <Wrapper {...props}>
      <Preview.Badge className='font-size-11px' variant='danger' corner='top-left' offset={8}>
        LIVE
      </Preview.Badge>
    </Wrapper>
  );
};
