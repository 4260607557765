import React from 'react';
import { Section } from './components';

export const AnalyticsProcessing = (): React.ReactElement => (
  <Section header='Event Analytics'>
    <Section.Content>
      <p className='m-0px'>We&apos;re processing your analytics. Please check back later.</p>
    </Section.Content>
  </Section>
);
