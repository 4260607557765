import { Device, Loading } from '@livecontrol/core-ui';
import { Destination } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { useState } from 'react';
import { Dropdown, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Store } from '../../../store';
import { Facebook, RTMP, Youtube } from '../integrations';
import { DestinationCard } from './destination-card';

const Toggle = styled(Dropdown.Toggle)`
  padding: var(--spacer-12px) var(--spacer-12px) var(--spacer-12px) var(--spacer-24px);

  &::after {
    content: none;
  }

  &.show {
    color: black !important;
  }
`;

const DropdownMenu = styled(Dropdown.Menu)`
  transform: translate3d(20px, 49px, 0px) !important;
  border: 0px solid transparent !important;
  padding-bottom: var(--spacer-16px) !important;
`;

const DropdownItem = styled(Dropdown.Item)`
  border: 1px solid #cbcbcb;
  border-radius: var(--spacer-4px);
  padding: var(--spacer-8px) var(--spacer-12px);
  margin: var(--spacer-12px) var(--spacer-16px) 0px var(--spacer-16px);
  width: auto;

  img {
    padding-right: 6px;
    margin-left: var(--spacer-4px);
  }
`;

const DestinationsContainer = styled.div`
  .x-${Device.MOBILE} & {
    width: 250px;
  }

  .x-${Device.TABLET} & {
    width: 540px;
  }
`;

export const DestinationsGrid = (): React.ReactElement => {
  const account = Store.Account.useAccount();
  const me = Store.User.useMe();
  const canEditDestinations = me.permissions.destinations;

  const [facebookPermissionModal, setFacebookPermissionModal] = useState(false);
  const [youtubePermissionModal, setYouTubePermissionModal] = useState(false);
  const [addRTMPModal, setAddRtmpModal] = useState(false);

  const { locations } = Scheduler.Location.useLocations(account);

  const { data: facebookOAuthURL } = Scheduler.Destination.useFacebookAuthorization({
    account,
    reauth: true
  });

  const { data: youTubeOAuthURL } = Scheduler.Destination.useYouTubeAuthorization({ account });

  const {
    destinations,
    error: destinationsError,
    loading: destinationsLoading
  } = Scheduler.Destination.useDestinations(account.id.toString(), true);

  const reAuthenticateDestination = (integration: Destination): void => {
    if (integration.service === Destination.Service.Facebook && facebookOAuthURL) {
      window.location.replace(facebookOAuthURL);
    }

    if (integration.service === Destination.Service.YouTube && youTubeOAuthURL) {
      window.location.replace(youTubeOAuthURL);
    }
  };

  const hasZoomEnabledLocation = !!locations?.find((location) => location.zoom);

  return (
    <>
      <RTMP.AddRTMPModal
        show={addRTMPModal}
        handleClose={(): void => {
          setAddRtmpModal(false);
        }}
        onDone={(): void => {
          setAddRtmpModal(false);
        }}
      />
      <Youtube.PermissionsModal
        show={youtubePermissionModal}
        handleClose={(): void => {
          setYouTubePermissionModal(false);
        }}
      />
      <Facebook.PermissionsModal
        show={facebookPermissionModal}
        handleClose={(): void => {
          setFacebookPermissionModal(false);
        }}
      />
      <div className='mt-16px'>
        <div className='d-flex'>
          <div className='flex-grow-1'>
            <h1>Destinations</h1>
            <div className='text-gray-light font-size-13px font-weight-bold'>
              Add channels and start streaming
            </div>
            <div className='text-gray-light font-size-13px mt-8px font-weight-bold'>
              {/* <div className='text-gray-light font-size-13px mt-8px font-weight-bold' style={{maxWidth: '698px'}}> */}
              *Please note that simulcasting partners update and change their configurations which
              can disable our ability to stream to them. You may be asked to re-link certain
              destinations to accommodate for this.
            </div>
          </div>
        </div>
        <hr className='my-28px' />

        {destinationsLoading ? (
          <Loading />
        ) : destinationsError ? (
          <h1>Error Loading...</h1>
        ) : destinations || hasZoomEnabledLocation ? (
          <Row className='justify-content-center justify-content-lg-end justify-content-xl-end flex-lg-row-reverse flex-xl-row-reverse'>
            <div className='col-12 col-lg-4 col-xl-3 d-flex flex-column align-items-center align-items-lg-end align-items-xl'>
              {canEditDestinations && (
                <Dropdown className='mb-8px mb-lg-0px'>
                  <Toggle
                    id='dropdownMenuButton'
                    className='btn btn-outline-dark bg-white text-dark font-weight-bold'
                  >
                    Add Destination
                    <i className='fal fa-chevron-down fa-fw ml-16px' />
                  </Toggle>
                  <DropdownMenu>
                    <DropdownItem
                      className='font-weight-bold font-size-13px'
                      onClick={(): void => {
                        setFacebookPermissionModal(true);
                      }}
                    >
                      <img src='assets/icons/facebook_icon.svg' alt='facebook' className='pr-8px' />
                      Facebook
                    </DropdownItem>
                    <DropdownItem
                      className='font-weight-bold font-size-13px'
                      onClick={(): void => {
                        setYouTubePermissionModal(true);
                      }}
                    >
                      <img src='assets/icons/youtube_icon.svg' alt='youtube' />
                      YouTube
                    </DropdownItem>
                    <DropdownItem
                      className='font-weight-bold font-size-13px'
                      onClick={(): void => {
                        setAddRtmpModal(true);
                      }}
                    >
                      <img src='assets/icons/rtmp_icon.svg' alt='rtmp' />
                      Custom RTMP
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}
            </div>

            <div className='d-flex justify-content-center justify-content-lg-start col-12 col-lg-8 col-xl-9 p-0'>
              <DestinationsContainer className='d-flex flex-wrap justify-content-md-start'>
                {destinations && destinations.length > 0
                  ? destinations.map((destination) => (
                      <DestinationCard
                        key={destination.id}
                        destination={destination}
                        reAuthenticateDestination={reAuthenticateDestination}
                      />
                    ))
                  : null}

                {hasZoomEnabledLocation && (
                  <DestinationCard
                    destination={{
                      id: 'zoom',
                      name: 'Enabled For Produced Events',
                      deauthorized: false,
                      service: Destination.Service.Zoom
                    }}
                  />
                )}
              </DestinationsContainer>
            </div>
          </Row>
        ) : null}

        {destinations?.length === 0 && !hasZoomEnabledLocation ? (
          <div className='text-center py-64px'>
            <div className='font-size-22px'>You have no destinations</div>
            <div className='text-muted font-size-13px mt-12px'>Please add a new destination</div>
          </div>
        ) : null}
      </div>
    </>
  );
};
