import type { Viewport } from '@livecontrol/core-ui';
import { Device } from '@livecontrol/core-ui';
import { Str } from '@livecontrol/core-utils';
import { Modal } from '@livecontrol/scheduler/components';
import type { Account } from '@livecontrol/scheduler/model';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Store } from '../../store';
import { CreditsModal } from './credits-modal';

interface Props {
  viewport: Viewport;
  billing: Account.Billing;
}

const Toggle = styled(Dropdown.Toggle)`
  &::after {
    content: none;
  }
`;

const Avatar = styled.img`
  width: 46px;
  height: 46px;
`;

const Container = styled.div`
  width: 52px;
  height: 52px;
  background-color: #fff;
  border:1px solid var(--primary);
  border-radius: 50%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

export const User = ({ viewport, billing }: Props): React.ReactElement => {
  const [showModal, setShowModal] = React.useState(false);
  const history = useHistory();
  const me = Store.User.useMe();
  const account = Store.Account.useAccount();
  const prompt = Modal.usePrompt();

  const isMobile = Device.isMobile(viewport);

  return (
    <>
      <Dropdown alignRight>
        <Toggle forwardedAs='button' className='x-pseudo'>
          <div className='d-flex align-items-center'>
            <Container>
              <Avatar className='border rounded-circle' src={account.avatar} />
            </Container>
            {!isMobile && (
              <div className='font-size-13px mx-12px text-left'>
                {account.name && (
                  <div className='pb-4px font-size-14px font-weight-bold'>{Str.truncate(account.name)}</div>
                )}
                <div className='text-secondary font-size-11px pb-4px'>{`Customer ID: ${account.id}`}</div>
              </div>
            )}
          </div>
        </Toggle>

        <Dropdown.Menu>
          {me.permissions.billing && (
            <Dropdown.Item
              className='text-center'
              onClick={(): void => {
                if (!billing.credits.price) {
                  prompt.message({
                    title: 'Credit Purchasing Not Enabled',
                    icon: 'far fa-coins',
                    content: (
                      <p className='text-center'>
                        Please contact your dedicated Account Manager. Your account needs to be
                        enabled for purchasing one-time credits.
                      </p>
                    )
                  });
                } else {
                  setShowModal(true);
                }
              }}
            >
              Add Credits
            </Dropdown.Item>
          )}
          <Dropdown.Item
            className='text-center'
            onClick={(): void => {
              history.push('/logout');
            }}
          >
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {showModal && (
        <CreditsModal
          billing={billing}
          onClose={(): void => {
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};
