import { Tooltip } from '@livecontrol/core-ui';
import { Destination } from '@livecontrol/scheduler/model';
import { Component } from 'react';
import type { HTMLAttributes, ReactNode } from 'react';
import { Icons } from '../icons';
import { RecordOnly as RecordOnly_ } from './record-only';

import S = Destination.Service;

// eslint-disable-next-line react/prefer-stateless-function
export class Service extends Component<Service.Props> {
  public override render(): ReactNode {
    const {
      destination: { name, service },
      ...props
    } = this.props;

    return (
      <Tooltip
        tip={
          <div>
            <strong>{service === S.RTMP ? 'Custom RTMP' : S[service]}</strong>
            <br /> {name}
          </div>
        }
      >
        <span {...props}>
          {service === S.LiveControl ? (
            <Icons.LcIcon />
          ) : (
            <i
              className={
                service === S.YouTube
                  ? 'fab fa-youtube'
                  : service === S.Facebook
                  ? 'fab fa-facebook'
                  : service === S.Zoom
                  ? 'fas fa-video'
                  : 'fas fa-signal-stream'
              }
            />
          )}
        </span>
      </Tooltip>
    );
  }
}

export namespace Service {
  export type Props = HTMLAttributes<HTMLElement> & {
    destination: Destination;
  };

  export const RecordOnly = RecordOnly_;
}
