import type { Account, User } from '@livecontrol/scheduler/model';
import type { ReactElement } from 'react';
import { Header } from './header';
import { Information } from './information';

/*
 * import { Loading } from '@livecontrol/core-ui';
 * import { Scheduler } from '@livecontrol/scheduler/store';
 * import { Store } from '../../../store';
 * import { Equipments } from './equipments';
 */

export const Plan = ({billing, me}: {billing: Account.Billing; me: User}): ReactElement =>
  /*
   * const account = Store.Account.useAccount();
   * const { locations } = Scheduler.Location.useLocations(account);
   */

   (
    <div className='d-flex flex-column'>
      <Header />
      <Information billing={billing} me={me} />
      {/* {!locations ? <Loading.Delay /> : <Equipments locations={locations} />} */}
    </div>
  )
;
