import cx from 'classnames';
import React from 'react';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import logo from './logo.svg';

/* eslint-disable react/no-danger */

export interface PageProps {
  className?: string;
  code: number;
  heading?: string;
  message?: string;
  reset?: () => void;
}

const StyledSVG = styled.svg`
  position: absolute;
  bottom: -160px;
  z-index: 0;
`;

const Code = styled.div`
  font-size: 4rem;
`;

export const Page = ({
  className,
  code,
  heading,
  message,
  reset
}: PageProps): React.ReactElement => {
  const handler = (event: React.MouseEvent): void => {
    event.preventDefault();

    // Reset the error boundary
    reset?.();

    window.location.href = 'https://www.livecontrol.io';
  };

  return (
    <div
      className={cx(
        'd-flex flex-column align-items-stretch',
        'vh-100 vw-100',
        'text-light position-relative bg-white',
        className
      )}
    >
      <div
        className='flex-grow-1'
        style={{
          backgroundColor: '#041f39'
        }}
      />
      <div className={cx('position-relative')} style={{ width: '100%' }}>
        <div
          style={{ backgroundColor: '#041f39', top: 0, left: 0, right: 0, bottom: 0, zIndex: 0 }}
          className='position-absolute'
        />
        <StyledSVG
          viewBox='0 0 400 200'
          fill='#041f39'
          preserveAspectRatio='none'
          style={{ zIndex: 1, backgroundColor: 'white' }}
        >
          <path d=' M 490 0 A 290 200 0 0 1 -90 0' />
        </StyledSVG>
        <div
          className={cx('position-relative px-32px', 'd-flex flex-column align-items-center')}
          style={{ zIndex: 2, width: '100%' }}
        >
          <img src={logo} alt='Livecontrol logo' />
          <Code className='font-weight-bold mt-64px'>{code}</Code>

          {heading && <div className='mt-32px font-size-24px'>{heading}</div>}

          {message && (
            <p
              className='mt-16px text-center font-size-20px'
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}

          <Button
            className='btn-wide text-light text-decoration-underline'
            variant='link'
            size='lg'
            onClick={handler}
            style={{ textDecoration: 'underline' }}
          >
            Visit our home page
          </Button>
        </div>
      </div>

      <div className='flex-grow-1' />
    </div>
  );
};
