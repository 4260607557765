import cx from 'classnames';
import React, { forwardRef } from 'react';
import Button from 'react-bootstrap/Button';
import type { DropdownProps } from 'react-bootstrap/Dropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownItem from 'react-bootstrap/DropdownItem';
import type { DropdownMenuProps } from 'react-bootstrap/DropdownMenu';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import type { DropdownToggleProps } from 'react-bootstrap/DropdownToggle';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import type { BsPrefixRefForwardingComponent } from 'react-bootstrap/helpers';
import styled from 'styled-components';

const Wrapper = styled(Dropdown)`
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
`;

const Toggle = forwardRef(
  (
    { className, children: _unused, ...rest }: DropdownToggleProps,
    ref: React.Ref<HTMLButtonElement>
  ) => (
    <Button className={cx('btn-round', className)} ref={ref} variant='light' {...rest}>
      <i className='fas fa-cog font-size-12px' />
    </Button>
  )
);

Toggle.displayName = 'Cog/Toggle';

const Cog_ = forwardRef(({ children, ...props }: Cog.Props, ref: React.Ref<HTMLButtonElement>) => {
  const { align, ...rest } = props;

  return (
    <Wrapper {...rest}>
      <DropdownToggle as={Toggle} ref={ref} />
      <DropdownMenu align={align ?? 'right'}>{children}</DropdownMenu>
    </Wrapper>
  );
});

Cog_.displayName = 'Cog';

export const Cog: BsPrefixRefForwardingComponent<'div', Cog.Props> & {
  Item: typeof DropdownItem;
  Divider: typeof Dropdown.Divider;
} = Object.assign(Cog_, {
  Item: DropdownItem,
  Divider: Dropdown.Divider
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Cog {
  export type Props = DropdownProps & Pick<DropdownMenuProps, 'align'>;
}
