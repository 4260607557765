import { Phone } from '@livecontrol/locale-us';
import { User } from '@livecontrol/scheduler/model';
import type { FormikContextType } from 'formik';
import { useFormik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';
import { Alert, Store } from '../../store';

const { Constraints } = User;

export interface SignUpValues {
  agree: boolean;
  confirmation: string;
  email: string;
  first: string;
  last: string;
  address: string;
  place_id: string;
  organization: string;
  industry: number;
  password: string;
  phone: string;
}

interface Form {
  formik: FormikContextType<SignUpValues>;
  loading: boolean;
  alert: Alert;
}

export interface Props {
  onSubmit?: (user?: SignUpValues) => void;
}

const validationSchema = Yup.object({
  first: Yup.string().trim().required('Please provide your first name.'),
  last: Yup.string().trim().required('Please provide your last name.'),
  email: Yup.string()
    .trim()
    .required('Please enter valid email address')
    .email('Please enter valid email address.'),
  phone: Yup.string()
    .required('A valid phone number is required.')
    .test({
      message: 'Please provide a valid phone number.',
      test: (value) => !!Phone.normalize(value)
    }),
  address: Yup.string().trim().required('Please specify the address where your venue is located.'),
  organization: Yup.string().trim().required('Please provide the name of your organization.'),
  industry: Yup.number().positive('Please select your primary industry.'),
  password: Yup.string()
    .trim()
    .min(
      Constraints.PASSWORD_MIN_LENGTH,
      `Your password must be at least ${Constraints.PASSWORD_MIN_LENGTH} characters long.`
    )
    .required('A valid password is required.'),
  confirmation: Yup.string()
    .trim()
    .required('Please confirm your password.')
    .equals([Yup.ref('password')], 'Your passwords do not match.'),
  agree: Yup.bool()
    .required('Please accept the terms and conditions.')
    .equals([true], 'Please accept the terms and conditions.')
});

export const useForm = ({ onSubmit }: Props): Form => {
  const [signUp, { loading, error }] = Store.User.useSignUp();
  const alert = Alert.useAlert(error);

  const formik = useFormik<SignUpValues>({
    initialValues: {
      agree: false,
      email: '',
      first: '',
      last: '',
      address: '',
      place_id: '',
      organization: '',
      industry: 0,
      password: '',
      confirmation: '',
      phone: ''
    },

    validationSchema,
    validateOnMount: true,

    validate(): void {
      alert.hide();
    },

    async onSubmit(data: SignUpValues): Promise<void> {
      alert.hide();

      if (!loading && data.agree) {
        // Execute the mutation
        const token = await signUp(_.omit(data, 'agree'));

        if (token) {
          // All set. Automatically login
          Store.User.login(token);
          onSubmit?.(data);
        }
      }
    }
  });

  return {
    formik,
    loading,
    alert
  };
};
