import { Glyph } from '@livecontrol/core-ui';
import _ from 'lodash';
import { useCallback, useContext, useEffect, useRef } from 'react';
import type { ElementType, ReactElement } from 'react';
import { isElement } from 'react-is';
import { Context } from './context';
import { Prompt } from './prompt';

let sequence = 1;

type MessageProps = Pick<Prompt.Props, 'icon' | 'okay' | 'title'> & {
  content?: ReactElement | string;
  onClose?: () => void;
};

type ConfirmProps = MessageProps &
  Pick<Prompt.Props, 'cancel'> & { onAnswer: (value: boolean) => void };

interface IPrompt {
  show: (component: ElementType | ReactElement) => void;
  hide: () => void;
  message: (props: MessageProps | string) => void;
  error: (props?: MessageProps | string) => void;
  confirm: (props: ConfirmProps) => void;
}

export const usePrompt = (): IPrompt => {
  const context = useContext(Context);
  const key = useRef(`modal-${sequence++}`).current;

  const show = useCallback(
    (component: ElementType | ReactElement) => {
      context.addPrompt(key, component);
    },
    [context, key]
  );

  const hide = useCallback(() => {
    context.removePrompt(key);
  }, [context, key]);

  // Hide the modal on unmount
  useEffect(() => hide, [hide]);

  const prompt = useCallback(
    (props?: Prompt.Props & { onClose?: () => void }) => {
      const { children, onOkay, onCancel, onClose, ...rest } = props ?? {};

      const close = (): void => {
        onClose?.();
        hide();
      };

      const passthru = _.defaults(
        {
          onOkay: () => {
            onOkay?.();
            close();
          },
          onCancel: () => {
            onCancel?.();
            close();
          }
        },
        rest,
        {
          show: true
        }
      );

      show(<Prompt {...passthru}>{children}</Prompt>);
    },
    [show, hide]
  );

  const message = useCallback(
    (props: MessageProps | string) => {
      const { content, ...rest } = typeof props === 'string' ? { content: props } : props;

      prompt(
        _.defaults(
          {
            children:
              content && (isElement(content) ? content : <p className='text-center'>{content}</p>)
          },
          rest,
          {
            icon: 'fas fa-info',
            cancel: false as const
          }
        )
      );
    },
    [prompt]
  );

  const error = useCallback(
    (props?: MessageProps | string) => {
      message(
        _.defaults({}, typeof props === 'string' ? { content: props } : props, {
          icon: (
            <Glyph className='text-white bg-danger'>
              <i className='fas fa-exclamation' />
            </Glyph>
          ),
          okay: { variant: 'outline-danger' },
          title: 'An error has occurred.'
        })
      );
    },
    [message]
  );

  const confirm = useCallback(
    ({ onAnswer, ...props }: ConfirmProps) => {
      message(
        _.defaults({}, props, {
          icon: 'fas fa-question',
          onOkay: _.partial(onAnswer, true),
          onCancel: _.partial(onAnswer, false),
          cancel: {
            label: 'Cancel'
          }
        })
      );
    },
    [message]
  );

  return {
    show,
    hide,
    message,
    error,
    confirm
  };
};
