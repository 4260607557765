import { Theme } from '@livecontrol/core-ui';
import { TableGrid } from '@livecontrol/scheduler/components';
import styled from 'styled-components';

const TableStyledWrapped = styled.div`

  ol {
    grid-row-gap: 10px;
  }

  .th {
    color: #8798ad;
    font-weight: 600;
    font-size: var(--font-size-15px);
  }

  .cell {
    color: var(--dark);
    font-weight: 600;
    font-size: var(--font-size-15px);

    border-width: 1px 0;
    border-style: solid;
    border-color: #D5DAEA;

    &:first-child {
        border-left-width: 1px;
        padding-left: var(--matrix-padding-x);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    &:last-child {
        justify-content: flex-end;
        border-right-width: 1px;
        padding-right: var(--matrix-padding-x);
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
  }


`;

export const Inactive = (): React.ReactElement => {
  const viewport = Theme.useViewport();

  const headers = [
    {
      title: 'CID',
      id: '1'
    },
    {
      title: 'Organization',
      id: '2'
    },
    {
      title: 'Account Owner',
      id: '3'
    },

    {
      title: '',
      id: '4'
    }
  ];

  const bodys = [
    [
      {
        text: '8',
        id: '5'
      },
      {
        text: 'Beth El Batlimore',
        id: '6'
      },
      {
        text: 'Sherri Holzer',
        id: '7'
      },
      {
        text: '8',
        action: (
          <button type='button' className='btn btn-primary'>
            View Account
          </button>
        ),
        id: '8'
      }
    ],
    [
      {
        text: '9',
        id: '9'
      },
      {
        text: 'Blue Note',
        id: '10'
      },
      {
        text: 'Glen Faug',
        id: '11'
      },
      {
        text: '8',
        action: (
          <button type='button' className='btn btn-primary'>
            View Account
          </button>
        ),
        id: '12'
      }
    ],
    [
      {
        text: '10',
        id: '13'
      },
      {
        text: 'Church of the Saints',
        id: '14'
      },
      {
        text: 'Sherri Holzer',
        id: '15'
      },
      {
        text: '8',
        action: (
          <button type='button' className='btn btn-primary'>
            View Account
          </button>
        ),
        id: '16'
      }
    ]
  ];

  const gridTemplateColumns = 'repeat(3, 1fr) 400px';

  return (
    <TableStyledWrapped>
      <TableGrid
        viewport={viewport}
        gridTemplateColumns={gridTemplateColumns}
        bodys={bodys}
        headers={headers}
      />
    </TableStyledWrapped>
  );
};
