/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql } from '@apollo/client';
import { EmailAddress, Num, Obj, Str } from '@livecontrol/core-utils';
import { Phone } from '@livecontrol/locale-us';
import type { Contact } from '@livecontrol/scheduler/model';

export type Record = Partial<{
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  notes: string;
  priority: number;
  phone_type: string;
  preferred_contact_method: string;
  location: Location | any;
}>;

export const normalize = (record?: Record): Contact | undefined => {
  let contact;

  if (record) {
    const firstName = Str.normalize(record.first_name);
    const lastName = Str.normalize(record.last_name);

    // Required properties
    const candidate = {
      id: Num.normalize(record.id),
      firstName,
      lastName,
      fullName: [firstName, lastName].join(' '),
      email: EmailAddress.normalize(record.email),
      phone: Phone.normalize(record.phone_number),
      priority: Num.normalize(record.priority) ? Num.normalize(record.priority) : 0,
      phoneType: Str.normalize(record.phone_type) ? Str.normalize(record.phone_type) : '',
      preferredContactMethod: Str.normalize(record.preferred_contact_method) ? Str.normalize(record.preferred_contact_method) : ''
    };

    if (Obj.isHydrated(candidate)) {

      contact = {...candidate, notes: Str.normalize(record.notes), location: record.location};
    }
  }

  return contact;
};

export const StandardContactResponse = gql`
  fragment StandardContactResponse on Contact {
    id
    first_name
    last_name
    email
    phone_number
    notes
    preferred_contact_method
    phone_type
    priority
    location{
      id
      name
    }
  }
`;

export const __typename = 'Contact';
