import type { Phone } from '@livecontrol/locale-us';

export interface SubscriptionAccountInfo {
  plan?: SubscriptionAccountInfo.Plan;
  subscription?: SubscriptionAccountInfo.Subscription;
  billingContact?: SubscriptionAccountInfo.BillingContact;
  invoices?: SubscriptionAccountInfo.Invoice[];
}

export namespace SubscriptionAccountInfo {
  export interface Plan {
    creditsLength: number;
    additionalCreditPrice: number;
    paymentCycle: string;
    renewal_date: string;
    contractFile: string;
  }
  export interface Subscription {
    nextBillingDate?: string;
    items: [
      {
        cost: number;
        description: string;
        id: number;
      }
    ];
  }
  export interface BillingContact {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber?: Phone;
    organizationName: string;
    organizationAddress: string;
    preferredContactMethod: string;
  }
  export interface Invoice {
    date: string;
    price: number;
    status: Invoice.InvoiceStatus;
    description: string;
    id: string;
    hosted_url: string;
  }

  export namespace Invoice {
    export const enum InvoiceStatus {
      DRAFT = 'draft',
      OPEN = 'open',
      VOID = 'void',
      PAID = 'paid',
      UNCOLLECTIBLE = 'uncollectible'
    }
  }
}
