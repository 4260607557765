import { Obj, Str } from '@livecontrol/core-utils';
import type { DateTime } from 'luxon';

export interface Location {
  id: string;
  secondaryId: number;
  name: string;
  zoom: boolean;
  timezone: string;
  simulatedLive: boolean;
  screenshare: boolean;
  isDisabled: boolean;
  isStatic: boolean;
  isProduced: boolean;
  isClientBooking: boolean;
  isMobile: boolean;
  isMobileKit: boolean;
  isTest: boolean;
  isCueSheetEnabled: boolean;
  productionNotes: ProductionNotes;
  onboardingLocationAddress?: Location.OnboardingLocationAddress;
  shippingZipCode?: string;
  shippingStreetAddress2?: string;
  shippingStreetAddress1?: string;
  shippingState?: string;
  shippingName?: string;
  shippingCountry?: string;
  shippingCity?: string;
  physicalZipCode?: string;
  physicalStreetAddress2?: string;
  physicalStreetAddress1?: string;
  physicalState?: string;
  physicalCity?: string;
  physicalCountry?: string;
  addOns?: AddOn[];
  addonNotes?: string;
  equipmentNotes?: string;
  isRefundableEquipment?: boolean;
  graphicsSoftware?: string;
  muxStreamId?: string;
  muxStreamKey?: string;
  muxStreamUrl?: string;
  jwt?: string;
  isStaticPlus?: boolean;
  externalEncoder?: boolean;
}

export interface ProductionNotes {
  locationId?: string;
  camera_movements_preference?: number;
  audience_shots?: number;
  close_up_shots?: number;
  wide_venue_shots?: number;
  detailed_venue_shots?: number;
  event_ending_preference?: number;
}

export interface AddOn {
  id: string;
  name: string;
  type: string;
}

/*
 * export interface OnboardingLocationAddress {
 *   id?: number;
 *   ideal_first_stream_dat?: string;
 *   ideal_site_visit_date?: string;
 *   install_date_request?: string;
 *   installation_city?: string;
 *   installation_state?: string;
 *   installation_street_address_1?: string;
 *   installation_street_address_2?: string;
 *   installation_zip_code?: string;
 *   shipping_city?: string;
 *   shipping_state?: string;
 *   shipping_street_address_1?: string;
 *   shipping_street_address_2?: string;
 *   shipping_zip_code?: string;
 * }
 */

export interface LocationFromPermisson {
  id: string;
  name: string;
  is_disabled?: boolean;
}

export namespace Location {
  export type ID = string;
  export type Like = ID | Location | string;

  export const toId = (location?: Like): ID | undefined =>
    Str.normalize(Obj.isObject<Location>(location) ? location.id : location);

  export interface DropdownLocation {
    value: string;
    label: string;
  }

  export interface OnboardingLocationAddress {
    id?: string;
    installationStreetAddress?: string;
    installationStreetAddress2?: string;
    installationCity?: string;
    installationState?: string;
    installationZipCode?: string;
    shippingStreetAddress?: string;
    shippingStreetAddress2?: string;
    shippingCity?: string;
    shippingState?: string;
    shippingZipCode?: string;
    handlerInstallation?: string;
    installDateRequest?: DateTime;
    idealSiteVisitDate?: DateTime;
    idealFirstStreamDate?: DateTime;
    additionalInformation?: string;
    shippingContactId?: number;
    onboardingMainContactId?: number;
    onboardingTechnicalContactId?: number;
    locationId?: string;
  }
}
