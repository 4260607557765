import type { Account, Location } from '@livecontrol/scheduler/model';
import type { FormikContextType } from 'formik';
import { useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import type { AccountUsersPermission } from './account-permissions';
import type { Values } from './form';
import { LocationSection } from './location';

interface Props {
  locations: Location[];
  accountUserPermissionSelected: AccountUsersPermission[];
  formik: FormikContextType<Values>;
  user: Account.SubUser;
  valueSelected: Account.SubUser | Location | undefined;
}

export const RowUser = ({
  locations,
  accountUserPermissionSelected,
  formik,
  user,
  valueSelected
}: Props): ReactElement => {
  const [isCollapse, setIsCollapse] = useState(false);

  useEffect(() => {
    if (valueSelected?.id === user.id) {
      setIsCollapse(true);
    } else {
      setIsCollapse(false);
    }
  }, [user.id, valueSelected]);

  return (
    <>
      <div className='d-none' />
      {isCollapse && (
        <>
          {locations.map((location) => (
            <LocationSection
              accountUserPermissionSelected={accountUserPermissionSelected}
              location={location}
              formik={formik}
              key={location.id}
              subUser={user}
            />
          ))}
        </>
      )}
    </>
  );
};
