import { Asset } from '@livecontrol/scheduler/model';
import type { Duration as L_Duration } from 'luxon';
import type { ReactElement } from 'react';
import { Preview } from '../preview';

import B = Preview.Badge;

export namespace Badge {
  export const Duration = ({
    corner,
    duration,
    ...props
  }: B.Props & { duration?: L_Duration }): ReactElement | null =>
    duration ? (
      <B corner={corner ?? 'top-left'} {...props}>
        {duration.toFormat(duration.as('minutes') >= 60 ? 'h:mm:ss' : 'mm:ss')}
      </B>
    ) : null;

  export const Password = ({
    corner,
    password,
    ...props
  }: B.Props & { password?: string }): ReactElement | null =>
    password ? (
      <B corner={corner ?? 'top-right'} {...props}>
        <i className='far fa-lock-alt' />
      </B>
    ) : null;

  export const Visibility = ({
    corner,
    visibility,
    ...props
  }: B.Props & { visibility?: Asset.Visibility }): ReactElement | null =>
    visibility === Asset.Visibility.Private ? (
      <B corner={corner ?? 'bottom-left'} {...props}>
        <i className='fal fa-eye-slash mr-8px' />
        PRIVATE
      </B>
    ) : null;
}
