import { Matrix } from '@livecontrol/scheduler/components';
import type { ReactElement } from 'react';
import styled from 'styled-components';

import G = Matrix.Grid;

const CellNoBorder = styled(Matrix.Grid.Row.Cell)`
  border: 0px solid transparent;
  justify-content: center;
  padding: 14px 0px;

  &.transparent {
    background-color: transparent !important;
  }

  &.boder-bottom {
    border-bottom: 1px solid #e4e8f0 !important;
  }

  &:last-child {
    padding: 5px 0px !important;
  }

  &:first-child {
    border-left: 0px solid transparent !important;
    position: sticky;
    left: 0;
  }
`;

interface Props {
  visibility: boolean;
}

export const AccountGlobalHolder = ({ visibility }: Props): ReactElement => (
  <G.Row style={{ display: visibility ? 'contents' : 'none' }}>
      <CellNoBorder className='column height-10px' />
      <CellNoBorder className='column height-10px' />
      <CellNoBorder className='column height-10px' />
      <CellNoBorder className='column height-10px' />
      <CellNoBorder className='column height-10px' />
      <CellNoBorder className='column height-10px' />
      <CellNoBorder className='column height-10px' />
      <CellNoBorder className='column height-10px' />
      <CellNoBorder className='column height-10px' />
      <CellNoBorder className='column height-10px' />
      <CellNoBorder className='column height-10px' />
    </G.Row>
);
