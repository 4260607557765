import { Num, Str } from '@livecontrol/core-utils';
import _ from 'lodash';
import { EventValidator } from './types';

export const validateZoomDetails = (
  newEventInfo: EventValidator.EventInfo
): EventValidator.Errors => {
  const errors: EventValidator.Errors = {};

  if (
    newEventInfo.zoomDetails?.streamZoom &&
    newEventInfo.type === EventValidator.ProductionType.PRODUCED
  ) {
    const meetingId = Str.normalize(newEventInfo.zoomDetails.meetingId);
    const password = Str.normalize(newEventInfo.zoomDetails.password);
    const meetingIdNumber = Num.normalize(meetingId?.trim().replace(/\s+/g, ''));

    if (!meetingIdNumber) {
      _.merge(errors, {
        zoomDetails: {
          meetingId: EventValidator.ErrorMessages.ZOOM_MEETING_ID_INVALID
        }
      });
    } else if (meetingIdNumber.toString().length < 9) {
      _.merge(errors, {
        zoomDetails: {
          meetingId: EventValidator.ErrorMessages.ZOOM_MEETING_ID_INVALID
        }
      });
    } else if (meetingIdNumber.toString().length > 11) {
      _.merge(errors, {
        zoomDetails: {
          meetingId: EventValidator.ErrorMessages.ZOOM_MEETING_ID_INVALID
        }
      });
    }

    if (!password) {
      _.merge(errors, {
        zoomDetails: {
          password: EventValidator.ErrorMessages.ZOOM_PASSWORD_INVALID
        }
      });
    }
  }

  return errors;
};
