import type { ReactElement } from 'react';
import AccordionToggle from 'react-bootstrap/AccordionToggle';
import type { AccordionToggleProps } from 'react-bootstrap/AccordionToggle';
import Card from 'react-bootstrap/Card';

export const Toggle = ({ children, ...props }: Toggle.Props): ReactElement => (
  <AccordionToggle {...props} as={Card.Header}>
    {children}
  </AccordionToggle>
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Toggle {
  export type Props = AccordionToggleProps;
}
