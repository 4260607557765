import { gql } from '@apollo/client';

export const CreateProduct = gql`
  mutation CreateProduct($type: Float!, $description: String!, $name: String!, $price: Float!) {
    createLiveControlProduct(
      input: { type: $type, description: $description, name: $name, price: $price }
    ) {
      id
      description
      price
      name
      type {
        id
        type
        description
      }
    }
  }
`;

export const EditProduct = gql`
  mutation EditProduct(
    $id: Float!
    $type: Float!
    $description: String!
    $name: String!
    $price: Float!
  ) {
    updateLiveControlProduct(
      input: { id: $id, type: $type, description: $description, name: $name, price: $price }
    ) {
      id
      description
      price
      name
      type {
        id
        type
        description
      }
    }
  }
`;

export const ShowProduct = gql`
  query GetLiveControlProduct($id: Float!) {
    getLiveControlProduct(input: { id: $id }) {
      id
      description
      price
      name
      type {
        id
        type
        description
      }
    }
  }
`;

export const ListProducts = gql`
  query GetLiveControlProducts {
    getLiveControlProducts {
      id
      price
      name
      type {
        id
        type
      }
      created_by {
        first_name
        last_name
      }
    }
  }
`;

export const GetProductTypeList = gql`
  query GetProductsTypeList {
    getProductsTypeList {
      id
      type
    }
  }
`;

export const DeleteProduct = gql`
  mutation DeleteProduct($id: Float!) {
    deleteLiveControlProduct(input: { id: $id }) {
      id
    }
  }
`;
