import styled from 'styled-components';

export const Footer = styled.div`
  padding-top: var(--spacer-16px);
  text-align: right;

  .x-light-theme & {
    border-top: 1px solid var(--light);
  }

  .x-dark-theme & {
    border-top: 1px solid var(--white15);
  }
`;
