import type { Any } from '@livecontrol/core-utils';
import Clipboard from 'clipboard';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import type { ReactElement } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';

export const CopyCTA = ({
  value,
  noIcon,
  noToast,
  onCopy,
  toastText = 'Copied to Clipboard!',
  toastStyle,
  children,
  className
}: {
  value: string;
  toastText?: string;
  onCopy?: () => void;
  noIcon?: boolean;
  noToast?: boolean;
  toastStyle?: React.CSSProperties;
  children?: React.ReactNode;
  className?: string;
}): ReactElement => {
  const ref = useRef<Any>(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    let clip: Clipboard | undefined;
    let timeout: NodeJS.Timeout | undefined;

    if (ref.current) {
      clip = new Clipboard(ref.current, {
        text(): string {
          return value;
        }
      });

      clip.on('success', () => {
        onCopy?.();

        if (noToast) {
          return;
        }

        setShow(true);

        timeout = setTimeout(() => {
          setShow(false);
        }, 1000);
      });
    }

    return (): void => {
      if (clip) {
        clip.destroy();
      }

      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [noToast, onCopy, value]);

  return (
    <span className={className}>
      <span ref={ref}>{children}</span>

      <Overlay target={ref} show={show} placement='bottom'>
        <Tooltip id={_.uniqueId('tooltip')} style={toastStyle}>
          {!noIcon && <i className='fas fa-info-circle mr-8px' />}
          {toastText}
        </Tooltip>
      </Overlay>
    </span>
  );
};
