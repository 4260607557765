import { Str } from '@livecontrol/core-utils';
import { EventValidator } from './types';

export const validateEventLocation = (
  newEventInfo: EventValidator.EventInfo
): EventValidator.Errors => {
  const errors: EventValidator.Errors = {};
  const location = Str.normalize(newEventInfo.location);

  if (!location) {
    errors.location = EventValidator.ErrorMessages.LOCATION_MISSING;
  }

  return errors;
};
