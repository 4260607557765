import { Loading } from '@livecontrol/core-ui';
import { Calendar } from '@livecontrol/core-utils';
import type { Location, User } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { EventValidator, validateNewEventStep4 } from '@livecontrol/scheduler/validator';
import { Formik } from 'formik';
import _ from 'lodash';
import { DateTime, IANAZone } from 'luxon';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { store, Store } from '../../../store';
import { EventDetailsForm, EventDetailsUtils } from '../event-details';
import type { EventContext } from '../event-store';
import { EventContextStore } from '../event-store';
import { FooterSection } from '../layout-sections';
import { DestinationsSection } from './destinations-section';
import { EventImages } from './event-images';
import { OtherOptions } from './other-options';
import { VideographerFeatures } from './videographer-features';
import { WebplayerFeatures } from './webplayer-features';

export const StreamingAndEventSettings = ({
  onBack,
  onSchedule,
  accountSettings
}: {
  onBack: () => void;
  onSchedule: () => void;
  accountSettings: User.admin;
}): React.ReactElement => {
  const account = Store.Account.useAccount();
  const { destinations = [], loading } = Scheduler.Destination.useDestinations(account, false);

  const { billing } = Scheduler.Account.useBilling(account);
  const { production } = store.environment;
  const isAdmin = Store.User.useIsAdmin();
  const { events: allEvents } = Scheduler.Event.useEvents(account);
  const { locations } = Scheduler.Location.useLocations(account);
  const { store: eventDetailsContext }: EventContext = useContext<EventContext>(EventContextStore);

  const availableLocations = useMemo(
    (): Location[] =>
      EventDetailsUtils.getAvailableLocations(locations, eventDetailsContext.eventType).sort(
        (a, b) => a.name.localeCompare(b.name)
      ),
    [locations, eventDetailsContext]
  );

  const timezone: string | undefined = useMemo(() => {
    const selected = availableLocations.find(
      (location) => location.id === eventDetailsContext.eventInfo.location
    );

    const timezone_ = selected
      ? selected.timezone
      : availableLocations.length
      ? availableLocations[0].timezone
      : undefined;

    return timezone_;
  }, [availableLocations, eventDetailsContext.eventInfo.location]);

  const currentSelectedLocation = availableLocations.find(
    (location) => location.id === eventDetailsContext.eventInfo.location
  );

  const slidesOn =
    (eventDetailsContext.eventType === EventValidator.ProductionType.PRODUCED ||
      eventDetailsContext.eventType === EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT) &&
    currentSelectedLocation?.addOns?.some((addon) => addon.type === 'intro_outro');

  const showZoomInput =
    (eventDetailsContext.eventType === EventValidator.ProductionType.PRODUCED ||
      eventDetailsContext.eventType === EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT) &&
    currentSelectedLocation?.addOns?.some((addon) => addon.type === 'zoom');

  const [initialValues, setInitialValues] = useState({
    type: eventDetailsContext.eventType,
    startTime: eventDetailsContext.eventInfo.start,
    endTime: eventDetailsContext.eventInfo.end,
    passwordSettings: {
      enabled: eventDetailsContext.eventInfo.passwordSettings?.enabled ?? false,
      password: eventDetailsContext.eventInfo.passwordSettings?.password ?? ''
    },
    redirectUrlSettings: {
      enabled: eventDetailsContext.eventInfo.redirectUrlSettings?.enabled,
      url: eventDetailsContext.eventInfo.redirectUrlSettings?.url
    },
    useIntroAndOutroSlides: eventDetailsContext.eventInfo.useIntroAndOutroSlides ?? slidesOn,
    receiveIsoRecordings: eventDetailsContext.eventInfo.receiveIsoRecordings,
    chatSettings: {
      requireRegistration: eventDetailsContext.eventInfo.chatSettings?.requireRegistration,
      showViewers: eventDetailsContext.eventInfo.chatSettings?.showViewers,
      registration: eventDetailsContext.eventInfo.chatSettings?.registration,
      liveChat: eventDetailsContext.eventInfo.chatSettings?.liveChat
    },
    repeatSettings: {
      repeat: eventDetailsContext.eventInfo.repeatSettings?.repeat,
      repeatFrequency: {
        repeatEvery:
          eventDetailsContext.eventInfo.repeatSettings?.repeatFrequency?.repeatEvery ?? 1,
        unit:
          eventDetailsContext.eventInfo.repeatSettings?.repeatFrequency?.unit ??
          Calendar.Unit.WEEKS,
        daysOfWeek:
          eventDetailsContext.eventInfo.repeatSettings?.repeatFrequency?.daysOfWeek ??
          new Set<Calendar.Weekday>()
      },
      repeatUntil: {
        numRecurrences:
          eventDetailsContext.eventInfo.repeatSettings?.repeatUntil?.numRecurrences ?? 3,
        stopRepeatingOn:
          eventDetailsContext.eventInfo.repeatSettings?.repeatUntil?.stopRepeatingOn ??
          DateTime.utc()
            .plus({ weeks: 3, days: 2 })
            .startOf('hour')
            .setZone(timezone ?? account.timezone)
      },
      repeatEndCondition:
        eventDetailsContext.eventInfo.repeatSettings?.repeatEndCondition ??
        EventValidator.EventInfo.RepeatEndCondition.ON_DATE
    },
    zoomDetails: {
      streamZoom: false,
      meetingId: '',
      password: ''
    },
    cueSheetURL: eventDetailsContext.eventInfo.cueSheetURL ?? ''
  });

  useEffect(() => {
    if (eventDetailsContext.eventInfo.useIntroAndOutroSlides === undefined) {
      setInitialValues({ ...initialValues, useIntroAndOutroSlides: slidesOn });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDetailsContext.eventInfo.useIntroAndOutroSlides, slidesOn]);

  const formik = {
    initialValues,
    enableReinitialize: true,
    validate: (
      values: Exclude<EventValidator.EventInfo, { start: DateTime; end: DateTime }>
    ): EventValidator.Errors => {
      const { cueSheetURL } = values;

      const errors = validateNewEventStep4(
        {
          availableCredits: billing?.credits.total ?? 0,
          events: allEvents ?? [],
          cueSheetURL: EventDetailsUtils.isCueSheetEnabled(availableLocations, values.location)
            ? cueSheetURL
            : undefined,
          newEventInfo: {
            zoomDetails: {
              meetingId: values.zoomDetails?.meetingId,
              password: values.zoomDetails?.password
            },
            start: eventDetailsContext.eventInfo.start,
            end: eventDetailsContext.eventInfo.end,
            ..._.omit(values, ['startTime', 'endTime', 'date'])
          },
          simulatedLiveAssetIsUpload: eventDetailsContext.simulatedLiveAssetIsUpload,
          timezone: timezone ? new IANAZone(timezone) : account.timezone,
          isAdmin,
          isProd: production
        },
        billing
      );

      eventDetailsContext.setEventInfo({
        ...eventDetailsContext.eventInfo,
        ..._.omit(values, ['startTime', 'endTime', 'date']),
        isValid: !!values.title && _.isEmpty(errors)
      });

      return errors;
    },
    onSubmit(): void {
      onSchedule();
    }
  };

  // Remove previous Zoom Data if switched to Static
  if (formik.initialValues.type === EventValidator.ProductionType.STATIC) {
    formik.initialValues.zoomDetails = {
      streamZoom: false,
      meetingId: '',
      password: ''
    };
  }

  const formLayout = useCallback(
    (): React.ReactElement => (
      <>
        <DestinationsSection destinations={destinations} />
        {eventDetailsContext.eventType !== EventValidator.ProductionType.TEST ? (
          <EventDetailsForm.Wrapper>
            <EventImages />
            <WebplayerFeatures accountSettings={accountSettings} />
            {(eventDetailsContext.eventType === EventValidator.ProductionType.PRODUCED ||
              eventDetailsContext.eventType ===
                EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT) && (
              <VideographerFeatures
                showZoomInput={showZoomInput}
                slidesOn={slidesOn}
                accountSettings={accountSettings}
              />
            )}
            <OtherOptions timezone={timezone} accountSettings={accountSettings} />
            <FooterSection>
              <EventDetailsForm.Submit text='Finish' onBack={onBack} />
            </FooterSection>
          </EventDetailsForm.Wrapper>
        ) : (
          <EventDetailsForm.Wrapper>
            <FooterSection>
              <EventDetailsForm.Submit text='Finish' onBack={onBack} />
            </FooterSection>
          </EventDetailsForm.Wrapper>
        )}
      </>
    ),
    [destinations, eventDetailsContext.eventType, accountSettings, showZoomInput, slidesOn, timezone, onBack]
  );

  return loading ? (
    <Loading />
  ) : (
    <Formik {...formik}>{(): React.ReactElement => formLayout()}</Formik>
  );
};
