import { Device, Grid, Theme } from '@livecontrol/core-ui';
import cx from 'classnames';
import type { HTMLAttributes, ReactElement } from 'react';
import styled from 'styled-components';
import { Event } from './event';
import { Keyword } from './keyword';
import { Location } from './location';
import { Period } from './period';
import { Sorting } from './sorting';

const ActionsBar = styled.div`
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 30px;
  margin-top: 6px;

  .search-input-section {
    min-width: 300px;
    margin-bottom: 10px;

    input {
      color: #0d1438;
      font-size: 12px;
      font-weight: 600;
    }
    div {
      background-color: transparent;
      border-color: #8798ad;
      color: #0d1438;
    }
  }

  input::-webkit-input-placeholder {
    /* Edge */
    color: #0d1438;
    font-size: 12px;
    font-weight: 600;
  }
  input::placeholder {
    color: #0d1438;
    font-size: 12px;
    font-weight: 600;
  }
`;

export const Actions = ({ ...rest }: HTMLAttributes<HTMLDivElement>): ReactElement => {
  const viewport = Theme.useViewport();
  const size = Device.isMobile(viewport) ? undefined : 'lg';

  return (
    <ActionsBar className='d-flex align-items-center justify-content-xl-between flex-xl-row flex-column'>
      <div className='search-input-section'>
        <Keyword size={size} style={{ backgroundColor: 'white' }} />
      </div>
      <Grid
        className={cx(
          'grid-flow-column',
          Device.isMobile(viewport) ? null : 'gap-8px',
          'justify-content-end'
        )}
        style={{ marginBottom: '10px' }}
        {...rest}
      >
        <Location size={size} />
        <Period size={size} />
        <Event size={size} />
        <Sorting size={size} />
      </Grid>
    </ActionsBar>
  );
};
