import classnames from 'classnames';
import type { ReactElement } from 'react';
import { usePagination } from './use-pagination';

interface props {
    onPageChange: (page: number) => void;
    totalCount: number;
    siblingCount?: number;
    currentPage: number;
    pageSize: number;
    className: string;
}

export const Pagination = ({
    className,
    currentPage,
    onPageChange,
    pageSize,
    siblingCount = 1,
    totalCount
}: props): ReactElement | null => {

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = (): void => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = (): void => {
        onPageChange(currentPage - 1);
    };

    const lastPage = paginationRange[paginationRange.length - 1];

    return (
    <nav className={classnames('pagination-container mt-16px', { [className]: className })} aria-label='Pagination'>
        <ul className='pagination d-flex justify-content-end'>
            <li className='page-item mr-4px'>
                <button type='button' aria-label='Previous'
                    className={classnames('btn btn-sm btn-outline-tertiary', {
                        disabled: currentPage === 1
                    })}
                    disabled={currentPage === 1}
                    onClick={onPrevious}>
                    Previous
                </button>
            </li>
            {paginationRange.map(pageNumber => (
                <li className='page-item mx-4px' key={pageNumber}>
                {
                    pageNumber === -1 ?
                    (
                        <button type='button'
                            className='btn btn-sm'>
                            &#8230;
                        </button>
                    ) : (
                        <button type='button'
                            className={classnames('btn btn-sm', {
                            'btn-primary': pageNumber === currentPage,
                            'btn-outline-tertiary': pageNumber !== currentPage
                            })}
                            onClick={(): void => {
                                onPageChange(pageNumber);
                            }}>
                        {pageNumber}
                        </button>
                    )
                }
                </li>)
            )}
            <li className='page-item ml-4px'>
                <button type='button' aria-label='Next'
                    className={classnames('btn btn-sm btn-outline-tertiary', {
                        disabled: currentPage === lastPage
                    })}
                    disabled={currentPage === lastPage}
                    onClick={onNext}>
                    Next
                </button>
            </li>
        </ul>
    </nav>
    );
};
