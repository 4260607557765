import { Loading } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import type { Event } from '@livecontrol/scheduler/model';
import { Asset } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { Formik } from 'formik';
import React from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Form } from '../../../../components';
import { Store } from '../../../../store';
import { CustomThumbnail } from './custom-thumbnail';
import type { Props } from './form';
import { useForm } from './form';

// @fixme: Josh - remove this
const StyledForm = styled(Form)`
  .large-form-label {
    font-size: 15px;
    color: var(--dark);
    text-transform: none;
  }
`;

const { Constraints } = Asset;

const EditorBody = (props: Props): React.ReactElement => {
  const { formik, loading, alert } = useForm(props);
  const { onClose } = props;

  return (
    <Formik {...formik}>
      <StyledForm>
        <Modal.Body>
          <Form.Alert show={alert.show} onClose={alert.hide}>
            {alert.message}
          </Form.Alert>

          <Form.Group
            name='title'
            labelProps={{
              className: 'large-form-label'
            }}
            controlProps={{
              autoSelect: true,
              maxLength: Constraints.TITLE_MAX_LENGTH
            }}
          />

          <Form.Group
            name='description'
            labelProps={{
              className: 'large-form-label'
            }}
            controlProps={{
              as: 'textarea',
              rows: 8,
              maxLength: Constraints.DESCRIPTION_MAX_LENGTH
            }}
          />
          {props.event && <CustomThumbnail event={props.event} />}
        </Modal.Body>

        <Modal.Footer>
          <Modal.ButtonBox>
            <Modal.Submit loading={loading}>Save Changes</Modal.Submit>
            <Modal.Button variant='outline-secondary' onClick={onClose}>
              Cancel
            </Modal.Button>
          </Modal.ButtonBox>
        </Modal.Footer>
      </StyledForm>
    </Formik>
  );
};

const InjectEvent = ({
  event,
  children
}: {
  event: Event.Like;
  children: (props: { loading: boolean; event?: Event }) => React.ReactElement;
}): React.ReactElement => {
  const account = Store.Account.useAccount();
  const { event: eventData, loading } = Scheduler.Event.useEvent({ event, account });

  return children({ event: eventData, loading });
};

export const Editor = (props: Props): ReactElement => {
  const { onClose } = props;

  return (
    <Modal show backdrop='static' keyboard={false} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Video Details</Modal.Title>
      </Modal.Header>

      {props.asset.event ? (
        <InjectEvent event={props.asset.event}>
          {({ loading, event }): React.ReactElement =>
            loading ? <Loading /> : <EditorBody {...props} event={event} />
          }
        </InjectEvent>
      ) : (
        <EditorBody {...props} />
      )}
    </Modal>
  );
};
