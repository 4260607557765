import { Asset } from '@livecontrol/scheduler/model';
import type { Event } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import React from 'react';
import styled from 'styled-components';
import { Store } from '../../../store';

const InfoContainer = styled.div`
  padding-top: 24px;

  .title-section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 10px;

    h1 {
      font-weight: 700;
      font-size: 24px;
      color: #2e384d;
      margin-bottom: 0px;
      margin-right: 14px;
      word-wrap: break-word;
      min-width: 100px;
    }

    .icon {
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }

    .lock {
      background-image: url(/assets/icons/Lock-grey.svg);
    }

    .eye-slash {
      background-image: url(/assets/icons/Eye-slash-grey.svg);
    }
  }

  .info-section {
    display: flex;
    padding: 0px 4px;
    justify-content: space-between;

    .main-info {
      width: calc(100% - 90px);
      display: flex;
      flex-wrap: wrap;

      .date {
        margin-top: 3px;
        font-size: 14px;
        font-weight: 600;
        color: #2e384d;
        margin-bottom: 16px;
        margin-right: 16px;
      }

      .time {
        margin-top: 3px;
        color: #9c9c9c;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 16px;
        margin-right: 16px;
      }

      .location-section {
        display: flex;
        align-items: center;
        margin-top: 3px;
        margin-bottom: 16px;
        margin-right: 16px;

        .icon {
          width: 16px;
          height: 16px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
        }

        .location-point {
          background-image: url(/assets/icons/Location_point.svg);
        }

        .text {
          color: #9c9c9c;
          font-size: 14px;
          font-weight: 600;
        }
      }

      .id-section {
        display: flex;
        align-items: center;
        margin-top: 3px;
        margin-bottom: 16px;
        margin-right: 16px;

        .icon {
          width: 16px;
          height: 16px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
        }

        .link {
          background-image: url(/assets/icons/Link_icon.svg);
        }

        .text {
          color: #9c9c9c;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    .type-info-section {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      width: 90px;

      .type-label {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1px 6px;
        min-width: 60px;
        min-height: 20px;
        border-radius: 4px;
        background-color: rgba(135, 152, 173, 0.2);

        .text {
          font-size: 11px;
          font-weight: 600;
          color: #2e384d;
          text-transform: capitalize;
        }
      }
    }
  }
`;

interface Props {
  asset: Asset;
  event?: Event.Like;
  eventLocation: string;
}

export const DataSection = ({ asset, event, eventLocation }: Props): React.ReactElement => {
  const account = Store.Account.useAccount();
  const timestamp = asset.timestamp.setZone(account.timezone);
  const isPasswordProtected = !!asset.eventPassword;
  let isPublic = false;

  if (asset.visibility !== Asset.Visibility.Private) {
    isPublic = true;
  }

  let eventType = '';

  if (event) {
    const { event: eventData } = Scheduler.Event.useEvent({ account, event });

    eventType = eventData?.type ? `${eventData.type}` : '';
  }

  return (
    <InfoContainer>
      <div className='title-section'>
        <h1>{asset.title}</h1>
        {!isPublic ? <div className='icon eye-slash' /> : ''}
        {isPasswordProtected ? <div className='icon lock' /> : ''}
      </div>
      <div className='info-section'>
        <div className='main-info'>
          <div className='date'>{timestamp.toFormat('LLLL d, y')}</div>
          <div className='time'>
            {timestamp.toFormat('h:mm a')}
            {asset.duration ? ` - ${timestamp.plus(asset.duration).toFormat('h:mm a')} ` : null}
          </div>

          {eventLocation ? (
            <div className='location-section'>
              <div className='icon location-point' />
              <div className='text'>{eventLocation}</div>
            </div>
          ) : null}

          <div className='id-section'>
            <div className='text'>EID: {event ? event : '--'}</div>
          </div>
        </div>

        {event && eventType ? (
          <div className='type-info-section'>
            <div className='type-label'>
              <div className='text'>{eventType}</div>
            </div>
          </div>
        ) : null}
      </div>
    </InfoContainer>
  );
};
