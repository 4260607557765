import type { HTMLAttributes, ReactElement } from 'react';
import { NoMatch } from '../common';
import { EventsSection } from '../event-rows';
import { Hooks } from '../hooks';
import { Phase, useStore } from '../store';
import { Actions } from './actions';

const PHASE = Phase.Past;

export const Past = (props: HTMLAttributes<HTMLDivElement>): ReactElement => {
  const events = useStore(({ model }) => model.value[PHASE].events);

  // Transform the events
  const xform = Hooks.useTransform(PHASE, events);

  return (
    <div {...props}>
      <Actions className='mb-32px' />
      {xform.length ? <EventsSection transformed={xform} /> : <NoMatch phase={PHASE} />}
    </div>
  );
};
