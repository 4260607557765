/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useRef } from 'react';
import type { ReactElement } from 'react';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';

interface Props {
  data: { x: number | string; y: number }[];
}

const axes = {
  grid: { tickColor: '#E0E7FF' },
  ticks: {
    beginAtZero: true,
    padding: 5,
    color: 'rgba(0,0,0,0.5)',
    fontSize: 10,
    maxTicksLimit: 3,
    minRotation: 0,
    maxRotation: 0
  }
};

const ConcurrrentViewsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .header {
    width: 100%;
    display: flex;
    padding: 10px 5px;
    color: #2e384d;
    font-size: 13px;
    font-weight: 600;
  }
  .content {
    width: 100%;
  }
`;

const StyledTooltip = styled.div`
  .tooltip-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    transition: 0.3s transform;
  }
  .chart-tooltip {
    display: none;
    transform: translate(-50%, calc(-100% - 8px));
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 10px;
    padding: 10px;
    font-size: 12px;
    pointer-events: none;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -4px;
      width: 0;
      height: 0;
      border-top: solid 8px rgba(0, 0, 0, 0.8);
      border-left: solid 4px transparent;
      border-right: solid 4px transparent;
    }
  }
  &:hover {
    .chart-tooltip {
      display: block;
    }
  }
`;

export const ConcurrrentViewsChart = ({ data }: Props): ReactElement => {
  const primary = '#3491F8';
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  return (
    <ConcurrrentViewsSection>
      <div className='header'>CONCURRENT VIEWERS</div>
      <div className='content'>
        <StyledTooltip>
          <div ref={tooltipRef} className='tooltip-wrapper'>
            <div className='chart-tooltip'>
              <div className='font-weight-bold xvalue' />
              <div className='mt-8px'>
                <span className='yvalue' /> Viewers
              </div>
            </div>
          </div>
          <Line
            style={{
              maxWidth: '100%'
            }}
            data={{
              labels: data.map((v) => v.x),
              datasets: [
                {
                  fill: true,
                  backgroundColor: 'rgba(52,145,248, 0.1)',
                  borderColor: primary,
                  borderCapStyle: 'butt',
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: 'miter',
                  pointBorderColor: primary,
                  pointBackgroundColor: '#fff',
                  pointBorderWidth: 2,
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: primary,
                  pointHoverBorderColor: 'rgba(220,220,220,1)',
                  pointHoverBorderWidth: 2,
                  pointRadius: 0,
                  pointHitRadius: 10,
                  data
                }
              ]
            }}
            options={{
              plugins: {
                legend: {
                  display: false
                },
                tooltip: {
                  enabled: false,
                  interaction: {
                    intersect: false,
                    mode: 'x'
                  },
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  external(context: any): void {
                    try {
                      const tooltipModel = context.tooltip;
                      const pos = context.chart.canvas.getBoundingClientRect();

                      const tooltip = tooltipRef.current;

                      if (!tooltip) {
                        return;
                      }

                      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                      const left = pos.left + tooltipModel.caretX;
                      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                      const top = pos.top + tooltipModel.caretY;

                      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                      tooltip.style.transform = `translate(${left}px, ${top}px)`;

                      const xValue = tooltipModel.dataPoints[0].raw.x;
                      const yValue = tooltipModel.dataPoints[0].raw.y;

                      const xDiv = tooltip.querySelector('.xvalue');
                      const yDiv = tooltip.querySelector('.yvalue');

                      if (xDiv && yDiv) {
                        xDiv.innerHTML = xValue;
                        yDiv.innerHTML = yValue;
                      }
                    } catch {
                      // Ignore.
                    }
                  }
                }
              },
              scales: {
                x: axes,
                y: axes
              }
            }}
          />
        </StyledTooltip>
      </div>
    </ConcurrrentViewsSection>
  );
};
