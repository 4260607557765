import type { Account } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { useCallback } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Form } from '../../../components';
import { Store } from '../../../store';
import { DonationSection } from './donation-section';

const Container = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 34px;
  padding-top: 24px;
  border-bottom: 1px solid #d5daea;

  .text-section {
    min-width: 300px;
    width: 300px;
    margin-bottom: 10px;
    margin-right: 26px;
  }

  .layout-options-section {
    width: 100%;
    max-width: 600px;

      .custom-control-label::before {
        width: 41px;
        height: 18px;
      }
      .custom-switch .custom-control-input ~ .custom-control-label::after {
        top: 6px;
        width: 14px;
        height: 14px;
      }
      .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
        transform: translateX(21px);
      }
    }
  }

  .open-donation-modal-link {
    display: block;
    color: #2e5bff;
    font-weight: 600;
    font-size: 15px;
    padding: 0px;
  }

  @media (max-width: 1199px) {
    flex-direction: column;
    .text-section {
      width: 100%;
      text-align: center;
      margin-right: 0px;
      align-items: center;
      display: flex;
      flex-direction: column;
    }
    .layout-options-section {
      max-width: 100%;
    }
  }
`;

const WebplayerTabSwitchField = ({
  title,
  description,
  disabled,
  fieldName,
  webplayer,
  account,
  className,
  onUpdate
}: {
  title: string;
  description: string;
  disabled?: boolean;
  webplayer: Account.WebPlayer;
  account: Account.Like;
  fieldName: keyof Account.WebPlayer.Tabs;
  className?: string;
  onUpdate?: (value: boolean) => void;
}): React.ReactElement => {
  const [editWebPlayer, { loading }] = Scheduler.Account.WebPlayer.useEdit();

  const mutateWebPlayer = useCallback(
    async (value: boolean) =>
      editWebPlayer(
        account,
        { tabs: { [fieldName]: value } },
        true
      ) as Promise<unknown> as Promise<void>,
    [account, editWebPlayer, fieldName]
  );

  const onChange = useCallback(
    async (value: boolean) => {
      if (disabled) {
        return;
      }

      await mutateWebPlayer(value);
      onUpdate?.(value);
    },
    [disabled, mutateWebPlayer, onUpdate]
  );

  return (
    <div className='position-relative'>
      <Form.SwitchField
        abledDescription={false}
        className={className}
        title={title}
        titleClassName='title text-dark mb-4px mr-lg-3 font-size-15px font-weight-bold'
        description={description}
        descriptionClassName='text-gray-light font-size-13px text-secondary font-weight-bold'
        disabled={disabled}
        onChange={onChange}
        loading={loading}
        checked={!!webplayer.tabs?.[fieldName]}
      />
    </div>
  );
};

export const CustomizeLayoutSection = ({
  webplayer
}: {
  webplayer: Account.WebPlayer;
}): ReactElement => {
  const account = Store.Account.useAccount();
  const accountId = account.id;

  const [editWebPlayer] = Scheduler.Account.WebPlayer.useEdit();

  const mutateWebPlayerToDefaults = useCallback(
    async () =>
      editWebPlayer(
        account,
        { tabs: { chat: true, profile: true, archive: false, schedule: false } },
        true
      ) as Promise<unknown> as Promise<void>,
    [account, editWebPlayer]
  );

  const resetDefault = useCallback(async () => {
    await mutateWebPlayerToDefaults();
  }, [mutateWebPlayerToDefaults]);

  const clickOnBtn = (): void => {
    const openDonationModalBtn = document.querySelector('.open-donation-modal-btn');

    if (openDonationModalBtn instanceof HTMLElement) {
      openDonationModalBtn.click();
    }
  };

  return (
    <Container>
      <div className='text-section'>
        <div className='text-dark font-size-15px font-weight-bold'>Customize Your Layout</div>
        <button
          type='button'
          className='btn btn-link d-block text-primary font-weight-bold font-size-15px p-0'
          onClick={resetDefault}
        >
          Reset to default
        </button>
      </div>
      <div className='layout-options-section'>
        <WebplayerTabSwitchField
          className='mt-24px tab-switch-section'
          title='Profile Page'
          fieldName='profile'
          description='Show your profile page'
          webplayer={webplayer}
          account={accountId}
        />
        {webplayer.tabs?.profile ? (
          <>
            <WebplayerTabSwitchField
              className='mt-24px tab-switch-section'
              title='Schedule'
              fieldName='schedule'
              disabled={!webplayer.tabs.profile}
              description='Show upcoming and live events on your profile page'
              webplayer={webplayer}
              account={accountId}
            />
            <WebplayerTabSwitchField
              className='mt-24px tab-switch-section'
              title='Archive'
              fieldName='archive'
              disabled={!webplayer.tabs.profile}
              description='Show past events on your profile page'
              webplayer={webplayer}
              account={accountId}
            />
          </>
        ) : null}
        <div className='tab-switch-section'>
          <DonationSection webplayer={webplayer} />
          <button
            type='button'
            className='btn btn-link open-donation-modal-link'
            onClick={clickOnBtn}
          >
            Button Settings
          </button>
        </div>
      </div>
    </Container>
  );
};
