import type { HTMLAttributes, PropsWithChildren } from 'react';
import React, { useState } from 'react';
import { Section } from './section';

/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */

type Props = HTMLAttributes<HTMLDivElement> &
  PropsWithChildren<{
    title: string;
  }>;

export const Accordion = ({ title, children, ...props }: Props): React.ReactElement => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <Section
      {...props}
      header={
        <div
          className='d-flex align-items-center cursor-pointer pointer-events-none'
          onClick={(): void => {
            setIsExpanded(!isExpanded);
          }}
        >
          <Section.Heading className='flex-grow-1'>{title}</Section.Heading>
          <span key={isExpanded ? 'exp' : 'nexp'}>
            {isExpanded ? <i className='fa fa-angle-down' /> : <i className='fa fa-angle-up' />}
          </span>
        </div>
      }
    >
      <Section.Content className={isExpanded ? 'd-block' : 'd-none'}>{children}</Section.Content>
    </Section>
  );
};
