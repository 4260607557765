/* eslint-disable react-hooks/rules-of-hooks */
import { Loading } from '@livecontrol/core-ui';
import type { UUID } from '@livecontrol/core-utils';
import { JaySON, Num, Obj, Str } from '@livecontrol/core-utils';
import { Modal } from '@livecontrol/scheduler/components';
import { Destination } from '@livecontrol/scheduler/model';
import type { Event } from '@livecontrol/scheduler/model';
import qs from 'qs';
import { useMemo } from 'react';
import type { ReactElement } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Store } from '../../store';
import { useFacebookAuth, useYoutubeAuth } from './auth';

// STEP2
const AuthenticateFacebookYoutube = ({
  code,
  uuid,
  eventId,
  authenticate
}: {
  code: string;
  uuid: string;
  eventId?: Event.ID;
  authenticate: (
    code: string,
    uuid: string,
    eventId: Event.ID | undefined
  ) => {
    error: unknown;
    loading: boolean;
  };
}): ReactElement => {
  const { error } = authenticate(code, uuid, eventId);

  const history = useHistory();

  const modalPrompt = Modal.usePrompt();

  if (error) {
    modalPrompt.error({
      title: 'Connection Failed',
      content:
        'Oops! Something went wrong and there was an issue in reconnecting your account. Please try again.',
      onClose() {
        if (eventId) {
          history.push(`/schedule/edit/${eventId}/${uuid}`);
        } else {
          history.push(`/schedule/create/${uuid}`);
        }
      }
    });
  }

  return <Loading />;
};

// STEP1
export const ReconnectDestination = (): ReactElement | null => {
  const me = Store.User.useMe();

  if (!me.permissions.someLocationHasEventManagmentEnabled) {
    return <Redirect to='/schedule' />;
  }

  const location = useLocation();

  const params = useMemo(() => qs.parse(location.search.slice(1)), [location]);

  const code = useMemo(() => Str.normalize(params.code), [params]);

  const state = useMemo(
    () =>
      Obj.normalize<{ service: string; uuid: UUID; eventId?: Event.ID }>(
        JaySON.parse(Str.normalize(params.state) ?? '')
      ),
    [params]
  );

  const service = useMemo(() => Destination.Service.normalize(state?.service), [state]);
  const uuid = useMemo(() => Str.normalize(state?.uuid), [state]);
  const eventId = useMemo(() => Num.normalize(state?.eventId), [state]);

  if (code && service) {
    return (
      <AuthenticateFacebookYoutube
        code={`${code}${location.hash}`}
        uuid={uuid ?? ''}
        eventId={eventId}
        authenticate={service === Destination.Service.Facebook ? useFacebookAuth : useYoutubeAuth}
      />
    );
  }

  return null;
};
