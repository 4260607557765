import { gql } from '@apollo/client';

export const SignUp = gql`
  mutation CreateUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
    $passwordConfirmation: String!
    $phoneNumber: String!
    $timeZone: String!
    $organizationName: String! # $organizationZipCode: String!,
  ) {
    createUser(
      input: {
        first_name: $firstName
        last_name: $lastName
        email: $email
        password: $password
        passwordConfirmation: $passwordConfirmation
        role: 6
        phone_number: $phoneNumber
        time_zone: $timeZone
        organization_name: $organizationName
        # organization_zip_code: $organizationZipCode
        industry: 1
      }
    ) {
      token
      user {
        time_zone
      }
    }
  }
`;

export const SignIn = gql`
  mutation Authenticate($email: String!, $password: String!) {
    authenticate(input: { email: $email, password: $password }) {
      token
      user {
        time_zone
        status {
          id
        }
      }
    }
  }
`;
