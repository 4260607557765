import type { Account } from '@livecontrol/scheduler/model';
import _ from 'lodash';
import type { ReactElement } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { SectionContainer } from '../../../components';
import { Store } from '../../../store';
import { AboutSection } from './about-section';
import { CustomizeLayoutSection } from './customize-layout-section';
import { DisplayNameSection } from './display-name-section';
import { FollowUsSection } from './follow-us-section';
import { Header } from './header';
import { IntroImageSection } from './intro-image-section';
import { OutroImageSection } from './outro-image-section';
import { PlayerBackgroundSection } from './player-background-section';
import { PlayerLogoSection } from './player-logo-section';
import { ProfilePageSection } from './profile-page-section';
import { ThemeSection } from './theme-section';

const Wrapper = styled.div`
  max-width: 916px;
`;

const ImagesSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 916px;
  padding: 4px 0px 14px;
  width: 100%;
  max-width: 916px;
  border-bottom: 1px solid #d5daea;

  .images-row {
    padding: 20px 0px;
  }
`;

export const Layout = ({ webplayer }: { webplayer: Account.WebPlayer }): ReactElement => {
  const me = Store.User.useMe();

  const { hash } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);

      if (element) {
        window.scrollTo(0, element.getBoundingClientRect().top - 100);
      }
    }
  }, [hash]);

  return (
    <SectionContainer className='col-12'>
      <Header />
      <Wrapper>
        <DisplayNameSection webplayer={webplayer} />
        {me.permissions.admin && <ProfilePageSection webplayer={webplayer} />}
        <ThemeSection webplayer={webplayer} />
        <ImagesSection>
          <div className='images-row d-flex w-100 justify-content-between flex-wrap flex-lg-nowrap'>
            <PlayerLogoSection webplayer={webplayer} />
            <PlayerBackgroundSection webplayer={webplayer} />
          </div>
          <div
            id='intro-outro'
            className='d-none images-row w-100 justify-content-between flex-wrap flex-lg-nowrap'
          >
            <IntroImageSection webplayer={webplayer} />
            <OutroImageSection webplayer={webplayer} />
          </div>
        </ImagesSection>
        <AboutSection webplayer={webplayer} />
        <FollowUsSection webplayer={webplayer} />
        <CustomizeLayoutSection webplayer={webplayer} />
      </Wrapper>
    </SectionContainer>
  );
};
