import { Button } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import { Asset } from '@livecontrol/scheduler/model';
import _ from 'lodash';
import React, { useState } from 'react';
import type { ReactElement } from 'react';
import FormCheck from 'react-bootstrap/FormCheck';

interface Props {
  asset: Asset;
  onConfirm: (commit: boolean, privitize?: boolean) => void;
}

export const Confirm = ({ asset, onConfirm }: Props): ReactElement => {
  const [isChecked, setChecked] = useState(false);

  return (
    <Modal.Prompt
      {...{
        show: true,
        icon: 'fal fa-trash-alt',
        title: 'Permanently delete this video?',
        okay: {
          variant: 'danger',
          label: 'Delete Forever',
          disabled: !isChecked
        },
        onOkay: _.partial(onConfirm, true),
        onCancel: _.partial(onConfirm, false)
      }}
    >
      <p className='text-center'>
        By deleting, this video will be permanently removed from our systems.
        <br />
        Are you sure you want to delete this video: <br />
        <strong>&quot;{asset.title}&quot;</strong>?
      </p>

      {(asset.visibility === Asset.Visibility.Public && asset.event) && (
        <div className='d-flex flex-column pt-16px align-items-center'>
          <p className='mb-8px'>To hide this video from your viewers, you can</p>

          <Button
            className='btn-wide border-0 btn-tint'
            size='sm'
            onClick={(): void => {
              onConfirm(false, true);
            }}
          >
            <i className='far fa-eye-slash' />
            Make Private instead of deleting
          </Button>
        </div>
      )}


      <div className='pt-32px d-flex justify-content-center'>
        <FormCheck
          id='asset-delete--understand'
          type='checkbox'
          checked={isChecked}
          label="I understand that deleting will be permanent and can't be undone."
          onChange={(e: React.FormEvent<HTMLInputElement>): void => {
            setChecked(e.currentTarget.checked);
          }}
        />
      </div>
    </Modal.Prompt>
  );
};
