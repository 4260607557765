import { gql, useMutation } from '@apollo/client';
import { Bool, EmailAddress, Num, Obj } from '@livecontrol/core-utils';
import type { User } from '@livecontrol/scheduler/model';
import { Account } from '@livecontrol/scheduler/model';
import { useCallback, useState } from 'react';
import type { MutationResult } from '../../graphql';
import { Errors } from '../../graphql';

interface TVariables {
  upsertSubUserPermissionsArgs: UpsertSubUserPermissionsArgs;
}

interface UpsertSubUserPermissionsArgs {
  id?: number;
  client: number;
  subUser?: User;
  email?: string;
  destinations: boolean;
  customize_webplayer: boolean;
  organization_account_info: boolean;
  billing: boolean;
  admin_access: boolean;
  event_managment?: boolean;
  content_managment?: boolean;
  view_and_download?: boolean;
  activity_log?: boolean;
  customize_production_notes?: boolean;
  some_location_has_event_managment_enabled?: boolean;
  some_location_has_view_and_download_enabled?: boolean;
  some_location_has_content_managment_enabled?: boolean;
  some_location_has_activity_log_enabled?: boolean;
  some_location_has_customize_production_notes_enabled?: boolean;
}

interface TData {
  upsertSubUserPermissions?: {
    success: boolean;
    result?: UpsertSubUserPermissionsArgs;
    error?: {
      code: string;
      message: string;
    };
  };
}

const MUTATION = gql`
  mutation UpsertSubUserPermission($upsertSubUserPermissionsArgs: UpsertSubUserPermissionsArgs!) {
    upsertSubUserPermissions(upsertSubUserPermissionsArgs: $upsertSubUserPermissionsArgs) {
      success
      result {
        id
        event_managment
        view_and_download
        customize_production_notes
        content_managment
        destinations
        customize_webplayer
        organization_account_info
        activity_log
        billing
        admin_access
      }
      error {
        code
        message
      }
    }
  }
`;

interface Args {
  id?: number;
  account: Account.Like;
  email?: string;
  destinations: boolean;
  customize_webplayer: boolean;
  organization_account_info: boolean;
  billing: boolean;
  admin_access: boolean;
  event_managment?: boolean;
  content_managment?: boolean;
}

export const useUpsertPermissions = (): [
  (args: Args) => Promise<Account.SubUser.Permissions | undefined>,
  MutationResult<Account.SubUser.Permissions, 'permissions'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [permissions, setPermissions] = useState<Account.SubUser.Permissions | undefined>();

  return [
    useCallback(
      async (args: Args): Promise<Account.SubUser.Permissions | undefined> => {
        let permissions_: Account.SubUser.Permissions | undefined;

        try {
          const client = Account.toId(args.account);
          const email = EmailAddress.normalize(args.email);

          if (!client || !email) {
            throw Errors.badRequest();
          }

          const id = args.id ? Num.normalize(args.id, { positive: true }) : undefined;

          const upsertSubUserPermissionsArgs: UpsertSubUserPermissionsArgs = {
            client,
            email,
            event_managment: Bool.normalize(args.event_managment),
            view_and_download: false,
            activity_log: false,
            customize_production_notes: false,
            content_managment: Bool.normalize(args.content_managment),
            destinations: Bool.normalize(args.destinations),
            customize_webplayer: Bool.normalize(args.customize_webplayer),
            organization_account_info: Bool.normalize(args.organization_account_info),
            billing: Bool.normalize(args.billing),
            admin_access: Bool.normalize(args.admin_access)
          };

          if (id) {
            upsertSubUserPermissionsArgs.id = id;
          }

          if (!Obj.isHydrated(upsertSubUserPermissionsArgs)) {
            throw Errors.badRequest();
          }

          const { data } = await mutation({
            variables: { upsertSubUserPermissionsArgs }
          });

          const candidate = {
            id: Num.normalize(data?.upsertSubUserPermissions?.result?.id, { positive: true }),
            eventManagment: Bool.normalize(data?.upsertSubUserPermissions?.result?.event_managment),
            eventSchedule: Bool.normalize(data?.upsertSubUserPermissions?.result?.event_managment),
            contentManagment: Bool.normalize(
              data?.upsertSubUserPermissions?.result?.content_managment
            ),
            archive: Bool.normalize(data?.upsertSubUserPermissions?.result?.content_managment),
            readOnlyArchive: Bool.normalize(
              data?.upsertSubUserPermissions?.result?.content_managment
            ),
            destinations: Bool.normalize(data?.upsertSubUserPermissions?.result?.destinations),
            webplayer: Bool.normalize(data?.upsertSubUserPermissions?.result?.customize_webplayer),
            accountInfo: Bool.normalize(
              data?.upsertSubUserPermissions?.result?.organization_account_info
            ),
            activityLog: Bool.normalize(data?.upsertSubUserPermissions?.result?.activity_log),
            billing: Bool.normalize(data?.upsertSubUserPermissions?.result?.billing),
            admin: Bool.normalize(data?.upsertSubUserPermissions?.result?.admin_access),
            productionNotes: Bool.normalize(
              data?.upsertSubUserPermissions?.result?.customize_production_notes
            ),
            viewAndDownload: Bool.normalize(
              data?.upsertSubUserPermissions?.result?.view_and_download
            ),
            organizationAccountInfo: Bool.normalize(
              data?.upsertSubUserPermissions?.result?.organization_account_info
            ),
            someLocationHasEventManagmentEnabled: Bool.normalize(
              data?.upsertSubUserPermissions?.result?.some_location_has_event_managment_enabled
            ),
            someLocationHasViewAndDownloadEnabled: Bool.normalize(
              data?.upsertSubUserPermissions?.result?.some_location_has_view_and_download_enabled
            ),
            someLocationHasContentManagmentEnabled: Bool.normalize(
              data?.upsertSubUserPermissions?.result?.some_location_has_content_managment_enabled
            ),
            someLocationHasActivityLogEnabled: Bool.normalize(
              data?.upsertSubUserPermissions?.result?.some_location_has_activity_log_enabled
            ),
            someLocationHasCustomizeProductionNotesEnabled: Bool.normalize(
              data?.upsertSubUserPermissions?.result
                ?.some_location_has_customize_production_notes_enabled
            )
          };

          if (Obj.isHydrated(candidate)) {
            permissions_ = candidate;
          }
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setPermissions(permissions_);

        return permissions_;
      },
      [mutation]
    ),
    {
      permissions,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
