import type { Asset } from '@livecontrol/scheduler/model';
import { Account } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import cx from 'classnames';
import { useMemo } from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';
import { Store, store } from '../../../../store';

type Props = HTMLAttributes<HTMLDivElement> & {
  asset: Asset;
};

// @FIXME: Needs refactor.
const StyledToggle = styled(Dropdown.Toggle)`
  &.dropdown-toggle.btn::after {
    display: none;
  }
`;

export const ViewEvent = ({ className, asset, ...props }: Props): ReactElement => {
  const account = Store.Account.useAccount();
  const { webPlayer, loading } = Scheduler.Account.WebPlayer.useView(account);
  const venueURI = store.getState().environment.VENUE_URI;

  const assetURL = useMemo((): string => {
    const webplayerEndpoint = webPlayer?.endpoint;

    if (!webplayerEndpoint) {
      return '';
    }

    return Account.WebPlayer.makeAssetURL({ venueURI, slug: webplayerEndpoint, asset });
  }, [asset, venueURI, webPlayer?.endpoint]);

  return (
    <a
      href={assetURL}
      target='_blank'
      className='mt-8px'
      style={{
        opacity: loading ? 0.3 : 1
      }}
      rel='noreferrer'
    >
      <StyledToggle
        variant='outline-dark'
        {...props}
        className={cx('py-0px px-8px align-self-start', 'font-size-12px text-uppercase', className)}
      >
        <i className='far fa-external-link' />
        <span>View Event</span>
      </StyledToggle>
    </a>
  );
};
