import { Calendar, Enum } from '@livecontrol/core-utils';
import cx from 'classnames';
import React, { useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import { Theme } from '../../theme';
import { Viewport } from '../../viewport';
import { Utils } from './utils';

const StyledTextIcon = styled.div`
  width: 22px;
  height: 22px;
  color: var(--text-dark);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Weekday = ({
  className,
  value,
  isInvalid,
  allowMultiple,
  ...rest
}: Weekday.Props): React.ReactElement => {
  const field = Utils.useField(rest);
  const viewport = Theme.useViewport();

  const onClick = useCallback(
    (weekday: Calendar.Weekday): void => {
      if (field) {
        let newSelectedWeekdays = new Set(value);

        if (allowMultiple) {
          if (newSelectedWeekdays.has(weekday)) {
            newSelectedWeekdays.delete(weekday);
          } else {
            newSelectedWeekdays.add(weekday);
          }
        } else if (newSelectedWeekdays.has(weekday)) {
          newSelectedWeekdays.delete(weekday);
        } else {
          newSelectedWeekdays = new Set<Calendar.Weekday>();
          newSelectedWeekdays.add(weekday);
        }

        field.helpers.setTouched(true);
        field.helpers.setValue(newSelectedWeekdays);
      }
    },
    [allowMultiple, value, field]
  );

  return (
    <div className={cx('d-flex', 'flex-row', 'mt-20px', isInvalid && 'is-invalid', className)}>
      {Enum.keys(Calendar.Weekday).map((key): React.ReactElement => {
        const weekday = Calendar.Weekday[key];

        return (
          <Button
            key={weekday}
            className={cx(
              viewport === Viewport.XS ? 'mr-12px' : 'mr-16px',
              'btn-round',
              value.has(weekday) && 'active'
            )}
            onClick={(): void => {
              onClick(weekday);
            }}
          >
            <StyledTextIcon>{weekday.charAt(0)}</StyledTextIcon>
          </Button>
        );
      })}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Weekday {
  export interface Props {
    className?: string;
    name: string;
    isInvalid: boolean;
    value: Set<Calendar.Weekday>;
    allowMultiple?: boolean;
  }
}
