import { CopyCTA, Device, Form, Loading, Theme } from '@livecontrol/core-ui';
import { Calendar } from '@livecontrol/core-utils';
import { Account, Destination, Event } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { EventValidator } from '@livecontrol/scheduler/validator';
import { assert } from '@sindresorhus/is';
import { DateTime } from 'luxon';
import ordinal from 'ordinal';
import React, { useContext, useMemo, useState } from 'react';
import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';
import { Store, store as storeInstance } from '../../../store';
import type { EventContext } from '../event-store';
import { EventContextStore } from '../event-store';

const SummaryWrapper = styled.div<{ isMobile: boolean }>`
  --min-width: ${({ isMobile }): string => (isMobile ? '300px' : '210px')};
  --num-columns: 3;
  --gap: 1rem;
  display: flex;

  > div {
    margin: 0 auto;
    max-width: calc(
      (var(--num-columns) * var(--min-width) + (var(--gap) * (var(--num-columns) - 1)))
    );
    display: grid;
    gap: var(--gap);
    ${({ isMobile }): SimpleInterpolation => {
      if (isMobile) {
        return css`
          grid-template-columns: var(--min-width);
          text-align: left;
        `;
      }

      return css`
        grid-template-columns: var(--min-width) var(--min-width) var(--min-width);
      `;
    }}
  }
`;

interface Props {
  eventId: number;
}

export const EventSummaryWrapper = ({ eventId }: Props): React.ReactElement => {
  const { environment } = storeInstance.getState();
  const account = Store.Account.useAccount();
  const { billing } = Scheduler.Account.useBilling(account);
  const { loading, webPlayer } = Scheduler.Account.WebPlayer.useView(account.id);
  const { store }: EventContext = useContext<EventContext>(EventContextStore);
  const { locations } = Scheduler.Location.useLocations(account);
  const useMobileLayout = Device.isMobile(Theme.useViewport());

  const eventDestinations = useMemo(() => {
    const destinations: string[] = [];

    if (store.eventDestinations.length > 0) {
      destinations.push(
        ...new Set(
          store.eventDestinations
            .map((destination) => Destination.Service[destination.service])
            .filter((destination) => destination !== 'Zoom')
        )
      );
    }

    if (store.eventInfo.zoomDetails?.streamZoom) {
      destinations.push('Zoom');
    }

    return destinations;
  }, [store]);

  const { passwordSettings } = store.eventInfo;
  const [showPasswordProtectedURL, setShowPasswordProtectedURL] = useState(false);

  const venueURL = useMemo((): string | undefined => {
    if (!webPlayer?.endpoint) {
      return undefined;
    }

    const { endpoint } = webPlayer;

    assert.string(endpoint);

    return Account.WebPlayer.makeURL({
      uri: environment.VENUE_URI,
      slug: webPlayer.endpoint,
      event: eventId,
      password: showPasswordProtectedURL
        ? passwordSettings?.enabled
          ? passwordSettings.password
          : undefined
        : undefined
    });
  }, [webPlayer, passwordSettings, environment.VENUE_URI, eventId, showPasswordProtectedURL]);

  const { start, end } = store.eventInfo;

  const creditsCharged = useMemo(() => {
    const eventDuration = start && end && end.diff(start);

    if (!billing || !eventDuration) {
      return 0;
    }

    if (store.eventInfo.type === EventValidator.ProductionType.PRODUCED) {
      return Event.Production.computeCreditsChargedForProducedEvent({
        duration: eventDuration,
        overTimeLimitCharge: billing.credits.over_time_limit_cost,
        overTimeLimitInMinutes: billing.credits.first_credit_time_limit
      });
    }

    return 0;
  }, [billing, end, start, store.eventInfo.type]);

  const isClientBooking = useMemo(
    () => store.eventInfo.type === EventValidator.ProductionType.CLIENT_BOOKING,
    [store]
  );

  const amountPaid = useMemo((): number | undefined => {
    if (
      isClientBooking &&
      store.clientBookingPaymentMethod === EventValidator.ClientBookingPaymentMethod.PAY_NOW
    ) {
      const isMobile = locations?.find(
        (location) => location.id === store.eventInfo.location
      )?.isMobile;

      return isMobile
        ? billing?.client_booking.cb2_host_price
        : billing?.client_booking.cb3_host_price;
    }

    return undefined;
  }, [billing, isClientBooking, locations, store]);

  const clientBookingUrl = Account.ClientBooking.makeClientBookingURL({
    eventId,
    slug: webPlayer?.endpoint ?? 'client',
    uri: environment.CLIENT_BOOKING_URI
  });

  return !locations || loading ? (
    <Loading.Delay />
  ) : (
    <>
      <p className='mt-12px text-secondary text-center'>
        {isClientBooking
          ? 'You’re all set! We’re reaching out to your client now, but giving them a heads up will help get the details worked out sooner.'
          : 'Please view a summary of your event below.'}
      </p>
      {isClientBooking ? <h2 className='font-size-20px'>Event Details</h2> : null}
      <SummaryWrapper className='pt-16px' isMobile={useMobileLayout}>
        <div>
          <div>
            <p className='font-weight-normal mb-0'>Title</p>
            <p className='text-muted'>
              {store.eventType === EventValidator.ProductionType.TEST
                ? `Test - ${store.eventInfo.title!}`
                : store.eventInfo.title}
            </p>
          </div>
          <div>
            <p className='font-weight-normal mb-0'>Location</p>
            <p className='text-muted'>
              {locations.find((location) => location.id === store.eventInfo.location)?.name}
            </p>
          </div>
          <div>
            <p className='font-weight-normal mb-0'>Event Destination</p>
            <p className='text-muted'>
              {eventDestinations.length > 0 ? eventDestinations.join(', ') : 'Private Event'}
            </p>
          </div>
          <div>
            <p className='font-weight-normal mb-0'>Start Date</p>
            <p className='text-muted'>
              {store.eventInfo.start?.setZone(account.timezone).toLocaleString(DateTime.DATE_MED)}
            </p>
          </div>
          <div>
            <p className='font-weight-normal mb-0'>Start & End Time</p>
            <p className='text-muted'>
              {store.eventInfo.start?.setZone(account.timezone).toFormat('h:mm a')} -{' '}
              {store.eventInfo.end?.setZone(account.timezone).toFormat('h:mm a')}
            </p>
          </div>
          {amountPaid ? (
            <div>
              <p className='font-weight-normal mb-0'>Amount Paid</p>
              <p className='text-muted'>{`$${amountPaid}.00`}</p>
            </div>
          ) : isClientBooking ? (
            <div>
              <p className='font-weight-normal mb-0'>Credits Charged</p>
              <p className='text-muted'>{`${creditsCharged} Credits`}</p>
            </div>
          ) : null}
          {
            store.eventInfo.redirectUrlSettings?.enabled && store.eventInfo.redirectUrlSettings.url ? (
              <div>
              <p className='font-weight-normal mb-0'>Redirect URL</p>
              <p className='text-muted'>{store.eventInfo.redirectUrlSettings.url}</p>
            </div>
            ) : null
          }
          {/* Don't show Webplayer URL for Record Only (private) events */}
          {store.eventDestinations.length > 0 && (!isClientBooking || !!amountPaid) && !!venueURL && (
            <div style={{ gridColumn: '1/-1' }}>
              <Form.Descriptor
                title='Web Player Link'
                titleClassName='font-weight-normal'
                descriptionClassName='text-secondary'
                description='Share this link to access your live event'
              >
                {passwordSettings?.password && (
                  <>
                    <Form.Radio
                      className='mt-16px'
                      selected={!showPasswordProtectedURL}
                      onClick={(): void => {
                        setShowPasswordProtectedURL(false);
                      }}
                      title='Web Player Without Password'
                      description='This link will prompt users to enter the password you set'
                    />
                    <Form.Radio
                      className='mt-8px'
                      selected={showPasswordProtectedURL}
                      onClick={(): void => {
                        setShowPasswordProtectedURL(true);
                      }}
                      title='Web Player With Password in the URL'
                      description='This link contains the password, so users will not have to type in the password'
                    />
                  </>
                )}

                <div className='d-flex mt-12px'>
                  <Form.Control value={venueURL} readOnly className='mr-8px' />
                  <CopyCTA value={venueURL} toastStyle={{ marginTop: 18 }}>
                    <Form.Submit size='md' style={{ minWidth: 150 }}>
                      <i className='fa fa-link mr-8px' />
                      Copy
                    </Form.Submit>
                  </CopyCTA>
                </div>
              </Form.Descriptor>
            </div>
          )}
          {store.eventInfo.repeatSettings?.repeat && store.eventInfo.start && (
            <div>
              <p className='font-weight-normal mb-0'>Recurring Event</p>
              <p className='text-muted mb-0'>
                {store.eventInfo.repeatSettings.repeatFrequency?.unit === Calendar.Unit.WEEKS &&
                store.eventInfo.repeatSettings.repeatFrequency.daysOfWeek
                  ? `Every ${[...store.eventInfo.repeatSettings.repeatFrequency.daysOfWeek].join(
                      ', '
                    )} at ${store.eventInfo.start
                      .setZone(account.timezone)
                      .toLocaleString(DateTime.TIME_SIMPLE)}`
                  : `Every month on the ${ordinal(
                      store.eventInfo.start.setZone(account.timezone).day
                    )}`}
              </p>
              <p className='text-muted'>
                {store.eventInfo.repeatSettings.repeatUntil?.numRecurrences
                  ? `For ${store.eventInfo.repeatSettings.repeatUntil.numRecurrences} recurrence(s)`
                  : store.eventInfo.repeatSettings.repeatUntil?.stopRepeatingOn
                  ? `Until ${store.eventInfo.repeatSettings.repeatUntil.stopRepeatingOn.toLocaleString(
                      DateTime.DATE_MED
                    )}`
                  : undefined}
              </p>
            </div>
          )}
        </div>
      </SummaryWrapper>
      {isClientBooking && (
        <>
          <h2 className='font-size-20px mb-16px mt-24px'>Contact Details</h2>
          <SummaryWrapper isMobile={useMobileLayout}>
            <div>
              <div>
                <p className='font-weight-normal mb-0'>Point of contact</p>
                <p className='text-muted lineclamp text-break'>
                  {store.clientContactDetails?.name}
                </p>
              </div>
              <div>
                <p className='font-weight-normal mb-0'>Phone number</p>
                <p className='text-muted'>{store.clientContactDetails?.phoneNumber}</p>
              </div>
              <div>
                <p className='font-weight-normal mb-0'>Email</p>
                <p className='text-muted lineclamp text-break'>
                  {store.clientContactDetails?.email}
                </p>
              </div>
            </div>
          </SummaryWrapper>
          {!amountPaid && (
            <>
              <h2 className='font-size-20px mb-16px mt-24px'>Event upgrade options</h2>
              <Form.Descriptor
                title='Upgrade options page'
                description='Use this link if you’d like to walk your client through the stream options.'
              >
                <div className='d-flex mt-12px'>
                  <Form.Control value={clientBookingUrl} readOnly className='mr-8px' />
                  <CopyCTA value={clientBookingUrl} toastStyle={{ marginTop: 18 }}>
                    <Form.Submit size='md' style={{ minWidth: 150 }}>
                      <i className='fa fa-link mr-8px' />
                      Copy
                    </Form.Submit>
                  </CopyCTA>
                </div>
              </Form.Descriptor>
            </>
          )}
        </>
      )}
    </>
  );
};
