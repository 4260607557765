import { Button } from '@livecontrol/core-ui';
import { Bool } from '@livecontrol/core-utils';
import { Event } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import qs from 'qs';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Store } from '../../../../store';
import { FeedbackModal } from './modal';
import { SuccessModal } from './success-modal';

const ButtonContainer = styled.div`
  button {
    background-color: #788db2 !important;
    color: white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #788db2;
    border: none;
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 6px 11px !important;
    border-radius: 4px;
    margin-right: 8px;
    position: relative;

    &:hover {
      background-color: #788db2;
      color: #ffffff;
    }

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 6px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }

    .star {
      background-image: url(/assets/icons/Star.svg);
    }

    .star-alt {
      background-image: url(/assets/icons/Star-alt.svg);
    }

    .custom-tooltip {
      display: none;
      justify-content: center;
      align-items: center;
      padding: 8px 20px;
      background: #ffffff;
      box-shadow: 0px 16px 34px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: -60px;
      z-index: 1;

      .icon {
        width: 20px;
        height: 20px;
        margin-right: 6px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }

      .help {
        background-image: url(/assets/icons/Help.svg);
      }

      .text {
        font-size: 14px;
        font-weight: 600;
        color: #2e384d;
        text-transform: initial;
      }

      .arrow {
        width: 24px;
        height: 4px;
        background: #ffffff;
        clip-path: polygon(100% 0, 0 0, 50% 100%);
        position: absolute;
        left: 0;
        right: 0;
        top: 99%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &:hover .custom-tooltip {
      display: flex;
    }
  }
`;

export const FeedbackButton = ({ event }: { event: number }): React.ReactElement | null => {
  const { location, replace } = useHistory();
  const account = Store.Account.useAccount();
  const { event: eventData } = Scheduler.Event.useEvent({ account, event });

  const [showModal, setShowModal] = useState(
    Bool.normalize(qs.parse(window.location.search, { ignoreQueryPrefix: true }).feedback)
  );

  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const isProducedEvent = eventData?.production === Event.Production.Produced;

  if (!isProducedEvent) {
    return null;
  }

  const isFeedbackSubmitted = !!eventData.eventFeedback || submitted;

  return (
    <ButtonContainer>
      <FeedbackModal
        show={showModal}
        onClose={(): void => {
          replace(location.pathname);
          setShowModal(false);
        }}
        setShowModalSuccess={setShowModalSuccess}
        setSubmitted={setSubmitted}
        event={eventData}
      />

      <Button
        onClick={(): void => {
          setShowModal(true);
        }}
        variant='outline-primary'
        disabled={isFeedbackSubmitted}
      >
        <div className='icon star-alt' />
        {isFeedbackSubmitted ? 'Feedback Submitted' : 'Leave Feedback'}
        {!isFeedbackSubmitted && (
          <div className='custom-tooltip'>
            <div className='icon help' />
            <div className='text'>Help us improve</div>
            <div className='arrow' />
          </div>
        )}
      </Button>
      <SuccessModal
        show={showModalSuccess}
        onClose={(): void => {
          setShowModalSuccess(false);
        }}
      />
    </ButtonContainer>
  );
};
