/* eslint-disable import/no-internal-modules */
import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Obj } from '@livecontrol/core-utils';
import type { Asset } from '@livecontrol/scheduler/model';
import { Account, Event } from '@livecontrol/scheduler/model';
import { useEffect, useState } from 'react';
import type { Record as EventRecord } from '../event/dbx';
import { normalize as normalizeEvent, StandardEventResponse } from '../event/dbx';
import type { QueryResult } from '../graphql';
import * as Dbx from './dbx';

interface TVariables {
  account: number;
  event?: number;
}

interface TData {
  listAssets?: Dbx.Record[];
  events?: EventRecord[];
}

export const QUERY = gql`
  query ListAssets($account: Int!, $accountFloat: Float!, $event: Float) {
    listAssets(clientId: $account, scheduledEventId: $event) {
      ...FullAssetResponse
    }
    events(event: { extra_filters: { client_id: $accountFloat } }) {
      ...StandardEventResponse
    }
  }
  ${Dbx.FullAssetResponse}
  ${StandardEventResponse}
`;

type Args = Account.Like;

interface Options {
  filters?: {
    event?: Event.Like;
  };
}

export const useAssets = (
  args: Args,
  options?: Options
): QueryResult<Asset.withEvent[], 'assets'> => {
  const [error, setError] = useState<Error | undefined>();
  const [assets, setAssets] = useState<Asset[] | undefined>();

  // Parse the input arguments
  const required = {
    account: Account.toId(args),
    accountFloat: Account.toId(args)
  };

  // Validate the input
  if (!Obj.isHydrated(required)) {
    return { loading: false, error: Exception.KABOOM };
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  const variables = {
    ...required,
    event: Event.toId(options?.filters?.event)
  };

  // Prepare the query
  const { loading, data } = useQuery<TData, TVariables>(QUERY, {
    variables,
    onError() {
      setError(Exception.KABOOM);
    }
  });

  // When available, parse server response
  useEffect(() => {
    let assets_;
    let error_;

    if (data) {
      const events_ = data.events ?? [];

      const eventMap = events_.reduce((map, v) => {
        if (v.id) {
          const event = normalizeEvent(v);

          if (event) {
            map.set(event.id, event);
          }
        }

        return map;
      }, new Map<Event.Like, Event>());

      assets_ = (data.listAssets ?? [])
        .map((record: Dbx.Record) => Dbx.normalize(record))
        .filter((value: Asset | undefined): value is Asset => !!value)
        .map((value) => ({
          ...value,
          // it seems that the event property doesn't exist anymore, so how to merge it with events???
          eventData: value.event ? eventMap.get(value.event) : undefined
        }))
        .sort((a, b) => b.timestamp.valueOf() - a.timestamp.valueOf());
    }

    setAssets(assets_);

    setError(error_);
  }, [data]);

  return { loading, error, assets };
};
