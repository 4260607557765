import { Device, Theme } from '@livecontrol/core-ui';
import type { Account, User } from '@livecontrol/scheduler/model';
import cx from 'classnames';
import { DateTime } from 'luxon';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Panel } from '../../../components';
import { Detail } from '../components';

const Container = styled.div`
  display: flex;
  padding: 0px;
  justify-content: space-between;

  a {
    font-size: 13px;
  }

  .renewal-details {
    min-width: 184px;
  }
  .contract-details {
    min-width: 170px;
  }
  .credit-details {
    min-width: 140px;
  }
  .additional-details {
    min-width: 170px;
  }
  .payment-details {
    min-width: 140px;
  }

  @media (max-width: 1349px) {
    justify-content: flex-start;

    .contract-details {
      min-width: 184px;
    }
    .credit-details {
      min-width: 184px;
    }
    .additional-details {
      min-width: 184px;
    }
    .payment-details {
      min-width: 184px;
    }
  }
`;

const Button = styled.a`
  max-height: 36px;
`;

export const Information = ({
  billing,
  me
}: {
  billing: Account.Billing;
  me: User;
}): ReactElement => {
  const viewport = Theme.useViewport();

  return (
    <Panel
      className={cx(
        'd-flex row-gap-16px',
        'custom-panel',
        Device.isMobile(viewport) || Device.isTablet(viewport)
          ? 'flex-column align-items-center'
          : 'flex-row'
      )}
    >
      <Container className='col-lg-9 col-xl-10 flex-lg-wrap flex-column flex-lg-row row-gap-16px'>
        <Detail className='col-lg-2 renewal-details' title='Current Annual Plan'>
          <div className='mt-8px'>
            <div>
              <div className='text-dark text-capitalize'>
                {' '}
                {billing.credits.recurrence_amount} Credits/{billing.credits.recurrence.toLowerCase()}{' '}
              </div>
            </div>
          </div>
        </Detail>

        <Detail className='col-lg-2 contract-details' title='Renewal date'>
          <div className='mt-8px'>
            <div className='mt-4px'>
              {me.userAccountDetails?.renewalDate ? 'Your Plan renews on ' : '-'}
              {me.userAccountDetails?.renewalDate
                ?.setLocale('en-US')
                .toLocaleString(DateTime.DATE_FULL)}{' '}
            </div>
          </div>
        </Detail>

        <Detail className='col-lg-2 credit-details' title='Partner'>
          <div className='mt-8px'>
            <div className='text-dark'>
              {me.userAccountDetails?.channelOrganization} <br />{' '}
              {me.userAccountDetails?.channelPhoneAreaCode} -{' '}
              {me.userAccountDetails?.channelPhone?.format({ separator: '-' })}
            </div>
          </div>
        </Detail>

        <Detail className='col-lg-2 additional-details' title='Produced Event Lead Time'>
          <div className='mt-8px'>
            <div className='text-dark'> {(me.leadTime ?? 0) * 24} Hours Notice</div>
          </div>
        </Detail>

        <Detail className='col-lg-2 payment-details' title='Credit Length'>
          <div className='mt-8px'>
            <div className='text-dark'>
              {' '}
              {billing.credits.first_credit_time_limit} minutes/credit
            </div>
          </div>
        </Detail>
      </Container>

      <div className='col-lg-3 col-xl-2' style={{ paddingRight: '0px' }}>
        <Button
          href='https://mylivecontrol.zendesk.com/hc/en-us/articles/20653040755351'
          type='button'
          className='btn btn-primary'
          style={{
            marginLeft: Device.isMobile(viewport) || Device.isTablet(viewport) ? '0' : '20'
          }}
          target='_blank '
          rel='noopener noreferrer'
        >
          <span role='img' aria-label='roket' className='mr-8px'>
            🚀
          </span>
          Latest Features
        </Button>
      </div>
    </Panel>
  );
};
