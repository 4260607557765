/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Device, Theme, Tooltip } from '@livecontrol/core-ui';
import { Matrix } from '@livecontrol/scheduler/components';
import type { Location, User } from '@livecontrol/scheduler/model';
import { useState } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { RowLocation } from './row';

import G = Matrix.Grid;
import H = G.Header;

const Grid = styled(Matrix.Grid)`
  grid-row-gap: 0 !important;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 500px;

  &.grid{
    width: 1300px !important;
  }

  .border-top {
    border-top: 1px solid #e4e8f0;
  }

  .badge {
    font-size: 12px;
  }

  .bg-red {
    background-color: #ff505f;
  }

  .bg-admin {
    background-color: #ef9563;
  }

  .bg-purplue {
    background-color: #7b61ff;
  }

  .bg-green {
    background-color: #37b34a;
  }

  .bg-blue {
    background-color: #00c1c1;
  }

  .column:nth-child(2n + 0) {
    background-color: #f0f6ff;
  }

  .column:nth-child(2n + 1) {
    background-color: white;
  }

  .height-10px {
    height: 10px !important;
    padding: 0px !important;
  }

  .th {
    margin: 0 !important;
    padding: 20px 18px;
    height: 140px;
    font-weight: 600;
    font-size: var(--font-size-16px);
    color: var(--dark);
    z-index: 1000:
  }

  .form-group {
    flex: 0 0 0 !important;
  }

  .form-control {
    padding: 0px;
  }

  input[type='checkbox'] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 5px;
    width: 16px;
    height: 16px;
    border: 1px solid #0d1438;
    outline: none;
  }

  input[type='checkbox']:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
  }

  input:checked[type='checkbox'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }

`;

const RowAccount = styled(Matrix.Grid.Row)`
  .border-top-botton {
    border-top: 1px solid transparent !important;
    border-bottom: 1px solid transparent !important;
  }

  button {
    visibility: hidden;
    display: flex !important;
  }

  &:hover {
    .border-top-botton {
      border-top: 1px solid #d5daea !important;
      border-bottom: 1px solid #d5daea !important;
      justify-content: center;
    }

    div:first-child {
      border-top-left-radius: var(--spacer-4px);
      border-bottom-left-radius: var(--spacer-4px);
    }

    div:last-child {
      border-top-right-radius: var(--spacer-4px);
      border-bottom-right-radius: var(--spacer-4px);
    }

    .column {
      border-color: #2e5bff !important;
    }

    button {
      visibility: visible !important;
      display: flex !important;
    }
  }
`;

const Content = styled.div`

  .btn {
    padding: 0px !important;
    width: 34px !important;
    height: 34px !important;
    z-index: 1;
  }

  .next {
    right: 0 !important;
  }

  .arrowRotated {
    transform: rotate(180deg);
  }

  img {
    width: 12px;
  }
`;

const CellNoBorder = styled(Matrix.Grid.Row.Cell)`
  border: 0px solid transparent;
  justify-content: center;
  padding: 14px 0px;

  &:first-child {
    position: sticky;
    left: 0;
  }

  &:last-child {
    padding: 5px 0px !important;
  }
`;

interface Props {
  me: User;
  locations: Location[] | undefined;
  locationLoading: boolean;
}

export const AccountPermissions = ({ locations, locationLoading, me }: Props): ReactElement => {
  const [isScroller, setIsScroller] = useState(false);

  const viewport = Theme.useViewport();

  const onMoveScroll = (spacer: number): void => {
    const gridScroll = document.querySelector('#gridScroll');

    if (gridScroll) {
      gridScroll.scrollLeft += spacer;

      if (gridScroll.scrollLeft > 0) {
        setIsScroller(true);
      } else {
        setIsScroller(false);
      }
    }
  };

  return (
    <div className='d-flex flex-row justify-content-center position-relative'>
      <Content className=''>
        <div
          className='position-absolute bg-white'
          style={{
            width: 190,
            height: 140,
            zIndex: 1
          }}
        />
        <button
          disabled={!isScroller}
          className='btn btn-primary rounded-circle position-absolute previus'
          onClick={(): void => {
            onMoveScroll(-120);
          }}
          type='button'
        >
          <img className='arrowRotated' src='assets/icons/arrow_left_white.svg' alt='arrow' />
        </button>
        <button
          className='btn btn-primary rounded-circle position-absolute next'
          onClick={(): void => {
            onMoveScroll(120);
          }}
          type='button'
        >
          <img src='assets/icons/arrow_left_white.svg' alt='arrow' />
        </button>
      </Content>
      <Grid
        id='gridScroll'
        viewport={viewport}
        gridTemplateColumns='190px repeat(7, 196px)'
        className='grid'
      >
        {/* Header */}
        <G.Row className='position-sticky d-flex' style={{ top: 0 }}>
          <H
            className='bg-white column font-weight-bold '
            viewport={viewport}
            style={{ left: 0, width: 190 }}
          >
            <span
              className='badge rounded-pill bg-primary text-white mb-8px'
              style={{ opacity: 0 }}
            >
              Event Schedule
            </span>

            <div style={{ width: Device.isMobile(viewport) ? '190px' : '190px', opacity: 0 }}>
              Event Schedule
            </div>
          </H>
          <H className='column text-center font-weight-bold' viewport={viewport}>
            <span className='badge rounded-pill bg-red text-white mb-8px'>Administrator</span>
            <div style={{ width: 160 }}>
              Admin Access
              <Tooltip
                backgroundColor='#0C3192'
                tip={
                  <div>
                    <div className='font-weight-bold font-size-11px'>
                      Access to all permissions including: manage Users, update Web Player slug, view Activity Log, and edit Organization Info.
                    </div>
                  </div>
                }
              >
                <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
              </Tooltip>
            </div>
          </H>
          <H className='column text-center font-weight-bold' viewport={viewport}>
            <span className='badge rounded-pill bg-purplue text-white mb-8px'>Stream Settings</span>
            <div style={{ width: 160 }}>
              Destinations & Web Player
              <Tooltip
                backgroundColor='#0C3192'
                tip={
                  <div>
                    <div className='font-weight-bold font-size-11px'>
                      Access to add and manage simulcasting destinations. Customize Web Player (except Web Player slug).
                    </div>
                  </div>
                }
              >
                <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
              </Tooltip>
            </div>
          </H>
          <H className='column text-center font-weight-bold' viewport={viewport}>
            <span className='badge rounded-pill bg-green text-white mb-8px'>Subscription</span>
            <div style={{ width: 160 }}>
              Billing
              <Tooltip
                backgroundColor='#0C3192'
                tip={
                  <div>
                    <div className='font-weight-bold font-size-11px'>
                    Access to view invoices, change payment methods, purchase credits, and view Activity Log.
                    </div>
                  </div>
                }
              >
                <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
              </Tooltip>
            </div>
          </H>
          <H className='column header text-center text-dark font-weight-bold' viewport={viewport}>
            <span className='badge rounded-pill bg-info text-white mb-8px'>Event Schedule</span>
            <div style={{ width: 160 }}>
              Manage & Edit
              <Tooltip
                backgroundColor='#0C3192'
                tip={
                  <div>
                    <div className='font-weight-bold font-size-11px'>
                      Access to create, edit and delete events. All users can view the event schedule.
                    </div>
                  </div>
                }
              >
                <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
              </Tooltip>
            </div>
          </H>
          <H className='column text-center font-weight-bold' viewport={viewport}>
            <span className='badge rounded-pill bg-admin text-white mb-8px'>Content Library</span>
            <div style={{ width: 160 }}>
              View & Download
              <Tooltip
                backgroundColor='#0C3192'
                tip={
                  <div>
                    <div className='font-weight-bold font-size-11px'>
                      Access to only view and download past videos in the Content Library. Access to view and download individual and global stream analytics. 
                    </div>
                  </div>
                }
              >
                <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
              </Tooltip>
            </div>
          </H>
          <H className='column text-center font-weight-bold' viewport={viewport}>
            <span className='badge rounded-pill bg-admin text-white mb-8px'>Content Library</span>
            <div style={{ width: 160 }}>
              Manage & Edit
              <Tooltip
                backgroundColor='#0C3192'
                tip={
                  <div>
                    <div className='font-weight-bold font-size-11px'>
                      Access to view, download, edit, and delete past videos in the Content Library. Access to view and download individual and global stream analytics.
                    </div>
                  </div>
                }
              >
                <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
              </Tooltip>
            </div>
          </H>
          <H className='column text-center font-weight-bold' viewport={viewport}>
            <span className='badge rounded-pill bg-purplue text-white mb-8px'>Stream Settings</span>
            <div style={{ width: 160 }}>
              Production Notes
              <Tooltip
                backgroundColor='#0C3192'
                tip={
                  <div>
                    <div className='font-weight-bold font-size-11px'>
                      Access to update account Production Notes.
                    </div>
                  </div>
                }
              >
                <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
              </Tooltip>
            </div>
          </H>
        </G.Row>

        <G.Row>
          <CellNoBorder className='column height-10px' style={{backgroundColor: 'transparent'}}/>
          <CellNoBorder className='column height-10px' />
          <CellNoBorder className='column height-10px' />
          <CellNoBorder className='column height-10px' />
          <CellNoBorder className='column height-10px' />
          <CellNoBorder className='column height-10px' />
          <CellNoBorder className='column height-10px' />
        </G.Row>

        <RowAccount>
          <CellNoBorder className='border-top-botton column justify-content-start'>
            <span className='text-dark font-weight-bold font-size-12px'>All Locations</span>
          </CellNoBorder>
          <CellNoBorder className='border-top-botton column'>
            {me.permissions.admin ? (
              <img alt='able' src='assets/icons/check-blue.svg' />
            ) : (
              <img alt='able' src='assets/icons/close.svg' />
            )}
          </CellNoBorder>
          <CellNoBorder className='border-top-botton column'>
          {me.permissions.admin || me.permissions.destinations || me.permissions.webplayer ? (
              <img alt='able' src='assets/icons/check-blue.svg' />
            ) : (
              <img alt='able' src='assets/icons/close.svg' />
            )}
          </CellNoBorder>
          <CellNoBorder className='border-top-botton column'>
          {me.permissions.admin || me.permissions.billing || me.permissions.activityLog ? (
              <img alt='able' src='assets/icons/check-blue.svg' />
            ) : (
              <img alt='able' src='assets/icons/close.svg' />
            )}
          </CellNoBorder>
          <CellNoBorder className='border-top-botton column' />
          <CellNoBorder className='border-top-botton column' />
          <CellNoBorder className='border-top-botton column' />
          <CellNoBorder className='border-top-botton column' />
        </RowAccount>

        {!locationLoading &&
          locations?.map((location) => (
            <RowLocation location={location} key={location.id} me={me} />
          ))}
      </Grid>
    </div>
  );
};
