import { Glyph } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import Clipboard from 'clipboard';
import { Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import type { ReactElement } from 'react';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Toast from 'react-bootstrap/Toast';
import { Form } from '../../../../components';
import { useForm } from './form';
import type { Props } from './form';

export const URL = ({ value, ...rest }: Form.Control.Props & { value: string }): ReactElement => {
  const ref = useRef<HTMLButtonElement>(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    let clip: Clipboard | undefined;

    if (ref.current) {
      clip = new Clipboard(ref.current, {
        text(): string {
          return value;
        }
      });

      clip.on('success', () => {
        setShow(true);
      });
    }

    return (): void => {
      if (clip) {
        clip.destroy();
      }
    };
  }, [value]);

  return (
    <div className='position-relative'>
      <InputGroup>
        <Form.Control value={value} {...rest} readOnly size='lg' />
        <InputGroup.Append>
          <Button className='px-16px' variant='outline-tint' title='Copy to clipboard' ref={ref}>
            <i className='far fa-copy m-0' />
          </Button>
        </InputGroup.Append>
      </InputGroup>

      <Toast
        className='position-absolute mt-8px'
        style={{ right: 0 }}
        onClose={(): void => {
          setShow(false);
        }}
        show={show}
        delay={2000}
        autohide
      >
        <Toast.Body>
          <i className='fas fa-info-circle mr-8px' />
          Video URL copied to clipboard.
        </Toast.Body>
      </Toast>
    </div>
  );
};

export const Transfer = (props: Props): ReactElement => {
  const { formik, loading, alert } = useForm(props);
  const { onClose } = props;

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Glyph>
          <i className='fas fa-arrow-down' />
        </Glyph>

        <Modal.Title>Download Video</Modal.Title>

        <p className='text-center text-secondary mt-8px'>
          Copy the URL to share your video, or click the button below to begin your download.
        </p>
      </Modal.Header>

      <Formik {...formik}>
        <Form>
          <Modal.Body className='pb-20px'>
            <Form.Alert show={alert.show} onClose={alert.hide}>
              {alert.message}
            </Form.Alert>

            <Form.Group name='url' label='Video URL' control={URL} />
          </Modal.Body>

          <Modal.Footer>
            <Modal.ButtonBox>
              <Modal.Submit className='btn-wide' loading={loading}>
                Download
              </Modal.Submit>
              <Modal.Button variant='outline-secondary' onClick={onClose}>
                Cancel
              </Modal.Button>
            </Modal.ButtonBox>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  );
};
