import { Obj } from '@livecontrol/core-utils';
import type { StripeLinkPaymentTokenResult } from './types';

export const normalize = (
  record?: StripeLinkPaymentTokenResult
): StripeLinkPaymentTokenResult | undefined => {
  if (record) {
    const canditate: StripeLinkPaymentTokenResult = {
      ok: !!record.ok
    };

    if (Obj.isHydrated(canditate)) {
      return canditate;
    }
  }

  return undefined;
};
