import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router';
import { SectionContainer } from '../../../components';
import { Store } from '../../../store';
import { AssignedContacts } from './assigned-contacts';
import { Dashboard } from './dashboard';
import { PermissionsLocation } from './permissions-location';
import { PermissionsUser } from './permissions-user';
import { Tab, Tabber } from './tabber';

export const UserManagement = (): React.ReactElement => {
  const account = Store.Account.useAccount();
  // const slug = Str.normalize(useParams<{ slug?: string }>().slug);
  const me = Store.User.useMe();
  const { pathname } = window.location;
  const [isWaitingForUser, setIsWaitingForUser] = useState(false);
  const [newUserId, setNewUserId] = useState<number>(0);
  const history = useHistory();

  const redirectWithUserID = (userId: number): void => {
    setIsWaitingForUser(true);
    setNewUserId(userId);
  };

  useEffect(()=>{
    if(newUserId > 0 && account.subUsers.some((subuser) => subuser.id === newUserId)){
      setIsWaitingForUser(false);
      history.push(`/account/users/permissions-user?user_selected=${newUserId}`);
      setNewUserId(0);
    }
  }, [account, history, newUserId]);

  if (!me.permissions.admin) {
    return <Redirect to='/schedule' />;
  }

  const notAdmin = me.role !== 6;

  const sortedSubUsers = account.subUsers
    ?.slice()
    .sort((a, b) => a.firstName.localeCompare(b.firstName));

  const usersAvailable = notAdmin
    ? sortedSubUsers.filter((subuser) => subuser.id !== me.id)
    : sortedSubUsers;

  const tab =
    !pathname.includes(Tab.AssignedContacts) &&
    !pathname.includes(Tab.PermissionByUser) &&
    !pathname.includes(Tab.PermissionByLocation)
      ? Tab.AllUsers
      : pathname.includes('permissions-user')
      ? Tab.PermissionByUser
      : pathname.includes('permissions-location')
      ? Tab.PermissionByLocation
      : Tab.AssignedContacts;

  const Content =
    tab === Tab.AllUsers
      ? Dashboard
      : tab === Tab.PermissionByUser
      ? PermissionsUser
      : tab === Tab.PermissionByLocation
      ? PermissionsLocation
      : AssignedContacts;

  /*
   * const tab = slug === 'users' ? Tab.AllUsers : Tab.AssignedContacts; Original
   * const tab = pathname.includes('users') ? Tab.AllUsers : Tab.AssignedContacts;
   * const Content = tab === Tab.AllUsers ? Dashboard : Dashboard;
   */

  return (
    <SectionContainer className='col-12'>
      <h1 className='m-0'>Users</h1>
      <Tabber tab={tab} />

      {Content === AssignedContacts ? (
        <AssignedContacts account={account} />
      ) : Content === PermissionsUser ? (
        <PermissionsUser account={account} usersAvailable={usersAvailable} isWaitingForUser={isWaitingForUser} />
      ) : Content === Dashboard ? (
        <Dashboard account={account} usersAvailable={usersAvailable} redirectWithUserID={redirectWithUserID} />
      ) : <PermissionsLocation account={account} usersAvailable={usersAvailable} />

    }
    </SectionContainer>
  );
};
