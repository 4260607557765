import { Device, Theme, Tooltip } from '@livecontrol/core-ui';
import { Phone } from '@livecontrol/locale-us';
import { Matrix } from '@livecontrol/scheduler/components';
import type { Account, Location } from '@livecontrol/scheduler/model';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Store } from '../../../../store';

import G = Matrix.Grid;

const Cell = styled(Matrix.Grid.Row.Cell)<{ active?: boolean }>`
  border-color: ${({ active }): string =>
    `${active ? '#2E5BFF !important' : '#D5DAEA !important'}`};
  background-color: ${({ active }): string =>
    `${active ? 'rgba(46, 91, 255, 0.1) !important;' : 'transparent !important;'}`};

  &:first-child {
    border-top-left-radius: var(--spacer-4px);
    border-bottom-left-radius: var(--spacer-4px);
  }
  &:last-child {
    border-top-right-radius: var(--spacer-4px);
    border-bottom-right-radius: var(--spacer-4px);
  }

  .bottom-12 {
    bottom: var(--spacer-12px) !important;
  }
`;

const Container = styled.div`
  max-width: 20px;
  display: contents;
  display: flex;
  flex-direction: column;
  justify-content: center;

  input[type='checkbox']:hover {
    cursor: pointer;
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 2px;
    width: 16px;
    height: 16px;
    border: 1px solid #ccc;
    outline: none;
  }

  input[type='checkbox']:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
  }

  input:checked[type='checkbox'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
}
`;

interface Props {
  subUser: Account.SubUser;
  users: Account.SubUser[] | undefined;
  onEdit: (subUser: Account.SubUser) => void;
  onSelectUser: (subUser: Account.SubUser) => void;
  onResendInvite: (subUser: Account.SubUser) => void;
  filteredLocation?: Location;
  locationsFormatedText?: string;
}

export const Row = ({
  subUser,
  users,
  onSelectUser,
  onEdit,
  onResendInvite,
  filteredLocation,
  locationsFormatedText
}: Props): React.ReactElement => {
  const [active, setActive] = useState(false);
  const viewport = Theme.useViewport();
  const history = useHistory();
  const { me } = Store.User.useIdentity();

  useEffect(() => {
    if (users && users.length >= 0) {
      const haveSubuser = users.find((user) => user.id === subUser.id);

      if (haveSubuser) {
        setActive(true);
      } else {
        setActive(false);
      }
    }
  }, [subUser, users]);

  let displayUser = true;

  if (
    subUser.sub_user_locations &&
    filteredLocation &&
    !subUser.sub_user_locations.some((location) => location.location.id === filteredLocation.id)
  ) {
    displayUser = false;
  }

  if (filteredLocation && subUser.sub_user_locations?.length === 0) {
    displayUser = false;
  }

  if (!displayUser) {
    return <div className='d-none' />;
  }

  const onSelectRow = (): void => {
    onSelectUser(subUser);
  };

  const handleRedirect = (): void => {
    history.push(`/account/users/permissions-user?user_selected=${subUser.id}`);
  };

  return (
    <>
      <Container>
        {subUser.id !== me?.id &&
          <input
            type='checkbox'
            checked={active}
            onClick={(): void => {
              onSelectRow();
            }}
            readOnly
          />
        }
      </Container>
      <G.Row className='text-dark font-size-13px position-relative' key={`user${subUser.id}`}>
        <Cell
          active={active}
          className={cx(Device.isDesktop(viewport) ? 'font-weight-bold' : 'font-weight-light')}
        >
          {subUser.fullName}
        </Cell>
        <Cell
          active={active}
          className={cx(Device.isDesktop(viewport) ? 'font-weight-bold' : 'font-weight-light')}
        >
          {subUser.email}
        </Cell>
        <Cell
          active={active}
          className={cx(Device.isDesktop(viewport) ? 'font-weight-bold' : 'font-weight-light')}
        >
          {subUser.phone
            ? Phone.normalize(subUser.phone)?.format({ parenthetical: true, separator: '-' })
            : '--'}
        </Cell>
        <Cell
          active={active}
          className={cx(
            'text-capitalize',
            Device.isDesktop(viewport) ? 'font-weight-bold' : 'font-weight-light'
          )}
        >
          {subUser.preferredContactMethod}
        </Cell>
        <Cell
          active={active}
          className={cx(Device.isDesktop(viewport) ? 'font-weight-bold' : 'font-weight-light')}
        >
          {locationsFormatedText && locationsFormatedText.length > 0 ? (
            <Tooltip
              backgroundColor='#0C3192'
              tip={<span>{locationsFormatedText}</span>}
              title='Assigned Locations: '
            >
              <button
                type='button'
                className={cx(
                  'btn text-primary text-nowrap',
                  Device.isDesktop(viewport) && 'font-weight-bold',
                  Device.isTablet(viewport) && 'font-weight-light'
                )}
                style={{ backgroundColor: 'rgba(46, 91, 255, 0.1)' }}
                onClick={(): void => {
                  handleRedirect();
                }}
                disabled={subUser.id === me?.id}
              >
                Edit Locations
              </button>
            </Tooltip>
          ) : (
            <Tooltip
              backgroundColor='#0C3192'
              tip={<span>{locationsFormatedText}</span>}
              title='Assigned Locations: None'
            >
              <button
                type='button'
                className={cx(
                  'btn text-primary text-nowrap',
                  Device.isDesktop(viewport) && 'font-weight-bold',
                  Device.isTablet(viewport) && 'font-weight-light'
                )}
                style={{ backgroundColor: 'rgba(46, 91, 255, 0.1)' }}
                onClick={(): void => {
                  handleRedirect();
                }}
              >
                + Add Locations
              </button>
            </Tooltip>
          )}
        </Cell>
        <Cell
          active={active}
          className='font-size-11px'
          style={{
            width: Device.isTablet(viewport) ? '130px' : '',
            flexDirection: Device.isTablet(viewport) ? 'column' : 'row'
          }}
        >
          <button
            type='button'
            className={cx(
              'btn text-primary text-nowrap',
              Device.isDesktop(viewport) && 'ml-16px font-weight-bold',
              Device.isTablet(viewport) && 'mt-8px font-weight-light'
            )}
            style={{ backgroundColor: 'rgba(46, 91, 255, 0.1)' }}
            onClick={(event): void => {
              event.stopPropagation();
              onEdit(subUser);
            }}
          >
            View / Edit
          </button>

          <button
            type='button'
            className={cx(
              'btn btn-primary',
              Device.isDesktop(viewport) && 'ml-16px font-weight-bold text-nowrap',
              Device.isTablet(viewport) && 'mt-8px font-weight-light text-wrap'
            )}
            onClick={(event): void => {
              event.stopPropagation();
              onResendInvite(subUser);
            }}
          >
            Reset Password
          </button>
        </Cell>
      </G.Row>
    </>
  );
};
