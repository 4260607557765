import { Theme, Tooltip, Viewport } from '@livecontrol/core-ui';
import type { Zone } from 'luxon';
import { DateTime } from 'luxon';
import type { HTMLAttributes, ReactElement } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 14px;
  font-size: 11px;
  color: #9c9c9c;
  font-weight: 600;
`;

export const TZ = ({ timezone, ...props }: TZ.Props): ReactElement => {
  const viewport = Theme.useViewport();

  const now = DateTime.utc().setZone(timezone);

  return (
    <Container {...props}>
      <span>Time Zone:</span>{' '}
      {viewport === Viewport.XS ? (
        <Tooltip tip={now.zoneName}>
          <abbr title={now.zoneName}>{now.offsetNameShort}</abbr>
        </Tooltip>
      ) : (
        now.zoneName
      )}
    </Container>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace TZ {
  export type Props = HTMLAttributes<HTMLDivElement> & { timezone: Zone };
}
