import { Loading } from '@livecontrol/core-ui';
import type { User } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { FormikProvider } from 'formik';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { Form, SectionContainer } from '../../components';
import { Store } from '../../store';
import { EmailSettings } from './email-settings';
import { Information, useForm } from './information';
import { Profile } from './profile';

interface LocationsNotification {
  id: number;
  locationId: string;
  eventScheduling: boolean;
  eventReminder: boolean;
  connectionIssues: boolean;
  customerFeedback: boolean;
}

export const Account = (): ReactElement => {
  const me = Store.User.useMe();

  const [locationsNotificationSelected, setLocationsNotificationSelected] = useState<
    LocationsNotification[]
  >([]);

  const { formik, loading } = useForm({ me, locationsNotificationSelected });

  const account = Store.Account.useAccount();
  const { locations, loading: locationLoading } = Scheduler.Location.useLocations(account);

  interface RoleInfo {
    role: string;
    locations: string;
  }

  const [userRoles, setUserRoles] = useState<RoleInfo[]>([]);

  useEffect(() => {
    const userId = me.id;

    const onboardingMainRoles: string[] = [];
    const onboardingTechnicalRoles: string[] = [];
    const onSiteTechnicalRoles: string[] = [];
    const billingRoles: string[] = [];
    const shipping: string[] = [];

    let rolesArray: User.ContactRole[] | undefined = [];

    const isAccountHolder = account.id === me.id;

    rolesArray = isAccountHolder
      ? account.contactRole
      : account.subUsers.find((user) => user.id === userId)?.contactRole;

    rolesArray?.forEach((role) => {
      if (role.location?.id) {
        if (role.onboarding_technical) {
          onboardingTechnicalRoles.push(role.location.name);
        }

        if (role.onsite_technical) {
          onSiteTechnicalRoles.push(role.location.name);
        }

        if (role.shipping) {
          shipping.push(role.location.name);
        }
      } else {
        if (role.onboarding_main) {
          onboardingMainRoles.push('All Locations');
        }

        if (role.primary_billing) {
          billingRoles.push('All Locations');
        }
      }
    });

    const locationsAvailableCount = locations?.filter((location) => !location.isDisabled).length;

    const rolesInfo: RoleInfo[] = [];
    let text = '';

    // uncomment when onbarding gets released
    /*
     * if (onboardingMainRoles.length > 0) {
     *   const roleInfo: RoleInfo = {
     *     role: 'Onboarding Main: ',
     *     locations: ''
     *   };
     *   text =
     *     onboardingMainRoles.length !== locationsAvailableCount
     *       ? onboardingMainRoles.join(', ')
     *       : 'All Locations';
     *   roleInfo.locations = text;
     *   rolesInfo.push(roleInfo);
     * }
     * if (onboardingTechnicalRoles.length > 0) {
     *   const roleInfo: RoleInfo = {
     *     role: 'Onboarding Technical: ',
     *     locations: ''
     *   };
     *   text =
     *     onboardingTechnicalRoles.length !== locationsAvailableCount
     *       ? onboardingTechnicalRoles.join(', ')
     *       : 'All Locations';
     *   roleInfo.locations = text;
     *   rolesInfo.push(roleInfo);
     * }
     */

    if (onSiteTechnicalRoles.length > 0) {
      const roleInfo: RoleInfo = {
        role: 'On Site Technical: ',
        locations: ''
      };

      text =
        onSiteTechnicalRoles.length !== locationsAvailableCount
          ? onSiteTechnicalRoles.join(', ')
          : 'All Locations';

      roleInfo.locations = text;
      rolesInfo.push(roleInfo);
    }

    if (billingRoles.length > 0) {
      const roleInfo: RoleInfo = {
        role: 'Primary Billing: ',
        locations: ''
      };

      text =
        billingRoles.length !== locationsAvailableCount ? billingRoles.join(', ') : 'All Locations';

      roleInfo.locations = text;
      rolesInfo.push(roleInfo);
    }

    if (shipping.length > 0) {
      const roleInfo: RoleInfo = {
        role: 'Shipping: ',
        locations: ''
      };

      text = shipping.length !== locationsAvailableCount ? shipping.join(', ') : 'All Locations';

      roleInfo.locations = text;
      rolesInfo.push(roleInfo);
    }

    setUserRoles(rolesInfo);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.subUsers, locations, me.id]);

  return locationLoading ? (
    <Loading.Delay />
  ) : (
    <SectionContainer className='col-12'>
      <FormikProvider value={formik}>
        <Form>
          <h1> Profile </h1>
          <Profile
            locations={locations}
            locationLoading={locationLoading}
            me={me}
            className='mb-32px mt-32px'
            userRoles={userRoles}
          />
          <Information me={me}/>
          {locations && (
            <EmailSettings
              locations={locations}
              me={me}
              setLocationsNotificationSelected={setLocationsNotificationSelected}
              locationsNotification={locationsNotificationSelected}
            />
          )}
          <div className='d-flex justify-content-center mt-64px'>
            <Form.Submit
              loading={loading}
              type='submit'
              className='btn btn-primary'
              style={{ padding: '11px 24px' }}
            >
              Update Profile
            </Form.Submit>
          </div>
        </Form>
      </FormikProvider>
    </SectionContainer>
  );
};
