import _ from 'lodash';
import numeral_ from 'numeral';
import { Nix } from './nix';
import { Scalar } from './scalar';
import { Unicode } from './unicode';

export namespace Num {
  export type Options = Scalar.NumberOptions;

  /**
   * Converts the specified value to a numeric value.
   *
   * - The specified value is first converted to a scalar (see `Scalar.normalize`).
   * - Numeric strings are converted to the numeric equivalent (eg. `normalize('123') === 123`).
   * - Non numeric strings return `undefined` (eg. `normalize("hello") === undefined`).
   * - The boolean values `true` and `false` return `undefined` (eg. `normalize(false) === undefined`).
   * - The numeric constants `Number.NaN` and `Number.Infinity` return `undefined` (eg. `toString(1/0) === undefined`).
   *
   * @param value - The value to be normalied.
   * @param options - Normalization options.
   * @returns The numeric value or `undefined`.
   */
  export const normalize = (value: unknown, options?: Options): number | undefined => {
    const scalarVal = Scalar.normalize(value, { ...options, trim: true });

    let numberVal;

    if (!Nix.isNil(scalarVal)) {
      if (typeof scalarVal === 'number') {
        if (Number.isFinite(scalarVal)) {
          numberVal = Number(scalarVal);
        }
      } else if (typeof scalarVal === 'string') {
        const stringVal = scalarVal.trim();

        // istanbul ignore else
        if (stringVal.length) {
          const candidate = _.toNumber(stringVal);

          if (Number.isFinite(candidate)) {
            numberVal = candidate;
          }
        }
      }

      // Constraints
      if (
        !Nix.isNil(numberVal) &&
        ((options?.positive && numberVal <= 0) || (options?.nonZero && numberVal === 0))
      ) {
        numberVal = undefined;
      }
    }

    return numberVal;
  };

  /**
   * Converts the specified value to an integer value.
   *
   * - The input value is first converted to a number (see `normalize`).
   * - Floating point numbers are truncated, not rounded.
   *
   * @param value - The value to be converted to an integer.
   * @param options - Normalization options.
   * @returns An integer value or `undefined`.
   */
  export const toInteger = (value: unknown, options?: Options): number | undefined => {
    const numberVal = normalize(value, options);

    let integerVal;

    if (!Nix.isNil(numberVal)) {
      integerVal = Math.trunc(numberVal);

      if ((options?.positive && integerVal <= 0) || (options?.nonZero && integerVal === 0)) {
        integerVal = undefined;
      }
    }

    return integerVal;
  };

  export namespace Format {
    export const numeral = numeral_;

    export const integer = (value: number, format: string = '0,0'): string =>
      numeral(value).format(format);

    export const decimal = (value: number, format: string = '0,0.0'): string =>
      numeral(value).format(format);

    export const fractional = (value: number): string => {
      const abs = Math.abs(value);
      const mantissa = abs % 1;

      const fraction =
        mantissa === 0.25
          ? Unicode.ONE_QUARTER
          : mantissa === 0.5
          ? Unicode.ONE_HALF
          : mantissa === 0.75
          ? Unicode.THREE_QS
          : undefined;

      return fraction
        ? `${value < 0 ? '-' : ''}${abs >= 1 ? integer(Math.floor(abs)) : ''}${fraction}`
        : mantissa === 0
        ? integer(value)
        : decimal(value);
    };

    export const us$ = (value: number, options?: { magnitude?: boolean }): string | undefined => {
      const format = options?.magnitude
        ? value <= 1000000 || value > 10000000
          ? '$0a'
          : '$0.0a'
        : '$0,0';

      return numeral(value).format(format).toUpperCase();
    };
  }
}
