import { Grid, Loading, Tooltip } from '@livecontrol/core-ui';
import { Str } from '@livecontrol/core-utils';
import { Filters } from '@livecontrol/scheduler/components';
import React, { useState } from 'react';
import type { ReactElement } from 'react';
import { Callout, Section } from './common';

export const Components = (): ReactElement => {
  const [keyword, setKeyword] = useState<string | undefined>();

  return (
    <Section name='Miscellaneous Components'>
      <h2>Filter/Keyword</h2>

      <Filters.Keyword
        placeholder='Search for something...'
        value={keyword}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
          setKeyword(Str.normalize(event.target.value));
        }}
      />

      <p className='pt-8px'>
        <strong>Keyword</strong>: {keyword}
      </p>

      <h2>Tooltip</h2>

      <Tooltip tip='This is the tooltip.'>
        <span className='d-inline-block mb-24px'>Hover over me!</span>
      </Tooltip>

      <h2>Loading</h2>

      <Callout>
        <code>
          &lt;Loading size=&quot;<em>string|number</em>&quot; /&gt;
          <br />
          <br />
          -- or --
          <br />
          <br />
          &lt;Loading.Delay delay=&quot;<em>milliseconds</em> [default 1 second]&quot; /&gt;
        </code>
      </Callout>

      <Grid.AutoRepeat className='gap-12px' min={200}>
        <Loading size='50' />
        <Loading size={100} />
        <Loading />
        <Loading.Delay size='12em' delay={2e3} />
      </Grid.AutoRepeat>
    </Section>
  );
};
