import { Glyph } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { ReactElement } from 'react';
import React from 'react';
import { Store } from '../../../store';

export interface ModalProps {
  show: boolean;
  handleClose?: () => void;
}

export const YoutubeErrors = {
  LIVE_NOT_ENABLED: 'YOUTUBE_LIVE_NOT_ENABLED'
};

export const PermissionsModal = ({ show = true, handleClose }: ModalProps): ReactElement => {
  const account = Store.Account.useAccount();

  const { data: youTubeOAuthURL } = Scheduler.Destination.useYouTubeAuthorization({ account });

  const goToYouTubeIntegration = (): void => {
    if (youTubeOAuthURL) {
      window.location.replace(youTubeOAuthURL);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>YouTube Permissions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='text-center'>
          You need to grant a few permissions to connect a YouTube Page. You’ll also need to grant
          permission to your timeline.
          <br />
          Don’t worry, we will never post to your timeline unless you connect your profile
          separetely.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Button variant='danger' size='lg' type='button' onClick={goToYouTubeIntegration}>
          <i className='fab fa-youtube fa-lg mr-8px' />
          <span className='align-self-center'>Connect YouTube</span>
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ErrorModal = ({ show, handleClose }: ModalProps): React.ReactElement => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Glyph className='text-white bg-danger'>
        <i className='fas fa-exclamation' />
      </Glyph>
      <h1>Connection Failed</h1>
    </Modal.Header>
    <Modal.Body>
      <div className='text-center'>
        <p>
          Your YouTube acount does not have live streaming enabled.
          <br /> To enable live streaming, please follow these steps:
        </p>
        <div>
          <img
            style={{ maxWidth: 400 }}
            className='img-fluid'
            src='assets/integration/youtube-livestream-error.jpg'
            alt=''
          />
        </div>
        <ol
          className='text-left mx-auto mt-16px font-size-14px line-height-lg'
          style={{ maxWidth: 350, listStyleType: 'decimal' }}
        >
          <li>Head over to your YouTube Live Dashboard</li>
          <li>Verify your YouTube account</li>
          <li>In 24 hours, we will confirm verifciation via e-mail</li>
        </ol>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Modal.Button href='https://studio.youtube.com/' target='blank' type='button' size='lg'>
        Go To Your YouTube Dashboard
      </Modal.Button>
    </Modal.Footer>
  </Modal>
);
