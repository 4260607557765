import type { Account } from '@livecontrol/scheduler/model';
import { assert } from '@sindresorhus/is';
import { store } from '../factory';
import type { Store } from '../store';

/**
 * React hook that returns the authenticated account/organization.
 *
 * @returns Authenticated account. Throws an exception if no account has been authenticated.
 * @throws An exception if no account has been authenticated.)
 */
export const useAccount = (): Account => {
  // Get both the actual user (`me`) and, potentially, the client for whom an adminstrator is masquerading (`thee`).
  const account = store((state: Store.State) => state.account);

  // If this is undefined, you've made a mistake.
  assert.object(account);

  // If an administrator is masquerading as a client, return the client.
  return account;
};
