import type { Lazy } from '@livecontrol/async-utils';
import type { Location } from '@livecontrol/scheduler/model';
import { Destination, Event } from '@livecontrol/scheduler/model';
import type { Draft, Producer, Range } from '@livecontrol/store-utils';
import { Sorting } from '@livecontrol/store-utils';
import type { Zone } from 'luxon';
import type { UseStore } from 'zustand';

import Phase = Event.Phase;

export enum Tab {
  Pending = 'pending',
  Past = 'past'
}

export interface M_Period {
  value?: T_Period;
  update: (value?: T_Period) => void;
}

export interface M_Location {
  available: readonly Location[];
  selected?: Location | Location[];
  update: (value?: Location | Location[]) => void;
}

export interface M_Keyword {
  value?: string;
  update: (value?: string) => void;
}

export interface M_Filters {
  keyword: M_Keyword;
  location: M_Location;
  period: M_Period;
  reset: () => void;
}

export interface M_Sorting {
  value: T_Sorting;
  update: (value: T_Sorting) => void;
}

export interface M_Live {
  events: readonly Event[];
  filters: M_Filters;
  sorting: M_Sorting;
}

export interface M_Upcoming {
  events: readonly Event[];
  filters: M_Filters;
  sorting: M_Sorting;

  selection: {
    selectedEvents: Set<Event>;
    synchronize: (filtered: readonly Event[]) => void;
    toggleOne: (value: Event) => void;
    toggleAll: (filtered: readonly Event[]) => void;
  };
}

export interface M_Past {
  events: readonly Event[];
  filters: M_Filters;
  sorting: M_Sorting;
}

export interface Model {
  [Phase.Live]: M_Live;
  [Phase.Upcoming]: M_Upcoming;
  [Phase.Past]: M_Past;
  tabset: Set<Tab>;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type State = {
  model: Lazy<Model>;
  timezone: Zone;
  produce: T_Producer;
};

export type T_Sorting = Sorting<T_Sorting.Key>;
export type T_Period = Range<Date>;

export type T_Draft = Draft<State>;
export type T_Producer = Producer<T_Draft>;

export type Store = UseStore<State>;

export type { Location };
export { Destination, Event, Phase };

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace T_Sorting {
  export const keys = {
    id: 'ID',
    title: 'Title',
    date: 'Date',
    location: 'Location',
    production: 'Type'
  };

  export type Key = keyof typeof keys;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export import Direction = Sorting.Direction;
}
