import { Glyph } from '@livecontrol/core-ui';
import _ from 'lodash';
import { Component } from 'react';
import type { PropsWithChildren, ReactElement, ReactNode } from 'react';
import type { ModalProps } from 'react-bootstrap/Modal';
import Modal from 'react-bootstrap/Modal';
import { isElement } from 'react-is';
import { Button } from '../button';
import { ButtonBox } from '../button-box';
import { Title } from '../title';
import { Provider as Provider_ } from './provider';

type ButtonProps = Button.Props & {
  label?: string;
};

type ButtonDescriptor = ButtonProps | ReactElement | string | false;

const toButton = (
  descriptor: ButtonDescriptor | undefined,
  defaults: ButtonProps
): ReactElement | null => {
  if (descriptor === false) {
    return null;
  } else if (isElement(descriptor)) {
    return descriptor;
  }

  const { label, className, ...props } = _.defaults(
    {},
    typeof descriptor === 'string' ? { label: descriptor } : descriptor,
    defaults
  );

  return (
    <Button className={className ?? 'btn-wide'} {...props}>
      {label}
    </Button>
  );
};

// eslint-disable-next-line react/prefer-stateless-function
export class Prompt extends Component<Prompt.Props> {
  public override render(): ReactNode {
    const { cancel, noButtons, children, icon, okay, onCancel, onOkay, title, closeButton, ...rest } =
      _.defaults({}, this.props, {
        centered: true,
        noButtons: false,
        keyboard: true,
        closeButton: true,
        onHide() {
          const fn = onCancel ?? onOkay;

          if (!fn) {
            throw new Error('You must provide either an `onOkay` or `onCancel` handler.');
          }

          fn();
        }
      });

    const buttonCount = (cancel !== false ? 1 : 0) + (okay !== false ? 1 : 0);

    const defaults = {
      className: buttonCount === 1 ? 'btn-xwide' : 'btn-wide',
      size: buttonCount === 1 ? ('lg' as const) : undefined
    };

    return (
      <Modal {...rest}>
        {(icon || title) && (
          <Modal.Header closeButton={closeButton}>
            {icon &&
              (isElement(icon) ? (
                icon
              ) : (
                <Glyph>
                  <span key={icon}>
                    <i className={icon} />
                  </span>
                </Glyph>
              ))}
            {title && (isElement(title) ? title : <Title>{title}</Title>)}
          </Modal.Header>
        )}

        <Modal.Body>{children}</Modal.Body>

        {
          !noButtons && <Modal.Footer>
          <ButtonBox>
            {toButton(
              okay,
              _.defaults(
                {
                  variant: 'primary',
                  onClick: onOkay,
                  label: 'OK'
                },
                defaults
              )
            )}
            {toButton(
              cancel,
              _.defaults(
                {
                  variant: 'outline-secondary',
                  onClick: onCancel,
                  label: 'Cancel'
                },
                defaults
              )
            )}
          </ButtonBox>
        </Modal.Footer>
        }
      </Modal>
    );
  }
}

export namespace Prompt {
  export type Props = Omit<ModalProps, 'onHide'> &
    PropsWithChildren<
      Partial<{
        cancel: ButtonDescriptor;
        noButtons: boolean;
        icon: ReactElement | string | false;
        okay: ButtonDescriptor;
        onCancel: () => void;
        onOkay: () => void;
        title: ReactElement | string | false;
      }>
    >;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export import Provider = Provider_;
}
