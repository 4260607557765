import type { Account } from '@livecontrol/scheduler/model';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports, lodash/import-scope
import _ from 'lodash';
import { validateEventConflicts } from './conflict-validator';
import { validateCreditAvailability } from './credit-validator';
import { validateCuesheetURL } from './cuesheets-validator';
import { validateEventDescription } from './description-validator';
import { validateEventBackground } from './event-background-validator';
import { validateEventLeadtimeAcknowledge } from './leadtime-acknwoledge-validator';
import { validateEventLocation } from './location-validator';
import { validateMaxRecurrences } from './max-recurrence-validator';
import { validateEventNotes } from './notes-validator';
import { validateEventPassword } from './password-validator';
import { getAllRecurrences } from './recurrence-calculator';
import { validateRedirectUrl } from './redirect-url-validator';
import { validateEventTitle } from './title-validator';
import { validateEventType } from './type-validator';
import type { EventValidator } from './types';
import { validateZoomDetails } from './zoom-validator';

export const validateNewEventDetails = (
  {
    availableCredits,
    timezone,
    events,
    newEventInfo,
    simulatedLiveAssetIsUpload,
    cueSheetURL,
    isAdmin,
    isProd = true
  }: EventValidator.NewEventProps,
  billing?: Account.Billing
): EventValidator.Errors => {
  const errors: EventValidator.Errors = {
    ...validateEventType(newEventInfo),
    ...validateEventTitle(newEventInfo),
    ...validateEventLocation(newEventInfo),
    ...validateEventDescription(newEventInfo),
    ...validateEventBackground(newEventInfo),
    ...validateEventNotes(newEventInfo),
    ...validateZoomDetails(newEventInfo),
    ...validateEventPassword(newEventInfo),
    ...validateEventLeadtimeAcknowledge(newEventInfo),
    ...validateRedirectUrl(newEventInfo),
    ...(cueSheetURL && validateCuesheetURL(cueSheetURL))
  };

  const { recurrences, errors: repeatingEventErrors } = getAllRecurrences({
    newEventInfo,
    simulatedLiveAssetIsUpload,
    isAdmin,
    isProd
  });

  if (repeatingEventErrors) {
    _.merge(errors, repeatingEventErrors);
  } else if (recurrences?.length) {
    _.merge(errors, validateMaxRecurrences(newEventInfo, recurrences));

    _.merge(
      errors,
      validateCreditAvailability(availableCredits, newEventInfo, recurrences, billing)
    );
  }

  if (_.isEmpty(errors.date)) {
    _.merge(
      errors,
      validateEventConflicts({
        newEventInfo,
        simulatedLiveAssetIsUpload,
        events,
        timezone,
        recurrences,
        isAdmin,
        isProd
      })
    );
  }

  return errors;
};
