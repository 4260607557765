import { Button } from '@livecontrol/core-ui';
import type { Asset } from '@livecontrol/scheduler/model';
import cx from 'classnames';
import React, { useState } from 'react';
import { Store } from '../../../../store';
import { PasswordModal } from './password-modal';

export const PasswordProtect = ({ asset }: { asset: Asset }): React.ReactElement | null => {
  const [show, setShow] = useState(false);
  const isPasswordProtected = !!asset.eventPassword;
  const me = Store.User.useMe();

  return (
    <>
      <Button
        variant={cx({
          'primary': isPasswordProtected,
          'outline-primary': !isPasswordProtected
        })}
        onClick={(): void => {
          setShow(true);
        }}
        disabled={!me.permissions.admin && me.permissions.readOnlyArchive}
      >
        <span key={isPasswordProtected ? 'true' : 'false'}>
          <i
            className={cx('far', {
              'fa-lock-open-alt': !isPasswordProtected,
              'fa-lock': isPasswordProtected
            })}
          />
        </span>
        {isPasswordProtected ? 'Password Protected' : 'Password Protect'}
      </Button>
      <PasswordModal
        asset={asset}
        show={show}
        onHide={(): void => {
          setShow(false);
        }}
      />
    </>
  );
};
