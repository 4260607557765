import { gql } from '@apollo/client';

export const EditEvent = gql`
  mutation UpdateEvent(
    $id: String!
    $name: String!
    $location: String!
    $integrations: [EventIntegrationsCreateInput!]!
    $youtube: Boolean!
    $facebook: Boolean!
    $recordOnly: Boolean!
    $startTime: DateTime!
    $endTime: DateTime!
    $isOperated: Boolean!
    $streamLive: Boolean! # $recurrence: String!
    $operatorNotes: String
  ) {
    updateEvent(
      event: {
        id: $id
        name: $name
        location: $location
        integrations: $integrations
        stream_youtube: $youtube
        stream_facebook: $facebook
        record_only: $recordOnly
        start_time: $startTime
        end_time: $endTime
        is_operated: $isOperated
        stream_live: $streamLive
        operator_notes: $operatorNotes
      }
    ) {
      id
    }
  }
`;

export const CreateEvent = gql`
  mutation CreateEvent(
    $name: String!
    $location: String!
    $youtube: Boolean!
    $facebook: Boolean!
    $recordOnly: Boolean!
    $startTime: DateTime!
    $endTime: DateTime!
    $isOperated: Boolean!
    $streamLive: Boolean!
    $recurrence: String
    $recurrenceEndDate: DateTime
    $recurrenceDaysOfWeek: [String!]
    $recurrenceEndOn: Float
    $operatorNotes: String
    $integrations: [EventIntegrationsCreateInput!]!
  ) {
    scheduleEvent(
      event: {
        name: $name
        location: $location
        stream_youtube: $youtube
        stream_facebook: $facebook
        record_only: $recordOnly
        start_time: $startTime
        end_time: $endTime
        recurrence_end_date: $recurrenceEndDate
        recurrence_days_of_week: $recurrenceDaysOfWeek
        recurrence: $recurrence
        recurrence2: $recurrence
        recurrence_end_on: $recurrenceEndOn
        integrations: $integrations
        # recurrence: $recurrence,
        is_operated: $isOperated
        stream_live: $streamLive
        operator_notes: $operatorNotes
      }
    ) {
      id
      name
      start_time
      record_only
      integrations {
        id
        integration
      }
      location {
        user {
          id
        }
      }
    }
  }
`;

export const DeleteEvent = gql`
  mutation DeleteEvent($id: Int!) {
    deleteEvent(eventId: $id)
  }
`;

export const ExtendEventTime = gql`
  mutation ExtendEventTime($id: Float!, $endTime: DateTime!, $location: String!) {
    extendEventTime(eventToExtend: { id: $id, end_time: $endTime, location: $location })
  }
`;

export const StopEvent = gql`
  mutation StopEvent($id: Float!, $location: String!, $emitWebsocket: Boolean!) {
    stopEvent(eventToStop: { event: $id, location: $location, emitWebsocket: $emitWebsocket })
  }
`;

export const GetEvent = gql`
  query events($id: String, $name: String, $location: String) {
    events(event: { location: $location, name: $name, id: $id }) {
      status
      # id
      name
      start_time
      end_time
      is_operated
      integrations {
        id
        integration
      }
      # stream_youtube
      # stream_facebook
      stream_live
      record_only
      location {
        name
        id
        user {
          id
          first_name
          last_name
          lead_time
          time_zone
        }
      }
      start_time
      end_time
      operator_notes
    }
  }
`;

export const ShowEvent = gql`
  query events($id: String) {
    events(event: { id: $id }) {
      status
      # id

      location {
        name
        id
        user {
          id
          first_name
          last_name
          lead_time
          time_zone
        }
      }
      start_time
      end_time
    }
  }
`;
