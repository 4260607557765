/* eslint-disable import/no-internal-modules */
import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import type { ErrorResponse } from '@apollo/client/link/error';
import { onError } from '@apollo/client/link/error';
import type { Any } from '@livecontrol/core-utils';
import { assert } from '@sindresorhus/is';
import type { GraphQLError } from 'graphql';
import { freeze, produce } from 'immer';
import _ from 'lodash';
import create from 'zustand';
import type { Environment } from '../model';
import * as Storage from './storage';
import type { Draft, State, Store } from './types';

let __singleton: Store | undefined;

// Mimic the Zustand `useStore` interface as a lazy-loaded singleton
export const store = new Proxy<Store>(<Any>_.noop, {
  apply(_unused, thiz: unknown, args?: Any): Any {
    assert.object(__singleton);

    return __singleton.apply(thiz, args);
  },
  get(_unused, prop: keyof Store): Any {
    assert.object(__singleton);

    return prop === 'environment' ? __singleton.getState()[prop] : __singleton[prop];
  }
});

// Create and initialize the singleton store
export const initializer = (environment: Environment): Store => {
  assert.undefined(__singleton);

  // Construct the Apollo client
  const authLink = setContext((_unused: unknown, context: Record<string, Any>): Any => {
    const { token } = store.getState();

    if (token) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      context.headers = {
        ...context.headers,
        authorization: `Bearer ${token.jwt}`
      };
    }

    return context;
  });

  const errorLink = onError(({ graphQLErrors, networkError }: ErrorResponse) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }: GraphQLError): void => {
        // eslint-disable-next-line no-console
        console.error(
          `[GraphQL Error]: Message: ${message}, Location: ${JSON.stringify(
            locations
          )}, Path: ${JSON.stringify(path)}`
        );
      });
    }

    if (networkError) {
      // eslint-disable-next-line no-console
      console.error(`[Network error]: ${networkError.message}`);
    }
  });

  const httpLink = createHttpLink({
    uri: `${environment.GRAPHQL_URI}/graphql`
  });

  const apollo = new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache()
  });

  // Create the underlying Zustand store
  const zustand = create<State>(() =>
    freeze({
      apollo,
      environment,
      token: Storage.getToken()
    })
  );

  // Immerize the zustand store
  Object.defineProperty(zustand, 'produce', {
    get() {
      return (fn: (draft: Draft) => void): void => {
        zustand.setState(produce(fn));
      };
    }
  });

  __singleton = <Store>zustand;

  return __singleton;
};
