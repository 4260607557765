import React, { Component } from 'react';
import type { ReactNode } from 'react';
import B_Card from 'react-bootstrap/Card';
import type { CardProps } from 'react-bootstrap/Card';
import { Attributes as Attributes_ } from './attributes';
import { Content as Content_ } from './content';
import { Title as Title_ } from './title';

// eslint-disable-next-line react/prefer-stateless-function
export class Card extends Component<Card.Props> {
  public override render(): ReactNode {
    return React.createElement(B_Card, this.props);
  }
}

export namespace Card {
  export type Props = CardProps;

  export const { Body, Footer } = B_Card;

  /* eslint-disable @typescript-eslint/no-unused-vars */
  export import Attributes = Attributes_;
  export import Content = Content_;
  export import Title = Title_;
}
