import { Theme, Viewport } from '@livecontrol/core-ui';
import { Str } from '@livecontrol/core-utils';
import type { Range } from '@livecontrol/store-utils';
import cx from 'classnames';
import { DateTime } from 'luxon';
import nextTick from 'next-tick';
import { useCallback, useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import Button from 'react-bootstrap/Button';
import type { DropdownButtonProps } from 'react-bootstrap/DropdownButton';
import DropdownButton from 'react-bootstrap/DropdownButton';
import type { ReactDatePickerProps } from 'react-datepicker';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

const FilterDropwdown = styled.div`
  position:relative;
  button.btn-outline-tertiary {
    color: #0D1438;
    border-color: #8798AD;
  }

  button.btn-outline-tertiary:hover {
    border-color: #8798AD;
  }

  .show > button.btn-outline-tertiary:hover {
    color: #fff !important;
    background-color: #8798AD;
  }

  .dropdown-menu.show {
    background-color:white;
  }

  button.dropdown-toggle {
    position:relative;
    padding:8px 11px 8px 37px;
    color: #0D1438;
    font-size:12px;
    font-weight:600;
    display:flex;
    align-items:center;
    justify-content:center;
  }  

  button.dropdown-toggle:after {
    display:none;
  }

  .icon {
    position: absolute;
    width:20px;
    height:20px;
    top:8px;
    left:11px;
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center center;
    margin-right:6px;
  }

  .filter {
    background-image:url(/assets/icons/Filter-black.svg);
  }  

  .react-datepicker {
    border:none;
  }
`;

const Clear = ({ onClick }: { onClick: () => void }): ReactElement => (
  <div className='py-8px text-center'>
    <Button size='sm' variant='outline-primary' onClick={onClick}>
      Clear Date
    </Button>
  </div>
);

export const Period = (props: Period.Props): ReactElement => {
  const [show, setShow] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);

  const viewport = Theme.useViewport();

  const {
    className,
    onChange,
    pickerProps,
    showFormatOnMobile = false,
    value: { min, max } = {},
    ...rest
  } = props;

  useEffect(() => {
    setSelectedStartDate(props.value?.min ?? null);
  }, [props.value]);

  const maxDateLimit = selectedStartDate
    ? DateTime.fromJSDate(selectedStartDate).plus({ days: 90 }).toJSDate()
    : null;

  const valueFormat =
    min && max
      ? `${DateTime.fromJSDate(min).toLocaleString({
          ...DateTime.DATE_MED,
          ...(viewport < Viewport.LG ? { year: undefined } : {})
        })} - ${DateTime.fromJSDate(max).toLocaleString(DateTime.DATE_MED)}`
      : 'Date Range';

  const isMobile = viewport === Viewport.XS;
  const title = showFormatOnMobile ? valueFormat : isMobile ? 'Date' : valueFormat;

  const handleChange = useCallback(
    (v?: Period.Value, options?: { close: boolean }) => {

      if (v?.min) {
        setSelectedStartDate(v.min);
      }

      onChange?.(v);

      // Close the popup if an end date has been selected
      if (v?.max || options?.close) {
        nextTick(() => {
          setShow(false);
        });
      }
    },
    [onChange]
  );

  return (
    <FilterDropwdown>
      <DropdownButton
        title={Str.truncate(title, { length: 30 })}
        variant={max ? 'tint' : 'outline-tertiary'}
        show={show}
        onToggle={(isOpen: boolean): void => {
          setShow(isOpen);
        }}
        className={cx(className, 'lc-datepicker-dropdown')}
        {...rest}
      >
        <DatePicker
          calendarClassName='lc-datepicker'
          selected={min}
          onChange={(v): void => {
            if (Array.isArray(v)) {
              const [minValue, maxValue] = v;

              handleChange({ min: minValue ?? undefined, max: maxValue ?? undefined });
            } else if (v) {
              handleChange({ min: v });
            }
          }}
          startDate={min}
          endDate={max}
          selectsRange
          allowSameDay
          inline
          maxDate={maxDateLimit}
          {...pickerProps}
        >
          {max && (
            <Clear
              onClick={(): void => {
                handleChange({ min }, { close: true });
              }}
            />
          )}
        </DatePicker>
      </DropdownButton>
      <div className='icon filter'/>
    </FilterDropwdown>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Period {
  export type Value = Range<Date>;

  export type Props = Omit<DropdownButtonProps, 'onChange' | 'title'> & {
    value?: Value;
    pickerProps?: Omit<ReactDatePickerProps, 'onChange' >;
    onChange?: (value?: Value) => void;
    // TODO: Need a better approach to style this.
    showFormatOnMobile?: boolean;
  };
}
