/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { gql } from '@apollo/client';
import { Bool, EmailAddress, Num, Obj, Str } from '@livecontrol/core-utils';
import { Phone, ZipCode } from '@livecontrol/locale-us';
import type { LocationFromPermisson } from '@livecontrol/scheduler/model';
import { Account, User } from '@livecontrol/scheduler/model';
import { assert } from '@sindresorhus/is';
import { IANAZone } from 'luxon';
import type { PartialDeep } from 'type-fest';

const toAddress = (record?: Record): string | undefined => {
  let address = Str.normalize(record?.organization_address);
  const zipCode = ZipCode.normalize(record?.organization_zip_code);

  // Tack on the ZIP code if it is not already there
  if (address && zipCode && !address.includes(zipCode.zip5)) {
    address = `${address} ${zipCode.format()}`;
  }

  return address;
};

export type Record = PartialDeep<{
  id: number;
  industry: { id: number };
  preferred_contact_method: string;
  lead_time: number;
  organization_address: string;
  organization_name: string;
  organization_phone_number: string;
  organization_zip_code: string;
  phone_number?: string;
  profile_picture: string;
  time_zone: string;
  first_name: string;
  last_name: string;
  email: string;
  contactRoles: ContactRole[];
}>;

export type SubUserAccountsRecord = PartialDeep<{
  id: number;
  first_name: string;
  last_name: string;
  preferred_contact_method: string;
  email: string;
  phone_number?: string;
  enable_produced_event_contact: boolean;
  has_all_locations: boolean;
  sub_user_locations: SubUserLocations[];
  landline?: string;
  landline_extension?: string;
  additional_information?: string;
  subUserPermission: {
    id: number;
    event_managment: boolean;
    view_and_download: boolean;
    content_managment: boolean;
    destinations: boolean;
    customize_webplayer: boolean;
    organization_account_info: boolean;
    activity_log: boolean;
    billing: boolean;
    admin_access: boolean;
    customize_production_notes: boolean;
    some_location_has_event_managment_enabled: boolean;
    some_location_has_view_and_download_enabled: boolean;
    some_location_has_content_managment_enabled: boolean;
    some_location_has_activity_log_enabled: boolean;
    some_location_has_customize_production_notes_enabled: boolean;
  };
  contactRoles: ContactRole[];
}>;

export interface SubUserLocations {
  id: string;
  location: LocationFromPermisson;
  event_managment: boolean;
  view_and_download: boolean;
  content_managment: boolean;
  activity_log: boolean;
  customize_production_notes: boolean;
}

export interface ContactRole {
  id: string;
  location?: LocationFromPermisson;
  onboarding_main: boolean;
  onboarding_technical: boolean;
  onsite_technical: boolean;
  onsite_technical_priority: number;
  primary_billing: boolean;
  shipping: boolean;
  user: {
    id: number;
    email: string;
  };
}

export const __typename = 'Account';

export const normalize = (
  record?: Record,
  subUserAccountsRecords?: SubUserAccountsRecord[]
): Account | undefined => {
  let account;

  if (record) {
    const id = Account.toId(record.id);
    const address = toAddress(record);
    const phone = record.phone_number ? Phone.normalize(record.phone_number) : undefined;
    const tz = Str.normalize(record.time_zone);
    const ownerFirstName = Str.normalize(record.first_name);
    const ownerLastName = Str.normalize(record.last_name);

    const accountContactRoles: User.ContactRole[] = [];

    if (record.contactRoles) {
      record.contactRoles.forEach((accountContactRole) => {
        const userContactLocation = {
          id: accountContactRole?.location?.id!,
          name: accountContactRole?.location?.name!,
          is_disabled: accountContactRole?.location?.is_disabled!
        };

        accountContactRoles.push({
          id: accountContactRole?.id!,
          location: userContactLocation,
          onboarding_main: Bool.normalize(accountContactRole?.onboarding_main),
          onboarding_technical: Bool.normalize(accountContactRole?.onboarding_technical),
          onsite_technical: Bool.normalize(accountContactRole?.onsite_technical),
          onsite_technical_priority: accountContactRole?.onsite_technical_priority!,
          primary_billing: Bool.normalize(accountContactRole?.primary_billing),
          shipping: Bool.normalize(accountContactRole?.shipping)
        });
      });
    }

    const candidate = {
      id,
      name: Str.normalize(record.organization_name),
      contactRole: accountContactRoles.filter((µ) => !µ.location?.is_disabled),
      preferredContactMethod: record.preferred_contact_method ?? '--',
      timezone:
        tz && IANAZone.isValidZone(tz) ? new IANAZone(tz) : new IANAZone('America/Los_Angeles'),
      avatar: Str.normalize(record.profile_picture) ?? '/assets/avatar.png',
      leadTime: Num.normalize(record.lead_time) ?? 2,
      accountOwnerName: [ownerFirstName, ownerLastName].join(' '),
      accountOwnerEmail: EmailAddress.normalize(record.email),
      industry: Account.Industry.normalize(record.industry?.id)
    };

    const subUsers = (subUserAccountsRecords ?? []).map((subUser) => {
      let subUser_: Account.SubUser | undefined;

      const firstName = Str.normalize(subUser.first_name);
      const lastName = Str.normalize(subUser.last_name);
      const additionalInformation = Str.normalize(subUser.additional_information) ?? '';

      const preferredContactMethod = Str.normalize(subUser.preferred_contact_method) ?? '';

      const subUserCandidate = {
        id: User.toId(subUser.id),
        hasAllLocations: Bool.normalize(subUser.has_all_locations),
        firstName,
        lastName,
        preferredContactMethod,
        phone: subUser.phone_number ? Phone.normalize(subUser.phone_number) : undefined,
        landline: subUser.landline ? subUser.landline : 1,
        landlineExtension: subUser.landline_extension ? subUser.landline_extension : 1,
        fullName: [firstName, lastName].join(' '),
        email: EmailAddress.normalize(subUser.email),
        additionalInformation
      };

      if (!subUserCandidate.phone) {
        delete subUserCandidate.phone;
      }

      const subUserCandidatePermissions = {
        id: Num.normalize(subUser.subUserPermission?.id),
        eventManagment: Bool.normalize(subUser.subUserPermission?.event_managment),
        eventSchedule: Bool.normalize(subUser.subUserPermission?.event_managment),
        viewAndDownload: Bool.normalize(subUser.subUserPermission?.view_and_download),
        contentManagment: Bool.normalize(subUser.subUserPermission?.content_managment),
        archive: Bool.normalize(subUser.subUserPermission?.content_managment),
        readOnlyArchive: Bool.normalize(subUser.subUserPermission?.content_managment),
        destinations: Bool.normalize(subUser.subUserPermission?.destinations),
        webplayer: Bool.normalize(subUser.subUserPermission?.customize_webplayer),
        accountInfo: Bool.normalize(subUser.subUserPermission?.organization_account_info),
        activityLog: Bool.normalize(subUser.subUserPermission?.activity_log),
        billing: Bool.normalize(subUser.subUserPermission?.billing),
        admin: Bool.normalize(subUser.subUserPermission?.admin_access),
        productionNotes: Bool.normalize(subUser.subUserPermission?.customize_production_notes),
        organizationAccountInfo: Bool.normalize(
          subUser.subUserPermission?.organization_account_info
        ),
        someLocationHasEventManagmentEnabled: Bool.normalize(
          subUser.subUserPermission?.some_location_has_event_managment_enabled
        ),
        someLocationHasViewAndDownloadEnabled: Bool.normalize(
          subUser.subUserPermission?.some_location_has_view_and_download_enabled
        ),
        someLocationHasContentManagmentEnabled: Bool.normalize(
          subUser.subUserPermission?.some_location_has_content_managment_enabled
        ),
        someLocationHasActivityLogEnabled: Bool.normalize(
          subUser.subUserPermission?.some_location_has_activity_log_enabled
        ),
        someLocationHasCustomizeProductionNotesEnabled: Bool.normalize(
          subUser.subUserPermission?.some_location_has_customize_production_notes_enabled
        )
      };

      const userLocations: User.LocationPermissions[] = [];
      const userContactRole: User.ContactRole[] = [];

      if (subUser.sub_user_locations) {
        subUser.sub_user_locations.forEach((subUserLocation) => {
          userLocations.push({
            id: subUserLocation?.id!,
            location: {
              id: subUserLocation?.location?.id!,
              name: subUserLocation?.location?.name!,
              is_disabled: subUserLocation?.location?.is_disabled!
            },
            eventManagment: Bool.normalize(subUserLocation?.event_managment),
            viewAndDownload: Bool.normalize(subUserLocation?.view_and_download),
            contentManagment: Bool.normalize(subUserLocation?.content_managment),
            activityLog: Bool.normalize(subUserLocation?.activity_log),
            productionNotes: Bool.normalize(subUserLocation?.customize_production_notes)
          });
        });
      }

      if (subUser.contactRoles) {
        subUser.contactRoles.forEach((subUserContactRoles) => {
          const userContactLocation = {
            id: subUserContactRoles?.location?.id!,
            name: subUserContactRoles?.location?.name!,
            is_disabled: subUserContactRoles?.location?.is_disabled
          };

          const userContact = {
            id: subUserContactRoles?.user?.id!,
            email: subUserContactRoles?.user?.email!
          };

          userContactRole.push({
            id: subUserContactRoles?.id!,
            location: userContactLocation,
            onboarding_main: Bool.normalize(subUserContactRoles?.onboarding_main),
            onboarding_technical: Bool.normalize(subUserContactRoles?.onboarding_technical),
            onsite_technical: Bool.normalize(subUserContactRoles?.onsite_technical),
            onsite_technical_priority: subUserContactRoles?.onsite_technical_priority!,
            primary_billing: Bool.normalize(subUserContactRoles?.primary_billing),
            shipping: Bool.normalize(subUserContactRoles?.shipping),
            user: userContact
          });
        });
      }

      if (Obj.isHydrated(subUserCandidate) && Obj.isHydrated(subUserCandidatePermissions)) {
        subUser_ = {
          ...subUserCandidate,
          landline: Phone.normalize(subUser.landline),
          landlineExtension: subUser.landline_extension ? subUser.landline_extension : '',
          permissions: { ...subUserCandidatePermissions },
          contactRole: [...userContactRole].filter((µ) => !µ.location?.is_disabled),
          sub_user_locations: userLocations
        };
      }

      assert.object(subUser_);

      return subUser_;
    });

    if (Obj.isHydrated(candidate)) {
      account = {
        ...candidate,
        organizationPhoneNumber: Phone.normalize(record.organization_phone_number),
        address,
        phone,
        subUsers
      };
    }
  }

  return account;
};

export const StandardAccountResponse = gql`
  fragment StandardAccountResponse on Account {
    id
    industry {
      id
    }
    organization_address
    preferred_contact_method
    organization_name
    organization_phone_number
    organization_zip_code
    phone_number
    profile_picture
    time_zone
    lead_time
    first_name
    last_name
    email
    contactRoles {
      id
      location {
        id
        name
        is_disabled
      }
      onboarding_main
      onboarding_technical
      onsite_technical
      onsite_technical_priority
      primary_billing
      shipping
    }
  }
`;

export const StandardSubUserAccountsResponse = gql`
  fragment StandardSubUserAccountsResponse on SubUser {
    id
    first_name
    last_name
    phone_number
    email
    preferred_contact_method
    has_all_locations
    landline
    landline_extension
    additional_information
    sub_user_locations {
      id
      location {
        id
        name
        is_disabled
      }
      event_managment
      customize_production_notes
      view_and_download
      content_managment
      activity_log
    }
    subUserPermission {
      id
      event_managment
      view_and_download
      content_managment
      destinations
      customize_webplayer
      organization_account_info
      activity_log
      billing
      admin_access
      customize_production_notes
      some_location_has_event_managment_enabled
      some_location_has_view_and_download_enabled
      some_location_has_content_managment_enabled
      some_location_has_activity_log_enabled
      some_location_has_customize_production_notes_enabled
    }
    contactRoles {
      id
      location {
        id
        name
        is_disabled
      }
      onboarding_main
      onboarding_technical
      onsite_technical
      onsite_technical_priority
      primary_billing
      shipping
      user {
        id
        email
      }
    }
  }
`;

export const StandarSubUserLocation = gql`
  fragment StandarSubUserLocation on SubUserLocations {
    id
    location {
      id
      name
    }
    event_managment
    view_and_download
    content_managment
    activity_log
    subUser {
      id
    }
  }
`;
