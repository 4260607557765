import type { Viewport } from '@livecontrol/core-ui';
import { Device, Theme } from '@livecontrol/core-ui';
import type { Any } from '@livecontrol/core-utils';
import React, { useState } from 'react';

export interface Store {
  readonly viewport: Viewport;
  readonly sidebar: Store.Sidebar;
}

export namespace Store {
  export interface Sidebar {
    readonly isExpanded: boolean;
    readonly isClosing: boolean;

    expand: () => void;
    collapse: () => void;
    toggle: () => void;
  }
}

export const Context = React.createContext<Store>(<Any>undefined);

export const useStore = (): Store => {
  const viewport = Theme.useViewport();

  const isMobile = Device.isMobile(viewport);
  const isTablet = Device.isTablet(viewport);
  const isDesktop = Device.isDesktop(viewport);

  const [isMobileExpanded, setMobileExpanded] = useState(false);
  const [isTabletExpanded, setTabletExpanded] = useState(false);
  const [isDesktopExpanded, setDesktopExpanded] = useState(true);

  const [isClosing, setIsClosing] = useState(false);

  const isExpanded_ = (): boolean => {
    switch (true) {
      case isMobile:
        return isMobileExpanded;
      case isTablet:
        return isTabletExpanded;
      case isDesktop:
        return isDesktopExpanded;
    }

    return false;
  };

  const isClosing_ = (): boolean => isClosing;

  const setExpanded = (b: boolean): void => {
    if (isMobile) {
      setMobileExpanded(b);
    } else if (isTablet) {
      setTabletExpanded(b);
    } else if (isDesktop) {
      setDesktopExpanded(b);
    }
  };

  return {
    viewport,

    sidebar: {
      get isExpanded(): boolean {
        return isExpanded_();
      },

      get isClosing(): boolean {
        return isClosing_();
      },

      expand(): void {
        setExpanded(true);
      },

      collapse(): void {
        setExpanded(false);
      },

      toggle(): void {
        setExpanded(!isExpanded_());

        if (isExpanded_()) {
          setIsClosing(true);

          setTimeout(() => {
            setIsClosing(false);
          }, 1000);
        }
      }
    }
  };
};
