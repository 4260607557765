import validator from 'validator';

import { EventValidator } from './types';

export const validateEventBackground = (
  eventInfo: EventValidator.EventInfo
): Pick<EventValidator.Errors, 'backgroundURL'> => {
  const errors: EventValidator.Errors = {};

  if (!eventInfo.backgroundURL || eventInfo.backgroundURL.length === 0) {
    return errors;
  }

  if (eventInfo.backgroundURL === 'loading') {
    errors.backgroundURL = EventValidator.ErrorMessages.BACKGROUND_UPLOADING;
  } else if (!validator.isURL(encodeURI(eventInfo.backgroundURL))) {
    errors.backgroundURL = EventValidator.ErrorMessages.BACKGROUND_INVALID;
  }

  return errors;
};
