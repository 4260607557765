import { Form } from '@livecontrol/core-ui';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { UrlHelper } from '../../common';

interface Props {
  onSubmit?: (value: string, title?: string) => void;
  className?: string;
  children: React.ReactNode;
  currentCustomTitle?: string;
  currentLink?: string;
}

export const CustomButtonForm = ({
  currentCustomTitle = '',
  currentLink = '',
  onSubmit,
  className,
  children
}: Props): React.ReactElement => {
  const formik = useFormik<{ title: string; link: string }>({
    initialValues: {
      title: currentCustomTitle,
      link: currentLink
    },
    validationSchema: Yup.object({
      link: Yup.string()
        .required('Please enter a valid URL.')
        .trim()
        .transform(UrlHelper.cleanUrl)
        .url('Please enter a valid URL.'),
      title: Yup.string().required('Please enter a title for the button')
    }),
    onSubmit(value) {
      onSubmit?.(UrlHelper.cleanUrl(value.link), value.title);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form className={className}>{children}</Form>
    </FormikProvider>
  );
};
