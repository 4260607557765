import { gql, useMutation } from '@apollo/client';
import { Num, Str } from '@livecontrol/core-utils';
import { assert } from '@sindresorhus/is';
import type { MutationResult } from '../../graphql';
import type { StripeLinkPaymentTokenResult } from './types';
import * as Utils from './utils';

interface T_Data {
  linkStripePaymentMethod: StripeLinkPaymentTokenResult;
}

interface T_Variables {
  user: number;
  stripePaymentMethod: string;
}

const MUTATION = gql`
  mutation linkStripePaymentMethod($user: Int!, $stripePaymentMethod: String!) {
    linkStripePaymentMethod(user: $user, stripePaymentMethod: $stripePaymentMethod) {
      ok
    }
  }
`;

export const useLinkStripePaymentMethod = (): [
  (userId: number, stripePaymentMethod: string) => Promise<StripeLinkPaymentTokenResult>,
  MutationResult
] => {
  const [fn, result] = useMutation<T_Data, T_Variables>(MUTATION);

  return [
    async (userId: number, stripePaymentMethod: string): Promise<StripeLinkPaymentTokenResult> => {
      const canonicalUserId = Num.normalize(userId);
      const canonicalStripePaymentMethod = Str.normalize(stripePaymentMethod);

      assert.number(canonicalUserId);
      assert.string(canonicalStripePaymentMethod);

      const mutation = await fn({
        variables: {
          user: canonicalUserId,
          stripePaymentMethod: canonicalStripePaymentMethod
        }
      });

      const stripeLinkResult = Utils.normalize(mutation.data?.linkStripePaymentMethod);

      return stripeLinkResult!;
    },
    result
  ];
};
