import { Loading } from '@livecontrol/core-ui';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { ReactElement } from 'react';
import { SectionContainer } from '../../components';
import { Store } from '../../store';
import { Plan } from './plan';

export const Subscription = (): ReactElement => {

  const me = Store.User.useMe();

  const { billing } = Scheduler.Account.useBilling(me.id);

  return (
  <SectionContainer className='col-12'>
    {
      billing ? (<Plan billing={billing} me={me} />) :
      (
        <Loading.Delay />
      )
    }
  </SectionContainer>
);
};
