import { EventValidator } from './types';

export const validateEventLeadtimeAcknowledge = (
  newEventInfo: EventValidator.EventInfo
): EventValidator.Errors => {
  const errors: EventValidator.Errors = {};
  const acknowledge = newEventInfo.leadtimeAcknowledge;

  if (acknowledge === false) {
    errors.leadtimeAcknowledge = EventValidator.ErrorMessages.LEADTIME_ACKNOWLEDGE;
  }

  return errors;
};
