import { Arr, Bool, Obj, Str } from '@livecontrol/core-utils';
import { Account } from '@livecontrol/scheduler/model';
import type { PartialDeep } from 'type-fest';
import type { Record } from './types';

import WebPlayer = Account.WebPlayer;

export const normalize = (record?: Record): WebPlayer | undefined => {
  let webplayer: WebPlayer | undefined;

  if (record) {
    const candidate = {
      displayName: Str.normalize(record.name)
    };

    if (!Obj.isHydrated(candidate)) {
      return undefined;
    }

    // Tack on optional fields
    webplayer = Object.assign(candidate, <Account.WebPlayer>{
      endpoint: Str.normalize(record.slug),
      logo: Str.normalize(record.logo_url),
      background: Str.normalize(record.custom_placeholder_image),
      intro: Str.normalize(record.intro_url),
      outro: Str.normalize(record.outro_url),
      about: Str.normalize(record.about),
      theme: Str.normalize(record.theme),
      followUsLinks: Object.fromEntries(
        Arr.normalize(
          record.links?.map(({ type, url }) => ({
            type: Str.normalize(type),
            url: Str.normalize(url)
          }))
        ).map((v) => [v.type ?? '', v.url])
      ),
      donation: ((): WebPlayer.Donation | undefined => {
        const label = record.custom_donate_button_copy;
        const link = record.donate_link;

        return typeof label === 'string' || typeof link === 'string' ? { label, link } : undefined;
      })(),
      tabs: {
        archive: Bool.normalize(record.has_archive),
        chat: Bool.normalize(record.has_chat),
        profile: Bool.normalize(record.has_profile),
        schedule: Bool.normalize(record.has_schedule)
      },
      isCustomButtonEnabled: Bool.normalize(record.custom_button_enabled),
      customButton: ((): WebPlayer.CustomButton | undefined => {
        const title = record.custom_button?.title;
        const url = record.custom_button?.url;

        return typeof title === 'string' && typeof url === 'string' ? { title, url } : undefined;
      })()
    });
  }

  return webplayer;
};

export const normalizeForMutation = (updates?: PartialDeep<WebPlayer>): Record | undefined => {
  const webPlayerUpdates: Record = {};

  if (updates?.displayName) {
    webPlayerUpdates.name = Str.normalize(updates.displayName);
  }

  if (updates?.endpoint) {
    webPlayerUpdates.slug = Str.normalize(updates.endpoint);
  }

  if (typeof updates?.logo === 'string') {
    webPlayerUpdates.logo_url = updates.logo;
  }

  if (typeof updates?.intro === 'string') {
    webPlayerUpdates.intro_url = updates.intro;
  }

  if (typeof updates?.outro === 'string') {
    webPlayerUpdates.outro_url = updates.outro;
  }

  if (updates?.theme) {
    webPlayerUpdates.theme = updates.theme;
  }

  if (typeof updates?.about === 'string') {
    webPlayerUpdates.about = updates.about;
  }

  if (updates?.followUsLinks) {
    const { followUsLinks } = updates;

    const links = Object.keys(followUsLinks).map((key) => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
      const value = followUsLinks[key as any as keyof WebPlayer.FollowUsLinks];

      return { type: key, url: value! };
    });

    webPlayerUpdates.links = links;
  }

  if (typeof updates?.background === 'string') {
    webPlayerUpdates.custom_placeholder_image = updates.background;
  }

  if (typeof updates?.donation?.label === 'string') {
    webPlayerUpdates.custom_donate_button_copy = updates.donation.label;
  }

  if (typeof updates?.donation?.link === 'string') {
    webPlayerUpdates.donate_link = updates.donation.link;
  }

  if (typeof updates?.tabs?.archive === 'boolean') {
    webPlayerUpdates.has_archive = Bool.normalize(updates.tabs.archive);
  }

  if (typeof updates?.tabs?.schedule === 'boolean') {
    webPlayerUpdates.has_schedule = Bool.normalize(updates.tabs.schedule);
  }

  if (typeof updates?.tabs?.chat === 'boolean') {
    webPlayerUpdates.has_chat = Bool.normalize(updates.tabs.chat);
  }

  if (typeof updates?.tabs?.profile === 'boolean') {
    webPlayerUpdates.has_profile = Bool.normalize(updates.tabs.profile);
  }

  if (typeof updates?.isCustomButtonEnabled === 'boolean') {
    webPlayerUpdates.custom_button_enabled = Bool.normalize(updates.isCustomButtonEnabled);
  }

  if (
    typeof updates?.customButton?.title === 'string' &&
    typeof updates.customButton.url === 'string'
  ) {
    webPlayerUpdates.custom_button = { title: '', url: '' };
    webPlayerUpdates.custom_button.title = updates.customButton.title;
    webPlayerUpdates.custom_donate_button_copy = updates.custom_donate_button_copy;
    webPlayerUpdates.donate_link = updates.donate_link;
    webPlayerUpdates.custom_button.url = updates.customButton.url;
  }

  return webPlayerUpdates;
};
