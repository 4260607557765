import { gql } from '@apollo/client';
import { Bool, EmailAddress, Num, Obj, Str } from '@livecontrol/core-utils';
import { Phone } from '@livecontrol/locale-us';
import { User } from '@livecontrol/scheduler/model';
import type { LocationFromPermisson } from '@livecontrol/scheduler/model';
import { DateTime } from 'luxon';
import type { PartialDeep } from 'type-fest';

export type Record = PartialDeep<{
  id: number;
  lead_time: number;
  email: string;
  phone_number?: string;
  preferred_contact_method: string;
  first_name: string;
  last_name: string;
  role: { id: number };
  status: { id: number };
  additional_information: string;
  landline?: string;
  time_zone: string;
  landline_extension?: string;
  enable_produced_event_contact: boolean;
  sub_user_locations: SubUserLocations[];
  subUserPermission?: {
    destinations: boolean;
    customize_webplayer: boolean;
    organization_account_info: boolean;
    billing: boolean;
    admin_access: boolean;
    id?: number;
    // evaluate
    activity_log: boolean;
    event_managment: boolean;
    content_managment: boolean;
    customize_production_notes: boolean;
    view_and_download: boolean;
    some_location_has_event_managment_enabled: boolean;
    some_location_has_view_and_download_enabled: boolean;
    some_location_has_content_managment_enabled: boolean;
    some_location_has_activity_log_enabled: boolean;
    some_location_has_customize_production_notes_enabled: boolean;
  };
  userAccountDetails?: {
    channel_organization?: string;
    channel_phone_area_code?: number;
    channel_phone?: number;
    renewal_date?: string;
    accountType: {
      id: string;
      name: string;
    };
  };
  email_location_notifications: Email_Location_Notification[];
  userGlobalSettings: {
    event_scheduling: boolean;
    analytics_range: number;
    client_contact_info: boolean;
    client_tier: string;
    cue_sheet: boolean;
    id: number;
    on_site_event_contact: boolean;
    operator_event_notes: boolean;
    password_protect: boolean;
    recurring_events: boolean;
    webplayer_redirect: boolean;
    webplayer_registration: boolean;
    webplayer_registration_required: boolean;
    webplayer_show_viewers: boolean;
  };
}>;

interface Email_Location_Notification {
  connection_issues: boolean;
  customer_feedback: boolean;
  event_reminder: boolean;
  event_scheduling: boolean;
  id: number;
  location: {
    id: string;
  };
}

export interface EmailLocationNotification {
  id: number;
  locationId: string;
  connectionIssues: boolean;
  customerFeedback: boolean;
  eventReminder: boolean;
  eventScheduling: boolean;
}

export interface SubUserLocations {
  id: string;
  location: LocationFromPermisson;
  event_managment: boolean;
  view_and_download: boolean;
  content_managment: boolean;
  activity_log: boolean;
  customize_production_notes: boolean;
}

export const __typename = 'Users';

export const normalize = (record?: Record): User | undefined => {
  let user: User | undefined;

  if (record) {
    const firstName = Str.normalize(record.first_name);
    const leadTime = Num.normalize(record.lead_time);
    const lastName = Str.normalize(record.last_name);
    const role = User.Role.normalize(record.role?.id);
    const phone = Phone.normalize(record.phone_number) ?? undefined;

    const preferredContactMethod = record.preferred_contact_method
      ? Str.normalize(record.preferred_contact_method)
      : 'Not Have';

    const userAccountDetails = {
      channelOrganization: Str.normalize(record.userAccountDetails?.channel_organization) ?? '',
      channelPhoneAreaCode: Num.normalize(record.userAccountDetails?.channel_phone_area_code),
      channelPhone: Phone.normalize(record.userAccountDetails?.channel_phone),
      renewalDate: record.userAccountDetails?.renewal_date
        ? DateTime.fromISO(record.userAccountDetails.renewal_date)
        : undefined,
      accountType: {
        id: Num.normalize(record.userAccountDetails?.accountType?.id),
        name: Str.normalize(record.userAccountDetails?.accountType?.name) ?? ''
      }
    };

    const userGlobalSettings = {
      analyticsRange: Num.normalize(record.userGlobalSettings?.analytics_range),
      clientContactInfo: Bool.normalize(record.userGlobalSettings?.client_contact_info),
      clientTier: Str.normalize(record.userGlobalSettings?.client_tier),
      cueSheet: Bool.normalize(record.userGlobalSettings?.cue_sheet),
      id: Num.normalize(record.userGlobalSettings?.id),
      onSiteEventContact: Bool.normalize(record.userGlobalSettings?.on_site_event_contact),
      operatorEventNotes: Bool.normalize(record.userGlobalSettings?.operator_event_notes),
      passwordProtect: Bool.normalize(record.userGlobalSettings?.password_protect),
      recurringEvents: Bool.normalize(record.userGlobalSettings?.recurring_events),
      webplayerRedirect: Bool.normalize(record.userGlobalSettings?.webplayer_redirect),
      webplayerRegistration: Bool.normalize(record.userGlobalSettings?.webplayer_registration),
      webplayerRegistrationRequired: Bool.normalize(
        record.userGlobalSettings?.webplayer_registration_required
      ),
      webplayerShowViewers: Bool.normalize(record.userGlobalSettings?.webplayer_show_viewers)
    };

    // Required fields
    const candidate = {
      id: User.toId(record.id),
      email: EmailAddress.normalize(record.email),
      timezone: Str.normalize(record.time_zone),
      phone,
      firstName,
      preferredContactMethod,
      lastName,
      leadTime,
      fullName: [firstName, lastName].join(' '),
      role,
      userAccountDetails,
      status: User.Status.normalize(record.status?.id),
      userGlobalSettings
    };

    if (!candidate.phone) {
      delete candidate.phone;
    }

    if (Obj.isHydrated(candidate)) {
      const permissions =
        role !== User.Role.Client
          ? {
              id: Number(record.subUserPermission?.id),
              destinations: Bool.normalize(record.subUserPermission?.destinations),
              webplayer: Bool.normalize(record.subUserPermission?.customize_webplayer),
              billing: Bool.normalize(record.subUserPermission?.billing),
              accountInfo: Bool.normalize(record.subUserPermission?.organization_account_info),
              admin: Bool.normalize(record.subUserPermission?.admin_access),
              activityLog: Bool.normalize(record.subUserPermission?.activity_log),
              eventSchedule: Bool.normalize(record.subUserPermission?.event_managment),
              archive: Bool.normalize(record.subUserPermission?.content_managment),
              readOnlyArchive: Bool.normalize(record.subUserPermission?.content_managment),
              viewAndDownload: Bool.normalize(record.subUserPermission?.content_managment),
              productionNotes: Bool.normalize(record.subUserPermission?.content_managment),
              organizationAccountInfo: Bool.normalize(
                record.subUserPermission?.organization_account_info
              ),
              someLocationHasEventManagmentEnabled: Bool.normalize(
                record.subUserPermission?.some_location_has_event_managment_enabled
              ),
              someLocationHasViewAndDownloadEnabled: Bool.normalize(
                record.subUserPermission?.some_location_has_view_and_download_enabled
              ),
              someLocationHasContentManagmentEnabled: Bool.normalize(
                record.subUserPermission?.some_location_has_content_managment_enabled
              ),
              someLocationHasActivityLogEnabled: Bool.normalize(
                record.subUserPermission?.some_location_has_activity_log_enabled
              ),
              someLocationHasCustomizeProductionNotesEnabled: Bool.normalize(
                record.subUserPermission?.some_location_has_customize_production_notes_enabled
              )
            }
          : {
              eventManagment: true,
              viewAndDownload: true,
              contentManagment: true,
              destinations: true,
              webplayer: true,
              productionNotes: true,
              accountInfo: true,
              activityLog: true,
              billing: true,
              admin: true,
              eventSchedule: true,
              archive: true,
              readOnlyArchive: true,
              organizationAccountInfo: true,
              someLocationHasEventManagmentEnabled: true,
              someLocationHasViewAndDownloadEnabled: true,
              someLocationHasContentManagmentEnabled: true,
              someLocationHasActivityLogEnabled: true,
              someLocationHasCustomizeProductionNotesEnabled: true
            };

      const enableProducedEventContacts = Bool.normalize(record.enable_produced_event_contact);

      user = {
        ...candidate,
        landlineExtension: record.landline_extension ? record.landline_extension : '',
        permissions,
        enableProducedEventContacts
      };

      const userLocations: User.LocationPermissions[] = [];

      if (record.sub_user_locations) {
        record.sub_user_locations.forEach((recordLocation) => {
          userLocations.push({
            id: recordLocation?.id ? recordLocation.id : '',
            location: {
              id: recordLocation?.location?.id ? recordLocation.location.id : '',
              name: recordLocation?.location?.name ? recordLocation.location.name : ''
            },
            eventManagment: Bool.normalize(recordLocation?.event_managment),
            productionNotes: Bool.normalize(recordLocation?.customize_production_notes),
            viewAndDownload: Bool.normalize(recordLocation?.view_and_download),
            contentManagment: Bool.normalize(recordLocation?.content_managment),
            activityLog: Bool.normalize(recordLocation?.activity_log)
          });
        });
      }

      const emailLocationNotifications: EmailLocationNotification[] = [];

      record.email_location_notifications?.forEach((emailLocation) => {
        const emailLocationNotification = {
          id: emailLocation?.id ? emailLocation.id : 1,
          connectionIssues: Bool.normalize(emailLocation?.connection_issues),
          customerFeedback: Bool.normalize(emailLocation?.customer_feedback),
          eventReminder: Bool.normalize(emailLocation?.event_reminder),
          eventScheduling: Bool.normalize(emailLocation?.event_scheduling),
          locationId: emailLocation?.location?.id ? emailLocation.location.id : ''
        };

        emailLocationNotifications.push(emailLocationNotification);
      });

      user = {
        ...candidate,
        landline: Phone.normalize(record.landline),
        landlineExtension: record.landline_extension ? record.landline_extension : '',
        additionalInformation: record.additional_information ? record.additional_information : '',
        permissions,
        emailLocationNotifications,
        sub_user_locations: userLocations,
        enableProducedEventContacts
      };
    }
  }

  return user;
};

export const StandardUserResponse = gql`
  fragment StandardUserResponse on Users {
    id
    email
    phone_number
    landline
    landline_extension
    lead_time
    first_name
    last_name
    preferred_contact_method
    time_zone
    additional_information
    role {
      id
    }
    status {
      id
    }
    enable_produced_event_contact
    subUserPermission {
      destinations
      customize_webplayer
      organization_account_info
      billing
      admin_access
      event_managment
      content_managment
      view_and_download
      customize_production_notes
      some_location_has_event_managment_enabled
      some_location_has_view_and_download_enabled
      some_location_has_content_managment_enabled
      some_location_has_activity_log_enabled
      some_location_has_customize_production_notes_enabled
      id
    }
    userAccountDetails {
      channel_organization
      channel_phone_area_code
      channel_phone
      renewal_date
      accountType {
        id
        name
      }
    }
    email_location_notifications {
      connection_issues
      customer_feedback
      event_reminder
      event_scheduling
      id
      location {
        id
      }
    }
    sub_user_locations {
      id
      location {
        id
        name
      }
      event_managment
      view_and_download
      content_managment
      activity_log
      customize_production_notes
    }
    userGlobalSettings {
      event_scheduling
      analytics_range
      client_contact_info
      client_tier
      cue_sheet
      id
      on_site_event_contact
      operator_event_notes
      password_protect
      recurring_events
      webplayer_redirect
      webplayer_registration
      webplayer_registration_required
      webplayer_show_viewers
    }
  }
`;
