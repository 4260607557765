import { Button, Device, Theme } from '@livecontrol/core-ui';
import type { Location, User } from '@livecontrol/scheduler/model';
import cx from 'classnames';
import { useState } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Panel } from '../../../components';
import { ChangePassword } from './change-password';
import { ViewPermissions } from './view-permissions';

const RolesTitleSection = styled.div`
  position: relative;
  width: fit-content;
  font-weight: 600;

  .custom-tooltip {
    display: none;
    justify-content: center;
    align-items: center;
    padding: 14px 15px;
    background: #0c3192;
    box-shadow: 0px 16px 34px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    width: 250px;
    max-width: 250px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: calc(100% + 10px);
    z-index: 11;
    text-align: center;
    min-width: 120px;

    .text {
      font-size: 11px;
      font-weight: 600;
      color: #ffffff;
      text-transform: initial;
      line-height: 14px;
    }

    .arrow {
      width: 16px;
      height: 8px;
      background: #0c3192;
      clip-path: polygon(100% 0, 0 0, 50% 100%);
      position: absolute;
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      bottom: calc(100% + 10px);
      margin-left: auto;
      margin-right: auto;
    }
  }
  &:hover .custom-tooltip {
    display: flex;
    flex-direction: column;
  }
`;

const RolesSection = styled.div`
  .roles-title {
    width: fit-content;
    font-weight: 600;
    position: relative;
  }

  .roles-text {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.5;

    .locations {
      font-weight: 400;
    }
  }

  .no-roles {
    font-style: italic;
  }
`;

interface Props {
  className: string;
  me: User;
  locations: Location[] | undefined;
  locationLoading: boolean;
  userRoles: {
    role: string;
    locations: string;
  }[];
}

export const Profile = ({
  className,
  me,
  locationLoading,
  locations,
  userRoles
}: Props): ReactElement => {
  const viewport = Theme.useViewport();
  const [showModal, setShowModal] = useState(false);
  const [showResetPasswordModal, setshowResetPasswordModal] = useState(false);

  return (
    <>
      <Panel className={className}>
        <div
          className={cx(
            'd-flex justify-content-between align-items-start',
            Device.isMobile(viewport) ? 'flex-column' : 'flex-row'
          )}
        >
          <div className='d-flex flex-column'>
            <RolesTitleSection>
              <div className='font-size-16px text-gray-light roles-title'>
                Assigned Contact Role(s):
                <div className='custom-tooltip'>
                  <div className='text'>
                    If your administrator has assigned you as the primary contact person for
                    LiveControl, it will be displayed here.
                  </div>
                  <div className='arrow' />
                </div>
              </div>
            </RolesTitleSection>

            <RolesSection>
              <div className='text-dark mt-16px'>
                {userRoles.length > 0 ? (
                  userRoles.map((role) => (
                    <div className='roles-text mt-2px mb-8px' key={role.role}>
                      <span className='title'>{role.role}</span>{' '}
                      <span className='locations'>{role.locations}</span>
                    </div>
                  ))
                ) : (
                  <div className='no-roles'>No Assigned Contact Roles</div>
                )}
              </div>
            </RolesSection>
          </div>

          <div
            className={cx(
              'd-flex flex-row',
              Device.isMobile(viewport) ? 'flex-column mt-16px' : 'flex-row'
            )}
          >
            <Button
              className={cx(
                'font-size-15px font-weight-bold',
                Device.isMobile(viewport) ? 'mb-8px' : 'mr-24px'
              )}
              style={{ height: '42px' }}
              variant='outline-primary'
              onClick={(): void => {
                setShowModal(true);
              }}
            >
              View Permissions
            </Button>
            <Button
              className='profile-btn'
              style={{ height: '42px' }}
              variant='primary'
              onClick={(): void => {
                setshowResetPasswordModal(true);
              }}
            >
              Change Password
            </Button>
          </div>
        </div>
      </Panel>
      <ViewPermissions
        locations={locations}
        locationLoading={locationLoading}
        show={showModal}
        me={me}
        onClose={(): void => {
          setShowModal(false);
        }}
      />

      <ChangePassword
        user={me}
        show={showResetPasswordModal}
        onClose={(): void => {
          setshowResetPasswordModal(false);
        }}
      />
    </>
  );
};
