import { Device, Theme } from '@livecontrol/core-ui';
import cx from 'classnames';
import type { ReactElement } from 'react';

interface props {
  title: string;
  className?: string;
  children: JSX.Element;
}

export const Detail = ({ title, className, children }: props): ReactElement => {
  const viewport = Theme.useViewport();

  return (
    <div
      className={cx(
        'd-flex flex-column font-size-13px font-weight-bold detail-container',
        className,
        Device.isMobile(viewport)
          ? 'col-12 mt-16px'
          : Device.isTablet(viewport)
          ? 'col-3 p-16px'
          : ''
      )}
    >
      <span className='text-tertiary font-size-13px'> {title} </span>
      {children}
    </div>
  );
};
