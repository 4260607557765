import { Button } from '@livecontrol/core-ui';
import type { Asset } from '@livecontrol/scheduler/model';
import { useState } from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import { Exporter } from './exporter';

type Props = HTMLAttributes<HTMLDivElement> & { asset: Asset.Like; users: Asset.Analytics.Viewers['users'] };

export const Export = ({ asset, users, ...props }: Props): ReactElement => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div {...props}>
      <Button
        variant='outline-primary'
        size='sm'
        onClick={(): void => {
          setShowModal(true);
        }}
      >
        <i className='fas fa-arrow-down' />
        Export
      </Button>

      {showModal && (
        <Exporter
          users={users}
          asset={asset}
          onClose={(): void => {
            setShowModal(false);
          }}
        />
      )}
    </div>
  );
};
