import { CopyCTA, Form } from '@livecontrol/core-ui';
import { Calendar } from '@livecontrol/core-utils';
import { Account, Event } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { EventValidator } from '@livecontrol/scheduler/validator';
import { assert } from '@sindresorhus/is';
import base64 from 'base-64';
import _ from 'lodash';
import { DateTime, Duration } from 'luxon';
import ordinal from 'ordinal';
import React, { useContext, useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import urlencode from 'urlencode';
import { Store, store as storeInstance } from '../../../store';
import type { EventContext, MobileContext } from '../event-store';
import { EventContextStore, MobileContextStore } from '../event-store';
import { ContentSection, FooterSection, HeaderSection } from '../layout-sections';

const EventInfoSection = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .event-info-content {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    max-width: 640px;
    .event-info-item-section {
      margin: 16px 30px 16px 0px;
      display: flex;
      min-width: 115px;
      &.full {
        width: 100%;
      }
      &.big {
        width: 230px;
      }
      &.med {
        width: 200px;
      }
      .event-info-item {
        width: 100%;
        .item-title {
          font-size: 12px;
          color: #8798ad;
          font-weight: 600;
        }
        .item-text {
          margin-top: 12px;
          font-weight: 600;
          font-size: 18px;
          line-height: 20px;
          color: #000000;
          word-wrap: break-word;
        }
        .credits-number {
          margin-top: 12px;
          width: 32px;
          height: 32px;
          border-radius: 16px;
          background-color: #2e5bff;
          color: #ffffff;
        }
        .shareable-link-password-radio {
          margin-top: 12px;
          .mb-4px.font-size-14px {
            font-weight: 600;
          }
        }
        .item-shareable-link-section {
          margin-top: 12px;
          flex-wrap: wrap;
          .link-container {
            width: 100%;
            max-width: 446px;
            display: flex;
            align-items: center;
            min-height: 46px;
            line-height: 18px;
            padding: 12px 20px;
            font-size: 15px;
            font-weight: 700;
            border: 1.5px solid #2e5bff;
            border-radius: 5px;
            margin-right: 14px;
            word-break: break-word;
            margin-bottom:10px;
          }
          button.btn {
            height: 46px;
            margin
          }
        }
      }
    }
  }
  .event-img-section {
    border-radius: 6px;
    overflow: hidden;
    min-width: 172px;
    .event.img {
      width: 172px;
      height: 97px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
`;

const EventInfoDivider = styled.div`
  padding: 12px 0px;
  width: 100%;
  max-width: 980px;
  border-top: 1px solid #d5daea;
`;

export const EventConfirmation = ({
  newEventId,
  mobileKitEventId,
  isEditing = false,
  onReset,
  setAsStatic = false
}: {
  newEventId?: number;
  mobileKitEventId?: number;
  isEditing?: boolean;
  onReset?: () => void;
  setAsStatic?: boolean;
}): React.ReactElement => {
  const { environment } = storeInstance.getState();
  const account = Store.Account.useAccount();
  const { billing } = Scheduler.Account.useBilling(account);
  const { store }: EventContext = useContext<EventContext>(EventContextStore);

  const { locations } = Scheduler.Location.useLocations(account);
  const { webPlayer } = Scheduler.Account.WebPlayer.useView(account.id);

  const mobileContext = useContext<MobileContext>(MobileContextStore);

  let mobileStore;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (mobileContext?.store) {
    mobileStore = mobileContext.store;
  }

  const isProduceAndMobile =
    (isEditing && mobileKitEventId && mobileKitEventId > 0) ||
    store.eventInfo.type === EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT;

  let mobileEventInfo: Event.MobileKitData = {
    title: '',
    description: '',
    location: '',
    start: undefined,
    end: undefined
  };

  if (
    store.eventInfo.type === EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT &&
    store.eventInfo.mobileKitData
  ) {
    mobileEventInfo = { ...store.eventInfo.mobileKitData };
  } else if (mobileKitEventId && mobileKitEventId > 0 && mobileStore) {
    mobileEventInfo = {
      title: mobileStore.eventInfo.title,
      description: mobileStore.eventInfo.description,
      location: mobileStore.eventInfo.location,
      start: mobileStore.eventInfo.start,
      end: mobileStore.eventInfo.end
    };
  }

  const eventImg = store.eventInfo.backgroundURL ? store.eventInfo.backgroundURL : '';

  let eventDuration =
    store.eventInfo.end &&
    store.eventInfo.start &&
    store.eventInfo.end.diff(store.eventInfo.start, 'minutes');

  let displayDuration = '-:-';

  if (eventDuration && eventDuration.minutes > 0) {
    const durationHours = Math.floor(eventDuration.minutes / 60);
    const durationMinutes = eventDuration.minutes - durationHours * 60;

    displayDuration = `${durationHours}h ${Math.round(durationMinutes * 100) / 100}m`;
  } else if (eventDuration && eventDuration.minutes < 0) {
    const positiveMinutes = 1440 + eventDuration.minutes;
    const durationHours = Math.floor(positiveMinutes / 60);
    const durationMinutes = positiveMinutes - durationHours * 60;

    displayDuration = `${durationHours}h ${Math.round(durationMinutes * 100) / 100}m`;

    eventDuration = Duration.fromMillis(positiveMinutes * 60 * 1000);
  }

  let creditsForEvent = 0;

  const isProduced =
    store.eventType === EventValidator.ProductionType.PRODUCED ||
    store.eventType === EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT;

  if (
    store.eventType === EventValidator.ProductionType.MOBILE_KIT
  ) {
    creditsForEvent = 1;
  } else if (isProduced && eventDuration && billing) {
    creditsForEvent = Event.Production.computeCreditsChargedForProducedEvent({
      duration: eventDuration,
      overTimeLimitCharge: billing.credits.over_time_limit_cost,
      overTimeLimitInMinutes: billing.credits.first_credit_time_limit
    });
    // calculate recurring
  }

  const eventLocation = locations?.find((location) => location.id === store.eventInfo.location);

  let startEndTimes = '-:-';

  if (store.eventInfo.start && store.eventInfo.end) {
    startEndTimes = `${store.eventInfo.start
      .setZone(account.timezone)
      .toFormat('h:mm a')} - ${store.eventInfo.end.setZone(account.timezone).toFormat('h:mm a')}`;
  }

  const { passwordSettings } = store.eventInfo;
  const [showPasswordProtectedURL, setShowPasswordProtectedURL] = useState(false);

  const venueURL = useMemo((): string | undefined => {
    if (!webPlayer?.endpoint) {
      return undefined;
    }

    const { endpoint } = webPlayer;

    assert.string(endpoint);

    let createdUrl;

    if (isProduceAndMobile) {
      createdUrl = Account.WebPlayer.makeURL({
        uri: environment.VENUE_URI,
        slug: webPlayer.endpoint,
        event: newEventId
      });

      createdUrl = `${createdUrl}&${Account.WebPlayer.makeEventSlug({
        eventId: mobileKitEventId
      })}`;

      if (showPasswordProtectedURL && passwordSettings?.password) {
        const encodedPass = urlencode(base64.encode(passwordSettings.password));

        createdUrl = `${createdUrl}?pwd1=${encodedPass}&pwd2=${encodedPass}`;
      }
    } else {
      createdUrl = Account.WebPlayer.makeURL({
        uri: environment.VENUE_URI,
        slug: webPlayer.endpoint,
        event: newEventId,
        password: showPasswordProtectedURL
          ? passwordSettings?.enabled
            ? passwordSettings.password
            : undefined
          : undefined
      });
    }

    return createdUrl;
  }, [
    webPlayer,
    environment.VENUE_URI,
    newEventId,
    showPasswordProtectedURL,
    passwordSettings?.enabled,
    passwordSettings?.password,
    mobileKitEventId,
    isProduceAndMobile
  ]);

  const isClientBooking = useMemo(
    () => store.eventInfo.type === EventValidator.ProductionType.CLIENT_BOOKING,
    [store]
  );

  let typeString = '';

  switch (store.eventType) {
    case EventValidator.ProductionType.CLIENT_BOOKING:
      typeString = 'Client Booking';

      break;

    case EventValidator.ProductionType.MOBILE:
      typeString = 'Mobile';

      break;

    case EventValidator.ProductionType.MOBILE_KIT:
      typeString = 'Mobile Kit';

      break;

    case EventValidator.ProductionType.PRODUCED:
      typeString = 'Produced';

      break;

    case EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT:
      typeString = 'Produced';

      break;

    case EventValidator.ProductionType.SIMULATED:
      typeString = 'Simulated';

      break;

    case EventValidator.ProductionType.STATIC:
      typeString = 'Static';

      break;

    case EventValidator.ProductionType.TEST:
      typeString = 'Test';

      break;

    default:
      typeString = 'Test';

      break;
  }

  if (setAsStatic) {
    typeString = 'Static';
  }

  // mobile
  const mobileEventLocation = isProduceAndMobile
    ? locations?.find((location) => location.id === mobileEventInfo.location)
    : null;

  let mobileEventDuration;

  let mobileEventDurationDisplay = '-:-';

  let mobileStartEndTimes = '-:-';

  if (isProduceAndMobile) {
    mobileEventDuration =
      mobileEventInfo.end &&
      mobileEventInfo.start &&
      mobileEventInfo.end.diff(mobileEventInfo.start, 'minutes');

    if (mobileEventDuration && mobileEventDuration.minutes > 0) {
      const durationHours = Math.floor(mobileEventDuration.minutes / 60);
      const durationMinutes = mobileEventDuration.minutes - durationHours * 60;

      mobileEventDurationDisplay = `${durationHours}h ${Math.round(durationMinutes * 100) / 100}m`;
    } else if (mobileEventDuration && mobileEventDuration.minutes < 0) {
      const positiveMinutes = 1440 + mobileEventDuration.minutes;
      const durationHours = Math.floor(positiveMinutes / 60);
      const durationMinutes = positiveMinutes - durationHours * 60;

      mobileEventDurationDisplay = `${durationHours}h ${Math.round(durationMinutes * 100) / 100}m`;

      mobileEventDuration = Duration.fromMillis(positiveMinutes * 60 * 1000);
    }

    if (mobileEventInfo.start && mobileEventInfo.end) {
      mobileStartEndTimes = `${mobileEventInfo.start
        .setZone(account.timezone)
        .toFormat('h:mm a')} - ${mobileEventInfo.end.setZone(account.timezone).toFormat('h:mm a')}`;
    }
  }

  return (
    <>
      {isProduceAndMobile ? (
        <>
          <HeaderSection>
            <div className='title'>
              <div className='pre-text'>
                Below is confirmation for both your Produced Event & Mobile Event. Once the Produced
                Event ends, viewers will automatically be redirected to watch the Mobile Event.
              </div>
            </div>
          </HeaderSection>
          <HeaderSection>
            <div className='title produced-mobile'>
              <div className='text'>Event #1 Confirmation</div>
            </div>
          </HeaderSection>
        </>
      ) : (
        <HeaderSection>
          <div className='font-size-20px font-weight-bold text-dark mt-16px'>
            Event confirmation
          </div>
        </HeaderSection>
      )}

      <ContentSection style={{ maxWidth: '870px' }}>
        <EventInfoSection>
          <div className='event-info-content'>
            <div className='event-info-item-section big'>
              <div className='event-info-item'>
                <div className='item-title'>Title</div>
                <div className='item-text'>
                  {store.eventType === EventValidator.ProductionType.TEST
                    ? `Test - ${store.eventInfo.title!}`
                    : _.upperFirst(store.eventInfo.title)}
                </div>
              </div>
            </div>

            <div className='event-info-item-section'>
              <div className='event-info-item'>
                <div className='item-title'>Start Date</div>
                <div className='item-text'>
                  {store.eventInfo.start
                    ? store.eventInfo.start
                        .setZone(account.timezone)
                        .toLocaleString(DateTime.DATE_SHORT)
                    : null}
                </div>
              </div>
            </div>

            <div className='event-info-item-section med'>
              <div className='event-info-item'>
                <div className='item-title'>Location</div>
                <div className='item-text'>{eventLocation?.name}</div>
              </div>
            </div>

            <div className='event-info-item-section big'>
              <div className='event-info-item'>
                <div className='item-title'>Start & End Time</div>
                <div className='item-text'>{startEndTimes}</div>
              </div>
            </div>

            <div className='event-info-item-section'>
              <div className='event-info-item'>
                <div className='item-title'>Duration</div>
                <div className='item-text'>{displayDuration}</div>
              </div>
            </div>

            <div className='event-info-item-section med'>
              <div className='event-info-item'>
                <div className='item-title'>Credits</div>
                <div className='credits-number d-flex align-items-center justify-content-center'>
                  {creditsForEvent}
                </div>
              </div>
              <div className='event-info-item'>
                <div className='item-title'>Event Type</div>
                <div className='item-text'>{typeString}</div>
              </div>
            </div>

            {store.eventInfo.repeatSettings?.repeat && store.eventInfo.start && (
              <div className='event-info-item-section'>
                <div className='event-info-item'>
                  <div className='item-title'>Recurring Event</div>
                  <div className='item-text'>
                    {store.eventInfo.repeatSettings.repeatFrequency?.unit === Calendar.Unit.WEEKS &&
                    store.eventInfo.repeatSettings.repeatFrequency.daysOfWeek
                      ? `Every ${[
                          ...store.eventInfo.repeatSettings.repeatFrequency.daysOfWeek
                        ].join(', ')} at ${store.eventInfo.start
                          .setZone(account.timezone)
                          .toLocaleString(DateTime.TIME_SIMPLE)}`
                      : `Every month on the ${ordinal(
                          store.eventInfo.start.setZone(account.timezone).day
                        )}`}
                  </div>

                  {store.eventInfo.repeatSettings.repeatEndCondition ===
                    EventValidator.EventInfo.RepeatEndCondition.AFTER_OCCURRENCES && (
                    <div className='item-text'>
                      {store.eventInfo.repeatSettings.repeatUntil?.numRecurrences
                        ? `For ${store.eventInfo.repeatSettings.repeatUntil.numRecurrences} recurrence(s)`
                        : undefined}
                    </div>
                  )}

                  {store.eventInfo.repeatSettings.repeatEndCondition ===
                    EventValidator.EventInfo.RepeatEndCondition.ON_DATE &&
                    store.eventInfo.repeatSettings.repeatUntil?.stopRepeatingOn && (
                      <div className='item-text'>
                        Until{' '}
                        {store.eventInfo.repeatSettings.repeatUntil.stopRepeatingOn.toLocaleString(
                          DateTime.DATE_MED
                        )}
                      </div>
                    )}
                </div>
              </div>
            )}
          </div>
          {eventImg.length > 0 ? (
            <div className='event-img-section'>
              <img src={eventImg} alt='event background' width='172px' height='auto' />
            </div>
          ) : null}
        </EventInfoSection>

        {isProduceAndMobile ? (
          <>
            <EventInfoDivider />
            <HeaderSection>
              <div className='title produced-mobile'>
                <div className='text'>Event #2 Confirmation</div>
              </div>
            </HeaderSection>
            <EventInfoSection>
              <div className='event-info-content'>
                <div className='event-info-item-section big'>
                  <div className='event-info-item'>
                    <div className='item-title'>Title</div>
                    <div className='item-text'>{mobileEventInfo.title}</div>
                  </div>
                </div>

                <div className='event-info-item-section'>
                  <div className='event-info-item'>
                    <div className='item-title'>Start Date</div>
                    <div className='item-text'>
                      {mobileEventInfo.start
                        ? mobileEventInfo.start
                            .setZone(account.timezone)
                            .toLocaleString(DateTime.DATE_SHORT)
                        : null}
                    </div>
                  </div>
                </div>

                <div className='event-info-item-section med'>
                  <div className='event-info-item'>
                    <div className='item-title'>Location</div>
                    <div className='item-text'>{mobileEventLocation?.name}</div>
                  </div>
                </div>

                <div className='event-info-item-section big'>
                  <div className='event-info-item'>
                    <div className='item-title'>Start & End Time</div>
                    <div className='item-text'>{mobileStartEndTimes}</div>
                  </div>
                </div>

                <div className='event-info-item-section'>
                  <div className='event-info-item'>
                    <div className='item-title'>Duration</div>
                    <div className='item-text'>{mobileEventDurationDisplay}</div>
                  </div>
                </div>

                <div className='event-info-item-section med'>
                  <div className='event-info-item'>
                    <div className='item-title'>Credits</div>
                    <div className='credits-number d-flex align-items-center justify-content-center'>
                      1
                    </div>
                  </div>
                  <div className='event-info-item'>
                    <div className='item-title'>Event Type</div>
                    <div className='item-text'>Mobile Kit</div>
                  </div>
                </div>
              </div>
              {eventImg.length > 0 ? (
                <div className='event-img-section'>
                  <img src={eventImg} alt='event background' width='172px' height='auto' />
                </div>
              ) : null}
            </EventInfoSection>
          </>
        ) : null}

        {/* Don't show Webplayer URL for Record Only (private) events */}
        {store.eventDestinations.length > 0 && !isClientBooking && !!venueURL && (
          <EventInfoSection>
            <div className='event-info-content w-100'>
              <div className='event-info-item-section full'>
                <div className='event-info-item w-100'>
                  <div className='item-title'>Link To Share Your Event</div>

                  {passwordSettings?.password && (
                    <div className='shareable-link-password-radio'>
                      <Form.Radio
                        className='mt-16px'
                        selected={!showPasswordProtectedURL}
                        onClick={(): void => {
                          setShowPasswordProtectedURL(false);
                        }}
                        title='Web Player Without Password'
                        description='This link will prompt users to enter the password you set'
                      />
                      <Form.Radio
                        className='mt-8px'
                        selected={showPasswordProtectedURL}
                        onClick={(): void => {
                          setShowPasswordProtectedURL(true);
                        }}
                        title='Web Player With Password in the URL'
                        description='This link contains the password, so users will not have to type in the password'
                      />
                    </div>
                  )}

                  <div className='item-shareable-link-section d-flex'>
                    <div className='link-container'>{venueURL}</div>
                    <CopyCTA value={venueURL} toastStyle={{ marginTop: 18 }}>
                      <Form.Submit size='md' style={{ minWidth: 150 }}>
                        <i className='fa fa-link mr-8px' />
                        Copy
                      </Form.Submit>
                    </CopyCTA>
                  </div>
                </div>
              </div>
            </div>
          </EventInfoSection>
        )}
      </ContentSection>

      <FooterSection>
        <Link to='/schedule'>
          <Button className='btn custom-primary-btn'>View Schedule</Button>
        </Link>
        {isEditing && onReset && (
          <Button className='text-secondary custom-primary-link' variant='link' onClick={onReset}>
            Edit Event
          </Button>
        )}

        {!isEditing && newEventId && !isProduceAndMobile && (
          <Link to={`/schedule/edit/${newEventId}`} className='text-secondary custom-primary-link'>
            Edit Event
          </Link>
        )}

        {!isEditing && newEventId && isProduceAndMobile && mobileKitEventId && (
          <Link
            to={`/schedule/edit-produce-mobile/${newEventId}/${mobileKitEventId}`}
            className='text-secondary custom-primary-link'
          >
            Edit Event
          </Link>
        )}
      </FooterSection>
    </>
  );
};
