import { Device } from '@livecontrol/core-ui';
import { ScaffoldContext } from '@livecontrol/scheduler/components';
import cx from 'classnames';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Store } from '../../store';

const Wrapper = styled.div`
  color: var(--tertiary);
  position:relative;

  .menu span {
    display: none !important;
  }

  .x-expanded & {
    .menu span {
      display: flex !important;
    }
  }

  .x-${Device.MOBILE} nav:hover &,
  .x-${Device.DESKSTOP} nav:hover & {
    .menu span {
      display: flex !important;
    }
  }

  label {
    padding: 25px 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-12px);
    font-weight: 400;
    border-bottom: 1px solid var(--tertiary);
  }

  div.menu {
    padding-left: 10px;
    height: 33px;
    font-size: var(--font-size-11px);;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  span {
    color: var(--white);
    padding-left: var(--spacer-4px);
    font-size: var(--font-size-14px));
    font-weight: 600;
  }
`;

const transition = (property: string): string => `${property} 500ms cubic-bezier(0.4, 0, 0.2, 1)`;

const Button = styled.button`
  position: absolute;
  left: 16px;
  top: 16px;
  border: none;
  margin-left: -10px;
  padding: 0;
  opacity: 0.5;
  transition: var(--transition-fade);

  svg path {
    fill: none;
    stroke-dasharray: 60 60;
    stroke-width: 6;
    transition: ${transition('stroke-dasharray')}, ${transition('stroke-dashoffset')};

    .x-dark-theme & {
      stroke: var(--white);
    }

    .x-light-theme & {
      stroke: #a8a8a8;
    }
  }

  &:hover {
    opacity: 0.75;
  }
`;

const SVG = styled.svg`
  .top,
  .bottom {
    stroke-dasharray: 60 207;
  }

  &.x-expanded {
    .top,
    .bottom {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
    }

    .middle {
      stroke-dasharray: 1 60;
      stroke-dashoffset: -30;
    }
  }
`;

export const Desktop = (props: React.ComponentPropsWithoutRef<'svg'>): React.ReactElement => (
  <SVG {...props}>
    <path
      className='top'
      d='M 20,29 H 80 C 80,29 95,29 95,67 95,78 91,82 85,82 80,82 75,75 75,75 L25,25'
    />
    <path className='middle' d='M 20,50 H 80' />
    <path
      className='bottom'
      d='M 20,71 H 80 C 80,71 94.5,71.2 94.5,33.3 94.5,22 91,18.33 85.25,18.33 79.55,18.33 75,25 75,25 L25,75'
    />
  </SVG>
);

export const Header = (): React.ReactElement => {
  const { sidebar } = useContext(ScaffoldContext);
  const me = Store.User.useMe();

  return (
    <Wrapper>
      <Button
        className='x-pseudo mr-12px'
        type='button'
        aria-expanded={sidebar.isExpanded}
        aria-label='Toggle Main Menu'
        onClick={sidebar.toggle}
      >
        {React.createElement(Desktop, {
          className: cx(sidebar.isExpanded && 'x-expanded'),
          width: 32,
          height: 32,
          viewBox: '0 0 100 100'
        })}
      </Button>
      <label className='name text-nowrap transition-fade'>
        Hello
        <span>{` ${me.firstName}`} </span>
      </label>
      <div className='menu'>
        <span className='text-nowrap transition-fade'>MENU</span>
      </div>
    </Wrapper>
  );
};
