import type { Asset } from '@livecontrol/scheduler/model';
import type { FormikConfig } from 'formik';
import _ from 'lodash';
import nextTick from 'next-tick';
import { useRef, useState } from 'react';
import { Alert } from '../../../../store';

export interface Props {
  asset: Asset;
  onClose: () => void;
}

interface Values {
  url: string;
}

interface Form {
  formik: FormikConfig<Values>;
  loading: boolean;
  alert: Alert;
}

export const useForm = ({ asset, onClose }: Props): Form => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  const alert = Alert.useAlert(error);

  const initialValues = useRef({
    url: asset.mux!.downloadUrl!
  }).current;

  const formik = {
    initialValues,

    validate(): void {
      alert.hide();
    },

    onSubmit(data: Values): void {
      alert.hide();

      // If not loading, execute the mutation
      if (!loading) {
        setLoading(true);

        try {
          const fileName = _.kebabCase(asset.title).toLocaleLowerCase();

          const a = document.createElement('a');

          a.download = fileName;
          a.href = `${data.url}?download=${fileName}.mp4`;
          a.target = '_blank';

          document.body.append(a);
          a.click();
          a.remove();

          nextTick(() => {
            setLoading(false);
            onClose();
          });
        } catch {
          setError(new Error('Unable to download video.  Please try again later.'));
          setLoading(false);
        }
      }
    }
  };

  return {
    formik,
    loading,
    alert
  };
};
