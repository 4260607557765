import type { Account, Location } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { ContactRole } from '@livecontrol/scheduler/store';
import type { FormikContextType } from 'formik';
import { useFormik } from 'formik';
import { useRef, useState } from 'react';
import { Alert } from '../../../../store';

export interface Values {
  contactRole: string;
  [index: string]: Record<string, boolean> | boolean | number | string | undefined;
}

interface Form {
  formik: FormikContextType<Values>;
  loading: boolean;
  contactRoleResponse: ContactRole[] | undefined;
}

export interface Props {
  account: Account;
  subUser?: Account.SubUser;
  locations?: Location[];
  errors: number[];
  handleSubmitSuccess: () => void;
}

export interface input {
  user_id: number;
  location_id?: string;
  value: boolean;
  priority?: number;
}

export const useForm = ({ locations, errors, handleSubmitSuccess }: Props): Form => {
  const [mutation, { loading, error }] = Scheduler.Account.UserManagement.useEditContactRole();
  const alert = Alert.useAlert(error);

  const initialValues: Values = useRef({
    contactRole: 'onboarding_main'
  }).current;

  const [contactRoleResponse, setContactRoleResponse] = useState<ContactRole[] | undefined>([]);

  const formik = useFormik<Values>({
    initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,

    validate(): void {
      alert.hide();
    },

    async onSubmit(data: Values): Promise<void> {
      alert.hide();

      if (errors.length > 0) {
        return;
      }

      const inputContactRole: input[] = [];

      locations?.forEach((location) => {
        const haveLocation = data[location.id];

        if (haveLocation) {
          if (data.contactRole === 'onboarding_technical' || data.contactRole === 'shipping') {
            const users = Object.getOwnPropertyNames(haveLocation);

            users.forEach((user) => {
              const inputBody = {
                location_id: location.id,
                user_id: Number(user.replace('_', '')),
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                value: haveLocation[user as keyof typeof haveLocation] as unknown as boolean
              };

              inputContactRole.push(inputBody);
            });
          } else if (data.contactRole === 'onsite_technical') {
            const users = Object.getOwnPropertyNames(haveLocation);

            users.forEach((user) => {
              if (user !== 'contactRole') {
                const inputBody = {
                  location_id: location.id,
                  user_id: Number(user.replace('_', '')),
                  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                  priority: (
                    haveLocation[user as keyof typeof haveLocation] as unknown as {
                      priority: number;
                    }
                  ).priority,
                  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                  value: (
                    haveLocation[user as keyof typeof haveLocation] as unknown as { value: boolean }
                  ).value
                };

                inputContactRole.push(inputBody);
              }
            });
          }
        }
      });

      if (data.contactRole === 'onboarding_main' || data.contactRole === 'primary_billing') {
        const users = Object.getOwnPropertyNames(data);

        users.forEach((user) => {
          if (user !== 'contactRole') {
            const inputBody = {
              user_id: Number(user.replace('_', '')),
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              value: data[user as keyof typeof data] as unknown as boolean
            };

            inputContactRole.push(inputBody);
          }
        });
      }

      const payload = {
        contactRole: data.contactRole,
        input: inputContactRole
      };

      if (!loading) {
        const contactRole = await mutation(payload);

        handleSubmitSuccess();
        setContactRoleResponse(contactRole);
      }
    }
  });

  return {
    contactRoleResponse,
    formik,
    loading
  };
};
