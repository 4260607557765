import type { ApolloError, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Obj, Str } from '@livecontrol/core-utils';
import { User } from '@livecontrol/scheduler/model';
import { Errors } from '@livecontrol/scheduler/store';
import type { MutationResult } from '@livecontrol/scheduler/store';
import { useCallback, useState } from 'react';
import { Token } from '../../model';

interface TVariables {
  confirmation: string;
  email: string;
  first: string;
  industry: number;
  last: string;
  organization: string;
  password: string;
  phone: string;
  address: string;
  place_id: string;
}

interface TData {
  createUser?: {
    token: string;
  };
}

type Args = TVariables;

const MUTATION = gql`
  mutation SignUp(
    $confirmation: String!
    $email: String!
    $first: String!
    $industry: Float!
    $last: String!
    $organization: String!
    $password: String!
    $phone: String!
    $address: String!
    $place_id: String!
  ) {
    createUser(
      input: {
        email: $email
        first_name: $first
        industry: $industry
        last_name: $last
        organization_name: $organization
        password: $password
        passwordConfirmation: $confirmation
        phone_number: $phone
        role: ${User.Role.Client}
        address: $address
        place_id: $place_id
      }
    ) {
      token
    }
  }
`;

export const useSignUp = (): [
  (args: Args) => Promise<Token | undefined>,
  MutationResult<Token, 'token'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [token, setToken] = useState<Token | undefined>();

  return [
    useCallback(
      async (args: Args): Promise<Token | undefined> => {
        let token_: Token | undefined;

        try {
          // Parse the input arguments
          const variables = {
            confirmation: Str.normalize(args.confirmation),
            email: Str.normalize(args.email),
            first: Str.normalize(args.first),
            industry: User.Industry.normalize(args.industry),
            last: Str.normalize(args.last),
            organization: Str.normalize(args.organization),
            password: Str.normalize(args.password),
            phone: Str.normalize(args.phone),
            address: Str.normalize(args.address),
            place_id: Str.normalize(args.place_id)
          };

          // Validate the input
          if (!Obj.isHydrated(variables)) {
            throw Errors.badRequest();
          }

          // Execute the GraphQL mutation
          const response = await mutation({ variables })
            .then(({ data }: FetchResult<TData>) => data?.createUser)
            .catch(({ networkError, graphQLErrors }: ApolloError) => {
              // Was this an unrecoverable Network error?
              if (networkError || !graphQLErrors.length) {
                throw Errors.serverError();
              }

              // Make an assumption as to why we got a GraphQL error.
              throw graphQLErrors[0];
            });

          // Parse the server response
          token_ = Token.decode(response?.token);

          if (!token_) {
            throw Errors.serverError();
          }
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setToken(token_);

        return token_;
      },
      [mutation, setError, setToken]
    ),
    {
      token,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
