import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Obj } from '@livecontrol/core-utils';
import { User } from '@livecontrol/scheduler/model';
import type { QueryResult } from '@livecontrol/scheduler/store';
import { useMemo, useState } from 'react';
import * as Dbx from './dbx';

interface TVariables {
  userId: number;
}

interface TData {
  getUserEmailNotifications?: Dbx.Record;
}

export const QUERY = gql`
  query GetEmailNotifications($userId: Float!) {
    getUserEmailNotifications(userId: $userId) {
      id
      user {
        id
        email
      }
      customer_feedback
      event_scheduling
      event_reminder
      connection_issues
    }
  }
`;

export const usePreferences = (
  args: User.Like
): QueryResult<Dbx.NotificationPreferences, 'preferences'> => {
  const [error, setError] = useState<Error | undefined>();

  const variables = {
    userId: User.toId(args)
  };

  if (!Obj.isHydrated(variables)) {
    return { loading: false, error: Exception.KABOOM };
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  const { loading, data } = useQuery<TData, TVariables>(QUERY, {
    variables,
    onError() {
      setError(Exception.KABOOM);
    }
  });

  const preferences = useMemo(() => {
    const result = data?.getUserEmailNotifications;

    if (result) {
      return Dbx.normalize(result);
    }

    return undefined;
  }, [data]);

  return { loading, error, preferences };
};
