import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import type { Event } from '../store';

export const useEdit = (): ((id: Event.ID) => void) => {
  const history = useHistory();

  return useCallback(
    (id: Event.ID): void => {
      history.push(`/schedule/edit/${id}`);
    },
    [history]
  );
};
