import { Device, Loading, Theme } from '@livecontrol/core-ui';
import { Obj } from '@livecontrol/core-utils';
// import type { SubUser } from '@livecontrol/scheduler/model';
import { Log } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SectionContainer } from '../../../components';
import { Store } from '../../../store';
import { Action, User } from './actions';
import { Logs } from './log-list';
// eslint-disable-next-line import/no-internal-modules
import { NoMatch } from './log-list/no-match';
import { None } from './none';

export interface M_Action {
  actions: { key: string; label: string }[];
  selected?: { key: string; label: string };
  update: (value?: { key: string; label: string }) => void;
}

export interface M_User {
  users: unknown[];
  selected?: { id: number; fullName: string };
  update: (value?: { id: number; fullName: string }) => void;
}

const TitleStyled = styled.div`
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid rgba(213, 218, 234, 0.8);
  padding-bottom: 30px;
  width: 100%;

  .actions {
    display: grid;
    grid-auto-flow: column;
    gap: 0.5rem !important;
  }
`;

const filterLogsByLocation = (logs: Log[], locations: string[]): Log[] =>
  logs.filter((log) => locations.includes(log.location_id ?? '') || log.location_id === '');

export const ActivityLog = (): ReactElement => {
  const account = Store.Account.useAccount();
  const { logs, loading } = Scheduler.Log.useLogs(account);
  const [selectedAction, setSelectedAction] = useState<{ key: string; label: string }>();
  const [selectedUser, setSelectedUser] = useState<{ id: number; fullName: string }>();
  const viewport = Theme.useViewport();
  const size = Device.isMobile(viewport) ? undefined : 'lg';

  const { keys } = Log.ActionType;

  const me = Store.User.useMe();

  const { locations } = Scheduler.Location.useLocations(account);

  const locationIds: string[] = locations ? locations.map((location) => location.id) : [];

  const users_ = [{ id: account.id, fullName: account.accountOwnerName }, ...account.subUsers];

  let availableLogs = logs;

  if (logs && !me.permissions.admin) {
    availableLogs = filterLogsByLocation(logs, locationIds);
  }

  const [filteredLogs, setFilteredLogs] = useState<Log[] | undefined>(availableLogs);

  const actions = Obj.keys(keys).map((key) => ({
    key,
    label: keys[key]
  }));

  const filterLogByAction = (logs_?: Log[], selected?: { label: string; key: string }): Log[] => {
    const filteredLogs_ = logs_?.filter((log) => log.action === selected?.label);

    return filteredLogs_ ? filteredLogs_ : [];
  };

  const filterLogByUser = (logs_?: Log[], selected?: { id: number; fullName: string }): Log[] => {
    const filteredLogs_ = logs_?.filter((log) => log.performedBy === selected?.fullName);

    return filteredLogs_ ? filteredLogs_ : [];
  };

  const onReset = (): void => {
    setFilteredLogs(logs);
    setSelectedAction(undefined);
    setSelectedUser(undefined);
  };

  useEffect(() => {
    let filteredLogs_: Log[] | undefined = availableLogs;

    if (selectedAction) {
      filteredLogs_ = filterLogByAction(filteredLogs_, selectedAction);
    }

    if (selectedUser) {
      filteredLogs_ = filterLogByUser(filteredLogs_, selectedUser);
    }

    setFilteredLogs(filteredLogs_);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser, selectedAction, logs]);

  const onUpdateAction = (value?: { key: string; label: string }): void => {
    const selected_ = actions.find(({ key }) => key === value?.key);

    setSelectedAction(selected_);
  };

  const onUpdateUser = (value?: { id: number; fullName: string }): void => {
    const selected_ = users_.find(({ id }) => id === value?.id);

    setSelectedUser(selected_);
  };

  const actionTypes: M_Action = {
    actions,
    selected: selectedAction,
    update: onUpdateAction
  };

  const users: M_User = {
    users: users_,
    selected: selectedUser,
    update: onUpdateUser
  };

  return loading ? (
    <Loading.Delay />
  ) : availableLogs ? (
    <SectionContainer className='col-12'>
      <div className='d-flex justify-content-between align-items-center mb-28px'>
        <TitleStyled>
          <h1 className='m-0'>Activity Log</h1>
          {availableLogs.length > 0 && (
            <div className='actions'>
              <Action actions={actionTypes} size={size} />
              <User users={users} size={size} />
            </div>
          )}
        </TitleStyled>
      </div>

      {availableLogs.length === 0 && (
        <div className='text-center py-64px'>
          <div className='font-size-22px'>You have no activity logs</div>
        </div>
      )}

      {availableLogs.length > 0 && filteredLogs && (
        <>
          <Logs logs={filteredLogs} />
          {filteredLogs.length === 0 && (selectedUser || selectedAction) && (
            <NoMatch className='pt-4px' onReset={onReset} />
          )}
        </>
      )}
    </SectionContainer>
  ) : (
    <None />
  );
};
