import type { ApolloQueryResult} from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import type { Account } from '@livecontrol/scheduler/model';
import { useEffect, useState } from 'react';
import type { QueryResult } from '../graphql';
import type * as Dbx from './dbx';

interface TData {
    getLoginCode?: Dbx.Record;
}

interface TVariable {
    clientId?: number;
}

const QUERY = gql`
    query GetLoginCode($clientId: Float) {
        getLoginCode(clientId: $clientId) {
        valid_until
        id
        date_created
        code
    }
}
`;

interface Link {
    code?: string;
}

interface Refetch {
    refetch?: () => Promise<ApolloQueryResult<TData>>;
}

export const useLink = (clientId?: Account.Like): QueryResult<Link, 'link'> & Refetch => {
    const [error, setError] = useState<Error | undefined>();
    const [link, setLink] = useState<Link | undefined>();

    const { data, refetch, loading } = useQuery<TData, TVariable>(QUERY, {
        variables: {
            clientId: clientId ? Number(clientId) : undefined
        }
    });

    useEffect(() => {
        let error_: Error | undefined;

        if (data?.getLoginCode) {
            setLink(data.getLoginCode);
        } else {
            error_ = Exception.NOT_FOUND;
        }

        setError(error_);
    }, [data]);

    return {
        loading,
        error,
        refetch,
        link
    };
};
