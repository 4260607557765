import { Component } from 'react';
import type { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import B_Accordion from 'react-bootstrap/Accordion';
import styled from 'styled-components';
import { Header as Header_ } from './header';
import { Toggle as Toggle_ } from './toggle';

const Wrapper = styled(B_Accordion)`
  font-weight: var(--font-weight-light);

  .card {
    margin-bottom: var(--spacer-16px);
    border: 1px solid var(--tint-ø2) !important;
    border-radius: var(--border-radius-sm) !important;
  }

  .card-header {
    background-color: var(--white);
    display: flex;
    min-height: 4.5rem;

    &.x-selected {
      background-color: var(--tint-ø2);
    }

    > div {
      width: 100%;
    }
  }
`;

// eslint-disable-next-line react/prefer-stateless-function
export class Collapsible extends Component<Collapsible.Props> {
  public override render(): ReactNode {
    const { children, ...props } = this.props;

    return <Wrapper {...props}>{children}</Wrapper>;
  }
}

export namespace Collapsible {
  export type Props = PropsWithChildren<HTMLAttributes<HTMLElement>>;

  export const { Collapse } = B_Accordion;

  /* eslint-disable @typescript-eslint/no-unused-vars */
  export import Header = Header_;
  export import Toggle = Toggle_;
}
