/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Form } from '@livecontrol/core-ui';
import { Matrix } from '@livecontrol/scheduler/components';
import type { FormikContextType } from 'formik';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import type { Values } from '../permissions-user';

import G = Matrix.Grid;

const Cell = styled(Matrix.Grid.Row.Cell)`
  border-color: #d5daea;
  justify-content: center;
  padding: 14px 0px;

  &:first-child {
    border-top-left-radius: var(--spacer-4px);
    border-bottom-left-radius: var(--spacer-4px);
  }
  &:last-child {
    border-top-right-radius: var(--spacer-4px);
    border-bottom-right-radius: var(--spacer-4px);
    padding: 14px 0px;
  }
`;

const CellNoBorder = styled(Matrix.Grid.Row.Cell)`
  border: 0px solid transparent;
  justify-content: center;
  padding: 14px 0px;

  &.transparent {
    background-color: transparent !important;
  }

  &.boder-bottom {
    border-bottom: 1px solid #e4e8f0 !important;
  }

  &:last-child {
    padding: 5px 0px !important;
  }

  &:first-child {
    border-left: 0px solid transparent !important;
    position: sticky;
    left: 0;
  }
`;

const Row = styled(Matrix.Grid.Row)`
  position: relative;

  .users {
    position: sticky;
    left: 0;
  }

  img {
    cursor: not-allowed;
  }

  button {
    visibility: hidden;
    display: flex !important;
  }

  &.hover {
    .column {
      border-color: #2e5bff !important;
    }

    button {
      visibility: visible !important;
      display: flex !important;
    }
  }
`;

interface Props {
  name: string;
  id: number | string;
  formik: FormikContextType<Values>;
}

export const TableRow = ({ name, id, formik }: Props): ReactElement => {
  const [rowSelected, setRowSelected] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const selectRow = (active: boolean): void => {
    setRowSelected(active);
    formik.setTouched({touched: true});

    formik.setFieldValue(`${id}.eventManagment`, active);
    formik.setFieldValue(`${id}.viewAndDownload`, active);
    formik.setFieldValue(`${id}.contentManagment`, active);
    formik.setFieldValue(`${id}.productionNotes`, active);
  };

  useEffect(() => {
    const { values } = formik;
    const valuesPermissions = values[`${id}` as keyof typeof values];

    if (valuesPermissions) {
      if (valuesPermissions['eventManagment' as keyof typeof valuesPermissions]) {
        setRowSelected(true);
      }

      if (valuesPermissions['viewAndDownload' as keyof typeof valuesPermissions]) {
        setRowSelected(true);
      }

      if (valuesPermissions['contentManagment' as keyof typeof valuesPermissions]) {
        formik.setFieldValue(`${id}.viewAndDownload`, true);
        setRowSelected(true);
      }

      if (valuesPermissions['productionNotes' as keyof typeof valuesPermissions]) {
        setRowSelected(true);
      }

      if (
        !valuesPermissions['eventManagment' as keyof typeof valuesPermissions] &&
        !valuesPermissions['viewAndDownload' as keyof typeof valuesPermissions] &&
        !valuesPermissions['contentManagment' as keyof typeof valuesPermissions] &&
        !valuesPermissions['productionNotes' as keyof typeof valuesPermissions]
      ) {
        setRowSelected(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  return (
    <>
      <G.Row>
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
      </G.Row>
      <Row className={isHovered ? 'hover' : ''}>
        <Cell
          className='column users justify-content-start'
          onMouseEnter={(): void => {
            setIsHovered(true);
          }}
          onMouseLeave={(): void => {
            setIsHovered(false);
          }}
        >
          <div className='d-flex flex-column pr-4px'>
            <div className='text-dark font-weight-bold font-size-12px'>{name}</div>
            {!rowSelected ? (
              <button
                onClick={(): void => {
                  selectRow(true);
                }}
                type='button'
                className='btn p-0 bg-transparent text-primary font-weight-bold font-size-12px'
              >
                Select All
              </button>
            ) : (
              <button
                onClick={(): void => {
                  selectRow(false);
                }}
                type='button'
                className='btn p-0 bg-transparent text-primary font-weight-bold font-size-12px'
              >
                Deselect All
              </button>
            )}
          </div>
        </Cell>
        <Cell className='column'>
          <Form.Group
            name={`${id}.eventManagment`}
            label={false}
            className='mb-0'
            controlProps={{
              type: 'checkbox'
            }}
          />
        </Cell>
        <Cell className='column'>
          <Form.Group
            name={`${id}.viewAndDownload`}
            label={false}
            className='mb-0'
            controlProps={{
              type: 'checkbox'
            }}
          />
        </Cell>
        <Cell className='column'>
          <Form.Group
            name={`${id}.contentManagment`}
            label={false}
            className='mb-0'
            controlProps={{
              type: 'checkbox'
            }}
          />
        </Cell>
        <Cell className='column'>
          <Form.Group
            name={`${id}.productionNotes`}
            label={false}
            className='mb-0'
            controlProps={{
              type: 'checkbox'
            }}
          />
        </Cell>
      </Row>
    </>
  );
};
