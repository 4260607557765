import type { ApolloCache, ApolloError, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Obj, Str } from '@livecontrol/core-utils';
import { Account, Asset } from '@livecontrol/scheduler/model';
import { useCallback, useState } from 'react';
import type { MutationResult } from '../graphql';
import { Errors } from '../graphql';
import * as Dbx from './dbx';

interface TVariables {
  asset: number;
  account: number;
}

interface TData {
  deleteAsset?: {
    error: boolean;
  };
}

const MUTATION = gql`
  mutation DeleteAsset($asset: Int!, $account: Int!) {
    deleteAsset(assetId: $asset, clientId: $account) {
      error
    }
  }
`;

interface Args {
  asset: Asset.Like;
  account: Account.Like;
}

export const useDelete = (): [
  (args: Args) => Promise<boolean>,
  MutationResult<boolean, 'success'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [success, setSuccess] = useState<boolean>();

  return [
    useCallback(
      async (args: Args): Promise<boolean> => {
        let success_ = false;

        try {
          // Parse the input arguments
          const variables = {
            asset: Asset.toId(args.asset),
            account: Account.toId(args.account)
          };

          if (!Obj.isHydrated(variables)) {
            throw Errors.badRequest();
          }

          // Execute the GraphQL mutation
          const response = await mutation({
            variables,
            update(cache: ApolloCache<unknown>, { data }: FetchResult<TData>): void {
              if (data?.deleteAsset?.error === false) {
                const identity = cache.identify({
                  id: variables.asset,
                  __typename: Dbx.__typename
                });

                if (!cache.evict({ id: identity })) {
                  // eslint-disable-next-line no-console
                  console.error(`Unable to evict asset: ${identity ?? Str.NULL}`);
                }
              }
            }
          })
            .then(({ data }: FetchResult<TData>) => data?.deleteAsset)
            .catch((_error: ApolloError) => {
              throw Errors.serverError();
            });

          success_ = response?.error === false;
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setSuccess(success_);

        return success_;
      },
      [mutation, setError, setSuccess]
    ),
    {
      success,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
