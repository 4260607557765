/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql } from '@apollo/client';
import { Obj, Str, Time } from '@livecontrol/core-utils';
import type { Log } from '@livecontrol/scheduler/model';

export type Record = Partial<{
  performed_by: string;
  message: string;
  date: any;
  action: string;
  credits_used?: number;
  current_balance?: number;
  location_id?: string;
}>;

export const normalize = (record?: Record): Log | undefined => {
  let log;

  const candidate = {
    performedBy: Str.normalize(record?.performed_by),
    message: Str.normalize(record?.message),
    date: Time.normalize(record?.date),
    action: Str.normalize(record?.action),
    location_id: Str.normalize(record?.location_id) ?? ''
  };

  if (Obj.isHydrated(candidate)) {
    log = {
      ...candidate,
      credits_used: record?.credits_used ?? 0,
      current_balance: record?.current_balance
    };
  }


  return log;
};

export const StandardLogResponse = gql`
  fragment StandardLogResponse on GetCustomerLogResposne {
    success
    error
    result {
      action
      performed_by
      message
      date
      credits_used
      current_balance
      location_id
    }
  }
`;

export const __typename = 'GetCustomerLogResposne';
