import React from 'react';
import Card from 'react-bootstrap/Card';
import { Section } from './common';

const swatches = [
  'black',
  'dark',
  'secondary',
  'tertiary',
  'light',
  'primary',
  'tint-ø1',
  'tint-ø2',
  'tint-ø3',
  'success',
  'danger',
  'white'
];

export const Palette = (): React.ReactElement => (
  <Section name='Palette'>
    <div
      className='d-grid gap-16px'
      style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))' }}
    >
      {swatches.map(
        (swatch: string): React.ReactElement => (
          <Card key={swatch}>
            <Card.Header className='text-center h2'>{swatch}</Card.Header>
            <Card.Body className='p-0'>
              <div style={{ height: 150, backgroundColor: `var(--${swatch})` }} />
            </Card.Body>
          </Card>
        )
      )}
    </div>
  </Section>
);
