import { gql } from '@apollo/client';

export const GetUserCreditsInfo = gql`
  query GetUserCreditsInfo($user: Float!) {
    getCredits(search: { user: $user }) {
      id
      recurrence
      recurrence_amt
      price
      current_amount
      user {
        id
        lead_time
      }
    }
  }
`;

export const UpdateCredits = gql`
  mutation UpdateCredits(
    $user: Float!
    $price: Float!
    $recurrence: String!
    $current_amount: Float!
    $recurrence_amt: Float!
  ) {
    assignCredit(
      credit: {
        user: $user
        recurrence: $recurrence
        recurrence_amt: $recurrence_amt
        price: $price
        current_amount: $current_amount
      }
    ) {
      id
    }
  }
`;

export const BuyCredits = gql`
  mutation BuyCredits($user: Float!, $amt_credit: Float!) {
    buyCredit(buyCredit: { userId: $user, amt_credit: $amt_credit })
  }
`;

export const UpdateLeadTime = gql`
  mutation UpdateUser($user: ID!, $lead_time: Float) {
    updateUser(input: { id: $user, lead_time: $lead_time }) {
      id
      lead_time
    }
  }
`;
