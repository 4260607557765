import { Form } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CustomButtonForm } from './custom-button-form';
import { DONATION_LABELS, DonationLabels } from './donation-labels';
import { LinkForm } from './link-form';
import { useUpdateDonation } from './use-update-donation';

const ModalHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .title {
    font-weight: 600;
    font-size: 20px;
    color: #000000;
    margin-bottom: 8px;
  }

  .text {
    width: 100%;
    text-align: center;
    font-size: 13px;
    color: #9c9c9c;
    font-weight: 600;
  }
`;

const ModalInputsSection = styled.div`
  width: 100%;
  margin-top: 14px;

  .form-group {
    margin-bottom: 0px;
  }

  input {
    background-color: white;
    color: #2e384d;
    border-radius: 5px;
    border: solid 1px #cbcbcb;

    &::placeholder {
      color: #a3a3a3;
      opacity: 1;
    }

    &::-ms-input-placeholder {
      color: #a3a3a3;
    }
  }
`;

const ModalFooterContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

  .cancel-link {
    min-width: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 18px;
    color: #8798ad;
    font-size: 13px;
    font-weight: 700;
    background-color: #ffffff;
    border: none;
    margin: 6px 10px;
    box-shadow: none;
  }

  .submit-btn {
    min-width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 18px;
    margin: 6px 10px;
  }
`;

export const Create = ({
  show,
  onHide,
  currentLabel,
  currentLink
}: {
  show: boolean;
  onHide?: () => void;
  currentLabel?: string;
  currentLink?: string;
}): React.ReactElement => {
  const selectedLabel = currentLabel?.length ? currentLabel : DONATION_LABELS.GIVE;

  const [label, setLabel] = useState<string>(selectedLabel);
  const [updateDonation, { loading }] = useUpdateDonation(false);

  const onSave = useCallback(
    (link: string, title?: string) => {
      if (label !== DONATION_LABELS.DONATE && label !== DONATION_LABELS.GIVE && title?.length) {
        void updateDonation({ label: title, link }, true).then(() => {
          onHide?.();
        });
      } else {
        void updateDonation({ label, link }, false).then(() => {
          onHide?.();
        });
      }
    },
    [label, onHide, updateDonation]
  );

  const [showCustomName, setShowCustomName] = useState<boolean>(
    label !== 'Give' && label !== 'Donate'
  );

  useEffect(() => {
    if (label !== 'Give' && label !== 'Donate') {
      setShowCustomName(true);
    } else {
      setShowCustomName(false);
    }
  }, [label]);

  return (
    <Modal show={show} backdrop='static' keyboard={false} onHide={onHide} className='bigger-modal'>
      <Modal.Header closeButton>
        <ModalHeaderContent>
          <div className='title'>Custom Button</div>
          <div className='text'>
            Show a donate or custom button on your Web Player & Profile Page
          </div>
        </ModalHeaderContent>
      </Modal.Header>

      <div>
        <DonationLabels label={label} onChange={setLabel} />

        {showCustomName ? (
          <CustomButtonForm
            onSubmit={onSave}
            currentLink={currentLink}
            currentCustomTitle={currentLabel}
          >
            <Modal.Body>
              <ModalInputsSection>
                <Form.Group
                  name='title'
                  label={false}
                  className='custom-form-group mb-16px'
                  controlProps={{ placeholder: 'Button Text', required: true }}
                />
                <Form.Group
                  name='link'
                  label={false}
                  className='custom-form-group'
                  controlProps={{ placeholder: 'URL' }}
                />
              </ModalInputsSection>
            </Modal.Body>
            <Modal.Footer>
              <Modal.ButtonBox>
                <ModalFooterContent>
                  <Form.Submit className='submit-btn' loading={loading} type='submit'>
                    Save Changes
                  </Form.Submit>
                  <Modal.Button className='cancel-link' onClick={onHide}>
                    Cancel
                  </Modal.Button>
                </ModalFooterContent>
              </Modal.ButtonBox>
            </Modal.Footer>
          </CustomButtonForm>
        ) : (
          <LinkForm onSubmit={onSave} currentLink={currentLink}>
            <Modal.Body>
              <ModalInputsSection>
                <Form.Group
                  name='link'
                  label={false}
                  className='custom-form-group'
                  controlProps={{ placeholder: 'URL' }}
                />
              </ModalInputsSection>
            </Modal.Body>
            <Modal.Footer>
              <Modal.ButtonBox>
                <ModalFooterContent>
                  <Form.Submit className='submit-btn' loading={loading} type='submit'>
                    Save Changes
                  </Form.Submit>
                  <Modal.Button className='cancel-link' onClick={onHide}>
                    Cancel
                  </Modal.Button>
                </ModalFooterContent>
              </Modal.ButtonBox>
            </Modal.Footer>
          </LinkForm>
        )}
      </div>
    </Modal>
  );
};
