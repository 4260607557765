import { Loading } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import type { ReactElement } from 'react';
import styled from 'styled-components';

const Icon = styled.img`
  height: 40px;
  width: 40px;
  display: inline-grid;
  place-items: center;
  margin-botton: 16px;
`;

export const CreditsProcessing = (): ReactElement => (
  <>
    <Modal.Header closeButton={false}>
      <Icon src='/assets/icons/dollar-coin.svg' alt='dollar coin' />
      <Modal.Title className='mb-8px mt-8px font-size-20px font-weight-bold'>
        Processing Your Transaction
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Loading size='50' />
    </Modal.Body>
    <Modal.Footer>
      <div className='text-center font-size-15px line-height-lg' style={{ color: '#9C9C9C' }}>
        Please wait while we process your transaction. This might take several minutes, so please do
        not hit the back button or refresh the page.
      </div>
    </Modal.Footer>
  </>
);
