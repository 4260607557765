import { Form } from '@livecontrol/core-ui';
import type { User } from '@livecontrol/scheduler/model';
import { EventValidator } from '@livecontrol/scheduler/validator';
import { useContext } from 'react';
import styled from 'styled-components';
import { EventContextStore } from '../event-store';
import type { EventContext } from '../event-store';
import { ContentSection, CustomInputContainer, HeaderSection } from '../layout-sections';

const CustomTitle = styled.div`
  display: flex;
  .new-feature-tag {
    margin-left: 8px;
    width: 33px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff505f;
    border-radius: 20px;
    color: #ffffff;
    font-size: 9px;
  }
`;

const CustomDescription = styled.div`
  .link-to-article {
    display: inline-block;
    margin-top: 1px;

    .icon {
      margin-left: 2px;
      width: 12px;
      height: 12px;
      background-image: url(/assets/icons/Info-article-blue.svg);
      background-size: cover;
      background-position: center center;
    }
  }
`;

const StyledInputSection = styled.div`
  width: 100%;
  .form-group {
    max-width: 440px;
    label {
      font-size: 15px;
      font-weight: 600;
      color: #2e384d;
    }
    input::placeholder,
    textarea::placeholder {
      font-style: normal;
    }
  }
`;

export const WebplayerFeatures = ({
  accountSettings
}: {
  accountSettings: User.admin;
}): React.ReactElement => {
  const { store }: EventContext = useContext<EventContext>(EventContextStore);

  const showPaswordField = accountSettings.userGlobalSettings.passwordProtect;
  const showRegistrationField = accountSettings.userGlobalSettings.webplayerRegistration;

  const showRequireRegistrationField =
    accountSettings.userGlobalSettings.webplayerRegistrationRequired;

  const showShowViewersField = accountSettings.userGlobalSettings.webplayerShowViewers;

  return (
    <>
      <HeaderSection className='mb-40px'>
        <div className='divider' />
      </HeaderSection>

      <ContentSection style={{ maxWidth: '870px', marginTop: '50px' }}>
        <CustomInputContainer>
          <Form.Descriptor
            title='Web Player Features'
            titleClassName='title mr-lg-3 font-size-15px font-weight-bold text-dark mt-24px'
            description='Add features to your event.'
            className='form-section-lateral d-lg-flex'
            descriptionClassName='font-size-13px text-secondary font-weight-bold text-gray-light mb-8px'
          >
            <div className='d-flex flex-column w-100'>
              {showPaswordField && (
                <Form.SwitchCustomField
                  abledDescription={false}
                  className='mt-24px'
                  title='Password'
                  titleClassName='title text-dark mb-4px mr-lg-3 font-size-15px font-weight-bold'
                  description='Enable password protection to restrict access to your event.'
                  descriptionClassName='font-size-13px text-secondary font-weight-bold text-gray-light mb-8px'
                  name='passwordSettings.enabled'
                  noSaveIndicator={false}
                />
              )}

              {store.eventInfo.passwordSettings?.enabled && (
                <StyledInputSection>
                  <Form.Group
                    name='passwordSettings.password'
                    label={false}
                    controlProps={{
                      type: 'text',
                      placeholder: 'Password',
                      className: 'mr-8px'
                    }}
                  />
                </StyledInputSection>
              )}

              <Form.SwitchCustomField
                abledDescription={false}
                className='mt-24px'
                title='Live Chat'
                titleClassName='title text-dark mb-4px mr-lg-3 font-size-15px font-weight-bold'
                description='Enables the live chat to show next to each event.'
                descriptionClassName='font-size-13px text-secondary font-weight-bold text-gray-light mb-8px'
                name='chatSettings.liveChat'
                noSaveIndicator={false}
              />

              {(store.eventType === EventValidator.ProductionType.PRODUCED ||
                store.eventType === EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT ||
                store.eventType === EventValidator.ProductionType.MOBILE_KIT) &&
                showRegistrationField && (
                  <Form.SwitchCustomField
                    abledDescription={false}
                    className='mt-24px'
                    title={
                      <CustomTitle>
                        Event Registration <div className='new-feature-tag'>New</div>
                      </CustomTitle>
                    }
                    titleClassName='title text-dark mb-4px mr-lg-3 font-size-15px font-weight-bold'
                    description={
                      <CustomDescription>
                        Collect names and emails from your viewers.
                        <a
                          className='link-to-article'
                          href='https://mylivecontrol.zendesk.com/hc/en-us/articles/16599228359191-Produced-Event-Registration'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <div className='icon' />
                        </a>
                      </CustomDescription>
                    }
                    descriptionClassName='font-size-13px text-secondary font-weight-bold text-gray-light mb-8px'
                    name='chatSettings.registration'
                  />
                )}

              {(store.eventInfo.chatSettings?.registration ||
                store.eventInfo.chatSettings?.liveChat) &&
                showShowViewersField && (
                  <Form.SwitchCustomField
                    abledDescription={false}
                    className='mt-24px'
                    titleClassName='title text-dark mb-4px mr-lg-3 font-size-15px font-weight-bold'
                    descriptionClassName='font-size-13px text-secondary font-weight-bold text-gray-light mb-8px'
                    title={
                      <CustomTitle>
                        Show viewers <div className='new-feature-tag'>New</div>
                      </CustomTitle>
                    }
                    description={
                      <CustomDescription>
                        Show the list of viewers.
                        <a
                          className='link-to-article'
                          href='https://mylivecontrol.zendesk.com/hc/en-us/articles/16684687293591'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <div className='icon' />
                        </a>
                      </CustomDescription>
                    }
                    name='chatSettings.showViewers'
                    noSaveIndicator={false}
                  />
                )}

              {(store.eventType === EventValidator.ProductionType.PRODUCED ||
                store.eventType === EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT ||
                store.eventType === EventValidator.ProductionType.MOBILE_KIT) &&
                showRequireRegistrationField &&
                store.eventInfo.chatSettings?.registration && (
                  <Form.SwitchCustomField
                    abledDescription={false}
                    className='mt-24px'
                    title={
                      <CustomTitle>
                        Require Registration <div className='new-feature-tag'>New</div>
                      </CustomTitle>
                    }
                    titleClassName='title text-dark mb-4px mr-lg-3 font-size-15px font-weight-bold'
                    description='Requires viewers to register to watch.'
                    descriptionClassName='font-size-13px text-secondary font-weight-bold text-gray-light mb-8px'
                    name='chatSettings.requireRegistration'
                  />
                )}
            </div>
          </Form.Descriptor>
        </CustomInputContainer>
      </ContentSection>
    </>
  );
};
