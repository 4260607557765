import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Obj } from '@livecontrol/core-utils';
import { Account } from '@livecontrol/scheduler/model';
import { useEffect, useState } from 'react';
import type { QueryResult } from '../../graphql';
import type { Record } from './types';
import * as Utils from './utils';

interface TVariables {
  id: number;
}

interface TData {
  getWebPlayerClientData?: Record;
}

const QUERY = gql`
  query GetWebPlayerClientData($id: Float!) {
    getWebPlayerClientData(id: $id) {
      id
      slug
      name
      about
      theme
      links {
        type
        url
      }
      logo_url
      donate_link
      has_schedule
      has_archive
      has_chat
      has_profile
      custom_placeholder_image
      custom_donate_button_copy
      custom_button_enabled
      custom_button {
        title
        url
      }
      intro_url
      outro_url
    }
  }
`;

export const useView = (args: Account.Like): QueryResult<Account.WebPlayer, 'webPlayer'> => {
  const [error, setError] = useState<Error | undefined>();
  const [webPlayer, setWebPlayer] = useState<Account.WebPlayer | undefined>();

  // Parse the input arguments
  const variables = {
    id: Account.toId(args)
  };

  // Validate the input
  if (!Obj.isHydrated(variables)) {
    return { loading: false, error: Exception.KABOOM };
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  // Prepare the query
  const { loading, data } = useQuery<TData, TVariables>(QUERY, {
    variables,
    onError() {
      setError(Exception.KABOOM);
    }
  });

  // When available, parse server response
  useEffect(() => {
    let webPlayer_;
    let error_;

    if (data) {
      webPlayer_ = Utils.normalize(data.getWebPlayerClientData);

      if (!webPlayer_) {
        error_ = Exception.NOT_FOUND;
      }
    }

    setWebPlayer(webPlayer_);
    setError(error_);
  }, [data]);

  return { loading, error, webPlayer };
};
