import type { Any } from '@livecontrol/core-utils';
import type { Asset, Destination, Event } from '@livecontrol/scheduler/model';
import type { EventValidator } from '@livecontrol/scheduler/validator';
import type { Duration } from 'luxon';
import type React from 'react';
import { createContext } from 'react';

export interface MobileContext {
  store: {
    eventType: EventValidator.ProductionType | undefined;
    eventInfo: EventValidator.EventInfo & { isValid: boolean };
    clientContactDetails: Event.ClientContactDetails | undefined;
    eventDestinations: Destination[];
    zoomDetails: Event.ZoomDetails | undefined;
    clientBookingPaymentMethod: EventValidator.ClientBookingPaymentMethod | undefined;
    simulatedLiveAssetDetails: Asset.MuxUploadedAsset | undefined;
    simulatedLiveFileUpload:
      | (Asset.MuxUpload & { fileName?: string; videoDuration: Duration })
      | undefined;
    simulatedLiveAssetIsUpload: boolean;
    setEventType: React.Dispatch<React.SetStateAction<EventValidator.ProductionType | undefined>>;
    setEventInfo: React.Dispatch<
      React.SetStateAction<EventValidator.EventInfo & { isValid: boolean }>
    >;
    setClientContactDetails: React.Dispatch<
      React.SetStateAction<Event.ClientContactDetails | undefined>
    >;
    setEventDestinations: React.Dispatch<React.SetStateAction<Destination[]>>;
    setZoomDetails: React.Dispatch<React.SetStateAction<Event.ZoomDetails | undefined>>;
    setClientBookingPaymentMethod: React.Dispatch<
      React.SetStateAction<EventValidator.ClientBookingPaymentMethod | undefined>
    >;
    setSimulatedLiveAssetDetails: React.Dispatch<
      React.SetStateAction<Asset.MuxUploadedAsset | undefined>
    >;
    setSimulatedLiveFileUpload: React.Dispatch<
      React.SetStateAction<
        (Asset.MuxUpload & { fileName?: string; videoDuration: Duration }) | undefined
      >
    >;
    setSimulatedLiveAssetIsUpload: React.Dispatch<React.SetStateAction<boolean>>;
  };
  existingEvent?: Event;
}

export const MobileContextStore = createContext<MobileContext>(<Any>undefined);
