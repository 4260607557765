import { Theme, Viewport } from '@livecontrol/core-ui';
import { Str } from '@livecontrol/core-utils';
import type { Location as T_Location } from '@livecontrol/scheduler/model';
import React, { useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import type { DropdownButtonProps } from 'react-bootstrap/DropdownButton';
import styled from 'styled-components';

const FilterDropwdown = styled.div`
  position: relative;
  button.btn-outline-tertiary {
    color: #0d1438;
    border-color: #8798ad;
  }
  button.btn-outline-tertiary:hover {
    border-color: #8798ad;
  }
  .show > button.btn-outline-tertiary:hover {
    color: #fff !important;
    background-color: #8798ad;
  }
  .dropdown-menu.show {
    background-color: white;
    margin: 0 !important;
  }
  .dropdown-menu {
    margin: 0 !important;
  }

  button.dropdown-toggle {
    position: relative;
    padding: 8px 11px 8px 37px;
    color: #0d1438;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
  }

  button.dropdown-toggle:after {
    display: none;
  }

  .icon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 8px;
    left: 11px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    margin-right: 6px;
  }

  .filter {
    background-image: url(/assets/icons/Filter-black.svg);
  }

  input[type='checkbox'] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 5px;
    width: 16px;
    height: 16px;
    border: 1px solid #6e7b87;
    outline: none;
  }

  input[type='checkbox']:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
  }

  input:checked[type='checkbox'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
`;

export const Location = (props: Location.Props): ReactElement => {
  const viewport = Theme.useViewport();

  const [locationsSelected, setLocationsSelected] = useState<T_Location[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const {
    value: { selected, available },
    isMultiple,
    onChange,
    ...rest
  } = props;

  const multipleSelected = (location: T_Location): void => {
    const haveLocation = locationsSelected.find(
      (locationSelected) => locationSelected.id === location.id
    );

    if (haveLocation) {
      setLocationsSelected((oldState): T_Location[] => {
        const newLocationsSelecte = oldState.filter(
          (locationSelected) => locationSelected.id !== location.id
        );

        return newLocationsSelecte;
      });
    } else {
      setLocationsSelected((oldState): T_Location[] => {
        const newState = [...oldState, location];

        return newState;
      });
    }
  };

  useEffect(() => {
    if (locationsSelected.length === 0) {
      onChange?.(undefined);
    } else {
      onChange?.(locationsSelected);
    }
  }, [locationsSelected, onChange]);

  let title =
    !selected && viewport === Viewport.XS
      ? 'Location'
      : Array.isArray(selected)
      ? selected[0]?.name
      : selected?.name ?? 'All Locations';

  if (available.length < 1) {
    title = 'No available locations';
  } else if (available.length < 2) {
    title = available[0].name;
  }

  return (
    <FilterDropwdown>
      <Dropdown
        show={isOpen}
        onBlur={(): void => {
          setIsOpen(false);
        }}
        {...rest}
      >
        <Dropdown.Toggle
          variant={selected ? 'tint' : 'outline-tertiary'}
          disabled={available.length < 1}
          onClick={(): void => {
            setIsOpen(!isOpen);
          }}
        >
          <div className='icon filter' />
          {Str.truncate(title, { length: 30 })}
        </Dropdown.Toggle>

        <Dropdown.Menu
          onMouseDown={(e: React.SyntheticEvent): void => {
            e.preventDefault();
          }}
        >
          <Dropdown.Item
            key='all'
            onClick={(): void => {
              onChange?.(undefined);
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              !isMultiple && setIsOpen(false);
            }}
          >
            All Locations
          </Dropdown.Item>

          {[...available]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((location: T_Location): ReactElement => {
              let active = false;

              const haveLocationSelected = Array.isArray(selected)
                ? selected.find((locationSelected) => locationSelected.id === location.id)
                : selected;

              active = haveLocationSelected ? location.id === haveLocationSelected.id : false;

              return (
                <div key={location.id}>
                  {isMultiple && (
                    <div>
                      <Dropdown.Item
                        className='d-flex flex-row align-items-center'
                        active={active}
                        onClick={(): void => {
                          multipleSelected(location);
                        }}
                      >
                        <input
                          readOnly
                          id='isProducedCheck'
                          type='checkbox'
                          className='mb-0 mr-4px'
                          name='isProducedCheck'
                          checked={active}
                        />

                        {location.name}
                      </Dropdown.Item>
                    </div>
                  )}
                  {!isMultiple && (
                    <Dropdown.Item
                      active={active}
                      onClick={(): void => {
                        onChange?.(!active ? location : undefined);
                        setIsOpen(false);
                      }}
                    >
                      {location.name}
                    </Dropdown.Item>
                  )}
                </div>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
    </FilterDropwdown>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Location {
  export interface Value {
    available: readonly T_Location[];
    selected?: T_Location | T_Location[];
  }

  export type Props = Omit<DropdownButtonProps, 'onChange' | 'title'> & {
    value: Value;
    isMultiple?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    locations?: any;
    onChange?: (value?: T_Location | T_Location[]) => void;
  };
}
