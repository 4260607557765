import { gql } from '@apollo/client';
import { Obj, Str } from '@livecontrol/core-utils';
import type { AddOn } from '@livecontrol/scheduler/model';

export type AddOnRecord = Partial<{
  id: string;
  name: string;
  type: string;
}>;

export const normalize = (record: Partial<AddOnRecord>): AddOn | undefined => {
  const candidate = {
    id: Str.normalize(record.id),
    name: Str.normalize(record.name),
    type: Str.normalize(record.type),
  };

  return Obj.isHydrated(candidate) ? candidate : undefined;
};

export const StandardAddOnResponse = gql`
  fragment StandardAddOnResponse on Addon {
    id
    name
    type
  }
`;

