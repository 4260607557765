import { Form, Loading } from '@livecontrol/core-ui';
import { Obj, Str } from '@livecontrol/core-utils';
import { Modal } from '@livecontrol/scheduler/components';
import { Scheduler } from '@livecontrol/scheduler/store';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { Store } from '../../../store';

const RTMPFields = (): React.ReactElement => (
  <>
    <Form.Group
      name='name'
      label='Name'
      controlProps={{
        placeholder: 'Name'
      }}
      labelProps={{
        className: 'text-dark font-size-15px font-weight-bold '
      }}
    />
    <Form.Group
      name='streamingURL'
      label='Streaming URL'
      controlProps={{
        placeholder: 'Streaming URL'
      }}
      labelProps={{
        className: 'text-dark font-size-15px font-weight-bold '
      }}
    />
    <Form.Group
      name='streamKey'
      label='Stream Key'
      controlProps={{
        placeholder: 'Stream Key'
      }}
      labelProps={{
        className: 'text-dark font-size-15px font-weight-bold '
      }}
    />
  </>
);

interface Values {
  name: string;
  streamingURL?: string;
  streamKey?: string;
}

export const validationSchema = Yup.object({
  name: Yup.string().required('Streaming name is necessary.'),
  streamingURL: Yup.string().required('Please enter Streaming URL'),
  streamKey: Yup.string().required('Please enter Stream Key')
});

export const AddRTMPModal = ({
  show,
  handleClose,
  onDone
}: {
  show: boolean;
  handleClose?: () => void;
  onDone: () => void;
}): React.ReactElement => {
  const account = Store.Account.useAccount();

  const [createRTMPDestination, { loading }] = Scheduler.Destination.useAddRTMP();

  return (
    <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} centered>
      <Modal.Header closeButton>
        <h1 className='font-size-20px font-weight-bold mb-24px'>Add Custom RTMP Destination</h1>
      </Modal.Header>
      <Formik<Values>
        initialValues={{ name: '' }}
        validationSchema={validationSchema}
        onSubmit={(data): void => {
          const rtmp = {
            destination: Str.normalize(data.streamingURL),
            streamKey: Str.normalize(data.streamKey)
          };

          if (Obj.isHydrated(rtmp)) {
            void createRTMPDestination({
              clientId: account.id.toString(),
              name: data.name,
              rtmp
            }).then(() => {
              onDone();
            });
          }
        }}
      >
        <Form>
          <Modal.Body>
            <RTMPFields />
          </Modal.Body>
          <Modal.Footer>
              <Form.Submit
                className='btn-primary font-size-14px font-weight-bold'
                loading={loading}
                size='lg'
                style={{paddingLeft: 100, paddingRight: 100}}
                >
                Connect
              </Form.Submit>
              <Modal.Button
                variant='btn text-tertiary font-size-14px w-25 font-weight-bold'
                onClick={handleClose}>
                Cancel
              </Modal.Button>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  );
};

export const EditRTMPModal = ({
  id,
  show,
  handleClose,
  onDone
}: {
  show: boolean;
  handleClose?: () => void;
  id: string;
  onDone: () => void;
}): React.ReactElement => {
  const account = Store.Account.useAccount();

  const { destinations, loading: destinationsLoading } =
    Scheduler.Destination.useDestinations(account);

  const [editRTMPDestination, { loading: editMutationLoading }] =
    Scheduler.Destination.useEditRTMP();

  const destination = destinations?.find((i) => i.id === id);

  return (
    <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} centered>
      {destinationsLoading || !destinations || !destination ? (
        <Loading size={100} />
      ) : (
        <>
          <Modal.Header closeButton>
            <h1 className='font-size-20px font-weight-bold mb-24px'>Edit Custom RTMP Destination</h1>
          </Modal.Header>
          <Formik<Values>
            initialValues={{
              name: destination.name,
              streamKey: destination.rtmp?.streamKey,
              streamingURL: destination.rtmp?.destination
            }}
            onSubmit={(data): void => {
              const rtmp = {
                destination: Str.normalize(data.streamingURL),
                streamKey: Str.normalize(data.streamKey)
              };

              if (Obj.isHydrated(rtmp)) {
                void editRTMPDestination({
                  account,
                  destination,
                  name: data.name,
                  rtmp
                }).then(() => {
                  onDone();
                });
              }
            }}
          >
            <Form>
              <Modal.Body>
                <RTMPFields />
              </Modal.Body>
              <Modal.Footer>
                  <Form.Submit
                    className='btn-primary font-size-14px font-weight-bold'
                    loading={editMutationLoading}
                    size='lg'
                    style={{paddingLeft: 100, paddingRight: 100}}
                    >
                    Update
                  </Form.Submit>
                  <Modal.Button variant='btn text-tertiary w-25 font-weight-bold' onClick={handleClose}>
                    Cancel
                  </Modal.Button>
              </Modal.Footer>
            </Form>
          </Formik>
        </>
      )}
    </Modal>
  );
};
