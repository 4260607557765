import { gql } from '@apollo/client';
import { Bool, Num, Obj, Str } from '@livecontrol/core-utils';
import { Destination, Event } from '@livecontrol/scheduler/model';
import _ from 'lodash';
import { DateTime } from 'luxon';
import type { PartialDeep } from 'type-fest';
import * as D_Store from '../destination';
import * as F_Store from '../feedback';
import * as L_Store from '../location';

import Production = Event.Production;
import Phase = Event.Phase;

const UTC = { zone: 'utc' };

const toPhase = (status: string, start?: DateTime, end?: DateTime): Phase | undefined => {
  let phase;

  if (status === '1') {
    phase = Phase.Upcoming;
  } else if (status === '2') {
    phase = Phase.Live;
  } else if (status === '3') {
    phase = Phase.Past;
  }

  if (start && end) {
    const now = DateTime.utc();

    // change phase to 3 if end time is 2+ hours long ago
    phase = end < now.minus({ hours: 2 }) ? Phase.Past : phase;
  }

  return phase;
};

export type Record = PartialDeep<{
  id: number;
  name: string;
  start_time: string;
  end_time: string;
  status: string;
  hidden: boolean;
  was_ever_public: boolean;
  is_operated: boolean;
  description: string;
  background_url: string | null;
  operator_notes: string;
  stream_youtube: boolean;
  stream_facebook: boolean;
  stream_zoom: boolean;
  stream_live: boolean;
  record_only: boolean;
  zoom_meeting_id: string;
  zoom_password: string;
  is_simulated_live: boolean;
  simulated_live_filename: string;
  simulated_live_asset_id: number;
  asset_url: string;
  integrations: { integration: string }[];
  event_feedback: F_Store.Dbx.Record;
  location: L_Store.Dbx.LocationRecord;
  event_password: string | null;
  cue_sheet_url: string | null;
  require_registration: boolean | null;
  show_viewers: boolean | null;
  registration: boolean | null;
  live_chat: boolean | null;
  redirect_url: string | null;
  receive_iso_recordings: boolean;
  use_intro_and_outro_slides: boolean;
  client_booking_customer_name?: string | null;
  client_booking_customer_email?: string | null;
  client_booking_customer_phone?: string | null;
  client_booking_upgrade_identifier: string | null;
  event_type?: string; // 'cb1' | 'cb2' | 'cb3' | 'regular';
  type?: string; // 'cb1' | 'cb2' | 'cb3' | 'regular';
  is_mobile_kit?: boolean;
  technical_contact?: {
    id: number;
  };
  linkedEvent?: {
    id: number;
  };
}>;

export const toEventIds = (events: Event.Like | Event.Like[]): number[] =>
  _(events).thru(_.castArray).map(Event.toId).compact().uniq().value();

/**
 * Converts a GraphQL event record into an `Event` object.
 *
 * @param record - Raw GrapQL user record.
 * @returns Normalized `User` object or `undefined`.
 */
export const normalize = (record?: Record): Event | undefined => {
  let event;

  if (record) {
    const start = record.start_time ? DateTime.fromISO(record.start_time, UTC) : undefined;
    const end = record.end_time ? DateTime.fromISO(record.end_time, UTC) : undefined;

    const location = record.location
      ? L_Store.Dbx.normalizeWithOnboarding(record.location)
      : undefined;

    const isMobileKit = record.is_mobile_kit ? record.is_mobile_kit : false;

    // Pull out required fields
    const candidate = {
      id: Event.toId(record.id),
      title: Str.normalize(record.name),
      production:
        typeof record.type === 'string' &&
        (record.type === 'cb1' || record.type === 'cb2' || record.type === 'cb3')
          ? Production.Client_Booking
          : record.is_operated && !isMobileKit
          ? Production.Produced
          : isMobileKit
          ? Production.Mobile_Kit
          : location?.simulatedLive
          ? Production.Simulated_Live
          : record.type === 'mobile'
          ? Production.Mobile
          : record.type === 'test'
          ? Production.Test
          : Production.Static,
      start,
      end,
      phase: toPhase(record.status!, start, end),
      location
    };

    if (!Obj.isHydrated(candidate)) {
      return undefined;
    }

    const eventFeedback = record.event_feedback
      ? F_Store.Dbx.normalize(record.event_feedback)
      : undefined;

    const clientContactDetails: Event.ClientContactDetails | undefined = Obj.normalize({
      name: Str.normalize(record.client_booking_customer_name),
      email: Str.normalize(record.client_booking_customer_email),
      phoneNumber: Str.normalize(record.client_booking_customer_phone)
    });

    // Tack on optional fields
    event = Object.assign(candidate, {
      operatorNotes: Str.normalize(record.operator_notes),
      description: Str.normalize(record.description),
      backgroundURL: Str.normalize(record.background_url),
      clientContactDetails,
      zoomDetails: {
        streamZoom: Bool.normalize(record.stream_zoom),
        meetingId: Str.normalize(record.zoom_meeting_id),
        password: Str.normalize(record.zoom_password)
      },
      cueSheetURL: Str.normalize(record.cue_sheet_url),
      simulatedLiveDetails: {
        fileName: Str.normalize(record.simulated_live_filename),
        assetId: Num.normalize(record.simulated_live_asset_id)
      },
      password: Str.normalize(record.event_password),
      liveChat: Bool.normalize(record.live_chat),
      requireRegistration: Bool.normalize(record.require_registration),
      showViewers: Bool.normalize(record.show_viewers),
      registration: Bool.normalize(record.registration),
      redirectUrl: Str.normalize(record.redirect_url),
      private: Bool.normalize(record.hidden),
      recordOnly: Bool.normalize(record.record_only),
      type: Str.normalize(record.event_type) ?? 'regular',
      repeat: undefined, // @fixme,
      eventFeedback,
      receiveIsoRecordings: Bool.normalize(record.receive_iso_recordings),
      useIntroAndOutroSlides: Bool.normalize(record.use_intro_and_outro_slides),
      redirectUrlSettings: {
        enabled: !!Str.normalize(record.redirect_url),
        url: Str.normalize(record.redirect_url)
      },
      isMobileKit,
      technicalContactSettings: {
        enabled: !!Num.normalize(record.technical_contact?.id),
        contactId: Num.normalize(record.technical_contact?.id)
      },
      linkedEventId: Num.normalize(record.linkedEvent?.id)
    });
  }

  return event;
};

export const correlate = (
  record: Record,
  destinations: Map<Destination.ID, Destination>
): Destination[] | undefined => {
  const result = (record.integrations ?? [])
    .map((el) => destinations.get(Destination.toId(el?.integration) ?? Str.NULL))
    .filter((el): el is Destination => !!el);

  if (record.stream_live && !record.hidden) {
    result.push(D_Store.LC_WEBPLAYER);
  }

  if (record.stream_zoom) {
    result.push(D_Store.ZOOM);
  }

  return result.length ? result : undefined;
};

export const StandardEventResponse = gql`
  fragment StandardEventResponse on Event {
    id
    status
    name
    start_time
    end_time
    hidden
    was_ever_public
    is_operated
    stream_live
    stream_youtube
    stream_facebook
    stream_zoom
    redirect_url
    record_only
    operator_notes
    description
    background_url
    event_feedback {
      ...StandardFeedbackResponse
    }
    zoom_meeting_id
    zoom_password
    is_simulated_live
    simulated_live_filename
    simulated_live_asset_id
    event_password
    cue_sheet_url
    live_chat
    require_registration
    registration
    show_viewers
    location {
      ...StandardLocationResponse
    }
    integrations {
      integration
    }

    event_type
    type
    client_booking_customer_name
    client_booking_customer_email
    client_booking_customer_phone
    receive_iso_recordings
    use_intro_and_outro_slides
    is_mobile_kit    
    technical_contact {
      id
    }
    linkedEvent {
      id
    }
  }
  ${L_Store.Dbx.StandardLocationResponse}
  ${F_Store.Dbx.StandardFeedbackResponse}
`;

export const __typename = 'Event';
