/* eslint-disable react-hooks/exhaustive-deps */
import { Glyph } from '@livecontrol/core-ui';
import { Num } from '@livecontrol/core-utils';
import { Modal } from '@livecontrol/scheduler/components';
import { Scheduler } from '@livecontrol/scheduler/store';
import { FormikProvider } from 'formik';
import { useEffect, useRef } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Form } from '../../../components';
import { Store } from '../../../store';
import { Event } from '../store';
import type { Props } from './form';
import { reasons, useForm } from './form';

const Body = styled(Modal.Body)`
  h2 {
    font-size: var(--font-size-20px);
    padding: var(--spacer-16px) 0;
  }
`;

const ProducedEventsBefore24HoursMessage = ({
  events
}: {
  events: Event[];
}): React.ReactElement | null => {
  const hasProducedEventBefore24Hours = (Array.isArray(events) ? events : [events]).some(
    (v) => Event.Production.Produced === v.production && v.start.diffNow('hours').hours < 24
  );

  if (!hasProducedEventBefore24Hours) {
    return null;
  }

  return (
    <p>
      Heads up - You are within the 24-hour window of{' '}
      {events.length === 1 ? 'the event' : 'your produced event'}
      and have already been assigned a videographer.  You will not receive a credit refund for this cancellation.
    </p>
  );
};

export const DeleteEvent = (props: Props & { result: (value: unknown) => void }): ReactElement => {
  const { formik, result, alert } = useForm(props);
  const { events, ids, onClose, result: result_ } = props;
  const initialRender = useRef(true);

  const account = Store.Account.useAccount();

  const { refetch } = Scheduler.Account.useBillingForced(account);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    }
  }, [formik.values]);

  useEffect(() => {
    result_(result);

    if (refetch) {
      void refetch();
    }
  }, [result]);

  return (
    <Modal show keyboard={false} onHide={onClose}>
      <Modal.Header closeButton>
        <Glyph className='text-white bg-danger'>
          <i className='fal fa-trash-alt' />
        </Glyph>
        <Modal.Title>
          {ids.length === 1
            ? 'Delete this event?'
            : `Delete these ${Num.Format.integer(ids.length)} events?`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='text-center'>
          <ProducedEventsBefore24HoursMessage events={events} />
          <p>
            Are you sure that you want to delete {ids.length > 1 ? 'these events' : 'this event'}?
            This operation is permanent and cannot be undone.
          </p>
          <p>
            Please let us know why you are deleting {ids.length > 1 ? 'these events' : 'this event'}
            .
          </p>
        </div>
        <FormikProvider value={formik}>
          <Form>
            <Body>
              <Form.Alert show={alert.show} onClose={alert.hide}>
                {alert.message}
              </Form.Alert>

              <Form.Group
                name='reason'
                label='Reason'
                controlProps={{
                  as: 'select'
                }}
              >
                {Object.keys(reasons).map((key) => (
                  <option key={key} value={key}>
                    {reasons[Number(key)]}
                  </option>
                ))}
              </Form.Group>

              <Form.Group
                name='other'
                label='Other Reason'
                controlProps={{
                  disabled: formik.values.reason !== '4'
                }}
              />
            </Body>

            <Modal.Footer>
              <Modal.ButtonBox>
                <Modal.Submit loading={(result as { loading: boolean }).loading}>
                  Delete
                </Modal.Submit>
                <Modal.Button variant='outline-secondary' onClick={onClose}>
                  Cancel
                </Modal.Button>
              </Modal.ButtonBox>
            </Modal.Footer>
          </Form>
        </FormikProvider>
      </Modal.Body>
    </Modal>
  );
};
