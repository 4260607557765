import { useRef } from 'react';
import type { ReactElement, ReactNode } from 'react';
import type { OverlayTriggerProps } from 'react-bootstrap/OverlayTrigger';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import B_Tooltip from 'react-bootstrap/Tooltip';
import styled from 'styled-components';

const B_TooltipContainer = styled(B_Tooltip)<{ backgroundcolor?: string }>`
  
.tooltip-inner {
  padding: var(--spacer-16px);
  background-color: ${({ backgroundcolor }): string => `${backgroundcolor ? backgroundcolor : ''}`};
  }
  
  .arrow::before {
    transform: translateY(-2px) !important;
  }

  &.tooltip.show {
    opacity: 1 !important;
  }

  &.tooltip.bs-tooltip-right .arrow:before {
      border-right-color: ${({ backgroundcolor }): string =>
        `${backgroundcolor ? backgroundcolor : ''}`} !important;
  }
  &.tooltip.bs-tooltip-left .arrow:before {
      border-left-color: ${({ backgroundcolor }): string =>
        `${backgroundcolor ? backgroundcolor : ''}`} !important;
  }
  &.tooltip.bs-tooltip-bottom .arrow:before {
      border-bottom-color: ${({ backgroundcolor }): string =>
        `${backgroundcolor ? backgroundcolor : ''}`} !important;
  }
  &.tooltip.bs-tooltip-top .arrow:before {
      border-top-color: ${({ backgroundcolor }): string =>
        `${backgroundcolor ? backgroundcolor : ''}`} !important;
`;

let sequence = 1;

export const Tooltip = ({
  children,
  className,
  backgroundColor,
  tip,
  title,
  ...rest
}: Tooltip.Props): ReactElement => {
  const id = useRef(`lc-truncator-${sequence++}`);

  return (
    <OverlayTrigger
      {...rest}
      overlay={
        <B_TooltipContainer className={className} id={id.current} backgroundcolor={backgroundColor}>
          {title &&
            <div>{title}</div>
          }
          {tip}
        </B_TooltipContainer>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Tooltip {
  export type Props = Omit<OverlayTriggerProps, 'overlay'> & {
    className?: string;
    backgroundColor?: string;
    tip: ReactNode;
    title?: string;
  };
}
