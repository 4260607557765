import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';
import { Form } from '../../../../components';

export const Wrapper = styled(Form)<{ $isMobile?: boolean }>`
  ${({ $isMobile }): SimpleInterpolation =>
    $isMobile
      ? css`
          width: 100%;
        `
      : css`
          min-width: 75%;
        `}

  .react-datepicker-wrapper {
    width: 100%;
  }

  .event-details-input-grid {
    display: grid;
    grid-template-columns: 6rem 10rem;
    align-items: center;
  }

  .event-details-numeric {
    width: 5rem;
  }

  .event-details-frequency-dropdown {
    max-width: 14rem;
    padding-bottom: var(--spacer-12px);
  }
`;
