import { gql, useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import type { MutationResult } from '../graphql';
import { QUERY } from './use-locations';

interface TData {
  updateLocation: {
    id: string;
  };
}

export const MUTATION = gql`
  mutation UpdateLocation(
    $id: String!
    $name: String!
    $user: Float!
    $time_zone: String
    $mux_stream_id: String
    $mux_stream_key: String
    $mux_stream_url: String
    $is_simulated_live: Boolean
    $is_simulcasting_manual: Boolean
    $is_mobile: Boolean
    $is_static: Boolean
    $is_produced: Boolean
    $is_mobile_kit: Boolean
    $is_test: Boolean
    $is_client_booking: Boolean
    $cue_sheet_enabled: Boolean
    $shipping_zip_code: String
    $shipping_street_address_2: String
    $shipping_street_address_1: String
    $shipping_state: String
    $shipping_name: String
    $shipping_country: String
    $shipping_city: String
    $physical_zip_code: String
    $physical_street_address_2: String
    $physical_street_address_1: String
    $physical_state: String
    $physical_city: String
    $physical_country: String
    $add_ons: [Float!]
    $addon_notes: String
    $equipment_notes: String
    $is_refundable_equipment: Boolean
    $graphics_software: String
    $is_static_plus: Boolean
    $external_encoder: Boolean
  ) {
    updateLocation(
      location: {
        id: $id
        name: $name
        user: $user
        time_zone: $time_zone
        mux_stream_id: $mux_stream_id
        mux_stream_key: $mux_stream_key
        mux_stream_url: $mux_stream_url
        is_simulated_live: $is_simulated_live
        is_simulcasting_manual: $is_simulcasting_manual
        is_mobile: $is_mobile
        is_static: $is_static
        is_produced: $is_produced
        is_client_booking: $is_client_booking
        is_mobile_kit: $is_mobile_kit
        is_test: $is_test
        cue_sheet_enabled: $cue_sheet_enabled
        shipping_zip_code: $shipping_zip_code
        shipping_street_address_2: $shipping_street_address_2
        shipping_street_address_1: $shipping_street_address_1
        shipping_state: $shipping_state
        shipping_name: $shipping_name
        shipping_country: $shipping_country
        shipping_city: $shipping_city
        physical_zip_code: $physical_zip_code
        physical_street_address_2: $physical_street_address_2
        physical_street_address_1: $physical_street_address_1
        physical_state: $physical_state
        physical_city: $physical_city
        physical_country: $physical_country
        add_ons: $add_ons
        addon_notes: $addon_notes
        equipment_notes: $equipment_notes
        is_refundable_equipment: $is_refundable_equipment
        graphics_software: $graphics_software
        is_static_plus: $is_static_plus
        external_encoder: $external_encoder
      }
    ) {
      id
      name
    }
  }
`;

interface Args {
  id: string;
  name: string;
  user: number;
  mux_stream_id?: string;
  mux_stream_key?: string;
  mux_stream_url?: string;
  is_simulated_live?: boolean;
  is_simulcasting_manual?: boolean;
  is_mobile?: boolean;
  is_static?: boolean;
  is_produced?: boolean;
  is_client_booking?: boolean;
  is_mobile_kit?: boolean;
  is_test?: boolean;
  cue_sheet_enabled?: boolean;
  shipping_zip_code?: string;
  shipping_street_address_2?: string;
  shipping_street_address_1?: string;
  shipping_state?: string;
  shipping_name?: string;
  shipping_country?: string;
  shipping_city?: string;
  physical_zip_code?: string;
  physical_street_address_2?: string;
  physical_street_address_1?: string;
  physical_state?: string;
  physical_city?: string;
  physical_country?: string;
  add_ons?: number[];
  addon_notes?: string;
  equipment_notes?: string;
  is_refundable_equipment?: boolean;
  graphics_software?: string;
  is_static_plus?: boolean;
  external_encoder?: boolean;
}

export const useUpdateLocation = (): [
  (args: Args) => Promise<boolean>,
  MutationResult<boolean, 'success'>
] => {
  const [mutation, result] = useMutation<TData, Args>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [success, setSuccess] = useState<boolean>();

  return [
    useCallback(
      async (args: Args): Promise<boolean> => {
        let success_ = false;

        try {
          // Parse the input arguments

          // Execute the GraphQL mutation
          const { data } = await mutation({
            variables: { ...args },
            refetchQueries: [
              {
                query: QUERY,
                variables: { id: args.user }
              }
            ]
          });

          success_ = data?.updateLocation.id ? data.updateLocation.id.length > 0 : false;
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setSuccess(success_);

        return success_;
      },
      [mutation, setError, setSuccess]
    ),
    {
      success,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
