import cx from 'classnames';
import type { ComponentPropsWithoutRef, ReactElement } from 'react';
import React from 'react';
import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';

const Wrapper = styled.div<Glyph.Props>`
  display: inline-grid;
  color: var(--primary);
  background-color: var(--tint-ø1);
  place-items: center;
  font-size: var(--font-size-24px);

  ${({ size }): SimpleInterpolation => {
    const x = size === 'lg' ? 4.5 : 3;

    return css`
      width: calc(${x}rem + 1px);
      height calc(${x}rem + 1px);
    `;
  }}
`;

export const Glyph = (props: Glyph.Props): ReactElement => {
  const { className, children, ...rest } = props;

  return (
    <Wrapper className={cx('lc-modal-glyph', 'rounded-circle', className)} {...rest}>
      {children}
    </Wrapper>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Glyph {
  export type Props = React.PropsWithChildren<ComponentPropsWithoutRef<'div'>> & {
    size?: 'lg' | 'sm';
  };
}
