import { gql } from '@apollo/client';
import { Bool, Obj, Str } from '@livecontrol/core-utils';
import { Destination } from '@livecontrol/scheduler/model';

const toRTMP = (record: Partial<Record>): Destination.RTMP | undefined => {
  const rtmp = {
    destination: Str.normalize(record.rtmp_destination),
    streamKey: Str.normalize(record.rtmp_stream_key)
  };

  return Obj.isHydrated(rtmp) ? rtmp : undefined;
};

export interface Record {
  id: string;
  name: string;
  type: string;
  rtmp_destination?: string;
  rtmp_stream_key?: string;
  deauthorized: boolean;
}

export const normalize = (record: Partial<Record>): Destination | undefined => {
  const destination = {
    id: Destination.toId(record.id),
    name: Str.normalize(record.name),
    service: Destination.Service.normalize(record.type),
    deauthorized: Bool.normalize(record.deauthorized)
  };

  return Obj.isHydrated(destination)
    ? {
        ...destination,
        rtmp: toRTMP(record)
      }
    : undefined;
};

export const toMap = (records?: Record[]): Map<Destination.ID, Destination> => {
  type T = [Destination.ID, Destination];

  return new Map(
    records
      ?.map((record: Record): T | undefined => {
        const destination = normalize(record);

        return destination ? [destination.id, destination] : undefined;
      })
      .filter((x): x is T => !!x)
  );
};

export const StandardDestinationResponse = gql`
  fragment StandardDestinationResponse on IntegrationsType {
    id
    name
    type
    rtmp_stream_key
    rtmp_destination
    deauthorized
  }
`;

export const __typename = 'IntegrationsType';
