/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Matrix } from '@livecontrol/scheduler/components';
import type { Account, Location } from '@livecontrol/scheduler/model';
import type { FormikContextType } from 'formik';
import { useEffect, useState } from 'react';
import type { MouseEvent, ReactElement } from 'react';
import styled from 'styled-components';
import { Form } from '../../../../components';
import type { AccountUsersPermission } from './account-permissions';
import type { Values } from './form';

import G = Matrix.Grid;

interface Props {
  subUser: Account.SubUser;
  locations: Location[];
  visibility: boolean;
  userSelected: boolean;
  formik: FormikContextType<Values>;
  setAccountUserPermissionSelected: React.Dispatch<React.SetStateAction<AccountUsersPermission[]>>;
}

const Cell = styled(Matrix.Grid.Row.Cell)`
  border-color: #d5daea;
  justify-content: center;
  padding: 14px 0px;

  &:first-child {
    border-top-left-radius: var(--spacer-4px);
    border-bottom-left-radius: var(--spacer-4px);
  }
  &:last-child {
    border-top-right-radius: var(--spacer-4px);
    border-bottom-right-radius: var(--spacer-4px);
    padding: 14px 0px;
  }
`;

const CellNoBorder = styled(Matrix.Grid.Row.Cell)`
  border: 0px solid transparent;
  justify-content: center;
  padding: 14px 0px;

  &.transparent {
    background-color: transparent !important;
  }

  &.boder-bottom {
    border-bottom: 1px solid #e4e8f0 !important;
  }

  &:last-child {
    padding: 5px 0px !important;
  }

  &:first-child {
    border-left: 0px solid transparent !important;
    position: sticky;
    left: 0;
  }
`;

const Row = styled(Matrix.Grid.Row)`
  position: relative;

  .users {
    position: sticky;
    left: 0;
  }

  img {
    cursor: not-allowed;
  }

  button {
    visibility: hidden;
    display: flex !important;
  }
  &:hover {
    .column {
      border-color: #2e5bff !important;
    }

    button {
      visibility: visible !important;
      display: flex !important;
    }
  }
`;

export const AccountUsers = ({
  setAccountUserPermissionSelected,
  subUser,
  visibility,
  userSelected,
  locations,
  formik
}: Props): ReactElement => {
  const [rowSelected, setRowSelected] = useState(false);

  const assigneLocationPermissionsForm = (isSelected: boolean): void => {
    locations?.forEach((location) => {
      formik.setFieldValue(`${location.id}._${subUser.id}.eventManagment`, isSelected);

      formik.setFieldValue(`${location.id}._${subUser.id}.viewAndDownload`, isSelected);

      formik.setFieldValue(`${location.id}._${subUser.id}.contentManagment`, isSelected);

      formik.setFieldValue(`${location.id}._${subUser.id}.activityLog`, isSelected);

      formik.setFieldValue(`${location.id}._${subUser.id}.productionNotes`, isSelected);
    });
  };

  const selectColumnAccount = (column: number, noChange?: boolean, value?: boolean): void => {
    setAccountUserPermissionSelected((oldState: { userId: string; columnPermission: number }[]) => {
      const permissionToRemove = oldState.find(
        (state) => state.userId === `${subUser.id}` && state.columnPermission === column
      );

      if (typeof value !== 'undefined') {
        if (value) {
          if (permissionToRemove) {
            if (noChange) {
              return oldState;
            }

            return oldState;
          }

          const newState = [
            ...oldState,
            {
              columnPermission: column,
              userId: `${subUser.id}`
            }
          ];

          return newState;
        }

        if (permissionToRemove) {
          if (noChange) {
            return oldState;
          }

          const foundIndex = oldState.findIndex(
            (state) => state.userId === `${subUser.id}` && state.columnPermission === column
          );

          const newState = oldState.filter((_, index) => index !== foundIndex);

          return newState;
        }

        return oldState;
      }

      if (permissionToRemove) {
        if (noChange) {
          return oldState;
        }

        const foundIndex = oldState.findIndex(
          (state) => state.userId === `${subUser.id}` && state.columnPermission === column
        );

        const newState = oldState.filter((_, index) => index !== foundIndex);

        return newState;
      }

      const newState = [
        ...oldState,
        {
          columnPermission: column,
          userId: `${subUser.id}`
        }
      ];

      return newState;
    });
  };

  const selectRow = (active: boolean, isAdmin?: boolean): void => {
    setRowSelected(active);

    formik.setFieldValue(`account._${subUser.id}.destinations`, active);
    formik.setFieldValue(`account._${subUser.id}.webplayer`, active);
    /* formik.setFieldValue(`account._${subUser.id}.productionNotes`, active); */
    formik.setFieldValue(`account._${subUser.id}.billing`, active);
    formik.setFieldValue(`account._${subUser.id}.organization`, active);
    formik.setFieldValue(`account._${subUser.id}.admin`, active);
    formik.setFieldValue(`account._${subUser.id}.id`, subUser.permissions.id);

    if (active) {
      assigneLocationPermissionsForm(active);
    }

    selectColumnAccount(0, isAdmin, active);
    selectColumnAccount(1, isAdmin, active);
    /* selectColumnAccount(2, isAdmin, active); */
    selectColumnAccount(3, isAdmin, active);
    selectColumnAccount(4, isAdmin, active);
    selectColumnAccount(5, undefined, active);
  };

  useEffect(() => {
    const { account } = formik.values;

    if (formik.submitCount > 0) {
      return;
    }

    if (account) {
      const accountPermissions = account[`_${subUser.id}` as keyof typeof account];

      if (accountPermissions) {
        if (accountPermissions['destinations' as keyof typeof accountPermissions]) {
          setRowSelected(true);
          selectColumnAccount(0, undefined, true);
        }

        if (accountPermissions['webplayer' as keyof typeof accountPermissions]) {
          setRowSelected(true);
          selectColumnAccount(1, undefined, true);
        }

        /*
         * if (accountPermissions['productionNotes' as keyof typeof accountPermissions]) {
         * setRowSelected(true);
         * selectColumnAccount(2, undefined, true);
         * }
         */

        if (accountPermissions['billing' as keyof typeof accountPermissions]) {
          setRowSelected(true);
          selectColumnAccount(3, undefined, true);
        }

        if (accountPermissions['organization' as keyof typeof accountPermissions]) {
          setRowSelected(true);
          selectColumnAccount(4, undefined, true);
        }

        if (accountPermissions['admin' as keyof typeof accountPermissions]) {
          setRowSelected(true);
          selectColumnAccount(5, undefined, true);
        }
      }

      if (
        !accountPermissions['destinations' as keyof typeof accountPermissions] &&
        !accountPermissions['webplayer' as keyof typeof accountPermissions] &&
        !accountPermissions['billing' as keyof typeof accountPermissions] &&
        !accountPermissions['organization' as keyof typeof accountPermissions] &&
        !accountPermissions['admin' as keyof typeof accountPermissions]
      ) {
        setRowSelected(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subUser, formik.values]);

  const toggleAdmin = (value: MouseEvent<unknown> | undefined): void => {
    if (typeof value !== undefined) {
      const valueTarget = value?.target as HTMLInputElement;
      const isSelected = valueTarget.checked;

      if (isSelected) {
        assigneLocationPermissionsForm(isSelected);

        selectRow(isSelected, true);
      } else {
        formik.setFieldValue(`account._${subUser.id}.admin`, isSelected);
        selectColumnAccount(5);
      }
    }
  };

  return (
    <>
      {false && (
        <G.Row style={{ display: visibility && userSelected ? 'contents' : 'none' }}>
          <CellNoBorder className='column height-10px' />
          <CellNoBorder className='column height-10px' />
          <CellNoBorder className='column height-10px' />
          <CellNoBorder className='column height-10px' />
          <CellNoBorder className='column height-10px' />
          <CellNoBorder className='column height-10px' />
          <CellNoBorder className='column height-10px' />
          <CellNoBorder className='column height-10px' />
          <CellNoBorder className='column height-10px' />
          <CellNoBorder className='column height-10px' />
          <CellNoBorder className='column height-10px' />
        </G.Row>
      )}
      <Row style={{ display: visibility && userSelected ? 'contents' : 'none' }}>
        <Cell className='column users justify-content-start'>
          <div className='d-flex flex-column pr-4px'>
            <div className='text-dark font-weight-bold font-size-12px'>
              <div className='d-flex flex-column'>
                <div className='account text-dark font-weight-bold font-size-12px'>
                  All Locations
                </div>
              </div>
            </div>
            {!rowSelected ? (
              <button
                onClick={(): void => {
                  selectRow(true);
                }}
                type='button'
                className='btn p-0 bg-transparent text-primary font-weight-bold font-size-12px'
              >
                Select All
              </button>
            ) : (
              <button
                onClick={(): void => {
                  selectRow(false);
                }}
                type='button'
                className='btn p-0 bg-transparent text-primary font-weight-bold font-size-12px'
              >
                Deselect All
              </button>
            )}
          </div>
        </Cell>
        <Cell className='column'>
          <Form.Group
            name={`account._${subUser.id}.admin`}
            label={false}
            className='mb-0'
            controlProps={{
              type: 'checkbox',
              onClick: (event): void => {
                toggleAdmin(event);
              }
            }}
          />
        </Cell>
        <Cell className='column' />
        <Cell className='column'>
          <Form.Group
            name={`account._${subUser.id}.destinations`}
            label={false}
            className='mb-0'
            controlProps={{
              type: 'checkbox',
              onClick: (): void => {
                selectColumnAccount(0);
              }
            }}
          />
        </Cell>
        <Cell className='column'>
          <Form.Group
            name={`account._${subUser.id}.webplayer`}
            label={false}
            className='mb-0'
            controlProps={{
              type: 'checkbox',
              onClick: (): void => {
                selectColumnAccount(1);
              }
            }}
          />
        </Cell>
        <Cell className='column' />
        <Cell className='column'>
          <Form.Group
            name={`account._${subUser.id}.billing`}
            label={false}
            className='mb-0'
            controlProps={{
              type: 'checkbox',
              onClick: (): void => {
                selectColumnAccount(3);
              }
            }}
          />
        </Cell>
        <Cell className='column' />
        <Cell className='column' />
        <Cell className='column'>
          <Form.Group
            name={`account._${subUser.id}.organization`}
            label={false}
            className='mb-0'
            controlProps={{
              type: 'checkbox',
              onClick: (): void => {
                selectColumnAccount(4);
              }
            }}
          />
        </Cell>
        <Cell className='column' />
      </Row>
    </>
  );
};
