import { Bool, Str } from '@livecontrol/core-utils';
import _ from 'lodash';
import validator from 'validator';
import { EventValidator } from './types';

export const validateEventRedirect = (
  newEventInfo: EventValidator.EventInfo
): EventValidator.Errors => {
  const errors: EventValidator.Errors = {};
  const enabled = Bool.normalize(newEventInfo.redirectUrlSettings?.enabled);
  const url = Str.normalize(newEventInfo.redirectUrlSettings?.url);

  if (enabled && !url) {
    _.merge(errors, {
      redirectUrlSettings: {
        url: EventValidator.ErrorMessages.EVENT_REDIRECT_REQUIRED
      }
    });
  } else if (url && !validator.isURL(encodeURI(url))) {
    errors.redirectUrlSettings = { url: EventValidator.ErrorMessages.INVALID_REDIRECT_URL };
  }

  return errors;
};
