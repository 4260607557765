/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { Account, Location } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { FormikContextType } from 'formik';
import { useFormik } from 'formik';
import { useRef } from 'react';

export interface Values {
  locationUser: string;
  // Checar el tipado para los objetos de las locaciones de los permisos
  [index: string]: Record<string, boolean> | boolean | string | undefined;
}

interface Input {
  location_id: string;
  permissions: PermissionsVar[];
}

interface InputAccount {
  clientId: number;
  input: PermissionsAccountVar[];
}

interface PermissionsVar {
  activity_log: boolean;
  content_managment: boolean;
  event_managment: boolean;
  customize_production_notes: boolean;
  view_and_download: boolean;
  user_id: number;
  id?: number;
}

interface PermissionsAccountVar {
  destinations: boolean;
  customize_webplayer: boolean;
  billing: boolean;
  organization_account_info: boolean;
  admin_access: boolean;
  id?: number;
}

interface Form {
  formik: FormikContextType<Values>;
  loading: boolean;
  loadingAccount: boolean;
  called: boolean;
  calledAccount: boolean;
}

export interface Props {
  account: Account;
  subUser?: Account.SubUser;
  locations?: Location[];
}

export const useForm = ({ account, locations }: Props): Form => {
  const [permissionLocationsMutation, { loading, called }] =
    Scheduler.Account.UserManagement.useUpdateForManySubUserLocations();

  const [permissionAccountMutation, { loading: loadingAccount, called: calledAccount }] =
    Scheduler.Account.UserManagement.useUpdateForManySubUserAccount();

  const initialValues: Values = useRef({
    locationUser: 'user'
  }).current;

  const formik = useFormik<Values>({
    initialValues,
    validateOnMount: true,
    validateOnChange: false,

    async onSubmit(data: Values): Promise<void> {
      const input: Input[] = [];
      const permissionsAccount: PermissionsAccountVar[] = [];
      const haveAccount = data.account;

      locations?.forEach((location) => {
        const haveLocation = data[location.id];

        if (haveLocation) {
          const usersId = Object.getOwnPropertyNames(haveLocation);

          const permissions: PermissionsVar[] = [];

          usersId.forEach((userId) => {
            if (
              (haveLocation[userId as keyof typeof haveLocation] as unknown as { id: number }).id
            ) {
              const bodyPermissions: PermissionsVar = {
                activity_log: (
                  haveLocation[userId as keyof typeof haveLocation] as unknown as {
                    activityLog: boolean;
                  }
                ).activityLog
                  ? (
                      haveLocation[userId as keyof typeof haveLocation] as unknown as {
                        activityLog: boolean;
                      }
                    ).activityLog
                  : false,
                content_managment: (
                  haveLocation[userId as keyof typeof haveLocation] as unknown as {
                    contentManagment: boolean;
                  }
                ).contentManagment
                  ? (
                      haveLocation[userId as keyof typeof haveLocation] as unknown as {
                        contentManagment: boolean;
                      }
                    ).contentManagment
                  : false,
                customize_production_notes: (
                  haveLocation[userId as keyof typeof haveLocation] as unknown as {
                    productionNotes: boolean;
                  }
                ).productionNotes
                  ? (
                      haveLocation[userId as keyof typeof haveLocation] as unknown as {
                        productionNotes: boolean;
                      }
                    ).productionNotes
                  : false,
                event_managment: (
                  haveLocation[userId as keyof typeof haveLocation] as unknown as {
                    eventManagment: boolean;
                  }
                ).eventManagment
                  ? (
                      haveLocation[userId as keyof typeof haveLocation] as unknown as {
                        eventManagment: boolean;
                      }
                    ).eventManagment
                  : false,
                user_id: Number(userId.replace('_', '')),
                view_and_download: (
                  haveLocation[userId as keyof typeof haveLocation] as unknown as {
                    viewAndDownload: boolean;
                  }
                ).viewAndDownload
                  ? (
                      haveLocation[userId as keyof typeof haveLocation] as unknown as {
                        viewAndDownload: boolean;
                      }
                    ).viewAndDownload
                  : false
              };

              permissions.push(bodyPermissions);
            } else {
              const bodyPermissions: PermissionsVar = {
                activity_log: (
                  haveLocation[userId as keyof typeof haveLocation] as unknown as {
                    activityLog: boolean;
                  }
                ).activityLog
                  ? (
                      haveLocation[userId as keyof typeof haveLocation] as unknown as {
                        activityLog: boolean;
                      }
                    ).activityLog
                  : false,
                customize_production_notes: (
                  haveLocation[userId as keyof typeof haveLocation] as unknown as {
                    productionNotes: boolean;
                  }
                ).productionNotes
                  ? (
                      haveLocation[userId as keyof typeof haveLocation] as unknown as {
                        productionNotes: boolean;
                      }
                    ).productionNotes
                  : false,

                content_managment: (
                  haveLocation[userId as keyof typeof haveLocation] as unknown as {
                    contentManagment: boolean;
                  }
                ).contentManagment
                  ? (
                      haveLocation[userId as keyof typeof haveLocation] as unknown as {
                        contentManagment: boolean;
                      }
                    ).contentManagment
                  : false,
                event_managment: (
                  haveLocation[userId as keyof typeof haveLocation] as unknown as {
                    eventManagment: boolean;
                  }
                ).eventManagment
                  ? (
                      haveLocation[userId as keyof typeof haveLocation] as unknown as {
                        eventManagment: boolean;
                      }
                    ).eventManagment
                  : false,
                user_id: Number(userId.replace('_', '')),
                view_and_download: (
                  haveLocation[userId as keyof typeof haveLocation] as unknown as {
                    viewAndDownload: boolean;
                  }
                ).viewAndDownload
                  ? (
                      haveLocation[userId as keyof typeof haveLocation] as unknown as {
                        viewAndDownload: boolean;
                      }
                    ).viewAndDownload
                  : false
              };

              permissions.push(bodyPermissions);
            }
          });

          input.push({ location_id: location.id, permissions });
        }
      });

      if (haveAccount) {
        const usersId = Object.getOwnPropertyNames(haveAccount);

        usersId.forEach((userId) => {
          if ((haveAccount[userId as keyof typeof haveAccount] as unknown as { id: string }).id) {
            const bodyPermissions: PermissionsAccountVar = {
              destinations: (
                haveAccount[userId as keyof typeof haveAccount] as unknown as {
                  destinations: boolean;
                }
              ).destinations
                ? (
                    haveAccount[userId as keyof typeof haveAccount] as unknown as {
                      destinations: boolean;
                    }
                  ).destinations
                : false,
              customize_webplayer: (
                haveAccount[userId as keyof typeof haveAccount] as unknown as { webplayer: boolean }
              ).webplayer
                ? (
                    haveAccount[userId as keyof typeof haveAccount] as unknown as {
                      webplayer: boolean;
                    }
                  ).webplayer
                : false,

              id: Number(
                (haveAccount[userId as keyof typeof haveAccount] as unknown as { id: string }).id
              ),
              organization_account_info: (
                haveAccount[userId as keyof typeof haveAccount] as unknown as {
                  organization: boolean;
                }
              ).organization
                ? (
                    haveAccount[userId as keyof typeof haveAccount] as unknown as {
                      organization: boolean;
                    }
                  ).organization
                : false,
              admin_access: (
                haveAccount[userId as keyof typeof haveAccount] as unknown as { admin: boolean }
              ).admin
                ? (haveAccount[userId as keyof typeof haveAccount] as unknown as { admin: boolean })
                    .admin
                : false,
              billing: (
                haveAccount[userId as keyof typeof haveAccount] as unknown as { billing: boolean }
              ).billing
                ? (
                    haveAccount[userId as keyof typeof haveAccount] as unknown as {
                      billing: boolean;
                    }
                  ).billing
                : false
            };

            permissionsAccount.push(bodyPermissions);
          } else {
            const bodyPermissions: PermissionsAccountVar = {
              destinations: (
                haveAccount[userId as keyof typeof haveAccount] as unknown as {
                  destinations: boolean;
                }
              ).destinations
                ? (
                    haveAccount[userId as keyof typeof haveAccount] as unknown as {
                      destinations: boolean;
                    }
                  ).destinations
                : false,
              customize_webplayer: (
                haveAccount[userId as keyof typeof haveAccount] as unknown as { webplayer: boolean }
              ).webplayer
                ? (
                    haveAccount[userId as keyof typeof haveAccount] as unknown as {
                      webplayer: boolean;
                    }
                  ).webplayer
                : false,
              organization_account_info: (
                haveAccount[userId as keyof typeof haveAccount] as unknown as {
                  organization: boolean;
                }
              ).organization
                ? (
                    haveAccount[userId as keyof typeof haveAccount] as unknown as {
                      organization: boolean;
                    }
                  ).organization
                : false,
              admin_access: (
                haveAccount[userId as keyof typeof haveAccount] as unknown as { admin: boolean }
              ).admin
                ? (haveAccount[userId as keyof typeof haveAccount] as unknown as { admin: boolean })
                    .admin
                : false,
              billing: (
                haveAccount[userId as keyof typeof haveAccount] as unknown as { billing: boolean }
              ).billing
                ? (
                    haveAccount[userId as keyof typeof haveAccount] as unknown as {
                      billing: boolean;
                    }
                  ).billing
                : false
            };

            permissionsAccount.push(bodyPermissions);
          }
        });
      }

      let permissionsAccountResponse;

      if (permissionsAccount.length !== 0) {
        const inputAccount: InputAccount = {
          clientId: account.id,
          input: permissionsAccount
        };

        permissionsAccountResponse = await permissionAccountMutation(inputAccount);

        if (permissionsAccountResponse && input.length !== 0) {
          await permissionLocationsMutation(input);
        }
      } else if (input.length !== 0) {
        await permissionLocationsMutation(input);
      }
    }
  });

  return {
    called,
    calledAccount,
    formik,
    loading,
    loadingAccount
  };
};
