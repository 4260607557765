import { Unicode } from '@livecontrol/core-utils';
import cx from 'classnames';
import type { HTMLAttributes, ReactElement } from 'react';
import { Link } from 'react-router-dom';

export const NoAssets = ({ className }: HTMLAttributes<HTMLDivElement>): ReactElement => (
  <div className={cx('d-flex', 'flex-column', 'align-items-center', 'w-100', className)}>
    <div className='font-size-40px pb-28px'>{Unicode.HAND_WAVING}</div>
    <div className='font-size-20px'>Uh oh, you do not have any assets!</div>
    <Link className='font-size-13px pt-12px text-secondary' to='/schedule/create'>
      Ready to start streaming?
    </Link>
  </div>
);
