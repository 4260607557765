import { Filters } from '@livecontrol/scheduler/components';
import React, { useCallback } from 'react';
import type { ReactElement } from 'react';
import { useStore } from '../store';

export const Keyword = (props: Filters.Keyword.Props): ReactElement => {
  const { value, update } = useStore(({ model }) => model.value.filters.keyword);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      update(event.target.value);
    },
    [update]
  );

  return (
    <Filters.Keyword
      placeholder='Search'
      onChange={onChange}
      value={value}
      htmlSize={30}
      {...props}
    />
  );
};
