import { Asset } from '@livecontrol/scheduler/model';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components';
import { DeleteButton } from './delete';
import { DownloadButton } from './download';
import { EditButton } from './edit';
import { EmbeddedButton } from './embedded';
import { FeedbackButton } from './feedback';
import { PasswordProtectButton } from './password-protect';
import { ShareLinkButton } from './share-link';
import { VisibilityButton } from './visibility';

const ActionsBar = styled.div`
  padding: 14px 0px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .main-options-section {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 90px);

    button {
      margin-bottom: 8px;
    }
  }

  .more-options-section {
    display: flex;
    justify-content: flex-end;
    width: 90px;
  }
`;

const MoreOptionsBtn = styled.div`
  .show.dropdown {
    button {
      background-color: #ffffff;
      color: #0d1438;
    }
  }
  button {
    border: 1px solid #cbcbcb;
    background-color: #ffffff;
    color: #0d1438;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 11px !important;
    border-radius: 4px;
    display: flex;

    &:hover {
      background-color: #ffffff;
      color: #0d1438;
      border: 1px solid #cbcbcb;
    }

    &:active {
      background-color: #ffffff !important;
      color: #0d1438 !important;
      border: 1px solid #cbcbcb;
    }

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 6px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }

    .settings {
      background-image: url(/assets/icons/settings.svg);
    }

    &:after {
      display: none;
    }
  }

  .dropdown-menu.show {
    position: absolute;
    transform: none !important;
    inset: unset !important;
    top: calc(100% + 8px) !important;
    left: 0px;
    min-height: 50px;
    min-width: 235px;
    padding: 12px 16px;
    background-color: white;

    a.dropdown-item {
      border: 1px solid #cbcbcb;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 600;
      color: #0d1438;
    }
  }
  @media (max-width: 1279px) {
    .dropdown-menu.show {
      right: 0px !important;
    }
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 6px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .film {
    background-image: url(/assets/icons/Film.svg);
  }

  .button-entry {
    height: 34px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: solid 1px #cbcbcb;
    border-radius: 4px;
    margin-bottom: 8px;
    cursor: pointer;
    color: #0d1438;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 11px !important;
  }
`;

interface Props {
  asset: Asset;
  hasManagementPermission: boolean;
  showRequestBtn: boolean;
}

export const ActionsSection = ({
  asset,
  hasManagementPermission,
  showRequestBtn = true
}: Props): React.ReactElement => {
  if (asset.event) {
    return (
      <ActionsBar>
        <div className='main-options-section'>
          {hasManagementPermission && (
            <>
              <VisibilityButton asset={asset} />
              <PasswordProtectButton asset={asset} />
            </>
          )}
          <DownloadButton asset={asset} />
          {hasManagementPermission && asset.event && <FeedbackButton event={asset.event} />}
        </div>
        <div className='more-options-section'>
          <MoreOptionsBtn>
            <Dropdown>
              <Dropdown.Toggle>
                <div className='icon settings' />
                <span>More</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {asset.visibility !== Asset.Visibility.Private && (
                  <>
                    <ShareLinkButton asset={asset} />
                    <EmbeddedButton asset={asset} />
                  </>
                )}
                {hasManagementPermission && (
                  <>
                    {showRequestBtn && (
                      <a
                        className='button-entry'
                        href='https://form.jotform.com/232046427365152'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <div className='icon film' />
                        <div className='text'>Request Edited Content</div>
                      </a>
                    )}
                    <EditButton asset={asset} />
                    <DeleteButton asset={asset} />
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </MoreOptionsBtn>
        </div>
      </ActionsBar>
    );
  }

  return (
    <ActionsBar>
      <div className='main-options-section'>
        <DownloadButton asset={asset} />
      </div>
      <div className='more-options-section'>
        <MoreOptionsBtn>
          <Dropdown>
            <Dropdown.Toggle>
              <div className='icon settings' />
              <span>More</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <EditButton asset={asset} />
              <DeleteButton asset={asset} />
            </Dropdown.Menu>
          </Dropdown>
        </MoreOptionsBtn>
      </div>
    </ActionsBar>
  );
};
