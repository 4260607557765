import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  .top,
  .bottom {
    stroke-dasharray: 60 207;
  }

  &.x-expanded {
    .top,
    .bottom {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
    }

    .middle {
      stroke-dasharray: 1 60;
      stroke-dashoffset: -30;
    }
  }
`;

// https://uxdesign.cc/the-menu-210bec7ad80c
export const Mobile = (props: React.ComponentPropsWithoutRef<'svg'>): React.ReactElement => (
  <SVG {...props}>
    <path
      className='top'
      d='M 20,29 H 80 C 80,29 95,29 95,67 95,78 91,82 85,82 80,82 75,75 75,75 L25,25'
    />
    <path className='middle' d='M 20,50 H 80' />
    <path
      className='bottom'
      d='M 20,71 H 80 C 80,71 94.5,71.2 94.5,33.3 94.5,22 91,18.33 85.25,18.33 79.55,18.33 75,25 75,25 L25,75'
    />
  </SVG>
);
