import type { ReactElement } from 'react';
import styled from 'styled-components';
import { SectionContainer } from '../../components';
import { Header } from './header';
import { SubmitATicket } from './submit-a-ticket';
import { SupportTypes } from './support-types';

const HelpCenterReminder = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #8798ad;
  margin-top: 64px;

  a {
    color: #2e5bff;
  }
`;

export const Layout = (): ReactElement => (
  <SectionContainer className='col-12'>
    <Header />
    <div className='container-fluid'>
      <div className='row'>
        <SubmitATicket className='col-12' />
        <SupportTypes className='col-12' />
        <HelpCenterReminder>
          Check out the
          <a
            className=''
            href='https://mylivecontrol.zendesk.com/hc/en-us'
            target='_blank'
            rel='noreferrer'
          >
            {' '}
            help center{' '}
          </a>
          while you contact Support!
        </HelpCenterReminder>
      </div>
    </div>
  </SectionContainer>
);
