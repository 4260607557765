export interface Frequency {
  name: string;
  id: string;
}

export namespace Frequency {
  export const Types = [
    { name: 'weekly', id: 'WEEKLY' },
    { name: 'monthly', id: 'MONTHLY' }
  ];
}
