import { Form } from '@livecontrol/core-ui';
import { ImageUploader } from '@livecontrol/scheduler/components';
import { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { DeleteModal } from '../../webplayer';
import { EventContextStore } from '../event-store';
import type { EventContext } from '../event-store';
import { ContentSection, CustomInputContainer, HeaderSection } from '../layout-sections';

const ImageUploaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 34px;
  width: 100%;

  .text-section {
    min-width: 300px;
    width: 300px;
    margin-bottom: 20px;

    .title {
      font-weight: 600;
      font-size: 15px;
      color: #2e384d;
      margin-bottom: 2px;
    }
    .description {
      font-weight: 600;
      font-size: 13px;
      color: #9c9c9c;
      word-wrap: break-word;
      max-width: 500px;
    }
  }

  > .intro-uploader.cursor-pointer {
    width: 231px !important;
    min-height: 70px;
    max-height: 144px;
  }

  .intro-image-button {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 4px 11px;
    border: solid 1px #cbcbcb;
    border-radius: 4px;
    background-color: #ffffff;
    margin-top: 12px;

    .icon {
      width: 20px;
      min-width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      margin-right: 6px;
    }
    .upload {
      background-image: url('/assets/icons/Upload-black.svg');
    }
    .text {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #0d1438;
    }
  }
  @media (max-width: 1199px) {
    display: flex;
    align-items: center;

    .text-section {
      width: 100%;
      text-align: center;
      margin-right: 0px;
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }
`;

export const EventImages = (): React.ReactElement => {
  const { store }: EventContext = useContext<EventContext>(EventContextStore);

  const uploadFile = ImageUploader.Helper.useUploadFile();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const field = Form.Utils.useField('backgroundURL');

  const onChange = useCallback(
    async ({ blob, type }: { blob: Blob; type: string }): Promise<void> => {
      try {
        field?.helpers.setValue('loading', false);

        const url = await uploadFile({
          blob,
          type,
          name: 'intro'
        });

        field?.helpers.setValue(url);

        store.setEventInfo({
          ...store.eventInfo,
          backgroundURL: url
        });
      } catch {
        field?.helpers.setValue(undefined);
      }
    },
    [field?.helpers, store, uploadFile]
  );

  const OnUrlChange = (url?: string): void => {
    store.setEventInfo({
      ...store.eventInfo,
      backgroundURL: url
    });
  };

  return (
    <>
      <HeaderSection>
        <div className='divider' />
        <div className='title'>
          <div className='text'>Event Settings </div>
        </div>
      </HeaderSection>

      <ContentSection>
        <CustomInputContainer>
          <Form.Descriptor
            title='Custom Background'
            titleClassName='title mr-lg-3 font-size-15px font-weight-bold text-dark pb-16px'
            description='This image appears before the event goes live, and as a cover photo in the Content Library. For the best results on all devices, use an image that’s at least 1920 x 1080 pixels and 6MB or less.'
            className='form-section-lateral d-lg-flex align-items-center'
            descriptionClassName='font-size-13px text-secondary font-weight-bold text-gray-light mb-8px line-height-18px'
          >
            <ImageUploaderContainer className='ml-12px'>
              <ImageUploader.Uploader
                style={{ width: '100%' }}
                name='backgroundURL'
                aspectRatio={16 / 9}
                aspectRatioLabel='16:9'
                src={store.eventInfo.backgroundURL}
                // src={field?.meta.value as string}
                onChange={onChange}
                onRemove={(): void => {
                  setShowDeleteModal(true);
                }}
                className='intro-uploader'
                useAltStyle
                displayPreviewText='Upload Your Image'
              />
              {field?.meta.error && (
                <p className='text-danger font-size-12px'>{field.meta.error}</p>
              )}
            </ImageUploaderContainer>

            <DeleteModal
              show={showDeleteModal}
              onClose={(): void => {
                setShowDeleteModal(false);
              }}
              onDelete={(): void => {
                setShowDeleteModal(false);
                OnUrlChange('');
              }}
              name='intro'
            />
          </Form.Descriptor>
        </CustomInputContainer>
      </ContentSection>
    </>
  );
};
