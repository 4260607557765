import cx from 'classnames';
import { Component } from 'react';
import type { PropsWithChildren, ReactNode } from 'react';
import type { BadgeProps } from 'react-bootstrap/Badge';
import B_Badge from 'react-bootstrap/Badge';
import styled from 'styled-components';

const Container = styled.div<{ offset: number }>`
  .badge {
    ${({ offset }): string => `--offset: ${offset}px;`}

    border: 1px solid rgba(var(--white-rgb), 0.15);

    &.x-top-left {
      top: var(--offset);
      left: var(--offset);
    }

    &.x-bottom-left {
      bottom: var(--offset);
      left: var(--offset);
    }

    &.x-top-right {
      top: var(--offset);
      right: var(--offset);
    }

    &.x-bottom-right {
      bottom: var(--offset);
      right: var(--offset);
    }
  }
`;

// eslint-disable-next-line react/prefer-stateless-function
export class Badge extends Component<Badge.Props> {
  public override render(): ReactNode {
    const { children, className, corner, offset, variant, ...rest } = this.props;

    return (
      <Container offset={offset ?? 16} {...rest}>
        <B_Badge
          className={cx('position-absolute', corner && `x-${corner}`, className)}
          variant={variant ?? 'dark'}
        >
          {children}
        </B_Badge>
      </Container>
    );
  }
}

export namespace Badge {
  export type Props = BadgeProps &
    PropsWithChildren<{
      corner?: 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right';
      offset?: number;
    }>;
}
