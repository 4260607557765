import type { Dehydrated } from '@livecontrol/core-utils';
import { Arr, EmailAddress, Enum, Nix, Num, Obj, Str } from '@livecontrol/core-utils';
import type { CognitoIdentityServiceProvider } from 'aws-sdk';

export interface CognitoUser {
  id: CognitoUser.ID;
  email: EmailAddress;
  phone_number?: string;
  first_name: string;
  last_name?: string;
  splashtop_email?: string;
  status?: CognitoUser.Status;
  groups?: CognitoUser.Group[];
  masqueradeToken?: string;
  time_zone?: string;
  command_center_permissions?: {
    installer_access: boolean;
    installer_management: boolean;
    event_access: boolean;
    device_access: boolean;
  };
}

export namespace CognitoUser {
  export type ID = string;
  export type Like = CognitoUser | ID | string;

  export const toId = (user?: Like): ID | undefined =>
    Str.normalize(Obj.isObject<CognitoUser>(user) ? user.id : user);

  export interface Group {
    name: string;
    precedence: number;
  }

  export enum Status {
    Archived = 'ARCHIVED',
    Compromised = 'COMPROMISED',
    Confirmed = 'CONFIRMED',
    ForceChangePassword = 'FORCE_CHANGE_PASSWORD',
    Reset = 'RESET_REQUIRED',
    Unconfirmed = 'UNCONFIRMED',
    Unknown = 'UNKNOWN'
  }

  export type Candidate = Dehydrated<{
    id?: string;
    sub?: string;
    email: string;
    first_name: string;
    phone_number: string;
    status: string;
    groups: Record<string, unknown>[];
  }>;

  export const normalize = (record?: Candidate): CognitoUser | undefined => {
    let user: CognitoUser | undefined;

    if (record) {
      // Required fields

      const candidate = {
        id: toId(record.id ?? record.sub),
        email: EmailAddress.normalize(record.email),
        phone_number: Str.normalize(record.phone_number),
        first_name: Str.normalize(record.first_name)
      };

      if (Obj.isHydrated(candidate)) {
        user = {
          ...candidate,
          status: Enum.normalize(Status, record.status),
          groups: Arr.normalize(record.groups)
            .map((group) => {
              const value = {
                name: Str.normalize(group.name),
                precedence: Num.normalize(group.precedence)
              };

              return Obj.isHydrated(value) ? <Group>value : undefined;
            })
            .filter(Nix.isNotNil)
        };
      }
    }

    return user;
  };

  export namespace Attributes {
    export const toAttributeObject = (
      attributes: Record<string, string>
    ): CognitoIdentityServiceProvider.AttributeType[] =>
      Obj.keys(attributes).map((key) => ({
        Name: key,
        Value: attributes[key]
      }));
  }

  export const Constraints = {
    NAME_MAX_LENGTH: 255,
    EMAIL_MAX_LENGTH: 128,
    PHONE_MAX_LENGTH: 15
  };
}
