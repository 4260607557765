import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
// eslint-disable-next-line import/no-internal-modules
import type { Area } from 'react-easy-crop/types';
import { Helper } from './helper';

export const ImageCropper = ({
  setBlob,
  src,
  aspectRatio = 1
}: {
  setBlob: React.Dispatch<React.SetStateAction<Blob | null>>;
  src: string;
  aspectRatio?: number;
}): JSX.Element => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = async (_croppedArea: Area, croppedAreaPixels: Area): Promise<void> => {
    const croppedImage = await Helper.getCroppedImg(src, croppedAreaPixels);

    setBlob(croppedImage);
  };

  /*
   * need to have a parent with `position: relative`
   * to prevent cropper taking up whole page
   */
  return (
    <Cropper
      image={src}
      crop={crop}
      zoom={zoom}
      aspect={aspectRatio}
      onCropChange={setCrop}
      onCropComplete={onCropComplete}
      onZoomChange={setZoom}
    />
  );
};
