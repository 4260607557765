import { Modal } from '@livecontrol/scheduler/components';
import type { Account } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { ReactElement } from 'react';
import { Form } from '../../../components';
import { Alert } from '../../../store';

interface Props {
  subUser: Account.SubUser;
  onClose: () => void;
}

export const ResendInvite = ({ subUser, onClose }: Props): ReactElement => {
  const [mutation, { loading, error }] = Scheduler.Account.UserManagement.useResetSubUserPassword();
  const alert = Alert.useAlert(error);

  const onOkay = async (): Promise<void> => {
    if (await mutation(subUser.id)) {
      onClose();
    }
  };

  return (
    <Modal show={!!subUser} onHide={onClose}>
      <Modal.Header closeButton>
        <i className='fal fa-paper-plane text-primary' style={{ height: 50, width: 30 }} />
        <Modal.Title className='mb-8px mt-8px font-size-20px font-weight-bold'>
          Resend Invitation
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Alert show={alert.show} onClose={alert.hide}>
          {alert.message}
        </Form.Alert>

        <p className='text-center'>
          A new user invite email will be sent to{' '}
          <strong>
            {subUser.fullName} - {subUser.email}
          </strong>{' '}
          prompting them to choose a new password so they can access your LiveControl account.
        </p>
        <p className='text-center'>Are you sure you want to send this email?</p>
      </Modal.Body>

      <Modal.Footer style={{ paddingLeft: '80px', paddingRight: '80px' }}>
        <Modal.ButtonBox>
          <Modal.Submit
            type='submit'
            loading={loading}
            onClick={onOkay}
            variant='primary'
            className='btn-wide font-size-14px font-weight-bold'
          >
            Resend Invite
          </Modal.Submit>
          <Modal.Button
            variant='bg-transparent'
            className='btn-wide font-size-14px font-weight-bold text-secondary'
            onClick={onClose}
          >
            Cancel
          </Modal.Button>
        </Modal.ButtonBox>
      </Modal.Footer>
    </Modal>
  );
};
