import React from 'react';

export const Action = ({ action }: { action: string }): React.ReactElement => (
  <>
    {action.includes('Created') ? (
      <img src='/assets/icons/video-black.svg' alt='Created' className='mr-4px' />
    ) : null}

    {action.includes('Deleted') ? (
      <img src='/assets/icons/video-off-black.svg' alt='Deleted' className='mr-4px' />
    ) : null}

    {action.includes('Started') ? (
      <img src='/assets/icons/Satellite.svg' alt='Started' className='mr-4px' />
    ) : null}

    {action.includes('Updated') ? (
      <img src='/assets/icons/upload-desktop-black.svg' alt='Updated' className='mr-4px' />
    ) : null}

    {action.includes('Stopped') ? (
      <img src='/assets/icons/check-black.svg' alt='Stopped' className='mr-4px' />
    ) : null}

    {action.includes('Credit - Manual Update') ? (
      <img src='/assets/icons/Edit-black.svg' alt='Credit - Manual Update' className='mr-4px' />
    ) : null}

    {action.includes('Credits - Updated') ? (
      <img src='/assets/icons/Edit-black.svg' alt='Credits - Updated' className='mr-4px' />
    ) : null}

    {action.includes('Invoice - Paid') ? (
      <img src='/assets/icons/check-black.svg' alt='Invoice - Paid' className='mr-4px' />
    ) : null}

    {action}
  </>
);
