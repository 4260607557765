import type { Account } from '@livecontrol/scheduler/model';
import { useEffect } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;

  .preview-frame {
    width: 317px;
    height: 210px;
    border: solid 2px #2e5bff;
    background-color: #080e23;
    padding: 16px 14px 0px;
    border-radius: 12px;

    .page {
      width: 100%;
      height: 100%;
      border: 1px solid rgba(228, 229, 233, 0.12);
      padding: 0px 3px;
      border-radius: 8px 8px 0px 0px;
      animation-fill-mode: both !important;

      .top-bar {
        width: 100%;
        padding: 7px 12px;
        border-bottom: 1px solid rgba(228, 229, 233, 0.12);
        display: flex;

        .ball {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 2px;
        }
        .ball.red {
          background-color: #e8756e;
        }
        .ball.yellow {
          background-color: #e3bd6c;
        }
        .ball.green {
          background-color: #6ebc64;
        }
      }

      .content {
        padding: 6px 11px;
        display: flex;
        justify-content: space-between;

        .left-side {
          .header {
            display: flex;
            align-items: center;

            .player-logo-circle {
              width: 20px;
              min-width: 20px;
              height: 20px;
              margin-right: 6px;
              border: 1px solid rgba(228, 229, 233, 0.12);
              overflow: hidden;
              border-radius: 10px;
              img {
                width: 100%;
                height: auto;
                border-radius: 10px;
              }
            }
            .display-name-preview {
              font-size: 11px;
              color: #ffffff;
              font-weight: 600;
            }
          }
          .background-image-preview {
            margin: 7px 0px 0px 10px;
            width: 174px;
            height: 108px;
            overflow: hidden;
            border-radius: 12px;
            background-color: #ffffff;
            box-shadow: inset -1px 0px 6px rgba(0, 0, 0, 0.3);
            
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 12px;
            }
          }
        }

        .right-side {
          display: flex;
          align-items: flex-end;
          width: 65px;
          flex-direction: column;

          .entry {
            border-radius: 7px;
            background-color: rgba(243, 245, 247, 0.1);
            margin-bottom: 8px;
            height: 14px;
            animation: none;
          }

          .entry.active {
            animation: fade-in 0.5s linear;
            animation-fill-mode: both;
          }

          .entry:nth-child(1) {
            margin-bottom: 16px;
            margin-right: 9px;
          }
          .entry:nth-child(1) {
            width: 32px;
            animation-delay: 0.15s;
          }
          .entry:nth-child(2) {
            width: 65px;
            animation-delay: 0.3s;
          }
          .entry:nth-child(3) {
            width: 62px;
            animation-delay: 0.45s;
          }
          .entry:nth-child(4) {
            width: 51px;
            animation-delay: 0.6s;
          }
          .entry:nth-child(5) {
            width: 62px;
            animation-delay: 0.75s;
          }
          .entry:nth-child(6) {
            width: 41px;
            animation-delay: 0.9s;
          }
        }
      }
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
      transform: scale(0.1);
    }

    85% {
      opacity: 0.95;
      transform: scale(1.1);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes gradient-white {
    0% {
      background: linear-gradient(113.69deg, #ffffff 0%, #ffffff 0%, rgba(8, 14, 35, 1) 100%);
    }
    10% {
      background: linear-gradient(113.69deg, #ffffff 0%, #ffffff 10%, rgba(8, 14, 35, 1) 100%);
    }
    20% {
      background: linear-gradient(113.69deg, #ffffff 0%, #ffffff 20%, rgba(8, 14, 35, 1) 100%);
    }
    30% {
      background: linear-gradient(113.69deg, #ffffff 0%, #ffffff 30%, rgba(8, 14, 35, 1) 100%);
    }
    40% {
      background: linear-gradient(113.69deg, #ffffff 0%, #ffffff 40%, rgba(8, 14, 35, 1) 100%);
    }
    50% {
      background: linear-gradient(113.69deg, #ffffff 0%, #ffffff 50%, rgba(8, 14, 35, 1) 100%);
    }
    60% {
      background: linear-gradient(113.69deg, #ffffff 0%, #ffffff 60%, rgba(8, 14, 35, 1) 100%);
    }
    70% {
      background: linear-gradient(113.69deg, #ffffff 0%, #ffffff 70%, rgba(8, 14, 35, 1) 100%);
    }
    80% {
      background: linear-gradient(113.69deg, #ffffff 0%, #ffffff 80%, rgba(8, 14, 35, 1) 100%);
    }
    90% {
      background: linear-gradient(113.69deg, #ffffff 0%, #ffffff 90%, rgba(8, 14, 35, 1) 100%);
    }
    100% {
      background: linear-gradient(113.69deg, #ffffff 0%, #ffffff 100%, rgba(8, 14, 35, 1) 100%);
    }
  }

  @keyframes gradient-light-blue {
    0% {
      background: linear-gradient(113.69deg, #ecf8ff 0%, #ecf8ff 0%, rgba(8, 14, 35, 1) 100%);
    }
    10% {
      background: linear-gradient(113.69deg, #ecf8ff 0%, #ecf8ff 10%, rgba(8, 14, 35, 1) 100%);
    }
    20% {
      background: linear-gradient(113.69deg, #ecf8ff 0%, #ecf8ff 20%, rgba(8, 14, 35, 1) 100%);
    }
    30% {
      background: linear-gradient(113.69deg, #ecf8ff 0%, #ecf8ff 30%, rgba(8, 14, 35, 1) 100%);
    }
    40% {
      background: linear-gradient(113.69deg, #ecf8ff 0%, #ecf8ff 40%, rgba(8, 14, 35, 1) 100%);
    }
    50% {
      background: linear-gradient(113.69deg, #ecf8ff 0%, #ecf8ff 50%, rgba(8, 14, 35, 1) 100%);
    }
    60% {
      background: linear-gradient(113.69deg, #ecf8ff 0%, #ecf8ff 60%, rgba(8, 14, 35, 1) 100%);
    }
    70% {
      background: linear-gradient(113.69deg, #ecf8ff 0%, #ecf8ff 70%, rgba(8, 14, 35, 1) 100%);
    }
    80% {
      background: linear-gradient(113.69deg, #ecf8ff 0%, #ecf8ff 80%, rgba(8, 14, 35, 1) 100%);
    }
    90% {
      background: linear-gradient(113.69deg, #ecf8ff 0%, #ecf8ff 90%, rgba(8, 14, 35, 1) 100%);
    }
    100% {
      background: linear-gradient(113.69deg, #ecf8ff 0%, #ecf8ff 100%, rgba(8, 14, 35, 1) 100%);
    }
  }

  @keyframes gradient-dark-green {
    0% {
      background: linear-gradient(113.69deg, #314e44 0%, #314e44 0%, rgba(8, 14, 35, 1) 100%);
    }
    10% {
      background: linear-gradient(113.69deg, #314e44 0%, #314e44 10%, rgba(8, 14, 35, 1) 100%);
    }
    20% {
      background: linear-gradient(113.69deg, #314e44 0%, #314e44 20%, rgba(8, 14, 35, 1) 100%);
    }
    30% {
      background: linear-gradient(113.69deg, #314e44 0%, #314e44 30%, rgba(8, 14, 35, 1) 100%);
    }
    40% {
      background: linear-gradient(113.69deg, #314e44 0%, #314e44 40%, rgba(8, 14, 35, 1) 100%);
    }
    50% {
      background: linear-gradient(113.69deg, #314e44 0%, #314e44 50%, rgba(8, 14, 35, 1) 100%);
    }
    60% {
      background: linear-gradient(113.69deg, #314e44 0%, #314e44 60%, rgba(8, 14, 35, 1) 100%);
    }
    70% {
      background: linear-gradient(113.69deg, #314e44 0%, #314e44 70%, rgba(8, 14, 35, 1) 100%);
    }
    80% {
      background: linear-gradient(113.69deg, #314e44 0%, #314e44 80%, rgba(8, 14, 35, 1) 100%);
    }
    90% {
      background: linear-gradient(113.69deg, #314e44 0%, #314e44 90%, rgba(8, 14, 35, 1) 100%);
    }
    100% {
      background: linear-gradient(113.69deg, #314e44 0%, #314e44 100%, rgba(8, 14, 35, 1) 100%);
    }
  }

  @keyframes gradient-blue {
    0% {
      background: linear-gradient(113.69deg, #002e51 0%, #002e51 0%, rgba(8, 14, 35, 1) 100%);
    }
    10% {
      background: linear-gradient(113.69deg, #002e51 0%, #002e51 10%, rgba(8, 14, 35, 1) 100%);
    }
    20% {
      background: linear-gradient(113.69deg, #002e51 0%, #002e51 20%, rgba(8, 14, 35, 1) 100%);
    }
    30% {
      background: linear-gradient(113.69deg, #002e51 0%, #002e51 30%, rgba(8, 14, 35, 1) 100%);
    }
    40% {
      background: linear-gradient(113.69deg, #002e51 0%, #002e51 40%, rgba(8, 14, 35, 1) 100%);
    }
    50% {
      background: linear-gradient(113.69deg, #002e51 0%, #002e51 50%, rgba(8, 14, 35, 1) 100%);
    }
    60% {
      background: linear-gradient(113.69deg, #002e51 0%, #002e51 60%, rgba(8, 14, 35, 1) 100%);
    }
    70% {
      background: linear-gradient(113.69deg, #002e51 0%, #002e51 70%, rgba(8, 14, 35, 1) 100%);
    }
    80% {
      background: linear-gradient(113.69deg, #002e51 0%, #002e51 80%, rgba(8, 14, 35, 1) 100%);
    }
    90% {
      background: linear-gradient(113.69deg, #002e51 0%, #002e51 90%, rgba(8, 14, 35, 1) 100%);
    }
    100% {
      background: linear-gradient(113.69deg, #002e51 0%, #002e51 100%, rgba(8, 14, 35, 1) 100%);
    }
  }

  @keyframes gradient-purple {
    0% {
      background: linear-gradient(113.69deg, #272438 0%, #272438 0%, rgba(8, 14, 35, 1) 100%);
    }
    10% {
      background: linear-gradient(113.69deg, #272438 0%, #272438 10%, rgba(8, 14, 35, 1) 100%);
    }
    20% {
      background: linear-gradient(113.69deg, #272438 0%, #272438 20%, rgba(8, 14, 35, 1) 100%);
    }
    30% {
      background: linear-gradient(113.69deg, #272438 0%, #272438 30%, rgba(8, 14, 35, 1) 100%);
    }
    40% {
      background: linear-gradient(113.69deg, #272438 0%, #272438 40%, rgba(8, 14, 35, 1) 100%);
    }
    50% {
      background: linear-gradient(113.69deg, #272438 0%, #272438 50%, rgba(8, 14, 35, 1) 100%);
    }
    60% {
      background: linear-gradient(113.69deg, #272438 0%, #272438 60%, rgba(8, 14, 35, 1) 100%);
    }
    70% {
      background: linear-gradient(113.69deg, #272438 0%, #272438 70%, rgba(8, 14, 35, 1) 100%);
    }
    80% {
      background: linear-gradient(113.69deg, #272438 0%, #272438 80%, rgba(8, 14, 35, 1) 100%);
    }
    90% {
      background: linear-gradient(113.69deg, #272438 0%, #272438 90%, rgba(8, 14, 35, 1) 100%);
    }
    100% {
      background: linear-gradient(113.69deg, #272438 0%, #272438 100%, rgba(8, 14, 35, 1) 100%);
    }
  }

  @keyframes gradient-base {
    0% {
      background: linear-gradient(113.69deg, #1c1c1c 0%, #1c1c1c 0%, rgba(8, 14, 35, 1) 100%);
    }
    10% {
      background: linear-gradient(113.69deg, #1c1c1c 0%, #1c1c1c 10%, rgba(8, 14, 35, 1) 100%);
    }
    20% {
      background: linear-gradient(113.69deg, #1c1c1c 0%, #1c1c1c 20%, rgba(8, 14, 35, 1) 100%);
    }
    30% {
      background: linear-gradient(113.69deg, #1c1c1c 0%, #1c1c1c 30%, rgba(8, 14, 35, 1) 100%);
    }
    40% {
      background: linear-gradient(113.69deg, #1c1c1c 0%, #1c1c1c 40%, rgba(8, 14, 35, 1) 100%);
    }
    50% {
      background: linear-gradient(113.69deg, #1c1c1c 0%, #1c1c1c 50%, rgba(8, 14, 35, 1) 100%);
    }
    60% {
      background: linear-gradient(113.69deg, #1c1c1c 0%, #1c1c1c 60%, rgba(8, 14, 35, 1) 100%);
    }
    70% {
      background: linear-gradient(113.69deg, #1c1c1c 0%, #1c1c1c 70%, rgba(8, 14, 35, 1) 100%);
    }
    80% {
      background: linear-gradient(113.69deg, #1c1c1c 0%, #1c1c1c 80%, rgba(8, 14, 35, 1) 100%);
    }
    90% {
      background: linear-gradient(113.69deg, #1c1c1c 0%, #1c1c1c 90%, rgba(8, 14, 35, 1) 100%);
    }
    100% {
      background: linear-gradient(113.69deg, #1c1c1c 0%, #1c1c1c 100%, rgba(8, 14, 35, 1) 100%);
    }
  }

  .preview-frame.white,
  .preview-frame.light-blue {
    .page {
      border: 1px solid rgba(8, 14, 35, 0.1);
      .top-bar {
        border-bottom: 1px solid rgba(8, 14, 35, 0.1);
      }

      .content {
        .left-side {
          .header {
            .player-logo-circle {
              border: 1px solid rgba(8, 14, 35, 0.1);
            }
            .display-name-preview {
              color: #080e23;
            }
          }
        }
      }

      .right-side {
        .entry {
          background-color: rgba(8, 14, 35, 0.1);
        }
      }
    }
  }

  .preview-text {
    font-size: 15px;
    font-weight: 600;
    color: #2e384d;
    padding: 9px 16px;
  }

  .preview-frame.white {
    .page {
      animation: gradient-white 0.45s ease-in-out;
    }
  }
  .preview-frame.light-blue {
    .page {
      animation: gradient-light-blue 0.45s ease-in-out;
    }
  }
  .preview-frame.dark-green {
    .page {
      animation: gradient-dark-green 0.45s ease-in-out;
    }
  }
  .preview-frame.blue {
    .page {
      animation: gradient-blue 0.45s ease-in-out;
    }
  }
  .preview-frame.purple {
    .page {
      animation: gradient-purple 0.45s ease-in-out;
    }
  }
  .preview-frame.base {
    .page {
      animation: gradient-base 0.45s ease-in-out;
    }
  }
`;

const themeMap: Record<string, string> = {};

themeMap.white = 'white';
themeMap.lightBlue = 'light-blue';
themeMap.darkGreen = 'dark-green';
themeMap.blue = 'blue';
themeMap.purple = 'purple';
themeMap.base = 'base';

export const PreviewSection = ({ webplayer }: { webplayer: Account.WebPlayer }): ReactElement => {
  const themeSelected = webplayer.theme ? webplayer.theme : '';
  const themeClass = themeMap[themeSelected];

  const displayName = webplayer.displayName ?? '';
  const playerLogoUrl = webplayer.logo ?? '';
  const playerBGUrl = webplayer.background ?? '';

  useEffect(() => {
    const pageTag = document.querySelectorAll('.preview-frame .entry');

    pageTag.forEach((el) => {
      el.classList.remove('active');
    });

    setTimeout(() => {
      pageTag.forEach((el) => {
        el.classList.add('active');
      });
    }, 100);
  }, [themeClass]);

  return (
    <PreviewContainer>
      <div className={`preview-frame ${themeClass}`}>
        <div className='page'>
          <div className='top-bar'>
            <div className='ball red' />
            <div className='ball yellow' />
            <div className='ball green' />
          </div>
          <div className='content'>
            <div className='left-side'>
              <div className='header'>
                {playerLogoUrl.length > 0 && (
                  <div className='player-logo-circle'>
                    <img src={playerLogoUrl} alt='player-logo' />
                  </div>
                )}
                <div className='display-name-preview'>{displayName}</div>
              </div>
              <div className='background-image-preview'>
                {playerBGUrl.length > 0 && <img src={playerBGUrl} alt='player-background' />}
              </div>
            </div>
            <div className='right-side'>
              <div className='entry' />
              <div className='entry' />
              <div className='entry' />
              <div className='entry' />
              <div className='entry' />
              <div className='entry' />
            </div>
          </div>
        </div>
      </div>
      <div className='preview-text'>Preview</div>
    </PreviewContainer>
  );
};
