import { Tooltip } from '@livecontrol/core-ui';
import type { ReactElement } from 'react';

export const RecordOnly = (): ReactElement => (
  <Tooltip
    tip={
      <>
        <strong>Private Event</strong>
        <br />
        This event will only be recorded.
      </>
    }
  >
    <span>
      <i className='fas fa-eye-slash' />
    </span>
  </Tooltip>
);
