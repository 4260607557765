import { Phone } from '@livecontrol/locale-us';
import { Matrix } from '@livecontrol/scheduler/components';
import type { Account } from '@livecontrol/scheduler/model';
import React from 'react';
import { useHistory } from 'react-router';

import S = Matrix.Stack;
import Card = S.Card;

interface Props {
  account: Account;
  onEdit: (subUser: Account.SubUser) => void;
  onDelete: (subUser: Account.SubUser) => void;
  onResendInvite: (subUser: Account.SubUser) => void;
  availableLocationsCount: number;
  showAccountHolder: boolean;
}

export const Stack = ({
  account,
  onEdit,
  onDelete,
  onResendInvite,
  availableLocationsCount,
  showAccountHolder
}: Props): React.ReactElement => {
  const { subUsers } = account;

  const history = useHistory();

  interface SubUserInfo {
    subUserInfo: Account.SubUser;
    locationsNamesToText?: string;
  }

  const subUsersInfo: SubUserInfo[] = [];

  if (subUsers.length) {
    let subUserLocationsToText = '';

    subUsers.forEach((aSubUser) => {
      const locations: string[] = [];

      if (
        aSubUser.permissions.billing ||
        aSubUser.permissions.webplayer ||
        aSubUser.permissions.destinations ||
        aSubUser.permissions.organizationAccountInfo ||
        aSubUser.permissions.admin
      ) {
        subUserLocationsToText = 'All Locations';
      } else if (aSubUser.sub_user_locations?.length) {
        aSubUser.sub_user_locations.forEach((aLocation) => {
          if (
            (aLocation.activityLog ||
              aLocation.contentManagment ||
              aLocation.eventManagment ||
              aLocation.productionNotes ||
              aLocation.viewAndDownload) &&
            !locations.includes(aLocation.location.name)
          ) {
            locations.push(aLocation.location.name);
          }
        });

        subUserLocationsToText =
          locations.length === availableLocationsCount ? 'All Locations' : locations.join(' ');
      }

      subUsersInfo.push({ subUserInfo: aSubUser, locationsNamesToText: subUserLocationsToText });
    });
  }

  const handleRedirect = (subUserId: number): void => {
    history.push(`/account/users/permissions-user?user_selected=${subUserId}`);
  };

  return (
    <S>
      {showAccountHolder && (
      <Card>
        <Card.Body>
          <Card.Row label='Name'>{account.accountOwnerName}</Card.Row>
          <Card.Row label='Email'>{account.accountOwnerEmail}</Card.Row>
          <Card.Row label='Phone'>
            {Phone.normalize(account.phone)?.format({ parenthetical: true, separator: '-' }) ??
              account.phone}
          </Card.Row>
          <Card.Row label='Prefered Contanct'>All</Card.Row>
          <Card.Row label='Location'>Account Owner - All Permissions Granted</Card.Row>
        </Card.Body>
        <Card.Footer>&nbsp;</Card.Footer>
      </Card>
      )}

      {subUsersInfo.map((subUser) => (
        <Card key={`user${subUser.subUserInfo.id}`}>
          <Card.Body>
            <Card.Row label='Name'>{subUser.subUserInfo.fullName}</Card.Row>
            <Card.Row label='Email'>{subUser.subUserInfo.email}</Card.Row>
            <Card.Row label='Phone'>
              {Phone.normalize(subUser.subUserInfo.phone)?.format({
                parenthetical: true,
                separator: '-'
              }) ?? subUser.subUserInfo.phone}
            </Card.Row>
            <Card.Row label='Prefered Contanct'>
              {subUser.subUserInfo.preferredContactMethod}
            </Card.Row>
            <Card.Row label='Locations'>
              {subUser.locationsNamesToText && subUser.locationsNamesToText.length > 0 ? (
                <button
                  type='button'
                  className='x-pseudo text-primary'
                  onClick={(): void => {
                    handleRedirect(subUser.subUserInfo.id);
                  }}
                >
                  Edit Locations
                </button>
              ) : (
                <button
                  type='button'
                  className='x-pseudo text-primary'
                  onClick={(): void => {
                    handleRedirect(subUser.subUserInfo.id);
                  }}
                >
                  + Add Locations
                </button>
              )}
            </Card.Row>
          </Card.Body>
          <Card.Footer className='font-size-13px'>
            <button
              type='button'
              className='x-pseudo text-primary'
              onClick={(): void => {
                onResendInvite(subUser.subUserInfo);
              }}
            >
              Reset Password
            </button>

            <span className='mx-8px text-light'>|</span>

            <button
              type='button'
              className='x-pseudo text-primary'
              onClick={(): void => {
                onEdit(subUser.subUserInfo);
              }}
            >
              Edit
            </button>

            <span className='mx-8px text-light'>|</span>

            <button
              type='button'
              className='x-pseudo text-danger'
              onClick={(): void => {
                onDelete(subUser.subUserInfo);
              }}
            >
              Delete
            </button>
          </Card.Footer>
        </Card>
      ))}
    </S>
  );
};
