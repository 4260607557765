import type { Theme } from '@livecontrol/core-ui';
import { Num, Str } from '@livecontrol/core-utils';
import cx from 'classnames';
import React from 'react';
import { withTheme } from 'styled-components';

const DEFAULT_SIZE = 16;

export const LcIcon = withTheme((props: LcIcon.Props): React.ReactElement => {
  const size = Num.normalize(props.size, { positive: true }) ?? DEFAULT_SIZE;
  const secondaryColor = Str.normalize(props.secondaryColor) ?? 'rgb(65, 122, 244)';

  const { duoTone, theme } = props;

  return (
    <svg
      className={cx('svg-inline--fa', props.className)}
      width={`${size}px`}
      height={`${size}px`}
      viewBox='0 0 70.000000 45.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,45.000000) scale(0.100000,-0.100000)'
        fill={props.color ?? theme.colors.dark.toString()}
        stroke='none'
      >
        <path
          d='M431 435 c-24 -8 -61 -31 -82 -51 -181 -176 31 -462 258 -349 73 37
     78 46 44 79 l-29 28 -31 -23 c-41 -30 -119 -32 -159 -3 -83 61 -78 185 10 230
     44 23 100 21 144 -5 l37 -22 23 22 c13 12 24 27 24 33 -1 28 -107 77 -164 75
     -17 0 -51 -7 -75 -14z'
        />
        <path
          fill={duoTone ? secondaryColor : props.color ?? theme.colors.dark.toString()}
          d='M0 230 l0 -210 130 0 130 0 0 40 0 40 -85 0 -85 0 0 170 0 170 -45 0
     -45 0 0 -210z'
        />
      </g>
    </svg>
  );
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace LcIcon {
  export type Props = Theme.Props & {
    className?: string;
    size?: number | string;
    duoTone?: boolean;
    color?: string;
    secondaryColor?: string;
  };
}
