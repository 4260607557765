import { useApolloClient } from '@apollo/client';
import { Scheduler } from '@livecontrol/scheduler/store';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Store } from '../../store';

export const useYouTubeAuth = (code: string | null): { error: unknown; loading: boolean } => {
  const account = Store.Account.useAccount();
  const history = useHistory();
  const apolloClient = useApolloClient();

  const [mutation, { loading, error }] = Scheduler.Destination.useYouTubeAuthorizationCallback();

  useEffect(() => {
    if (code) {
      void mutation({
        account,
        code
      }).then((value) => {
        if (value?.youtubeAuthorizationCallback.authorization) {
          history.push('/destinations/add/youtube');
        }
      });
    }
  }, [code, apolloClient, history, account, mutation]);

  return { error, loading };
};

export const useFacebookAuth = (
  code: string | null
): { error: string | false; loading: boolean } => {
  const account = Store.Account.useAccount();
  const history = useHistory();
  const apolloClient = useApolloClient();

  const [mutation, { loading }] = Scheduler.Destination.useFacebookAuthorizationCallback();

  const [error, setError] = useState<string | false>(false);

  useEffect(() => {
    if (code) {
      mutation({
        account,
        code
      })
        .then((value) => {
          if (value?.facebookAuthorizationCallback.authorization) {
            history.push('/destinations/add/facebook');
          }
        })
        .catch((): void => {
          setError('Error trying to Authenticate Facebook connection.');
        });
    }
  }, [code, apolloClient, history, account, mutation]);

  return { error, loading };
};
