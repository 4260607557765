import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Obj } from '@livecontrol/core-utils';
import type { EventValidator } from '@livecontrol/scheduler/validator';
import { useEffect, useState } from 'react';
import type { QueryResult } from '../graphql';

export const QUERY = gql`
  query GetContactRoles($input: GetContactRolesInput!) {
    getContactRoles(input: $input) {
      id
      onsite_technical
      onsite_technical_priority
      location {
        id
      }
      user {
        id
        email
        first_name
        last_name
      }
    }
  }
`;

interface TVariables {
  input: {
    client_id?: number;
    onsite_technical?: boolean;
  };
}

interface TData {
  getContactRoles: [
    {
      id: number;
      onsite_technical: boolean;
      onsite_technical_priority: number;
      location: { id: string };
      user: { id: number; email: string; first_name: string; last_name: string };
    }
  ];
}

interface Args {
  clientId: number;
}

export const useGetTechnicalContacts = (
  args: Args
): QueryResult<EventValidator.EventTechnicalContact[], 'contacts'> => {
  const [error, setError] = useState<Error | undefined>();
  const [contacts, setContacts] = useState<EventValidator.EventTechnicalContact[] | undefined>();

  /* eslint-disable react-hooks/rules-of-hooks */

  const variables = { input: { client_id: args.clientId, onsite_technical: true } };

  // Validate the input
  if (!Obj.isHydrated(variables)) {
    return { loading: false, error: Exception.KABOOM };
  }

  // Prepare the query
  const { loading, data } = useQuery<TData, TVariables>(QUERY, {
    variables,
    onError() {
      setError(Exception.KABOOM);
    }
  });

  // When available, parse server response
  useEffect(() => {
    const contacts_: EventValidator.EventTechnicalContact[] = [];
    let error_;

    if (data) {
      data.getContactRoles.forEach((element) => {
        const aContact: EventValidator.EventTechnicalContact = {
          id: element.id,
          locationId: element.location.id,
          name: `${element.user.first_name} ${element.user.last_name}`,
          email: element.user.email,
          onsiteTechnicalPriority: element.onsite_technical_priority ?? 0
        };

        contacts_.push(aContact);
      });
    }

    setContacts(contacts_);
    setError(error_);
  }, [data]);

  return { loading, error, contacts };
};
