/* eslint-disable @typescript-eslint/no-unnecessary-qualifier */
import { Enum, Num, Obj } from '@livecontrol/core-utils';
import type { DateTime, Duration } from 'luxon';
import type { Asset } from './asset';
import type { Destination } from './destination';
import type { EventFeedback } from './feedback';
import type { Location } from './location';

export interface Event {
  id: Event.ID;
  assets?: Asset[];
  description?: string;
  backgroundURL?: string;
  destinations?: Destination[];
  end: DateTime;
  location: Location;
  eventFeedback?: EventFeedback;
  operatorNotes?: string;
  password?: string;
  requireRegistration: boolean;
  showViewers: boolean;
  registration: boolean;
  liveChat: boolean;
  redirectUrl?: string;
  phase: Event.Phase;
  private: boolean;
  production: Event.Production;
  recordOnly: boolean;
  repeat?: Event.Repeat;
  clientContactDetails?: Event.ClientContactDetails;
  simulatedLiveDetails?: Event.SimulatedLiveDetails;
  start: DateTime;
  title: string;
  zoomDetails?: Event.ZoomDetails;
  cueSheetURL?: string;
  type: string; // 'cb1' | 'cb2' | 'cb3' | 'regular';
  redirectUrlSettings?: Event.RedirectUrlSettings;
  mobileKitData?: Event.MobileKitData;
  isMobileKit?: boolean;
  receiveIsoRecordings?: boolean;
  useIntroAndOutroSlides?: boolean;
  technicalContactSettings?: Event.TechnicalContactSettings;
  linkedEventId?: Event.ID;
  linkedEventInfo?: Event;
}

export namespace Event {
  export type ID = number;
  export type Like = Event | ID | string;

  export const toId = (event?: Like): ID | undefined =>
    Num.normalize(Obj.isObject<Event>(event) ? event.id : event, { positive: true });

  export const Constraints = {
    DESCRIPTION_MAX_LENGTH: 5e3,
    MAX_DESTINATIONS: 4,
    NOTES_MAX_LENGTH: 255,
    TITLE_MAX_LENGTH: 255
  };

  export enum EventType {
    Mobile = 'Mobile',
    MobileKit = 'Mobile Kit',
    Test = 'Test',
    Regular = 'regular',
    CB1 = 'cb1',
    CB2 = 'cb2',
    CB3 = 'cb3',
    Produced = 'Produced',
    Static = 'Static',
    Simulated = 'Simulated'
    /*
     * SimLive = 'SimLive',
     */
  }

  export namespace EventTypeForFilters {
    export const keys = {
      Produced: 'Produced',
      Static: 'Static',
      Test: 'Test',
      Simulated: 'Simulated',
      Mobile: 'Mobile',
      MobileKit: 'Mobile Kit'
    };

    export type Key = keyof typeof keys;
  }

  export namespace EventType {
    export const keys = {
      Mobile: 'Mobile',
      MobileKit: 'Mobile Kit',
      Produced: 'Produced',
      Static: 'Static',
      Test: 'Test',
      regular: 'Regular',
      cb1: 'Client Booking 1',
      cb2: 'Client Booking 2',
      cb3: 'Client Booking 3',
      Simulated: 'Simulated'
    };

    export type Key = keyof typeof keys;
  }

  export enum Production {
    Produced = 1,
    Static = 2,
    Simulated_Live = 3,
    Client_Booking = 4,
    Mobile = 5,
    Test = 6,
    Mobile_Kit = 7
  }

  export namespace Production {
    export const normalize = (value: unknown): Production | undefined =>
      Enum.normalize(Production, value);

    export const stringValues = new Map<Production, string>([
      [Production.Produced, 'Produced'],
      [Production.Static, 'Static'],
      [Production.Simulated_Live, 'Simulated Live'],
      [Production.Client_Booking, 'Client Booking'],
      [Production.Mobile, 'Mobile'],
      [Production.Test, 'Test']
    ]);

    export const computeCreditsChargedForProducedEvent = ({
      duration,
      overTimeLimitInMinutes,
      overTimeLimitCharge
    }: {
      duration: Duration;
      overTimeLimitInMinutes: number;
      overTimeLimitCharge: number;
    }): number => (duration.as('minutes') > overTimeLimitInMinutes ? overTimeLimitCharge : 1);

    export const toString = (production: Production): string => stringValues.get(production)!;
  }

  export enum Phase {
    Upcoming = 1,
    Live = 2,
    Past = 3
  }

  export namespace Phase {
    export const normalize = (value: unknown): Phase | undefined => Enum.normalize(Phase, value);
  }

  export enum Interval {
    Day = 1,
    Week = 2,
    Month = 3
  }

  export namespace Interval {
    export const normalize = (value: unknown): Interval | undefined =>
      Enum.normalize(Interval, value);
  }

  export enum DayOfWeek {
    Sunday = 1,
    Monday = 2,
    Tuesday = 3,
    Wednesday = 4,
    Thursday = 5,
    Friday = 6,
    Saturday = 7
  }

  export namespace DayOfWeek {
    export const normalize = (value: unknown): DayOfWeek | undefined =>
      Enum.normalize(DayOfWeek, value);
  }

  export interface Repeat {
    value: number;
    interval: Interval;
    days: DayOfWeek[];
    until?: Date | number;
  }

  export interface ClientContactDetails {
    name?: string;
    email?: string;
    phoneNumber?: string;
  }

  export interface ZoomDetails {
    streamZoom?: boolean;
    meetingId?: string;
    password?: string;
  }

  export interface SimulatedLiveDetails {
    fileName?: string;
    assetId?: number;
    assetUrl?: string;
  }

  export interface RedirectUrlSettings {
    enabled?: boolean;
    url?: string;
  }

  export interface MobileKitData {
    title?: string;
    description?: string;
    location?: string;
    start?: DateTime;
    end?: DateTime;
  }

  export interface TechnicalContactSettings {
    enabled?: boolean;
    contactId?: number;
  }
}
