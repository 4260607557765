import { DateTime } from 'luxon';
import React from 'react';
import { Form } from '../../../../components';

export const StartTime = ({
  errors,
  disabled,
  timezone,
  leadTimeWarning
}: StartTimeProps): React.ReactElement => (
  <Form.Group
    name='startTime'
    label={<label>Start Time {timezone && <span>({DateTime.local().setZone(timezone).toFormat('ZZZZ')})</span>}</label>}
    className={` d-flex flex-column custom-input time-input ${errors.startTime ? 'has-errors' : ''}`}
    error={
      errors.startTime ? (
        errors.startTime
      ) : leadTimeWarning ? (
        <p className='text-danger font-size-12px font-weight-normal'>
          Start time is inside the required lead time. This event will default to a static event.
          Please finish scheduling the event and then call or email Support if you&apos;d like to change
          it to a Produced event. Late requests are not guaranteed
        </p>
      ) : disabled ? (
        <p className='text-muted font-size-12px font-weight-normal'>
          Start time cannot be changed so close to the event start time.
        </p>
      ) : undefined
    }
    controlProps={{
      disabled
    }}
    control={Form.Time}
    wrapperClass = 'input-wrapper'
    rightIconClass = 'icon clock'
  />
);

export interface StartTimeProps {
  errors: { startTime?: string; leadTime?: string };
  disabled?: boolean;
  leadTimeWarning?: boolean;
  timezone?: string;
}
