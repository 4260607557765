import { EventValidator } from './types';

export const validateEventType = (
  newEventInfo: EventValidator.EventInfo
): EventValidator.Errors => {
  const errors: EventValidator.Errors = {};
  const type = EventValidator.ProductionType.normalize(newEventInfo.type);

  if (!type) {
    errors.type = EventValidator.ErrorMessages.TYPE_INVALID;
  }

  return errors;
};
