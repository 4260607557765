/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Modal } from '@livecontrol/scheduler/components';
import type { User } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { FormikContextType } from 'formik';
import { useFormik } from 'formik';

export interface Props {
  onSuccess?: (value: Value) => void;
  initialValues?: Value;
  me: User;
}

export interface Value {
  // filter(arg0: (productionNote: any) => boolean): unknown;
  camera_movements_preference?: number;
  audience_shots?: number;
  close_up_shots?: number;
  location_id?: string;
  wide_venue_shots?: number;
  detailed_venue_shots?: number;
  event_ending_preference?: number;
  allLocation?: boolean;
}

interface Form {
  formik: FormikContextType<Value>;
  loading: boolean;
}

interface UpdateProductionNotes {
  category: string;
  content: string;
  option: number;
}

export const useForm = ({ initialValues, onSuccess, me }: Props): Form => {
  const [createProductionNotes, { loading }] = Scheduler.ProductionNote.useProductionNotes();
  const [mutation, { loading:  loadingClientId}] = Scheduler.ProductionNote.useProductionNotesClientId();
  const { error: errorModal } = Modal.usePrompt();

  // eslint-disable-next-line @typescript-eslint/ban-types
  const validate = (values: Value): {} => {
    const errors: {
      camera_movements_preference?: string;
      audience_shots?: string;
      close_up_shots?: string;
      wide_venue_shots?: string;
      event_ending_preference?: string;
      detailed_venue_shots?: string;
    } = {};

    if (!values.camera_movements_preference) {
      errors.camera_movements_preference = 'Required';
    }

    if (!values.audience_shots) {
      errors.audience_shots = 'Required';
    }

    if (!values.close_up_shots) {
      errors.close_up_shots = 'Required';
    }

    if (!values.wide_venue_shots) {
      errors.wide_venue_shots = 'Required';
    }

    if (!values.detailed_venue_shots) {
      errors.detailed_venue_shots = 'Required';
    }

    if (!values.event_ending_preference) {
      errors.event_ending_preference = 'Required';
    }

    return errors;
  };

  const formik = useFormik<Value>({
    initialValues: { ...initialValues },
    validate,
    onSubmit(value: Value): void {
      const valuesNames = Object.entries(value);
      const notes: UpdateProductionNotes[] = [];

      valuesNames.forEach((valueName) => {

        if(valueName[0] !== 'location_id' && valueName[0] !== 'allLocation') {

            const noteToPush = {
              category: valueName[0],
              content: '',
              option: value[valueName[0]]
            };

            notes.push(noteToPush);
          }

      });

      if(value.allLocation){
        const input = {
          client_id: me.id,
          notes
        };

        if (!loading) {
          void mutation({ input })
            .then(() => {
              onSuccess?.(value);
            })
            .catch(() => {
              errorModal({
                title: 'Oops!',
                content: 'Something went wrong. Please try again later'
              });
            });
        }
      } else {
        const input = {
          location_id: value.location_id!,
          notes
        };

        if (!loading) {
          void createProductionNotes({ input })
            .catch(() => {
              errorModal({
                title: 'Oops!',
                content: 'Something went wrong. Please try again later'
              });
            });
        }
      }


    }
  });

  return {
    formik,
    loading: loading || loadingClientId
  };
};
