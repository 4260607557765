import type { Event, User } from '@livecontrol/scheduler/model';
import _ from 'lodash';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Store } from '../../../store';
import { EventData } from './event-data';

const EventDataContainer = styled.div`
  padding: 14px 0px 30px;
`;

interface Props {
  transformed: readonly Event[];
}

export const EventsSection = ({ transformed }: Props): ReactElement => {
  const transformedCopy = transformed.map((event) => ({ ...event }));
  const me = Store.User.useMe();

  const adminUser: User.admin = me as User.admin;

  const showRequestBtn =
    adminUser.userGlobalSettings.clientTier === 'pro' ||
    adminUser.userGlobalSettings.clientTier === 'standard';

  const linkedMobileKitEvents = transformedCopy.filter(
    (event) => event.linkedEventId && event.isMobileKit && event.phase !== 2
  );

  const deleteEventsIds = new Set<number>();

  linkedMobileKitEvents.forEach((event) => {
    let itsProducedEvent = transformedCopy.find(
      (produced) => produced.id === event.linkedEventId && produced.phase !== 2
    );

    if (itsProducedEvent) {
      if (!Object.isExtensible(itsProducedEvent)) {
        itsProducedEvent = { ...itsProducedEvent };
      }

      itsProducedEvent.linkedEventInfo = { ...event };
      deleteEventsIds.add(event.id);
    }
  });

  const finalEvents = transformedCopy.filter((event) => !deleteEventsIds.has(event.id));

  return (
    <EventDataContainer className='d-flex flex-wrap align-items-start justify-content-center'>
      <div className='w-100 d-flex flex-column align-items-start'>
        {finalEvents.map((event) => (
          <EventData key={event.id} event={event} showRequestBtn={showRequestBtn} />
        ))}
      </div>
    </EventDataContainer>
  );
};
