import cx from 'classnames';
import React from 'react';

export const Descriptor = ({
  title,
  titleClassName,
  description,
  descriptionClassName,
  className,
  moreDetails,
  moreDetailsClassName,
  children,
  style,
  centerLabelsV,
  titleStyle
}: Descriptor.Props): React.ReactElement => (
  <div className={cx(className)} style={{ width: '100%', ...style }}>
    <div className={centerLabelsV ? 'd-flex align-items-center' : ''}>
      <div
        className={cx(
          titleClassName,
          titleClassName?.includes('mb-') ? '' : 'mb-4px',
          titleClassName?.includes('font-size-') ? '' : 'font-size-14px'
        )}
        style={{ ...titleStyle }}
      >
        {title}
      </div>
      {description && (
        <div
          className={cx(
            descriptionClassName,
            !moreDetails ? 'mb-8px' : 'mb-4px',
            descriptionClassName?.includes('font-size-') ? '' : 'font-size-14px'
          )}
        >
          {description}
        </div>
      )}

      {moreDetails && (
        <div
          className={cx(
            moreDetailsClassName,
            'mb-8px',
            moreDetailsClassName?.includes('font-size-') ? '' : 'font-size-14px'
          )}
        >
          {moreDetails}
        </div>
      )}
    </div>
    {children}
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Descriptor {
  export interface Props {
    title: React.ReactNode;
    description?: React.ReactNode;
    moreDetails?: React.ReactNode;
    descriptionClassName?: string;
    className?: string;
    titleClassName?: string;
    moreDetailsClassName?: string;
    children?: React.ReactNode;
    style?: React.CSSProperties;
    centerLabelsV?: boolean;
    titleStyle?: React.CSSProperties;
  }
}
