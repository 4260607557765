import { gql, useMutation } from '@apollo/client';
import type { Location } from '@livecontrol/scheduler/model';
import type { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import type { MutationResult } from '../graphql';

interface TVariables {
  input: {
    installation_street_address_1?: string;
    installation_street_address_2?: string;
    installation_city?: string;
    installation_state?: string;
    installation_zip_code?: string;
    shipping_street_address_1?: string;
    shipping_street_address_2?: string;
    shipping_city?: string;
    shipping_state?: string;
    shipping_zip_code?: string;
    handler_installation?: string;
    install_date_request?: DateTime;
    ideal_site_visit_date?: DateTime;
    ideal_first_stream_date?: DateTime;
    additional_information?: string;
    shippingContactId?: number;
    onboardingMainContactId?: number;
    onboardingTechnicalContactId?: number;
    location_id?: string;
  };
}

interface TData {
  upsertOnboardingLocationAddress: {
    id: string;
  };
}

export const MUTATION = gql`
  mutation UpsertOnboardingLocationAddress($input: UpsertOnboardingLocationAdressInput!) {
    upsertOnboardingLocationAddress(input: $input) {
      id
    }
  }
`;

export const useUpdateOnboardingLocationAddress = (): [
  (args: Location.OnboardingLocationAddress) => Promise<boolean>,
  MutationResult<boolean, 'success'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [success, setSuccess] = useState<boolean>();

  return [
    useCallback(
      async (args: Location.OnboardingLocationAddress): Promise<boolean> => {
        let success_ = false;

        try {
          // Parse the input arguments

          const input = {
            installation_street_address_1: args.installationStreetAddress,
            installation_street_address_2: args.installationStreetAddress2 ?? '',
            installation_city: args.installationCity,
            installation_state: args.installationState,
            installation_zip_code: args.installationZipCode,
            shipping_street_address_1: args.shippingStreetAddress,
            shipping_street_address_2: args.shippingStreetAddress2 ?? '',
            shipping_city: args.shippingCity,
            shipping_state: args.shippingState,
            shipping_zip_code: args.shippingZipCode,
            handler_installation: args.handlerInstallation,
            install_date_request: args.installDateRequest ?? undefined,
            ideal_site_visit_date: args.idealSiteVisitDate ?? undefined,
            ideal_first_stream_date: args.idealFirstStreamDate ?? undefined,
            additional_information: args.additionalInformation ?? '',
            location_id: args.locationId
          };

          // Execute the GraphQL mutation
          const { data } = await mutation({
            variables: { input }
          });

          success_ = data?.upsertOnboardingLocationAddress.id
            ? data.upsertOnboardingLocationAddress.id.length > 0
            : false;
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setSuccess(success_);

        return success_;
      },
      [mutation, setError, setSuccess]
    ),
    {
      success,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
