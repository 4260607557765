import { gql } from '@apollo/client';
import { Bool, Num, Obj, Str } from '@livecontrol/core-utils';
import { Asset, Event } from '@livecontrol/scheduler/model';
import { DateTime, Duration } from 'luxon';
import type { PartialDeep } from 'type-fest';

import Status = Asset.Mux.Status;

const toMux = (record: Record): Asset.Mux | undefined => {
  let mux;

  const candidate = {
    assetId: Str.normalize(record.mux_asset_id),
    playbackId: Str.normalize(record.mux_playback_id),
    status: Status.normalize(record.mux_status)
  };

  if (Obj.isHydrated(candidate)) {
    mux = {
      ...candidate,
      streamUrl: Str.normalize(record.stream_url),
      downloadUrl: Str.normalize(record.download_url),
      thumbnailUrl:
        record.thumbnail_url && !record.thumbnail_url.includes('image.mux.com')
          ? record.thumbnail_url
          : candidate.status === Status.Ready
          ? Asset.Mux.thumbnail(candidate.playbackId)
          : undefined
    };
  }

  return mux;
};

export type Record = PartialDeep<{
  id: number;
  created_at: string;
  description: string;
  download_url: string;
  duration: number;
  manual_client_id: number;
  hidden: boolean;
  was_ever_public: boolean;
  mux_asset_id: string;
  mux_playback_id: string;
  mux_status: string;
  scheduled_event_id: string;
  scheduled_time: string;
  stream_url: string;
  thumbnail_url: string;
  scheduled_event_password: string;
  title: string;
  stream: {
    location_id: string;
  };
  location_id: string;
}>;

export type MuxUploadResponse = Partial<{
  upload_id: string;
  mux_upload_url: string;
}>;

export type MuxUploadedAsset = Partial<{
  asset_id: number;
  mux_asset_url: string;
}>;

export const __typename = 'AssetResponse';

export const normalize = (record?: Record): Asset | undefined => {
  let asset;

  if (record) {
    const ts = record.scheduled_time ?? record.created_at;

    // Required fields
    const candidate = {
      id: Asset.toId(record.id),
      visibility: Bool.normalize(record.hidden)
        ? Asset.Visibility.Private
        : Asset.Visibility.Public,
      timestamp: ts ? DateTime.fromISO(ts, { zone: 'utc' }) : undefined
    };

    if (Obj.isHydrated(candidate)) {
      const seconds = Num.normalize(record.duration);

      asset = {
        ...candidate,
        title: Str.normalize(record.title) ?? `Untitled Event ${candidate.id}`,
        event: Event.toId(record.scheduled_event_id),
        description: Str.normalize(record.description),
        eventPassword: Str.normalize(record.scheduled_event_password),
        wasEverPublic: Bool.normalize(record.was_ever_public),
        locationId: Str.normalize(record.stream?.location_id),
        duration: seconds
          ? Duration.fromObject({
              seconds
            })
          : undefined,
        mux: toMux(record)
      };
    }
  }

  return asset;
};

export const normalizeMuxUploadResponse = (
  muxAssetUploadResponse?: MuxUploadResponse
): Asset.MuxUpload | undefined => {
  let muxAssetUpload;

  if (muxAssetUploadResponse) {
    // Required fields
    const candidate = {
      uploadId: Str.normalize(muxAssetUploadResponse.upload_id),
      muxUploadUrl: Str.normalize(muxAssetUploadResponse.mux_upload_url)
    };

    if (Obj.isHydrated(candidate)) {
      muxAssetUpload = {
        ...candidate
      };
    }
  }

  return muxAssetUpload;
};

export const normalizeMuxUploadedAsset = (
  muxAssetUploadResponse?: MuxUploadedAsset
): Asset.MuxUploadedAsset | undefined => {
  let muxAssetUpload;

  if (muxAssetUploadResponse) {
    // Required fields
    const candidate = {
      assetId: Asset.toId(muxAssetUploadResponse.asset_id),
      muxAssetUrl: Str.normalize(muxAssetUploadResponse.mux_asset_url)
    };

    if (Obj.isHydrated(candidate)) {
      muxAssetUpload = {
        ...candidate
      };
    }
  }

  return muxAssetUpload;
};

export const toEventMap = (records?: Record[]): Map<Event.ID, Asset[]> => {
  const map = new Map<Event.ID, Asset[]>();

  records?.forEach((record: Record): void => {
    const asset = normalize(record);

    if (asset?.event) {
      const arr = map.get(asset.event);

      if (!arr) {
        map.set(asset.event, [asset]);
      } else {
        arr.push(asset);
      }
    }
  });

  return map;
};

export const BaseAssetResponse = gql`
  fragment BaseAssetResponse on AssetResponse {
    id
    manual_client_id
    description
    hidden
    was_ever_public
    scheduled_event_id
    title
    created_at
    scheduled_time
    scheduled_event_password
  }
`;

export const FullAssetResponse = gql`
  fragment FullAssetResponse on AssetResponse {
    ...BaseAssetResponse

    download_url
    duration
    mux_asset_id
    mux_playback_id
    mux_status
    stream_url
    thumbnail_url
    stream {
      location_id
    }
  }

  ${BaseAssetResponse}
`;
