import { gql } from '@apollo/client';

export const GetOperators = gql`
  query GetUsers {
    getUsers(input: { role: 4 }) {
      id
      first_name
      last_name
    }
  }
`;

export const AssignOperator = gql`
  mutation AssignOperatorToEvent($user: Float!, $event: Float!) {
    assignOperatorToEvent(assignEvent: { user: $user, event: $event }) {
      id
      confirmation
      user {
        id
      }
    }
  }
`;

export const GetOperatorEvents = gql`
  query GetOperatedEvents($user: Float!) {
    getOperatedEvents(searchOperatedEvents: { user: $user }) {
      id
      confirmation
      event {
        id
        name
        start_time
        end_time
        location {
          id
          name
          user {
            id
            organization_name
          }
        }
      }
      date_created
    }
  }
`;

export const ConfirmOperator = gql`
  mutation ConfirmToOperateEvent($id: Float!, $confirmation: String!) {
    confirmToOperateEvent(confirmEvent: { id: $id, confirmation: $confirmation }) {
      id
      confirmation
    }
  }
`;
