import { Modal } from '@livecontrol/scheduler/components';
import type { ReactElement } from 'react';
import styled from 'styled-components';

const ModalPrompt = styled(Modal.Prompt)`
  .modal-header {
    margin-bottom: 0px !important;
  }
`;

interface Props {
  show: boolean;
  loading: boolean;
  onClose: () => void;
  onRevert: () => void;
  onSave: () => void;
}

export const FormWarning = ({ show, loading, onClose, onRevert, onSave }: Props): ReactElement => {
  const onOkay = (): void => {
    onSave();
  };

  return (
    <ModalPrompt
      {...{
        show,
        title: <h1 className='font-size-20px font-weight-bold mt-16px'>Save changes?</h1>,
        icon: (
          <img src='assets/icons/Exclamation-red.svg' alt='exclamation' style={{ width: 40 }} />
        ),
        noButtons: true,
        onCancel: onClose
      }}
    >
      <p className='text-center text-gray-light mb-24px pt-4px font-size-bold'>
        To save your changes, click Save. To move away from this page without saving your changes,
        click Revert.
      </p>
      <Modal.Footer>
        <Modal.Submit
          onClick={onOkay}
          className='pt-16px pb-16px pl-40px pr-40px'
          loading={loading}
        >
          Save
        </Modal.Submit>
        <button
          type='button'
          className='btn bg-transparent font-weight-bold text-gray-light font-size-13px'
          onClick={onRevert}
        >
          Revert
        </button>
      </Modal.Footer>
    </ModalPrompt>
  );
};
