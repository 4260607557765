import { usePreferences } from './use-preferences';
import { useSubscribe } from './use-subscribe';
import { useUnsubscribe } from './use-unsubscribe';

export const Preferences = {
  Notification: {
    usePreferences,
    useSubscribe,
    useUnsubscribe
  }
};
