import type { ApolloError, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Obj, Str } from '@livecontrol/core-utils';
import { Account, Destination } from '@livecontrol/scheduler/model';
import { useCallback, useState } from 'react';
import { Errors } from '../graphql';
import type { MutationResult } from '../graphql';
import * as Dbx from './dbx';
import { QUERY as destinationsQuery } from './use-destinations';

interface TVariables {
  clientId: string;
  name: string;
  destination_id: string;
  authorization_id: string;
  type: string;
}

interface TData {
  createFacebookIntegration: Dbx.Record;
}

interface Args {
  account: Account.Like;
  destination: Destination.Like;
  name: string;
  type: string;
  authorization: string;
}

const MUTATION = gql`
  mutation AddFacebookIntegration(
    $clientId: String!
    $name: String!
    $type: String!
    $destination_id: String!
    $authorization_id: String!
  ) {
    createFacebookIntegration(
      clientId: $clientId
      integration: {
        name: $name
        type: $type
        destination_id: $destination_id
        authorization_id: $authorization_id
      }
    ) {
      ...StandardDestinationResponse
    }
  }
  ${Dbx.StandardDestinationResponse}
`;

export const useAddFacebook = (): [
  (args: Args) => Promise<Destination | undefined>,
  MutationResult<Destination, 'destination'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [destination, setDestination] = useState<Destination | undefined>();

  return [
    useCallback(
      async (args: Args): Promise<Destination | undefined> => {
        let destination_: Destination | undefined;

        try {
          // Parse the input arguments
          const input: Partial<TVariables> = {
            clientId: Account.toId(args.account)?.toString(),
            destination_id: Destination.toId(args.destination),
            name: Str.normalize(args.name),
            type: Str.normalize(args.type),
            authorization_id: Str.normalize(args.authorization)
          };

          if (!Obj.isHydrated(input)) {
            throw Errors.badRequest();
          }

          // Execute the GraphQL mutation
          const response = await mutation({
            variables: input,
            refetchQueries: [destinationsQuery]
          })
            .then(
              ({ data }: FetchResult<TData>): Dbx.Record | undefined =>
                data?.createFacebookIntegration
            )
            .catch((_error: ApolloError) => {
              throw Errors.serverError();
            });

          // Parse the server response
          if (response === undefined) {
            throw new Error('Empty response received!');
          }

          destination_ = Dbx.normalize(response);

          if (!destination_) {
            throw Errors.serverError();
          }
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setDestination(destination_);

        return destination_;
      },
      [mutation, setDestination, setError]
    ),
    {
      destination,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
