import type { ApolloCache, ApolloError, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Str } from '@livecontrol/core-utils';
import { Contact } from '@livecontrol/scheduler/model';
import { useCallback, useState } from 'react';
import type { MutationResult } from '../graphql';
import { Errors } from '../graphql';
import * as Dbx from './dbx';

interface TVariables {
  id: number;
}

interface TData {
  deleteContact: Pick<Dbx.Record, 'id'>;
}

const MUTATION = gql`
  mutation DeleteContact($id: Float!) {
    deleteContact(input: { contactId: $id }) {
      id
    }
  }
`;

type Args = Contact.Like;

export const useDelete = (): [
  (args: Args) => Promise<boolean>,
  MutationResult<boolean, 'success'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [success, setSuccess] = useState<boolean>();

  return [
    useCallback(
      async (args: Args): Promise<boolean> => {
        let success_ = false;

        try {
          // Parse the input arguments
          const id = Contact.toId(args);

          if (!id) {
            throw Errors.badRequest();
          }

          // Execute the GraphQL mutation
          await mutation({
            variables: { id },
            update(cache: ApolloCache<unknown>, { data }: FetchResult<TData>): void {
              const orphan = data?.deleteContact;

              // Evict the deleted contact from the cache
              if (orphan) {
                // @fixme - The GraphQL response is not returning the ID of the deleted contact!
                const identity = cache.identify({ id, __typename: Dbx.__typename });

                if (!identity || !cache.evict({ id: identity })) {
                  // eslint-disable-next-line no-console
                  console.error(`Unable to evict contact: ${identity ?? Str.NULL}`);
                }
              }
            }
          })
            .then(({ data }: FetchResult<TData>) => data?.deleteContact)
            .catch((_error: ApolloError) => {
              throw Errors.serverError();
            });

          success_ = true;
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setSuccess(success_);

        return success_;
      },
      [mutation, setError, setSuccess]
    ),
    {
      success,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
