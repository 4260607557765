import { Matrix } from '@livecontrol/scheduler/components';
import type { Zone } from 'luxon';
import type { ReactElement } from 'react';
import { Formatter } from '../../formatter';
import type { Event } from '../../store';

export const Attributes = ({ event, timezone }: Attributes.Props): ReactElement => (
  <>
    <Matrix.Stack.Card.Row label='ID'>{event.id}</Matrix.Stack.Card.Row>
    <Matrix.Stack.Card.Row label='Title'>{event.title}</Matrix.Stack.Card.Row>
    <Matrix.Stack.Card.Row label='Date'>
      {Formatter.datetime(event, timezone)}
    </Matrix.Stack.Card.Row>
    <Matrix.Stack.Card.Row label='Location'>{event.location.name}</Matrix.Stack.Card.Row>
    <Matrix.Stack.Card.Row label='Destination'>
      {Formatter.destinations(event)}
    </Matrix.Stack.Card.Row>
    <Matrix.Stack.Card.Row label='Type'>{Formatter.production(event)}</Matrix.Stack.Card.Row>
  </>
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Attributes {
  export interface Props {
    event: Event;
    timezone: Zone;
  }
}
