import type { ApolloError, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Obj, Str } from '@livecontrol/core-utils';
import { Account } from '@livecontrol/scheduler/model';
import type { Asset } from '@livecontrol/scheduler/model';
import { useCallback, useState } from 'react';
import { Errors } from '../graphql';
import type { MutationResult } from '../graphql';
import * as Dbx from './dbx';

interface TVariables {
  account: number;
  uploadId: string;
  title: string;
  description: string;
}

interface TData {
  getUploadedAsset?: Dbx.MuxUploadedAsset;
}

export const MUTATION = gql`
  mutation GetUploadedAsset(
    $account: Int!
    $uploadId: String!
    $title: String
    $description: String
  ) {
    getUploadedAsset(
      clientId: $account
      uploadId: $uploadId
      title: $title
      description: $description
    ) {
      asset_id
      mux_asset_url
    }
  }
`;

interface Args {
  id: Account.Like;
  uploadId: string;
  title: string;
  description: string;
}

export const useMuxAsset = (): [
  (args: Args) => Promise<{ assetDetails?: Asset.MuxUploadedAsset } | undefined>,
  MutationResult<Asset.MuxUploadedAsset, 'muxUploadedAsset'>
] => {
  const [error, setError] = useState<Error | undefined>();

  // Prepare the query
  const [mutation, { called, loading }] = useMutation<TData, TVariables>(MUTATION);

  return [
    useCallback(
      async (args: Args): Promise<{ assetDetails?: Asset.MuxUploadedAsset } | undefined> => {
        let assetDetails_;
        let error_;

        const required = {
          account: Account.toId(args.id),
          uploadId: Str.normalize(args.uploadId),
          title: Str.normalize(args.title),
          description: Str.normalize(args.description)
        };

        if (!Obj.isHydrated(required)) {
          throw Errors.badRequest();
        }

        const variables = {
          ...required
        };

        // Execute the GraphQL mutation
        const response = await mutation({ variables })
          .then(({ data }: FetchResult<TData>) => data?.getUploadedAsset)
          .catch((_error: ApolloError) => {
            throw Errors.serverError();
          });

        if (response) {
          assetDetails_ = Dbx.normalizeMuxUploadedAsset(response);

          if (!assetDetails_) {
            throw Errors.serverError();
          }
        }

        setError(error_);

        return {
          assetDetails: assetDetails_
        };
      },
      [mutation]
    ),
    {
      error,
      called,
      loading
    }
  ];
};
