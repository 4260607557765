import React from 'react';
import { Redirect } from 'react-router';
import { Store } from '../../store';
import { CreateEventWorkflow } from './create-event-workflow';
import { CreateEventContextProvider, CreateMobileInfoContextProvider } from './event-store';

export const CreateEvent = (): React.ReactElement => {
  const me = Store.User.useMe();

  if (!me.permissions.someLocationHasEventManagmentEnabled) {
    return <Redirect to='/schedule' />;
  }

  return (
    <CreateEventContextProvider>
      <CreateMobileInfoContextProvider>
        <CreateEventWorkflow />
      </CreateMobileInfoContextProvider>
    </CreateEventContextProvider>
  );
};
