import type { Account, Location } from '@livecontrol/scheduler/model';
import type { FormikContextType } from 'formik';
import type { ReactElement } from 'react';
import { AccountGlobalHolder } from './account-global-holder';
import type { AccountUsersPermission } from './account-permissions';
import { AccountUsers } from './account-users';
import type { Values } from './form';

interface Props {
  formik: FormikContextType<Values>;
  valueSelected?: Account.SubUser | undefined;
  visibility: boolean;
  account: Account;
  locations: Location[];
  setAccountUserPermissionSelected: React.Dispatch<React.SetStateAction<AccountUsersPermission[]>>;
}

export const AccountGlobal = ({
  setAccountUserPermissionSelected,
  formik,
  visibility,
  valueSelected,
  locations,
  account
}: Props): ReactElement => (
  <>
    <AccountGlobalHolder visibility={visibility} />
    {account.subUsers.map((subuser) => (
      <AccountUsers
        setAccountUserPermissionSelected={setAccountUserPermissionSelected}
        userSelected={valueSelected?.id === subuser.id}
        formik={formik}
        key={subuser.id}
        locations={locations}
        subUser={subuser}
        visibility={visibility}
      />
    ))}
  </>
);
