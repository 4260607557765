import { Loading } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import { Destination } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import cx from 'classnames';
import type { MouseEvent } from 'react';
import React, { useCallback, useState } from 'react';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';
import { DeleteModal } from '../../../components';
import { Store } from '../../../store';
import { RTMP } from '../integrations';

const StyledDestinationCard = styled.div`
  position: relative;
  width: fit-content;

  .integration-icon {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--tint-ø3);
  }

  .loadingContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--tint-ø1);
  }
  .card-selection {
    position: relative;
    cursor: default;
    width: fit-content;
  }

  .card-body {
    padding: var(--spacer-12px) var(--spacer-12px) 0px var(--spacer-12px) !important;
  }

  .icon-check-success,
  .icon-check-error {
    position: absolute;
    right: -10px;
    top: -10px;
    font-size: 17px;
    background-color: var(--white);
    border-radius: 50%;
  }

  .icon-check-error {
    color: var(--danger);
  }

  .icon-check-success {
    color: var(--primary);
  }

  .card-selection.selected {
    border-color: var(--primary);
  }

  .card-selection.deauthorized {
    border-color: var(--danger);
    color: var(--danger);
  }

  .line-height {
    line-height: 1.2;
  }

  button {
    border: 1px solid #d5daea;
    padding: 2px var(--spacer-4px);
    border-radius: var(--spacer-4px) !important;
    background-color: white;
  }

  .settings,
  .delete {
    padding: var(--spacer-4px) var(--spacer-4px) !important;
  }
`;

export const DestinationCard = ({
  destination,
  reAuthenticateDestination,
  canEditDestinations = true,
  className,
  ref: _unused,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  destination: Destination;
  reAuthenticateDestination?: (destination: Destination) => void;
  canEditDestinations?: boolean;
}): React.ReactElement => {
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [deleteDestination, { loading: deleting }] = Scheduler.Destination.useDelete();

  const destinationType = destination.service;

  const destinationName = {
    [Destination.Service.YouTube]: 'YouTube Live',
    [Destination.Service.Facebook]: 'Facebook Live',
    [Destination.Service.RTMP]: 'RTMP',
    [Destination.Service.LiveControl]: 'Live Control',
    [Destination.Service.Zoom]: 'Zoom'
  };

  const destinationImg = {
    [Destination.Service.YouTube]: 'assets/icons/youtube_destination.svg',
    [Destination.Service.Facebook]: 'assets/icons/facebook_destination.svg',
    [Destination.Service.RTMP]: 'assets/icons/rtmp_destination.svg',
    [Destination.Service.Zoom]: 'assets/icons/zoom_destination.svg'
  };

  const { deauthorized } = destination;

  const modalPrompt = Modal.usePrompt();

  const account = Store.Account.useAccount();

  const onDelete = useCallback(() => {
    deleteDestination({
      clientId: account.id.toString(),
      destinationId: destination.id
    }).catch((): void => {
      modalPrompt.error({
        title: 'Error!',
        content: 'Error trying to delete destination.'
      });
    });
  }, [account.id, deleteDestination, destination.id, modalPrompt]);

  return (
    <StyledDestinationCard {...props} className={cx('m-8px', className)}>
      <RTMP.EditRTMPModal
        id={destination.id}
        show={editModal}
        handleClose={(): void => {
          setEditModal(false);
        }}
        onDone={(): void => {
          setEditModal(false);
        }}
      />
      <DeleteModal
        show={deleteModal}
        onClose={(): void => {
          setDeleteModal(false);
        }}
        onDelete={onDelete}
        loading={deleting}
        title='Are you sure you would like to delete?'
        content='By deleting this destination it will be permanently removed from our system.'
      />
      <Card
        className={`card-selection select-disable ${
          destination.deauthorized ? 'deauthorized' : 'selected'
        }`}
      >
        <Card.Body
          className='d-flex align-items-center p-12px'
          style={{
            backgroundColor: '#F3F5F7',
            width: 'fit-content',
            borderRadius: 12
          }}
        >
          {deauthorized ? (
            <div className='icon-check-error'>
              <i className='fa fa-exclamation-circle' />
            </div>
          ) : (
            <div className='icon-check-success'>
              <i className='fa fa-check-circle' />
            </div>
          )}

          {destinationType === Destination.Service.YouTube ? (
            <img src={destinationImg[Destination.Service.YouTube]} alt='destination-type' />
          ) : destinationType === Destination.Service.Facebook ? (
            <img src={destinationImg[Destination.Service.Facebook]} alt='destination-type' />
          ) : destinationType === Destination.Service.RTMP ? (
            <img src={destinationImg[Destination.Service.RTMP]} alt='destination-type' />
          ) : destinationType === Destination.Service.Zoom ? (
            <img src={destinationImg[Destination.Service.Zoom]} alt='destination-type' />
          ) : (
            <div>desconocido</div>
          )}
        </Card.Body>
      </Card>
      <div className='d-flex flex-row justify-content-between mt-16px'>
        <div className='flex-shrink-0 mr-8px text-capitalize'>
          <div className='font-weight-bold font-size-13px'>
            {destinationName[destinationType] ? (
              destinationName[destinationType]
            ) : (
              <span className='text-red'>Unknown type</span>
            )}
          </div>
          <div className='line-height'>
            {destinationType !== Destination.Service.Zoom ? (
              <small className='text-muted'>{destination.name}</small>
            ) : null}
          </div>
        </div>
        <div className='flex-shrink-1'>
          {destinationType !== Destination.Service.Zoom && (
            <>
              {destinationType === Destination.Service.RTMP && canEditDestinations ? (
                <button
                  type='button'
                  className='settings'
                  onClick={(event: MouseEvent): void => {
                    event.stopPropagation();
                    setEditModal(true);
                  }}
                >
                  <img src='assets/icons/settings.svg' alt='settings' />
                </button>
              ) : (
                deauthorized && (
                  <button
                    type='button'
                    className='ml-4px'
                    onClick={(): void => {
                      localStorage.setItem('reconnectid', destination.id);
                      reAuthenticateDestination?.(destination);
                    }}
                  >
                    <i className='fas fa-sync' />
                  </button>
                )
              )}
              {canEditDestinations && (
                <button
                  type='button'
                  onClick={(event: MouseEvent): void => {
                    event.stopPropagation();
                    setDeleteModal(true);
                  }}
                  className='delete ml-4px'
                >
                  <img src='assets/icons/delete_black.svg' alt='trash' />
                </button>
              )}
            </>
          )}
        </div>
      </div>
      {deleting && (
        <div className='loadingContainer'>
          <Loading size={25} />
        </div>
      )}
    </StyledDestinationCard>
  );
};
