import { Form } from '@livecontrol/core-ui';
import _ from 'lodash';
import React from 'react';

export const Submit = React.forwardRef(
  (
    { children, ...props }: Form.Submit.Props,
    ref: React.Ref<HTMLButtonElement>
  ): React.ReactElement => (
    <Form.Submit
      {..._.defaults(props, {
        minimum: 'md'
      })}
      ref={ref}
    >
      {children}
    </Form.Submit>
  )
);

Submit.displayName = 'ModalSubmit';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Submit {
  export type Props = Form.Submit.Props;
}
