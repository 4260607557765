import type { ApolloError, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Obj } from '@livecontrol/core-utils';
import { Account } from '@livecontrol/scheduler/model';
import type { Asset } from '@livecontrol/scheduler/model';
import type { UpChunk } from '@mux/upchunk';
import { createUpload } from '@mux/upchunk';
import { useCallback, useState } from 'react';
import type { MutationResult } from '../graphql';
import { Errors } from '../graphql';
import * as Dbx from './dbx';

interface TVariables {
  account: number;
}

interface TData {
  createAssetUpload?: Dbx.MuxUploadResponse;
}

export const MUTATION = gql`
  mutation CreateAssetUpload($account: Int!) {
    createAssetUpload(clientId: $account) {
      upload_id
      mux_upload_url
    }
  }
`;

interface Result {
  progress: number;
  error?: Error;
  uploading: boolean;
  onAbort: () => void;
}

interface Args {
  account: Account.Like;
  file: File;
}

export const useMuxUpload = (): [
  (args: Args) => Promise<{ activeUpload?: UpChunk; uploadDetails?: Asset.MuxUpload } | undefined>,
  MutationResult<Asset.MuxUpload, 'muxUpload'> & Result
] => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0.0);
  const [error, setError] = useState<Error | undefined>();

  const onAbort = useCallback(() => {
    setUploading(false);
    setProgress(0.0);
  }, []);

  // Prepare the query
  const [mutation, { called, loading }] = useMutation<TData, TVariables>(MUTATION);

  return [
    useCallback(
      async (
        args: Args
      ): Promise<{ activeUpload?: UpChunk; uploadDetails?: Asset.MuxUpload } | undefined> => {
        let activeUpload_;
        let uploadDetails_;
        let error_;

        const required = {
          account: Account.toId(args.account),
          file: args.file
        };

        if (!Obj.isHydrated(required)) {
          throw Errors.badRequest();
        }

        const variables = {
          account: required.account
        };

        // Execute the GraphQL mutation
        const response = await mutation({ variables })
          .then(({ data }: FetchResult<TData>) => data?.createAssetUpload)
          .catch((_error: ApolloError) => {
            throw Errors.serverError();
          });

        if (response) {
          uploadDetails_ = Dbx.normalizeMuxUploadResponse(response);

          if (!uploadDetails_) {
            throw Errors.serverError();
          } else {
            try {
              setUploading(true);

              activeUpload_ = createUpload({
                endpoint: uploadDetails_.muxUploadUrl!,
                file: required.file,
                chunkSize: 5120
              });

              activeUpload_.on('error', (err: unknown) => {
                setError(<Error>err);
              });

              activeUpload_.on('progress', (uploadProgress: { detail: number }) => {
                setProgress(uploadProgress.detail);
              });

              activeUpload_.on('success', () => {
                setUploading(false);
                setProgress(0.0);
              });
            } catch (directUploadError_: unknown) {
              error_ = <Error>directUploadError_;
            }
          }
        }

        setError(error_);

        return {
          activeUpload: activeUpload_,
          uploadDetails: uploadDetails_
        };
      },
      [mutation]
    ),
    {
      progress,
      error,
      called,
      loading,
      uploading,
      onAbort
    }
  ];
};
