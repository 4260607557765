import { Glyph } from '@livecontrol/core-ui';
import { Num } from '@livecontrol/core-utils';
import { Modal } from '@livecontrol/scheduler/components';
import type { Account } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { ReactElement } from 'react';
import { Store } from '../../../../store';
import { PaymentProcessing } from './payment-processing';
import type { PaymentsModalProps } from './payments-modal';

interface Props {
  onClose: () => void;
  modalProps: PaymentsModalProps;
  confirmTotal: (result: Step.FAIL | Step.SUCCESS) => void;
  billing: Account.Billing;
}

enum Step {
  CONFIRM = 'Confirm',
  FAIL = 'Fail',
  SUCCESS = 'Success'
}

export const PaymentConfirm = ({
  modalProps,
  onClose,
  confirmTotal,
  billing
}: Props): ReactElement => {
  const account = Store.Account.useAccount();
  const [purchaseMutation, purchaseResult] = Scheduler.Account.useClientBookingPayment();

  const brandIcon =
    billing.stripe.defaultCardBrand === 'Visa' ? (
      <i className='fab fa-cc-visa font-size-24px' />
    ) : billing.stripe.defaultCardBrand === 'MasterCard' ? (
      <i className='fab fa-cc-mastercard font-size-24px' />
    ) : billing.stripe.defaultCardBrand === 'Discover' ? (
      <i className='fab fa-cc-discover font-size-24px' />
    ) : billing.stripe.defaultCardBrand === 'American Express' ? (
      <i className='fab fa-cc-amex font-size-24px' />
    ) : (
      <i className='fas fa-credit-card font-size-24px' />
    );

  return (
    <div>
      {!purchaseResult.called ? (
        <>
          <Modal.Header closeButton>
            <Glyph>
              <i className='far fa-coins' />
            </Glyph>

            <Modal.Title className='mb-16px'>Confirm Payment</Modal.Title>

            <div className='text-secondary text-center font-size-16px line-height-lg'>
              <p>
                {`You will be charged ${Num.Format.us$(
                  modalProps.price
                )!} for this client booking event.`}
              </p>
              <p className='text-nowrap'>
                {brandIcon}&nbsp;• • • •&nbsp;{billing.stripe.defaultLast4CardNumber}
              </p>

              <a href={`${billing.stripe.url!}`} target='_blank' rel='noreferrer' onClick={onClose}>
                Change Payment Method
              </a>
            </div>
          </Modal.Header>
          <Modal.Footer>
            <Modal.ButtonBox>
              <Modal.Button
                type='submit'
                disabled={purchaseResult.loading}
                onClick={async (): Promise<void> => {
                  let paymentConfirmation: Account.Purchase | undefined;

                  if (!purchaseResult.loading) {
                    paymentConfirmation = await purchaseMutation(account, modalProps.price);
                  }

                  if (paymentConfirmation?.paid) {
                    confirmTotal(Step.SUCCESS);
                  } else {
                    confirmTotal(Step.FAIL);
                  }
                }}
              >
                Confirm and Pay
              </Modal.Button>
              <Modal.Button
                variant='outline-secondary'
                onClick={onClose}
                disabled={purchaseResult.loading}
              >
                Cancel
              </Modal.Button>
            </Modal.ButtonBox>
          </Modal.Footer>
        </>
      ) : (
        <PaymentProcessing />
      )}
    </div>
  );
};
