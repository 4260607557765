import _ from 'lodash';
import React, { Component } from 'react';
import type { ComponentPropsWithRef, ReactNode } from 'react';
import type { ModalProps } from 'react-bootstrap/Modal';
import B_Modal from 'react-bootstrap/Modal';
import { Button as Button_ } from './button';
import { ButtonBox as ButtonBox_ } from './button-box';
import * as P from './prompt';
import { Submit as Submit_ } from './submit';
import { Title as Title_ } from './title';

// eslint-disable-next-line react/prefer-stateless-function
export class Modal extends Component<Modal.Props> {
  public override render(): ReactNode {
    return React.createElement(B_Modal, _.defaults({}, this.props, { centered: true }));
  }
}

export namespace Modal {
  export type Props = ComponentPropsWithRef<'div'> & ModalProps;

  export const { Body, Header, Footer, Dialog } = B_Modal;

  export const { usePrompt } = P;

  /* eslint-disable */
  export import Prompt = P.Prompt;
  export import Button = Button_;
  export import ButtonBox = ButtonBox_;
  export import Submit = Submit_;
  export import Title = Title_;
}
