import { Auth } from '@livecontrol/core-ui';
import { User } from '@livecontrol/scheduler/model';
import { FormikProvider } from 'formik';
import React from 'react';
import type { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Form } from '../../components';
import { Store } from '../../store';
import { useForm } from './form';

const Content = (): ReactElement => {
  const { formik, loading, alert } = useForm();

  return (
    <>
      <Form.Alert show={alert.show} onClose={alert.hide}>
        {alert.message}
      </Form.Alert>

      <FormikProvider value={formik}>
        <Form>
          <Form.Group
            name='password'
            label='Password'
            controlProps={{
              autoFocus: true,
              type: 'password',
              autoComplete: 'new-password',
              maxLength: User.Constraints.PASSWORD_MAX_LENGTH
            }}
          />

          <Form.Group
            name='confirmation'
            label='Confirm Password'
            controlProps={{
              type: 'password',
              maxLength: User.Constraints.PASSWORD_MAX_LENGTH
            }}
          />

          <div className='d-grid place-items-center pt-16px'>
            <Form.Submit className='btn-wide' loading={loading} size='lg'>
              Submit
            </Form.Submit>
          </div>
        </Form>
      </FormikProvider>
    </>
  );
};

export const ResetPassword = (): ReactElement => {
  React.useEffect(() => {
    Store.User.logout();
  }, []);

  return (
    <Auth.Layout>
      <Auth.Panel title='Change Your Password'>
        <Content />
      </Auth.Panel>

      <Auth.Linkbox>
        Remembered your password? <Link to='/login'>Login here!</Link>
      </Auth.Linkbox>
    </Auth.Layout>
  );
};
