import { Button } from '@livecontrol/core-ui';
import { Hash } from '@livecontrol/core-utils';
import { Event } from '@livecontrol/scheduler/model';
import { useCallback } from 'react';
import type { ReactElement } from 'react';
import Badge from 'react-bootstrap/Badge';
import { Hooks } from '../../hooks';
import { useStore } from '../../store';

export const Delete = (props: Button.Props): ReactElement => {
  const selection = useStore(({ model }) => model.value[Event.Phase.Upcoming].selection);
  const [doDelete, result] = Hooks.useDelete();

  const onClick = useCallback(
    (events: Event[]) => {
      doDelete(events);
    },
    [doDelete]
  );

  const icon = result?.loading ? 'fad fa-spinner-third' : 'fas fa-trash';
  const { size } = selection.selectedEvents;

  return (
    <Button
      {...props}
      variant='danger'
      disabled={selection.selectedEvents.size === 0 || result?.loading}
      onClick={(): void => {
        onClick([...selection.selectedEvents]);
      }}
    >
      <span key={Hash.digest(icon)}>
        <i className={icon} />
      </span>
      Delete
      {!!size && (
        <Badge className='font-size-10px bg-white ml-8px' variant='light' pill>
          {size}
        </Badge>
      )}
    </Button>
  );
};
