import { Scheduler } from '@livecontrol/scheduler/store';
import type { ReactElement } from 'react';
import { Store } from '../../../store';
import { BillingContact } from './billing-contact';
import { Header } from './header';
import { Information } from './information';
import { Invoices } from './invoices';
import { PaymentMethod } from './payment-method';
import { Subscription } from './subscription';

export const Billing = (): ReactElement => {
  const account = Store.Account.useAccount();

  const { billingInfo } = Scheduler.Billing.useSubscriptionAccountInfo(account.id);

  const { billing } = Scheduler.Account.useBilling(account);

  return (
    <div className='d-flex flex-column'>
      <Header />
      <Information plan={billingInfo?.plan} account={account} billing={billing} />
      <Subscription subscription={billingInfo?.subscription} />
      <PaymentMethod billing={billing} />
      <BillingContact billingContact={billingInfo?.billingContact} />
      {billingInfo?.invoices && <Invoices account={account} invoices={billingInfo.invoices} />}
    </div>
  );
};
