import { Arr, Nix, Num, Obj, Str } from '@livecontrol/core-utils';

const LATITUDE = ['latitude', 'lat'];
const LONGITUDE = ['longitude', 'lon', 'lng', 'long'];

const extract = (obj: Obj.Rec, properties: readonly string[]): number | undefined => {
  for (const property of properties) {
    const numberVal = Num.normalize(obj[property]);

    if (Nix.isNotNil(numberVal)) {
      return numberVal;
    }
  }

  return undefined;
};

export class Coordinates {
  public readonly latitude: number;
  public readonly longitude: number;

  public constructor(latitude: number, longitude: number) {
    this.latitude = Coordinates.constrain(latitude);
    this.longitude = Coordinates.constrain(longitude);
  }

  public toString(): string {
    return `(${this.latitude},${this.longitude})`;
  }

  public static constrain(value: number): number {
    let result = Num.normalize(value) ?? 0;

    while (Math.abs(result) >= 360) {
      result %= 360.0;
    }

    if (result > 180) {
      result -= 360;
    } else if (result < -180) {
      result += 360;
    }

    return result;
  }

  public static normalize(value: unknown): Coordinates | undefined {
    let coords: Coordinates | undefined;

    if (value instanceof Coordinates) {
      coords = value;
    } else if (value) {
      let latitude: number | undefined;
      let longitude: number | undefined;

      if (Obj.isObject(value)) {
        const obj = Obj.normalize<Obj.Rec>(value);

        if (obj) {
          latitude = extract(obj, LATITUDE);
          longitude = extract(obj, LONGITUDE);
        }
      } else if (Arr.isArray(value)) {
        const arr = Arr.normalize(value);

        if (arr.length === 2) {
          latitude = Num.normalize(arr[0]);
          longitude = Num.normalize(arr[1]);
        }
      } else {
        const tokens = Str.tokenize(Str.normalize(value), /[\s+,:|]/);

        if (tokens.length === 2) {
          latitude = Num.normalize(tokens[0]);
          longitude = Num.normalize(tokens[1]);
        }
      }

      if (Nix.isNotNil(latitude) && Nix.isNotNil(longitude)) {
        coords = new Coordinates(latitude, longitude);
      }
    }

    return coords;
  }
}
