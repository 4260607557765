import type { Account } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { useCallback } from 'react';
import { Store } from '../../../../store';

export const useUpdateEnableButton = (
  isOptimistic = true
): [(value: boolean) => Promise<Account.WebPlayer>, { loading: boolean }] => {
  const account = Store.Account.useAccount();

  const [editWebPlayer, { loading }] = Scheduler.Account.WebPlayer.useEdit();

  const mutateWebPlayer = useCallback(
    async (value: boolean) =>
      editWebPlayer(
        account,
        { isCustomButtonEnabled: value, donation: { label: '', link: '' } },
        isOptimistic
      ),
    [account, editWebPlayer, isOptimistic]
  );

  return [mutateWebPlayer, { loading }];
};
