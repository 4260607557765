import React, { useCallback } from 'react';
import { SwitchField } from './switch-field';
import { Utils } from './utils';

export const SwitchToggle = ({
  title,
  description,
  className,
  name,
  defaultValue
}: SwitchToggle.SwitchToggleProps): React.ReactElement => {
  const field = Utils.useField<boolean>(name);

  const onChange = useCallback(
    (v: boolean): void => {
      field?.helpers.setValue(v);
    },
    [field?.helpers]
  );

  const checked = (field?.meta.value !== undefined) ? field.meta.value : defaultValue ? defaultValue : false;

  return (
    <SwitchField
      className={className}
      title={title}
      description={description}
      noSaveIndicator
      checked= {checked}
      onChange={onChange}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace SwitchToggle {
  export interface SwitchToggleProps {
    title: React.ReactElement | string;
    description?: string;
    defaultValue?: boolean;
    className?: string;
    name: string;
  }
}
