import type { EmailAddress } from '@livecontrol/core-utils';
import { Num, Obj } from '@livecontrol/core-utils';
import type { Phone } from '@livecontrol/locale-us';
import type { Location } from './location';

export interface Contact {
  id: Contact.ID;
  email: EmailAddress;
  firstName: string;
  lastName: string;
  fullName: string;
  preferredContactMethod?: string;
  phoneType?: string;
  priority?: number;
  phone: Phone;
  notes?: string;
  location?: Location;
}

export namespace Contact {
  export type ID = number;
  export type Like = Contact | ID | string;

  export const toId = (contact?: Like): ID | undefined =>
    Num.normalize(Obj.isObject<Contact>(contact) ? contact.id : contact, { positive: true });

  export const Constraints = {
    NAME_MAX_LENGTH: 255,
    EMAIL_MAX_LENGTH: 128
  };
}
