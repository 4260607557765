/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Form } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import type { Event } from '@livecontrol/scheduler/model';
import { Formik } from 'formik';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import type { Value as FormValues } from './form';
import { useForm } from './form';

interface FeedbackModalProps {
  show?: boolean;
  onClose: () => void;
  onSubmit?: (form: FormValues) => void;
  event: Event;
  setShowModalSuccess: (show: boolean) => void;
  setSubmitted: (show: boolean) => void;
}

const Icon = styled.img`
  height: 40px;
  width: 40px;
  display: inline-grid;
  place-items: center;
  margin-botton: 16px;
`;

const StyledLabel = styled.label`
  line-height: var(--line-height-sm);
  max-width: 581px;
`;

const StyledModal = styled(Modal)`
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 740px;
    }
  }
`;

export const FeedbackModal = ({
  show = false,
  event,
  onClose,
  onSubmit,
  setShowModalSuccess,
  setSubmitted
}: FeedbackModalProps): React.ReactElement => {
  const onSuccess = useCallback(
    (values: FormValues) => {
      onSubmit?.(values);
      onClose();
      setShowModalSuccess(true);
      setSubmitted(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClose, onSubmit]
  );

  const { formik, loading } = useForm({
    feedbackID: event.id,
    initialValues: {
      issuesVideographer: [],
      issuesTech: [],
      videographer_thumb: undefined,
      technical_thumb: undefined,
      videographer_extra_comment: '',
      technical_extra_comment: ''
    },
    onSuccess
  });

  return (
    <StyledModal show={show} onHide={onClose} backdrop='static' keyboard={false}>
      <React.Fragment key={show ? 'true' : 'false'}>
        <Formik {...formik}>
          {({ values, touched, errors }): React.ReactElement => (
            <Form>
              <Modal.Header closeButton onHide={onClose}>
                <Icon src='/assets/icons/favourite-email.svg' alt='feedback-icon' />
                <h1 className='font-size-24px font-weight-bold'>Event Feedback</h1>
              </Modal.Header>
              <Modal.Body>
                <div className='d-flex flex-column align-items-center mb-56px'>
                  <label className='d-flex flex-column align-items-center text-center font-size-24px text-dark font-weight-bold'>
                    How was your videographer?
                    <Form.Like className='d-flex flex-row mt-20px' name='videographer_thumb' />
                  </label>
                  {touched.videographer_thumb && errors.videographer_thumb && (
                    <span className='font-size-8px text-danger text-left mt-24px'>
                      {errors.videographer_thumb}
                    </span>
                  )}
                  {values.videographer_thumb !== undefined &&
                    !values.videographer_thumb &&
                    touched.videographer_thumb && (
                      <>
                        <div className='d-flex flex-column text-center align-items-center font-size-15px text-dark mt-48px font-weight-bold'>
                          <StyledLabel>
                            We&apos;re sorry the event wasn&lsquo;t produced to your expectations.
                            We take your feedback seriously and will use this information to improve
                            your next event.
                          </StyledLabel>
                          <label className='font-size-15px mt-20px d-flex justify-content-center'>
                            Please let us know which areas can be addressed:
                          </label>
                          <Form.Checkboxes
                            className='d-flex flex-column align-items-start'
                            name='issuesVideographer'
                            style={{fontSize:13}}
                            checkboxes={[
                              { value: 'camera_frame_issue', label: 'Camera framing' },
                              { value: 'camera_movement_issue', label: 'Live camera movement' },
                              {
                                value: 'videographer_attention_issue',
                                label: 'Videographer not paying attention'
                              },
                              {
                                value: 'videographer_misunderstanding_issue',
                                label: 'Videographer didn’t understand the event'
                              },
                              {
                                value: 'videographer_notes_issue',
                                label: 'Videographer didn’t follow the notes'
                              }
                            ]}
                          />
                        </div>

                        <div className='d-block w-100 mt-28px font-weight-normal'>
                          <Form.Group
                            className='mt-8px'
                            controlProps={{
                              as: 'textarea',
                              placeholder: 'Other Notes',
                              style: {
                                minHeight: 125,
                                backgroundColor: 'white'
                              }
                            }}
                            label={false}
                            name='videographer_extra_comment'
                          />
                        </div>
                      </>
                    )}
                  <label className='d-flex flex-column text-center align-items-center font-size-24px text-dark mt-24px font-weight-bold'>
                    How was the tech?
                    <Form.Like className='d-flex flex-row mt-20px' name='technical_thumb' />
                  </label>
                  {touched.technical_thumb && errors.technical_thumb && (
                    <span className='font-size-8px text-danger text-left mt-24px'>
                      {errors.technical_thumb}
                    </span>
                  )}
                  {values.technical_thumb !== undefined &&
                    touched.technical_thumb &&
                    !values.technical_thumb && (
                      <>
                        <div className='d-flex flex-column text-center align-items-center font-size-15px text-dark mt-48px font-weight-bold'>
                          <StyledLabel>
                            We&apos;re sorry to hear the event had technical issues. What seemed to
                            be the problem?
                          </StyledLabel>
                          <Form.Checkboxes
                            className='d-flex flex-column align-items-start mt-8px'
                            name='issuesTech'
                            style={{fontSize:13}}
                            checkboxes={[
                              { value: 'sound_quality_issue', label: 'Sound Quality' },
                              { value: 'live_stream_quality_issue', label: 'Live Stream Quality' },
                              {
                                value: 'lighting_and_exposure_issue',
                                label: 'Lighting & Exposure'
                              },
                              {
                                value: 'graphics_and_screen_sharing_issue',
                                label: 'Graphics Screen Sharing'
                              }
                            ]}
                          />
                        </div>
                        <div className='d-block w-100 mt-28px font-weight-normal'>
                          <Form.Group
                            className='mt-8px'
                            controlProps={{
                              as: 'textarea',
                              placeholder: 'Other Notes',
                              style: {
                                minHeight: 125,
                                backgroundColor: 'white'
                              }
                            }}
                            label={false}
                            name='technical_extra_comment'
                          />
                        </div>
                      </>
                    )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Form.Submit loading={loading}>
                  <i className='far fa-star mr-8px' />
                  Leave feedback
                </Form.Submit>
                <Button variant='outline-secondary' onClick={onClose}>
                  Skip
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </React.Fragment>
    </StyledModal>
  );
};
