import { Loading, Theme, Viewport } from '@livecontrol/core-ui';
import { Calendar } from '@livecontrol/core-utils';
import type { Location } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { EventValidator, validateEventUpdate } from '@livecontrol/scheduler/validator';
import { Formik } from 'formik';
import _ from 'lodash';
import { DateTime, Duration, IANAZone } from 'luxon';
import type { Moment } from 'moment';
import moment from 'moment';
import React, { useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
// import { Form } from '../../../components';
import { store, Store } from '../../../store';
import type { EventContext, MobileContext } from '../../create-event';
import {
  ContentSection,
  CustomInputContainer,
  EventContextStore,
  EventDetailsForm,
  EventDetailsUtils,
  FooterSection,
  HeaderSection,
  MobileContextStore
} from '../../create-event';

const LogisticsFormSection = styled.div`
  .info-section {
    width: 228px;
    padding: 8px 28px;
    .title,
    .text {
      color: #2e384d;
      font-size: 15px;
      font-weight: 600;
    }
    .title {
      margin-bottom: 16px;
    }
    .text {
      margin-top: 4px;
    }
    .credits-number {
      width: 32px;
      height: 32px;
      border-radius: 16px;
      background-color: #2e5bff;
      color: #ffffff;
    }
  }
  .has-errors .invalid-feedback {
    opacity: 1;
  }
  .lead-time-error {
    max-width: 900px;
    font-size: 15px;
    margin-bottom: 42px;
    .error-text {
      color: #ff0000;
      margin-bottom: 24px;
      line-height: 23px;
    }
    .invalid-feedback {
      display: none;
    }
    input {
      width: 16px;
    }
    .form-check {
      display: flex;
      align-items: center;
      .form-group {
        margin-bottom: 0px;
        margin-right: 16px;
      }
    }
    .form-check-label {
      font-weight: 600;
    }
  }
`;

export const MobileDetails = ({
  onBack,
  onContinue,
  title,
  minDate,
  locations
}: {
  onBack: () => void;
  onContinue: () => void;
  title?: string;
  minDate?: DateTime;
  locations: Location[] | undefined;
}): React.ReactElement => {
  const { production } = store.environment;
  const account = Store.Account.useAccount();
  const isAdmin = Store.User.useIsAdmin();
  const { events: allEvents } = Scheduler.Event.useEvents(account);
  const { store: nomalEventStore }: EventContext = useContext<EventContext>(EventContextStore);

  const { store: eventDetailsContext, existingEvent }: MobileContext =
    useContext<MobileContext>(MobileContextStore);

  const viewport = Theme.useViewport();

  const availableLocations = useMemo(
    (): Location[] =>
      EventDetailsUtils.getAvailableLocations(locations, eventDetailsContext.eventType).sort(
        (a, b) => a.name.localeCompare(b.name)
      ),
    [locations, eventDetailsContext]
  );

  const timezone: string | undefined = useMemo(() => {
    const selected = availableLocations.find(
      (location) => location.id === eventDetailsContext.eventInfo.location
    );

    const timezone_ = selected
      ? selected.timezone
      : availableLocations.length
      ? availableLocations[0].timezone
      : undefined;

    return timezone_;
  }, [availableLocations, eventDetailsContext.eventInfo.location]);

  const initialDates = useMemo(() => {
    let date = DateTime.utc()
      .plus({ days: 0, hours: 2 })
      .startOf('hour')
      .setZone(timezone ?? account.timezone);

    if (eventDetailsContext.eventInfo.start) {
      date = eventDetailsContext.eventInfo.start.setZone(timezone ?? account.timezone);
    } else if (minDate) {
      date = minDate
        .plus({ days: 0, hours: 2 })
        .startOf('hour')
        .setZone(timezone ?? account.timezone);
    }

    const producedEventEnd =
      minDate?.setZone(timezone ?? account.timezone) ??
      DateTime.utc()
        .plus({ days: 0, hours: 0 })
        .startOf('hour')
        .setZone(timezone ?? account.timezone);

    const startTime =
      eventDetailsContext.eventInfo.start
        ?.setZone(timezone ?? account.timezone)
        .toFormat('hhmm a') ?? producedEventEnd.toFormat('hhmm a');

    // console.log("starting date for input: ", startTime);

    const endTime =
      eventDetailsContext.eventInfo.end?.setZone(timezone ?? account.timezone).toFormat('hhmm a') ??
      producedEventEnd
        .plus(EventValidator.SchedulerDurations.DEFAULT_EVENT_DURATION)
        .setZone(timezone ?? account.timezone)
        .toFormat('hhmm a');

    return {
      date,
      startTime,
      endTime
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone]);

  const displayTitle = title?.length ? title : 'Logistics';

  const formLayout = useCallback(
    (values: EventDetailsForm.Values, errors: EventDetailsForm.Errors): React.ReactElement => {
      const { startTime, endTime } = EventDetailsUtils.calculateEventTimes({ ...values, timezone });
      let eventDuration = startTime && endTime && endTime.diff(startTime, 'minutes');
      let displayDuration = '-:-';

      // console.log("ERRORS; ", errors);

      values.leadtimeAcknowledge = true;

      if (eventDuration && eventDuration.minutes > 0) {
        const durationHours = Math.floor(eventDuration.minutes / 60);
        const durationMinutes = eventDuration.minutes - durationHours * 60;

        displayDuration = `${durationHours}h ${durationMinutes}m`;
      } else if (eventDuration && eventDuration.minutes < 0) {
        const positiveMinutes = 1440 + eventDuration.minutes;
        const durationHours = Math.floor(positiveMinutes / 60);
        const durationMinutes = positiveMinutes - durationHours * 60;

        displayDuration = `${durationHours}h ${durationMinutes}m`;

        eventDuration = Duration.fromMillis(positiveMinutes * 60 * 1000);
      }

      const creditsForEvent = 1;

      return (
        <>
          <HeaderSection>
            <div className='title'>
              <div className='text'>{displayTitle} </div>
            </div>
          </HeaderSection>

          <ContentSection>
            <LogisticsFormSection>
              <div className='custom-form-group d-flex justify-content-between flex-wrap'>
                <EventDetailsForm.EventLocation options={availableLocations} errors={errors} />
                <EventDetailsForm.Date
                  timezone={timezone}
                  eventType={eventDetailsContext.eventType}
                  errors={errors}
                />
              </div>

              <div className='custom-form-group d-flex justify-content-between flex-wrap'>
                <div className='d-flex times-section justify-content-between flex-wrap'>
                  <EventDetailsForm.StartTime timezone={timezone} errors={errors} />
                  <EventDetailsForm.EndTime
                    timezone={timezone}
                    errors={errors}
                    startTime={startTime}
                    endTime={endTime}
                    startDate={values.date}
                    eventType={eventDetailsContext.eventType}
                  />
                </div>

                <div className='info-section d-flex justify-content-between'>
                  <div className='duration d-flex flex-column custom-input'>
                    <div className='title'>Duration</div>
                    <div className='text'>{displayDuration}</div>
                  </div>
                  <div className='credits d-flex flex-column align-items-center custom-input'>
                    <div className='title'>Credits</div>
                    <div className='credits-number d-flex align-items-center justify-content-center'>
                      {creditsForEvent}
                    </div>
                  </div>
                </div>
              </div>
            </LogisticsFormSection>
          </ContentSection>

          <HeaderSection>
            <div className='divider' />
            <div className='title'>
              <div className='text'>Information </div>
            </div>
          </HeaderSection>

          <ContentSection>
            <CustomInputContainer>
              <EventDetailsForm.Title />
            </CustomInputContainer>

            <CustomInputContainer>
              <EventDetailsForm.Description />
            </CustomInputContainer>
          </ContentSection>

          <FooterSection>
            <EventDetailsForm.Submit onBack={onBack} />
          </FooterSection>
        </>
      );
    },
    [timezone, eventDetailsContext.eventType, availableLocations, onBack, displayTitle]
  );

  if (!allEvents || !existingEvent) {
    return <Loading.Delay />;
  }

  const formik = {
    initialValues: {
      type: eventDetailsContext.eventType,
      location: availableLocations.length ? availableLocations[0].id : '',
      date: initialDates.date,
      timezone,
      startTime: moment(initialDates.startTime, 'hhmm a'),
      endTime: moment(
        eventDetailsContext.eventInfo.end
          ?.setZone(timezone ?? account.timezone)
          .toFormat('hhmm a') ?? initialDates.endTime,
        'hhmm a'
      ),
      passwordSettings: {
        enabled: false,
        password: ''
      },
      chatSettings: {
        requireRegistration: eventDetailsContext.eventInfo.chatSettings?.requireRegistration,
        showViewers: eventDetailsContext.eventInfo.chatSettings?.showViewers,
        registration: eventDetailsContext.eventInfo.chatSettings?.registration,
        liveChat: eventDetailsContext.eventInfo.chatSettings?.liveChat
      },
      title: '',
      // leadtimeAcknowledge: eventDetailsContext.eventInfo.leadtimeAcknowledge,
      repeatSettings: {
        repeat: false,
        repeatFrequency: {
          repeatEvery: 1,
          unit: Calendar.Unit.WEEKS,
          daysOfWeek: new Set<Calendar.Weekday>()
        },
        repeatUntil: {
          numRecurrences: 3,
          stopRepeatingOn: DateTime.utc()
            .plus({ weeks: 3, days: 2 })
            .startOf('hour')
            .setZone(timezone ?? account.timezone)
        },
        repeatEndCondition: EventValidator.EventInfo.RepeatEndCondition.AFTER_OCCURRENCES
      },
      zoomDetails: {
        streamZoom: false,
        meetingId: '',
        password: ''
      },
      ..._.omit(eventDetailsContext.eventInfo, ['start', 'end'])
    },
    validate: (
      values: Exclude<EventValidator.EventInfo, { start: DateTime; end: DateTime }> & {
        date?: DateTime;
        startTime?: Moment;
        endTime?: Moment;
        leadTime?: boolean;
        leadtimeAcknowledge?: boolean;
      }
    ): EventValidator.Errors => {
      let startDate = _.cloneDeep(values.date)?.setZone(timezone ?? account.timezone);
      let endDate = _.cloneDeep(values.date)?.setZone(timezone ?? account.timezone);

      if (startDate && values.startTime) {
        const start = DateTime.fromFormat(values.startTime.format('hhmm a'), 'hhmm a', {
          zone: timezone ?? account.timezone
        });

        if (!start.isValid) {
          return {
            startTime: EventValidator.ErrorMessages.INVALID_TIME_FORMAT
          };
        }

        // check if start date is after produced event end date
        if (minDate && startDate < minDate) {
          return {
            startTime: EventValidator.ErrorMessages.START_TIME_MOBILE_CONFLICT
          };
        }

        startDate = startDate.set({ hour: start.hour, minute: start.minute }).toUTC();
      }

      if (endDate?.isValid && values.endTime) {
        const end = DateTime.fromFormat(values.endTime.format('hhmm a'), 'hhmm a', {
          zone: timezone ?? account.timezone
        });

        if (!end.isValid) {
          return {
            endTime: EventValidator.ErrorMessages.INVALID_TIME_FORMAT
          };
        }

        endDate =
          eventDetailsContext.eventType === EventValidator.ProductionType.SIMULATED
            ? startDate?.plus(
                eventDetailsContext.simulatedLiveAssetDetails?.duration ??
                  eventDetailsContext.simulatedLiveFileUpload!.videoDuration
              )
            : eventDetailsContext.eventType === EventValidator.ProductionType.TEST
            ? startDate?.plus(EventValidator.SchedulerDurations.TEST_EVENT_DURATION)
            : endDate.set({ hour: end.hour, minute: end.minute }).toUTC();

        if (startDate && endDate && endDate < startDate) {
          endDate = endDate.plus({ day: 1 });
        }
      }

      const { cueSheetURL } = values;

      const errors = validateEventUpdate({
        events: allEvents,
        existingEvent,
        cueSheetURL: EventDetailsUtils.isCueSheetEnabled(availableLocations, values.location)
          ? cueSheetURL
          : undefined,
        eventUpdate: {
          type: eventDetailsContext.eventType,
          start: startDate?.setZone(timezone ?? account.timezone),
          end: endDate?.setZone(timezone ?? account.timezone),
          zoomDetails: {
            meetingId: values.zoomDetails?.meetingId,
            password: values.zoomDetails?.password
          },
          ..._.omit(values, ['startTime', 'endTime', 'date'])
        },
        simulatedLiveAssetIsUpload: eventDetailsContext.simulatedLiveAssetIsUpload,
        leadTime: account.leadTime,
        timezone: timezone ? new IANAZone(timezone) : account.timezone,
        isAdmin,
        isProd: production
      });

      eventDetailsContext.setEventInfo({
        ...eventDetailsContext.eventInfo,
        start: startDate,
        end: endDate,
        ..._.omit(values, ['startTime', 'endTime', 'date']),
        isValid: !!values.title && _.isEmpty(errors)
      });

      nomalEventStore.setEventInfo({
        ...nomalEventStore.eventInfo,
        mobileKitData: {
          title: eventDetailsContext.eventInfo.title,
          description: eventDetailsContext.eventInfo.description,
          location: eventDetailsContext.eventInfo.location,
          start: eventDetailsContext.eventInfo.start,
          end: eventDetailsContext.eventInfo.end
        },
        isValid: true
      });

      return errors;
    },

    onSubmit(): void {
      onContinue();
    }
  };

  // Remove previous Zoom Data if switched to Static
  if (formik.initialValues.type === EventValidator.ProductionType.STATIC) {
    formik.initialValues.zoomDetails = {
      streamZoom: false,
      meetingId: '',
      password: ''
    };
  }

  return (
    <div className='mt-28px'>
      <Formik {...formik}>
        {({ values, errors }): React.ReactElement => (
          <EventDetailsForm.Wrapper $isMobile={viewport === Viewport.XS}>
            {formLayout(values, errors)}
          </EventDetailsForm.Wrapper>
        )}
      </Formik>
    </div>
  );
};
