import { VideoPlayer } from '@livecontrol/core-ui';
import { Preview } from '@livecontrol/scheduler/components';
import { Account, Asset } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import cx from 'classnames';
import { useCallback, useMemo, useRef, useState } from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import Badge from 'react-bootstrap/Badge';
import styled from 'styled-components';
import type videojs from 'video.js';
import { store, Store } from '../../../store';
import { PlayPause } from './play-pause';

const Container = styled.div`
  position: relative;

  .video-js {
    background-color: transparent;

    &.vjs-has-started {
      background-color: black;
    }
    &.vjs-waiting + .play-pause {
      opacity: 0;
    }
  }

  .vjs-big-play-button {
    display: none;
  }

  .play-pause {
    opacity: 0;
  }

  &.x-mouse-enter,
  &.x-paused {
    .play-pause {
      opacity: 1;
    }
  }

  .duration {
    position: absolute;
    left: 18px;
    top: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    padding: 1px 8px;
    min-height: 20px;
    min-width: 60px;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(6px);
    border-radius: 4px;
  }
  .privacy {
    position: absolute;
    left: 18px;
    bottom: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    padding: 1px 8px;
    min-height: 20px;
    min-width: 60px;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(6px);
    border-radius: 4px;
  }
  .web-player-btn {
    position: absolute;
    right: 18px;
    top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 11px;
    min-height: 20px;
    min-width: 60px;
    background-color: #2e5bff;
    border-radius: 4px;

    .icon {
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }

    .lanch-icon {
      background-image: url(/assets/icons/Launch.svg);
    }

    .text {
      margin-left: 6px;
      font-size: 12px;
      color: #ffffff;
      font-weight: 600;
    }
  }
`;

export const Player = ({ asset, ...props }: Player.Props): ReactElement => {
  const { duration, mux } = asset;
  const player = useRef<videojs.Player | undefined>();
  const [isPlaying, setPlaying] = useState(false);
  const [isEntered, setEntered] = useState(false);

  const { environment } = store;
  const account = Store.Account.useAccount();
  const { webPlayer } = Scheduler.Account.WebPlayer.useView(account.id);

  const poster = useMemo(() => Asset.Mux.thumbnail(mux, { width: 1024, height: 576 }), [mux]);

  const [isReady, streamUrl] = mux
    ? [mux.status === Asset.Mux.Status.Ready, mux.streamUrl]
    : [false, undefined];

  const handlePlayPause = useCallback(() => {
    const { current } = player;

    if (current) {
      void current[current.paused() ? 'play' : 'pause']();
    }
  }, [player]);

  const handleEvent = useCallback(
    (anEvent: string) => {
      const { current } = player;

      if (current && ['play', 'pause'].includes(anEvent)) {
        setPlaying(!current.paused());
      }
    },
    [player, setPlaying]
  );

  let webPlayerURL = '';
  const endpoint = webPlayer?.endpoint;
  let showButton = false;

  if (endpoint && asset.visibility !== Asset.Visibility.Private && asset.event) {
    webPlayerURL = Account.WebPlayer.makeURL({
      uri: environment.VENUE_URI,
      slug: `${endpoint}`,
      event: asset.event,
      password: asset.eventPassword
    });
  }

  if (endpoint && asset.event) {
    showButton = true;
  }

  return (
    <div {...props}>
      <Preview imageUrl={poster}>
        <Container
          className={cx(
            'lc-absolute-overlay',
            isEntered && 'x-mouse-enter',
            !isPlaying && 'x-paused'
          )}
          onMouseEnter={(): void => {
            setEntered(true);
          }}
          onMouseLeave={(): void => {
            setEntered(false);
          }}
        >
          {isReady && streamUrl ? (
            <>
              <VideoPlayer ref={player} onEvent={handleEvent} options={{ aspectRatio: '16:9' }}>
                <source src={streamUrl} />
              </VideoPlayer>

              <PlayPause
                className='lc-absolute-centered transition-fade play-pause'
                playing={isPlaying}
                onClick={handlePlayPause}
              />

              {!isPlaying && (
                <>
                  {duration && (
                    <div className='duration'>
                      {duration.toFormat(duration.as('minutes') >= 60 ? 'h:mm:ss' : 'mm:ss')}
                    </div>
                  )}
                  {showButton ? (
                    <a
                      href={webPlayerURL}
                      className='web-player-btn'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <div className='icon lanch-icon' />
                      <div className='text'>View Web Player</div>
                    </a>
                  ) : null}
                </>
              )}
            </>
          ) : (
            <Badge className='lc-absolute-centered font-size-15px py-8px px-16px' variant='dark'>
              Video not available.
            </Badge>
          )}
        </Container>
      </Preview>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Player {
  export type Props = HTMLAttributes<HTMLDivElement> & { asset: Asset };
}
