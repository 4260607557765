import { Device } from '@livecontrol/core-ui';
import { Asset as AssetModel } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import shallow from 'zustand/shallow';
import { Store } from '../../store';
import { NoMatch } from './no-match';
import { useStore } from './store';
import type { Asset, M_Filters, M_Sorting } from './types';
import { Utils } from './utils';

const Matrix = styled.div`
  grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr));

  .x-${Device.DESKSTOP} & {
    grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr)) minmax(19rem, 1fr);
  }
`;

const LibraryItem = styled.div`
  width: 100%;
  margin: 0px 5px 0px;

  .image-section {
    display: block;
    width: 98%;
    border-radius: 6px;
    height: 173px;
    background-color: #ebf0f3;
    overflow: hidden;
    position: relative;
    padding-top: calc(56.25%);

    .image {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      padding-top: calc(56.25%);

      .duration {
        position: absolute;
        left: 8px;
        top: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
        font-weight: 600;
        color: white;
        padding: 1px 8px;
        min-height: 20px;
        min-width: 60px;
        background-color: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(6px);
        border-radius: 4px;
      }

      .type {
        position: absolute;
        right: 8px;
        top: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 9px;
        font-weight: 600;
        color: white;
        padding: 1px 8px;
        min-height: 20px;
        min-width: 60px;
        background: rgba(135, 152, 173, 0.2);
        backdrop-filter: blur(6px);
        border-radius: 4px;
        text-transform: capitalize;
      }
    }
  }

  .info-section {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 5px 0px;

    .title {
      font-weight: 600;
      font-size: 13px;
      color: #2e384d;
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      a {
        color: #2e384d;
      }

      .icons {
        margin-left: 7px;
      }

      .icon {
        width: 20px;
        min-width: 20px;
        height: 20px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }

      .eye-slash {
        background-image: url('/assets/icons/Eye-slash-grey.svg');
      }

      .lock {
        background-image: url('/assets/icons/Lock-grey.svg');
      }
    }
    .place {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      .text {
        font-weight: 600;
        font-size: 11px;
        color: #9c9c9c;
        margin-left: 4px;
        margin-top: 2px;
      }
    }

    .date {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      .text {
        font-weight: 600;
        font-size: 11px;
        color: #9c9c9c;
        margin-left: 4px;
        margin-top: 2px;
      }
    }
    .icon {
      width: 16px;
      min-width: 16px;
      height: 16px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .location {
      background-image: url('/assets/icons/Location_point.svg');
    }

    .calendar {
      background-image: url('/assets/icons/Calendar.svg');
    }
  }
`;

export const Grid = (): ReactElement => {
  const store = useStore();

  const [isFiltersChanged, setIsFiltersChanged] = useState(false);

  const [assets, sorting, timezone] = store((state) => {
    const m = state.model.value;

    return [m.assets, m.sorting, state.timezone];
  }, shallow);

  const account = Store.Account.useAccount();
  const { locations } = Scheduler.Location.useLocations(account);

  const assetsCopy: Asset.withEvent[] = [...assets];

  const assetsWithPermission = assetsCopy.filter((asset) => {
    if (locations?.some((location) => location.id === asset.locationId)) {
      return true;
    }

    return false;
  });

  const [filters, setFilters] = useState(
    store.getState().model.value.filters // not reactive!
  );

  const [prevFilters, setPrevFilters] = useState<M_Filters>();
  const [prevSorting, setPrevSorting] = useState<M_Sorting>();

  useEffect(
    () =>
      store.subscribe(
        _.debounce((f: M_Filters) => {
          setFilters(f);
          setIsFiltersChanged(true);
        }, 500),
        ({ model }) => model.value.filters
      ),
    [store]
  );

  useEffect(
    () =>
      store.subscribe(
        () => {
          setIsFiltersChanged(true);
        },
        ({ model }) => model.value.sorting
      ),
    [store]
  );

  useEffect(() => {
    if (isFiltersChanged) {
      const isDateFilterChanged = !!filters.period.value?.max !== !!prevFilters?.period.value?.max;
      const isSortingKeyChanged = sorting.value.key !== prevSorting?.value.key;
      const isSortingDirectionChanged = sorting.value.direction !== prevSorting?.value.direction;
      const isSearchKeywordChanged = !!filters.keyword.value !== !!prevFilters?.keyword.value;

      if (
        !isDateFilterChanged &&
        !isSortingKeyChanged &&
        !isSortingDirectionChanged &&
        !isSearchKeywordChanged
      ) {
        // filters were not updated -> don't send mixpanel event
        return;
      }

      setPrevFilters(filters);
      setPrevSorting(sorting);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, isFiltersChanged, sorting]);

  const transformed = useMemo(
    (): readonly Asset[] =>
      Utils.sort(
        Utils.filterByKeyword(
          Utils.filterByEventType(
            Utils.filterByLocation(
              Utils.filterByPeriod(assetsWithPermission, filters.period.value),
              filters.location.selected
            ),
            filters.event.value
          ),
          filters.keyword.value
        ),
        sorting.value
      ),
    [assetsWithPermission, filters, sorting]
  );

  return transformed.length ? (
    <div className='justify-content-center'>
      <Matrix
        className='d-grid gap-32px class'
        style={{
          gridTemplateColumns:
            transformed.length === 1 || transformed.length === 2
              ? 'repeat(auto-fit, minmax(19rem, 1fr)) minmax(19rem, 1fr) minmax(19rem, 1fr) minmax(19rem, 1fr)'
              : ''
        }}
      >
        {transformed.map((asset) => {
          const { id, title, eventData, duration, visibility, eventPassword } =
            asset as Asset.withEvent;

          const timestamp = asset.timestamp.setZone(timezone);
          const to = `/content-library/library/view/${id}`;

          const eventLocation =
            locations?.find((location) => location.id === asset.locationId)?.name ?? '';

          const imageUrl = eventData?.backgroundURL
            ? eventData.backgroundURL.replaceAll(' ', '%20')
            : asset.mux?.thumbnailUrl;

          return (
            <LibraryItem key={asset.id}>
              <a href={to} className='image-section'>
                <div
                  className='image'
                  style={{ backgroundImage: `url(${imageUrl ? imageUrl : ''})` }}
                >
                  {duration && (
                    <div className='duration'>
                      {duration.toFormat(duration.as('minutes') >= 60 ? 'h:mm:ss' : 'mm:ss')}
                    </div>
                  )}
                  {eventData && <div className='type'>{eventData.type}</div>}
                </div>
              </a>
              <div className='info-section'>
                <div className='title'>
                  <div className='text'>
                    <a href={to}>{title}</a>
                  </div>
                  <div className='d-flex icons'>
                    {visibility === AssetModel.Visibility.Private && (
                      <div className='icon eye-slash' />
                    )}
                    {eventPassword && eventPassword.length > 0 && <div className='icon lock' />}
                  </div>
                </div>
                {eventData && (
                  <div className='font-size-12px font-weight-bold text-gray-light'>
                    EID <span>{eventData.id}</span>
                  </div>
                )}
                {eventLocation && (
                  <div className='place'>
                    <div className='icon location' />
                    <div className='text'>{eventLocation}</div>
                  </div>
                )}
                <div className='date'>
                  <div className='icon calendar' />
                  <div className='text'>
                    {timestamp.toLocaleString(DateTime.DATE_FULL)}
                    &nbsp; - &nbsp;
                    {timestamp.toLocaleString(DateTime.TIME_SIMPLE)}
                  </div>
                </div>
              </div>
            </LibraryItem>
          );
        })}
      </Matrix>
    </div>
  ) : (
    <NoMatch onReset={filters.reset} />
  );
};
