import type { Viewport } from '@livecontrol/core-ui';
import { Theme } from '@livecontrol/core-ui';
import React from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ viewport: Viewport }>`
  padding: 10px 0;
`;

const Container = styled.div<{ viewport: Viewport }>`
  background-color: var(--white);
  display: block;
  background-color: #ffffff;
  width: 100%;
  border-radius: var(--border-radius-lg);
  padding: 25px 25px;
  transition: padding linear var(--transition-duration);
  height: 100%;
`;

export const SectionSmallContainer = ({
  className,
  children,
  ...rest
}: React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>): ReactElement => {
  const viewport = Theme.useViewport();

  return (
    <Wrapper viewport={viewport} className={className} {...rest}>
      <Container viewport={viewport}>{children}</Container>
    </Wrapper>
  );
};
