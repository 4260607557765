import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  &:not(.x-expanded) {
    .bottom {
      stroke-dashoffset: 30;
    }
  }
`;

export const Desktop = (props: React.ComponentPropsWithoutRef<'svg'>): React.ReactElement => (
  <SVG {...props}>
    <path className='top' d='M 20,29 H 80' />
    <path className='middle' d='M 20,50 H 80' />
    <path className='bottom' d='M 20,71 H 80' />
  </SVG>
);
