import type { Account, Location } from '@livecontrol/scheduler/model';
import type { FormikContextType } from 'formik';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { AccountHolder } from './account-holder';
import type { AccountUsersPermission } from './account-permissions';
import type { Values } from './form';
import { User } from './user';

interface Props {
  location: Location;
  accountUserPermissionSelected: AccountUsersPermission[];
  formik: FormikContextType<Values>;
  account: Account;
  valueSelected: Account.SubUser | Location | undefined;
}

export const RowLocation = ({
  location,
  accountUserPermissionSelected,
  formik,
  account,
  valueSelected
}: Props): ReactElement => {
  const [isCollapse, setIsCollapse] = useState(false);

  useEffect(() => {
    if (valueSelected?.id === location.id) {
      setIsCollapse(true);
    } else {
      setIsCollapse(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueSelected]);

  return (
    <>
      <div className='d-none' />
      {isCollapse && (
        <>
          <AccountHolder account={account} />
          {account.subUsers.map((subuser) => (
            <User
              accountUserPermissionSelected={accountUserPermissionSelected}
              location={location}
              formik={formik}
              key={subuser.id}
              subUser={subuser}
            />
          ))}
        </>
      )}
    </>
  );
};
