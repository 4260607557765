import { Button } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import type { Asset } from '@livecontrol/scheduler/model';
import cx from 'classnames';
import { useState } from 'react';
import type { ReactElement } from 'react';
import { Transfer } from './transfer';

type Props = Button.Props & {
  asset: Asset;
};

export const Download = (props: Props): ReactElement => {
  const { asset, className, ...rest } = props;

  const prompt = Modal.usePrompt();
  const [showModal, setShowModal] = useState(false);

  const isReady = !!asset.mux?.downloadUrl;
  const icon = isReady ? 'fas fa-arrow-down' : 'far fa-clock';

  const doDownload = (): void => {
    // If the download ready?
    if (!isReady) {
      prompt.message({
        title: 'Download Not Ready.',
        icon: 'far fa-clock',
        content:
          'This video is still being generated and is not yet available for downloading.  Please check back at a later time.'
      });
    } else {
      setShowModal(true);
    }
  };

  return (
    <>
      <Button
        className={cx(!isReady && 'disabled', className)}
        {...rest}
        variant='outline-primary'
        onClick={doDownload}
      >
        <span key={icon}>
          <i className={icon} />
        </span>
        Download {isReady ? 'Video' : 'Processing...'}
      </Button>

      {showModal && (
        <Transfer
          asset={asset}
          onClose={(): void => {
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};
