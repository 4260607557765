/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Phone } from '@livecontrol/locale-us';
import type { User } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { FormikContextType } from 'formik';
import { useFormik } from 'formik';
import { useRef } from 'react';
import * as Yup from 'yup';
import { Store } from '../../../store';

interface LocationsNotification {
  id: number;
  locationId: string;
  eventScheduling: boolean;
  eventReminder: boolean;
  connectionIssues: boolean;
  customerFeedback: boolean;
}

export interface Props {
  me?: User;
  locationsNotificationSelected: LocationsNotification[];
}

export interface Values {
  // This is for useUpsertSubUser
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | undefined;
  landline: string | undefined;
  landlineExtension: string | undefined;
  preferredContactMethod: string;
  additionalInformation: string;
}

interface Form {
  formik: FormikContextType<Values>;
  loading: boolean;
}

const validationSchema = Yup.object({
  firstName: Yup.string().trim().required("Please provide the user's first name."),
  lastName: Yup.string().trim().required("Please provide the user's last name."),
  email: Yup.string()
    .trim()
    .required('Please enter valid email address')
    .email('Please enter valid email address.'),
  phoneNumber: Yup.string()
    .trim()
    .test({
      message: 'Please provide a valid phone number.',
      test: (value) => (value ? value.length > 0 && !!Phone.normalize(value) : true)
    })
    .test({
      message: 'Please provide at least one phone number',
      test: (value, context) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (!value && !context.parent.landline) {
          return false;
        }

        return true;
      }
    }),
  landline: Yup.string()
    .trim()
    .test({
      message: 'Please provide a valid phone number.',
      test: (value) => (value ? value.length > 0 && !!Phone.normalize(value) : true)
    })
    .test({
      message: 'Please provide at least one phone number',
      test: (value, context) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (!value && !context.parent.phoneNumber) {
          return false;
        }

        return true;
      }
    }),
  landlineExtension: Yup.string().trim().optional(),
  preferredContactMethod: Yup.string().trim().required('Please provide a contact method'),
  additionalInformation: Yup.string().trim()
});

export const useForm = ({ me, locationsNotificationSelected }: Props): Form => {
  const [userMutation, { loading }] = Store.User.useEdit();

  const [locationsNotificationsMutations, { loading: locationsNotificationsLoading }] =
    Scheduler.Account.UserManagement.useUpdateForEmailLocationsNotifications();

  const initialValues = useRef({
    // This is for useUpsertSubUser
    firstName: me?.firstName ?? '',
    lastName: me?.lastName ?? '',
    email: me?.email ?? '',
    phoneNumber: Phone.normalize(me?.phone)?.format({ separator: '' }) ?? '',
    landline: Phone.normalize(me?.landline)?.format({ separator: '' }) ?? '',
    landlineExtension: me?.landlineExtension ?? '',
    preferredContactMethod: me?.preferredContactMethod
      ? me.preferredContactMethod === '--'
        ? ''
        : me.preferredContactMethod
      : '',
    additionalInformation: me?.additionalInformation
      ? me.additionalInformation === '--'
        ? ''
        : me.additionalInformation
      : ''
  }).current;

  const formik = useFormik<Values>({
    initialValues,
    validationSchema,
    validateOnMount: true,

    async onSubmit(data: Values): Promise<void> {
      const userArgs = {
        id: me?.id!,
        first: data.firstName,
        last: data.lastName,
        phone: data.phoneNumber,
        landline: data.landline,
        landlineExtension: data.landlineExtension,
        preferredContactMethod: data.preferredContactMethod,
        additionalInformation: data.additionalInformation
      };

      await userMutation(userArgs);

      const bodyInput = locationsNotificationSelected.map((notifications) => {
        if (notifications.id !== 0) {
          return {
            connection_issues: notifications.connectionIssues,
            customer_feedback: notifications.customerFeedback,
            event_reminder: notifications.eventReminder,
            event_scheduling: notifications.eventScheduling,
            id: notifications.id,
            location_id: notifications.locationId
          };
        }

        return {
          connection_issues: notifications.connectionIssues,
          customer_feedback: notifications.customerFeedback,
          event_reminder: notifications.eventReminder,
          event_scheduling: notifications.eventScheduling,
          location_id: notifications.locationId
        };
      });

      await locationsNotificationsMutations({
        input: bodyInput,
        userId: me?.id!
      });
    }
  });

  return {
    formik,
    loading: loading || locationsNotificationsLoading
  };
};
