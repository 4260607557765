import _ from 'lodash';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Tooltip } from './tooltip';

const Wrapper = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
  line-height: var(--line-height-sm);
`;

export const Overflow = (props: Overflow.Props): ReactElement => {
  const { value, length, className, ...rest } = _.defaults({}, props, {
    length: 50
  });

  return value.length > length ? (
    <Tooltip {...rest} tip={value}>
      <Wrapper className={className}>{value}</Wrapper>
    </Tooltip>
  ) : (
    <Wrapper className={className}>{value}</Wrapper>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Overflow {
  export type Props = Omit<Tooltip.Props, 'children' | 'tip'> & {
    className?: string;
    value: string;
    length?: number;
  };
}
