import type { Asset } from '@livecontrol/scheduler/model';
import type { ReactElement } from 'react';
import { Preview } from '../preview';
import { Badge } from './badge';

export const Thumbnail = ({ asset, children, ...rest }: Thumbnail.Props): ReactElement => (
  <Preview imageUrl={asset.eventData?.backgroundURL ?? asset.mux?.thumbnailUrl} {...rest}>
    <Badge.Duration duration={asset.duration} />
    <Badge.Password password={asset.eventPassword} />
    <Badge.Visibility visibility={asset.visibility} />
    {children}
  </Preview>
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Thumbnail {
  export type Props = Preview.Props & { asset: Asset.withEvent };
}
