import React from 'react';
import styled from 'styled-components';

type Props = React.PropsWithChildren<{
  label: string;
}>;

const Label = styled.div`
  font-size: var(--font-size-12px);
  letter-spacing: 0.75px;
  text-transform: uppercase;
  padding-right: var(--spacer-16px);
  padding-bottom: var(--spacer-16px);

  .x-light-theme & {
    color: var(--tertiary);
  }

  .x-dark-theme & {
    color: var(--white60);
  }
`;

const Value = styled.div`
  .x-light-theme & {
    color: var(--tertiary);
  }

  .x-dark-theme & {
    color: var(--white);
  }
`;

export const Row = ({ children, label }: Props): React.ReactElement => (
  <>
    <Label>{label}:</Label>
    <Value>{children}</Value>
  </>
);
