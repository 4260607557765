import { Event } from '@livecontrol/scheduler/model';
import { useEffect } from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import { NoMatch } from '../common';
import { EventsSection } from '../event-rows';
import { Hooks } from '../hooks';
import { useStore } from '../store';
import { Actions } from './actions';

type Props = HTMLAttributes<HTMLDivElement> & { events: readonly Event[] };

const PHASE = Event.Phase.Upcoming;

export const Upcoming = ({ events, ...props }: Props): ReactElement => {
  const store = useStore();

  // Transform the events
  const xform = Hooks.useTransform(PHASE, events);

  // Synchronize the selected events
  useEffect(() => {
    // Does not cause this component to re-render
    store.getState().model.value[PHASE].selection.synchronize(xform);
  }, [store, xform]);

  return (
    <div {...props}>
      <Actions className='mb-32px' />
      {xform.length ? <EventsSection transformed={xform} /> : <NoMatch phase={PHASE} />}
    </div>
  );
};
