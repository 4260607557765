import { Sorting as T_Sorting } from '@livecontrol/store-utils';
import { useCallback } from 'react';
import type { ReactElement } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import type { DropdownButtonProps } from 'react-bootstrap/DropdownButton';
import styled from 'styled-components';

import Direction = T_Sorting.Direction;

const DdB = styled(DropdownButton)`
  .dropdown-item {
    display: flex;
    justify-content: space-between;
    padding-right: var(--spacer-8px);

    svg {
      font-size: var(--font-size-15px);
    }
  }
`;

const FilterDropwdown = styled.div`
  position:relative;
  button.btn-outline-tertiary {
    color: #0D1438;
    border-color: #8798AD;
  }

  button.btn-outline-tertiary:hover {
    border-color: #8798AD;
  }

  .show > button.btn-outline-tertiary:hover {
    color: #fff !important;
    background-color: #8798AD;
  }

  .dropdown-menu.show {
    background-color:white;
  }

  button.dropdown-toggle {
    position:relative;
    padding:8px 11px 8px 37px;
    color: #0D1438;
    font-size:12px;
    font-weight:600;
    display:flex;
    align-items:center;
    justify-content:center;
  }  

  button.dropdown-toggle:after {
    display:none;
  }

  .icon {
    position: absolute;
    width:20px;
    height:20px;
    top:8px;
    left:11px;
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center center;
    margin-right:6px;
  }

  .filter {
    background-image:url(/assets/icons/Filter-black.svg);
  }  
`;

export function Sorting<T extends string>(props: Sorting.Props<T>): ReactElement {
  const {
    as,
    value: { selected, available },
    onChange,
    ...rest
  } = props;

  const handleChange = useCallback(
    (key: T): void => {
      const direction =
        key === selected.key ? Direction.flip(selected.direction) : Direction.ASCENDING;

      onChange?.({ key, direction });
    },
    [selected, onChange]
  );

  return (
    <FilterDropwdown>
      <DdB variant='outline-tertiary' title='Sort' forwardedAs={as} {...rest}>
        {available.map(({ key, label }): ReactElement => {
          const active = key === selected.key;
          const icon = selected.direction === T_Sorting.Direction.ASCENDING ? 'up' : 'down';

          return (
            <Dropdown.Item
              key={key}
              active={active}
              onClick={(): void => {
                handleChange(key);
              }}
            >
              {label ?? key}
              {active && (
                <span key={icon}>
                  <i className={`fad fa-sort-amount-${icon}`} />
                </span>
              )}
            </Dropdown.Item>
          );
        })}
      </DdB>
      <div className='icon filter'/>
    </FilterDropwdown>
  );
}

export namespace Sorting {
  export interface Value<T extends string> {
    available: { key: T; label?: string }[];
    selected: T_Sorting<T>;
  }

  export type Props<T extends string> = Omit<DropdownButtonProps, 'onChange' | 'title'> & {
    value: Value<T>;
    onChange?: (value: T_Sorting<T>) => void;
  };
}
