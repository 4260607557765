import { gql, useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import type { MutationResult } from '../graphql';

interface TVariables {
  input: {
    value: boolean;
    user_id: number;
    location_id: string;
  }[];
  contactRole: string;
}

interface TData {
  upsertContactRole: {
    id: string;
  };
}

export const MUTATION = gql`
  mutation UpsertContactRole($input: [UpsertContactRoleInput!]!, $contactRole: String!) {
    upsertContactRole(input: $input, contact_role: $contactRole) {
      id
    }
  }
`;

interface Args {
  contactRole: string;
  userId: number;
  locationId: string;
}

export const useUpdateLocationContact = (): [
  (args: Args) => Promise<boolean>,
  MutationResult<boolean, 'success'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [success, setSuccess] = useState<boolean>();

  return [
    useCallback(
      async (args: Args): Promise<boolean> => {
        let success_ = false;

        try {
          // Parse the input arguments

          const input = [
            {
              value: true,
              user_id: args.userId,
              location_id: args.locationId
            }
          ];

          // Execute the GraphQL mutation
          const { data } = await mutation({
            variables: { input, contactRole: args.contactRole }
          });

          success_ = data?.upsertContactRole.id ? data.upsertContactRole.id.length > 0 : false;
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setSuccess(success_);

        return success_;
      },
      [mutation, setError, setSuccess]
    ),
    {
      success,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
