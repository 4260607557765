import { Bool, Str } from '@livecontrol/core-utils';
import _ from 'lodash';
import { EventValidator } from './types';

export const validateEventPassword = (
  newEventInfo: EventValidator.EventInfo
): EventValidator.Errors => {
  const errors: EventValidator.Errors = {};
  const enabled = Bool.normalize(newEventInfo.passwordSettings?.enabled);
  const password = Str.normalize(newEventInfo.passwordSettings?.password);

  if (enabled && !password) {
    _.merge(errors, {
      passwordSettings: {
        password: EventValidator.ErrorMessages.EVENT_PASSWORD_REQUIRED
      }
    });
  }

  return errors;
};
