import cx from 'classnames';
import React from 'react';
import styled from 'styled-components';

const Grid = styled.div`
  clip-path: ellipse(100% 200% at 100% 50%);

  .x-light-theme & {
    background-color: var(--dark);
  }

  .x-dark-theme & {
    background-color: var(--alpha);
  }
`;

const Logo = styled.img`
  width: 60%;
  max-width: 400px;
`;

export const Ornamentation = ({ className }: Ornamentation.Props): React.ReactElement => (
  <Grid
    className={cx(
      'd-flex',
      'flex-column',
      'align-items-center',
      'justify-content-center',
      'h-100',
      className
    )}
  >
    <Logo src='assets/entrance-light.svg' alt='LiveControl Logo' />
  </Grid>
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Ornamentation {
  export interface Props {
    className?: string;
  }
}
