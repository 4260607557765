import cx from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { Badge } from 'react-bootstrap';
import styled from 'styled-components';
import type { Control } from './control';
import { Utils } from './utils';

const StyledBadge = styled(Badge)`
  border: 1px solid #e0e7ff;
  padding: 6px 12px;
  border-radius: 21px;
`;

export const Tags = ({ className, tags, tagClassName, name }: Tags.Props): React.ReactElement => {
  const field = Utils.useField(name);

  const valueSet = useMemo(() => new Set(field?.meta.value ?? []), [field?.meta.value]);

  const onTagSelect = useCallback(
    (tag: string) => (): void => {
      if (valueSet.has(tag)) {
        valueSet.delete(tag);
      } else {
        valueSet.add(tag);
      }

      field?.helpers.setValue([...valueSet]);
    },
    [field?.helpers, valueSet]
  );

  const tagsUI = useMemo(
    () =>
      tags.map(({ value: tagValue, label }) => (
        <StyledBadge
          className={cx(
            'mr-12px cursor-pointer',
            {
              'bg-primary text-white': valueSet.has(tagValue)
            },
            tagClassName
          )}
          onClick={onTagSelect(tagValue)}
          key={tagValue}
        >
          {label ?? tagValue}
        </StyledBadge>
      )),
    [onTagSelect, tagClassName, tags, valueSet]
  );

  return <label className={className}>{tagsUI}</label>;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Tags {
  export type Props = Omit<Control.Props, 'as'> & {
    tags: { value: string; label?: string }[];
    value?: string[];
    onChange?: (value: number) => void;
    className?: string;
    tagClassName?: string;
    name: string;
  };
}
