import { Button } from '@livecontrol/core-ui';
import { Bool } from '@livecontrol/core-utils';
import type { Event } from '@livecontrol/scheduler/model';
import cx from 'classnames';
import qs from 'qs';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FeedbackModal } from './modal';

export const Feedback = ({
  event,
  setShowModalSuccess
}: {
  event: Event;
  setShowModalSuccess: (show: boolean) => void;
}): React.ReactElement | null => {
  const { location, replace } = useHistory();
  const [submitted, setSubmitted] = useState(false);

  const [showModal, setShowModal] = useState(
    Bool.normalize(qs.parse(window.location.search, { ignoreQueryPrefix: true }).feedback)
  );

  const isFeedbackSubmitted = !!event.eventFeedback || submitted;

  return (
    <>
      <FeedbackModal
        show={showModal}
        onClose={(): void => {
          replace(location.pathname);
          setShowModal(false);
        }}
        event={event}
        setShowModalSuccess={setShowModalSuccess}
        setSubmitted={setSubmitted}
      />
      <Button
        onClick={(): void => {
          setShowModal(true);
        }}
        variant='outline-primary'
        disabled={isFeedbackSubmitted}
      >
        <span key={isFeedbackSubmitted ? 'one' : 'two'}>
          <i className={cx({ far: !isFeedbackSubmitted, fas: isFeedbackSubmitted }, 'fa-star')} />
        </span>
        {isFeedbackSubmitted ? 'Feedback Submitted' : 'Leave Feedback'}
      </Button>
    </>
  );
};
