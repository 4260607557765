/* eslint-disable @typescript-eslint/no-unnecessary-qualifier */
import type { Time } from '@livecontrol/core-utils';
import { Enum, Num, Obj, Str } from '@livecontrol/core-utils';
import type { Coordinates } from '@livecontrol/gis-utils';
import type { Event } from '@livecontrol/scheduler/model';
import type { DateTime, Duration } from 'luxon';
import qs from 'qs';

export interface Asset {
  id: Asset.ID;
  description?: string;
  duration?: Duration;
  event?: Event.ID;
  mux?: Asset.Mux;
  timestamp: DateTime;
  title: string;
  visibility: Asset.Visibility;
  wasEverPublic: boolean;
  eventPassword?: string;
  locationId?: string;
}

export type EventAnalytics = {
  averageWatchTime: number;
  deviceInfo?: Asset.Analytics.WebPlayer | undefined;
  id?: number;
  percentageWatchTime: number;
  haveAnalytics: boolean;
  totalViews: number;
  uniqueViews: number;
} & {
  event?: Event;
  viewers?: Asset.Analytics.Viewers;
};



const MUX_IMAGE_URL = 'https://image.mux.com';

export namespace Asset {
  export type ID = number;
  export type Like = Asset | ID | string;

  export type withEvent = Asset & {
    eventData?: Event;
  };

  export const toId = (asset?: Like): ID | undefined =>
    Num.normalize(Obj.isObject<Asset>(asset) ? asset.id : asset, { positive: true });

  export enum Visibility {
    Public = 1,
    Private = 2
  }

  export namespace Visibility {
    export const normalize = (value: unknown): Visibility | undefined =>
      Enum.normalize(Visibility, value);

    export const stringValues = new Map<Visibility, string>([
      [Visibility.Public, 'Public'],
      [Visibility.Private, 'Private']
    ]);

    export const toString = (visibility: Visibility): string => stringValues.get(visibility)!;
  }

  export interface Mux {
    assetId: string;
    downloadUrl?: string;
    playbackId: string;
    status: Mux.Status;
    streamUrl?: string;
    thumbnailUrl?: string;
  }

  export interface MuxUpload {
    uploadId?: string;
    muxUploadUrl?: string;
  }

  export interface MuxUploadedAsset {
    assetId: number;
    muxAssetUrl: string;
    duration?: Duration;
  }

  export namespace Mux {
    export type Thumbnail = Partial<{
      width: number;
      height: number;
      time: Time.Encoding.SECONDS;
      fit: 'crop' | 'pad' | 'smartcrop';
      ext: 'jpg' | 'png';
    }>;

    export enum Status {
      Preparing = 1,
      Ready = 2
    }

    export namespace Status {
      export const normalize = (value: unknown): Status | undefined =>
        Enum.normalize(Status, value);
    }

    export const thumbnail = (id?: Mux | string, options: Thumbnail = {}): string | undefined => {
      if (typeof id !== 'string' && id?.thumbnailUrl) {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        return id?.thumbnailUrl;
      }

      const xid = Str.normalize(typeof id === 'string' ? id : id?.playbackId);

      let thumb;

      if (xid) {
        const width = Num.normalize(options.width) ?? 320;
        const height = Num.normalize(options.height);
        const ext = Str.normalize(options.ext) ?? 'jpg';

        const args = {
          width,
          height,
          time: Num.normalize(options.time),
          fit: width && height ? Str.normalize(options.fit) ?? 'smartcrop' : undefined
        };

        thumb = `${MUX_IMAGE_URL}/${xid}/thumbnail.${ext}${qs.stringify(args, {
          addQueryPrefix: true
        })}`;
      }

      return thumb;
    };
  }

  export interface Analytics {
    views?: number;
    viewers?: Analytics.Viewers;
    events?: EventAnalytics[];
    total?: number;
    page?: number;
    platforms: {
      webPlayer?: Analytics.WebPlayer;
      // Not implemented yet
      facebook?: unknown;
      youtube?: unknown;
    };
  }

  export namespace Analytics {
    export interface WebPlayer {
      views?: {
        total: number;
        unique: number;
      };

      retention?: {
        average: Duration;
        percent: number;
      };

      engagement?: {
        timestamp: DateTime;
        viewers: number;
      }[];

      category?: {
        mobile: number;
        desktop: number;
        tablet: number;
        tv?: number;
        other: number;
      };

      os?: {
        ios?: number;
        android?: number;
        chrome?: number;
        linux?: number;
        osx?: number;
        other?: number;
        windows?: number;
      };

      locations?: {
        name: string;
        coordinates: Coordinates;
        views: number;
      }[];
    }

    export interface Viewers {
      capturedEmails: number;
      registeredViewers: number;
      users: {
        name: string;
        lastName: string;
        email: string;
      }[];
    }
  }

  export const Constraints = {
    TITLE_MAX_LENGTH: 255,
    DESCRIPTION_MAX_LENGTH: 5e3
  };
}
