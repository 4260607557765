import { Device, Grid, Theme } from '@livecontrol/core-ui';
import { Matrix, Modal } from '@livecontrol/scheduler/components';
import { User } from '@livecontrol/scheduler/model';
import cx from 'classnames';
import { FormikProvider } from 'formik';
import { useEffect } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Form } from '../../../../components';
import { useForm } from './form';

import G = Matrix.Grid;

const ModalContent = styled(Modal)`
  .modal-dialog {
    max-width: 908px !important;
  }

  .border-bottom {
    border-bottom: 1px solid #d5daea !important;
  }

  .form-group {
    display: contents;
  }
`;

interface Props {
  show?: boolean;
  onClose?: () => void;
  user: User;
}

export const ChangePassword = ({ user, onClose, show = false }: Props): ReactElement => {
  const { formik, loading, success, alert } = useForm({ user });
  const viewport = Theme.useViewport();

  useEffect(() => {
    if (success) {
      formik.resetForm();
      onClose?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <ModalContent
      show={show}
      onHide={(): void => {
        formik.resetForm();
        onClose?.();
      }}
    >
      <FormikProvider value={formik}>
        <Form>
          <Modal.Header className='text-center mb-0' closeButton>
            <img src='assets/icons/Shield-Lock.svg' alt='Shield Lock' />
            <h1 className='font-size-20px font-weight-bold mt-16px mb-16px'> Change Password </h1>
          </Modal.Header>
          <Modal.Body className='text-center mb-32px'>
            <Form.Alert show={alert.show} onClose={alert.hide}>
              {alert.message}
            </Form.Alert>
            <div className='font-size-15px font-weight-bold'>
              <Grid gridTemplateColumns={`${Device.isMobile(viewport) ? 'auto' : '200px 500px'}`}>
                <G.Row>
                  <div
                    className={cx(
                      !Device.isMobile(viewport) ? 'border-bottom pb-24px' : '',
                      'pt-24px ml-8px d-flex flex-column align-items-start justify-content-center text-dark'
                    )}
                  >
                    Current Password
                    {formik.touched.oldPassword && formik.errors.oldPassword && (
                      <span className='font-size-13px mt-8px text-danger text-left'>&nbsp;</span>
                    )}
                  </div>

                  <div className='border-bottom pt-24px pb-24px d-flex flex-column'>
                    <Form.Group
                      name='oldPassword'
                      label={false}
                      error={false}
                      controlProps={{
                        type: 'password',
                        placeholder: 'Currrent Password',
                        maxLength: User.Constraints.PASSWORD_MAX_LENGTH
                      }}
                    />

                    {formik.touched.oldPassword && formik.errors.oldPassword && (
                      <span className='font-size-13px mt-8px text-danger text-left'>
                        {formik.errors.oldPassword}
                      </span>
                    )}
                  </div>
                </G.Row>
                <G.Row>
                  <div
                    className={cx(
                      !Device.isMobile(viewport) ? 'border-bottom pb-24px' : '',
                      'ml-8px pt-24px d-flex flex-column align-items-start justify-content-center text-dark'
                    )}
                  >
                    New Password
                    {formik.touched.newPassword && formik.errors.newPassword && (
                      <span className='font-size-13px mt-8px text-danger text-left'>&nbsp;</span>
                    )}
                  </div>

                  <div className='border-bottom pt-24px pb-24px d-flex flex-column'>
                    <Form.Group
                      name='newPassword'
                      label={false}
                      error={false}
                      controlProps={{
                        type: 'password',
                        placeholder: 'New Password',
                        maxLength: User.Constraints.PASSWORD_MAX_LENGTH
                      }}
                    />

                    {formik.touched.newPassword && formik.errors.newPassword && (
                      <span className='font-size-13px mt-8px text-danger text-left'>
                        {formik.errors.newPassword}
                      </span>
                    )}
                  </div>
                </G.Row>
                <G.Row>
                  <div
                    className={cx(
                      !Device.isMobile(viewport) ? 'pb-24px' : '',
                      'pt-24px ml-8px  d-flex flex-column align-items-start justify-content-center text-dark'
                    )}
                  >
                    Confirm Password
                    {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
                      <span className='font-size-13px mt-8px text-danger text-left'>&nbsp;</span>
                    )}
                  </div>

                  <div className='pt-24px d-flex flex-column'>
                    <Form.Group
                      name='passwordConfirmation'
                      label={false}
                      error={false}
                      controlProps={{
                        type: 'password',
                        placeholder: 'Confirm Password',
                        maxLength: User.Constraints.PASSWORD_MAX_LENGTH
                      }}
                    />

                    {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
                      <span className='font-size-13px mt-8px text-danger text-left'>
                        {formik.errors.passwordConfirmation}
                      </span>
                    )}
                  </div>
                </G.Row>
              </Grid>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Modal.Submit
              loading={loading}
              variant='primary font-size-14px font-weight-bold pl-40px pr-40px'
            >
              <img className='mr-8px mb-4px' src='assets/icons/Key.svg' alt='key' />
              Update Password
            </Modal.Submit>
            <Modal.Button
              variant='bg-white text-tertiary font-size-14px font-weight-bold'
              onClick={(): void => {
                formik.resetForm();
                onClose?.();
              }}
            >
              Cancel
            </Modal.Button>
          </Modal.Footer>
        </Form>
      </FormikProvider>
    </ModalContent>
  );
};
