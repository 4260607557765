import type { CSSProperties, ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import { colors } from './utils';

interface Props {
  width?: number | string;
  height?: number | string;
  style?: CSSProperties;
  isTablet?: boolean;
}

export const LocationsLoader = ({ height, style, width, isTablet }: Props): ReactElement => (
  <ContentLoader
    speed={2}
    width={width}
    height={height}
    viewBox={isTablet ? '0 0 600 175' : '0 0 300 350'}
    {...colors}
    style={style}
  >
    {isTablet ? (
      <>
        <rect x='0' y='0' rx='0' ry='0' width='300' height='175' />
        <rect x='330' y='40' rx='0' ry='0' width='300' height='12' />
        <rect x='330' y='60' rx='0' ry='0' width='300' height='12' />
        <rect x='330' y='80' rx='0' ry='0' width='300' height='12' />
        <rect x='330' y='100' rx='0' ry='0' width='300' height='12' />
        <rect x='330' y='120' rx='0' ry='0' width='300' height='12' />
      </>
    ) : (
      <>
        <rect x='0' y='0' rx='0' ry='0' width='300' height='232' />
        <rect x='0' y='260' rx='0' ry='0' width='300' height='12' />
        <rect x='0' y='280' rx='0' ry='0' width='300' height='12' />
        <rect x='0' y='300' rx='0' ry='0' width='300' height='12' />
        <rect x='0' y='320' rx='0' ry='0' width='300' height='12' />
        <rect x='0' y='340' rx='0' ry='0' width='300' height='12' />
      </>
    )}
  </ContentLoader>
);
