import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Obj } from '@livecontrol/core-utils';
import { Account } from '@livecontrol/scheduler/model';
import { useEffect, useState } from 'react';
import type { QueryResult } from '../graphql';
import * as Dbx from './dbx';

interface TVariables {
  id: number;
}

interface TData {
  getAccount?: Dbx.Record;
  getSubUsersForAccount?: {
    result?: Dbx.SubUserAccountsRecord[];
  };
}

export const QUERY = gql`
  query GetAccount($id: Float!) {
    getAccount(input: { id: $id }) {
      ...StandardAccountResponse
    }
    getSubUsersForAccount(accountId: $id) {
      id
      success
      result {
        ...StandardSubUserAccountsResponse
      }
    }
  }
  ${Dbx.StandardAccountResponse}
  ${Dbx.StandardSubUserAccountsResponse}
`;

export const useAccount = (args: Account.Like): QueryResult<Account, 'account'> => {
  const [error, setError] = useState<Error | undefined>();
  const [account, setAccount] = useState<Account | undefined>();

  const variables = {
    id: Account.toId(args)
  };

  if (!Obj.isHydrated(variables)) {
    return { loading: false, error: Exception.KABOOM };
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  const { loading, data } = useQuery<TData, TVariables>(QUERY, {
    variables,
    onError() {
      setError(Exception.KABOOM);
    }
  });

  useEffect(() => {
    let account_;
    let error_;

    if (data) {
      account_ = Dbx.normalize(data.getAccount, data.getSubUsersForAccount?.result);

      if (!account_) {
        error_ = Exception.NOT_FOUND;
      }
    }

    setAccount(account_);
    setError(error_);
  }, [data]);

  return { loading, error, account };
};
