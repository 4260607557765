import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Form } from '../../../../components';

export const Submit = ({ text, onBack, hideBackBtn = false }: SubmitProps): React.ReactElement => {
  const currentLocation = useLocation();

  return (
    <>
      <Form.Submit className='btn custom-primary-btn' minimum='md'>
        {text ? text : 'Next'}
      </Form.Submit>
      {!hideBackBtn && (
        <Link
          className='text-secondary custom-primary-link'
          to={currentLocation.pathname}
          onClick={onBack}
        >
          Go Back
        </Link>
      )}
    </>
  );
};

export interface SubmitProps {
  text?: string;
  onBack: () => void;
  hideBackBtn?: boolean;
}
