import { JaySON, Obj } from '@livecontrol/core-utils';
import type { Asset, Destination, Event } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { EventValidator } from '@livecontrol/scheduler/validator';
import _ from 'lodash';
import type { Duration } from 'luxon';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Storage } from '../../../store';
import { MobileContextStore } from './mobile-context';

export const CreateMobileInfoContextProvider = (
  props: React.PropsWithChildren<unknown>
): React.ReactElement => {
  const { uuid } = useParams<{ uuid: string }>();

  const storedEventDetails = Obj.normalize<{
    eventType?: EventValidator.ProductionType;
    eventInfo?: EventValidator.EventInfo & {
      isValid: boolean;
      start: string;
      end: string;
      repeatSettings: { repeatUntil: { stopRepeatingOn: string } };
    };
    clientContactDetails?: Event.ClientContactDetails;
    eventDestinations?: Destination[];
    zoomDetails?: Event.ZoomDetails;
    clientBookingPaymentMethod?: EventValidator.ClientBookingPaymentMethod;
    simulatedLiveAssetDetails?: Asset.MuxUploadedAsset;
    simulatedLiveFileUpload?: Asset.MuxUpload & { fileName: string; videoDuration: Duration };
    simulatedLiveAssetIsUpload: boolean;
  }>(JaySON.parse(Storage.getItem(uuid) ?? ''));

  const [eventType, setEventType] = useState<EventValidator.ProductionType | undefined>(
    storedEventDetails?.eventType
  );

  const [eventInfo, setEventInfo] = useState<EventValidator.EventInfo & { isValid: boolean }>(
    storedEventDetails?.eventInfo
      ? _.merge(storedEventDetails.eventInfo, {
          start: DateTime.fromISO(storedEventDetails.eventInfo.start),
          end: DateTime.fromISO(storedEventDetails.eventInfo.end),
          repeatSettings: {
            repeatFrequency: {
              daysOfWeek: new Set(
                storedEventDetails.eventInfo.repeatSettings.repeatFrequency?.daysOfWeek ?? []
              )
            },
            repeatUntil: {
              stopRepeatingOn: DateTime.fromISO(
                storedEventDetails.eventInfo.repeatSettings.repeatUntil.stopRepeatingOn
              )
            }
          }
        })
      : {
          isValid: false
        }
  );

  const [clientContactDetails, setClientContactDetails] = useState<
    Event.ClientContactDetails | undefined
  >(storedEventDetails?.clientContactDetails);

  const [eventDestinations, setEventDestinations] = useState<Destination[]>(
    storedEventDetails?.eventDestinations ?? [Scheduler.Destination.LC_WEBPLAYER]
  );

  const [zoomDetails, setZoomDetails] = useState<Event.ZoomDetails | undefined>(
    storedEventDetails?.zoomDetails
  );

  const [clientBookingPaymentMethod, setClientBookingPaymentMethod] = useState<
    EventValidator.ClientBookingPaymentMethod | undefined
  >(storedEventDetails?.clientBookingPaymentMethod);

  const [simulatedLiveAssetDetails, setSimulatedLiveAssetDetails] = useState<
    Asset.MuxUploadedAsset | undefined
  >(storedEventDetails?.simulatedLiveAssetDetails);

  const [simulatedLiveFileUpload, setSimulatedLiveFileUpload] = useState<
    (Asset.MuxUpload & { fileName?: string; videoDuration: Duration }) | undefined
  >(storedEventDetails?.simulatedLiveFileUpload);

  const [simulatedLiveAssetIsUpload, setSimulatedLiveAssetIsUpload] = useState(
    storedEventDetails?.simulatedLiveAssetIsUpload ?? false
  );

  return (
    <MobileContextStore.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        store: {
          eventType,
          eventInfo,
          clientContactDetails,
          eventDestinations,
          zoomDetails,
          clientBookingPaymentMethod,
          simulatedLiveAssetDetails,
          simulatedLiveFileUpload,
          simulatedLiveAssetIsUpload,
          setEventType,
          setEventInfo,
          setClientContactDetails,
          setEventDestinations,
          setZoomDetails,
          setClientBookingPaymentMethod,
          setSimulatedLiveAssetDetails,
          setSimulatedLiveFileUpload,
          setSimulatedLiveAssetIsUpload
        }
      }}
    >
      {props.children}
    </MobileContextStore.Provider>
  );
};
