import { useApolloClient } from '@apollo/client';
import type { Event } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Store } from '../../store';

export const useYoutubeAuth = (
  code: string,
  uuid: string,
  eventId: Event.ID | undefined
): { error: unknown; loading: boolean } => {
  const account = Store.Account.useAccount();
  const history = useHistory();
  const location = useLocation();
  const apolloClient = useApolloClient();
  const [error, setError] = useState<string | false>(false);

  const [mutation, { loading }] = Scheduler.Destination.useYouTubeAuthorizationCallback();

  useEffect(() => {
    mutation({ account, code, callbackPath: location.pathname.slice(1) })
      .then((value) => {
        if (value?.youtubeAuthorizationCallback.authorization) {
          if (eventId) {
            history.push(`/schedule/edit/${eventId}/${uuid}`);
          } else {
            history.push(`/schedule/create/${uuid}`);
          }
        }
      })
      .catch((): void => {
        setError('Error trying to authenticate YouTube connection.');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, apolloClient, history, account, mutation]);

  return { error, loading };
};

export const useFacebookAuth = (
  code: string,
  uuid: string,
  eventId: Event.ID | undefined
): { error: string | false; loading: boolean } => {
  const account = Store.Account.useAccount();
  const history = useHistory();
  const location = useLocation();
  const apolloClient = useApolloClient();

  const [mutation, { loading }] = Scheduler.Destination.useFacebookAuthorizationCallback();
  const [error, setError] = useState<string | false>(false);

  useEffect(() => {
    mutation({ account, code, callbackPath: location.pathname.slice(1) })
      .then((value) => {
        if (value?.facebookAuthorizationCallback.authorization) {
          if (eventId) {
            history.push(`/schedule/edit/${eventId}/${uuid}`);
          } else {
            history.push(`/schedule/create/${uuid}`);
          }
        }
      })
      .catch((): void => {
        setError('Error trying to authenticate Facebook connection.');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, apolloClient, history, account, mutation]);

  return { error, loading };
};
