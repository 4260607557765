import { gql, useMutation } from '@apollo/client';
import type { ApolloCache, ApolloError, FetchResult } from '@apollo/client';
import { Str } from '@livecontrol/core-utils';
import type { Event } from '@livecontrol/scheduler/model';
import { Account } from '@livecontrol/scheduler/model';
import { useCallback, useState } from 'react';
// eslint-disable-next-line import/no-internal-modules
import { QUERY } from '../account/use-account';
import { Errors } from '../graphql';
import type { MutationResult } from '../graphql';
import * as Dbx from './dbx';

interface TVariables {
  ids: number[];
  reason?: string;
}

interface TData {
  deleteEvents: {
    event_id: number;
    deleted: boolean;
  }[];
}

const MUTATION = gql`
  mutation DeleteEvents($ids: [Int!]!, $reason: String) {
    deleteEvents(eventIds: $ids, reason: $reason) {
      event_id
      deleted
    }
  }
`;

interface Args {
  ids: Event.Like | Event.Like[];
  reason?: string;
  account: Account.Like;
}

interface Summary {
  success: number[];
  failure: number[];
}

export const useDelete = (): [
  (args: Args) => Promise<Summary | undefined>,
  MutationResult<Summary, 'summary'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION, {
    update(cache: ApolloCache<unknown>, { data }: FetchResult<TData>): void {
      if (data?.deleteEvents) {
        const errs: number[] = [];

        // Evict the deleted events from the cache
        data.deleteEvents.forEach((item) => {
          const identity = item.deleted
            ? cache.identify({ id: item.event_id, __typename: Dbx.__typename })
            : undefined;

          if (!identity || !cache.evict({ id: identity })) {
            errs.push(item.event_id);
          }
        });

        if (errs.length) {
          // eslint-disable-next-line no-console
          console.error(`Unable to evict events: ${errs.join(', ')}`);
        }

        cache.gc();
      }
    }
  });

  const [error, setError] = useState<Error | undefined>();
  const [summary, setSummary] = useState<Summary | undefined>();

  return [
    useCallback(
      async (args: Args): Promise<Summary | undefined> => {
        let summary_: Summary | undefined;

        try {
          // Parse the input arguments
          const variables = {
            ids: Dbx.toEventIds(args.ids),
            reason: Str.normalize(args.reason)
          };

          // Validate the input
          if (variables.ids.length) {
            // Execute the GraphQL mutation
            const response = await mutation({
              variables,
              refetchQueries: [
                {
                  query: QUERY,
                  variables: {
                    id: Account.toId(args.account)!
                  }
                }
              ]
            })
              .then(({ data }: FetchResult<TData>) => data?.deleteEvents)
              .catch((_error: ApolloError) => {
                throw Errors.serverError();
              });

            // Parse the server response
            summary_ = {
              success: [],
              failure: []
            };

            for (const item of response ?? []) {
              summary_[item.deleted ? 'success' : 'failure'].push(item.event_id);
            }
          }
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setSummary(summary_);

        return summary_;
      },
      [mutation, setError, setSummary]
    ),
    {
      summary,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
