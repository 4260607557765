import { Button } from '@livecontrol/core-ui';
import { Unicode } from '@livecontrol/core-utils';
import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Callout, Section } from './common';

const TAB = _.pad('', 3, Unicode.NBSP);

const Grid = styled.div`
  display: grid;
  gap: var(--spacer-12px);
  grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
  justify-items: start;
  align-items: center;
  margin-bottom: var(--spacer-24px);
`;

const variants = [
  'primary',
  'secondary',
  'success',
  'danger',
  'dark',
  'outline-primary',
  'outline-secondary',
  'outline-success',
  'outline-danger',
  'outline-dark'
];

const label = (variant: string): string => _.upperFirst(variant.replace('outline-', ''));

export const Buttons = (): React.ReactElement => (
  <Section name='Buttons'>
    <h2>Small</h2>

    <Callout>
      <code>
        &lt;Button size=&apos;sm&apos; variant=&apos;[outline-]primary|secondary...&apos;&gt;
        <em>label</em>&lt;/Button&gt;
      </code>
    </Callout>

    <Grid>
      {variants.map((variant: string) => (
        <Button size='sm' key={variant} variant={variant}>
          {label(variant)}
        </Button>
      ))}
    </Grid>

    <h2>Medium</h2>

    <Callout>
      <code>
        &lt;Button size=&apos;md&apos; variant=&apos;...&apos;&gt;
        <em>label</em>
        &lt;/Button&gt;
      </code>
    </Callout>

    <Grid>
      {variants.map((variant: string) => (
        <Button key={variant} variant={variant} size='md'>
          {label(variant)}
        </Button>
      ))}
    </Grid>

    <h2>Large</h2>

    <Callout>
      <code>
        &lt;Button size=&apos;lg&apos; variant=&apos;...&apos;&gt;
        <em>label</em>&lt;/Button&gt;
      </code>
    </Callout>

    <Grid>
      {variants.map((variant: string) => (
        <Button size='lg' key={variant} variant={variant}>
          {label(variant)}
        </Button>
      ))}
    </Grid>

    <h2>Responsive</h2>

    <Callout>
      <code>
        &lt;Button
        <br />
        {TAB}size=&apos;auto&apos; {'{/* or leave undefined */}'}
        <br />
        {TAB}minimum=&apos;sm|md|lg&apos; {'{/* optional */}'}
        <br />
        {TAB}maximum=&apos;sm|md|lg&apos; {'{/* optional */}'}
        <br />
        {TAB}...
      </code>
    </Callout>

    <Grid>
      <Button size='auto'>Default</Button>
      <Button minimum='md'>minimum=&apos;md&apos;</Button>
      <Button maximum='md'>maximum=&apos;md&apos;</Button>
    </Grid>

    <h2>Wide</h2>

    <Callout>
      <code>&lt;Button className=&apos;btn-wide&apos; ...</code>
    </Callout>

    <Grid>
      <Button className='btn-wide' size='sm' variant='primary'>
        Small
      </Button>
      <Button className='btn-wide' size='md' variant='primary'>
        Medium
      </Button>
      <Button className='btn-wide' size='lg' variant='primary'>
        Large
      </Button>
    </Grid>

    <h2>Extra-Wide</h2>

    <Callout>
      <code>&lt;Button className=&apos;btn-xwide&apos; ...</code>
    </Callout>

    <Grid>
      <Button className='btn-xwide' size='sm' variant='dark'>
        Small
      </Button>
      <Button className='btn-xwide' size='md' variant='dark'>
        Medium
      </Button>
      <Button className='btn-xwide' size='lg' variant='dark'>
        Large
      </Button>
    </Grid>

    <h2>Icons</h2>

    <Callout>
      <code>
        &lt;Button&gt;&lt;i className=&apos;fa? fa-?&apos; /&gt;<em>label</em>&lt;/Button&gt;
      </code>
    </Callout>

    <Grid>
      <Button size='sm'>
        <i className='far fa-check' />
        Small
      </Button>

      <Button size='md'>
        <i className='far fa-info-circle' />
        Medium
      </Button>

      <Button size='lg'>
        <i className='fad fa-spin fa-spinner-third' />
        Large
      </Button>

      <Button>
        Right Icon
        <i className='fas fa-arrow-alt-right right' />
      </Button>

      <Button>
        <i className='fas fa-quote-left' />
        Two Icons
        <i className='fas fa-quote-right right' />
      </Button>
    </Grid>

    <h2>Round</h2>

    <Callout>
      <code>
        &lt;Button className=&apos;btn-round&apos;&gt;&lt;i className=&apos;fa? fa-?&apos;
        /&gt;&lt;/Button&gt;
      </code>
    </Callout>

    <Grid>
      <Button className='btn-round'>
        <span className='fas fa-cog' />
        <span className='sr-only'>Cog</span>
      </Button>

      <Button className='btn-round btn-tint'>
        <span className='fas fa-cog' />
        <span className='sr-only'>Cog</span>
      </Button>

      <Button className='btn-round' variant='outline-success'>
        <span className='fas fa-cog fa-spin' />
        <span className='sr-only'>Loading</span>
      </Button>

      <Button className='btn-round' variant='outline-dark'>
        <span className='fas fa-cog fa-2x' />
        <span className='sr-only'>Cog</span>
      </Button>
    </Grid>
  </Section>
);
