import cx from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';
import { Device } from '../../device';
import { Theme } from '../../theme';
import { Descriptor } from './descriptor';

const StyledWrapper = styled.div<{ $isMobile: boolean }>`
  ${({ $isMobile }): SimpleInterpolation =>
    $isMobile
      ? css`
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .customization-switch {
            padding-left: 3rem;
            margin-bottom: 4px;
          }
        `
      : css`
          display: flex;
          align-items: center;
        `}

  /* Set switch cursor style to pointer. */
  .custom-switch .custom-control-input {
    &,
    &:checked {
      & ~ .custom-control-label::before {
        cursor: pointer;
      }
    }
  }

  .save-indicator {
    transition: 0.2s opacity;
    opacity: 0;

    &.saved {
      opacity: 1;
    }
  }
`;

export const SwitchField = ({
  title,
  titleClassName,
  description,
  descriptionClassName,
  disabled,
  checked,
  onChange,
  loading,
  className,
  noSaveIndicator = false,
  name,
  abledDescription = true,
  spaceInBetween = true
}: SwitchField.SwitchFieldProps): React.ReactElement => {
  const viewport = Theme.useViewport();
  const isMobile = Device.isMobile(viewport);

  const [saved, setSaved] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const handleChange = useCallback(
    (value: boolean) => (): void => {
      const result = onChange(value);

      if (result && !disabled) {
        result
          .then((modal) => {
            if (modal) {
              setIsModal(modal);
            } else {
              setSaved(true);
            }
          })
          .catch(() => {
            // Ignore.
          });
      }
    },
    [disabled, onChange]
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (checked && isModal) {
      setSaved(true);
      setIsModal(false);
    }

    if (saved) {
      timeout = setTimeout(() => {
        setSaved(false);
      }, 3000);
    }

    return (): void => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [saved, checked, isModal]);

  return abledDescription ? (
    <button
      type='button'
      className='x-pseudo text-left'
      disabled={disabled}
      style={{ width: '100%' }}
      onClick={handleChange(!checked)}
    >
      <Descriptor
        title={title}
        titleClassName={titleClassName}
        description={description}
        descriptionClassName={descriptionClassName}
        className={cx('d-flex align-items-start', !disabled && 'cursor-pointer', className)}
      >
        {spaceInBetween ? <div className='flex-grow-1' /> : null}

        <StyledWrapper $isMobile={isMobile}>
          <Form.Switch
            className='customization-switch'
            checked={checked}
            disabled={disabled}
            onChange={handleChange(!checked)}
            style={{
              opacity: loading || disabled ? 0.5 : 1,
              pointerEvents: 'none'
            }}
            name={name}
          />
          <span
            className={cx(
              'text-success font-weight-bold text-center font-size-12px save-indicator',
              'ml-md-8px',
              {
                saved,
                'd-none': noSaveIndicator
              }
            )}
            style={{
              whiteSpace: 'nowrap'
            }}
          >
            <i className='mr-8px fa fa-check' />
            Saved
          </span>
        </StyledWrapper>
      </Descriptor>
    </button>
  ) : (
    <Descriptor
      title={title}
      titleClassName={titleClassName}
      description={description}
      descriptionClassName={descriptionClassName}
      className={cx('d-flex align-items-start', className)}
    >
      {spaceInBetween ? <div className='flex-grow-1' /> : null}
      <button
        type='button'
        className='x-pseudo text-left'
        disabled={disabled}
        onClick={handleChange(!checked)}
      >
        <StyledWrapper $isMobile={isMobile}>
          <Form.Switch
            className='customization-switch'
            checked={checked}
            disabled={disabled}
            onChange={handleChange(!checked)}
            style={{
              opacity: loading || disabled ? 0.5 : 1,
              pointerEvents: 'none'
            }}
            name={name}
          />
          <span
            className={cx(
              'text-success font-weight-bold text-center font-size-12px save-indicator',
              'ml-md-8px',
              {
                saved,
                'd-none': noSaveIndicator
              }
            )}
            style={{
              whiteSpace: 'nowrap'
            }}
          >
            <i className='mr-8px fa fa-check' />
            Saved
          </span>
        </StyledWrapper>
      </button>
    </Descriptor>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace SwitchField {
  export interface SwitchFieldProps {
    abledDescription?: boolean;
    title: React.ReactNode;
    titleClassName?: string;
    description?: React.ReactNode;
    descriptionClassName?: string;
    checked: boolean;
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    onChange: (value: boolean) => Promise<boolean | void> | void;
    loading?: boolean;
    disabled?: boolean;
    className?: string;
    noSaveIndicator?: boolean;
    name?: string;
    spaceInBetween?: boolean;
  }
}
