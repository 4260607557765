import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Obj } from '@livecontrol/core-utils';
import { Phone } from '@livecontrol/locale-us';
import { Account } from '@livecontrol/scheduler/model';
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-internal-modules
import type { SubscriptionAccountInfo } from '../../../../customer-dashboard/frontend/src/routes/subscription/billing';
import type { QueryResult } from '../graphql';

interface TVariables {
  clientId: number;
}

interface TData {
  getSubscriptionAccountInfo: {
    plan: {
      credits_length: number;
      additional_credit_price: number;
      payment_cycle: string;
      renewal_date: string;
      contract_file: string;
    };
    subscription: {
      next_billing_date?: string;
      items: [
        {
          cost: number;
          description: string;
          id: number;
        }
      ];
    };
    billing_contact?: {
      id: string;
      email: string;
      first_name: string;
      last_name: string;
      phone_number: Phone;
      organization_name: string;
      organization_address: string;
      preferred_contact_method: string;
    };
    invoices: SubscriptionAccountInfo.Invoice[];
  };
}

export const QUERY = gql`
  query GetSubscriptionAccountInfo($clientId: Float!) {
    getSubscriptionAccountInfo(client_id: $clientId) {
      plan {
        credits_length
        additional_credit_price
        payment_cycle
        renewal_date
        contract_file
      }
      subscription {
        next_billing_date
        items {
          cost
          description
          id
        }
      }
      billing_contact {
        id
        email
        first_name
        last_name
        phone_number
        organization_name
        organization_address
        preferred_contact_method
      }
      invoices {
        date
        price
        status
        description
        id
        hosted_url
      }
    }
  }
`;

export const useSubscriptionAccountInfo = (
  args: Account.Like
): QueryResult<SubscriptionAccountInfo, 'billingInfo'> => {
  const [error, setError] = useState<Error | undefined>();
  const [billingInfo, setBillingInfo] = useState<SubscriptionAccountInfo | undefined>();

  // Parse the input arguments
  const variables = {
    clientId: Account.toId(args)
  };

  // Validate the input
  if (!Obj.isHydrated(variables)) {
    return { loading: false, error: Exception.KABOOM };
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  // Prepare the query
  const { loading, data } = useQuery<TData, TVariables>(QUERY, {
    variables,
    onError() {
      setError(Exception.KABOOM);
    },
    fetchPolicy: 'network-only'
  });

  // When available, parse server response
  useEffect(() => {
    let billingInfo_;
    let error_;

    if (data) {
      billingInfo_ = {
        plan: {
          creditsLength: data.getSubscriptionAccountInfo.plan.credits_length,
          additionalCreditPrice: data.getSubscriptionAccountInfo.plan.additional_credit_price,
          paymentCycle: data.getSubscriptionAccountInfo.plan.payment_cycle,
          renewal_date: data.getSubscriptionAccountInfo.plan.renewal_date,
          contractFile: data.getSubscriptionAccountInfo.plan.contract_file
        },
        subscription: {
          nextBillingDate: data.getSubscriptionAccountInfo.subscription.next_billing_date ?? undefined,
          items: data.getSubscriptionAccountInfo.subscription.items
        },
        billingContact: {
          id: data.getSubscriptionAccountInfo.billing_contact?.id ?? '',
          email: data.getSubscriptionAccountInfo.billing_contact?.email ?? '',
          firstName: data.getSubscriptionAccountInfo.billing_contact?.first_name ?? '',
          lastName: data.getSubscriptionAccountInfo.billing_contact?.last_name ?? '',
          phoneNumber: Phone.normalize(data.getSubscriptionAccountInfo.billing_contact?.phone_number),
          organizationName: data.getSubscriptionAccountInfo.billing_contact?.organization_name ?? '',
          organizationAddress: data.getSubscriptionAccountInfo.billing_contact?.organization_address ?? '',
          preferredContactMethod:
            data.getSubscriptionAccountInfo.billing_contact?.preferred_contact_method ?? ''
        },
        invoices: data.getSubscriptionAccountInfo.invoices
      };
    }

    setBillingInfo(billingInfo_);
    setError(error_);
  }, [data]);

  return { loading, error, billingInfo };
};
