import React from 'react';
import type { FeedbackProps } from 'react-bootstrap/Feedback';
import B_Feedback from 'react-bootstrap/Feedback';
import { Utils } from './utils';

export const Feedback = (props: Feedback.Props): React.ReactElement => {
  const { name: _unused, type, ...rest } = props;
  const field = Utils.useField(props);

  let { children } = props;

  // If formik is enabled, generate the error message
  if (field?.meta.error) {
    children = field.meta.error;
  }

  return (
    <B_Feedback {...rest} type={type ?? 'invalid'}>
      {children}
    </B_Feedback>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Feedback {
  export type Props = React.PropsWithChildren<FeedbackProps> & { name?: string };
}
