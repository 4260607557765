import { Auth, Grid } from '@livecontrol/core-ui';
import { User } from '@livecontrol/scheduler/model';
import { FormikProvider } from 'formik';
import { useCallback } from 'react';
import type { ReactElement } from 'react';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { Form } from '../../components';
import { Store } from '../../store';
import type { Props } from './form';
import { useForm } from './form';

const { Constraints, Industry } = User;

const Panel = styled(Auth.Panel)`
  max-width: calc(var(--content-width) * 0.75);
`;

const Content = (props: Props): ReactElement => {
  const { formik, loading, alert } = useForm(props);

  const setPlaceId = useCallback(
    (placeId: string) => {
      formik.setFieldValue('place_id', placeId);
    },
    [formik]
  );

  return (
    <>
      <div id='map'/>
      <Form.Alert show={alert.show} onClose={alert.hide}>
        {alert.message}
      </Form.Alert>

      <FormikProvider value={formik}>
        <Form autoComplete='off'>
          <Grid.AutoRepeat className='column-gap-16px' min={250}>
            <Form.Group
              name='first'
              label='First Name'
              controlProps={{
                autoFocus: true,
                maxLength: Constraints.NAME_MAX_LENGTH
              }}
            />

            <Form.Group
              name='last'
              label='Last Name'
              controlProps={{
                maxLength: Constraints.NAME_MAX_LENGTH
              }}
            />

            <Form.Group
              name='email'
              label='Email Address'
              controlProps={{
                maxLength: Constraints.EMAIL_MAX_LENGTH
              }}
            />

            <Form.Group name='phone' label='Phone Number' control={Form.Phone}/>
            <Form.Group
              name='address'
              style={{
                gridColumn: 'span 2'
              }}
              control={Form.AddressSelector}
              controlProps={{
                handleSelect: setPlaceId
              }}
            />
            <Form.Group
              name='organization'
              label='Organization Name'
              controlProps={{
                maxLength: Constraints.NAME_MAX_LENGTH
              }}
            />

            <Form.Group
              name='industry'
              label='Industry'
              controlProps={{
                as: 'select'
              }}
            >
              <option key='none' value={0} disabled>
                Select An Industry
              </option>
              {[...Industry.stringValues.entries()].map(([industry, label]) => (
                <option key={industry} value={industry}>
                  {label}
                </option>
              ))}
            </Form.Group>

            <Form.Group
              name='password'
              controlProps={{
                type: 'password',
                autoComplete: 'new-password'
              }}
            />

            <Form.Group
              name='confirmation'
              label='Confirm Password'
              controlProps={{
                type: 'password'
              }}
            />

            <Form.Group
              label={false}
              name='agree'
              control={Form.Check}
              controlProps={{
                type: 'checkbox',
                label: (
                  <>
                    By submitting, I agree to the
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://www.livecontrol.io/terms'
                      className='ml-4px'
                    >
                      Terms &amp; Conditions
                    </a>
                    .
                  </>
                )
              }}
              style={{
                gridColumn: '1 / -1'
              }}
            />
          </Grid.AutoRepeat>

          <div className='d-grid place-items-center pt-16px'>
            <Form.Submit className='btn-xwide' loading={loading} size='lg'>
              Sign Up
            </Form.Submit>
          </div>
        </Form>
      </FormikProvider>
    </>
  );
};

const Page = (): ReactElement => (
  <Auth.Layout>
    <Panel title='Sign Up'>
      <Content/>
    </Panel>

    <Auth.Linkbox>
      Already have an account?
      <Link to='/login'>
        <strong>Login here!</strong>
      </Link>
    </Auth.Linkbox>
  </Auth.Layout>
);

export const SignUp = (): ReactElement =>
  // Is the user already logged in
  Store.Token.useToken() ? <Redirect to='/'/> : <Page/>;
