import { Button } from '@livecontrol/core-ui';
import React from 'react';
import type { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Store } from '../../store';

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title-text {
    font-size: 28px;
    font-weight: 700;
    color: #2e384d;
  }
  .icon {
    width: 20px;
    height: 20px;
    background-size: cover;
    background-position: center center;
    margin-right: 8px;
  }

  .new-event {
    background-image: url(/assets/icons/New-event.svg);
  }

  button {
    display: flex;
    padding-top: 7px;
    padding-bottom: 7px;

    .text {
      font-weight: 600;
      font-size: 12px;
    }
  }
`;

export const Header = (): ReactElement => {
  const history = useHistory();
  const me = Store.User.useMe();

  const handleNewEventClick = React.useCallback(() => {
    history.push('/schedule/create');
  }, [history]);

  return (
    <ActionsContainer>
      <div className='title-text'>Schedule</div>

      {me.permissions.someLocationHasEventManagmentEnabled && (
        <Button onClick={handleNewEventClick}>
          <div className='icon new-event' />
          <div className='text'> Add New Event</div>
        </Button>
      )}
    </ActionsContainer>
  );
};
