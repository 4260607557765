import { Device } from '@livecontrol/core-ui';
import type { ReactElement } from 'react';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Blueprint } from '../blueprint';
import { Context } from '../store';
import { Hamburger } from './hamburger';

const Logo = styled.div`
  display: flex;
  flex-grow: 1;

  .x-${Device.MOBILE} & {
    -webkit-box-pack: center;
    justify-content: center;
  }

  img {
    width: 200px;
  }
`;

const Container = styled.div`
  z-index: 2 !important;
  align-items: center;
  display: flex;
  height: ${Blueprint.Header.height[Device.DESKSTOP]}px;
  padding: 25px var(--spacer-56px);
  width: 100%;
  right: 0;
  z-index: 2;
  position: fixed;

  .x-dark-theme & {
    background-color: var(--beta);
    border-bottom: 1px solid var(--white15);
  }

  .x-light-theme & {
    background-color: white;
    border-bottom: 1px solid var(--light);
  }

  .x-${Device.MOBILE} & {
    height: ${Blueprint.Header.height[Device.MOBILE]}px;
    padding: 0 var(--spacer-16px);
    padding-left: ${Blueprint.Sidebar.width.collapsed + 16} ${Logo} {
      justify-content: center;

      div {
        transform: scale(0.8);
      }
    }
  }
`;

export const Header = ({
  children,
  logo,
  className
}: React.PropsWithChildren<{ logo: React.ReactElement; className?: string }>): ReactElement => {
  const { viewport, sidebar } = useContext(Context);
  const { isExpanded } = sidebar;

  const sidebarPaddingLeft = Device.isTablet(viewport)
    ? Blueprint.Sidebar.width.collapsed
    : Device.isDesktop(viewport)
    ? Blueprint.Sidebar.width[isExpanded ? 'expanded' : 'collapsed']
    : 0;

  const paddingLeft =
    Device.isTablet(viewport) || Device.isDesktop(viewport)
      ? sidebarPaddingLeft + 59
      : sidebarPaddingLeft + 16;

  return (
    <Container style={{ paddingLeft }} className={className}>
      {Device.isMobile(viewport) && <Hamburger className='mr-12px' />}

      <Logo>
        <Link to='/home'>{logo}</Link>
      </Logo>

      {children}
    </Container>
  );
};
