export const setIntent = (): void => {
  // https://thisthat.dev/border-vs-outline
  document.addEventListener('mousedown', () => {
    document.body.classList.add('intent-mouse');
  });

  document.addEventListener('keydown', (e) => {
    if (e.key === 'Tab') {
      document.body.classList.remove('intent-mouse');
    }
  });
};
