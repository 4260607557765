import { gql, useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import type { MutationResult } from '../../graphql';

interface TData {
  clientId: number;
  input: PerissionsData[];
}

interface PerissionsData {
  destinations: boolean;
  customize_webplayer: boolean;
  billing: boolean;
  admin_access: boolean;
  organization_account_info: boolean;
  id?: number;
}

const MUTATION = gql`
  mutation UpdateManySubUserPermissions($clientId: Float!, $input: [UpdateSubUserPermission!]!) {
    updateManySubUserPermissions(client_id: $clientId, input: $input) {
      error {
        code
        message
      }
      result {
        admin_access
        billing
        id
        organization_account_info
        customize_webplayer
        destinations
      }
    }
  }
`;

export const useUpdateForManySubUserAccount = (): [
  (args: TData) => Promise<TData | undefined>,
  MutationResult<TData, 'response'>
] => {
  const [mutation, result] = useMutation<TData>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [response, setResponse] = useState<TData | undefined>();

  return [
    useCallback(
      async (args: TData): Promise<TData | undefined> => {
        let formatResponse: TData | undefined;

        try {
          const response_ = await mutation({
            variables: {
              clientId: args.clientId,
              input: args.input
            }
          });

          formatResponse = response_.data ? response_.data : undefined;

          setResponse(formatResponse);
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        return formatResponse;
      },
      [mutation, setResponse]
    ),
    {
      response,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
