import { gql, useMutation } from '@apollo/client';
import type { ApolloError, FetchResult } from '@apollo/client';
import { Obj, Str } from '@livecontrol/core-utils';
import { Account } from '@livecontrol/scheduler/model';
import { useCallback, useState } from 'react';
import type { MutationResult } from '../../graphql';
import { Errors } from '../../graphql';

import Plaid = Account.Plaid;

interface TData {
  createPlaidLinkToken?: {
    link_token?: string;
  };
}

interface TVariables {
  account: number;
}

const MUTATION = gql`
  mutation createPlaidLinkToken($account: Int!) {
    createPlaidLinkToken(user: $account) {
      link_token
    }
  }
`;

export const useCreatePlaidLinkToken = (): [
  (account: Account.Like) => Promise<Plaid.Token | undefined>,
  MutationResult<Plaid.Token, 'token'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [token, setToken] = useState<Plaid.Token | undefined>();
  const [error, setError] = useState<Error | undefined>();

  return [
    useCallback(
      async (account: Account.Like): Promise<Plaid.Token | undefined> => {
        let token_: Plaid.Token | undefined;

        try {
          // Parse the input arguments
          const variables = {
            account: Account.toId(account)
          };

          if (!Obj.isHydrated(variables)) {
            throw Errors.badRequest();
          }

          // Execute the GraphQL mutation
          const response = await mutation({ variables })
            .then(({ data }: FetchResult<TData>) => data?.createPlaidLinkToken)
            .catch((_error: ApolloError) => {
              throw Errors.serverError();
            });

          // Parse the server response
          token_ = Str.normalize(response?.link_token);

          if (!token_) {
            throw Errors.serverError();
          }
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setToken(token_);

        return token_;
      },
      [mutation, setError, setToken]
    ),
    {
      token,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
