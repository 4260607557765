/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { Account, Location } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { FormikContextType } from 'formik';
import { useFormik } from 'formik';
import { useRef } from 'react';

export interface Values {
  // This is for useUpsertPermissions
  id: string;
  account: {
    admin: boolean;
  };
  // Checar el tipado para los objetos de las locaciones de los permisos
  [index: string]: Record<string, boolean> | boolean | string | undefined;
}

interface Input {
  location_id: string;
  permissions: Permissions[];
}

interface InputAccount {
  clientId: number;
  input: GlobalSettings[];
}

interface Permissions {
  content_managment: boolean;
  event_managment: boolean;
  customize_production_notes: boolean;
  view_and_download: boolean;
  user_id: number;
  id?: number;
}

interface GlobalSettings {
  destinations: boolean;
  customize_webplayer: boolean;
  billing: boolean;
  organization_account_info: boolean;
  admin_access: boolean;
  id?: number;
}

interface Form {
  formik: FormikContextType<Values>;
  loading: boolean;
  loadingAccount: boolean;
}

export interface Props {
  account: Account;
  subUser?: Account.SubUser[];
  locations?: Location[];
  permisionByUser: boolean;
  handleSubmitSuccess: () => void;
}

export const useForm = ({
  account,
  locations,
  permisionByUser,
  handleSubmitSuccess
}: Props): Form => {
  const [permissionLocationsMutation, { loading }] =
    Scheduler.Account.UserManagement.useUpdateForManySubUserLocations();

  const [permissionAccountMutation, { loading: loadingAccount }] =
    Scheduler.Account.UserManagement.useUpdateForManySubUserAccount();

  const initialValues: Values = useRef({
    // This is for useUpsertPermissions
    locationUser: permisionByUser ? 'user' : 'location',
    account: { admin: account.subUsers[0].permissions.admin },
    id: permisionByUser && locations ? locations[0].id : String(account.subUsers[0].id)
  }).current;

  const formik = useFormik<Values>({
    initialValues,
    validateOnMount: true,
    validateOnChange: false,

    async onSubmit(data: Values, { setTouched }): Promise<void> {
      const input: Input[] = [];

      // if is permisions by User

      if (permisionByUser) {
        // Handle Global Settings

        const permissionsAccount = {
          destinations: (
            data['account' as keyof typeof data] as unknown as {
              webplayer: boolean;
            }
          ).webplayer,
          customize_webplayer: (
            data['account' as keyof typeof data] as unknown as {
              webplayer: boolean;
            }
          ).webplayer,
          billing: (
            data['account' as keyof typeof data] as unknown as {
              billing: boolean;
            }
          ).billing,
          activity_log: (
            data['account' as keyof typeof data] as unknown as {
              activityLog: boolean;
            }
          ).activityLog,
          organization_account_info: (
            data['account' as keyof typeof data] as unknown as {
              admin: boolean;
            }
          ).admin,
          admin_access: (
            data['account' as keyof typeof data] as unknown as {
              admin: boolean;
            }
          ).admin,
          id: Number((data['account' as keyof typeof data] as unknown as { id: string }).id)
        };

        const inputAccount: InputAccount = {
          clientId: account.id,
          input: [permissionsAccount]
        };

        await permissionAccountMutation(inputAccount).then((_): void => {
          setTouched({});
        });

        // permison level location

        locations?.forEach((location) => {
          const assignePermissions = [
            {
              content_managment: (
                data[location.id as keyof typeof data] as unknown as {
                  contentManagment: boolean;
                }
              ).contentManagment,
              event_managment: (
                data[location.id as keyof typeof data] as unknown as {
                  eventManagment: boolean;
                }
              ).eventManagment,
              customize_production_notes: (
                data[location.id as keyof typeof data] as unknown as {
                  productionNotes: boolean;
                }
              ).productionNotes,
              view_and_download: (
                data[location.id as keyof typeof data] as unknown as {
                  viewAndDownload: boolean;
                }
              ).viewAndDownload,
              user_id: Number(data.id)
            }
          ];

          const permission: Input = {
            location_id: location.id,
            permissions: assignePermissions
          };

          input.push(permission);
        });

        await permissionLocationsMutation(input).then((_): void => {
          setTouched({});
          handleSubmitSuccess();
        });

        // permissions by location, dont have handle global settings and only use users one location
      } else {
        const assignePermissions: Permissions[] = [];

        account.subUsers.forEach((user) => {
          assignePermissions.push({
            content_managment: (
              data[user.id as keyof typeof data] as unknown as {
                contentManagment: boolean;
              }
            ).contentManagment,
            event_managment: (
              data[user.id as keyof typeof data] as unknown as {
                eventManagment: boolean;
              }
            ).eventManagment,
            customize_production_notes: (
              data[user.id as keyof typeof data] as unknown as {
                productionNotes: boolean;
              }
            ).productionNotes,
            view_and_download: (
              data[user.id as keyof typeof data] as unknown as {
                viewAndDownload: boolean;
              }
            ).viewAndDownload,
            user_id: Number(user.id)
          });
        });

        const permission: Input = {
          location_id: data.id,
          permissions: assignePermissions
        };

        input.push(permission);

        await permissionLocationsMutation(input).then((_): void => {
          setTouched({});
          handleSubmitSuccess();
        });
      }
    }
  });

  return {
    formik,
    loading,
    loadingAccount
  };
};
