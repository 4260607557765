import { Device, Theme } from '@livecontrol/core-ui';
import { Event } from '@livecontrol/scheduler/model';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import shallow from 'zustand/shallow';
import { Actions as A, Keyword, Location, Period, Sorting } from '../../common';
import { useStore } from '../../store';
import { Delete } from './delete';

const ActionsSection = styled.div`
  &.is-mobile > div {
    margin-right: 8px;
    margin-bottom: 10px;
  }
  .search-section {
    border: solid 1px #8798ad;
    background-color: white;
    input {
      font-size: 12px;
      &::placeholder,
      &::-ms-input-placeholder {
        color: #0d1438 !important;
        opacity: 1;
        font-size: 12px;
      }
      &::placeholder {
        color: #0d1438 !important;
        opacity: 1;
        font-size: 12px;
      }
    }
  }

  &.is-mobile .search-section {
    max-width: 300px;
    height: 36px;
  }
`;

const PHASE = Event.Phase.Upcoming;

export const Actions = (props: A.Props): ReactElement => {
  const [selection, locations] = useStore(
    ({ model }) => [model.value[PHASE].selection, model.value[PHASE].filters.location.available],
    shallow
  );

  const viewport = Theme.useViewport();
  const size = Device.isMobile(viewport) ? 'sm' : 'lg';
  const isMobile = Device.isMobile(viewport);

  return (
    <ActionsSection
      className={`d-flex flex-wrap justify-content-end ${isMobile ? 'is-mobile' : ''}`}
    >
      {!isMobile && (
        <A {...props}>
          <Period phase={PHASE} size={size} />
          {locations.length >= 2 ? <Location phase={PHASE} size={size} /> : null}
          <Sorting phase={PHASE} size={size} />
          {!!selection.selectedEvents.size && <Delete size={size} />}
          <Keyword phase={PHASE} size={size} className='search-section' />
        </A>
      )}
      {isMobile && (
        <>
          <Period phase={PHASE} size={size} />
          {locations.length >= 2 ? <Location phase={PHASE} size={size} /> : null}
          <Sorting phase={PHASE} size={size} />
          {!!selection.selectedEvents.size && <Delete size={size} />}
          <Keyword phase={PHASE} size={size} className='search-section' />
        </>
      )}
    </ActionsSection>
  );
};
