import type { ApolloError, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Num, Obj } from '@livecontrol/core-utils';
import { Errors } from '@livecontrol/scheduler/store';
import type { MutationResult } from '@livecontrol/scheduler/store';
import { useCallback, useState } from 'react';

interface TVariables {
  input: Input;
}

interface Input {
  newPassword: string;
  oldPassword: string;
  passwordConfirmation: string;
  id: number;
}

interface TData {
  updatePassword?: {
    id: number;
  };
}

type Args = Input;

const MUTATION = gql`
  mutation UpdatePassword($input: UpdateUserPassword!) {
    updatePassword(input: $input) {
      email
      id
    }
  }
`;

export const useUpdatePassword = (): [
  (args: Args) => Promise<boolean>,
  MutationResult<boolean, 'success'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [success, setSuccess] = useState(false);

  return [
    useCallback(
      async (args: Input): Promise<boolean> => {
        let success_ = false;

        try {
          // Parse the input arguments
          const variables = {
            input: {
              newPassword: args.newPassword,
              oldPassword: args.oldPassword,
              passwordConfirmation: args.passwordConfirmation,
              id: args.id
            }
          };

          // Validate the input
          if (!Obj.isHydrated(variables)) {
            throw Errors.badRequest();
          }

          // Execute the GraphQL mutation
          const response = await mutation({ variables })
            .then(({ data }: FetchResult<TData>) => data?.updatePassword)
            .catch((error_: ApolloError) => {
              // Was this an unrecoverable Network error?

              // Make an assumption as to why we got a GraphQL error.
              throw new Error(error_.message);
            });

          // Parse the server response
          if (!Num.normalize(response?.id)) {
            throw Errors.serverError();
          }

          // Success!
          success_ = true;
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setSuccess(success_);

        return success_;
      },
      [mutation, setError, setSuccess]
    ),
    {
      success,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
