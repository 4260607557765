import cx from 'classnames';
import type { HTMLAttributes, ReactElement } from 'react';
import styled from 'styled-components';

const TicketPanel = styled.div`
  background-color: #f7fafc;
  border: solid 1px #d5daea;
  border-radius: 5px;
  min-height: 325px;

  .ticket-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    padding: 10px;

    .icon-section {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border: 1px solid #d5daea;
      border-radius: var(--border-radius-sm);
      margin-bottom: 15px;

      .icon {
        width: 20px;
        height: 20px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }

      .satellite {
        background-image: url(/assets/icons/Satellite.svg);
      }
    }

    .title {
      font-weight: 600;
      color: var(--dark);
      font-size: var(--font-size-16px);
      margin-bottom: 4px;
      text-align: center;
    }

    .text {
      font-weight: 600;
      color: var(--gray-light);
      font-size: var(--font-size-13px);
      margin-bottom: 14px;
      text-align: center;
    }

    .button-section {
      display: flex;
      width: 100%;
      justify-content: center;

      a.btn {
        display: flex;
      }
      .icon {
        width: 20px;
        height: 20px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
      .user-add {
        background-image: url(/assets/icons/User_Add.svg);
        margin-right: 8px;
      }
    }
  }
`;

export const SubmitATicket = ({
  className
}: React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>): ReactElement => (
  <TicketPanel className={cx('d-flex justify-content-center align-items-center', className)}>
    <div className='ticket-card'>
      <div className='icon-section'>
        <div className='icon satellite' />
      </div>
      <div className='title'>Submit a ticket</div>
      <div className='text'>Send a message directly to our team</div>
      <div className='button-section'>
        <a
          type='button'
          className='btn-wide btn btn-primary btn-lg'
          href='https://mylivecontrol.zendesk.com/hc/en-us/requests/new'
          rel='noreferrer'
          target='_blank'
        >
          <div className='icon user-add' /> Submit a ticket
        </a>
      </div>
    </div>
  </TicketPanel>
);
