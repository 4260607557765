import type { Environment } from '../model';
import * as AccountStore from './account';
import { initializer } from './factory';
import * as TokenStore from './token-store';
import type * as Types from './types';
import * as UserStore from './user';

export type Store = Types.Store;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Store {
  export type State = Types.State;
  export type Draft = Types.Draft;

  // Initialize the store
  export const initialize = (environment: Environment): Store => initializer(environment);

  /* eslint-disable @typescript-eslint/no-unused-vars */
  export import Account = AccountStore;
  export import Token = TokenStore;
  export import User = UserStore;
}
