import { Form } from '@livecontrol/core-ui';
import { Calendar } from '@livecontrol/core-utils';
import type { User } from '@livecontrol/scheduler/model';
import { EventValidator } from '@livecontrol/scheduler/validator';
import { DateTime } from 'luxon';
import { useContext } from 'react';
import styled from 'styled-components';
import { Store } from '../../../store';
import type { EventContext } from '../event-store';
import { EventContextStore } from '../event-store';
import { ContentSection, CustomInputContainer } from '../layout-sections';

const CustomTitle = styled.div`
  display: flex;
  .new-feature-tag {
    margin-left: 8px;
    width: 33px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff505f;
    border-radius: 20px;
    color: #ffffff;
    font-size: 9px;
  }
  .paid-feature-section {
    width: 25px;
    height: 15px;
    overflow: visible;
    margin-left: 6px;
    position: relative;
    .icon {
      width: 25px;
      height: 25px;
      background-image: url(/assets/icons/Paid-feature.svg);
      background-size: cover;
      background-position: center center;
      position: absolute;
      top: -7px;
    }
  }
`;

const CustomDescription = styled.div`
  align-items: flex-start;
  width: calc(100% - 14px);

  .link-to-article {
    display: inline-block;
    margin-top: 1px;

    .icon {
      margin-left: 2px;
      width: 12px;
      height: 12px;
      background-image: url(/assets/icons/Info-article-blue.svg);
      background-size: cover;
      background-position: center center;
    }
  }
`;

const StyledInputSection = styled.div`
  width: 100%;

  .form-group {
    max-width: 440px;

    label {
      font-size: 15px;
      font-weight: 600;
      color: #2e384d;
    }

    input::placeholder,
    textarea::placeholder {
      font-style: normal;
    }
  }
`;

export const OtherOptions = ({
  timezone,
  creatingEvent = true,
  accountSettings
}: {
  timezone: string | undefined;
  creatingEvent?: boolean;
  accountSettings: User.admin;
}): React.ReactElement => {
  const account = Store.Account.useAccount();

  const { store: eventDetailsContext, existingEvent }: EventContext =
    useContext<EventContext>(EventContextStore);

  const showRedirectField =
    eventDetailsContext.eventType !== EventValidator.ProductionType.TEST &&
    accountSettings.userGlobalSettings.webplayerRedirect;

  const showingRecurrentInput =
    eventDetailsContext.eventType !== EventValidator.ProductionType.TEST &&
    eventDetailsContext.eventType !== EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT &&
    creatingEvent &&
    accountSettings.userGlobalSettings.recurringEvents;

  const isProduced = eventDetailsContext.eventType === EventValidator.ProductionType.PRODUCED;

  const isProducedAndMobileKit =
    eventDetailsContext.eventType === EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT;

  let eventIsLinked = false;

  if (isProducedAndMobileKit) {
    eventIsLinked = true;
  } else if (isProduced && !creatingEvent && existingEvent?.linkedEventId) {
    eventIsLinked = false;
  }

  if ((!showRedirectField || eventIsLinked) && !showingRecurrentInput) {
    return <div />;
  }

  return (
    <ContentSection style={{ maxWidth: '870px', marginTop: '50px' }}>
      <CustomInputContainer>
        <Form.Descriptor
          title='Other Features'
          titleClassName='title mr-lg-3 font-size-15px font-weight-bold text-dark mt-24px'
          description='Add features to your event. '
          className='form-section-lateral d-lg-flex'
          descriptionClassName='font-size-13px text-secondary font-weight-bold text-gray-light mb-8px'
        >
          <div className='d-flex flex-column w-100'>
            {showingRecurrentInput && (
              <>
                <Form.SwitchCustomField
                  abledDescription={false}
                  title='Recurring Event'
                  description='Make this into a recurring event that repeats on a weekly or monthly basis.'
                  name='repeatSettings.repeat'
                  className='mt-24px'
                  titleClassName='title text-dark mb-4px mr-lg-3 font-size-15px font-weight-bold'
                  descriptionClassName='font-size-13px text-secondary font-weight-bold text-gray-light mb-8px line-height-18px'
                />

                {eventDetailsContext.eventInfo.repeatSettings?.repeat && (
                  <div className='mt-12px'>
                    <div className='mt-24px'>
                      <span className='title text-dark font-size-15px font-weight-bold'>
                        Repeat Every
                      </span>
                      <div className='d-flex flex-row custom-form-group'>
                        <div className='input-wrapper d-flex'>
                          <Form.Group
                            className='event-details-frequency-dropdown custom-input location-input mt-0px'
                            name='repeatSettings.repeatFrequency.unit'
                            label=''
                            aria-label='Repeat unit'
                            controlProps={{
                              as: 'select',
                              style: { width: '125px' }
                            }}
                            wrapperClass='input-wrapper'
                            rightIconClass='icon chevron-down'
                          >
                            <option value={Calendar.Unit.WEEKS}>Week</option>
                            <option value={Calendar.Unit.MONTHS}>Month</option>
                          </Form.Group>
                          {eventDetailsContext.eventInfo.start &&
                            eventDetailsContext.eventInfo.repeatSettings.repeatFrequency?.unit ===
                              Calendar.Unit.MONTHS && (
                              <div
                                style={{
                                  color: '#2e384d',
                                  marginLeft: '8px',
                                  whiteSpace: 'nowrap',
                                  height: '75px'
                                }}
                                className='d-flex align-items-center'
                              >
                                {`On day ${
                                  eventDetailsContext.eventInfo.start.setZone(account.timezone).day
                                }`}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>

                    {eventDetailsContext.eventInfo.repeatSettings.repeatFrequency?.unit ===
                      Calendar.Unit.WEEKS && (
                      <>
                        <span className='title text-dark font-size-15px font-weight-bold'>
                          Repeat On
                        </span>
                        <Form.Group
                          name='repeatSettings.repeatFrequency.daysOfWeek'
                          label=''
                          aria-label='Repeat days'
                          className='custom-form-group custom-week-days'
                          controlProps={{
                            allowMultiple: true
                            // value: new Set<Calendar.Weekday>([])
                          }}
                          control={Form.Weekday}
                        />
                      </>
                    )}

                    <span className='title text-dark font-size-15px font-weight-bold'>Ends</span>
                    <div className='event-details-input-grid d-flex flex-column align-items-start'>
                      <div className='d-flex flex-row align-items-center'>
                        <Form.Group
                          name='repeatSettings.repeatEndCondition'
                          label=''
                          aria-label='Repeat Until'
                          className='mb-0 custom-radio-check'
                          controlProps={{
                            type: 'radio',
                            custom: true,
                            value: EventValidator.EventInfo.RepeatEndCondition.ON_DATE
                          }}
                          control={Form.Check}
                        />
                        <span
                          className='text-dark font-size-15px font-weight-bold mr-16px'
                          style={{ width: '40px' }}
                        >
                          On
                        </span>

                        <Form.Group
                          name='repeatSettings.repeatUntil.stopRepeatingOn'
                          label=''
                          aria-label='Repeat Until'
                          className='mb-0 d-flex flex-column justify-content-center mt-12px'
                          controlProps={{
                            placeholderText: 'Repeat Until',
                            timezone: timezone ?? account.timezone,
                            dateFormat: 'MMM d, yyyy',
                            defaultDate: DateTime.utc()
                              .plus({ months: 1 })
                              .setZone(timezone ?? account.timezone)
                              .toJSDate()
                          }}
                          control={Form.DateSelector}
                        />
                      </div>

                      <div className='d-flex flex-row align-items-center'>
                        <Form.Group
                          name='repeatSettings.repeatEndCondition'
                          label=''
                          aria-label='Stop After'
                          className='mb-0 custom-radio-check'
                          controlProps={{
                            type: 'radio',
                            custom: true,
                            value: EventValidator.EventInfo.RepeatEndCondition.AFTER_OCCURRENCES
                          }}
                          control={Form.Check}
                        />
                        <span
                          className='text-dark font-size-15px font-weight-bold mr-16px'
                          style={{ width: '40px' }}
                        >
                          After
                        </span>
                        <Form.Group
                          name='repeatSettings.repeatUntil.numRecurrences'
                          label=''
                          aria-label='Stop After'
                          className='mb-0'
                          controlProps={{
                            type: 'text',
                            className: 'event-details-numeric'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {showRedirectField && !eventIsLinked && (
              <Form.SwitchCustomField
                abledDescription={false}
                className='mt-24px'
                title={
                  <CustomTitle>
                    Redirect Viewers <div className='new-feature-tag'>New</div>
                  </CustomTitle>
                }
                titleClassName='title text-dark mb-4px mr-lg-3 font-size-15px font-weight-bold'
                description={
                  <CustomDescription>
                    <span>
                      Enable web player viewers to stay on the same link to view a subsequent event
                      or new URL after the event ends.
                    </span>
                    <a
                      className='link-to-article'
                      href='https://mylivecontrol.zendesk.com/hc/en-us/articles/16951891200151-URL-Redirect-Redirecting-your-Viewers'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <div className='icon' />
                    </a>
                  </CustomDescription>
                }
                descriptionClassName='text-gray-light font-size-13px text-secondary font-weight-bold line-height-18px'
                name='redirectUrlSettings.enabled'
              />
            )}
            {showRedirectField &&
              !eventIsLinked &&
              eventDetailsContext.eventInfo.redirectUrlSettings?.enabled && (
                <StyledInputSection>
                  <Form.Group
                    name='redirectUrlSettings.url'
                    label=''
                    controlProps={{
                      type: 'text',
                      placeholder: 'Redirect URL',
                      className: 'mr-8px'
                    }}
                  />
                </StyledInputSection>
              )}
          </div>
        </Form.Descriptor>
      </CustomInputContainer>
    </ContentSection>
  );
};
