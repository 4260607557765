import { Glyph } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import type { ReactElement } from 'react';

interface Props {
  onClose: () => void;
}

export const CreditsSuccess = ({ onClose }: Props): ReactElement => (
  <>
    <Modal.Header closeButton>
      <Glyph>
        <i className='far fa-check' />
      </Glyph>
      <Modal.Title className='mb-16px'>Payment Successful</Modal.Title>

      <div className='text-secondary text-center font-size-16px line-height-lg'>
        Thank you for purchasing additional credits! Your credit balance has been updated.
      </div>
    </Modal.Header>
    <Modal.Footer>
      <Modal.ButtonBox>
        <Modal.Button variant='primary' className='btn-xwide' onClick={onClose}>
          OK
        </Modal.Button>
      </Modal.ButtonBox>
    </Modal.Footer>
  </>
);
