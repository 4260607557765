// import { Str } from '@livecontrol/core-utils';
import { Str } from '@livecontrol/core-utils';
import type { Item, Item as T_Item } from '@livecontrol/scheduler/model';
import type { ReactElement } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import type { DropdownButtonProps } from 'react-bootstrap/DropdownButton';
import DropdownButton from 'react-bootstrap/DropdownButton';

export const DropdownSelector = (props: DropdownSelector.Props): React.ReactElement => {

  const {
    value: { selected, available },
    title,
    nullableOption,
    onChange,
    noTruncate,
    setState,
    ...rest
  } = props;

  const placeholder = selected?.label ?? (title ?? '-');

  return (
    <DropdownButton
      variant={selected ? 'tint' : 'outline-tertiary'}
      title={noTruncate ? placeholder : Str.truncate(placeholder, { length: 30 }) }
      {...rest}
    >

    {nullableOption?.isNullable && (
     <Dropdown.Item
        key='all'
        onClick={(): void => {
          onChange?.(undefined);
          setState?.(undefined);
        }}
      >
        {nullableOption.nullableLabel}
      </Dropdown.Item>)}

      {available.map((item: T_Item): ReactElement => {
        const active = item.identifier === selected?.identifier;

        return (
          <Dropdown.Item
            key={item.identifier}
            active={active}
            onClick={(): void => {
              onChange?.(item, available);
              setState?.(item);
            }}
          >
            {item.label}
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace DropdownSelector {
  export interface Value {
    available: T_Item[];
    selected?: T_Item;
    title?: string;
    nullableOption?: boolean;
  }

  interface NullableOption{
    isNullable: boolean;
    nullableLabel: string;
  }

  export interface DropdownItem {
    available: Item[];
    selected?: Item;
    update: (value?: Item) => void;
  }


  export type Props = Omit<DropdownButtonProps, 'onChange' | 'title'> & {
    value: Value;
    noTruncate?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items?: any;
    title?: string;
    nullableOption?: NullableOption;
    onChange?: (item?: T_Item, value?: T_Item[]) => void;
    setState?: (value: React.SetStateAction<Item | undefined>) => void;
  };
}

