import { Str } from '@livecontrol/core-utils';
import { useMemo } from 'react';
import type { ReactElement } from 'react';
import { Utils } from '../../utils';
import { Control } from './control';

export const CountryCode = ({ value, ...props }: CountryCode.Props): ReactElement => {
  const code = Str.normalize(value);

  const options = useMemo(() => {
    const frequent = Utils.FREQUENT_COUNTRIES_LIST;
    const others = Utils.COUNTRIES_LIST;

    const all = [...frequent, ...others];

    return (
      <>
        {!code ? (
          <option key='none' disabled value=''>
            Select Code
          </option>
        ) : !all.some((entry) => entry.dialCode === code) ? (
          <option key={code} value={code}>
            {code}
          </option>
        ) : null}
        {[...frequent].map((v) => (
          <option key={v.name} value={v.dialCode}>
            {v.name} ({v.dialCode})
          </option>
        ))}
        <optgroup label='Other countries'>
          {[...others].map((v) => (
            <option key={v.name} value={v.dialCode}>
              {v.name} ({v.dialCode})
            </option>
          ))}
        </optgroup>
      </>
    );
  }, [code]);

  return (
    <Control as='select' value={value} {...props}>
      {options}
    </Control>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace CountryCode {
  export type Props = Omit<Control.Props, 'as'> & { deprecated?: boolean };
}
