import { Num, Str } from '@livecontrol/core-utils';
import React from 'react';
import { withTheme } from 'styled-components';
import type { Theme } from '../theme';

const DEFAULT_SIZE = 150;

export const Gears = withTheme((props: Gears.Props & Theme.Props): React.ReactElement => {
  const numValue = Num.normalize(props.size, { positive: true });
  const strValue = Str.normalize(props.size);

  const size = numValue || !strValue ? `${numValue ?? DEFAULT_SIZE}px` : strValue;

  const { theme } = props;

  return (
    <svg className='d-block' width={size} height={size} viewBox='0 0 100 100'>
      <g transform='translate(50 50)'>
        <g transform='translate(-19 -19) scale(0.6)'>
          <g>
            <animateTransform
              attributeName='transform'
              type='rotate'
              values='0;45'
              keyTimes='0;1'
              dur='0.33s'
              begin='0s'
              repeatCount='indefinite'
            />
            <path
              d='M31.359972760794346 21.46047782418268 L38.431040572659825 28.531545636048154 L28.531545636048158 38.431040572659825 L21.460477824182682 31.359972760794346 A38 38 0 0 1 7.0000000000000036 37.3496987939662 L7.0000000000000036 37.3496987939662 L7.000000000000004 47.3496987939662 L-6.999999999999999 47.3496987939662 L-7 37.3496987939662 A38 38 0 0 1 -21.460477824182675 31.35997276079435 L-21.460477824182675 31.35997276079435 L-28.53154563604815 38.431040572659825 L-38.43104057265982 28.531545636048158 L-31.359972760794346 21.460477824182682 A38 38 0 0 1 -37.3496987939662 7.000000000000006 L-37.3496987939662 7.000000000000006 L-47.3496987939662 7.000000000000007 L-47.3496987939662 -6.9999999999999964 L-37.3496987939662 -6.999999999999997 A38 38 0 0 1 -31.35997276079435 -21.460477824182675 L-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794346 L21.460477824182686 -31.359972760794346 L28.531545636048158 -38.431040572659825 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 L37.3496987939662 -6.999999999999995 L47.3496987939662 -6.999999999999997 L47.349698793966205 6.999999999999973 L37.349698793966205 6.999999999999976 A38 38 0 0 1 31.359972760794346 21.460477824182686 M0 -28A28 28 0 1 0 0 28 A28 28 0 1 0 0 -28'
              fill={theme.colors.primary.toString()}
            />
          </g>
        </g>

        <g transform='translate(19 19) scale(0.6)'>
          <g>
            <animateTransform
              attributeName='transform'
              type='rotate'
              values='45;0'
              keyTimes='0;1'
              dur='0.33s'
              begin='-0.16s'
              repeatCount='indefinite'
            />
            <path
              d='M-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794346 L21.460477824182686 -31.359972760794346 L28.531545636048158 -38.431040572659825 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 L37.3496987939662 -6.999999999999995 L47.3496987939662 -6.999999999999997 L47.349698793966205 6.999999999999973 L37.349698793966205 6.999999999999976 A38 38 0 0 1 31.359972760794346 21.460477824182686 L31.359972760794346 21.460477824182686 L38.431040572659825 28.531545636048158 L28.53154563604818 38.4310405726598 L21.460477824182703 31.35997276079433 A38 38 0 0 1 6.9999999999999964 37.3496987939662 L6.9999999999999964 37.3496987939662 L6.999999999999995 47.3496987939662 L-7.000000000000009 47.3496987939662 L-7.000000000000007 37.3496987939662 A38 38 0 0 1 -21.46047782418263 31.359972760794385 L-21.46047782418263 31.359972760794385 L-28.531545636048097 38.43104057265987 L-38.431040572659796 28.531545636048186 L-31.35997276079433 21.460477824182703 A38 38 0 0 1 -37.34969879396619 7.000000000000032 L-37.34969879396619 7.000000000000032 L-47.34969879396619 7.0000000000000355 L-47.3496987939662 -7.000000000000002 L-37.3496987939662 -7.000000000000005 A38 38 0 0 1 -31.359972760794346 -21.460477824182682 M0 -28A28 28 0 1 0 0 28 A28 28 0 1 0 0 -28'
              fill={theme.colors.secondary.toString()}
            />
          </g>
        </g>
      </g>
    </svg>
  );
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Gears {
  export interface Props {
    size?: number | string;
  }
}
