import { Asset } from '@livecontrol/scheduler/model';
import type { Event } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Panel } from '../../../components';
import { Store } from '../../../store';
import { Delete } from './delete';
import { Download } from './download';
import { Edit } from './edit';
import { Embedded } from './embedded';
import { Feedback, SuccessModal } from './feedback';
import { PasswordProtect } from './password-protect';
import { ShareLink } from './share-link';
import { ViewEvent } from './view-event';
import { Visibility } from './visibility';

const StyledOptions = styled.div`
  flex-wrap: wrap;
  flex-direction: column;

  > * {
    margin-top: 8px;
    flex-grow: 1;

    .x-viewport-sm & {
      margin-right: 8px;
      flex-grow: 0;
    }
  }
`;

const StyledFlex = styled.div`
  > * {
    margin-right: 8px;
    margin-top: 8px;
  }
`;

interface Props {
  asset: Asset;
  event?: Event.Like;
}

export const Overview = ({
  asset,
  event
}: Props): React.ReactElement => {
  const account = Store.Account.useAccount();
  const timestamp = asset.timestamp.setZone(account.timezone);
  const [showModalSuccess, setShowModalSuccess] = useState(false);

  if(event){
    const {event:eventData} = Scheduler.Event.useEvent({ account, event});
    const eventLocation = eventData?.location.name ? `- ${eventData.location.name}` : '';

    return (
      <Panel>
        <div className='border-bottom pb-12px'>
          <h1 className='font-size-lg-32px'>{asset.title}</h1>

          <StyledFlex className='d-flex flex-wrap mb-16px'>
            <Visibility asset={asset} />
            {asset.visibility !== Asset.Visibility.Private && (
              <>
                <ViewEvent asset={asset} />
                <ShareLink asset={asset} />
                <Embedded asset={asset} />
              </>
            )}
          </StyledFlex>

          <div className='font-size-13px py-4px'>
            <span className='font-weight-bold'>{timestamp.toFormat('LLLL d, y')}</span>
            <span className='pl-8px'>
              {/* {eventData ? `EID: ${eventData.id} - ` : null} */}
              {timestamp.toFormat('h:mm a')}
              {asset.duration ? ` - ${timestamp.plus(asset.duration).toFormat('h:mm a')} ` : null}
              {eventLocation}
            </span>
          </div>
        </div>

        <StyledOptions className='d-flex flex-column flex-sm-row mt-12px'>
          <Edit asset={asset} />
          {asset.visibility !== Asset.Visibility.Private && <PasswordProtect asset={asset} />}
          {eventData && <Feedback event={eventData} setShowModalSuccess={setShowModalSuccess} />}
          {asset.event && <Download asset={asset} />}
          <Delete asset={asset} />
        </StyledOptions>
        <p className='mt-20px'>{asset.description}</p>
        <SuccessModal
          show = {showModalSuccess}
          onClose={(): void => {
            setShowModalSuccess(false);
          }}
        />
      </Panel>
    );
  }

  return (
    <Panel>
      <div className='border-bottom pb-12px'>
        <h1 className='font-size-lg-32px'>{asset.title}</h1>

        <div className='font-size-13px py-4px'>
          <span className='font-weight-bold'>{timestamp.toFormat('LLLL d, y')}</span>
          <span className='pl-8px'>
            {timestamp.toFormat('h:mm a')}
            {asset.duration ? ` - ${timestamp.plus(asset.duration).toFormat('h:mm a')} ` : null}
            {/* {eventLocation} */}
          </span>
        </div>
      </div>

      <StyledOptions className='d-flex flex-column flex-sm-row mt-12px'>
        <Edit asset={asset} />
        <Download asset={asset} />
        <Delete asset={asset} />
      </StyledOptions>
      <p className='mt-20px'>{asset.description}</p>
    </Panel>
  );
};
