import { gql } from '@apollo/client';
import { Bool, Num, Obj, Str } from '@livecontrol/core-utils';
import type { EventFeedback } from '@livecontrol/scheduler/model';

export type Record = Partial<{
  camera_frame_issue: boolean;
  camera_movement_issue: boolean;
  graphics_and_screen_sharing_issue: boolean;
  lighting_and_exposure_issue: boolean;
  live_stream_quality_issue: boolean;
  sound_quality_issue: boolean;
  technical_extra_comment: string;
  videographer_attention_issue: boolean;
  videographer_extra_comment: string;
  videographer_thumb: boolean;
  technical_thumb: boolean;
  videographer_misunderstanding_issue: boolean;
  videographer_notes_issue: boolean;
  id: number;
}>;

export const normalize = (record: Partial<Record>): EventFeedback | undefined => {
  const candidate = {
    cameraFrameIssue: Bool.normalize(record.camera_frame_issue),
    cameraMovementIssue: Bool.normalize(record.camera_movement_issue),
    graphicsAndScreenSharingIssue: Bool.normalize(record.graphics_and_screen_sharing_issue),
    lightingAndExposureIssue: Bool.normalize(record.lighting_and_exposure_issue),
    liveStreamQualityIssue: Bool.normalize(record.live_stream_quality_issue),
    soundQualityIssue: Bool.normalize(record.sound_quality_issue),
    technicalExtraComment: record.technical_extra_comment ? Str.normalize(record.technical_extra_comment) : '',
    videographerAttentionIssue: Bool.normalize(record.videographer_attention_issue),
    videographerExtraComment: record.videographer_extra_comment ? Str.normalize(record.videographer_extra_comment) : '',
    videographerThumb: Bool.normalize(record.videographer_thumb),
    technicalThumb: Bool.normalize(record.technical_thumb),
    videographerMisunderstandingIssue: Bool.normalize(record.videographer_misunderstanding_issue),
    videographerNotesIssue: Bool.normalize(record.videographer_notes_issue),
    id: Num.normalize(record.id)
  };

  return Obj.isHydrated(candidate) ? candidate : undefined;
};

export const StandardFeedbackResponse = gql`
  fragment StandardFeedbackResponse on EventFeedback {
    camera_frame_issue
    camera_movement_issue
    graphics_and_screen_sharing_issue
    id
    lighting_and_exposure_issue
    live_stream_quality_issue
    sound_quality_issue
    technical_extra_comment
    videographer_attention_issue
    videographer_extra_comment
    videographer_misunderstanding_issue
    videographer_notes_issue
  }
`;
