import { Device, Theme } from '@livecontrol/core-ui';
import { Matrix } from '@livecontrol/scheduler/components';
import type { Account, Location } from '@livecontrol/scheduler/model';
import type { FormikContextType } from 'formik';
import type { ReactElement } from 'react';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import type { Values } from '../permissions-user';
import { TableHeader } from './table-header';
import { TableRow } from './table-row';

import G = Matrix.Grid;

const CellNoBorder = styled(Matrix.Grid.Row.Cell)`
  background-color: white !important;
  border: 0px solid transparent;
  justify-content: center;
  padding: 14px 0px;

  &.transparent {
    background-color: transparent !important;
  }

  &.boder-bottom {
    border-bottom: 1px solid #e4e8f0 !important;
  }

  &:last-child {
    padding: 5px 0px !important;
  }

  &:first-child {
    border-left: 0px solid transparent !important;
    left: 0;
  }
`;

const Grid = styled(Matrix.Grid)<{ isHeaderHovered?: number }>`
  grid-row-gap: 0 !important;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 500px;
 

  .border-top {
    border-top: 1px solid #e4e8f0;
  }

  .badge {
    font-size: 12px;
  }

  .bg-admin {
    background-color: #EF9563;
  }

  .bg-purplue {
    background-color: #7b61ff;
  }

  .bg-green {
    background-color: #37b34a;
  }

  .bg-blue {
    background-color: #00c1c1;
  }

  .column:nth-child(2n + 0) {
    background-color: #f0f6ff;
  }
  .column:nth-child(2n + 1) {
    background-color: white;
  }

  .height-10px {
    height: 10px !important;
    padding: 0px !important;
  }

  .th {
    margin: 0 !important;
    padding: 20px 10px;
    height: 140px;
    font-weight: 600;
    font-size: var(--font-size-16px);
    color: var(--dark);
    z-index: 1000:
  }

  .form-group {
    flex: 0 0 0 !important;
  }

  .form-control {
    padding: 0px;
  }

  input[type='checkbox'] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 5px;
    width: 16px;
    height: 16px;
    border: 1px solid #0d1438;
    outline: none;
  }

  input[type='checkbox']:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
  }

  input:checked[type='checkbox'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }

  .invalid-feedback{
    display: none
  }

    ${({ isHeaderHovered }): string => {
      let style = '';

      if (isHeaderHovered) {
        // eslint-disable-next-line unicorn/prefer-spread
        style = style.concat(`
        li > .column:nth-child(${isHeaderHovered}) {
          border-left: 1px solid #2e5bff !important;
          border-right: 1px solid #2e5bff !important;
        }

        li > div:last-child  {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
        }

        li:first-child > div:nth-child(${isHeaderHovered}) {
          border-top:  1px solid #2e5bff;
        }

        li:last-child > div:nth-child(${isHeaderHovered}) {
          border-bottom:  1px solid #2e5bff;
        }

      `);
      }

      return style;
    }};

`;

interface Props {
  locations?: Location[];
  subUsers?: Account.SubUser[];
  formik: FormikContextType<Values>;
}

export const TablePermissions = ({ locations, subUsers, formik }: Props): ReactElement => {
  const viewport = Theme.useViewport();
  const [isHeaderHovered, setIsHeaderHovered] = useState<number>();
  const sortedLocations = useMemo(
    () => locations?.sort((a, b) => a.name.localeCompare(b.name)),
    [locations]
  );
  const sortedSubUsers = useMemo(
    () => subUsers?.sort((a, b) => a.firstName.localeCompare(b.firstName)),
    [subUsers]
  );

  return (
    <Grid
      id='gridScroll'
      isHeaderHovered={isHeaderHovered}
      viewport={viewport}
      gridTemplateColumns={`${Device.isMobile(viewport) ? '125px' : '300px'} repeat(4, 140px)`}
      className='position-relative'
    >
      <TableHeader
        setIsHeaderHovered={setIsHeaderHovered}
        formik={formik}
        subUsers={sortedSubUsers}
        locations={sortedLocations}
      />
      <G.Row>
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
        <CellNoBorder className='column height-10px' />
      </G.Row>

      {sortedLocations?.map((location) => (
        <TableRow formik={formik} key={location.id} id={location.id} name={location.name} />
      ))}
      {sortedSubUsers?.map((subUser) => (
        <TableRow formik={formik} key={subUser.id} id={subUser.id} name={subUser.fullName} />
      ))}
    </Grid>
  );
};
