import { Device } from '@livecontrol/core-ui';
import type { Account } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import _ from 'lodash';
import { useCallback } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Store } from '../../../store';
import { PreviewSection } from './preview';

const ThemeContainer = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 24px;
  padding-top: 24px;
  border-bottom: 1px solid #d5daea;

  .text-section {
    min-width: 300px;
    margin-bottom: 10px;
    margin-right: 26px;

    .description {
      max-width: 300px;
      font-weight: 600;
      font-size: 13px;
      color: #9c9c9c;
      word-wrap: break-word;
    }

    .theme-colors-section {
      display: flex;
      padding-top: 40px;
      justify-content: center;
      flex-wrap: wrap;
      row-gap: 16px;

      .color-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 18px;

        .color-btn {
          position: relative;
          width: 64px;
          height: 64px;
          border-radius: 50%;
          border: 2px solid #d5daea;

          .icon {
            position: absolute;
            top: 0%;
            right: 0%;
            display: none;
            width: 20px;
            height: 20px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            border-radius: 10px;
            background-color: #2253f5;
          }
          .check {
            background-image: url('/assets/icons/Check.svg');
          }
        }
        .color-btn.active {
          border: 2px solid #2e5bff;
          .icon {
            display: block;
          }
        }
        .color-name {
          margin-top: 10px;
          font-size: 13px;
          color: #9c9c9c;
          font-weight: 600;
        }
      }
    }
  }

  .x-${Device.MOBILE} &, .x-${Device.TABLET} & {
    flex-direction: column;
    align-items: center;
    .text-section {
      width: 100%;
      text-align: center;
      margin-right: 0px;
      .description {
        max-width: 100%;
      }
    }

    .theme-colors-section {
      margin-bottom: 16px;
    }
  }
`;

export const ThemeSection = ({ webplayer }: { webplayer: Account.WebPlayer }): ReactElement => {
  const account = Store.Account.useAccount();

  const themeMap = [
    { color: '#FFFFFF', themeName: 'White', name: 'white' },
    { color: '#ECF8FF', themeName: 'Light Blue', name: 'lightBlue' },
    { color: '#314E44', themeName: 'Green', name: 'darkGreen' },
    { color: '#002E51', themeName: 'Blue', name: 'blue' },
    { color: '#272438', themeName: 'Purple', name: 'purple' },
    { color: '#1C1C1C', themeName: 'Black', name: 'base' }
  ];

  const [editTheme] = Scheduler.Account.WebPlayer.useEdit();

  const onThemeSelect = useCallback(
    (theme: string): void => {
      void editTheme(
        account.id,
        {
          theme
        },
        true
      );
    },
    [editTheme, account.id]
  );

  return (
    <ThemeContainer>
      <div className='text-section'>
        <div className='font-weight-bold font-size-15px text-dark mb-2px'>Theme</div>
        <p className='description mb-4px'>
          Choose from one of these themes to use on both your Web Player & Profile Page.
        </p>
        <div className='theme-colors-section'>
          {themeMap.map(({ color, name, themeName }) => (
            <div key={color} className='color-section'>
              <button
                type='button'
                className={`color-btn ${webplayer.theme === name ? 'active' : ''}`}
                name={themeName}
                style={{ backgroundColor: `${color}` }}
                onClick={(): void => {
                  onThemeSelect(name);
                }}
              >
                <div className='icon check' />
              </button>
              <div className='color-name'>{themeName}</div>
            </div>
          ))}
        </div>
      </div>
      <PreviewSection webplayer={webplayer} />
    </ThemeContainer>
  );
};
