import { Grid } from '@livecontrol/core-ui';
import cx from 'classnames';
import type { HTMLAttributes, PropsWithChildren, ReactElement } from 'react';
import { useContext } from 'react';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { Chevron } from './chevron';

export const Header = ({ className, children, current, ...props }: Header.Props): ReactElement => {
  const context = useContext(AccordionContext);

  return (
    <Grid
      className={cx('align-items-center', 'column-gap-8px', className)}
      gridTemplateColumns='1fr min-content'
      {...props}
    >
      {children}

      <Chevron expanded={context === current} />
    </Grid>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Header {
  export type Props = HTMLAttributes<HTMLDivElement> &
    PropsWithChildren<{
      current?: string;
    }>;
}
