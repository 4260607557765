import { gql, useMutation } from '@apollo/client';
import { Bool, Num, Obj, Str } from '@livecontrol/core-utils';
import { Phone } from '@livecontrol/locale-us';
import type { Account } from '@livecontrol/scheduler/model';
import { useCallback, useState } from 'react';
import type { MutationResult } from '../../graphql';
import { Errors } from '../../graphql';
import type * as Dbx from '../dbx';

interface TVariables {
  subUserId: number;
}

interface TData {
  resendSubUserInvite?: {
    success: boolean;
    result?: Dbx.SubUserAccountsRecord[];
    error?: {
      code: string;
      message: string;
    };
  };
}

const MUTATION = gql`
  mutation ResendInviteToSubUser($subUserId: Float!) {
    resendSubUserInvite(subUserId: $subUserId) {
      success
      result {
        id
        first_name
        last_name
        has_all_locations
        email
        subUserPermission {
          id
          event_managment
          view_and_download
          content_managment
          destinations
          customize_webplayer
          organization_account_info
          activity_log
          billing
          admin_access
        }
      }
      error {
        code
        message
      }
    }
  }
`;

export const useResetSubUserPassword = (): [
  (subUserId: number) => Promise<Account.SubUser | undefined>,
  MutationResult<Account.SubUser, 'subUser'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [subUser, setSubUser] = useState<Account.SubUser | undefined>();

  return [
    useCallback(
      async (subUserId: number): Promise<Account.SubUser | undefined> => {
        let subUser_: Account.SubUser | undefined;

        try {
          const id = subUserId ? Num.normalize(subUserId, { positive: true }) : undefined;

          if (!id) {
            throw Errors.badRequest();
          }

          const { data } = await mutation({
            variables: { subUserId: id }
          });

          const createdSubUser = data?.resendSubUserInvite?.result?.length
            ? data.resendSubUserInvite.result[0]
            : undefined;

          const subUserFirstName = Str.normalize(createdSubUser?.first_name);
          const subUserLastName = Str.normalize(createdSubUser?.last_name);
          const preferredContactMethod = Str.normalize(createdSubUser?.preferred_contact_method) ?? '';

          const candidate = {
            id: Num.normalize(createdSubUser?.id, { positive: true }),
            firstName: subUserFirstName,
            lastName: subUserLastName,
            preferredContactMethod,
            hasAllLocations: Bool.normalize(createdSubUser?.has_all_locations),
            fullName: [subUserFirstName, subUserLastName].join(' '),
            email: Str.normalize(createdSubUser?.email)
          };

          const permissionCandidate = {
            id: Num.normalize(createdSubUser?.subUserPermission?.id),
            eventManagment: Bool.normalize(createdSubUser?.subUserPermission?.event_managment),
            eventSchedule: Bool.normalize(createdSubUser?.subUserPermission?.event_managment),
            viewAndDownload: Bool.normalize(createdSubUser?.subUserPermission?.view_and_download),
            contentManagment: Bool.normalize(createdSubUser?.subUserPermission?.content_managment),
            archive: Bool.normalize(createdSubUser?.subUserPermission?.content_managment),
            readOnlyArchive: Bool.normalize(createdSubUser?.subUserPermission?.content_managment),
            destinations: Bool.normalize(createdSubUser?.subUserPermission?.destinations),
            webplayer: Bool.normalize(createdSubUser?.subUserPermission?.customize_webplayer),
            accountInfo: Bool.normalize(
              createdSubUser?.subUserPermission?.organization_account_info
            ),
            activityLog: Bool.normalize(createdSubUser?.subUserPermission?.activity_log),
            billing: Bool.normalize(createdSubUser?.subUserPermission?.billing),
            admin: Bool.normalize(createdSubUser?.subUserPermission?.admin_access),
            productionNotes: Bool.normalize(
              createdSubUser?.subUserPermission?.customize_production_notes
            ),
            organizationAccountInfo: Bool.normalize(
              createdSubUser?.subUserPermission?.organization_account_info
            ),
            someLocationHasEventManagmentEnabled: Bool.normalize(
              createdSubUser?.subUserPermission?.some_location_has_event_managment_enabled
            ),
            someLocationHasViewAndDownloadEnabled: Bool.normalize(
              createdSubUser?.subUserPermission?.some_location_has_view_and_download_enabled
            ),
            someLocationHasContentManagmentEnabled: Bool.normalize(
              createdSubUser?.subUserPermission?.some_location_has_content_managment_enabled
            ),
            someLocationHasActivityLogEnabled: Bool.normalize(
              createdSubUser?.subUserPermission?.some_location_has_activity_log_enabled
            ),
            someLocationHasCustomizeProductionNotesEnabled: Bool.normalize(
              createdSubUser?.subUserPermission
                ?.some_location_has_customize_production_notes_enabled
            )
          };

          if (Obj.isHydrated(candidate) && Obj.isHydrated(permissionCandidate)) {
            subUser_ = {
              ...candidate,
              phone: Phone.normalize(createdSubUser?.phone_number)!,
              permissions: { ...permissionCandidate }
            };
          }
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setSubUser(subUser_);

        return subUser_;
      },
      [mutation]
    ),
    {
      subUser,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
