import { Loading } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import { Scheduler } from '@livecontrol/scheduler/store';
import React, { useEffect, useMemo, useState } from 'react';
import { Store } from '../../../store';
import * as Youtube from './youtube';

export const AddIntegration = ({
  integrationType,
  show,
  handleClose,
  onDone
}: {
  show: boolean;
  handleClose?: () => void;
  integrationType: string;
  onDone?: () => void;
}): React.ReactElement | null => {
  const account = Store.Account.useAccount();
  const modalPrompt = Modal.usePrompt();
  const destinationType = integrationType;

  const [selectedDestination, setSelectedDestination] = useState(0);

  const [youtubeError, setYoutubeError] = useState(false);

  const {
    data: destinations_,
    loading: destinationsLoading,
    error: destinationsError
  } = Scheduler.Destination.usePendingDestinations(account);

  const filteredDestinations = useMemo(
    () => destinations_?.filter((v) => v.strategy === destinationType) ?? [],
    [destinationType, destinations_]
  );

  useEffect((): void => {
    if (localStorage.getItem('reconnectid')) {
      localStorage.removeItem('reconnectid');
      onDone?.();
    }

    if (show && destinations_ && filteredDestinations.length === 0) {
      onDone?.();

      modalPrompt.message({
        title: 'Destination already added.'
      });
    }
  }, [destinations_, filteredDestinations.length, modalPrompt, onDone, show]);

  const [addFacebookIntegration, { loading: addEditFacebookMutationLoading }] =
    Scheduler.Destination.useAddFacebook();

  const [addYouTubeIntegration, { loading: createYouTubeMutationLoading }] =
    Scheduler.Destination.useAddYouTube();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (filteredDestinations[selectedDestination]) {
      const destination = filteredDestinations[selectedDestination];

      if (destinationType === 'facebook') {
        void addFacebookIntegration({
          account,
          destination: destination.id,
          name: destination.name,
          authorization: destination.authorization_id,
          type: destination.type
        })
          .then(() => {
            onDone?.();
          })
          .catch((): void => {
            modalPrompt.error({
              title: 'Connection Failed',
              content:
                'Oops! Something went wrong and there was an issue in adding your Facebook account. Please try again.'
            });
          });
      }

      if (destinationType === 'youtube') {
        void addYouTubeIntegration({
          account,
          destination: destination.id,
          name: destination.name,
          authorization: destination.authorization_id
        })
          .then(() => {
            onDone?.();
          })
          .catch((error: Error): void => {
            if (error.message.includes(Youtube.YoutubeErrors.LIVE_NOT_ENABLED)) {
              setYoutubeError(true);

              return;
            }

            modalPrompt.error({
              title: 'Connection Failed',
              content:
                'Oops! Something went wrong and there was an issue in adding your YouTube account. Please try again.',
              onClose() {
                handleClose?.();
              }
            });
          });
      }
    }
  };

  if (show && destinationsError) {
    modalPrompt.error({
      title: 'Error trying to retrieve destinations.',
      onClose: onDone
    });

    return null;
  }

  return (
    <>
      <Youtube.ErrorModal
        show={youtubeError}
        handleClose={(): void => {
          setYoutubeError(false);
          handleClose?.();
        }}
      />
      <Modal size='sm' show={show} backdrop='static' keyboard={false} onHide={handleClose} centered>
        {destinationsLoading ? (
          <Loading size={100} />
        ) : (
          <>
            <Modal.Header closeButton>
              <h1>Add Destination</h1>
              <div className='text-muted font-size-12px'>Connect Channel</div>
            </Modal.Header>

            <form onSubmit={onSubmit}>
              <Modal.Body>
                <div className='d-flex align-items-center'>
                  <div className='form-group mb-0px'>
                    <select
                      className='form-control text-capitalize'
                      id='destination'
                      name='destination'
                      value={selectedDestination}
                      onChange={(e): void => {
                        setSelectedDestination(Number(e.target.value));
                      }}
                    >
                      {filteredDestinations.map((destination, i) => (
                        <option value={i} key={destination.id}>
                          {destination.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Modal.ButtonBox>
                  <Modal.Submit
                    loading={addEditFacebookMutationLoading || createYouTubeMutationLoading}
                    disabled={addEditFacebookMutationLoading || createYouTubeMutationLoading}
                  >
                    Connect
                  </Modal.Submit>
                  <Modal.Button variant='outline-secondary' onClick={handleClose}>
                    Cancel
                  </Modal.Button>
                </Modal.ButtonBox>
              </Modal.Footer>
            </form>
          </>
        )}
      </Modal>
    </>
  );
};
