import cx from 'classnames';
import React from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import { isElement } from 'react-is';
import { Group } from './group';

type ValueProps = HTMLAttributes<HTMLDivElement>;

let sequence = 1;

const Value = ({ className, value, ...rest }: ValueProps & { value: string }): ReactElement => (
  <div className={cx('line-height-lg', 'text-break', className)} {...rest}>
    {value}
  </div>
);

export const Attribute = ({ value, valueProps, ...rest }: Attribute.Props): ReactElement => {
  const name = React.useRef(`form-attr-${sequence++}`).current;
  const control = isElement(value) ? value : <Value value={value} {...valueProps} />;

  return <Group name={name} control={control} error={false} {...rest} />;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Attribute {
  export type Props = HTMLAttributes<HTMLDivElement> &
    Pick<Group.Props, 'labelProps'> & {
      label: ReactElement | string;
      value: ReactElement | string;
      valueProps?: ValueProps;
    };
}
