import { Destination } from '@livecontrol/scheduler/model';
import * as Dbx_ from './dbx';
import Service = Destination.Service;

/* eslint-disable @typescript-eslint/no-unused-vars */
export import Dbx = Dbx_;

export {
  useFacebookAuthorizationCallback,
  useYouTubeAuthorizationCallback
} from './authorization-callback';

export { useAddFacebook } from './use-add-facebook';
export { useAddRTMP } from './use-add-rtmp';
export { useAddYouTube } from './use-add-youtube';
export { useDelete } from './use-delete';
export { useDestinations } from './use-destinations';
export { useEditRTMP } from './use-edit-rtmp';
export { useFacebookAuthorization } from './use-facebook-authorization';
export { usePendingDestinations } from './use-pending-destinations';
export { useYouTubeAuthorization } from './use-youtube-authorization';

export const LC_WEBPLAYER: Destination = {
  id: 'lc-webplayer',
  name: 'LiveControl Web Player',
  service: Service.LiveControl,
  deauthorized: false
};

export const ZOOM: Destination = {
  id: 'lc-zoom',
  service: Service.Zoom,
  name: 'Zoom Meeting Room',
  deauthorized: false
};
