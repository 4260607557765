/* eslint-disable @typescript-eslint/no-unnecessary-qualifier */
import type { EmailAddress } from '@livecontrol/core-utils';
import { Enum, Num, Obj } from '@livecontrol/core-utils';
import type { Phone } from '@livecontrol/locale-us';
import type { DateTime } from 'luxon';
import type { LocationFromPermisson } from './location';

export interface User {
  id: User.ID;
  email: EmailAddress;
  phone?: Phone;
  preferredContactMethod: string;
  profile_picture?: string;
  firstName: string;
  lastName: string;
  fullName: string;
  role: User.Role;
  status: User.Status;
  createdAt?: DateTime;
  permissions: User.Permissions;
  enableProducedEventContacts?: boolean;
  landline?: Phone;
  splashtopEmail?: string;
  additionalInformation?: string;
  landlineExtension?: string;
  timezone?: string;
  leadTime?: number;
  emailLocationNotifications?: User.EmailLocationNotification[];
  commandCenterPermissions?: User.CommandCenterPermissions;
  sub_user_locations?: User.LocationPermissions[];
  organizationZipCode?: string;
  organizationPhoneNumber?: Phone;
  organizationName?: string;
  organizationAddress?: string;
  userAccountDetails?: {
    channelOrganization: string;
    channelPhoneAreaCode?: number;
    channelPhone?: Phone;
    renewalDate?: DateTime;
    accountType?: {
      id?: number;
      name: string;
    };
  };
  enableEmailNotifications?: boolean;
  eventScheduling?: boolean;
  userGlobalSettings?: {
    analyticsRange?: number;
  };
}

export interface SubUser {
  id: User.ID;
  email: EmailAddress;
  firstName: string;
  lastName: string;
  fullName: string;
}

export namespace User {
  export type ID = number;
  export type Like = ID | User | string;

  export type admin = User & {
    buildingNumber: string;
    city: string;
    country: string;
    state: string;
    cpAddress: string;
    organizationType: number;
    organizationPhoneLada: string;
    organizationNotes: string;
    phoneNumberLada: string;
    landlineLada: string;
    mainAccountNotes: string;
    eventOperatorNotes: string;
    supportNotes: string;
    notes: string;
    userAccountDetail: {
      videographerType: {
        id?: number;
        name: string;
      };
      targetFirstEventDate?: DateTime;
      updatedAt?: DateTime;
      subscriptionStartDate?: DateTime;
      renewalDate?: DateTime;
      referralName: string;
      referralEmail: EmailAddress;
      referralUser: {
        id?: number;
      };
      createdAt?: DateTime;
      id?: number;
      channelPhoneAreaCode: string;
      channelOrganization: string;
      channelPhone?: Phone;
      channelExtraPhoneAreaCode: string;
      channelExtraPhone?: Phone;
      channelEmail: EmailAddress;
      channelContactName: string;
      actualFirstEventDate?: DateTime;
      accountType: {
        id?: number;
        name: string;
      };
    };
    userNotes: {
      technicalNotes: string;
      networkNotes: string;
      imageNotes: string;
      id?: number;
      hardwareNotes: string;
      audioNotes: string;
      accountNotes: string;
    };
    userGlobalSettings: {
      analyticsRange?: number;
      clientContactInfo: boolean;
      clientTier?: string;
      cueSheet: boolean;
      id?: number;
      onSiteEventContact: boolean;
      operatorEventNotes: boolean;
      passwordProtect: boolean;
      recurringEvents: boolean;
      webplayerRedirect: boolean;
      webplayerRegistration: boolean;
      webplayerRegistrationRequired: boolean;
      webplayerShowViewers: boolean;
    };
  };

  export const toId = (user?: Like): ID | undefined =>
    Num.normalize(Obj.isObject<User>(user) ? user.id : user, { positive: true });

  export enum Role {
    Admin = 1,
    Sales = 2,
    IT = 3,
    Operator = 4,
    Supervisor = 5,
    Client = 6,
    SubUser = 7,
    EMS = 8,
    InternalService = 9,
    API = 10
  }

  export enum NotificationPreference {
    CUSTOMER_FEEDBACK = 20878,
    EVENT_SCHEDULING = 20893,
    UPCOMING_EVENT_REMINDER = 20894,
    DESTINATION_CONNECTION_ISSUE = 20895
  }

  export namespace Role {
    export const normalize = (value: unknown): Role | undefined => Enum.normalize(Role, value);

    export const stringValues = new Map<Role, string>([
      [Role.Admin, 'Admin'],
      [Role.Client, 'Client'],
      [Role.IT, 'IT'],
      [Role.Operator, 'Operator'],
      [Role.Sales, 'Sales'],
      [Role.SubUser, 'SubUser'],
      [Role.Supervisor, 'Supervisor']
    ]);

    export const toString = (role: Role): string => stringValues.get(role)!;
  }

  export enum Status {
    Pending = 1,
    Active = 2,
    Paused = 3
  }

  export namespace Status {
    export const normalize = (value: unknown): Status | undefined => Enum.normalize(Status, value);

    export const stringValues = new Map<Status, string>([
      [Status.Pending, 'Pending'],
      [Status.Active, 'Active'],
      [Status.Paused, 'Paused']
    ]);

    export const toString = (status: Status): string => stringValues.get(status)!;
  }

  export enum Industry {
    House_Of_Worship = 1,
    Event_Venue = 2,
    Comedy = 3,
    Fitness = 4,
    Conference = 5,
    Content_Creator = 6,
    Funeral = 7,
    Government = 8,
    Education = 9,
    Internal = 10
  }

  export namespace Industry {
    export const normalize = (value: unknown): Industry | undefined =>
      Enum.normalize(Industry, value);

    export const stringValues = new Map<Industry, string>([
      [Industry.Internal, 'LiveControl Internal'],
      [Industry.House_Of_Worship, 'House of Worship'],
      [Industry.Funeral, 'Funeral or Memorial Provider'],
      [Industry.Government, 'Government'],
      [Industry.Event_Venue, 'Event Venue & Live Music'],
      [Industry.Education, 'Education'],
      [Industry.Comedy, 'Comedy'],
      [Industry.Fitness, 'Gym & Exercise'],
      [Industry.Conference, 'Conference'],
      [Industry.Content_Creator, 'Podcast & YouTube']
    ]);

    export const toString = (industry: Industry): string => stringValues.get(industry)!;
  }

  export interface Permissions {
    id?: number;
    destinations: boolean;
    webplayer: boolean;
    accountInfo: boolean;
    billing: boolean;
    admin: boolean;
    activityLog: boolean;
    // evaluate
    archive: boolean;
    eventSchedule: boolean;
    readOnlyArchive: boolean;
    viewAndDownload?: boolean;
    productionNotes?: boolean;
    organizationAccountInfo?: boolean;
    someLocationHasEventManagmentEnabled?: boolean;
    someLocationHasViewAndDownloadEnabled?: boolean;
    someLocationHasContentManagmentEnabled?: boolean;
    someLocationHasActivityLogEnabled?: boolean;
    someLocationHasCustomizeProductionNotesEnabled?: boolean;
  }

  export interface ContactRole {
    id: string;
    location?: LocationFromPermisson;
    onboarding_main: boolean;
    onboarding_technical: boolean;
    onsite_technical: boolean;
    onsite_technical_priority: number;
    primary_billing: boolean;
    shipping: boolean;
    user?: {
      id: number;
      email: string;
    };
  }

  export interface LocationPermissions {
    id: string;
    location: LocationFromPermisson;
    eventManagment: boolean;
    viewAndDownload: boolean;
    contentManagment: boolean;
    activityLog: boolean;
    productionNotes: boolean;
  }

  export enum LocationPermissionsNames {
    EventManagment = 'EventManagment',
    ViewAndDownload = 'ViewAndDownload',
    ContentManagment = 'ContentManagment',
    ActivityLog = 'ActivityLog',
    ProductionNotes = 'ProductionNotes'
  }

  export interface CommandCenterPermissions {
    installerAccess: boolean;
    eventAccess: boolean;
    deviceAccess: boolean;
    installerManagement: boolean;
  }

  export interface EmailLocationNotification {
    id: number;
    locationId: string;
    connectionIssues: boolean;
    customerFeedback: boolean;
    eventReminder: boolean;
    eventScheduling: boolean;
  }

  export const Constraints = {
    NAME_MAX_LENGTH: 255,
    EMAIL_MAX_LENGTH: 128,
    TIMEZONE_MAX_LENGTH: 32,
    PASSWORD_MAX_LENGTH: 32,
    PASSWORD_MIN_LENGTH: 8
  };
}
