import _ from 'lodash';
import type { M_Sorting, Phase, Store } from './types';
import { T_Sorting } from './types';

export namespace Sorting {
  export const make = ({
    phase,
    store,
    value
  }: {
    phase: Phase;
    store: Store;
    value?: Partial<T_Sorting>;
  }): M_Sorting => {
    const { produce } = store.getState();

    return {
      value: _.defaults({}, value, {
        key: 'date',
        direction: T_Sorting.Direction.DESCENDING
      }),

      update(v: T_Sorting): void {
        produce(({ model }) => {
          model.value[phase].sorting.value = v;
        });
      }
    };
  };
}
