import { Button } from '@livecontrol/core-ui';
import { Str } from '@livecontrol/core-utils';
import { Scheduler } from '@livecontrol/scheduler/store';
import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import type { PlaidLinkOnSuccessMetadata, PlaidLinkOptions } from 'react-plaid-link';
import { usePlaidLink } from 'react-plaid-link';
import { Panel } from '../../../../components';
import { Store } from '../../../../store';

const PlaidLinkButton = (options: PlaidLinkOptions): ReactElement => {
  const plaid = usePlaidLink(options);

  return (
    <Button
      className='btn-xwide justify-self-start'
      variant='primary'
      onClick={(): void => {
        plaid.open();
      }}
    >
      Link Bank Account
    </Button>
  );
};

export const AddAch = (_props: HTMLAttributes<HTMLDivElement>): ReactElement => {
  const account = Store.Account.useAccount();

  const [plaidLinkToken, setPlaidLinkToken] = useState<string | undefined>(undefined);

  const [linkStatus, setLinkStatus] = useState<'error' | 'init' | 'linked' | 'linking' | 'loaded'>(
    'init'
  );

  const [mutateCreatePlaidLinkToken] = Scheduler.Account.Plaid.useCreatePlaidLinkToken();
  const [mutateLinkPlaidPublicToken] = Scheduler.Account.Plaid.useLinkPlaidPublicToken();

  const createPlaidToken = useCallback(async (): Promise<void> => {
    const plaidToken = await mutateCreatePlaidLinkToken(account.id);

    if (!plaidToken) {
      setLinkStatus('error');
    }

    setPlaidLinkToken(plaidToken);
    setLinkStatus('loaded');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.id]);

  useEffect(() => {
    void createPlaidToken();
  }, [createPlaidToken]);

  const onSuccess = useCallback(
    async (token: string, { accounts }: PlaidLinkOnSuccessMetadata) => {
      const plaidAccount = accounts.length ? Str.normalize(accounts[0].id) : undefined;

      if (plaidAccount) {
        setLinkStatus('linking');

        try {
          const result = await mutateLinkPlaidPublicToken({
            account,
            plaid: { token, account: plaidAccount }
          });

          setLinkStatus(result ? 'linked' : 'error');
        } catch (_error: unknown) {
          const error = _error as Error;

          Sentry.captureException(error);
          setLinkStatus('error');
        }
      } else {
        setLinkStatus('error');
      }
    },
    [account, mutateLinkPlaidPublicToken]
  );

  return (
    <Panel>
      <div className='d-grid row-gap-28px'>
        <h1>Bank Account</h1>
        {linkStatus === 'init' && <p>Loading...</p>}

        {linkStatus === 'error' && (
          <p>There was an error linking your bank account, please try again.</p>
        )}

        {linkStatus === 'linking' && <p>Linking...</p>}

        {linkStatus === 'linked' && <p>Your bank account has been successfully linked.</p>}

        {linkStatus === 'loaded' && plaidLinkToken && (
          <PlaidLinkButton {...{ token: plaidLinkToken, onSuccess }} />
        )}
      </div>
    </Panel>
  );
};
