import { Modal } from '@livecontrol/scheduler/components';
import { useCallback } from 'react';
import type { Event } from '../../store';
import { Editor } from './editor';

export const useEditPassword = (): ((event: Event) => void) => {
  const prompt = Modal.usePrompt();

  const handler = useCallback(
    (event: Event): void => {
      prompt.show(<Editor event={event} onClose={prompt.hide} />);
    },
    [prompt]
  );

  return handler;
};
