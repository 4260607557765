import { Form } from '@livecontrol/core-ui';
import React from 'react';

export const PreferenceToggle = ({
  title,
  description,
  checked,
  notificationName,
  handleLocationNotification
}: {
  title: string;
  description: string;
  checked: boolean;
  notificationName: string;
  handleLocationNotification: (value: boolean, notificationName: string) => void;
}): React.ReactElement => (
  <Form.SwitchField
    abledDescription={false}
    checked={checked}
    className='mb-20px'
    title={title}
    titleClassName='text-dark font-weight-bold font-size-15px'
    descriptionClassName='text-gray-light font-weight-bold font-size-13px mr-32px'
    description={description}
    onChange={(): void => {
      handleLocationNotification(!checked, notificationName);
    }}
  />
);
