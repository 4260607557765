import type { Any } from '@livecontrol/core-utils';
import { Obj, Str } from '@livecontrol/core-utils';
import type { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { FormikContext } from 'formik';
import _ from 'lodash';
import React, { useContext } from 'react';

export namespace Utils {
  export type InjectProps<T = Any> = React.HTMLAttributes<T> & {
    name?: string;
    [key: string]: unknown;
  };

  export const useField = <T = Any>(
    arg: string | { name?: string }
  ):
    | { props: FieldInputProps<T>; meta: FieldMetaProps<T>; helpers: FieldHelperProps<T> }
    | undefined => {
    const formik = useContext(FormikContext);

    const props = ((): { name: string } | undefined => {
      let p;

      if (Obj.isObject(arg)) {
        const { name, ...rest } = <Obj.Rec>arg;

        const candidate = Str.normalize(name);

        if (candidate) {
          p = { ...rest, name: candidate };
        }
      } else {
        const candidate = Str.normalize(arg);

        if (candidate) {
          p = { name: candidate };
        }
      }

      return p;
    })();

    const { name } = props ?? {};

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return name && formik
      ? {
          meta: formik.getFieldMeta(name),
          helpers: formik.getFieldHelpers(name),
          props: formik.getFieldProps(props)
        }
      : undefined;
  };

  export const injectFormik = <P extends InjectProps>(
    WrappedComponent: React.ElementType<P>
  ): React.ForwardRefExoticComponent<
    React.PropsWithoutRef<P> & React.RefAttributes<HTMLElement>
  > => {
    const X = React.forwardRef((props: P, ref: React.Ref<HTMLElement>): React.ReactElement => {
      const field = useField(props);

      let passthru;

      // If formik is enabled, hook it up
      if (field) {
        const { onChange, onBlur, ...rest } = props;

        passthru = {
          ..._.omit(field.props, 'onChange', 'onBlur'),
          ...rest,
          isInvalid: field.meta.touched && field.meta.error,
          onChange: (event: React.ChangeEvent<Any>): void => {
            field.props.onChange(event);

            // Call original event handler (if applicable)
            if (onChange) {
              onChange(event);
            }
          },
          onBlur: (event: React.FocusEvent<Any>): void => {
            field.props.onBlur(event);

            // Call original event handler (if applicable)
            if (onBlur) {
              onBlur(event);
            }
          }
        };
      } else {
        passthru = props;
      }

      return React.createElement(WrappedComponent, {
        ref,
        ...(<P>passthru)
      });
    });

    // eslint-disable-next-line
    X.displayName = `injectFormik(${(WrappedComponent as Any).displayName ?? 'Component'})`;

    return X;
  };
}
