import type { ReactElement } from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  .custom-white-buttom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-right: 14px;
    background-color: #ffffff;
    border-radius: 4px;
    min-width: 134px;
    padding: 8px 11px;
    border: 1px solid #8798ad;
    text-decoration: none;

    .text {
      color: #0d1438;
      font-size: 12px;
      font-weight: 600;
      min-height: 20px;
      display: flex;
      align-items: center;
    }

    &:hover {
      background-color: #8798ad;
    }
  }
  @media (max-width: 575px) {
    .custom-white-buttom {
      margin-right: 0px;
    }
  }
`;

export const ViewProfileButton = ({ webPlayerUrl }: { webPlayerUrl?: string }): ReactElement => (
  <ButtonContainer>
    <a href={webPlayerUrl} target='_blank' className='custom-white-buttom' rel='noreferrer'>
      <div className='text'>View Profile</div>
    </a>
  </ButtonContainer>
);
