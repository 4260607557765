import { Tooltip } from '@livecontrol/core-ui';
import type { FormikContextType } from 'formik';
import type { ReactElement } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import type { Values } from './form';

const Select = styled.select`
  max-width: 500px;
  text-align: left;
  padding: 8px 42px 8px 9px;
  border: 1.5px solid var(--dark);
  border-radius: 8px;
  height: 35px;
  appearance: none;
  background-image: url(assets/icons/arrow.svg);
  background-repeat: no-repeat;
  background-size: 14px auto;
  background-position: right 14px center;
  line-height: normal;

  &::after {
    content: none;
  }
`;

interface Props {
  formik: FormikContextType<Values>;
  setRoleContact: (x: number) => void;
  setErrors: (x: number[]) => void;
  defaultValue: string;
}

const tooltipMessage = {
  message: 'Primary contact for all non-technical account setup items.',
  subMessage: 'One per location.',
  defaultMessage: 'Default: Main Account Holder.'
};

export const RoleContacts = ({
  formik,
  setErrors,
  setRoleContact,
  defaultValue
}: Props): ReactElement => {
  const [message, setMessage] = useState(tooltipMessage);
  const [dropdownValue, setDropdownValue] = useState(defaultValue);

  // const location = useLocation();

  const roleSelected = (event: React.ChangeEvent<HTMLSelectElement> | number): void => {
    let value = 5;

    value = typeof event === 'number' ? event : Number(event.target.value);

    setRoleContact(value);
    setErrors([]);

    formik.resetForm();

    switch (value) {
      case 1:
        setMessage({
          message: 'Primary contact for all non-technical account setup items.',
          subMessage: 'One per account.',
          defaultMessage: 'Default: Main Account Holder.'
        });

        break;
      case 2:
        setMessage({
          message: 'Primary contact for all technical account setup items.',
          subMessage: 'One per location.',
          defaultMessage: 'Default: Main Account Holder.'
        });

        break;
      case 3:
        setMessage({
          message: 'Receives billing communication.',
          subMessage: 'One per account.',
          defaultMessage: 'Default: Main Account Holder.'
        });

        break;
      case 4:
        setMessage({
          message: 'Receives equipment shipments and handles any equipment returns.',
          subMessage: 'One per location.',
          defaultMessage: 'Default: Main Account Holder.'
        });

        break;
      default:
        setMessage({
          message:
            'Manages urgent event and equipment-related matters. Assign priority outreach to let our Support team know who to call first, second, etc.',
          subMessage: 'At least one per account.',
          defaultMessage: 'Default: Main Account Contact.'
        });
    }
  };

  return (
    <div className='d-flex flex-row font-size-12px'>
      <Select
        value={dropdownValue}
        name='roleContaact'
        onChange={(e): void => {
          const selectedValue = e.target.value;

          setDropdownValue(selectedValue);
          roleSelected(Number(selectedValue));
        }}
      >
        <option value={5}>On-Site Technical</option>
        <option value={3}>Primary Billing</option>
        <option value={4}>Shipping</option>
        {/* <option value={1}>Onboarding Main</option>
        <option value={2}>Onboarding Technical</option> */}
      </Select>
      <Tooltip
        backgroundColor='#0C3192'
        tip={
          <div>
            <div className='font-weight-bold font-size-11px'>{message.message}</div>
            <div className='font-weight-bold font-size-11px mt-16px'>{message.subMessage}</div>
            <div className='font-weight-bold font-size-11px'>{message.defaultMessage}</div>
          </div>
        }
      >
        <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
      </Tooltip>
    </div>
  );
};
