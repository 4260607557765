import type { ApolloError, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import type { MutationResult } from '../../graphql';
import type { ContactRole } from '../dbx';

interface TVariables {
  contact_role: string;
  input: inputsArgs[];
}

interface TVResult {
  upsertContactRole: ContactRole[];
}

interface Args {
  contactRole: string;
  input: inputsArgs[];
}

interface inputsArgs {
  user_id: number;
  location_id?: string;
  value: boolean;
  priority?: number;
}

const MUTATION = gql`
  mutation upsertContactRole($input: [UpsertContactRoleInput!]!, $contact_role: String!) {
    upsertContactRole(input: $input, contact_role: $contact_role) {
      id
      onboarding_main
      onboarding_technical
      onsite_technical
      onsite_technical_priority
      primary_billing
      shipping
      user {
        id
        email
      }
      location {
        id
        name
      }
    }
  }
`;

export const useEditContactRole = (): [
  (args: Args) => Promise<ContactRole[] | undefined>,
  MutationResult<ContactRole[], 'contactRole'>
] => {
  const [mutation, result] = useMutation<TVResult, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [contactRole, setContactRole] = useState<ContactRole[] | undefined>();

  return [
    useCallback(
      async (args: Args): Promise<ContactRole[] | undefined> => {
        let contactRole_: ContactRole[] | undefined;

        try {
          // Parse the input arguments

          const variables = {
            contact_role: args.contactRole,
            input: args.input
          };

          const response = await mutation({ variables })
            .then(({ data }: FetchResult<TVResult>) => data)
            .catch((_error: ApolloError) => {
              throw new Error('EventID/ClientID is required.');
            });

          // Parse the server response

          contactRole_ = response ? response.upsertContactRole : undefined;

          setContactRole(contactRole_);

          if (!contactRole_) {
            throw new Error('EventID/ClientID is required.');
          }
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        return contactRole_;
      },
      [mutation, setError, setContactRole]
    ),
    {
      contactRole,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
