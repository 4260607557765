import { Loading, Theme, Viewport } from '@livecontrol/core-ui';
import type { Location, User } from '@livecontrol/scheduler/model';
import { Event } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { EventValidator, validateEventUpdate } from '@livecontrol/scheduler/validator';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import _ from 'lodash';
import { DateTime, IANAZone } from 'luxon';
import type { Moment } from 'moment';
import moment from 'moment';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Form } from '../../../components';
import { Store, store } from '../../../store';
import type { EventContext } from '../../create-event';
import {
  ContentSection,
  CustomInputContainer,
  EventContextStore,
  EventDetailsForm,
  EventDetailsUtils,
  EventImages,
  FooterSection,
  HeaderSection,
  OtherOptions,
  VideographerFeatures,
  WebplayerFeatures
} from '../../create-event';

const LogisticsFormSection = styled.div`
  .info-section {
    width: 228px;
    padding: 8px 28px;
    .title,
    .text {
      color: #2e384d;
      font-size: 15px;
      font-weight: 600;
    }
    .title {
      margin-bottom: 16px;
    }
    .text {
      margin-top: 4px;
    }
    .credits-number {
      width: 32px;
      height: 32px;
      border-radius: 16px;
      background-color: #2e5bff;
      color: #ffffff;
    }
  }
  .lead-time-error {
    max-width: 900px;
    font-size: 15px;
    margin-bottom: 42px;
    .error-text,
    .error-text a {
      color: #ff0000;
      margin-bottom: 24px;
      line-height: 23px;
      a {
        text-decoration: underline;
        color: #2e5bff;
      }
    }
    .invalid-feedback {
      display: none;
    }
    input {
      width: 16px;
      cursor: pointer;
    }
    .form-check {
      display: flex;
      align-items: center;
      .form-group {
        margin-bottom: 0px;
        margin-right: 16px;
      }
    }
    .form-check-label {
      font-weight: 600;
    }
  }
`;

function usePrevious<T>(value: T): T | undefined {
  const ref = React.useRef<T>();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export const EventDetails = ({
  onBack,
  onContinue,
  activeStep = 0,
  locations,
  accountSettings
}: {
  onBack: () => void;
  onContinue: () => void;
  activeStep?: number;
  locations: Location[] | undefined;
  accountSettings: User.admin;
}): React.ReactElement => {
  const { production } = store.environment;
  const account = Store.Account.useAccount();
  const isAdmin = Store.User.useIsAdmin();
  const { events: allEvents } = Scheduler.Event.useEvents(account);
  const { contacts } = Scheduler.Event.useGetTechnicalContacts({ clientId: account.id });
  const [showLeadtimeMessage, setShowLeadtimeMessage] = useState(false);

  const { store: eventDetailsContext, existingEvent }: EventContext =
    useContext<EventContext>(EventContextStore);

  const viewport = Theme.useViewport();

  const showOnSiteContact =
    eventDetailsContext.eventType !== EventValidator.ProductionType.TEST &&
    eventDetailsContext.eventType !== EventValidator.ProductionType.SIMULATED &&
    eventDetailsContext.eventType !== EventValidator.ProductionType.MOBILE &&
    accountSettings.userGlobalSettings.onSiteEventContact;

  const showVideographerNotes = accountSettings.userGlobalSettings.operatorEventNotes;

  const availableLocations = useMemo(
    (): Location[] =>
      EventDetailsUtils.getAvailableLocations(locations, eventDetailsContext.eventType).sort(
        (a, b) => a.name.localeCompare(b.name)
      ),
    [locations, eventDetailsContext]
  );

  const selectedLocation =
    eventDetailsContext.eventInfo.location &&
    availableLocations.some((location) => location.id === eventDetailsContext.eventInfo.location)
      ? eventDetailsContext.eventInfo.location
      : availableLocations.length
      ? availableLocations[0].id
      : '';

  const timezone: string | undefined = useMemo(() => {
    const selected = availableLocations.find(
      (location) => location.id === eventDetailsContext.eventInfo.location
    );

    const timezone_ = selected
      ? selected.timezone
      : availableLocations.length
      ? availableLocations[0].timezone
      : undefined;

    return timezone_;
  }, [availableLocations, eventDetailsContext.eventInfo.location]);

  const currentSelectedLocation = availableLocations.find(
    (location) => location.id === eventDetailsContext.eventInfo.location
  ) ?? availableLocations[0];

  const showZoomInput = currentSelectedLocation?.addOns?.some((addon) => addon.type === 'zoom');

  const slidesOn =
    (eventDetailsContext.eventType === EventValidator.ProductionType.PRODUCED ||
      eventDetailsContext.eventType === EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT) &&
    currentSelectedLocation?.addOns?.some((addon) => addon.type === 'intro_outro');

  const isProduced = eventDetailsContext.eventType === EventValidator.ProductionType.PRODUCED;

  const isProducedAndMobileKit =
    eventDetailsContext.eventType === EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT;

  const isMobileKit = eventDetailsContext.eventType === EventValidator.ProductionType.MOBILE_KIT;

  const isEditingProducedAndMobile = existingEvent?.linkedEventId;

  const initialDate = existingEvent?.start.setZone(timezone ?? account.timezone);

  const initialStartTime = moment(
    existingEvent?.start.setZone(timezone ?? account.timezone).toFormat('hhmm a'),
    'hhmm a'
  );

  const initialEndTime = moment(
    existingEvent?.end.setZone(timezone ?? account.timezone).toFormat('hhmm a'),
    'hhmm a'
  );

  const contactsByLocation = contacts?.filter(
    (contact) => contact.locationId === eventDetailsContext.eventInfo.location
  );

  const selectedTechnicalContact = eventDetailsContext.eventInfo.technicalContactSettings?.contactId
    ? eventDetailsContext.eventInfo.technicalContactSettings.contactId
    : contactsByLocation?.length
    ? contactsByLocation[0].id
    : 0;

  const desktopFormLayout = useCallback(
    (
      values: EventDetailsForm.Values,
      errors: EventDetailsForm.Errors,
      setErrors: FormikHelpers<EventDetailsForm.Values>['setErrors']
    ): React.ReactElement => {
      const { startTime, endTime } = EventDetailsUtils.calculateEventTimes({ ...values, timezone });

      if (isEditingProducedAndMobile && values.redirectUrlSettings) {
        values.redirectUrlSettings.enabled = false;
      }

      if (!showLeadtimeMessage) {
        const convertedNewStart = moment(
          startTime?.setZone(timezone ?? account.timezone).toFormat('hhmm a'),
          'hhmm a'
        );

        const convertedNewEnd = moment(
          endTime?.setZone(timezone ?? account.timezone).toFormat('hhmm a'),
          'hhmm a'
        );

        if (
          initialDate?.day !== values.date?.day ||
          initialStartTime.diff(convertedNewStart) !== 0 ||
          initialEndTime.diff(convertedNewEnd) !== 0
        ) {
          setShowLeadtimeMessage(true);
        }
      }

      const schedulingFieldsDisabled =
        eventDetailsContext.eventType === EventValidator.ProductionType.PRODUCED &&
        !isAdmin &&
        production &&
        (startTime?.diffNow().as('days') ?? account.leadTime) < account.leadTime;

      const timeFieldsDisabled =
        eventDetailsContext.eventType === EventValidator.ProductionType.PRODUCED &&
        !isAdmin &&
        production &&
        Math.abs(existingEvent?.start.diffNow().as('hours') ?? 25) < 24;

      const beforeLeadTime = isAdmin
        ? false
        : (startTime?.diffNow().as('days') ?? account.leadTime + 1) < account.leadTime;

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const previousValues = usePrevious(values);

      let dateHasChanged = false;

      if (
        values.date &&
        previousValues?.date &&
        values.startTime &&
        previousValues.startTime &&
        values.endTime &&
        previousValues.endTime &&
        (!values.date.equals(previousValues.date) ||
          !values.startTime.isSame(previousValues.startTime) ||
          !values.endTime.isSame(previousValues.endTime))
      ) {
        dateHasChanged = true;
      }

      if (isProduced || isMobileKit) {
        if (!beforeLeadTime) {
          values.leadtimeAcknowledge = undefined;
        } else if (values.leadtimeAcknowledge === undefined) {
          values.leadtimeAcknowledge = false;
        }
      } else {
        values.leadtimeAcknowledge = true;
      }

      if (values.leadtimeAcknowledge && dateHasChanged) {
        values.leadtimeAcknowledge = false;
      }

      if (beforeLeadTime && isEditingProducedAndMobile) {
        values.leadtimeAcknowledge = false;

        if (Object.keys(errors).length < 1) {
          setErrors({ ...errors, leadtimeAcknowledge: 'Lead time warning must be acknowledged.' });
        }
      } else if (!beforeLeadTime && isEditingProducedAndMobile && errors.leadtimeAcknowledge) {
        const updatedErrors = { ...errors };

        delete updatedErrors.leadtimeAcknowledge;
        setErrors(updatedErrors);
      }

      return (
        <>
          {!isEditingProducedAndMobile && (
            <HeaderSection>
              <div className='description'>
                <div className='text'>
                  {schedulingFieldsDisabled && timeFieldsDisabled
                    ? 'To change your event location, date, or time, you must go back to Step 1 and change this to a Static Event'
                    : schedulingFieldsDisabled
                    ? 'To change your event location or date, you must go back to Step 1 and change this to a Static Event'
                    : timeFieldsDisabled
                    ? 'To change your event time, you must go back to Step 1 and change this to a Static Event'
                    : 'To change your event type, you must go back to Step 1'}
                </div>
              </div>
            </HeaderSection>
          )}

          <HeaderSection>
            <div className='title'>
              {isEditingProducedAndMobile ? (
                <div className='text'>Produced Event Details </div>
              ) : (
                <div className='text'>Logistics </div>
              )}
            </div>
          </HeaderSection>

          <ContentSection>
            <LogisticsFormSection>
              <div className='custom-form-group d-flex justify-content-between flex-wrap'>
                <EventDetailsForm.EventLocation
                  options={availableLocations}
                  disabled={schedulingFieldsDisabled}
                  errors={errors}
                />
                <EventDetailsForm.Date
                  timezone={timezone}
                  eventType={eventDetailsContext.eventType}
                  disabled={schedulingFieldsDisabled}
                  errors={errors}
                />
              </div>

              <div className='custom-form-group d-flex justify-content-between flex-wrap'>
                <div className='d-flex times-section justify-content-between flex-wrap'>
                  <EventDetailsForm.StartTime timezone={timezone} errors={errors} />

                  {eventDetailsContext.eventType === EventValidator.ProductionType.SIMULATED ? (
                    <Form.Group
                      name='fixedEndTime'
                      label={
                        <label>
                          End Time{' '}
                          {timezone && (
                            <span style={{ color: '#0a4b86', fontWeight: 800 }}>
                              ({DateTime.local().setZone(timezone).toFormat('ZZZZ')})
                            </span>
                          )}
                        </label>
                      }
                      error={
                        <p className='text-muted font-size-12px font-weight-normal'>
                          End time is calculated based on recorded video duration.
                        </p>
                      }
                      controlProps={{
                        as: 'select',
                        defaultValue: 1,
                        disabled: true
                      }}
                      className='d-flex flex-column custom-input time-input'
                    >
                      <option value={1}>
                        {startTime && eventDetailsContext.simulatedLiveAssetDetails?.duration
                          ? startTime
                              .plus(eventDetailsContext.simulatedLiveAssetDetails.duration)
                              .toFormat('h:mm a')
                              .toLowerCase()
                          : startTime && eventDetailsContext.simulatedLiveFileUpload?.videoDuration
                          ? startTime
                              .plus(eventDetailsContext.simulatedLiveFileUpload.videoDuration)
                              .toFormat('h:mm a')
                              .toLowerCase()
                          : startTime
                          ? startTime.plus({ hours: 3 }).toFormat('h:mm a').toLowerCase()
                          : ''}
                      </option>
                    </Form.Group>
                  ) : !isAdmin &&
                    eventDetailsContext.eventType === EventValidator.ProductionType.TEST ? (
                    <Form.Group
                      name='fixedEndTime'
                      label={
                        <label>
                          End Time{' '}
                          {timezone && (
                            <span style={{ color: '#0a4b86', fontWeight: 800 }}>
                              ({DateTime.local().setZone(timezone).toFormat('ZZZZ')})
                            </span>
                          )}
                        </label>
                      }
                      error={
                        <p className='text-muted font-size-12px font-weight-normal'>
                          Test events are{' '}
                          {EventValidator.SchedulerDurations.TEST_EVENT_DURATION.minutes} minutes
                        </p>
                      }
                      controlProps={{
                        as: 'select',
                        defaultValue: 1,
                        disabled: true
                      }}
                      className='d-flex flex-column custom-input time-input'
                    >
                      <option value={1}>
                        {startTime
                          ? startTime
                              .plus(EventValidator.SchedulerDurations.TEST_EVENT_DURATION)
                              .setZone(timezone ?? account.timezone)
                              .toFormat('h:mm a')
                              .toLowerCase()
                          : ''}
                      </option>
                    </Form.Group>
                  ) : (
                    <EventDetailsForm.EndTime
                      errors={errors}
                      eventType={eventDetailsContext.eventType}
                      startTime={startTime}
                      endTime={endTime}
                      timezone={timezone}
                      startDate={values.date}
                      disabled={timeFieldsDisabled}
                      disabledReason='End time cannot be changed so close to the event start time.'
                    />
                  )}
                </div>

                <div className='lead-time-error'>
                  {!isAdmin &&
                    isProduced &&
                    beforeLeadTime &&
                    showLeadtimeMessage &&
                    !isEditingProducedAndMobile && (
                      <>
                        <div className='error-text'>
                          You are attempting to schedule or edit the date/time of a Produced event
                          outside of your lead time - {account.leadTime * 24} hours. This event will
                          default to a Static event. Please finish scheduling the event, then call
                          Support if you would like to change it to Produced. Refer to our{' '}
                          <a
                            href='https://mylivecontrol.zendesk.com/hc/en-us/articles/13287638920471-Last-Minute-Event-Policy-Late-Scheduling'
                            target='_blank'
                            rel='noreferrer noopener'
                          >
                            Late Scheduling Policy
                          </a>{' '}
                          here. Late requests are not guaranteed.
                        </div>
                        <div className='form-check'>
                          <Form.Group
                            name='leadtimeAcknowledge'
                            className=''
                            label={false}
                            controlProps={{
                              type: 'checkbox'
                            }}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='leadtimeAcknowledge'
                            style={{ cursor: 'default' }}
                          >
                            Acknowledge
                          </label>
                        </div>
                      </>
                    )}
                  {!isAdmin && isProduced && beforeLeadTime && isEditingProducedAndMobile && (
                    <div className='error-text'>
                      You are attempting to schedule or edit the date/time of a Produced + Mobile
                      Kit event outside of your lead time - {account.leadTime * 24} hours. This
                      event cannot be scheduled or edited. Please call Support if you would like to
                      schedule or modify this event. Late requests are not guaranteed. For more
                      information, you may refer to our{' '}
                      <a
                        href='https://mylivecontrol.zendesk.com/hc/en-us/articles/13287638920471-Last-Minute-Event-Policy-Late-Scheduling'
                        target='_blank'
                        rel='noreferrer noopener'
                      >
                        Late Scheduling Policy here.
                      </a>
                    </div>
                  )}
                  {!isAdmin && isMobileKit && beforeLeadTime && (
                    <div className='error-text'>
                      You are attempting to schedule or edit the date/time of a Mobile Kit event
                      outside of your lead time - {account.leadTime * 24} hours. Please call our
                      Support team to make any scheduling changes to this event. Refer to our{' '}
                      <a
                        href='https://mylivecontrol.zendesk.com/hc/en-us/articles/13287638920471-Last-Minute-Event-Policy-Late-Scheduling'
                        target='_blank'
                        rel='noreferrer noopener'
                      >
                        Late Scheduling Policy here
                      </a>{' '}
                      . Late requests are not guaranteed.
                    </div>
                  )}
                </div>
              </div>
            </LogisticsFormSection>
          </ContentSection>

          <HeaderSection>
            <div className='divider' />
            <div className='title'>
              <div className='text'>Information </div>
            </div>
          </HeaderSection>

          <ContentSection>
            <CustomInputContainer>
              <EventDetailsForm.Title
                prefix={
                  eventDetailsContext.eventType === EventValidator.ProductionType.TEST
                    ? 'Test \u2013 '
                    : undefined
                }
              />
            </CustomInputContainer>

            {eventDetailsContext.eventType !== EventValidator.ProductionType.TEST && (
              <>
                <CustomInputContainer>
                  <EventDetailsForm.Description />
                </CustomInputContainer>

                {showOnSiteContact && (
                  <CustomInputContainer>
                    <EventDetailsForm.TechnicalContact
                      contacts={contacts}
                      showTechnicalContacts={
                        eventDetailsContext.eventInfo.technicalContactSettings?.enabled ?? false
                      }
                      selectedLocationId={selectedLocation}
                    />
                  </CustomInputContainer>
                )}

                {(isProduced || isProducedAndMobileKit) && showVideographerNotes && (
                  <CustomInputContainer>
                    <EventDetailsForm.OperatorNotes />
                  </CustomInputContainer>
                )}
              </>
            )}
          </ContentSection>
        </>
      );
    },
    [
      timezone,
      isEditingProducedAndMobile,
      showLeadtimeMessage,
      eventDetailsContext.eventType,
      eventDetailsContext.simulatedLiveAssetDetails?.duration,
      eventDetailsContext.simulatedLiveFileUpload?.videoDuration,
      eventDetailsContext.eventInfo.technicalContactSettings?.enabled,
      selectedLocation,
      isAdmin,
      production,
      account.leadTime,
      account.timezone,
      existingEvent?.start,
      isProduced,
      isMobileKit,
      availableLocations,
      showOnSiteContact,
      contacts,
      showVideographerNotes,
      initialDate?.day,
      initialStartTime,
      initialEndTime,
      isProducedAndMobileKit
    ]
  );

if (!allEvents || !existingEvent || !currentSelectedLocation) {
    return <Loading.Delay />;
  }

  const formik = {
    initialValues: {
      type: eventDetailsContext.eventType,
      location: selectedLocation,
      date: eventDetailsContext.eventInfo.start?.setZone(timezone ?? account.timezone),
      startTime: moment(
        eventDetailsContext.eventInfo.start
          ?.setZone(timezone ?? account.timezone)
          .toFormat('hhmm a'),
        'hhmm a'
      ),
      endTime: moment(
        eventDetailsContext.eventInfo.end?.setZone(timezone ?? account.timezone).toFormat('hhmm a'),
        'hhmm a'
      ),
      title:
        existingEvent.production === Event.Production.Test &&
        eventDetailsContext.eventType === EventValidator.ProductionType.TEST
          ? eventDetailsContext.eventInfo.title?.replace('Test - ', '')
          : eventDetailsContext.eventInfo.title,
      description: eventDetailsContext.eventInfo.description,
      backgroundURL: eventDetailsContext.eventInfo.backgroundURL,
      operatorNotes: eventDetailsContext.eventInfo.operatorNotes,
      passwordSettings: eventDetailsContext.eventInfo.passwordSettings,
      chatSettings: eventDetailsContext.eventInfo.chatSettings,
      redirectUrlSettings: eventDetailsContext.eventInfo.redirectUrlSettings,
      cueSheetURL: eventDetailsContext.eventInfo.cueSheetURL,
      repeatSettings: {
        repeat: false
      },
      zoomDetails: {
        streamZoom: existingEvent.zoomDetails?.streamZoom ?? false,
        meetingId: existingEvent.zoomDetails?.meetingId ?? '',
        password: existingEvent.zoomDetails?.password ?? ''
      },
      technicalContactSettings: {
        enabled: eventDetailsContext.eventInfo.technicalContactSettings?.enabled,
        contactId: selectedTechnicalContact
      },
      useIntroAndOutroSlides: eventDetailsContext.eventInfo.useIntroAndOutroSlides,
      receiveIsoRecordings: eventDetailsContext.eventInfo.receiveIsoRecordings,
      leadtimeAcknowledge: true
    },
    validate: (
      values: Exclude<EventValidator.EventInfo, { start: DateTime; end: DateTime }> & {
        date?: DateTime;
        startTime?: Moment;
        endTime?: Moment;
        leadTime?: boolean;
        leadtimeAcknowledge?: boolean;
      }
    ): EventValidator.Errors => {
      let startDate = _.cloneDeep(values.date)?.setZone(timezone ?? account.timezone);
      let endDate = _.cloneDeep(values.date)?.setZone(timezone ?? account.timezone);

      if (startDate && values.startTime) {
        const start = DateTime.fromFormat(values.startTime.format('hhmm a'), 'hhmm a', {
          zone: timezone ?? account.timezone
        });

        if (!start.isValid) {
          return {
            startTime: EventValidator.ErrorMessages.INVALID_TIME_FORMAT
          };
        }

        startDate = startDate.set({ hour: start.hour, minute: start.minute }).toUTC();
      }

      if (endDate?.isValid && values.endTime) {
        const end = DateTime.fromFormat(values.endTime.format('hhmm a'), 'hhmm a', {
          zone: timezone ?? account.timezone
        });

        if (!end.isValid) {
          return {
            endTime: EventValidator.ErrorMessages.INVALID_TIME_FORMAT
          };
        }

        endDate =
          eventDetailsContext.eventType === EventValidator.ProductionType.SIMULATED
            ? startDate?.plus(
                eventDetailsContext.simulatedLiveAssetDetails?.duration ??
                  eventDetailsContext.simulatedLiveFileUpload!.videoDuration
              )
            : eventDetailsContext.eventType === EventValidator.ProductionType.TEST
            ? startDate?.plus(EventValidator.SchedulerDurations.TEST_EVENT_DURATION)
            : endDate.set({ hour: end.hour, minute: end.minute }).toUTC();

        if (startDate && endDate && endDate < startDate) {
          endDate = endDate.plus({ day: 1 });
        }
      }

      const { cueSheetURL } = values;

      const errors = validateEventUpdate({
        events: allEvents,
        existingEvent,
        cueSheetURL: EventDetailsUtils.isCueSheetEnabled(availableLocations, values.location)
          ? cueSheetURL
          : undefined,
        eventUpdate: {
          type: eventDetailsContext.eventType,
          start: startDate?.setZone(timezone ?? account.timezone),
          end: endDate?.setZone(timezone ?? account.timezone),
          zoomDetails: {
            meetingId: values.zoomDetails?.meetingId,
            password: values.zoomDetails?.password
          },
          ..._.omit(values, ['startTime', 'endTime', 'date'])
        },
        simulatedLiveAssetIsUpload: eventDetailsContext.simulatedLiveAssetIsUpload,
        leadTime: account.leadTime,
        timezone: timezone ? new IANAZone(timezone) : account.timezone,
        isAdmin,
        isProd: production
      });

      eventDetailsContext.setEventInfo({
        ...eventDetailsContext.eventInfo,
        start: startDate,
        end: endDate,
        ..._.omit(values, ['startTime', 'endTime', 'date']),
        isValid: _.isEmpty(errors)
      });

      return errors;
    },

    onSubmit(): void {
      onContinue();
    }
  };

  return (
    <div className='mt-28px'>
      <Formik {...formik}>
        {({ values, errors, setErrors }): React.ReactElement => (
          <EventDetailsForm.Wrapper $isMobile={viewport === Viewport.XS}>
            {desktopFormLayout(values, errors, setErrors)}
            {eventDetailsContext.eventType !== EventValidator.ProductionType.TEST && (
              <>
                <EventImages />
                <WebplayerFeatures accountSettings={accountSettings}/>
                {(eventDetailsContext.eventType === EventValidator.ProductionType.PRODUCED ||
                  eventDetailsContext.eventType ===
                    EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT) && (
                  <VideographerFeatures showZoomInput={showZoomInput} slidesOn={slidesOn} accountSettings={accountSettings}/>
                )}
                <OtherOptions timezone={timezone} creatingEvent={false} accountSettings={accountSettings}/>
              </>
            )}
            <FooterSection>
              <EventDetailsForm.Submit
                onBack={onBack}
                hideBackBtn={!!isEditingProducedAndMobile && activeStep === 1}
              />
            </FooterSection>
          </EventDetailsForm.Wrapper>
        )}
      </Formik>
    </div>
  );
};
