import type { PropsWithChildren, ReactElement } from 'react';
import type { DropEvent, FileRejection } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';

const ACCEPTED_VIDEO_FORMATS = [
  'video/*',
  'video/mp4',
  'video/x-m4v',
  '.hevc',
  '.3gpp',
  '.3gp',
  '.webm',
  '.mpg',
  '.mp2',
  '.mpeg',
  '.mpe',
  '.mpv',
  '.ogg',
  '.mp4',
  '.m4p',
  '.m4v',
  '.avi',
  '.wmv',
  '.mov',
  '.qt',
  '.flv',
  '.swf'
];

export const FileDrop = ({ children, className, onDrop }: FileDrop.Props): ReactElement => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ACCEPTED_VIDEO_FORMATS.join(',')
  });

  return (
    <div className={className} {...getRootProps()}>
      <input type='file' {...getInputProps()} />
      {children}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace FileDrop {
  export type Props = PropsWithChildren<{
    className?: string;
    onDrop?: <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => void;
  }>;
}
