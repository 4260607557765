import { Boundary, Utils } from '@livecontrol/core-ui';
import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import { Kaboom, NotFound } from './components';
import type { Environment } from './model';
import { Providers } from './providers';
import { Router } from './routes';
import { Reactor, Storage, Store } from './store';

const ZENDESK_KEY = '23d09bc8-37f2-4b6d-b9e4-17b9f6822298';

export const App = (props: { environment: Environment }): React.ReactElement | null => {
  // get the current logged in user
  const [isReady, setReady] = useState(false);
  // Initialize the global store

  useEffect(
    () => {
      Store.initialize(props.environment);
      Utils.setIntent();
      setReady(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );


  return isReady ? (
    <Providers>
      <BrowserRouter>
        <Zendesk zendeskKey={ZENDESK_KEY} {...{}} onLoaded={(): void => {
              const zendeskToken = Storage.getZendeskToken();

              if(zendeskToken){
                ZendeskAPI('messenger:set', 'zIndex', 9999999);

                ZendeskAPI('messenger', 'loginUser', (callback: (arg0: string) => void) => {
                  callback(zendeskToken);
                });
              }else{
                ZendeskAPI('messenger:set', 'zIndex', -9999999);
              }
            }
          } />
        <Reactor>
          <Boundary notFound={NotFound} kaboom={Kaboom}>
            <Router />
          </Boundary>
        </Reactor>
      </BrowserRouter>
    </Providers>
  ) : null;
};
