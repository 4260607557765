import { DateTime } from 'luxon';
import { EventValidator } from './types';

interface Props {
  newEventInfo: EventValidator.EventInfo;
  existingEvent?: {
    id: number;
    start: DateTime;
    end: DateTime;
  };
  simulatedLiveAssetIsUpload: boolean;
  leadTime?: number;
  isAdmin?: boolean;
  isProd?: boolean;
}

export const validateEventDates = ({
  newEventInfo,
  existingEvent,
  simulatedLiveAssetIsUpload,
  leadTime,
  isAdmin,
  isProd
}: Props): EventValidator.Errors => {
  const errors: EventValidator.Errors = {};
  const currentTime = DateTime.utc();
  const { start, end } = newEventInfo;

  if (!start || !DateTime.isDateTime(start)) {
    errors.startTime = EventValidator.ErrorMessages.START_DATE_REQUIRED;
  } else if (currentTime > start) {
    errors.date = EventValidator.ErrorMessages.START_DATE_ALREADY_PASSED;
  } else if (isProd && !isAdmin) {
    if (
      !existingEvent?.start &&
      newEventInfo.type === EventValidator.ProductionType.SIMULATED &&
      simulatedLiveAssetIsUpload &&
      currentTime.plus(EventValidator.SchedulerDurations.SIMULATED_LIVE_FROM_UPLOAD_LEAD_TIME) > start
    ) {
      // 2 hour rule only applies to simulated live events
      errors.startTime = EventValidator.ErrorMessages.START_TIME_TOO_SOON_2H;
    } else if (!existingEvent?.start && currentTime.plus(EventValidator.SchedulerDurations.NEW_EVENT_LEAD_TIME) > start) {
      // 15 minute rule only applies to new events
      errors.startTime = EventValidator.ErrorMessages.START_TIME_TOO_SOON_15M;
    } else if (!!existingEvent?.start && currentTime.plus(EventValidator.SchedulerDurations.EXISTING_EVENT_LEAD_TIME) > start) {
      errors.startTime = EventValidator.ErrorMessages.START_TIME_TOO_SOON_10M;
    } else if (
      newEventInfo.type === EventValidator.ProductionType.PRODUCED &&
      existingEvent?.start &&
      leadTime &&
      existingEvent.start.diffNow().as('days') < leadTime &&
      Math.abs(existingEvent.start.diff(start, 'hours').as('hours')) > 2
    ) {
      errors.startTime = EventValidator.ErrorMessages.START_TIME_LEAD_TIME_LIMIT;
    }

    if (
      newEventInfo.type === EventValidator.ProductionType.CLIENT_BOOKING &&
      newEventInfo.start &&
      currentTime.plus({ hours: 1 }) > newEventInfo.start
    ) {
      errors.startTime = EventValidator.ErrorMessages.START_TIME_LEAD_TIME_CLIENT_BOOKING_LIMIT;
    }
  }

  // End Time Validation
  if (!end || !DateTime.isDateTime(end)) {
    errors.endTime = EventValidator.ErrorMessages.END_TIME_REQUIRED;
  } else if (!!start && DateTime.isDateTime(start) && end <= start) {
    errors.endTime = EventValidator.ErrorMessages.END_TIME_BEFORE_START;
  } else if (!!start && DateTime.isDateTime(start) && end.diff(start, 'hours').as('hours') > 6) {
    errors.endTime = EventValidator.ErrorMessages.EVENT_TOO_LONG;
  } else if (
    !isAdmin &&
    newEventInfo.type === EventValidator.ProductionType.PRODUCED &&
    existingEvent?.start &&
    leadTime &&
    existingEvent.start.diffNow().as('days') < leadTime &&
    Math.abs(existingEvent.end.diff(end, 'hours').as('hours')) > 2
  ) {
    errors.endTime = EventValidator.ErrorMessages.END_TIME_LEAD_TIME_LIMIT;
  }

  return errors;
};
