import { Device, Tooltip } from '@livecontrol/core-ui';
import { Account } from '@livecontrol/scheduler/model';
import type { Event } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import cx from 'classnames';
import { useRef, useState } from 'react';
import type { ReactElement } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import shallow from 'zustand/shallow';
import { Event as EventComponent } from '../../../components';
import { store, Store } from '../../../store';
import { EmbeddedEventModal } from '../../view-asset';
import { Hooks } from '../hooks';
import { useStore, Utils } from '../store';

const MoreOptionsBtn = styled.div`
  .x-${Device.MOBILE} & {
    .dropdown-menu.show {
      transform: translateX(-80%) !important;
    }
  }

  .dropdown-button {
    width: 44px;
    height: 34px;
    justify-content: center;

    svg {
      margin-right: 0px;
    }
  }

  .show.dropdown {
    button {
      background-color: #ffffff;
      color: #0d1438;
      border: 1px solid #cbcbcb;
    }
  }

  button {
    border: 1px solid #cbcbcb;
    background-color: #ffffff;
    color: #0d1438;
    font-size: 11px;
    font-weight: 600;
    padding: 6px 11px !important;
    border-radius: 4px;
    display: flex;
    align-items: center;

    &:focus {
      background-color: white;
      border: solid 1px #cbcbcb;
    }

    &:hover {
      background-color: #ffffff;
      color: #0d1438;
      border: 1px solid #cbcbcb;
    }

    &:active {
      background-color: #ffffff !important;
      color: #0d1438 !important;
      border: 1px solid #cbcbcb;
    }

    &:after {
      display: none;
    }
  }

  .dropdown-menu {
    &.show {
      position: absolute;
      transform: translateX(-65%) !important;
      inset: unset !important;
      top: calc(100% + 8px) !important;
      pointer-events: all !important;
      opacity: 1 !important;

      left: 0px;
      min-height: 50px;
      min-width: 120px;
      padding: 12px 16px;
      background-color: white;
      width: max-content;

      a.dropdown-item {
        border: 1px solid #cbcbcb;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 600;
        color: #0d1438;
      }
    }
  }

  .button-entry {
    height: 34px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: solid 1px #cbcbcb;
    border-radius: 4px;
    margin-bottom: 8px;
    padding: 4px 11px;
    cursor: pointer;

    &.sent {
      cursor: default;
    }

    .text {
      color: #0d1438;
      font-weight: 600;
      font-size: 12px;
      margin-left: 6px;
    }

    .dd-icon {
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }

    &.custom-danger {
      border: none;
      background-color: #ffe6e6 !important;
      .text {
        color: #ff0000;
      }
    }
  }

  .type-title {
    font-weight: 600;
    font-size: 15px;
    padding: 6px 12px 12px 12px;
    width: 100%;
    text-align: center;
  }

  .share {
    background-image: url(/assets/icons/Upload.svg);
  }

  .launch {
    background-image: url(/assets/icons/Launch-black.svg);
  }

  .clock {
    background-image: url(/assets/icons/Clock-black.svg);
  }

  .trash {
    background-image: url(/assets/icons/Trash-red.svg);
  }

  .edit {
    background-image: url(/assets/icons/Edit-black.svg);
  }

  .film {
    background-image: url(/assets/icons/Film.svg);
  }
  .video-off {
    background-image: url(/assets/icons/Video-off-white.svg);
  }
`;

const StyledToggle = styled(Dropdown.Toggle)`
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  .btn-primary & {
    border-color: #cbcbcb;
  }

  .text {
    color: #0d1438;
    font-weight: 600;
    font-size: 12px;
    margin-left: 6px;
  }

  .dd-icon {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  &.dropdown-toggle.btn::after {
    display: none;
  }
`;

interface Props {
  event: Event;
  isAdmin: boolean;
  linkedEvent?: Event;
  showRequestBtn: boolean;
}

export const EventOptionsButton = ({
  event,
  isAdmin,
  linkedEvent,
  showRequestBtn = true
}: Props): ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const [showModalMobile, setShowModalMobile] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showEmbedModal, setShowEmbedModal] = useState(false);
  const [showEmbedModalMobile, setShowEmbedModalMobile] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailSentMobile, setEmailSentMobile] = useState(false);
  const history = useHistory();
  const { environment } = store;
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [timezone] = useStore((aStore) => [aStore.timezone], shallow);
  const [onDelete] = Hooks.useDelete();
  const onExtend = Hooks.useExtend(timezone);
  const onStop = Hooks.useStop();

  const [resendNotification, { loading }] = Scheduler.Event.useResendClientNotification();
  const account = Store.Account.useAccount();
  const { webPlayer } = Scheduler.Account.WebPlayer.useView(account.id);
  const endpoint = webPlayer?.endpoint;

  const isUpcoming = event.phase === 1;
  const isLive = event.phase === 2;
  const isPast = event.phase === 3;

  const hasPermissionsOnEvent = linkedEvent
    ? Store.User.hasEventPemissionForLocation(linkedEvent.location.id)
    : Store.User.hasEventPemissionForLocation(event.location.id);

  const goEditEvent = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    history.push(`/schedule/edit/${event.id}`);
  };

  const goEditEventMobile = (e: React.MouseEvent<HTMLElement>): void => {
    if (linkedEvent) {
      e.preventDefault();
      history.push(`/schedule/edit-produce-mobile/${event.id}/${linkedEvent.id}`);
    }
  };

  const goEditArchive = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();

    if (event.assets) {
      history.push(`/content-library/library/view/${event.assets[0].id}`);
    }
  };

  const goEditArchiveMobile = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();

    if (linkedEvent?.assets) {
      history.push(`/content-library/library/view/${linkedEvent.assets[0].id}`);
    }
  };

  const handleResendClient = (): void => {
    if (!loading && !emailSent) {
      resendNotification({ eventID: event.id });
      setEmailSent(true);

      setTimeout(() => {
        setEmailSent(false);
      }, 2000);
    }
  };

  const handleResendClientMobile = (): void => {
    if (!loading && !emailSentMobile && linkedEvent) {
      resendNotification({ eventID: linkedEvent.id });
      setEmailSentMobile(true);

      setTimeout(() => {
        setEmailSentMobile(false);
      }, 2000);
    }
  };

  const handleDelete = (eventToDelete: Event): void => {
    onDelete(eventToDelete);
  };

  return (
    <MoreOptionsBtn>
      <Dropdown
        show={showDropdown}
        onClick={(clickEvent: { stopPropagation: () => void }): void => {
          clickEvent.stopPropagation();
        }}
        onToggle={(isShow): void => {
          setShowDropdown(isShow);
        }}
      >
        <Dropdown.Toggle className={cx('dropdown-button')}>
          {loading && (
            <span>
              <i className={cx('fad fa-spinner-third fa-spin')} />
            </span>
          )}
          {!loading && <img src='/assets/icons/settings.svg' alt='Settings' />}
        </Dropdown.Toggle>
        <Dropdown.Menu
          ref={dropdownRef}
          popperConfig={{
            modifiers: [
              {
                name: 'flip',
                options: {
                  fallbackPlacements: ['top', 'left']
                }
              }
            ]
          }}
          className='m-0'
        >
          {linkedEvent && <div className='type-title'>Produced</div>}

          {!event.private && endpoint && (
            <button
              type='button'
              className='button-entry'
              onClick={(): void => {
                window.open(
                  Account.WebPlayer.makeURL({
                    uri: environment.VENUE_URI,
                    slug: endpoint,
                    event,
                    password: event.password
                  })
                );
              }}
            >
              <div className='dd-icon launch' />
              <div className='text'>View Event</div>
            </button>
          )}
          {!event.private && (
            <>
              <StyledToggle
                className='tests'
                onClick={(): void => {
                  setShowModal(true);
                }}
              >
                <div className='dd-icon share' />
                <div className='text'>Share Link</div>
              </StyledToggle>
              <EventComponent.ShareLinkModal
                showModal={showModal}
                onHide={(): void => {
                  setShowModal(false);
                }}
                password={event.password}
                event={event}
              />
            </>
          )}

          {isLive && Utils.isExtendable(event) && hasPermissionsOnEvent && (
            <button
              type='button'
              className='button-entry custom-danger'
              onClick={(): void => {
                onExtend(event);
              }}
            >
              <div className='dd-icon clock' />
              <div className='text'>Edit End Time</div>
            </button>
          )}
          <>
            <StyledToggle
              onClick={(): void => {
                setShowEmbedModal(true);
              }}
            >
              <div className='dd-icon launch' />
              <div className='text'>Embed Code</div>
            </StyledToggle>
            <EmbeddedEventModal
              show={showEmbedModal}
              onClose={(): void => {
                setShowEmbedModal(false);
              }}
              password={event.password}
              event={event}
            />
          </>
          {isUpcoming && event.clientContactDetails?.name && (
            <button
              type='button'
              onClick={handleResendClient}
              className={cx('btn button-entry', emailSent && 'sent')}
            >
              <div className='dd-icon clock' />
              <div className='text'>Resend Client Notification</div>
              {emailSent && (
                <span className='text-success ml-16px font-weight-bold text-center font-size-12px'>
                  <i className='mr-8px fa fa-check' />
                  Sent
                </span>
              )}
            </button>
          )}
          {isPast && event.clientContactDetails?.name && (
            <Tooltip
              backgroundColor='#0C3192'
              tip={<div>Post-Event email can be sent 25 hours after the event ends</div>}
            >
              <button
                type='button'
                onClick={handleResendClient}
                className={cx('button-entry', emailSent && 'sent')}
              >
                <div className='dd-icon clock' />
                <div className='text'>Resend Client Notification</div>
                {emailSent && (
                  <span className='text-success ml-16px font-weight-bold text-center font-size-12px'>
                    <i className='mr-8px fa fa-check' />
                    Sent
                  </span>
                )}
              </button>
            </Tooltip>
          )}

          {showRequestBtn && (
            <a
              className='button-entry'
              href='https://form.jotform.com/232046427365152'
              target='_blank'
              rel='noopener noreferrer'
            >
              <div className='dd-icon film' />
              <div className='text'>Request Edited Content</div>
            </a>
          )}

          {(isUpcoming || isLive) &&
            Utils.isEditable(event, isAdmin, environment.production) &&
            hasPermissionsOnEvent && (
              <button type='button' className='button-entry' onClick={goEditEvent}>
                <div className='dd-icon edit' />
                <div className='text'>Edit</div>
              </button>
            )}

          {isPast && event.assets && hasPermissionsOnEvent && (
            <button type='button' className='button-entry' onClick={goEditArchive}>
              <div className='dd-icon edit' />
              <div className='text'>Edit</div>
            </button>
          )}

          {isUpcoming && hasPermissionsOnEvent && (
            <button
              type='button'
              className='button-entry custom-danger'
              onClick={(): void => {
                handleDelete(event);
              }}
            >
              <div className='dd-icon trash' />
              <div className='text'>Delete Event</div>
            </button>
          )}

          {isLive && Utils.isStoppable(event) && hasPermissionsOnEvent && (
            <button
              type='button'
              className='button-entry custom-danger'
              onClick={(): void => {
                onStop(event);
              }}
            >
              <div className='dd-icon video-off' />
              <div className='text'>Stop Event</div>
            </button>
          )}

          {linkedEvent && (
            <>
              <div className='type-title'>Mobile Kit</div>

              {!linkedEvent.private && endpoint && (
                <button
                  type='button'
                  className='button-entry'
                  onClick={(): void => {
                    window.open(
                      Account.WebPlayer.makeURL({
                        uri: environment.VENUE_URI,
                        slug: endpoint,
                        event: linkedEvent,
                        password: linkedEvent.password
                      })
                    );
                  }}
                >
                  <div className='dd-icon launch' />
                  <div className='text'>View Event</div>
                </button>
              )}

              {!linkedEvent.private && (
                <>
                  <StyledToggle
                    className='tests'
                    onClick={(): void => {
                      setShowModalMobile(true);
                    }}
                  >
                    <div className='dd-icon share' />
                    <div className='text'>Share Link</div>
                  </StyledToggle>
                  <EventComponent.ShareLinkModal
                    showModal={showModalMobile}
                    onHide={(): void => {
                      setShowModalMobile(false);
                    }}
                    password={linkedEvent.password}
                    event={linkedEvent}
                  />
                </>
              )}

              <>
                <StyledToggle
                  onClick={(): void => {
                    setShowEmbedModalMobile(true);
                  }}
                >
                  <div className='dd-icon launch' />
                  <div className='text'>Embed Code</div>
                </StyledToggle>
                <EmbeddedEventModal
                  show={showEmbedModalMobile}
                  onClose={(): void => {
                    setShowEmbedModalMobile(false);
                  }}
                  password={linkedEvent.password}
                  event={linkedEvent}
                />
              </>

              {isUpcoming && linkedEvent.clientContactDetails?.name && (
                <button
                  type='button'
                  onClick={handleResendClientMobile}
                  className={cx('btn button-entry', emailSentMobile && 'sent')}
                >
                  <div className='dd-icon clock' />
                  <div className='text'>Resend Client Notification</div>
                  {emailSentMobile && (
                    <span className='text-success ml-16px font-weight-bold text-center font-size-12px'>
                      <i className='mr-8px fa fa-check' />
                      Sent
                    </span>
                  )}
                </button>
              )}

              {isPast && linkedEvent.clientContactDetails?.name && (
                <Tooltip
                  backgroundColor='#0C3192'
                  tip={<div>Post-Event email can be sent 25 hours after the event ends</div>}
                >
                  <button
                    type='button'
                    onClick={handleResendClientMobile}
                    className={cx('button-entry', emailSentMobile && 'sent')}
                  >
                    <div className='dd-icon clock' />
                    <div className='text'>Resend Client Notification</div>
                    {emailSentMobile && (
                      <span className='text-success ml-16px font-weight-bold text-center font-size-12px'>
                        <i className='mr-8px fa fa-check' />
                        Sent
                      </span>
                    )}
                  </button>
                </Tooltip>
              )}

              {showRequestBtn && (
                <a
                  className='button-entry'
                  href='https://form.jotform.com/232046427365152'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <div className='dd-icon film' />
                  <div className='text'>Request Edited Content</div>
                </a>
              )}

              {(isUpcoming || isLive) &&
                Utils.isEditable(linkedEvent, isAdmin, environment.production) &&
                hasPermissionsOnEvent && (
                  <button type='button' className='button-entry' onClick={goEditEventMobile}>
                    <div className='dd-icon edit' />
                    <div className='text'>Edit</div>
                  </button>
                )}

              {isPast && linkedEvent.assets && hasPermissionsOnEvent && (
                <button type='button' className='button-entry' onClick={goEditArchiveMobile}>
                  <div className='dd-icon edit' />
                  <div className='text'>Edit</div>
                </button>
              )}

              {isUpcoming && hasPermissionsOnEvent && (
                <button
                  type='button'
                  className='button-entry custom-danger'
                  onClick={(): void => {
                    handleDelete(linkedEvent);
                  }}
                >
                  <div className='dd-icon trash' />
                  <div className='text'>Delete Event</div>
                </button>
              )}

              {isLive && Utils.isStoppable(linkedEvent) && hasPermissionsOnEvent && (
                <button
                  type='button'
                  className='button-entry custom-danger'
                  onClick={(): void => {
                    onStop(linkedEvent);
                  }}
                >
                  <div className='dd-icon video-off' />
                  <div className='text'>Stop Event</div>
                </button>
              )}
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </MoreOptionsBtn>
  );
};
