import { Modal } from '@livecontrol/scheduler/components';
import type { ReactElement } from 'react';
import styled from 'styled-components';

const Icon = styled.img`
  height: 40px;
  width: 40px;
  display: inline-grid;
  place-items: center;
  margin-bottom: 14px;
`;

const Container = styled.div`
  color: #9c9c9c;
`;

interface Props {
  onClose: () => void;
  show: boolean;
}

export const SuccessModal = ({ onClose, show }: Props): ReactElement => (
  <Modal show={show} onHide={onClose}>
    <Modal.Header closeButton>
      <Icon src='/assets/icons/favourite-email.svg' alt='feedback-icon' />
      <Modal.Title className='font-size-20px font-weigth-bold'>Feedback submitted!</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Container className='mt-8 font-weight-bold text-center'>
        Thank you! Our team closely reviews each submission. Your feedback is valuable to us!
      </Container>
    </Modal.Body>
    <Modal.Footer>
      <Modal.Button className='btn btn-primary' onClick={onClose}>
        Close
      </Modal.Button>
    </Modal.Footer>
  </Modal>
);
