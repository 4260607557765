import { Device, Loading, Theme, Tooltip } from '@livecontrol/core-ui';
import { Matrix } from '@livecontrol/scheduler/components';
import type { Account, Location } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import cx from 'classnames';
import { FormikProvider } from 'formik';
import type { Location as UrlLocation } from 'history';
import { useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Form } from '../../../../components';
import { FormWarning } from '../form-warning';
import { AccountGlobal } from './account-global';
import { useForm } from './form';
import { PermissionsDropdown } from './permissions-dropdown';
import { RowLocation } from './row';
import { RowUser } from './row-user';

import G = Matrix.Grid;
import H = G.Header;

const CellNoBorder = styled(Matrix.Grid.Row.Cell)`
  background-color: white !important;
  border: 0px solid transparent;
  justify-content: center;
  padding: 14px 0px;

  &.transparent {
    background-color: transparent !important;
  }

  &.boder-bottom {
    border-bottom: 1px solid #e4e8f0 !important;
  }

  &:last-child {
    padding: 5px 0px !important;
  }

  &:first-child {
    border-left: 0px solid transparent !important;
    /* position: sticky; */
    left: 0;
  }
`;

const Grid = styled(Matrix.Grid)`
  grid-row-gap: 0 !important;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 500px;
 

  .border-top {
    border-top: 1px solid #e4e8f0;
  }

  .badge {
    font-size: 12px;
  }

  .bg-admin {
    background-color: #EF9563;
  }

  .bg-purplue {
    background-color: #7b61ff;
  }

  .bg-green {
    background-color: #37b34a;
  }

  .bg-blue {
    background-color: #00c1c1;
  }

  .column:nth-child(2n + 0) {
    background-color: #f0f6ff;
  }
  .column:nth-child(2n + 1) {
    background-color: white;
  }

  .height-10px {
    height: 10px !important;
    padding: 0px !important;
  }

  .th {
    margin: 0 !important;
    padding: 20px 18px;
    height: 140px;
    font-weight: 600;
    font-size: var(--font-size-16px);
    color: var(--dark);
    z-index: 1000:
  }

  .form-group {
    flex: 0 0 0 !important;
  }

  .form-control {
    padding: 0px;
  }

  input[type='checkbox'] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 5px;
    width: 16px;
    height: 16px;
    border: 1px solid #0d1438;
    outline: none;
  }

  input[type='checkbox']:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
  }

  input:checked[type='checkbox'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }

  .invalid-feedback{
    display: none
  }
`;

const Content = styled.div`
  .btn {
    padding: 0px !important;
    width: 34px !important;
    height: 34px !important;
    z-index: 1;
  }

  .arrowRotated {
    transform: rotate(180deg);
  }

  .right {
    right: 20px;
  }

  img {
    width: 12px;
  }
`;

interface Props {
  account: Account;
}

export interface AccountUsersPermission {
  userId: string;
  columnPermission: number;
}

export const AccountPermissions = ({ account }: Props): ReactElement => {
  const { locations } = Scheduler.Location.useLocations(account);
  const { formik, loading, loadingAccount } = useForm({ account, locations });
  const viewport = Theme.useViewport();

  const [isScrollerLeft, setIsScrollerLeft] = useState(false);
  const [value, setValue] = useState<Account.SubUser | Location | undefined>();
  const [isScrollerRight, setIsScrollerRight] = useState(false);
  const { loading: loadingBilling } = Scheduler.Account.useBilling(account);
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<UrlLocation | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const history = useHistory();

  const [accountUserPermissionSelected, setAccountUserPermissionSelected] = useState<
    AccountUsersPermission[]
  >([]);

  useEffect(() => {
    if (formik.values.locationUser === 'location' && locations) {
      setValue(locations[0]);
    }

    if (formik.values.locationUser === 'user') {
      setValue(account.subUsers[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.locationUser, locations]);

  useEffect(() => {
    account.subUsers.forEach((subuser) => {
      if (subuser.sub_user_locations?.length !== 0) {
        locations?.forEach((location) => {
          const permissions = subuser.sub_user_locations?.find(
            (locationPermission) => location.id === locationPermission.location.id
          );

          formik.setFieldValue(
            `${location.id}._${subuser.id}.eventManagment`,
            permissions?.eventManagment
          );

          formik.setFieldValue(
            `${location.id}._${subuser.id}.viewAndDownload`,
            permissions?.viewAndDownload
          );

          formik.setFieldValue(
            `${location.id}._${subuser.id}.contentManagment`,
            permissions?.contentManagment
          );

          formik.setFieldValue(
            `${location.id}._${subuser.id}.activityLog`,
            permissions?.activityLog
          );

          formik.setFieldValue(
            `${location.id}._${subuser.id}.productionNotes`,
            permissions?.productionNotes
          );

          formik.setFieldValue(`${location.id}._${subuser.id}.id`, Number(permissions?.id));
        });
      }

      formik.setFieldValue(`account._${subuser.id}.destinations`, subuser.permissions.destinations);
      formik.setFieldValue(`account._${subuser.id}.webplayer`, subuser.permissions.webplayer);
      formik.setFieldValue(`account._${subuser.id}.billing`, subuser.permissions.billing);
      formik.setFieldValue(`account._${subuser.id}.organization`, subuser.permissions.accountInfo);
      formik.setFieldValue(`account._${subuser.id}.admin`, subuser.permissions.admin);
      formik.setFieldValue(`account._${subuser.id}.id`, subuser.permissions.id);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  const onMoveScroll = (spacer: number): void => {
    const gridScroll = document.querySelector('#gridScroll');

    if (gridScroll) {
      gridScroll.scrollLeft += spacer;

      setIsScrollerLeft(gridScroll.scrollLeft > 0);
      setIsScrollerRight(gridScroll.clientWidth + gridScroll.scrollLeft > gridScroll.scrollWidth);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const userSelectedFromUrl = searchParams.get('user_selected');

    if (userSelectedFromUrl !== null && !Number.isNaN(userSelectedFromUrl)) {
      formik.values.locationUser = 'user';

      const subUserSelected = account.subUsers.find(
        (subuser) => subuser.id === Number(userSelectedFromUrl)
      );

      if (subUserSelected) {
        setValue(subUserSelected);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, locations]);

  const [initialValuesLoaded, setInitialValuesLoaded] = useState<boolean>(false);
  const [formikIsDirty, setFormikIsDirty] = useState<boolean>(false);

  useEffect(() => {
    if (!loading && !loadingAccount && !loadingBilling && !initialValuesLoaded) {
      setInitialValuesLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, loadingAccount, loadingBilling]);

  useEffect(() => {
    const isFormTouched = Object.keys(formik.touched)
      .filter((key) => key !== 'locationUser')
      .some((key) => formik.touched[key]);

    if (initialValuesLoaded && isFormTouched && !formikIsDirty) {
      setFormikIsDirty(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValuesLoaded, formik.touched]);

  const handleBlockedNavigation = (nextLocation: UrlLocation): boolean => {
    if (!confirmedNavigation) {
      setModalVisible(true);
      setLastLocation(nextLocation);

      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = (): void => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, history, lastLocation]);

  const handleSubmit = async (): Promise<void> => {
    await formik.submitForm();

    formik.setTouched({});

    setFormikIsDirty(false);
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  return (
    <div>
      {loadingBilling && initialValuesLoaded ? (
        <Loading.Delay />
      ) : (
        <FormikProvider value={formik}>
          <Form>
            <div className='d-flex justify-content-between flex-wrap mb-12px'>
              <div
                className={cx(
                  Device.isMobile(viewport) ? 'flex-column mb-16px' : 'flex-row',
                  'd-flex'
                )}
              >
                <div>
                  <h1>Permissions</h1>
                  <div className='d-flex flex-row '>
                    <div className='mt-8px font-size-12px font-weight-bold'> View By: </div>
                    <Form.Group
                      name='locationUser'
                      label={
                        <div className='font-size-12px font-weight-bold' style={{ marginTop: 6 }}>
                          {' '}
                          User{' '}
                        </div>
                      }
                      error={false}
                      className='d-flex flex-row-reverse align-items-start ml-8px'
                      controlProps={{
                        type: 'radio',
                        custom: true,
                        value: 'user'
                      }}
                      control={Form.Check}
                    />
                    <Form.Group
                      name='locationUser'
                      label={
                        <div
                          className='font-size-12px font-weight-bold mb-0'
                          style={{ marginTop: 6 }}
                        >
                          {' '}
                          Location{' '}
                        </div>
                      }
                      error={false}
                      className='d-flex flex-row-reverse align-items-start ml-8px'
                      controlProps={{
                        type: 'radio',
                        custom: true,
                        value: 'location'
                      }}
                      control={Form.Check}
                    />
                  </div>
                </div>
                <div className={cx(Device.isMobile(viewport) ? '' : 'ml-64px mt-8px')}>
                  <PermissionsDropdown
                    isLocation={formik.values.locationUser}
                    valueSelected={value}
                    setValue={setValue}
                    locations={locations}
                    subusers={account.subUsers}
                  />
                </div>
              </div>
              <button
                type='button'
                className='btn btn-primary ml-12px mt-8px'
                style={{ height: '42px' }}
                onClick={handleSubmit}
                disabled={loading || loadingAccount}
              >
                {(loading || loadingAccount) && (
                  <span>
                    <i className='fad fa-spinner-third fa-spin mr-8px' />
                  </span>
                )}
                Save Changes
              </button>
            </div>

            <Content className='position-relative'>
              <div
                className='position-absolute bg-white'
                style={{
                  width: Device.isMobile(viewport) ? '125px' : '190px',
                  height: 140,
                  zIndex: 1
                }}
              />
              <button
                disabled={!isScrollerLeft}
                className='btn btn-primary rounded-circle position-absolute previus'
                onClick={(): void => {
                  onMoveScroll(-120);
                }}
                type='button'
              >
                <img className='arrowRotated' src='assets/icons/arrow_left_white.svg' alt='arrow' />
              </button>
              <button
                disabled={isScrollerRight}
                className='btn btn-primary right rounded-circle position-absolute'
                onClick={(): void => {
                  onMoveScroll(120);
                }}
                type='button'
              >
                <img src='assets/icons/arrow_left_white.svg' alt='arrow' />
              </button>
            </Content>
            <Grid
              id='gridScroll'
              viewport={viewport}
              gridTemplateColumns={`${
                Device.isMobile(viewport) ? '125px' : '190px'
              } repeat(10, 196px)`}
              className='position-relative'
            >
              {/* Header */}
              <G.Row className='position-sticky d-flex' style={{ top: 0 }}>
                <H
                  className='bg-white column font-weight-bold '
                  viewport={viewport}
                  style={{ left: 0, width: Device.isMobile(viewport) ? '125px' : '190px' }}
                >
                  <span
                    className='badge rounded-pill bg-primary text-white mb-8px'
                    style={{ opacity: 0 }}
                  >
                    Event Schedule
                  </span>

                  <div style={{ width: Device.isMobile(viewport) ? '125px' : '190px', opacity: 0 }}>
                    Event Schedule
                  </div>
                </H>
                <H className='column text-center font-weight-bold ' viewport={viewport}>
                  <span className='badge rounded-pill bg-admin text-white mb-8px'>
                    Administrator
                  </span>
                  <div style={{ width: 160 }}>
                    Admin Access
                    <Tooltip
                      backgroundColor='#0C3192'
                      tip={
                        <div>
                          <div className='font-weight-bold font-size-11px'>
                            Grant all permissions including user management access.
                          </div>
                          <div className='font-weight-bold font-size-11px d-flex d-row mt-16px'>
                            Choose View by: User to change.
                          </div>
                        </div>
                      }
                    >
                      <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
                    </Tooltip>
                  </div>
                </H>
                <H
                  className='column header text-center text-dark font-weight-bold '
                  viewport={viewport}
                >
                  <span className='badge rounded-pill bg-primary text-white mb-8px'>
                    Event Schedule
                  </span>
                  <div style={{ width: 160 }}>
                    Event Schedule
                    <Tooltip
                      backgroundColor='#0C3192'
                      tip={
                        <div>
                          <div className='font-weight-bold font-size-11px'>
                            Access to create, edit and delete events. All users can view the event
                            schedule.
                          </div>
                          <div className='font-weight-bold font-size-11px mt-16px'>
                            *Can Assign Per Location
                          </div>
                        </div>
                      }
                    >
                      <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
                    </Tooltip>
                  </div>
                </H>
                <H className='column text-center font-weight-bold ' viewport={viewport}>
                  <span className='badge rounded-pill bg-purplue text-white mb-8px'>
                    Stream Settings
                  </span>
                  <div style={{ width: 160 }}>
                    Destinations
                    <Tooltip
                      backgroundColor='#0C3192'
                      tip={
                        <div>
                          <div className='font-weight-bold font-size-11px'>
                            Access to add and manage simulcasting destinations.
                          </div>
                          <div className='font-weight-bold font-size-11px mt-16px d-flex d-row'>
                            Choose View by: User to change.
                          </div>
                        </div>
                      }
                    >
                      <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
                    </Tooltip>
                  </div>
                </H>
                <H className='column text-center font-weight-bold ' viewport={viewport}>
                  <span className='badge rounded-pill bg-purplue text-white mb-8px'>
                    Stream Settings
                  </span>
                  <div style={{ width: 160 }}>
                    Customize Web Player
                    <Tooltip
                      backgroundColor='#0C3192'
                      tip={
                        <div>
                          <div className='font-weight-bold font-size-11px'>
                            Access to customize the Web Player.
                          </div>
                          <div className='font-weight-bold font-size-11px d-flex d-row mt-16px'>
                            Choose View by: User to change.
                          </div>
                        </div>
                      }
                    >
                      <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
                    </Tooltip>
                  </div>
                </H>
                <H className='column text-center font-weight-bold ' viewport={viewport}>
                  <span className='badge rounded-pill bg-purplue text-white mb-8px'>
                    Stream Settings
                  </span>
                  <div style={{ width: 160 }}>
                    Customize Production Notes
                    <Tooltip
                      backgroundColor='#0C3192'
                      tip={
                        <div>
                          <div className='font-weight-bold font-size-11px'>
                            Access to update account Production Notes.
                          </div>
                          <div className='font-weight-bold font-size-11px d-flex d-row mt-16px'>
                            Choose View by: User to change.
                          </div>
                        </div>
                      }
                    >
                      <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
                    </Tooltip>
                  </div>
                </H>
                <H className='column text-center font-weight-bold ' viewport={viewport}>
                  <span className='badge rounded-pill bg-green text-white mb-8px'>
                    {' '}
                    Subscription{' '}
                  </span>
                  <div style={{ width: 160 }}>
                    Billing
                    <Tooltip
                      backgroundColor='#0C3192'
                      tip={
                        <div>
                          <div className='font-weight-bold font-size-11px'>
                            Access to view invoices, change payment methods, and purchase credits.
                          </div>
                          <div className='font-weight-bold font-size-11px d-flex d-row mt-16px'>
                            Choose View by: User to change.
                          </div>
                        </div>
                      }
                    >
                      <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
                    </Tooltip>
                  </div>
                </H>
                <H className='column text-center font-weight-bold ' viewport={viewport}>
                  <span className='badge rounded-pill bg-accent text-white mb-8px'>
                    Content Library
                  </span>
                  <div style={{ width: 160 }}>
                    View & Download Analytics
                    <Tooltip
                      backgroundColor='#0C3192'
                      tip={
                        <div>
                          <div className='font-weight-bold font-size-11px'>
                            Access to view and download past videos in the archive. Access to stream
                            analytics.
                          </div>
                          <div className='font-weight-bold font-size-11px mt-16px'>
                            *Can Assign Per Location
                          </div>
                        </div>
                      }
                    >
                      <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
                    </Tooltip>
                  </div>
                </H>
                <H className='column text-center font-weight-bold ' viewport={viewport}>
                  <span className='badge rounded-pill bg-accent text-white mb-8px'>
                    Content Library
                  </span>
                  <div style={{ width: 160 }}>
                    Content Management & Viewer Info
                    <Tooltip
                      backgroundColor='#0C3192'
                      tip={
                        <div>
                          <div className='font-weight-bold font-size-11px'>
                            Access to edit and delete past videos in the archive. Access to download
                            viewer contact info.
                          </div>
                          <div className='font-weight-bold font-size-11px mt-16px'>
                            *Can Assign Per Location
                          </div>
                        </div>
                      }
                    >
                      <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
                    </Tooltip>
                  </div>
                </H>
                <H className='column text-center font-weight-bold ' viewport={viewport}>
                  <span className='badge rounded-pill bg-blue text-white mb-8px'>
                    {' '}
                    Account Info{' '}
                  </span>
                  <div style={{ width: 160 }}>
                    Organization
                    <Tooltip
                      backgroundColor='#0C3192'
                      tip={
                        <div>
                          <div className='font-weight-bold font-size-11px'>
                            Access to edit the organization’s account information.
                          </div>
                          <div className='font-weight-bold font-size-11px d-flex d-row mt-16px'>
                            Choose View by: User to change.
                          </div>
                        </div>
                      }
                    >
                      <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
                    </Tooltip>
                  </div>
                </H>
                <H className='column text-center font-weight-bold ' viewport={viewport}>
                  <span className='badge rounded-pill bg-blue text-white mb-8px'>
                    {' '}
                    Account Info{' '}
                  </span>
                  <div style={{ width: 160 }}>
                    Activity Log
                    <Tooltip
                      backgroundColor='#0C3192'
                      tip={
                        <div>
                          <div className='font-weight-bold font-size-11px'>
                            Access to Activity Log.
                          </div>
                          <div className='font-weight-bold font-size-11px mt-16px'>
                            *Can Assign Per Location
                          </div>
                        </div>
                      }
                    >
                      <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
                    </Tooltip>
                  </div>
                </H>
              </G.Row>

              <G.Row>
                <CellNoBorder className='column height-10px' />
                <CellNoBorder className='column height-10px' />
                <CellNoBorder className='column height-10px' />
                <CellNoBorder className='column height-10px' />
                <CellNoBorder className='column height-10px' />
                <CellNoBorder className='column height-10px' />
                <CellNoBorder className='column height-10px' />
                <CellNoBorder className='column height-10px' />
                <CellNoBorder className='column height-10px' />
                <CellNoBorder className='column height-10px' />
              </G.Row>

              {formik.values.locationUser === 'location' &&
                locations?.map((location) => (
                  <RowLocation
                    valueSelected={value}
                    accountUserPermissionSelected={accountUserPermissionSelected}
                    formik={formik}
                    location={location}
                    key={location.id}
                    account={account}
                  />
                ))}

              {locations && (
                <AccountGlobal
                  valueSelected={value && 'firstName' in value ? value : undefined}
                  setAccountUserPermissionSelected={setAccountUserPermissionSelected}
                  formik={formik}
                  account={account}
                  locations={locations}
                  visibility={formik.values.locationUser === 'user'}
                />
              )}

              {formik.values.locationUser === 'user' &&
                locations &&
                account.subUsers.map((user) => (
                  <RowUser
                    key={user.id}
                    user={user}
                    locations={locations}
                    formik={formik}
                    valueSelected={value}
                    accountUserPermissionSelected={accountUserPermissionSelected}
                  />
                ))}
            </Grid>
            <Prompt when={formikIsDirty} message={handleBlockedNavigation} />
            <FormWarning
              show={modalVisible}
              loading={loading || loadingAccount}
              onClose={(): void => {
                setModalVisible(false);
              }}
              onRevert={handleConfirmNavigationClick}
              onSave={handleSubmit}
            />
          </Form>
        </FormikProvider>
      )}
    </div>
  );
};
