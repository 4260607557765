import { Num } from '@livecontrol/core-utils';
import cx from 'classnames';
import React from 'react';

const DEFAULT_SCALE = 1;

export const FbLiveIcon = (props: FbLiveIcon.Props): React.ReactElement => {
  const scale = Num.normalize(props.scale, { positive: true }) ?? DEFAULT_SCALE;

  return (
    <svg
      className={cx('svg-inline--fa', props.className)}
      width={`${scale * 35}px`}
      height={`${scale * 14}px`}
      viewBox='0 0 350.000000 140.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <title>FBLive_Hex-RGB-BRC-Site</title>
      <g>
        <path
          id='Blue_3_'
          style={{ fill: '#ED4242' }}
          d='M363.1,129.2c3.9,0,7.1-3.2,7.1-7.1V7.1c0-3.9-3.2-7.1-7.1-7.1H146.2c-3.9,0-7.1,3.2-7.1,7.1v115
    c0,3.9,3.2,7.1,7.1,7.1H363.1z'
        />
        <path
          style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FFFFFF' }}
          d='M334.1,83.5h-25.6V68.2h24.2v-8h-24.2V45.7h25.6v-8.5h-35.4V92h35.4V83.5z M270.3,92l18.9-54.7h-10.4
    l-13.7,43.3h-0.6l-13.8-43.3h-10.8L259,92H270.3z M230.5,92V37.3h-9.8V92H230.5z M210.1,83.4H185V37.3h-9.8V92h34.9V83.4z'
        />
        <g>
          <path
            style={{ fill: '#1877F2' }}
            d='M129.3,64.7c0-35.7-28.9-64.6-64.6-64.6S0,29,0,64.7c0,32.3,23.7,59,54.6,63.9V83.4H38.1V64.7h16.5l0-14.2
      c0-16.2,9.6-25.2,24.4-25.2c7.1,0,14.4,1.4,14.4,1.4v15.8h-8.1c-8,0-10.6,5-10.6,10.1l0,12.1h18l-2.9,18.7H74.7v45.2
      C105.6,123.8,129.3,97,129.3,64.7z'
          />
          <path
            style={{ fill: '#FFFFFF' }}
            d='M89.8,83.4l2.9-18.7h-18l0-12.1c0-5.1,2.6-10.1,10.6-10.1h8.1V26.7c0,0-7.3-1.4-14.4-1.4
      c-14.8,0-24.4,9-24.4,25.2l0,14.2H38.1v18.7h16.5v45.2c3.3,0.5,6.6,0.8,10.1,0.8c3.4,0,6.8-0.3,10.1-0.8V83.4H89.8z'
          />
        </g>
      </g>
    </svg>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace FbLiveIcon {
  export interface Props {
    className?: string;
    scale?: number | string;
    color?: string;
  }
}
