/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react-hooks/exhaustive-deps */
import { Device, Form, Loading, Theme } from '@livecontrol/core-ui';
import type { Account } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { FormikProvider } from 'formik';
import type { Location as UrlLocation } from 'history';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { TablePermissions } from '../components';
import { FormWarning } from '../form-warning';
import { useForm } from './form';
import { GlobalSettings } from './global-settings';

interface Props {
  account: Account;
  usersAvailable: Account.SubUser[];
  isWaitingForUser?: boolean;
}

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 73px;

  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;

export const PermissionsUser = ({ account, usersAvailable, isWaitingForUser = false }: Props): ReactElement => {
  const viewport = Theme.useViewport();

  const [userSelected, setUserSelected] = useState<Account.SubUser | undefined>(usersAvailable[0]);

  const [initialValuesLoaded, setInitialValuesLoaded] = useState<boolean>(false);
  const [formikIsDirty, setFormikIsDirty] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<UrlLocation | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const { loading: loadingLocation, locations } = Scheduler.Location.useLocations(account);
  const history = useHistory();
  const { loading: loadingBilling } = Scheduler.Account.useBilling(account);

  const [saved, setSaved] = useState(false);

  const handleSubmitSuccess = (): void => {
    setSaved(true);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (saved) {
      setSaved(true);

      timeout = setTimeout(() => {
        setSaved(false);
      }, 3000);
    }

    return (): void => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [saved]);

  const { formik, loading, loadingAccount } = useForm({
    account,
    locations,
    permisionByUser: true,
    handleSubmitSuccess
  });

  const setFormik = (admin?: boolean): void => {
    locations?.forEach((location) => {
      const permissions = userSelected?.sub_user_locations?.find(
        (locationPermission) => location.id === locationPermission.location.id
      );

      formik.setFieldValue(
        `${location.id}.eventManagment`,
        admin ?? (permissions ? permissions.eventManagment : false)
      );

      formik.setFieldValue(
        `${location.id}.viewAndDownload`,
        admin ?? (permissions ? permissions.viewAndDownload : false)
      );

      formik.setFieldValue(
        `${location.id}.contentManagment`,
        admin ?? (permissions ? permissions.contentManagment : false)
      );

      formik.setFieldValue(
        `${location.id}.productionNotes`,
        admin ?? (permissions ? permissions.productionNotes : false)
      );

      if (permissions?.id) {
        formik.setFieldValue(`${location.id}.id`, Number(permissions.id));
      }
    });

    formik.setFieldValue(
      'account.webplayer',
      admin ?? userSelected?.permissions.destinations ?? userSelected?.permissions.webplayer
    );

    formik.setFieldValue(
      'account.billing',
      admin ?? userSelected?.permissions.billing ?? userSelected?.permissions.activityLog
    );

    formik.setFieldValue(
      'account.admin',
      admin ?? userSelected?.permissions.admin ?? userSelected?.permissions.organizationAccountInfo
    );

    formik.setFieldValue('account.id', userSelected?.permissions.id);
  };

  useEffect(() => {
    if(!isWaitingForUser){
      const searchParams = new URLSearchParams(location.search);
      const userSelectedFromUrl = searchParams.get('user_selected');

      if (
        userSelectedFromUrl !== null &&
        !Number.isNaN(userSelectedFromUrl) &&
        Number(userSelectedFromUrl) !== userSelected?.id
      ) {
        const subUserSelected = usersAvailable.find(
          (subuser) => subuser.id === Number(userSelectedFromUrl)
        );

        if (subUserSelected) {
          setUserSelected(subUserSelected);
        }
      }
    }
  }, [account, locations, isWaitingForUser]);

  useEffect(() => {
    const { values } = formik;
    const valuesPermissions = values['account' as keyof typeof values];

    if (valuesPermissions && valuesPermissions['admin' as keyof typeof valuesPermissions]) {
      setFormik(true);
    }
  }, [formik.values.account.admin]);

  useEffect(() => {
    if (!loading && !loadingAccount && !loadingBilling && !initialValuesLoaded) {
      setInitialValuesLoaded(true);
    }
  }, [loading, loadingAccount, loadingBilling]);

  useEffect(() => {
    const isFormTouched = Object.keys(formik.touched)
      .filter((key) => key !== 'locationUser')
      .filter((key) => key !== 'id')
      .some((key) => formik.touched[key]);

    if (initialValuesLoaded && isFormTouched) {
      setFormikIsDirty(true);
      setConfirmedNavigation(false);
    } else {
      setFormikIsDirty(false);
    }
  }, [initialValuesLoaded, formik.touched]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      const searchParams = new URLSearchParams(lastLocation.search);
      const userSelectedFromUrl = searchParams.get('user_selected');

      if (userSelectedFromUrl !== null && !Number.isNaN(userSelectedFromUrl)) {
        const subUserSelected = usersAvailable.find(
          (subuser) => subuser.id === Number(userSelectedFromUrl)
        );

        if (subUserSelected) {
          formik.setFieldValue('id', userSelectedFromUrl);
          setUserSelected(subUserSelected);
          setFormik();
        }
      }

      history.push(lastLocation.pathname + lastLocation.search);

      setFormikIsDirty(false);
      setModalVisible(false);
      setConfirmedNavigation(true);
      formik.setTouched({});
    }
  }, [confirmedNavigation, history, lastLocation]);

  useEffect(() => {
    if (userSelected === undefined) {
      setUserSelected(usersAvailable[0]);

      return;
    }

    history.push(`/account/users/permissions-user?user_selected=${userSelected.id}`);

    if (!formikIsDirty) {
      formik.setFieldValue('id', userSelected?.id);
      setFormik();
    }
  }, [userSelected, locations]);

  const handleBlockedNavigation = (nextLocation: UrlLocation): boolean => {
    if (!confirmedNavigation) {
      setModalVisible(true);
      setLastLocation(nextLocation);

      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = (): void => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  const handleSubmit = async (): Promise<void> => {
    await formik.submitForm();

    formik.setTouched({});

    setFormikIsDirty(false);
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  const handleCloseModal = (): void => {
    setModalVisible(false);
    setConfirmedNavigation(true);

    const searchParams = new URLSearchParams(location.search);
    const userSelectedFromUrl = searchParams.get('user_selected');

    if (userSelectedFromUrl !== null && !Number.isNaN(userSelectedFromUrl)) {
      const subUserSelected = usersAvailable.find(
        (subuser) => subuser.id === Number(userSelectedFromUrl)
      );

      if (subUserSelected) {
        setUserSelected(subUserSelected);
      }
    }
  };

  return (
    <div className='mt-56px'>
      {(loadingBilling || !initialValuesLoaded || loadingLocation || isWaitingForUser) ? (
        <Loading.Delay />
      ) : (
        <FormikProvider value={formik}>
          <Form>
            <Content>
              <GlobalSettings
                userSelected={userSelected}
                setUserSelected={setUserSelected}
                usersAvailable={usersAvailable}
              />

              <div className='position-relative'>
                <Form.Submit
                  type='submit'
                  className='btn btn-primary ml-12px mt-8px'
                  style={{ height: '42px', width: 'fit-content' }}
                  loading={loading || loadingAccount}
                >
                  Save Changes
                </Form.Submit>
                {saved && (
                  <span
                    className='text-success font-weight-bold text-center font-size-12px'
                    style={{ position: 'absolute', left: '50px', top: '54px' }}
                  >
                    <i className='mr-8px fa fa-check' />
                    Saved
                  </span>
                )}
              </div>
            </Content>

            <div>
              <div className='position-relative'>
                <div
                  className='position-absolute bg-white font-weight-bold'
                  style={{
                    width: Device.isMobile(viewport) ? '125px' : '300px',
                    height: 140,
                    zIndex: 1
                  }}
                >
                  <h1 className='font-size-28px'>Location Settings</h1>
                  <div className='font-size-12px mb-28px'>Settings applied per location</div>
                </div>
              </div>
              <TablePermissions locations={locations} formik={formik} />
            </div>
            <Prompt when={formikIsDirty} message={handleBlockedNavigation} />
            <FormWarning
              show={modalVisible}
              loading={loading || loadingAccount}
              onClose={(): void => {
                handleCloseModal();
              }}
              onRevert={handleConfirmNavigationClick}
              onSave={handleSubmit}
            />
          </Form>
        </FormikProvider>
      )}
    </div>
  );
};
