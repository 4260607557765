import React from 'react';
import type { ButtonProps } from 'react-bootstrap/Button';
import B_Button from 'react-bootstrap/Button';
import { Device } from '../device';
import { Theme } from '../theme';

const xform: Record<Button.Size, number> = {
  sm: 1,
  md: 10,
  lg: 100
};

/**
 * A responsive button.  This is a simply a wrapper around the Bootstrap button that
 * automatically resizes itself based on the current responsive breakpoint:
 *
 * CSS Breakpoint => Bootstrap Button Size (`sm` | `lg`)
 * - Device.isMobile => size="sm"
 * - Device.isTablet => size={undefined}    [Bootstrap default button size]
 * - Device.isDesktop => size='lg'
 */
export const Button = React.forwardRef(
  (
    { children, minimum, maximum, size, ...props }: Button.Props,
    ref: React.Ref<HTMLButtonElement>
  ): React.ReactElement => {
    const viewport = Theme.useViewport();

    let canonicalSize =
      !!size && size !== 'auto'
        ? size
        : Device.isMobile(viewport)
        ? 'sm'
        : Device.isTablet(viewport)
        ? 'md'
        : 'lg';

    // Use numeric equvalents to make sure size stays within the specified bounds
    if (minimum && xform[canonicalSize] < xform[minimum]) {
      canonicalSize = minimum;
    }

    if (maximum && xform[canonicalSize] > xform[maximum]) {
      canonicalSize = maximum;
    }

    // Bootstrap only has sizes 'sm' and 'lg'.
    const bsSize = canonicalSize === 'sm' ? 'sm' : canonicalSize === 'lg' ? 'lg' : undefined;

    return (
      <B_Button {...props} size={bsSize} ref={ref}>
        {children}
      </B_Button>
    );
  }
);

Button.displayName = 'ResponsiveButton';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Button {
  export type Size = 'lg' | 'md' | 'sm';

  export type Props = Omit<ButtonProps, 'size'> &
    React.ComponentPropsWithRef<'button'> & {
      size?: Size | 'auto';
      minimum?: Size;
      maximum?: Size;
    };
}
