import type { EventValidator } from '@livecontrol/scheduler/validator';
import type { FormikErrors } from 'formik';
import type { DateTime } from 'luxon';
import type { Moment } from 'moment';
import { CueSheets as CueSheets_ } from './cue-sheets';
import { CustomBackground as CustomBackground_ } from './custom-background';
import { Date as Date_ } from './date';
import type { DateProps as DateProps_ } from './date';
import { Description as Description_ } from './description';
import { EndTime as EndTime_ } from './end-time';
import type { EndTimeProps as EndTimeProps_ } from './end-time';
import { EventLocation as EventLocation_ } from './event-location';
import type { EventLocationProps as EventLocationProps_ } from './event-location';
import { OperatorNotes as OperatorNotes_ } from './operator-notes';
import { StartTime as StartTime_ } from './start-time';
import type { StartTimeProps as StartTimeProps_ } from './start-time';
import { Submit as Submit_ } from './submit';
import type { SubmitProps as SubmitProps_ } from './submit';
import { TechnicalContact as TechnicalContact_ } from './technical-contact';
import { Title as Title_ } from './title';
import { Wrapper as Wrapper_ } from './wrapper';
import { ZoomDetails as ZoomDetails_ } from './zoom-details';
import { ZoomSwitch as ZoomSwitch_ } from './zoom-switch';

export namespace EventDetailsForm {
  export const Wrapper = Wrapper_;

  export const EventLocation = EventLocation_;
  export type EventLocationProps = EventLocationProps_;

  export const Description = Description_;
  export const CustomBackground = CustomBackground_;

  export const OperatorNotes = OperatorNotes_;
  export const Title = Title_;
  export const ZoomSwitch = ZoomSwitch_;
  export const ZoomDetails = ZoomDetails_;

  export const CueSheets = CueSheets_;

  export const StartTime = StartTime_;
  export type StartTimeProps = StartTimeProps_;

  export const Submit = Submit_;
  export type SubmitProps = SubmitProps_;

  export const EndTime = EndTime_;
  export type EndTimeProps = EndTimeProps_;

  export const Date = Date_;
  export type DateProps = DateProps_;

  export const TechnicalContact = TechnicalContact_;

  export type Values = EventValidator.EventInfo & {
    date?: DateTime | undefined;
    startTime?: Moment | undefined;
    endTime?: Moment | undefined;
    leadTime?: boolean | undefined;
    timezone?: string;
    leadtimeAcknowledge?: boolean | undefined;
  };

  export type Errors = FormikErrors<
    EventValidator.EventInfo & {
      date?: DateTime | undefined;
      startTime?: Moment | undefined;
      endTime?: Moment | undefined;
      leadTime?: boolean | undefined;
      leadtimeAcknowledge?: boolean | undefined;
    }
  >;
}
