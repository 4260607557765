import React from 'react';
import { Form } from '../../../../components';

export const ZoomSwitch = (): React.ReactElement => (
  <div className='d-flex flex-row align-items-center'>
    <span className='text-secondary pr-20px'>Zoom Event</span>
    <Form.Group
      name='zoomDetails.streamZoom'
      label=''
      aria-label='Zoom Event'
      className='mb-0'
      controlProps={{
        type: 'checkbox'
      }}
      control={Form.Switch}
    />
  </div>
);
