/* eslint-disable react-hooks/rules-of-hooks */
import { Loading } from '@livecontrol/core-ui';
import { User } from '@livecontrol/scheduler/model';
import type { Location } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { ReactElement } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { NotFound, SectionContainer } from '../../components';
import { Store } from '../../store';
import { Analytics } from './analytics';
import { ActionsSection, DataSection } from './overview';
import { Player } from './player';

const HeaderSection = styled.div`
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(213, 218, 234, 0.8);

  .back-link {
    display: flex;
    align-items: center;
    color: #9c9c9c;

    .icon {
      width: 24px;
      height: 24px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .arrow-left {
      background-image: url(/assets/icons/Arrow_left.svg);
    }
    .text {
      margin-left: 12px;
      font-size: 14px;
    }
  }
`;

const mergeUniqueLocations = (arr1: Location[], arr2: Location[]): Location[] => {
  const map = new Map<string, Location>();

  [...arr1, ...arr2].forEach((location) => {
    map.set(location.id, location);
  });

  return [...map.values()];
};

export const ViewAsset = (): ReactElement => {
  const account = Store.Account.useAccount();
  const isAdmin = Store.User.useIsAdmin();
  const me = Store.User.useMe();

  if (!me.permissions.someLocationHasViewAndDownloadEnabled) {
    return <Redirect to='/schedule' />;
  }

  const { locations } = Scheduler.Location.useLocations(account);

  let locationForAnalytics: Location[] = locations ? { ...locations } : [];

  const locationsWithContentManagement = Store.User.useLocationWithPermission(
    User.LocationPermissionsNames.ContentManagment,
    locations
  );

  if (!me.permissions.admin) {
    const locationsWithViewAndDownload = Store.User.useLocationWithPermission(
      User.LocationPermissionsNames.ViewAndDownload,
      locations
    );

    locationForAnalytics = mergeUniqueLocations(
      locationsWithViewAndDownload,
      locationsWithContentManagement
    );
  }

  const { asset, error } = Scheduler.Asset.useAsset({
    asset: useParams<{ id: string }>().id,
    account
  });

  const assetLocationHasManagementPermission = me.permissions.admin
    ? true
    : locationsWithContentManagement.some((location) => location.id === asset?.locationId);

  const assetLocationHasAnalyticsPermission = me.permissions.admin
    ? true
    : locationForAnalytics.some((location) => location.id === asset?.locationId);

  const adminUser: User.admin = me as User.admin;

  const showRequestBtn =
    adminUser.userGlobalSettings.clientTier === 'pro' ||
    adminUser.userGlobalSettings.clientTier === 'standard';

  const eventLocation =
    locations?.find((location) => location.id === asset?.locationId)?.name ?? '';

  return error ? (
    <NotFound />
  ) : asset ? (
    <>
      <SectionContainer className='col-12 col-md-12 col-lg-8 col-xl-9'>
        <HeaderSection>
          <a className='back-link' href='/content-library/library'>
            <div className='icon arrow-left' />
            <div className='text'>Back to Content Library</div>
          </a>
        </HeaderSection>
        <DataSection asset={asset} event={asset.event} eventLocation={eventLocation} />
        <Player className='' asset={asset} />
        <ActionsSection
          asset={asset}
          showRequestBtn={showRequestBtn}
          hasManagementPermission={assetLocationHasManagementPermission}
        />
      </SectionContainer>

      {(isAdmin || assetLocationHasAnalyticsPermission || assetLocationHasManagementPermission) && (
        <Analytics asset={asset} createdOn={asset.timestamp} className='analytics' />
      )}
    </>
  ) : (
    <Loading.Delay />
  );
};
