import type { ApolloCache, ApolloError, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Obj } from '@livecontrol/core-utils';
import type { Asset, Event } from '@livecontrol/scheduler/model';
import type { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import { Errors } from '../graphql';
import type { MutationResult } from '../graphql';
import * as Dbx from './dbx';

interface TVariables {
  id: number;
  endTime: Date;
  location: string;
  assetIDs: Asset.ID[];
}

interface TData {
  extendEventTime?: boolean;
}

interface Args {
  event: Event;
  end: DateTime;
}

const MUTATION = gql`
  mutation ExtendEventTime(
    $id: Float!
    $endTime: DateTime!
    $location: String!
    $assetIDs: [Float!]!
  ) {
    extendEventTime(
      eventToExtend: { id: $id, end_time: $endTime, location: $location, assetIDs: $assetIDs }
    )
  }
`;

export const useExtend = (): [
  (args: Args) => Promise<boolean>,
  MutationResult<boolean, 'success'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [success, setSuccess] = useState<boolean>();

  return [
    useCallback(
      async (args: Args): Promise<boolean> => {
        let success_ = false;

        try {
          const { event, end } = args;

          // Parse the input arguments
          const variables = {
            id: event.id,
            endTime: end.toJSDate(),
            location: event.location.id,
            assetIDs: event.assets?.map((asset) => asset.id) ?? []
          };

          if (!Obj.isHydrated(variables)) {
            throw Errors.badRequest();
          }

          // Execute the GraphQL mutation
          const response = await mutation({
            variables,
            update(cache: ApolloCache<unknown>) {
              cache.modify({
                id: `${Dbx.__typename}:${event.id}`,
                fields: {
                  end_time(): string {
                    return end.toISO();
                  }
                }
              });
            }
          })
            .then(({ data }: FetchResult<TData>) => data?.extendEventTime)
            .catch((_error: ApolloError) => {
              throw Errors.serverError();
            });

          success_ = response ?? false;
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setSuccess(success_);

        return success_;
      },
      [mutation, setError, setSuccess]
    ),
    {
      success,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
