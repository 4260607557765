import _ from 'lodash';
import { Arr } from './arr';
import { Nix } from './nix';
import { Scalar } from './scalar';

export namespace Str {
  export type Options = Scalar.StringOptions;

  /** A string representation of the literal `null` */
  export const NULL = 'null';

  /** The default truncation length. */
  export const TRUNCATE_LENGTH = 50;

  /**
   * Converts the specified value to a optionally-truncated, non-empty string or `undefined`.
   *
   * Notes:
   * - If an array is specified, it is flattened and is converted to a winnowed, delimited string (see `_.toString`)
   * - A zero length string returns `undefined` (eg. `normalize('  ') === undefined).
   * - Numeric values are converted to their string equivalent (eg. `normalize(123) === '123'`).
   * - The boolean values `true` and `false` return `undefined` (eg. `normalize(true) === undefined`).
   * - The numeric constants `Number.NaN` and `Number.Infinity` return `undefined` (eg. `normalize(1/0) === undefined`).
   *
   * @param value - The value to be converted to a string.
   * @param options - Conversion options.
   * @returns The string value or `undefined`.
   */
  export const normalize = (value: unknown, options?: Options): string | undefined => {
    const flatVal = Array.isArray(value)
      ? Arr.winnow(value.flatMap((µ: unknown) => normalize(µ, options))).join(
          options?.separator ?? ', '
        )
      : value;

    const scalarVal = Scalar.normalize(flatVal, options);

    const stringVal =
      (!Nix.isNil(scalarVal) && typeof scalarVal === 'string') ||
      (typeof scalarVal === 'number' && Number.isFinite(scalarVal))
        ? _.toString(scalarVal)
        : undefined;

    return stringVal?.length ? stringVal : undefined;
  };

  /**
   * Splits a string-like value by the specified separator. This function is similar to `String.split`
   * or `_.split`, except that all tokens are trimmed and zero-length tokens are discared. The default
   * separator is any whitespace character.
   *
   * @param value - The string to be tokenized.
   * @param separator - The separator pattern to tokenize on. (default: whitespace)
   * @returns An array of non-empty string tokens.
   */
  export const tokenize = (value?: string, separator: RegExp | string = /\s+/): string[] => {
    const tokens: string[] = [];

    const stringVal = normalize(value);

    const parts = stringVal ? stringVal.split(separator) : [];

    parts.forEach((item: string) => {
      const trimmed = item.trim();

      if (trimmed) {
        tokens.push(trimmed);
      }
    });

    return tokens;
  };

  /**
   * Trim and truncate the specified string.
   *
   * @param value - The string to be truncated.
   * @param options - Lodash `_.truncate` options. (default length: 50)
   * @returns The (potentially) truncated string.
   */
  export const truncate = (
    value: string,
    options: _.TruncateOptions = { length: TRUNCATE_LENGTH }
  ): string => _.truncate(value.trim(), options);
}
