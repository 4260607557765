import { Num } from '@livecontrol/core-utils';
import React, { useEffect, useState } from 'react';

const DEFAULT_DELAY = 1e3; // 1s

/**
 * Renders children after the specified delay.  Typically used to prevent a loading-type component
 * from flashing on the screen briefly.
 *
 * @param props - The component properties.
 * @returns React element.
 */
export const Delay = (props: Delay.Props): React.ReactElement | null => {
  const { delay, children } = props;

  const [isReady, setReady] = useState(false);

  useEffect(() => {
    const timer = window.setTimeout(() => {
      setReady(true);
    }, Num.normalize(delay, { positive: true }) ?? DEFAULT_DELAY);

    return (): void => {
      clearTimeout(timer);
    };
  }, [delay]);

  return isReady ? <>{React.Children.toArray(children)}</> : null;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Delay {
  export type Props = React.PropsWithChildren<{
    delay?: number | string;
  }>;
}
