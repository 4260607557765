import { AspectRatio } from '@livecontrol/core-ui';
import { Component } from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';
import { Badge as Badge_ } from './badge';

interface ImageProps {
  imageUrl?: string;
}

const Image = styled.div.attrs(({ imageUrl }: ImageProps) => ({
  style: imageUrl
    ? {
        backgroundImage: `url('${imageUrl}')`
      }
    : {
        background: "url('/assets/image-not-found.png') var(--light) no-repeat center"
      }
}))`
  background-size: ${({ imageUrl }: ImageProps): string => (imageUrl ? 'cover' : 'contain')};
`;

// eslint-disable-next-line react/prefer-stateless-function
export class Preview extends Component<Preview.Props> {
  public override render(): ReactNode {
    const { imageUrl, children, ratio, ...rest } = this.props;

    return (
      <AspectRatio ratio={ratio ?? 16 / 9} {...rest}>
        <Image className='lc-absolute-overlay rounded-sm border' imageUrl={imageUrl} />
        {children}
      </AspectRatio>
    );
  }
}

export namespace Preview {
  export type Props = AspectRatio.Props & { imageUrl?: string };

  /* eslint-disable @typescript-eslint/no-unused-vars */
  export import Badge = Badge_;
}
