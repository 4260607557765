import { Str } from '@livecontrol/core-utils';
import { EventValidator } from './types';

export const validateEventDescription = (
  newEventInfo: EventValidator.EventInfo
): EventValidator.Errors => {
  const errors: EventValidator.Errors = {};
  const description = Str.normalize(newEventInfo.description);

  if (!!description && description.length > 5000) {
    errors.description = EventValidator.ErrorMessages.DESCRIPTION_TOO_LONG;
  }

  return errors;
};
