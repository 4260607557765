import { Button } from '@livecontrol/core-ui';
import type { Asset } from '@livecontrol/scheduler/model';
import cx from 'classnames';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Store } from '../../../../store';
import { PasswordModal } from './password-modal';

const ButtonContainer = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(46, 91, 255, 0.1);
    border: none;
    color: #2e5bff;
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 6px 11px !important;
    border-radius: 4px;
    margin-right: 8px;

    &:hover {
      background-color: #2e5bff;
      color: #ffffff;
    }

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 6px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }

    .Lock {
      background-image: url(/assets/icons/Lock-blue.svg);
    }

    .Lock-alt {
      background-image: url(/assets/icons/Lock-alt-blue.svg);
    }

    &:hover .Lock {
      background-image: url(/assets/icons/Lock.svg);
    }

    &:hover .Lock-alt {
      background-image: url(/assets/icons/Lock-alt.svg);
    }
  }
`;

export const PasswordProtectButton = ({ asset }: { asset: Asset }): React.ReactElement | null => {
  const [show, setShow] = useState(false);
  const isPasswordProtected = !!asset.eventPassword;
  const me = Store.User.useMe();

  return (
    <ButtonContainer>
      <Button
        variant={cx({
          'primary': isPasswordProtected,
          'outline-primary': !isPasswordProtected
        })}
        onClick={(): void => {
          setShow(true);
        }}
        disabled={!me.permissions.admin && me.permissions.readOnlyArchive}
      >
        {isPasswordProtected ? <div className='icon Lock' /> : <div className='icon Lock-alt' />}
        {isPasswordProtected ? 'Password Protected' : 'Password Protect'}
      </Button>
      <PasswordModal
        asset={asset}
        show={show}
        onHide={(): void => {
          setShow(false);
        }}
      />
    </ButtonContainer>
  );
};
