import { Lazy } from '@livecontrol/async-utils';
import type { Any } from '@livecontrol/core-utils';
import type { Event } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { freeze, produce as produce_ } from 'immer';
import { createContext, createElement, useContext, useEffect, useRef } from 'react';
import type { PropsWithChildren, ReactElement } from 'react';
import type { EqualityChecker, SetState, StateSelector } from 'zustand';
import create from 'zustand';
import { Store as A_Store } from '../../../store';
import { Filters } from './filters';
import { Sorting } from './sorting';
import type { State, Store, T_Draft } from './types';
import { Phase, T_Sorting, Tab } from './types';
import { Utils } from './utils';

const Context = createContext<Store>(<Any>undefined);

// Construct a provider
export const Provider = ({ children }: PropsWithChildren<unknown>): ReactElement => {
  const account = A_Store.Account.useAccount();
  const { events } = Scheduler.Event.useEvents(account);

  const store = useRef<Store>(
    create<State>((set: SetState<State>) => {
      const produce = (fn: (draft: T_Draft) => void): void => {
        set(produce_(fn));
      };

      return freeze({
        model: new Lazy(),
        timezone: account.timezone,
        produce
      });
    })
  ).current;

  useEffect((): void => {
    if (events) {
      const buckets = {
        [Phase.Live]: <Event[]>[],
        [Phase.Upcoming]: <Event[]>[],
        [Phase.Past]: <Event[]>[]
      };

      // Bucketize the events
      events.forEach((event: Event): void => {
        buckets[event.phase].push(event);
      });

      // Construct the store
      const { produce } = store.getState();

      produce((x) => {
        x.model.setValue({
          tabset: new Set(
            [Tab.Pending, buckets[Phase.Past].length ? Tab.Past : undefined].filter(
              (t?: Tab): t is Tab => !!t
            )
          ),

          [Phase.Live]: {
            events: buckets[Phase.Live],

            filters: Filters.make({
              store,
              phase: Phase.Live,
              locations: Utils.getUniqueLocations(buckets[Phase.Live])
            }),

            sorting: Sorting.make({
              store,
              phase: Phase.Live
            })
          },

          [Phase.Upcoming]: {
            events: buckets[Phase.Upcoming],

            filters: Filters.make({
              store,
              phase: Phase.Upcoming,
              locations: Utils.getUniqueLocations(buckets[Phase.Upcoming])
            }),

            sorting: Sorting.make({
              store,
              phase: Phase.Upcoming,
              value: {
                direction: T_Sorting.Direction.ASCENDING
              }
            }),

            selection: {
              selectedEvents: new Set(),

              synchronize(filtered: readonly Event[]): void {
                produce(({ model }) => {
                  const { selectedEvents } = model.value[Phase.Upcoming].selection;

                  // The selection must only ever contain filtered events
                  if (filtered.length) {
                    const filteredMap = new Set(filtered.map((event: Event) => event.id));

                    // Run through the selection and remove unfiltered elements
                    [...selectedEvents].forEach((selectedEvent) => {
                      if (!filteredMap.has(selectedEvent.id)) {
                        selectedEvents.delete(selectedEvent);
                      }
                    });
                  } else {
                    selectedEvents.clear();
                  }
                });
              },

              toggleOne(value: Event): void {
                produce(({ model }) => {
                  const { selectedEvents: ids } = model.value[Phase.Upcoming].selection;

                  ids[ids.has(value) ? 'delete' : 'add'](value);
                });
              },

              toggleAll(filtered: readonly Event[]): void {
                produce(({ model }) => {
                  const { selectedEvents } = model.value[Phase.Upcoming].selection;

                  // Clear the current selection
                  selectedEvents.clear();

                  filtered.forEach((event: Event) => {
                    selectedEvents.add(event);
                  });
                });
              }
            }
          },

          [Phase.Past]: {
            events: buckets[Phase.Past],

            filters: Filters.make({
              store,
              phase: Phase.Past,
              locations: Utils.getUniqueLocations(buckets[Phase.Past])
            }),

            sorting: Sorting.make({
              store,
              phase: Phase.Past
            })
          }
        });
      });
    }
  }, [events, store]);

  // Pass the context down
  return createElement(
    Context.Provider,
    {
      value: store
    },
    children
  );
};

export const useReady = (): boolean => useContext(Context)(({ model }) => model.isResolved);

export function useStore(): Store;

export function useStore<U>(selector: StateSelector<State, U>, equalityFn?: EqualityChecker<U>): U;

export function useStore<U>(
  selector?: StateSelector<State, U>,
  equalityFn?: EqualityChecker<U>
): Store | U {
  const store = useContext(Context);

  return selector ? store(selector, equalityFn) : store;
}
