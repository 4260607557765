import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Obj } from '@livecontrol/core-utils';
import type { Contact } from '@livecontrol/scheduler/model';
import { Account } from '@livecontrol/scheduler/model';
import { useEffect, useState } from 'react';
import type { QueryResult } from '../graphql';
import * as Dbx from './dbx';

interface TVariables {
  id: number;
}

interface TData {
  getContacts?: Dbx.Record[];
}

export const QUERY = gql`
  query GetContacts($id: Float!) {
    getContacts(input: { user: $id }) {
      ...StandardContactResponse
    }
  }
  ${Dbx.StandardContactResponse}
`;

export const useContacts = (args: Account.Like): QueryResult<Contact[], 'contacts'> => {
  const [error, setError] = useState<Error | undefined>();
  const [contacts, setContacts] = useState<Contact[] | undefined>();

  // Parse the input arguments
  const variables = {
    id: Account.toId(args)
  };

  // Validate the input
  if (!Obj.isHydrated(variables)) {
    return { loading: false, error: Exception.KABOOM };
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  // Prepare the query
  const { loading, data } = useQuery<TData, TVariables>(QUERY, {
    variables,
    onError() {
      setError(Exception.KABOOM);
    }
  });

  // When available, parse server response
  useEffect(() => {
    let contacts_;
    let error_;

    if (data) {
      contacts_ = (data.getContacts ?? [])
        .map((record: Dbx.Record) => Dbx.normalize(record))
        .filter((contact: Contact | undefined): contact is Contact => !!contact);
    }

    setContacts(contacts_);
    setError(error_);
  }, [data]);

  return { loading, error, contacts };
};
