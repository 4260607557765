// import { User } from '@livecontrol/scheduler/model';
import _ from 'lodash';
import { Children, Component, useEffect } from 'react';
import type { PropsWithChildren, ReactElement, ReactNode } from 'react';
import useHotjar from 'react-use-hotjar';
import { Store } from '../../store';
import { Provider as Provider_ } from './provider';

type Props = PropsWithChildren<unknown>;

// @ts-ignore
const _Inject = ({ children }: Props): ReactElement => {
  const { me, thee } = Store.User.useIdentity();
  // const account = Store.Account.useAccount();
  const { identifyHotjar } = useHotjar();
  // const intercom = useIntercom();

  useEffect(
    () => {
      if (me) {
        // HotJar
        identifyHotjar(_([me.id, thee?.id]).compact().join('-'), {
          displayName: _([me.fullName, thee?.fullName]).compact().join(' as '),
          email: thee?.email ?? me.email
        });

      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <>{Children.toArray(children)}</>;
};

// eslint-disable-next-line react/prefer-stateless-function
export class Trackers extends Component<Props> {
  public override render(): ReactNode {
    const { children } = this.props;

    // return me && production ? <Inject>{children}</Inject> : <>{Children.toArray(children)}</>;
    return <>{Children.toArray(children)}</>;
  }
}

export namespace Trackers {
  export const Provider = Provider_;
}
