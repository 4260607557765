import { useDelete } from './use-delete';
import { useEdit } from './use-edit';
import { useEditPassword } from './use-edit-password';
import { useExtend } from './use-extend';
import { useRequestEdit } from './use-request-edit';
import { useStop } from './use-stop';
import { useTransform } from './use-transform';

export const Hooks = {
  useDelete,
  useEdit,
  useRequestEdit,
  useEditPassword,
  useExtend,
  useStop,
  useTransform
};
