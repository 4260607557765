import { Device, Form } from '@livecontrol/core-ui';
import type { Item, Location, ProductionNotes } from '@livecontrol/scheduler/model';
import cx from 'classnames';
import { FormikProvider } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Store } from '../../../store';
import * as dropdownConstants from './dropdown-constants';
import type { Value } from './form';
import { useForm } from './form';

const ContentSaveOptions = styled.div`
  input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 2px;
    width: 30px;
    height: auto;
    border: 1px solid #ccc;
    outline: none;
  }

  input[type='checkbox']:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
  }

  input:checked[type='checkbox'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
`;

const DropdownSelector = styled(Form.DropdownSelector)`
  &.is-invalid button {
    border-color: red;
  }

  .dropdown-toggle {
    white-space: normal;

    &::after {
      content: none;
    }
  }

  .x-${Device.DESKSTOP} & {
    button {
      width: 367px;
    }
  }

  button {
    max-width: 500px;
    text-align: left;
    padding: 14px 44px 14px 20px;
    border: 1.5px solid var(--dark);
    border-radius: 8px;
    max-heigth: fit-content;
    appearance: none;
    background-image: url(assets/icons/arrow.svg);
    background-repeat: no-repeat;
    background-size: 14px auto;
    background-position: right 14px center;
  }

  .dropdown-menu {
    overflow-y: auto;
    max-height: 500px;
    margin: 0 !important;
  }

  .dropdown-item {
    white-space: normal;
  }

  .btn-tint,
  .btn-outline-tertiary {
    background-color: white;
    color: black !important;

    &:active,
    &:hover,
    &:focus {
      background-color: white !important;
      color: black !important;
      border: 1.5px solid var(--dark) !important;
    }
  }
`;

interface DropdownItem {
  available: Item[];
  selected?: Item;
  update?: (value?: Item) => void;
}

type productionType = 'audience_shots' | 'camera_movements_preference' | 'close_up_shots' | 'detailed_venue_shots' | 'event_ending_preference' | 'wide_venue_shots';
type productionConstant = 'audienceShots' | 'cameraMovementsPreference' | 'closeUpShots' | 'detailedVenueShots' | 'eventEndingPreference' | 'wideVenueShots';

interface Props {
  locations: Location[];
}

export const FormContent = ({ locations }: Props): ReactElement => {
  const me = Store.User.useMe();

  const findLabelOption = (identifier: string, options: Item[]): string => {
    const optionSelected = options.find((option) => option.identifier === identifier);

    return String(optionSelected?.label);
  };

  const [selectedLocation, setSelectedLocation] = useState<Item>({
    identifier: locations[0].id,
    label: locations[0].name
  });

  const [locationsState, setlocationsState] = useState<Location[]>(locations);

  const {
    selectedCameraMovementsPreference,
    selectedAudienceShots,
    selectedCloseUpShots,
    selectedWideVenueShots,
    selectedDetailedVenueShots,
    selectedEventEnding,
    setCameraMovementsPreference,
    setAudienceShots,
    setCloseUpShots,
    setWideVenueShots,
    setDetailedVenueShots,
    setEventEnding
  } = dropdownConstants.useProductionNotes(locationsState);

  const initialValues = {
    camera_movements_preference: undefined,
    event_ending_preference: undefined,
    audience_shots: undefined,
    location_id: undefined,
    close_up_shots: undefined,
    wide_venue_shots: undefined,
    allLocation: false
  };

  const onSuccess = useCallback((values: Value) => {
      setlocationsState((oldState): Location[] => {
        const newState = oldState.map((location) => ({ ...location, productionNotes: values }));

        return newState;
      });
  }, []);

  const { formik, loading } = useForm({
    initialValues,
    me,
    onSuccess
  });

  useEffect(() => {
    const productionNote = locationsState[0].productionNotes;

    formik.setValues(
      {
        camera_movements_preference: productionNote.camera_movements_preference,
        audience_shots: productionNote.audience_shots,
        location_id: locationsState[0].id,
        close_up_shots: productionNote.close_up_shots,
        wide_venue_shots: productionNote.wide_venue_shots,
        detailed_venue_shots: productionNote.detailed_venue_shots,
        event_ending_preference: productionNote.event_ending_preference
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsState]);

  useEffect(() => {

    const selectedLocation_ = locationsState.find(
      (location) => location.id === selectedLocation.identifier
    );

    const formValues = formik.values;

    const productionNotes = Object.fromEntries(
      Object.entries(formValues).filter((value) => value[1] !== undefined)
    );

    if (selectedLocation_) {
      const originalProductionNotes = selectedLocation_.productionNotes;

      selectedLocation_.productionNotes = { ...originalProductionNotes, ...productionNotes };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const onChange = (item?: Item): void => {
    if (selectedCameraMovementsPreference !== item && item !== undefined) {
      formik.setFieldValue(item.name ?? '', Number(item.identifier));
    }
  };

  const handleSelected = (
    hasProductionNotes: boolean,
    productionNotes: ProductionNotes | undefined,
    identify: productionType,
    identifyConstant: productionConstant
  ): Item | undefined => hasProductionNotes
      ? {
          identifier: String(productionNotes?.[identify]),
          label: productionNotes?.[identify] ? findLabelOption(String(productionNotes[identify]),  dropdownConstants[identifyConstant]) : undefined,
          name: identify
        }
      : undefined;

  const showProductionNotesByLocation = (value?: Item): void => {
    const selectedLocation_ = locationsState.find((location) => location.id === value?.identifier);
    const productionNotes = selectedLocation_?.productionNotes;

    formik.setFieldValue('location_id', selectedLocation_?.id);

    setSelectedLocation(value!);

    const hasProductionNotes = Object.keys(productionNotes!).length > 1;

    const selectedCameraMovementsPreferenceOption = handleSelected(hasProductionNotes, productionNotes, 'camera_movements_preference', 'cameraMovementsPreference');

    onChange(selectedCameraMovementsPreferenceOption);
    setCameraMovementsPreference(selectedCameraMovementsPreferenceOption);

    const selectedAudienceShotsyOption = handleSelected(hasProductionNotes, productionNotes, 'audience_shots', 'audienceShots');

    onChange(selectedAudienceShotsyOption);
    setAudienceShots(selectedAudienceShotsyOption);

    const selectedCloseUpShotsOption = handleSelected(hasProductionNotes, productionNotes, 'close_up_shots', 'closeUpShots');

    onChange(selectedCloseUpShotsOption);
    setCloseUpShots(selectedCloseUpShotsOption);

    const selectedWideVenueShotsyOption = handleSelected(hasProductionNotes, productionNotes, 'wide_venue_shots', 'wideVenueShots');

    onChange(selectedWideVenueShotsyOption);
    setWideVenueShots(selectedWideVenueShotsyOption);

    const selectedDetailedVenueShotsOption = handleSelected(hasProductionNotes, productionNotes, 'detailed_venue_shots', 'detailedVenueShots');

    onChange(selectedDetailedVenueShotsOption);
    setDetailedVenueShots(selectedDetailedVenueShotsOption);

    const selectedEventEndingPreferenceOption = handleSelected(hasProductionNotes, productionNotes, 'event_ending_preference', 'eventEndingPreference');

    onChange(selectedEventEndingPreferenceOption);
    setEventEnding(selectedEventEndingPreferenceOption);
  };

  const locationOptions = locationsState.map((location) => ({
    identifier: location.id,
    label: location.name
  }));

  const cameraMovementsPreferenceItems: DropdownItem = {
    available: dropdownConstants.cameraMovementsPreference,
    selected: selectedCameraMovementsPreference
  };

  const audienceShotsItems: DropdownItem = {
    available: dropdownConstants.audienceShots,
    selected: selectedAudienceShots
  };

  const closeUpShotsItems: DropdownItem = {
    available: dropdownConstants.closeUpShots,
    selected: selectedCloseUpShots
  };

  const wideVenueShotsItems: DropdownItem = {
    available: dropdownConstants.wideVenueShots,
    selected: selectedWideVenueShots
  };

  const detailedVenueShotsItems: DropdownItem = {
    available: dropdownConstants.detailedVenueShots,
    selected: selectedDetailedVenueShots
  };

  const eventEndingItems: DropdownItem = {
    available: dropdownConstants.eventEndingPreference,
    selected: selectedEventEnding
  };

  const locationItems: DropdownItem = {
    available: locationOptions,
    selected: selectedLocation,
    update: () => null
  };

  const locationsProduced = locationsState.filter((location) => location.isProduced);

  const hasValidLocations = locationsProduced.length;

  const noLocationItem: DropdownItem = {
    available: [{ identifier: '0', label: 'No available location' }],
    selected: { identifier: '0', label: 'No available location' },
    update: showProductionNotesByLocation
  };
  
  return (
    <div className='mt-16px line-height-lg'>
      <FormikProvider value={formik}>
        <Form>
          <div>
            {hasValidLocations ? (
              <DropdownSelector
                value={locationItems}
                onChange={showProductionNotesByLocation}
                noTruncate
              />
            ) : (
              <DropdownSelector value={noLocationItem} noTruncate disabled />
            )}

            <div className='mt-16px mb-40px text-gray-light font-size-13px font-weight-bold'>
              Choose the location you want to view/edit style notes.
            </div>
          </div>
          <div className='d-flex flex-column'>
            <div className='d-flex mt-20px flex-column flex-lg-row'>
              <div className='d-flex flex-column p-0 mr-16px col-12 col-lg-6 mb-8px mb-lg-0'>
                <div className='font-size-15px font-weight-bold text-dark'>
                  Live Camera Movements
                </div>
                <div className='font-size-13px font-weight-bold text-gray-light'>
                  Videographer may use pans and zooms in the live shot. Responding “avoid” will
                  limit live camera shots.
                </div>
              </div>
              <div className='p-0 col-12 col-lg-6'>
                <DropdownSelector
                  value={cameraMovementsPreferenceItems}
                  onChange={onChange}
                  setState={setCameraMovementsPreference}
                  noTruncate
                  size='lg'
                  className={cx(
                    formik.errors.camera_movements_preference &&
                      formik.touched.camera_movements_preference &&
                      'is-invalid'
                  )}
                  disabled={!hasValidLocations}
                />
                {formik.errors.camera_movements_preference &&
                  formik.touched.camera_movements_preference && (
                    <div className='text-danger font-size-12px font-weight-normal'>
                      Select an option
                    </div>
                  )}
              </div>
            </div>
            <div className='d-flex mt-20px flex-column flex-lg-row'>
              <div className='d-flex flex-column p-0 mr-16px col-12 col-lg-6 mb-8px mb-lg-0'>
                <div className='font-size-15px font-weight-bold text-dark'>Audience Shots</div>
                <div className='font-size-13px font-weight-bold text-gray-light'>
                  When the videographer zooms out, audience members may be captured. Responding with
                  “avoid” will limit those shots.
                </div>
              </div>
              <div className='p-0 col-12 col-lg-6'>
                <DropdownSelector
                  value={audienceShotsItems}
                  onChange={onChange}
                  setState={setAudienceShots}
                  noTruncate
                  size='lg'
                  className={cx(
                    formik.errors.audience_shots && formik.touched.audience_shots && 'is-invalid'
                  )}
                  disabled={!hasValidLocations}
                />
                {formik.errors.audience_shots && formik.touched.audience_shots && (
                  <div className='text-danger font-size-12px font-weight-normal'>
                    Select an option
                  </div>
                )}
              </div>
            </div>
            <div className='d-flex mt-20px flex-column flex-lg-row'>
              <div className='d-flex flex-column p-0 mr-16px col-12 col-lg-6 mb-8px mb-lg-0'>
                <div className='font-size-15px font-weight-bold text-dark'>Close Up Shots</div>
                <div className='font-size-13px font-weight-bold text-gray-light'>
                  Videographer may utilize close up shots as one of their camera techniques.
                  Responding “avoid” will limit close up shots.
                </div>
              </div>
              <div className='p-0 col-12 col-lg-6'>
                <DropdownSelector
                  value={closeUpShotsItems}
                  onChange={onChange}
                  setState={setCloseUpShots}
                  noTruncate
                  size='lg'
                  className={cx(
                    formik.errors.close_up_shots && formik.touched.close_up_shots && 'is-invalid'
                  )}
                  disabled={!hasValidLocations}
                />
                {formik.errors.close_up_shots && formik.touched.close_up_shots && (
                  <div className='text-danger font-size-12px font-weight-normal'>
                    Select an option
                  </div>
                )}
              </div>
            </div>
            <div className='d-flex mt-20px flex-column flex-lg-row'>
              <div className='d-flex flex-column p-0 mr-16px col-12 col-lg-6 mb-8px mb-lg-0'>
                <div className='font-size-15px font-weight-bold text-dark'>Wide Venue Shots</div>
                <div className='font-size-13px font-weight-bold text-gray-light'>
                  Videographer may utilize wide shots of the entire room. Responding “avoid” will
                  limit wide venue shots.
                </div>
              </div>
              <div className='p-0 col-12 col-lg-6'>
                <DropdownSelector
                  value={wideVenueShotsItems}
                  onChange={onChange}
                  setState={setWideVenueShots}
                  noTruncate
                  size='lg'
                  className={cx(
                    formik.errors.wide_venue_shots &&
                      formik.touched.wide_venue_shots &&
                      'is-invalid'
                  )}
                  disabled={!hasValidLocations}
                />
                {formik.errors.wide_venue_shots && formik.touched.wide_venue_shots && (
                  <div className='text-danger font-size-12px font-weight-normal'>
                    Select an option
                  </div>
                )}
              </div>
            </div>
            <div className='d-flex mt-20px flex-column flex-lg-row'>
              <div className='d-flex flex-column p-0 mr-16px col-12 col-lg-6 mb-8px mb-lg-0'>
                <div className='font-size-15px font-weight-bold text-dark'>
                  Detailed Venue Shots
                </div>
                <div className='font-size-13px font-weight-bold text-gray-light'>
                  Videographer may zoom in on specific elements & details in the room. Responding
                  “avoid” will limit detailed venue shots.
                </div>
              </div>
              <div className='p-0 col-12 col-lg-6'>
                <DropdownSelector
                  value={detailedVenueShotsItems}
                  onChange={onChange}
                  setState={setDetailedVenueShots}
                  noTruncate
                  size='lg'
                  className={cx(
                    formik.errors.detailed_venue_shots &&
                      formik.touched.detailed_venue_shots &&
                      'is-invalid'
                  )}
                  disabled={!hasValidLocations}
                />
                {formik.errors.detailed_venue_shots && formik.touched.detailed_venue_shots && (
                  <div className='text-danger font-size-12px font-weight-normal'>
                    Select an option
                  </div>
                )}
              </div>
            </div>
            <div className='d-flex mt-20px flex-column flex-lg-row'>
              <div className='d-flex flex-column p-0 mr-16px col-12 col-lg-6 mb-8px mb-lg-0'>
                <div className='font-size-15px font-weight-bold text-dark'>
                  How would you like your events to end?
                </div>
                <div className='font-size-13px font-weight-bold text-gray-light'>
                  Videographer will end the event at it`&apos;`s natural stopping point, near the
                  scheduled end time. To have more control over the end of the event, please choose
                  one of the other options. By choosing a different option you are agreeing to our
                  <a
                    href='https://mylivecontrol.zendesk.com/hc/en-us/articles/11755254845335-Event-End-Policy-for-Produced-Events'
                    target='_blank'
                    rel='noreferrer'
                    className='text-gray-light'
                    style={{ textDecoration: 'underline' }}
                  >
                    {' '}
                    End Event Policy{' '}
                  </a>
                  .
                </div>
              </div>
              <div className='p-0 col-12 col-lg-6'>
                <DropdownSelector
                  value={eventEndingItems}
                  onChange={onChange}
                  setState={setEventEnding}
                  noTruncate
                  size='lg'
                  className={cx(
                    formik.errors.event_ending_preference &&
                      formik.touched.event_ending_preference &&
                      'is-invalid'
                  )}
                  disabled={!hasValidLocations}
                />
                {formik.errors.event_ending_preference &&
                  formik.touched.event_ending_preference && (
                    <div className='text-danger font-size-12px font-weight-normal'>
                      Select an option
                    </div>
                  )}
              </div>
            </div>
            <ContentSaveOptions className='d-flex flex-row justify-content-center align-items-center mt-20px'>
              <div>Apply to All Existing Locations</div>

              <Form.Group
                name='allLocation'
                className='mb-0 mr-8px ml-8px d-flex'
                label={false}
                controlProps={{
                  type: 'checkbox'
                }}
              />

              <Form.Submit
                className='btn btn-primary pt-12px pr-40px pl-40px pb-12px'
                type='submit'
                loading={loading}
                disabled={!hasValidLocations}
              >
                Save
              </Form.Submit>
            </ContentSaveOptions>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};
