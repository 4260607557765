import { Button, CopyCTA, Device, Form } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import type { Account } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { ReactElement } from 'react';
import slugify from 'slugify';
import styled from 'styled-components';
import { Store, store } from '../../../store';
import { TextField } from '../common';

const ProfilePageContainer = styled.div`
  padding-bottom: 24px;
  padding-top: 24px;
  border-bottom: 1px solid #d5daea;

  .x-${Device.DESKSTOP} & {
    .form-section-lateral > div:first-of-type {
      min-width: 285px;
      width: 285px;
      margin-bottom: 10px;
      margin-right: 26px;
    }
  }
  
  .link {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    bottom
  }


  .form-control {
    height: 46px;
  }
`;

export const ProfilePageSection = ({
  webplayer
}: {
  webplayer: Account.WebPlayer;
}): ReactElement => {
  const account = Store.Account.useAccount();
  const { environment } = store.getState();

  const [editEndpoint, { loading: endpointLoading }] = Scheduler.Account.WebPlayer.useEdit();
  const [endpoint, setEndpoint] = useState(webplayer.endpoint ?? '');

  const [checkAvailability, { loading: checkingAvailability, data }] =
    Scheduler.Account.WebPlayer.useSlugCheck();

  const prompt = Modal.usePrompt();

  const [isTyping, setIsTyping] = useState(false);

  const debouncedAvailability = useRef(
    _.debounce(
      (func: () => void) => {
        func();
      },
      600,
      {
        leading: true,
        trailing: true
      }
    )
  );

  useEffect(() => {
    debouncedAvailability.current(() => {
      setIsTyping(false);
      void checkAvailability(webplayer.endpoint ?? '', endpoint);
    });
  }, [checkAvailability, endpoint, webplayer.endpoint]);

  const onEndpointChange = useCallback(
    (value: string) => {
      if (value.trim() !== endpoint) {
        setIsTyping(true);
        setEndpoint(slugify(value.trim(), { strict: true }));
      }
    },
    [endpoint]
  );

  return (
    <ProfilePageContainer>
      <Form.Descriptor
        title='Profile Page Link'
        titleClassName='title mb-4px mr-lg-3 font-size-15px font-weight-bold text-dark'
        description='Your unique link will be:'
        moreDetails={environment.VENUE_URI.replace('{slug}', endpoint)}
        moreDetailsClassName='link font-size-13px text-secondary font-weight-bold text-gray-light'
        descriptionClassName='font-size-13px text-secondary font-weight-bold text-gray-light'
        className='form-section-lateral d-lg-flex'
      >
        <TextField
          className='w-100'
          loading={endpointLoading}
          placeholder='Profile Page Link'
          value={endpoint}
          disabled={endpoint === webplayer.endpoint || checkingAvailability}
          onChange={onEndpointChange}
          onSubmit={async (): Promise<void> =>
            new Promise((resolve, reject) => {
              prompt.confirm({
                content: (
                  <div>
                    <p className='text-center'>
                      Changing your web player link will impact all existing video links and
                      embedded video players. All of your videos will now have a new URL.
                    </p>
                    <p className='text-center'>Are you sure you want to do this?</p>
                  </div>
                ),
                icon: 'far fa-link',
                title: 'Change Your Web Player Link?',
                okay: 'Change Link',
                onAnswer(value) {
                  if (value) {
                    void editEndpoint(account.id, { endpoint })
                      .then(() => {
                        resolve();
                      })
                      .catch(reject);
                  } else {
                    reject(new Error('Ignore'));
                  }
                }
              });
            })
          }
        >
          <div className='d-flex'>
            {webplayer.endpoint !== endpoint && endpoint && (
              <div className='font-size-12px mt-8px'>
                {checkingAvailability || isTyping ? (
                  <span className='text-danger'>Checking availability...</span>
                ) : !data?.slugCheck.slug_valid ? (
                  <span className='text-success'>This player link is available!</span>
                ) : (
                  <span className='text-danger'>This player link is unavailable or invalid.</span>
                )}
              </div>
            )}

            {webplayer.endpoint && (
              <CopyCTA
                value={environment.VENUE_URI.replace('{slug}', webplayer.endpoint)}
                toastStyle={{ marginTop: 12 }}
              >
                <Button variant='link' className='font-size-12px'>
                  Copy Link
                </Button>
              </CopyCTA>
            )}
          </div>
        </TextField>
      </Form.Descriptor>
    </ProfilePageContainer>
  );
};
