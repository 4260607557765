import { Grid } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import { FormikProvider } from 'formik';
import type { ReactElement } from 'react';
import { Form } from '../../../components';
import type { Props } from './form';
import { useForm } from './form';

export const Editor = (props: Props): ReactElement => {
  const { formik, loading, alert, handleClose } = useForm(props);

  return (
    <Modal show backdrop='static' keyboard={false} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Organization Information</Modal.Title>
        <div className='text-secondary font-size-14px mt-4px'>To change Organization Name or email please contact <a href="https://mylivecontrol.zendesk.com/hc/en-us/requests/new?ticket_form_id=12641114845207" target="_blank" rel="noopener noreferrer" >Customer Success.</a></div>
      </Modal.Header>

      <FormikProvider value={formik}>
        <Form autoComplete='off'>
          <Modal.Body>
            <Form.Alert show={alert.show} onClose={alert.hide}>
              {alert.message}
            </Form.Alert>

            <Grid.AutoRepeat className='column-gap-16px' min={250}>
              <Form.Group
                name='address'
                label='Organization Address'
                style={{
                  gridColumn: 'span 2'
                }}
                control={Form.AddressSelector}
              />

              <Form.Group
                name='organizationPhoneNumber'
                label='Organization Phone'
                style={{
                  gridColumn: 'span 2'
                }}
                controlProps={{ as: Form.Phone }}
              />
            </Grid.AutoRepeat>
          </Modal.Body>

          <Modal.Footer>
            <Modal.ButtonBox>
              <Modal.Submit loading={loading}>Save Changes</Modal.Submit>
              <Modal.Button
                variant='bg-transparent'
                className='btn-wide font-size-16px font-weight-bold text-secondary'
                onClick={handleClose}
              >
                Cancel
              </Modal.Button>
            </Modal.ButtonBox>
          </Modal.Footer>
        </Form>
      </FormikProvider>
    </Modal>
  );
};
