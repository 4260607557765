import { Grid } from '@livecontrol/core-ui';
import cx from 'classnames';
import type { ReactElement } from 'react';

export const Actions = ({ className, children, ...props }: Actions.Props): ReactElement => (
  <Grid className={cx('grid-flow-column', 'gap-8px', 'justify-content-end', className)} {...props}>
    {children}
  </Grid>
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Actions {
  export type Props = Grid.Props;
}
