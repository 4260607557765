import { JaySON, Obj } from '@livecontrol/core-utils';
import type { Asset, Destination } from '@livecontrol/scheduler/model';
import { Event } from '@livecontrol/scheduler/model';
import { EventValidator } from '@livecontrol/scheduler/validator';
import _ from 'lodash';
import { DateTime, Duration, Interval } from 'luxon';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Storage, store } from '../../store';
import { EventContextStore } from '../create-event';

export const EventContextProvider = (
  props: React.PropsWithChildren<{ existingEvent: Event }>
): React.ReactElement => {
  const { uuid } = useParams<{ uuid: string }>();
  const { ALLOW_NEW_FEATURES_ACCOUNT_IDS: _IDS } = store.environment;
  // const _account = Store.Account.useAccount();

  const storedEventDetails = Obj.normalize<{
    eventType?: EventValidator.ProductionType;
    eventInfo?: EventValidator.EventInfo & {
      isValid: boolean;
      start: string;
      end: string;
      repeatSettings: { repeatUntil: { stopRepeatingOn: string } };
    };
    clientContactDetails?: Event.ClientContactDetails;
    eventDestinations?: Destination[];
    zoomDetails?: Event.ZoomDetails;
    clientBookingPaymentMethod?: EventValidator.ClientBookingPaymentMethod;
    simulatedLiveAssetDetails?: Asset.MuxUploadedAsset;
    simulatedLiveFileUpload?: Asset.MuxUpload & { fileName: string; videoDuration: Duration };
    simulatedLiveAssetIsUpload: boolean;
    mobileKitData?: EventValidator.EventInfo.MobileKitData;
  }>(JaySON.parse(Storage.getItem(uuid) ?? ''));

  const [eventType, setEventType] = useState<EventValidator.ProductionType | undefined>(
    storedEventDetails?.eventType
      ? EventValidator.ProductionType.normalize(storedEventDetails.eventType)
      : props.existingEvent.production === Event.Production.Produced
      ? EventValidator.ProductionType.PRODUCED
      : props.existingEvent.production === Event.Production.Simulated_Live
      ? EventValidator.ProductionType.SIMULATED
      : props.existingEvent.production === Event.Production.Client_Booking
      ? EventValidator.ProductionType.CLIENT_BOOKING
      : props.existingEvent.production === Event.Production.Mobile
      ? EventValidator.ProductionType.MOBILE
      : props.existingEvent.production === Event.Production.Test
      ? EventValidator.ProductionType.TEST
      : props.existingEvent.production === Event.Production.Mobile_Kit
      ? EventValidator.ProductionType.MOBILE_KIT
      : EventValidator.ProductionType.STATIC
  );

  const [eventInfo, setEventInfo] = useState<EventValidator.EventInfo & { isValid: boolean }>(
    storedEventDetails?.eventInfo
      ? _.merge(storedEventDetails.eventInfo, {
          start: DateTime.fromISO(storedEventDetails.eventInfo.start),
          end: DateTime.fromISO(storedEventDetails.eventInfo.end)
        })
      : {
          type:
            props.existingEvent.production === Event.Production.Produced
              ? EventValidator.ProductionType.PRODUCED
              : props.existingEvent.production === Event.Production.Simulated_Live
              ? EventValidator.ProductionType.SIMULATED
              : props.existingEvent.production === Event.Production.Client_Booking
              ? EventValidator.ProductionType.CLIENT_BOOKING
              : props.existingEvent.production === Event.Production.Mobile
              ? EventValidator.ProductionType.MOBILE
              : props.existingEvent.production === Event.Production.Mobile_Kit
              ? EventValidator.ProductionType.MOBILE_KIT
              : EventValidator.ProductionType.STATIC,
          location: props.existingEvent.location.id,
          start: props.existingEvent.start,
          end: props.existingEvent.end,
          title: props.existingEvent.title,
          description: props.existingEvent.description,
          backgroundURL: props.existingEvent.backgroundURL,
          operatorNotes: props.existingEvent.operatorNotes,
          clientContactDetails: props.existingEvent.clientContactDetails,
          repeatSettings: {
            repeat: false
          },
          passwordSettings: {
            enabled: !!props.existingEvent.password,
            password: props.existingEvent.password
          },
          chatSettings: {
            liveChat: props.existingEvent.liveChat,
            registration: props.existingEvent.registration,
            requireRegistration: props.existingEvent.requireRegistration,
            showViewers: props.existingEvent.showViewers
          },
          cueSheetURL: props.existingEvent.cueSheetURL,
          redirectUrlSettings: {
            enabled: !!props.existingEvent.redirectUrlSettings?.enabled,
            url: props.existingEvent.redirectUrlSettings?.url
          },
          technicalContactSettings: {
            enabled: !!props.existingEvent.technicalContactSettings?.enabled,
            contactId: props.existingEvent.technicalContactSettings?.contactId
          },
          receiveIsoRecordings: props.existingEvent.receiveIsoRecordings,
          useIntroAndOutroSlides: props.existingEvent.useIntroAndOutroSlides,
          mobileKitData: {
            title: props.existingEvent.mobileKitData?.title,
            description: props.existingEvent.mobileKitData?.description,
            location: props.existingEvent.mobileKitData?.location,
            start: props.existingEvent.mobileKitData?.start,
            end: props.existingEvent.mobileKitData?.end
          },
          isValid: true
        }
  );

  const [clientContactDetails, setClientContactDetails] = useState<
    Event.ClientContactDetails | undefined
  >(storedEventDetails?.clientContactDetails ?? props.existingEvent.clientContactDetails);

  const [eventDestinations, setEventDestinations] = useState<Destination[]>(
    storedEventDetails?.eventDestinations ?? props.existingEvent.destinations ?? []
  );

  const [zoomDetails, setZoomDetails] = useState<Event.ZoomDetails | undefined>(
    storedEventDetails?.zoomDetails
  );

  const [clientBookingPaymentMethod, setClientBookingPaymentMethod] = useState<
    EventValidator.ClientBookingPaymentMethod | undefined
  >(storedEventDetails?.clientBookingPaymentMethod);

  const [simulatedLiveAssetDetails, setSimulatedLiveAssetDetails] = useState<
    Asset.MuxUploadedAsset | undefined
  >(storedEventDetails?.simulatedLiveAssetDetails);

  const [simulatedLiveFileUpload, setSimulatedLiveFileUpload] = useState<
    (Asset.MuxUpload & { fileName?: string; videoDuration: Duration }) | undefined
  >(
    storedEventDetails?.simulatedLiveFileUpload ?? {
      fileName: props.existingEvent.simulatedLiveDetails?.fileName,
      videoDuration: storedEventDetails?.simulatedLiveAssetDetails?.duration
        ? storedEventDetails.simulatedLiveAssetDetails.duration
        : eventInfo.start && eventInfo.end
        ? Interval.fromDateTimes(eventInfo.start, eventInfo.end).toDuration()
        : Duration.fromObject({ hours: 3 })
    }
  );

  const [simulatedLiveAssetIsUpload, setSimulatedLiveAssetIsUpload] = useState(
    storedEventDetails?.simulatedLiveAssetIsUpload ??
      !!props.existingEvent.simulatedLiveDetails?.fileName
  );

  const [mobileKitData, setMobileKitData] = useState<
    EventValidator.EventInfo.MobileKitData | undefined
  >(storedEventDetails?.mobileKitData);

  return (
    <EventContextStore.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        store: {
          eventType,
          eventInfo,
          clientContactDetails,
          eventDestinations,
          zoomDetails,
          clientBookingPaymentMethod,
          simulatedLiveAssetDetails,
          simulatedLiveFileUpload,
          simulatedLiveAssetIsUpload,
          mobileKitData,
          setEventType,
          setEventInfo,
          setClientContactDetails,
          setEventDestinations,
          setZoomDetails,
          setClientBookingPaymentMethod,
          setSimulatedLiveAssetDetails,
          setSimulatedLiveFileUpload,
          setSimulatedLiveAssetIsUpload,
          setMobileKitData
        },
        existingEvent: props.existingEvent
      }}
    >
      {props.children}
    </EventContextStore.Provider>
  );
};
