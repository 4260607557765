import { AddressSelector as AddressSelector_ } from './address-selector';
import { Form } from './form';

/* eslint-disable */
declare module './form' {
  namespace Form {
    export import AddressSelector = AddressSelector_;
  }
}
/* eslint-enable */

Object.assign(Form, { AddressSelector: AddressSelector_ });

export { Form };
