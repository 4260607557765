import { Device } from '@livecontrol/core-ui';
import type { Location } from '@livecontrol/scheduler/model';
import { User } from '@livecontrol/scheduler/model';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DropdownLocations } from './dropdown-locations';
import { PreferenceToggle } from './preference-toggle';

const NotificationSettings = styled.div`
  .x-${Device.DESKSTOP} & {
    min-width: 284px;
  }
`;

interface LocationsNotification {
  id: number;
  locationId: string;
  eventScheduling: boolean;
  eventReminder: boolean;
  connectionIssues: boolean;
  customerFeedback: boolean;
}

export const preferenceMap = [
  {
    title: 'Scheduling Updates',
    description: "We'll email you when an event has been scheduled, deleted or updated.",
    groupId: User.NotificationPreference.EVENT_SCHEDULING,
    key: 'eventScheduling' as const
  },
  {
    title: 'Event Reminders',
    description: "We'll email you with reminders before your event starts.",
    groupId: User.NotificationPreference.UPCOMING_EVENT_REMINDER,
    key: 'eventReminder' as const
  },
  {
    title: 'Error Alerts',
    description:
      "We'll email you if there is an issue with a stream or one of your connected destinations.",
    groupId: User.NotificationPreference.DESTINATION_CONNECTION_ISSUE,
    key: 'connectionIssues' as const
  },
  {
    title: 'Feedback Emails',
    description: "We'll email you to get your feedback on how we are doing.",
    groupId: User.NotificationPreference.CUSTOMER_FEEDBACK,
    key: 'customerFeedback' as const
  }
];

interface Props {
  locations: Location[];
  locationsNotification: LocationsNotification[];
  setLocationsNotificationSelected: React.Dispatch<React.SetStateAction<LocationsNotification[]>>;
  me: User;
}

export const EmailSettings = ({
  me,
  setLocationsNotificationSelected,
  locationsNotification,
  locations
}: Props): React.ReactElement => {
  const defaultValues: LocationsNotification | undefined = me.emailLocationNotifications?.find(
    (notification) => notification.locationId === locations[0].id
  );

  const [locationSelected, setlocationSelected] = useState<Location | undefined>();

  const [actualLocationNotification, setActualLocationNotification] = useState<
    LocationsNotification | undefined
  >(defaultValues);

  useEffect(() => {
    if (!locationSelected) {
      setlocationSelected(locations[0]);
    }

    if (me.emailLocationNotifications?.length === 0) {
      setActualLocationNotification({
        locationId: locationSelected?.id ? locationSelected.id : '',
        eventScheduling: true,
        eventReminder: true,
        connectionIssues: true,
        customerFeedback: true,
        id: 0
      });
    }

    locationsNotification.forEach((locationNotification) => {
      if (locationNotification.locationId === locationSelected?.id) {
        setActualLocationNotification(locationNotification);
      } else if (me.emailLocationNotifications?.length === 0) {
        setActualLocationNotification({
          locationId: locationSelected?.id ? locationSelected.id : '',
          eventScheduling: true,
          eventReminder: true,
          connectionIssues: true,
          customerFeedback: true,
          id: 0
        });
      } else {
        const emailLocationNotifications = me.emailLocationNotifications?.find(
          (notification) => notification.locationId === locationSelected?.id
        );

        if (emailLocationNotifications) {
          let index = -1;

          if (locationSelected) {
            index = locationsNotification.findIndex(
              (aLocationNotification) => aLocationNotification.locationId === locationSelected.id
            );
          }

          if (index === -1) {
            setActualLocationNotification(emailLocationNotifications);
          }
        } else {
          setActualLocationNotification({
            locationId: locationSelected?.id ? locationSelected.id : '',
            eventScheduling: true,
            eventReminder: true,
            connectionIssues: true,
            customerFeedback: true,
            id: 0
          });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, locationSelected]);

  useEffect(() => {
    setLocationsNotificationSelected((oldStateLocation): LocationsNotification[] => {
      if (actualLocationNotification && actualLocationNotification.locationId.length !== 0) {
        const newState = oldStateLocation.filter(
          (old) => old.locationId !== actualLocationNotification.locationId
        );

        return [...newState, actualLocationNotification];
      }

      return oldStateLocation;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualLocationNotification]);

  const resetToDefault = (): void => {
    setActualLocationNotification({
      locationId: locationSelected?.id ? locationSelected.id : '',
      eventScheduling: true,
      eventReminder: true,
      connectionIssues: true,
      customerFeedback: true,
      id: actualLocationNotification?.id ? actualLocationNotification.id : 0
    });
  };

  const handleLocationNotification = (value: boolean, notificationName: string): void => {
    setActualLocationNotification((oldState): LocationsNotification | undefined => {
      if (oldState) {
        const newLocationNotification: LocationsNotification = { ...oldState };

        newLocationNotification[notificationName] = value;

        return { ...newLocationNotification };
      }

      return oldState;
    });
  };

  return (
    <div className={cx('d-flex flex-row mt-32px flex-column flex-lg-row')}>
      <NotificationSettings className='d-flex flex-column mr-32px'>
        <div className='font-size-15px font-weight-bold text-dark mb-16px'>
          Notification Settings
        </div>
        <DropdownLocations
          locations={locations}
          locationSelected={locationSelected}
          setLocation={setlocationSelected}
        />
        <button
          type='button'
          onClick={resetToDefault}
          className='btn btn-transparent font-weight-bold text-primary mt-8px text-left pl-0'
        >
          Reset to Default
        </button>
      </NotificationSettings>
      <div className='d-flex flex-column'>
        {preferenceMap.map(({ description, title, groupId, key }) => (
          <PreferenceToggle
            title={title}
            description={description}
            key={groupId}
            checked={actualLocationNotification ? actualLocationNotification[key] : true}
            notificationName={key}
            handleLocationNotification={handleLocationNotification}
          />
        ))}
      </div>
    </div>
  );
};
