import type { ApolloError, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Bool, Obj, Str } from '@livecontrol/core-utils';
import { Account } from '@livecontrol/scheduler/model';
import { useCallback, useState } from 'react';
import type { MutationResult } from '../../graphql';
import { Errors } from '../../graphql';

import Plaid = Account.Plaid;

interface TData {
  linkPlaidPublicTokenToUser?: { ok?: boolean };
}

interface TVariables {
  account: number;
  plaidToken: string;
  plaidAccount: string;
}

const MUTATION = gql`
  mutation linkPlaidPublicTokenToUser(
    $account: Int!
    $plaidToken: String!
    $plaidAccount: String!
  ) {
    linkPlaidPublicTokenToUser(
      user: $account
      plaidPublicToken: $plaidToken
      plaidAccountId: $plaidAccount
    ) {
      ok
    }
  }
`;

interface Args {
  account: Account.Like;
  plaid: {
    token: Plaid.Token;
    account: Plaid.Account;
  };
}

export const useLinkPlaidPublicToken = (): [
  (args: Args) => Promise<boolean>,
  MutationResult<boolean, 'success'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [success, setSuccess] = useState<boolean>(false);

  return [
    useCallback(
      async (args: Args): Promise<boolean> => {
        let success_ = false;

        try {
          // Parse the input arguments
          const variables = {
            account: Account.toId(args.account),
            plaidToken: Str.normalize(args.plaid.token),
            plaidAccount: Str.normalize(args.plaid.account)
          };

          if (!Obj.isHydrated(variables)) {
            throw Errors.badRequest();
          }

          const response = await mutation({ variables })
            .then(({ data }: FetchResult<TData>) => data?.linkPlaidPublicTokenToUser)
            .catch((_error: ApolloError) => {
              throw Errors.serverError();
            });

          // Parse the server response
          success_ = Bool.normalize(response?.ok);
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setSuccess(success_);

        return success_;
      },
      [mutation, setError, setSuccess]
    ),
    {
      success,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
