import { Modal } from '@livecontrol/scheduler/components';
import type { FormikContextType } from 'formik';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Alert, Store } from '../../store';

interface Values {
  email: string;
}

interface Form {
  formik: FormikContextType<Values>;
  loading: boolean;
  alert: Alert;
}

const validationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .required('Your email address is required.')
    .email('Please enter a valid email address.')
});

export const useForm = (): Form => {
  const [forgotPassword, { loading, error }] = Store.User.useForgotPassword();
  const alert = Alert.useAlert(error);
  const prompt = Modal.usePrompt();
  const history = useHistory();

  const formik = useFormik<Values>({
    initialValues: { email: '' },

    validationSchema,
    validateOnMount: true,

    validate(): void {
      alert.hide();
    },

    async onSubmit(data: Values): Promise<void> {
      alert.hide();

      // If not loading, execute the mutation
      if (!loading && (await forgotPassword(data))) {
        prompt.message({
          title: 'Password Request Received',
          content: 'A password reset link has been sent to your email address.',
          onClose(): void {
            history.push('/login');
          }
        });
      }
    }
  });

  return {
    formik,
    loading,
    alert
  };
};
