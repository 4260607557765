/* eslint-disable @typescript-eslint/no-use-before-define */
import { useApolloClient } from '@apollo/client';
import { Modal } from '@livecontrol/scheduler/components';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { MutationResult } from '@livecontrol/scheduler/store';
import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import { DeleteEvent } from '../common';
import type { Event } from '../store';

export const useDelete = (): [(events: Event | Event[]) => void, (MutationResult<void> | undefined)] => {
  const [result, setResult] = useState<unknown>(); // Scheduler.Event.useDelete();

  const prompt = Modal.usePrompt();

  const apolloClient = useApolloClient();

  const handler = useCallback(
    (events_: Event | Event[]): void => {
      const events = Array.isArray(events_) ? events_ : [events_];
      const ids = Scheduler.Event.Dbx.toEventIds(events);

      if (!ids.length) {
        return;
      }

      if (isEventBeyondStartTime(events)) {
        prompt.error({
          title: 'Event is already in progress.',
          content: 'This event has already started and therefore you are not able to delete this event.',
          onClose() {
            void apolloClient.refetchQueries({
              include: ['GetEvents']
            });
          }
        });

        return;
      }

      prompt.show(<DeleteEvent
        onClose={(): void => {
          prompt.hide();
        }}
        result={(value): void => {
        setResult(value);
      }
      } ids={ids} events={events}
     />);
    },
    [prompt, apolloClient]
  );

  return [handler, result as (MutationResult<void> | undefined)];
};

const isEventBeyondStartTime = (events: Event[]): boolean => {
  const now = DateTime.now();

  return events.some(event=> now > event.start);
};
