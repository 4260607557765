import { Loading } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import { User } from '@livecontrol/scheduler/model';
import type { Event, Location } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { EventValidator } from '@livecontrol/scheduler/validator';
import { DateTime } from 'luxon';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Form, SectionContainer } from '../../components';
import { Store } from '../../store';
// import { ChooseEventDestinations } from './choose-event-destinations';
import { ChooseEventType } from './choose-event-type';
import { ClientBookingPayment } from './client-booking-payment';
import { ClientContact } from './client-contact';
import { EventConfirmation } from './event-confirmation';
import { EventDetails, EventDetailsUtils, MobileDetails } from './event-details';
import type { EventContext } from './event-store';
import { EventContextStore } from './event-store';
import { SelectVideo } from './select-video';
import { StreamingAndEventSettings } from './streaming-and-event-settings';

const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e4e8f0;
  padding-bottom: 36px;
  .title-text {
    font-size: 28px;
    font-weight: 700;
    color: #2e384d;
  }
  .step-section {
    margin-left: 15px;

    .text {
      color: #8798ad;
      font-size: 28px;
      font--weight: 600;
    }
  }
`;

interface Alert {
  message: string;
  clear: () => void;
}

export const CreateEventWorkflow = (): React.ReactElement => {
  const account = Store.Account.useAccount();
  const { locations } = Scheduler.Location.useLocations(account);
  const { contacts } = Scheduler.Event.useGetTechnicalContacts({ clientId: account.id });
  const isAdmin = Store.User.useIsAdmin();
  const { loading: userLoading, user } = Store.User.useUser(account.id);
  const { uuid } = useParams<{ uuid: string }>();
  const { store }: EventContext = useContext<EventContext>(EventContextStore);
  const [activeStep, setActiveStep] = useState(uuid ? 2 : 0);
  const [createdEventIds, setCreatedEventIds] = useState<(number | undefined)[]>([]);
  const [createEvent, { error, loading }] = Scheduler.Event.useCreate();
  const [eventIsStatic, setEventIsStatic] = useState<boolean>(false);
  const { refetch } = Scheduler.Account.useBilling(account);

  const userWithSettings: User.admin = user as User.admin;

  const locationsWithPermission = Store.User.useLocationWithPermission(
    User.LocationPermissionsNames.EventManagment,
    locations
  );

  const [createEventWithMobile, { error: errorEventWithMobile, loading: loadingEventWithMobile }] =
    Scheduler.Event.useCreateWithMobile();

  const [alert, setAlert] = useState<Alert | undefined>();
  const [showSchedulingNotice, setShowSchedulingNotice] = useState(false);

  const clear = (): void => {
    setAlert(undefined);
  };

  useEffect((): void => {
    if (error) {
      setAlert({
        message: error.message,
        clear
      });
    }

    if (errorEventWithMobile) {
      setAlert({
        message: errorEventWithMobile.message,
        clear
      });
    }
  }, [error, errorEventWithMobile]);

  const onSchedule = useCallback(
    async (values?: Partial<Event>): Promise<void> => {
      if (
        store.eventInfo.technicalContactSettings?.enabled &&
        store.eventInfo.technicalContactSettings.contactId === 0
      ) {
        const eventTypeForLocations =
          store.eventType === EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT
            ? EventValidator.ProductionType.PRODUCED
            : store.eventType;

        const availableLocations = EventDetailsUtils.getAvailableLocations(
          locations,
          eventTypeForLocations
        );

        const contactsByLocation = contacts?.filter(
          (contact) =>
            contact.locationId === (availableLocations.length ? availableLocations[0].id : '')
        );

        if (contactsByLocation) {
          store.eventInfo.technicalContactSettings.contactId = contactsByLocation[0].id;
        }
      }

      if (
        !isAdmin &&
        store.eventType === EventValidator.ProductionType.PRODUCED &&
        store.eventInfo.start &&
        store.eventInfo.start < DateTime.utc().plus({ days: account.leadTime })
      ) {
        setShowSchedulingNotice(true);
      } else if (store.eventType === EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT) {
        const result = await createEventWithMobile({
          ...store,
          ...values,
          account: account.id,
          eventLocation: locations?.find(
            (location: Location) => location.id === store.eventInfo.location
          )
        });

        if (result.length) {
          setCreatedEventIds(result);
          setActiveStep(activeStep + 1);
          window.scrollTo(0, 0);

          if (refetch) {
            void refetch();
          }
        }
      } else {
        const result = await createEvent({
          ...store,
          ...values,
          account: account.id,
          eventLocation: locations?.find(
            (location: Location) => location.id === store.eventInfo.location
          )
        });

        if (result.length) {
          setCreatedEventIds(result);
          setActiveStep(activeStep + 1);
          window.scrollTo(0, 0);

          if (refetch) {
            void refetch();
          }
        }
      }
    },
    [
      store,
      isAdmin,
      account.leadTime,
      account.id,
      locations,
      contacts,
      createEventWithMobile,
      activeStep,
      refetch,
      createEvent
    ]
  );

  const nextStep = useCallback((): void => {
    setActiveStep(activeStep + 1);
    window.scrollTo(0, 0);
  }, [activeStep]);

  const previousStep = useCallback((): void => {
    alert?.clear();
    setActiveStep(activeStep - 1);
    window.scrollTo(0, 0);
  }, [activeStep, alert]);

  const handleCloseSchedulingNotice = useCallback((): void => {
    setShowSchedulingNotice(false);
    setActiveStep(1);
    window.scrollTo(0, 0);
  }, []);

  const handleConfirmSchedulingNotice = useCallback(async (): Promise<void> => {
    if (
      store.eventInfo.technicalContactSettings?.enabled &&
      store.eventInfo.technicalContactSettings.contactId === 0
    ) {
      const eventTypeForLocations =
        store.eventType === EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT
          ? EventValidator.ProductionType.PRODUCED
          : store.eventType;

      const availableLocations = EventDetailsUtils.getAvailableLocations(
        locations,
        eventTypeForLocations
      );

      const contactsByLocation = contacts?.filter(
        (contact) =>
          contact.locationId === (availableLocations.length ? availableLocations[0].id : '')
      );

      if (contactsByLocation) {
        store.eventInfo.technicalContactSettings.contactId = contactsByLocation[0].id;
      }
    }

    const result = await createEvent({
      ...store,
      eventType: locations?.find((location: Location) => location.id === store.eventInfo.location)
        ?.isMobile
        ? EventValidator.ProductionType.MOBILE
        : EventValidator.ProductionType.STATIC,
      account: account.id
    });

    if (result.length) {
      setEventIsStatic(true);
      setCreatedEventIds(result);
      setShowSchedulingNotice(false);
      setActiveStep(activeStep + 1);
      window.scrollTo(0, 0);
    }
  }, [store, createEvent, locations, account.id, contacts, activeStep]);

  const onClientContactDetailsUpdate = useCallback(
    (value: Event['clientContactDetails']) => {
      if (value) {
        store.setClientContactDetails(value);
        nextStep();
      }
    },
    [nextStep, store]
  );

  const steps = useMemo(
    (): React.ReactElement[] => [
      <ChooseEventType key='event-type' onContinue={nextStep} />,
      ...(store.eventType === EventValidator.ProductionType.SIMULATED
        ? [<SelectVideo key='event-video' onContinue={nextStep} onBack={previousStep} />]
        : []),
      ...(store.eventType !== EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT
        ? [
            <EventDetails
              key='event-details'
              onContinue={nextStep}
              onBack={previousStep}
              locations={locationsWithPermission}
              contacts={contacts}
              accountSettings={userWithSettings}
            />
          ]
        : [
            <EventDetails
              key='event-details'
              onContinue={nextStep}
              onBack={previousStep}
              title='Produced Event Details'
              locations={locationsWithPermission}
              contacts={contacts}
              accountSettings={userWithSettings}
            />,
            <MobileDetails
              key='event-details'
              onContinue={nextStep}
              onBack={previousStep}
              title='Mobile Event Details'
              minDate={store.eventInfo.end}
              locations={locationsWithPermission}
              accountSettings={userWithSettings}
            />
          ]),
      ...(store.eventType === EventValidator.ProductionType.CLIENT_BOOKING
        ? [
            <ClientContact
              key='client-contact'
              onUpdate={onClientContactDetailsUpdate}
              onBack={previousStep}
              initialValue={store.clientContactDetails}
            />,
            <ClientBookingPayment
              key='client-booking-payment'
              onContinue={onSchedule}
              onBack={previousStep}
            />
          ]
        : []),
      ...((store.eventType === EventValidator.ProductionType.PRODUCED ||
        store.eventType === EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT ||
        store.eventType === EventValidator.ProductionType.MOBILE_KIT ||
        store.eventType === EventValidator.ProductionType.STATIC) &&
      userWithSettings.userGlobalSettings.clientContactInfo
        ? [
            <ClientContact
              skippable
              key='client-contact'
              onUpdate={onClientContactDetailsUpdate}
              onBack={previousStep}
              onSkip={nextStep}
              initialValue={store.clientContactDetails}
            />
          ]
        : []),

      ...(store.eventType !== EventValidator.ProductionType.CLIENT_BOOKING
        ? [
            <Fragment key='event-destinations'>
              <Form.Alert show={!!alert} onClose={alert?.clear}>
                {alert?.message}
              </Form.Alert>
              <StreamingAndEventSettings
                onSchedule={onSchedule}
                onBack={previousStep}
                accountSettings={userWithSettings}
              />
            </Fragment>
          ]
        : []),

      <EventConfirmation
        key='event-confirmation'
        newEventId={createdEventIds[0]}
        mobileKitEventId={createdEventIds.length > 1 ? createdEventIds[1] : undefined}
        setAsStatic={eventIsStatic}
      />
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      alert,
      createdEventIds,
      eventIsStatic,
      nextStep,
      onClientContactDetailsUpdate,
      onSchedule,
      previousStep,
      store.clientContactDetails,
      store.eventInfo.end,
      store.eventType,
      user?.enableProducedEventContacts
    ]
  );

  if (loading || userLoading || loadingEventWithMobile) {
    return <Loading.Delay />;
  }

  const stepsCount = steps.length - 1;

  const onLastStep = activeStep === stepsCount;

  return (
    <SectionContainer className='col-12'>
      <Header>
        {onLastStep ? (
          <div className='title-text big d-flex flex-row'>
            <div className='title-text'>
              Your event has been scheduled!{' '}
              <span className='title-emoji' role='img'>
                <img style={{ width: 20 }} src='assets/icons/icon-fiesta.jpeg' alt='emoji fiesta' />
              </span>
            </div>
          </div>
        ) : (
          <>
            <div className='title-text'>Schedule an event: </div>
            <div className='step-section'>
              <div className='text'>Step {activeStep + 1}</div>
            </div>
          </>
        )}
      </Header>

      {steps[activeStep]}
      <Modal.Prompt
        show={showSchedulingNotice}
        title='Scheduling Notice'
        okay='Schedule'
        cancel='Go Back'
        onCancel={handleCloseSchedulingNotice}
        onOkay={handleConfirmSchedulingNotice}
      >
        <p className='text-center'>
          You are attempting to schedule a Produced event outside of your lead time -{' '}
          {account.leadTime} day(s).
        </p>
        <p className='text-center'>
          This event will default to a static event. Please finish scheduling the event, then call
          or email support if you would like to change it to Produced. Late requests are not
          guaranteed.
        </p>
      </Modal.Prompt>
    </SectionContainer>
  );
};
