import { Loading } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import { Event, User } from '@livecontrol/scheduler/model';
import type { Location } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { EventValidator } from '@livecontrol/scheduler/validator';
import { DateTime, Duration } from 'luxon';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Form, SectionContainer } from '../../components';
import { Store } from '../../store';
import type { EventContext, MobileContext } from '../create-event';
import {
  ChooseEventType,
  ClientContact,
  DestinationsSection,
  EventConfirmation,
  EventContextStore,
  FooterSection,
  MobileContextStore
} from '../create-event';
import { EventDetails, MobileDetails } from './event-details';

const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e4e8f0;
  padding-bottom: 36px;
  .title-text {
    font-size: 28px;
    font-weight: 700;
    color: #2e384d;
  }
  .step-section {
    margin-left: 15px;
    .text {
      color: #8798ad;
      font-size: 28px;
      font--weight: 600;
    }
  }
`;

interface Alert {
  message: string;
  clear: () => void;
}

export const EditProduceMobileKitWorkflow = (): React.ReactElement => {
  const { uuid } = useParams<{ uuid: string }>();
  const account = Store.Account.useAccount();
  const { locations } = Scheduler.Location.useLocations(account);
  const isAdmin = Store.User.useIsAdmin();
  const { loading: userLoading, user } = Store.User.useUser(account.id);
  const { store, existingEvent }: EventContext = useContext<EventContext>(EventContextStore);

  const [userWithSettings, setUserWithSettings] = useState<User.admin | undefined>(undefined);

  useEffect(() => {
    const userAsAdmin: User.admin = user as User.admin;

    setUserWithSettings(userAsAdmin);
  }, [user]);

  const { store: mobileStore, existingEvent: existingMobileEvent }: MobileContext =
    useContext<MobileContext>(MobileContextStore);

  const currentLocation = useLocation();

  const locationsWithPermission = Store.User.useLocationWithPermission(
    User.LocationPermissionsNames.EventManagment,
    locations
  );

  const [activeStep, setActiveStep] = useState(
    existingEvent?.production === Event.Production.Simulated_Live && uuid
      ? 3
      : existingEvent?.production === Event.Production.Simulated_Live
      ? 2
      : 1
  );

  const [editEvent, { error, loading }] = Scheduler.Event.useEdit();

  const [alert, setAlert] = useState<Alert | undefined>();
  const [showSchedulingNotice, setShowSchedulingNotice] = useState(false);

  const clear = (): void => {
    setAlert(undefined);
  };

  const { billing } = Scheduler.Account.useBilling(account);
  const prompt = Modal.usePrompt();

  const confirmDurationChangeIfRequired = useCallback(
    async () =>
      new Promise<void>((resolve, reject): void => {
        if (
          billing &&
          existingEvent &&
          existingEvent.production === Event.Production.Produced &&
          store.eventType === EventValidator.ProductionType.PRODUCED &&
          existingEvent.start.diffNow('hours').hours < 24
        ) {
          const existingEventDuration = existingEvent.start.diff(existingEvent.end);

          const existingEventCreditsCharged =
            Event.Production.computeCreditsChargedForProducedEvent({
              duration: existingEventDuration,
              overTimeLimitCharge: billing.credits.over_time_limit_cost,
              overTimeLimitInMinutes: billing.credits.first_credit_time_limit
            });

          if (store.eventInfo.start && store.eventInfo.end) {
            const updatedEventDuration = store.eventInfo.start.diff(store.eventInfo.end);

            const updatedEventCreditsCharged =
              Event.Production.computeCreditsChargedForProducedEvent({
                duration: updatedEventDuration,
                overTimeLimitInMinutes: billing.credits.first_credit_time_limit,
                overTimeLimitCharge: billing.credits.over_time_limit_cost
              });

            const overTimeDuration = Duration.fromMillis(
              billing.credits.first_credit_time_limit * 60 * 1000
            );

            if (updatedEventCreditsCharged < existingEventCreditsCharged) {
              prompt.confirm({
                title: 'Change your event duration?',
                content: (
                  <div>
                    <p>
                      Heads up — as you know Produced events over{' '}
                      {overTimeDuration.toFormat("h'hr' m'm'")} are charged additional credits.
                      Since you’re within a 24 hour window of the event and have already been
                      assigned an operator, you will not receive a refund for the additional credits
                      you have already paid.
                    </p>
                    <p>Are you sure that you want to change the duration of your event?</p>
                  </div>
                ),
                okay: 'Change Duration',
                onAnswer(value) {
                  if (value) {
                    resolve();
                  } else {
                    reject(new Error('Cancelled.'));
                  }
                }
              });

              return;
            }
          }
        }

        resolve();
      }),
    [billing, existingEvent, prompt, store.eventInfo.end, store.eventInfo.start, store.eventType]
  );

  const onUpdate = useCallback(
    async (update?: Partial<Event>): Promise<void> => {
      if (existingEvent && existingMobileEvent) {
        if (
          !isAdmin &&
          store.eventInfo.start &&
          store.eventInfo.end &&
          store.eventInfo.start < DateTime.utc().plus({ days: account.leadTime }) &&
          (Math.abs(store.eventInfo.start.diff(existingEvent.start).as('hours')) > 2 ||
            Math.abs(store.eventInfo.end.diff(existingEvent.end).as('hours')) > 2)
        ) {
          setShowSchedulingNotice(true);
        } else {
          try {
            await confirmDurationChangeIfRequired();
          } catch {
            // Ignore.
            return;
          }

          // console.log('update: ', update);

          const result = await editEvent({
            event: existingEvent.id,
            eventLocation: locations?.find(
              (location: Location) => location.id === store.eventInfo.location
            ),
            account: account.id,
            replacement: {
              ...update,
              title: store.eventInfo.title,
              location: store.eventInfo.location,
              production: Event.Production.Produced,
              start: store.eventInfo.start,
              end: store.eventInfo.end,
              destinations: store.eventDestinations,
              operatorNotes: store.eventInfo.operatorNotes,
              description: store.eventInfo.description,
              backgroundURL: store.eventInfo.backgroundURL,
              simulatedLiveDetails: {
                assetId: store.simulatedLiveAssetDetails?.assetId,
                assetUrl: store.simulatedLiveAssetDetails?.muxAssetUrl,
                fileName: store.simulatedLiveFileUpload?.fileName
              },
              password: store.eventInfo.passwordSettings?.enabled
                ? store.eventInfo.passwordSettings.password
                : undefined,
              ...store.eventInfo.chatSettings,
              redirectUrl: store.eventInfo.redirectUrlSettings?.enabled
                ? store.eventInfo.redirectUrlSettings.url
                : undefined,
              cueSheetURL: store.eventInfo.cueSheetURL,
              zoomDetails: store.eventInfo.zoomDetails,
              clientContactDetails: store.clientContactDetails,
              technicalContactSettings: {
                enabled: store.eventInfo.technicalContactSettings?.enabled,
                contactId: store.eventInfo.technicalContactSettings?.enabled
                  ? store.eventInfo.technicalContactSettings.contactId
                  : 0
              },
              mobileKitData: { ...store.eventInfo.mobileKitData },
              useIntroAndOutroSlides: store.eventInfo.useIntroAndOutroSlides,
              receiveIsoRecordings: store.eventInfo.receiveIsoRecordings
            }
          });

          const resultForMobile = await editEvent({
            event: existingMobileEvent.id,
            eventLocation: locations?.find(
              (location: Location) => location.id === mobileStore.eventInfo.location
            ),
            account: account.id,
            replacement: {
              ...update,
              title: mobileStore.eventInfo.title,
              location: mobileStore.eventInfo.location,
              production: Event.Production.Mobile_Kit,
              start: mobileStore.eventInfo.start,
              end: mobileStore.eventInfo.end,
              destinations: store.eventDestinations,
              operatorNotes: store.eventInfo.operatorNotes,
              description: mobileStore.eventInfo.description,
              backgroundURL: store.eventInfo.backgroundURL,
              simulatedLiveDetails: {
                assetId: store.simulatedLiveAssetDetails?.assetId,
                assetUrl: store.simulatedLiveAssetDetails?.muxAssetUrl,
                fileName: store.simulatedLiveFileUpload?.fileName
              },
              password: store.eventInfo.passwordSettings?.enabled
                ? store.eventInfo.passwordSettings.password
                : undefined,
              ...store.eventInfo.chatSettings,
              redirectUrl: store.eventInfo.redirectUrlSettings?.enabled
                ? store.eventInfo.redirectUrlSettings.url
                : undefined,
              cueSheetURL: store.eventInfo.cueSheetURL,
              zoomDetails: store.eventInfo.zoomDetails,
              clientContactDetails: store.clientContactDetails,
              technicalContactSettings: {
                enabled: store.eventInfo.technicalContactSettings?.enabled,
                contactId: store.eventInfo.technicalContactSettings?.enabled
                  ? store.eventInfo.technicalContactSettings.contactId
                  : 0
              }
            }
          });

          if (result && resultForMobile) {
            setActiveStep(activeStep + 1);
          }
        }
      }
    },
    [
      existingEvent,
      existingMobileEvent,
      isAdmin,
      store,
      account.leadTime,
      account.id,
      editEvent,
      locations,
      mobileStore,
      confirmDurationChangeIfRequired,
      activeStep
    ]
  );

  const nextStep = useCallback((): void => {
    setActiveStep(activeStep + 1);
  }, [activeStep]);

  const previousStep = useCallback((): void => {
    setActiveStep(activeStep - 1);
  }, [activeStep]);

  const resetSteps = useCallback((): void => {
    setActiveStep(1);
  }, []);

  const handleCloseSchedulingNotice = useCallback((): void => {
    setShowSchedulingNotice(false);
    setActiveStep(1);
  }, []);

  const handleConfirmSchedulingNotice = useCallback(async (): Promise<void> => {
    if (existingEvent && existingMobileEvent) {
      const result = await editEvent({
        event: existingEvent.id,
        eventLocation: locations?.find(
          (location: Location) => location.id === store.eventInfo.location
        ),
        account: account.id,
        replacement: {
          title: store.eventInfo.title,
          location: store.eventInfo.location,
          production: Event.Production.Produced,
          start: store.eventInfo.start,
          end: store.eventInfo.end,
          destinations: store.eventDestinations,
          operatorNotes: store.eventInfo.operatorNotes,
          description: store.eventInfo.description,
          backgroundURL: store.eventInfo.backgroundURL,
          simulatedLiveDetails: {
            assetId: store.simulatedLiveAssetDetails?.assetId,
            assetUrl: store.simulatedLiveAssetDetails?.muxAssetUrl,
            fileName: store.simulatedLiveFileUpload?.fileName
          },
          password: store.eventInfo.passwordSettings?.enabled
            ? store.eventInfo.passwordSettings.password
            : undefined,
          ...store.eventInfo.chatSettings,
          redirectUrl: store.eventInfo.redirectUrlSettings?.enabled
            ? store.eventInfo.redirectUrlSettings.url
            : undefined,
          cueSheetURL: store.eventInfo.cueSheetURL,
          zoomDetails: store.eventInfo.zoomDetails,
          clientContactDetails: store.clientContactDetails,
          technicalContactSettings: {
            enabled: store.eventInfo.technicalContactSettings?.enabled,
            contactId: store.eventInfo.technicalContactSettings?.enabled
              ? store.eventInfo.technicalContactSettings.contactId
              : 0
          },
          mobileKitData: { ...store.eventInfo.mobileKitData },
          useIntroAndOutroSlides: store.eventInfo.useIntroAndOutroSlides,
          receiveIsoRecordings: store.eventInfo.receiveIsoRecordings
        }
      });

      const resultForMobile = await editEvent({
        event: existingMobileEvent.id,
        eventLocation: locations?.find(
          (location: Location) => location.id === mobileStore.eventInfo.location
        ),
        account: account.id,
        replacement: {
          title: mobileStore.eventInfo.title,
          location: mobileStore.eventInfo.location,
          production: Event.Production.Mobile_Kit,
          start: mobileStore.eventInfo.start,
          end: mobileStore.eventInfo.end,
          destinations: store.eventDestinations,
          operatorNotes: store.eventInfo.operatorNotes,
          description: mobileStore.eventInfo.description,
          backgroundURL: store.eventInfo.backgroundURL,
          simulatedLiveDetails: {
            assetId: store.simulatedLiveAssetDetails?.assetId,
            assetUrl: store.simulatedLiveAssetDetails?.muxAssetUrl,
            fileName: store.simulatedLiveFileUpload?.fileName
          },
          password: store.eventInfo.passwordSettings?.enabled
            ? store.eventInfo.passwordSettings.password
            : undefined,
          ...store.eventInfo.chatSettings,
          redirectUrl: store.eventInfo.redirectUrlSettings?.enabled
            ? store.eventInfo.redirectUrlSettings.url
            : undefined,
          cueSheetURL: store.eventInfo.cueSheetURL,
          zoomDetails: store.eventInfo.zoomDetails,
          clientContactDetails: store.clientContactDetails,
          technicalContactSettings: {
            enabled: store.eventInfo.technicalContactSettings?.enabled,
            contactId: store.eventInfo.technicalContactSettings?.enabled
              ? store.eventInfo.technicalContactSettings.contactId
              : 0
          }
        }
      });

      if (result && resultForMobile) {
        setShowSchedulingNotice(false);
        setActiveStep(activeStep + 1);
      }
    }
  }, [
    existingEvent,
    editEvent,
    locations,
    account.id,
    store,
    activeStep,
    mobileStore,
    existingMobileEvent
  ]);

  const onClientContactDetailsUpdate = useCallback(
    (value: Event['clientContactDetails']) => {
      if (value) {
        store.setClientContactDetails(value);
        void onUpdate();
      }
    },
    [onUpdate, store]
  );

  const { destinations = [] } = Scheduler.Destination.useDestinations(account, false);

  const steps = useMemo(
    (): React.ReactElement[] => [
      <ChooseEventType key='event-type' onContinue={nextStep} isAdmin={isAdmin} />,
      ...(userWithSettings
        ? [
            <EventDetails
              key='event-details'
              onContinue={nextStep}
              onBack={previousStep}
              activeStep={activeStep}
              locations={locationsWithPermission}
              accountSettings={userWithSettings}
            />,
            <MobileDetails
              key='event-details'
              onContinue={nextStep}
              onBack={previousStep}
              title='Mobile Event Details'
              minDate={store.eventInfo.end}
              locations={locationsWithPermission}
            />
          ]
        : [<Loading.Delay key='element-loading' />]),
      ...((store.eventType === EventValidator.ProductionType.PRODUCED ||
        store.eventType === EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT ||
        store.eventType === EventValidator.ProductionType.MOBILE_KIT ||
        store.eventType === EventValidator.ProductionType.STATIC) &&
      userWithSettings?.userGlobalSettings.clientContactInfo
        ? [
            <ClientContact
              skippable
              key='client-contact'
              onUpdate={onClientContactDetailsUpdate}
              onBack={previousStep}
              onSkip={nextStep}
              initialValue={existingEvent?.clientContactDetails}
            />
          ]
        : []),

      ...(store.eventType !== EventValidator.ProductionType.CLIENT_BOOKING
        ? [
            <Fragment key='event-destinations'>
              <Form.Alert show={!!alert} onClose={alert?.clear}>
                {alert?.message}
              </Form.Alert>
              <DestinationsSection destinations={destinations} />
              <FooterSection>
                <Button
                  className='btn custom-primary-btn btn btn-primary btn-lg mt-32px '
                  onClick={onUpdate}
                >
                  Finish
                </Button>
                <Link
                  className='mt-32px text-secondary custom-primary-link'
                  to={currentLocation.pathname}
                  onClick={previousStep}
                >
                  Go Back
                </Link>
              </FooterSection>
            </Fragment>
          ]
        : []),
      <EventConfirmation
        key='event-confirmation'
        newEventId={existingEvent?.id}
        isEditing
        onReset={resetSteps}
        mobileKitEventId={existingMobileEvent?.id}
      />
    ],
    [
      nextStep,
      isAdmin,
      previousStep,
      activeStep,
      locationsWithPermission,
      userWithSettings,
      store.eventInfo.end,
      store.eventType,
      onClientContactDetailsUpdate,
      existingEvent?.clientContactDetails,
      existingEvent?.id,
      alert,
      destinations,
      onUpdate,
      currentLocation.pathname,
      resetSteps,
      existingMobileEvent?.id
    ]
  );

  useEffect((): void => {
    if (error) {
      setAlert({
        message: error.message,
        clear
      });
    }
  }, [error]);

  if (!existingEvent || loading || userLoading) {
    return <Loading.Delay />;
  }

  const stepsCount = steps.length - 1;

  const onLastStep = activeStep === stepsCount;

  const isEditingProducedAndMobile = existingEvent.linkedEventId;

  return (
    <SectionContainer className='col-12'>
      <Header>
        {onLastStep ? (
          <div className='title-text big d-flex flex-row'>
            <div className='title-text'>
              Your event has been updated!{' '}
              <span className='title-emoji' role='img'>
                <img style={{ width: 20 }} src='assets/icons/icon-fiesta.jpeg' alt='emoji fiesta' />
              </span>
            </div>
          </div>
        ) : (
          <div className='title-text'>
            {isEditingProducedAndMobile
              ? 'Edit Your Event'
              : activeStep === 0
              ? `Edit Your ${existingEvent.type} Event`
              : `Edit Your ${store.eventType ?? 'Event'}`}
          </div>
        )}
      </Header>
      {steps[activeStep]}
      <Modal.Prompt
        show={showSchedulingNotice}
        title='Scheduling Notice'
        okay='Schedule'
        cancel='Go Back'
        onCancel={handleCloseSchedulingNotice}
        onOkay={handleConfirmSchedulingNotice}
      >
        <p className='text-center'>
          You are trying to modify the time of an operated event that starts in less than{' '}
          {account.leadTime} day(s).
        </p>
        <p className='text-center'>
          Based on your lead time restriction, you can only modify the times of a produced event{' '}
          {account.leadTime} or more days in advance. Proceeding will make this event a static
          service.
        </p>
      </Modal.Prompt>
    </SectionContainer>
  );
};
