import { Theme } from '@livecontrol/core-ui';
import { useMemo, useState } from 'react';
import type { PropsWithChildren, ReactElement } from 'react';
import { Context } from './context';

const loaders: Record<Theme.Name, Theme.Provider.Loader> = {
  base: {
    component: async () => import(/* webpackChunkName: "base-theme" */ './base'),
    variant: 'light'
  }
};

export const Provider = ({
  children,
  defaultTheme = 'base',
  ...rest
}: Provider.Props): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const [theme, setTheme] = useState(loaders[defaultTheme] ? defaultTheme : 'base');
  const { variant } = loaders[theme];

  const themeValue = useMemo(
    () => ({
      setTheme
    }),
    [setTheme]
  );

  return (
    <Context.Provider value={themeValue}>
      <Theme.Provider loaders={loaders} {...rest}>
        <div className={`x-${variant}-theme`}>{children}</div>
      </Theme.Provider>
    </Context.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Provider {
  export type Props = PropsWithChildren<{ defaultTheme?: Theme.Name; themeName?: Theme.Name }>;
}
