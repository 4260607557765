import { Device } from '@livecontrol/core-ui';
import { Account } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Form } from '../../../components';
import { Store } from '../../../store';

const AboutContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 916px;
  justify-content: space-between;
  padding-bottom: 34px;
  border-bottom: 1px solid #d5daea;
  padding-top: 24px;

  .text-section {
    min-width: 300px;
    width: 300px;
    margin-bottom: 10px;
    margin-right: 26px;

    .title {
      font-weight: 600;
      font-size: 15px;
      color: #2e384d;
      margin-bottom: 2px;
    }
    .description {
      font-weight: 600;
      font-size: 13px;
      color: #9c9c9c;
      word-wrap: break-word;
    }
  }

  form {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .form-section {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
    width: 100%;
    max-width: 584px;
    flex-direction: column;

    .form-control {
      width: 100%;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 14px 10px 14px 20px;
      border: solid 1.5px #cbcbcb;
      font-size: 15px;
      font-weight: 500;
      background-color: #ffffff;
      min-height: 46px;
      min-width: 300px;
    }

    button[type='submit'].btn.btn-primary {
      min-width: 114px !important;
      padding: 13px 20px;
      margin-left: 20px !important;
      color: #ffffff;
      background-color: #2e5bff;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 600;
    }

    .bottom-form-section {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 14px;
    }
  }

  .text-grey {
    color: #9c9c9c;
  }

  .x-${Device.MOBILE} &, .x-${Device.TABLET} & {
    flex-direction: column;
    .text-section {
      width: 100%;
      text-align: center;
      margin-right: 0px;
    }
    .form-section {
      justify-content: center;
    }
    form {
      justify-content: center;
    }
  }
`;

export const AboutSection = ({ webplayer }: { webplayer: Account.WebPlayer }): ReactElement => {
  const [saved, setSaved] = useState(false);

  const [about, setAbout] = useState(webplayer.about ?? '');
  const account = Store.Account.useAccount();

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (saved) {
      setSaved(true);

      timeout = setTimeout(() => {
        setSaved(false);
      }, 3000);
    }

    return (): void => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [saved]);

  const [editWebplayer, { loading }] = Scheduler.Account.WebPlayer.useEdit();

  const { ABOUT_MAX_LENGTH } = Account.WebPlayer.Constraints;

  return (
    <AboutContainer>
      <div className='text-section'>
        <div className='title mb-4px'>About</div>
        <p className='description'>This About section will appear on your Profile Page.</p>
      </div>
      <Form
        onSubmit={(e): void => {
          e.preventDefault();

          void editWebplayer(account.id, {
            about
          }).then(() => {
            setSaved(true);
          });
        }}
      >
        <Form.Descriptor title='' className='form-section'>
          <Form.Control
            as='textarea'
            style={{ height: 108 }}
            value={about}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              setAbout(e.target.value);
            }}
          />
          <div className='bottom-form-section'>
            <div
              className={cx('font-size-14px', {
                'text-secondary': about.length < ABOUT_MAX_LENGTH,
                'text-danger': about.length > ABOUT_MAX_LENGTH
              })}
            >
              {about.length}/{ABOUT_MAX_LENGTH}
            </div>
            <div className='flex-grow-1' />
            <div className='position-relative'>
              <Form.Submit
                loading={loading}
                disabled={about.length > ABOUT_MAX_LENGTH || about === webplayer.about}
                style={{ minWidth: 150 }}
              >
                Save
              </Form.Submit>
              {saved && (
                <span
                  className='text-success font-weight-bold text-center font-size-12px'
                  style={{ position: 'absolute', left: 0, right: 0, top: 'calc(100% + 4px)' }}
                >
                  <i className='mr-8px fa fa-check' />
                  Saved
                </span>
              )}
            </div>
          </div>
        </Form.Descriptor>
      </Form>
    </AboutContainer>
  );
};
