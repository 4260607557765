import { gql, useMutation } from '@apollo/client';
import { Bool, Str } from '@livecontrol/core-utils';
import type { Account } from '@livecontrol/scheduler/model';
import { assert } from '@sindresorhus/is';
import { useCallback } from 'react';
import type { MutationResult } from '../../graphql';
import type { SlugCheckRecord } from './types';

interface TVariables {
  slug: string;
  endpointToCheck: string;
}

interface TData {
  slugCheck: SlugCheckRecord;
}

const MUTATION = gql`
  mutation ($slug: String!, $endpointToCheck: String) {
    slugCheck(slug: $slug, endpointToCheck: $endpointToCheck) {
      slug_valid
    }
  }
`;

export const useSlugCheck = (): [
  (slug: string, endpointToCheck: string) => Promise<Account.SlugCheck>,
  MutationResult<TData | null>
] => {
  const [fn, result] = useMutation<TData, TVariables>(MUTATION);

  return [
    useCallback(
      async (slug: string, endpointToCheck: string): Promise<Account.SlugCheck> => {
        if (slug === endpointToCheck || !endpointToCheck) {
          return { slug_valid: true };
        }

        const canonicalSlug = Str.normalize(slug) ?? '';

        assert.string(canonicalSlug);

        const canonicalEndpointToCheck = Str.normalize(endpointToCheck) ?? '';

        assert.string(canonicalEndpointToCheck);

        const mutation = await fn({
          variables: {
            slug: canonicalSlug,
            endpointToCheck: canonicalEndpointToCheck
          }
        });

        const slugCheckResult = Bool.normalize(mutation.data?.slugCheck.slug_valid);

        assert.boolean(slugCheckResult);

        const response = mutation.data?.slugCheck;

        assert.plainObject(response);

        return response;
      },
      [fn]
    ),
    result
  ];
};
