import { Button } from '@livecontrol/core-ui';
import { Scheduler } from '@livecontrol/scheduler/store';
import * as Sentry from '@sentry/react';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import type { PaymentMethodResult, Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import type { HTMLAttributes, ReactElement } from 'react';

import { Panel } from '../../../../components';
import { store, Store } from '../../../../store';

const StripeLinkButton = ({
  onCreatePaymentMethod
}: {
  onCreatePaymentMethod: (addCardPromise: PaymentMethodResult) => void;
}): ReactElement => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    const paymentMethod = await stripe!.createPaymentMethod({
      type: 'card',
      card: elements!.getElement(CardElement)!
    });

    onCreatePaymentMethod(paymentMethod);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement
        options={{
          style: {
            base: {
              'fontSize': '16px',
              'color': '#424770',
              '::placeholder': {
                color: '#aab7c4'
              }
            },
            invalid: {
              color: '#9e2146'
            }
          }
        }}
      />
      <Button type='submit' className='mt-32px' disabled={!stripe}>
        Add Card
      </Button>
    </form>
  );
};

export const AddCard = (_props: HTMLAttributes<HTMLDivElement>): ReactElement => {
  const account = Store.Account.useAccount();

  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();

  const [linkStatus, setLinkStatus] = useState<'error' | 'init' | 'linked' | 'linking' | 'loaded'>(
    'init'
  );

  useEffect(() => {
    setStripePromise(loadStripe(store.getState().environment.STRIPE_PUBLISHABLE_KEY));
    setLinkStatus('loaded');
  }, []);

  const [mutateLinkStripePaymentMethod] = Scheduler.Account.Stripe.useLinkStripePaymentMethod();

  const handleCreatePaymentMethod = async (
    paymentMethodResult: PaymentMethodResult
  ): Promise<void> => {
    setLinkStatus('linking');

    const { error, paymentMethod } = paymentMethodResult;

    if (error) {
      Sentry.captureException(error);
      setLinkStatus('error');

      return;
    }

    const { ok } = await mutateLinkStripePaymentMethod(account.id, paymentMethod!.id);

    setLinkStatus(ok ? 'linked' : 'error');
  };

  return (
    <Panel>
      <div className='d-grid row-gap-28px'>
        <h1>Card Payments</h1>
        {linkStatus === 'init' && <p>Loading...</p>}
        {linkStatus === 'error' && <p>There was an error linking your card, please try again.</p>}

        {linkStatus === 'linking' && <p>Linking...</p>}

        {linkStatus === 'linked' && <p>Your card has been successfully linked.</p>}

        {linkStatus === 'loaded' && (
          <Elements stripe={stripePromise!}>
            <StripeLinkButton onCreatePaymentMethod={handleCreatePaymentMethod} />
          </Elements>
        )}
      </div>
    </Panel>
  );
};
