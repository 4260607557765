import { Form, Tooltip } from '@livecontrol/core-ui';
import type { Account } from '@livecontrol/scheduler/model';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { PermissionsDropdown } from '../account-permissions';

const ContentGlobalSettings = styled.div`
  display: flex;
  flex-direction: row;

  .setting {
    align-items: center;
    width: 140px;
    height: 122px;
    padding: 11px 20px;
    font-weight: bold;
    color: var(--dark);
    justify-content: space-between;
  }

  .bg-light-red {
    background-color: #ffe6e6;
  }

  .bg-light-blue {
    background-color: #f0f6ff;
  }

  .bg-red {
    background-color: #ff505f;
  }

  .bg-purplue {
    background-color: #7b61ff;
  }

  .bg-green {
    background-color: #37b34a;
  }

  .bg-info {
    background-color: #00c1c1;
  }

  @media (max-width: 1120px) {
    flex-direction: column;

    .setting {
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
`;

interface Props {
  userSelected: Account.SubUser | undefined;
  setUserSelected: React.Dispatch<React.SetStateAction<Account.SubUser | undefined>>;
  usersAvailable: Account.SubUser[];
}

export const GlobalSettings = ({
  userSelected,
  setUserSelected,
  usersAvailable
}: Props): ReactElement => (
  <ContentGlobalSettings>
    <div className='flex-column text-dark font-weight-bold' style={{ width: 300 }}>
      <h1 className='font-size-28px'>Global Settings</h1>
      <div className='font-size-12px mb-28px'>Settings applied across your account</div>
      <PermissionsDropdown
        isLocation='user'
        valueSelected={userSelected}
        setValueUser={setUserSelected}
        subusers={usersAvailable}
      />
    </div>

    <div className='d-flex flex-row settings'>
      <div className='d-flex flex-column bg-light-red setting font-weight-bold'>
        <div className='d-flex flex-column'>
          <span className='badge rounded-pill font-size-9px bg-red text-white mb-8px'>
            Administration
          </span>
          <div className='font-size-12px text-center'>
            Admin Access
            <Tooltip
              backgroundColor='#0C3192'
              tip={
                <div>
                  <div className='font-weight-bold font-size-11px'>
                    Access to all permissions including: manage Users, update Web Player slug, view
                    Activity Log, and edit Organization Info.
                  </div>
                </div>
              }
            >
              <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
            </Tooltip>
          </div>
        </div>
        <Form.Group
          name='account.admin'
          className='mb-12px'
          error={false}
          label={false}
          controlProps={{
            type: 'checkbox'
          }}
          control={Form.Switch}
        />
      </div>
      <div className='d-flex flex-column setting font-weight-bold'>
        <div className='d-flex flex-column'>
          <span className='badge rounded-pill bg-purplue font-size-9px text-white mb-8px'>
            Stream Settings
          </span>
          <div className='font-size-12px text-center'>
            Destinations & Web Player
            <Tooltip
              backgroundColor='#0C3192'
              tip={
                <div>
                  <div className='font-weight-bold font-size-11px'>
                    Access to add and manage simulcasting destinations. Customize Web Player (except
                    Web Player slug).
                  </div>
                </div>
              }
            >
              <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
            </Tooltip>
          </div>
        </div>
        <Form.Group
          name='account.webplayer'
          className='mb-12px'
          error={false}
          label={false}
          controlProps={{
            type: 'checkbox'
          }}
          control={Form.Switch}
        />
      </div>
      <div className='d-flex flex-column bg-light-blue setting  font-weight-bold'>
        <div>
          <span className='badge rounded-pill font-size-9px bg-green text-white mb-8px'>
            Subscription
          </span>
          <div className='font-size-12px text-center'>
            Billing
            <Tooltip
              backgroundColor='#0C3192'
              tip={
                <div>
                  <div className='font-weight-bold font-size-11px'>
                    Access to view invoices, change payment methods, purchase credits, and view
                    Activity Log.
                  </div>
                </div>
              }
            >
              <img className='ml-4px' src='assets/icons/Info-blue.svg' alt='info' />
            </Tooltip>
          </div>
        </div>
        <Form.Group
          name='account.billing'
          className='mb-12px'
          error={false}
          label={false}
          controlProps={{
            type: 'checkbox'
          }}
          control={Form.Switch}
        />
      </div>
    </div>
  </ContentGlobalSettings>
);
