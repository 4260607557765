import cx from 'classnames';
import React from 'react';
import { Panel } from '../../components';

export const Callout = ({
  className,
  children
}: React.PropsWithChildren<{ className?: string }>): React.ReactElement => (
  <div
    className={cx(
      'px-16px',
      'py-8px',
      'mb-24px',
      'border',
      'border-tint-ø2',
      'bg-tint-ø3',
      className
    )}
  >
    {children}
  </div>
);

export const Section = ({
  className,
  name,
  children
}: React.PropsWithChildren<{ className?: string; name: string }>): React.ReactElement => (
  <section className={cx('mb-32px', className)}>
    <h1>{name}</h1>
    <Panel>{children}</Panel>
  </section>
);
