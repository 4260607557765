import { AspectRatio } from '@livecontrol/core-ui';
import cx from 'classnames';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { Helper } from './helper';

const Container = styled.div`
  position: relative;
  border-radius: 5%;
  border: 2px solid transparent;

  &.drop-active {
    border: 2px solid var(--primary);
  }
  &.drop-accept {
    border: 2px solid var(--primary);
  }
  &.drop-reject {
    border: 2px solid var(--danger);
  }
`;

const StyledAspect = styled(AspectRatio)`
  border-radius: 5%;
  background-color: rgba(0, 0, 0, 0.02);
  overflow: hidden;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    > .placeholder {
      transition: transform 0.3s;
    }
    &:hover {
      > .placeholder {
        transform: scale(1.05);
      }
    }
  }

  &.altBorder {
    border: solid 2px #2e5bff;
  }
`;

const Overlay = {
  container: styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 500ms ease-in-out;

    &:hover,
    &.uploading {
      opacity: 1;
    }
  `,
  content: styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 5%;

    &:hover + div {
      opacity: 0.5;
    }
  `,
  close: styled.div`
    &,
    > span {
      position: absolute;
      top: -5px;
      right: -5px;
    }
    z-index: 2;
    &:hover ~ div {
      opacity: 0.5;
    }
  `
};

const AltPreview = styled.div`
  .altBg {
    width: 100%;
    height: 100%;
    background-color: #f7fafc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .previewIconSection {
    width: 54px;
    height: 54px;
    background: rgba(46, 91, 255, 0.1);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    .iconSection {
      width: 40px;
      height: 40px;
      background-size: cover;
      background-position: center;
      background-image: url(/assets/icons/Upload-cloud-blue.svg);
    }
  }
  .displayText {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #2e384d;
  }
`;

interface Props {
  src?: string;
  aspectRatio?: AspectRatio.Props['ratio'];
  onUpdate?: (val: { dataSrc: string; file: File }) => void;
  onRemove?: () => void;
  isUploading?: boolean;
  style?: React.CSSProperties;
  className?: string;
  name?: string;
  useAltStyle?: boolean;
  displayPreviewText?: string;
}

export const PreviewContainer = ({
  src,
  aspectRatio = 1,
  onUpdate,
  onRemove,
  isUploading,
  name = 'Image',
  style,
  className,
  useAltStyle,
  displayPreviewText
}: Props): React.ReactElement => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach(async (file: File) => {
        if (!file.type.startsWith('image')) {
          return;
        }

        const blob = await Helper.fileToBlob(file);

        if (blob) {
          onUpdate?.({
            dataSrc: blob,
            file
          });
        }
      });
    },
    [onUpdate]
  );

  const onRemoveClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      e.stopPropagation();
      onRemove?.();
    },
    [onRemove]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop
  });

  return (
    <Container
      {...getRootProps()}
      style={{ ...style, pointerEvents: isUploading ? 'none' : 'initial' }}
      className={cx(className, 'cursor-pointer', {
        'drop-active': isDragActive,
        'drop-accept': isDragAccept,
        'drop-reject': isDragReject
      })}
    >
      <StyledAspect ratio={aspectRatio} className={`${useAltStyle ? 'altBorder' : ''}`}>
        {src ? (
          <img
            src={src}
            alt='alternate'
            style={{
              maxWidth: '100%'
            }}
          />
        ) : useAltStyle ? (
          <AltPreview>
            <div className='altBg'>
              <div className='previewIconSection'>
                <div className='iconSection' />
              </div>
              <div className='displayText mt-16px'>{displayPreviewText}</div>
            </div>
          </AltPreview>
        ) : (
          <div className='text-center text-tertiary placeholder'>
            <i className='far fa-image fa-3x' />
            <div className='mt-8px font-size-12px'>Upload {name}</div>
          </div>
        )}
      </StyledAspect>
      <Overlay.container
        className={cx({
          'd-none': !src && !isUploading,
          'uploading': isUploading
        })}
      >
        <Overlay.close className={cx({ 'd-none': !src || isUploading })} onClick={onRemoveClick}>
          <span>
            <i className='fas fa-circle font-size-24px' style={{ color: 'white' }} />
          </span>
          <span>
            <i className='far fa-times-circle font-size-24px' style={{ color: 'black' }} />
          </span>
        </Overlay.close>
        <Overlay.content className='overflow-hidden d-flex align-items-center justify-content-center'>
          <div className='text-center text-white' id='upload'>
            <i className='far fa-image fa-3x' />
            <div className='mt-8px font-size-12px'>
              {isUploading ? 'Uploading...' : `Change ${name}`}
            </div>
            <input {...getInputProps()} multiple={false} type='file' accept='image/*' />
          </div>
        </Overlay.content>
      </Overlay.container>
    </Container>
  );
};
