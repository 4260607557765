import { gql, useMutation } from '@apollo/client';
import { Bool, Num, Str } from '@livecontrol/core-utils';
import { Account } from '@livecontrol/scheduler/model';
import { assert } from '@sindresorhus/is';
import { useCallback } from 'react';
import type { MutationResult } from '../graphql';

interface TVariables {
  userId: number;
  price: number;
}

interface TData {
  payClientBookingInvoice?: {
    id?: string;
    paid: boolean;
    lines: {
      data: { quantity: number }[];
    };
  };
}

const MUTATION = gql`
  mutation PayClientBookingInvoice($userId: Float!, $price: Float!) {
    payClientBookingInvoice(userId: $userId, price: $price) {
      id
      paid
      lines {
        data {
          quantity
        }
      }
    }
  }
`;

export const useClientBookingPayment = (): [
  (account: Account.Like, price: number) => Promise<Account.Purchase>,
  MutationResult
] => {
  const [fn, result] = useMutation<TData, TVariables>(MUTATION);

  return [
    useCallback(
      async (account: Account.Like, price: number): Promise<Account.Purchase> => {
        const canonicalId = Account.toId(account);
        const normalizePrice = Num.normalize(price, { positive: true });

        assert.number(canonicalId);
        assert.number(normalizePrice);

        const mutation = await fn({
          variables: {
            userId: canonicalId,
            price: normalizePrice
          }
        });

        const userPurchase: Account.Purchase = {
          paid: Bool.normalize(mutation.data?.payClientBookingInvoice?.paid),
          amount:
            Num.normalize(mutation.data?.payClientBookingInvoice?.lines.data[0].quantity, {
              positive: true
            }) ?? 0
        };

        if (mutation.data?.payClientBookingInvoice?.id) {
          userPurchase.stripeInvoiceId = Str.normalize(mutation.data.payClientBookingInvoice.id);
        }

        assert.plainObject(userPurchase);

        return userPurchase;
      },
      [fn]
    ),
    result
  ];
};
