import type { ApolloError, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Obj, Str } from '@livecontrol/core-utils';
import { Account, Destination } from '@livecontrol/scheduler/model';
import { useCallback, useState } from 'react';
import { Errors } from '../graphql';
import type { MutationResult } from '../graphql';
import * as Dbx from './dbx';

type TVariables = Pick<Destination, 'id' | 'name' | 'rtmp'> & {
  clientId: string;
};

interface TData {
  editCustomIntegration: Dbx.Record;
}

type Args = Pick<Destination, 'name' | 'rtmp'> & {
  account: Account.Like;
  destination: Destination.Like;
};

const MUTATION = gql`
  mutation EditCustomIntegration(
    $clientId: String!
    $id: String!
    $name: String!
    $rtmpDestination: String!
    $streamKey: String!
  ) {
    editCustomIntegration(
      clientId: $clientId
      integrationId: $id
      integration: { name: $name, rtmp_destination: $rtmpDestination, rtmp_stream_key: $streamKey }
    ) {
      ...StandardDestinationResponse
    }
  }
  ${Dbx.StandardDestinationResponse}
`;

export const useEditRTMP = (): [
  (args: Args) => Promise<Destination | undefined>,
  MutationResult<Destination, 'destination'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [destination, setDestination] = useState<Destination | undefined>();

  return [
    useCallback(
      async (args: Args): Promise<Destination | undefined> => {
        let destination_: Destination | undefined;

        try {
          // Parse the input arguments
          const input = {
            clientId: Account.toId(args.account)?.toString(),
            id: Destination.toId(args.destination),
            name: Str.normalize(args.name),
            rtmpDestination: Str.normalize(args.rtmp?.destination),
            streamKey: Str.normalize(args.rtmp?.streamKey)
          };

          if (!Obj.isHydrated(input)) {
            throw Errors.badRequest();
          }

          // Execute the GraphQL mutation
          const response = await mutation({
            variables: input
          })
            .then(({ data }: FetchResult<TData>) => data?.editCustomIntegration)
            .catch((_error: ApolloError) => {
              throw Errors.serverError();
            });

          // Parse the server response
          if (response === undefined) {
            throw new Error('Empty response received!');
          }

          destination_ = Dbx.normalize(response);

          if (!destination_) {
            throw Errors.serverError();
          }
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setDestination(destination_);

        return destination_;
      },
      [mutation, setDestination, setError]
    ),
    {
      destination,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
