import { Device, Theme } from '@livecontrol/core-ui';
import { Str } from '@livecontrol/core-utils';
import type { Account, Location } from '@livecontrol/scheduler/model';
import type { Dispatch, SetStateAction } from 'react';
import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components';

interface Props {
  isLocation: string;
  locations?: Location[];
  subusers?: Account.SubUser[];
  setValue?: Dispatch<SetStateAction<Account.SubUser | Location | undefined>>;
  setValueUser?: Dispatch<SetStateAction<Account.SubUser | undefined>>;
  setValueLocation?: Dispatch<SetStateAction< Location | undefined>>;
  valueSelected?: Account.SubUser | Location | undefined;
  truncate?: boolean;
}

const Toggle = styled(Dropdown.Toggle)`
  background-color: white !important;
  color: var(--dark) !important;
  border: 1.5px solid var(--dark);
  border-radius: 4px;
  margin: 0 !important;

  background-image: url(assets/icons/arrow.svg);
  background-repeat: no-repeat;
  background-size: 14px auto;
  background-position: right 14px center;

  &::after {
    content: none;
  }

  div {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 50px;
  }
`;

const Menu = styled(Dropdown.Menu)`
  margin: 0px !important;
`;

interface valueToDropdown {
  name: string;
}

export const PermissionsDropdown = ({
  locations,
  isLocation,
  subusers,
  setValue,
  setValueUser,
  setValueLocation,
  truncate,
  valueSelected
}: Props): React.ReactElement => {
  const [valueToShow, setValueToShow] = useState<valueToDropdown>();
  const viewport = Theme.useViewport();

  const identifyParameter = (): void => {
    if (valueSelected) {
      if ('name' in valueSelected) {
        setValueToShow({ name: valueSelected.name });
      } else if ('firstName' in valueSelected) {
        setValueToShow({ name: `${valueSelected.firstName} ${valueSelected.lastName}` });
      }
    }
  };

  useEffect(() => {
    identifyParameter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueSelected]);

  return (
    <Dropdown>
      <Toggle>
        {valueToShow && (
          <div className='d-flex align-items-center font-weight-bold'>
            {truncate ? Str.truncate(valueToShow.name, { length: 30 }) : Device.isMobile(viewport)
              ? Str.truncate(valueToShow.name, { length: 30 })
              : valueToShow.name}
          </div>
        )}
      </Toggle>

      <Menu>
        {isLocation === 'location' &&
          locations?.map((location) => (
            <Dropdown.Item
              key={location.id}
              onClick={(): void => {
                setValue?.(location);
                setValueLocation?.(location);
              }}
            >
              {location.name}
            </Dropdown.Item>
          ))}
        {isLocation === 'user' &&
          subusers?.map((subuser) => (
            <Dropdown.Item
              key={subuser.id}
              onClick={(): void => {
                setValue?.(subuser);
                setValueUser?.(subuser);
              }}
            >
              {subuser.firstName} {subuser.lastName}
            </Dropdown.Item>
          ))}
      </Menu>
    </Dropdown>
  );
};
