import { Loading } from '@livecontrol/core-ui';
import { useEffect } from 'react';
import type { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import { ZendeskAPI } from 'react-zendesk';
import { Store } from '../store';

export const Logout = (): ReactElement => {
  const token = Store.Token.useToken();

  useEffect(() => {
    Store.User.logout();
  }, []);

  ZendeskAPI('messenger', 'logoutUser');
  ZendeskAPI('messenger:set', 'zIndex', -9999999);
  ZendeskAPI('messenger', 'close');

  return token ? <Loading.Delay /> : <Redirect to='/home' />;
};
