import type { ApolloCache, ApolloError, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Str } from '@livecontrol/core-utils';
import { User } from '@livecontrol/scheduler/model';
import { Errors } from '@livecontrol/scheduler/store';
import type { MutationResult } from '@livecontrol/scheduler/store';
import { useCallback, useState } from 'react';
import * as Dbx from './dbx';

interface TVariables {
  input: Partial<{
    first_name: string;
    last_name: string;
    password: string;
    email: string;
    phone_number: string;
  }> & {
    id: number;
  };
}

interface TData {
  updateUser?: Dbx.Record;
}

type Args = Partial<{
  first: string;
  last: string;
  email?: string;
  phone: string;
  password?: string;
  landline?: string;
  landlineExtension?: string;
  preferredContactMethod?: string;
  additionalInformation?: string;
}> & {
  id: User.Like;
};

const MUTATION = gql`
  mutation EditUser($input: UpdateUser!) {
    updateUser(input: $input) {
      ...StandardUserResponse
    }
  }
  ${Dbx.StandardUserResponse}
`;

export const useEdit = (): [
  (args: Args) => Promise<User | undefined>,
  MutationResult<User, 'user'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [user, setUser] = useState<User | undefined>();

  return [
    useCallback(
      async (args: Args): Promise<User | undefined> => {
        let user_: User | undefined;

        try {
          // Parse the input arguments
          const id = User.toId(args.id);

          if (!id) {
            throw Errors.badRequest();
          }

          const variables = {
            input: {
              id,
              first_name: Str.normalize(args.first),
              last_name: Str.normalize(args.last),
              landline: Str.normalize(args.landline) ?? '',
              landline_extension: Str.normalize(args.landlineExtension) ?? '',
              additional_information: Str.normalize(args.additionalInformation) ?? '',
              preferred_contact_method: Str.normalize(args.preferredContactMethod),
              phone_number: Str.normalize(args.phone) ?? ''
            }
          };

          // Execute the GraphQL mutation
          const response = await mutation({
            variables,
            update(cache: ApolloCache<unknown>, { data: fetchData }: FetchResult<TData>): void {
              const identifyTypes = ['Account', 'Users', 'SubUsers'];

              identifyTypes.forEach((type) => {
                const identifyData = cache.identify({
                  id: fetchData?.updateUser?.id,
                  __typename: type
                });

                if (identifyData) {
                  cache.modify({
                    id: identifyData,
                    fields: {
                      first_name(): string {
                        return fetchData?.updateUser?.first_name ?? '';
                      },
                      last_name(): string {
                        return fetchData?.updateUser?.last_name ?? '';
                      },
                      phone_number(): string {
                        return fetchData?.updateUser?.phone_number ?? '';
                      },
                      preferred_contact_method(): string {
                        return fetchData?.updateUser?.preferred_contact_method ?? '';
                      }
                    }
                  });
                }
              });
            }
          })
            .then(({ data }: FetchResult<TData>) => data?.updateUser)
            .catch((_error: ApolloError) => {
              throw Errors.serverError();
            });

          // Parse the server response
          user_ = Dbx.normalize(response);

          if (!user_) {
            throw Errors.serverError();
          }
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setUser(user_);

        return user_;
      },
      [mutation, setError, setUser]
    ),
    {
      user,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
