import { Matrix } from '@livecontrol/scheduler/components';
import type { Log } from '@livecontrol/scheduler/model';
import { DateTime } from 'luxon';
import type { Zone } from 'luxon';
import React from 'react';

import S = Matrix.Stack;
import Card = S.Card;

export const Stack = ({ logs, timezone }: { logs: Log[]; timezone: Zone }): React.ReactElement => {
  return(
  <S>
    {logs.map(
      (log, index): React.ReactElement => (
        // eslint-disable-next-line react/no-array-index-key
        <Card key={index}>
          <Card.Body>
            <Card.Row label='Action'>{log.action}</Card.Row>
            <Card.Row label='Message'>{log.message}</Card.Row>
            <Card.Row label='Performed by'>{log.performedBy}</Card.Row>
            <Card.Row label='Date'>
              {log.date?.setZone(timezone).toLocaleString(DateTime.DATE_SHORT)}{' '}
              {log.date?.setZone(timezone).toLocaleString(DateTime.TIME_SIMPLE)}
            </Card.Row>
            <Card.Row label='Credits Changes'>{log.credits_used}</Card.Row>
            <Card.Row label='Balance'>{log.current_balance}</Card.Row>
          </Card.Body>
        </Card>
      )
    )}
  </S>
  )
};
