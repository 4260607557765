import { Device, Theme } from '@livecontrol/core-ui';
import type { Destination, Event } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import cx from 'classnames';
import { DateTime } from 'luxon';
import { useState } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import shallow from 'zustand/shallow';
import { Store } from '../../../store';
import { useStore } from '../store';
import { Destinations } from './destinations';
import { EventOptionsButton } from './event-options-dropdown';

const EventDataRow = styled.div`
  display: flex;
  width: 100%;
  padding: 14px 28px 14px 14px;
  background-color: #ffffff;
  border: 1px solid #d5daea;
  border-radius: 5px;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.07);
  margin-bottom: 20px;
  align-items: center;
  flex-wrap: wrap;
  transition: 0.9s ease;
  min-width: 330px;
  flex-direction: column;
  align-items: flex-start;
  word-wrap: break-word;

  .icon,
  .custom-icon {
    width: 20px;
    min-width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    &.destination-entry {
      width: 25px;
      min-width: 25px;
      height: 25px;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }

  .title-section {
    font-family: 'TT Hoves';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #2e384d;
    max-width: calc(100% - 111px);
  }

  .top-section {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    justify-content: space-between;
    .image-section {
      display: none;
      width: 110px;
      height: 63px;
      position: relative;
      .image {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url("/assets/Event-img.svg");
        border-radius: 4px;
      }
      .live-label {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 43px;
        height: 14px;
        top: 5px;
        left: 7px;
        border-radius: 2px;
        background-color: #ff505f;

        .dot {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: white;
          margin-right: 6px;
        }

        .text {
          color: #ffffff;
          font-size: 8px;
          font-weight: 600;
        }
      }
    }

    .date-section {
      display: flex;
      align-items: center;
      white-space: nowrap;
      width: 111px;
      min-width: 111px;
      justify-content: flex-end;
      .date-data {
        font-weight: 600;
        font-size: 13px;
        line-height: 105%;
        letter-spacing: -0.005em;
        color: #000000;
        white-space: nowrap;
      }
    }
    .toggle-icon-section {
      padding: 5px 7px 5px 5px;
      position: absolute;
      left: 100%;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);

      .toggle-icon {
        width: 16px;
        height: 16px;
        background-image: url('/assets/icons/Caret-down-black.svg');
        transition: transform 0.8s ease;
      }
    }
  }
  .event-content {
    display: none;
    width: 100%;
    margin-right: auto;
  }
  .event-info-row {
    display: flex;
    width: 100%;

    .event-info {
      width: 100%;
      display: flex;
      flex-direction: column;

      .title-section {
        margin-bottom: 12px;
        position: relative;
        .id-section {
          font-size: 13px;
          color: #9c9c9c;
          position: absolute;
          top: 100%;
        }
      }
      .location-section {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        .location-icon {
          background-image: url(/assets/icons/Location_point-black.svg);
          margin-right: 4px;
        }
        .location-name {
          font-weight: 500;
          font-size: 13px;
          max-width: calc(100% - 24px);
        }
      }
      .datetime-section {
        width: 100%;
        display: flex;
        margin-bottom: 16px;
        letter-spacing: -0.3px;
        .datetime-icon {
          display: none;
          background-image: url('/assets/icons/Calendar-black.svg');
          margin-right: 4px;
        }
        .datetime-data {
          display: none;
          font-weight: 500;
          font-size: 13px;
        }
        .date-section {
          display: flex;
          align-items: center;
          margin-right: 6px;
          width: 70px;
          .date-icon {
            background-image: url('/assets/icons/Calendar-black.svg');
            margin-right: 4px;
          }
          .date-data {
            font-weight: 500;
            font-size: 13px;
            white-space: nowrap;
          }
        }
        .time-section {
          display: flex;
          align-items: center;
          .time-icon {
            background-image: url('/assets/icons/Clock-black.svg');
            margin-right: 4px;
          }
          .time-data {
            font-weight: 500;
            font-size: 13px;
            white-space: nowrap;
          }
        }
      }
      .type-section {
        width: 80px;
        .type {
          padding: 1px 9px;
          background-color: rgba(46, 91, 255, 0.08);
          backdrop-filter: blur(6px);
          border-radius: 4px;
          text-align: center;
          line-height: 18px;
        }
      }
    }
  }
  .bottom-section {
    display: none;
    .destinations-section {
      .destinations-row {
        flex-wrap: wrap;
        min-width: 90px;
        .destination-entry {
          margin: 3px 0px 3px 4px;
        }
      }
      .destinationIcon {
      }
    }
    .option-button {
      .optionIcon {
      }
    }
  }

  &.is-upcoming {
    border: 1px solid #2e5bff;
  }

  &.is-live {
    border: 1px solid #ff505f;
    .top-section {
      justify-content: flex-start;
      .image-section {
        display: flex;
      }
      .date-section {
        display: none;
      }
    }
  }

  &&.is-expanded {
    transition: 0.9s ease;
    height: auto;
    .date-section {
      display: none;
    }
    .event-content {
      display: block;
    }
    .event-info-row {
      margin-top: 16px;
    }
    .bottom-section {
      display: flex;
      margin-top: 16px;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .toggle-icon {
      transform: rotate(180deg);
    }
  }
  &&.is-mobile {
    &&.is-live {
      .top-section {
        .image-section {
          margin-right: 16px;
        }
      }
    }
    .top-section {
      .title-section {
        display: flex;
        align-items: center;
      }
    }
  }
  &&.is-desktop {
    cursor: default;
    padding: 20px 30px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    .event-content {
      display: flex;
      flex-direction: column;
      width: fit-content;
      .event-info {
        flex-direction: row;
        padding: 10px 6px 10px 0px;
        align-items: center;
        flex-wrap: wrap;
        .title-section,
        .location-section {
          margin: 0px;
          width: 190px;
          margin-right: auto;
        }
        .datetime-section {
          margin: 0px;
          width: 220px;
          margin-right: auto;
        }
        .type-section {
          margin-right: auto;
        }
      }
    }
    .bottom-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0px 10px 0px;
    }
    .top-section {
      width: 44px;
      margin-right: 20px;
      .image-section {
        display: flex;
        align-items: center;
        width: 44px;
        min-width: 44px;
        height: 44px;
      }
      .title-section {
        display: none;
      }
    }
    &&.is-live {
      .top-section {
        width: 110px;
        .image-section {
          width: 110px;
          height: 63px;
        }
      }
    }
  }
  .date-icon {
    background-image: url('/assets/icons/Calendar-black.svg');
    margin-right: 4px;
  }
  .lock {
    background-image: url(/assets/icons/Lock-black.svg);
    margin-right: 4px;
  }
  @media (min-width: 1439px) {
    &&.is-desktop {
      flex-wrap: nowrap;
      .event-content {
        width: 100%;
        .event-info {
          width: 100%;
          justify-content: space-around;
          .title-section,
          .location-section,
          .datetime-section {
            min-width: 200px;
            width: 18%;
          }
        }
      }
    }
  }
  @media (max-width: 1083px) {
    &&.is-desktop {
      .event-content .type-section {
        margin-top: 20px;
      }
    }
  }
  @media (max-width: 1358px) {
    &&.is-desktop.is-live {
      .destinations-content {
        width: auto;
        min-width: 85px;
        justify-content: flex-start;
      }
    }
  }
  @media (max-width: 1292px) {
    .destinations-content {
      width: auto;
      min-width: 85px;
      justify-content: flex-start;
    }
  }
`;

interface Props {
  event: Event;
  showRequestBtn: boolean;
}

function cutText(text: string, maxLength: number): string {
  if (text.length <= maxLength) {
    return text;
  }

  const substring = text.slice(0, maxLength + 1);

  if (/[\s!"',.:;?]/.test(substring.charAt(maxLength))) {
    return `${text.slice(0, maxLength)}...`;
  }

  const lastSpace = substring.lastIndexOf(' ');

  const lastSign = Math.max(
    substring.lastIndexOf('.'),
    substring.lastIndexOf(','),
    substring.lastIndexOf(';'),
    substring.lastIndexOf(':'),
    substring.lastIndexOf('!'),
    substring.lastIndexOf('?'),
    substring.lastIndexOf('"'),
    substring.lastIndexOf("'")
  );

  const cutIndex = Math.max(lastSpace, lastSign);

  if (cutIndex !== -1) {
    return `${text.slice(0, cutIndex)}...`;
  }

  return `${text.slice(0, maxLength)}...`;
}

export const EventData = ({ event, showRequestBtn = true }: Props): ReactElement => {
  const [timezone] = useStore((aStore) => [aStore.timezone], shallow);
  const [isExpanded, setIsExpanded] = useState(false);

  const { id, title, location, destinations, type, start, end, linkedEventInfo } = event;

  const eventLocation = location.name ? `${location.name}` : '';
  let timestamp = start.setZone(timezone);

  const date = `${timestamp.toLocaleString({ month: 'short', day: 'numeric' })}`;

  const startTime = `${timestamp.toLocaleString(DateTime.TIME_SIMPLE)}`;

  timestamp = end.setZone(timezone);

  const finalTime = `${timestamp.toLocaleString(DateTime.TIME_SIMPLE)}`;

  const hours = `${startTime} - ${finalTime}`;

  let linkedInfoLocation = '';
  let linkedInfoDate = '';
  let linkedInfoHours = '';

  const hasLinkedEventInfo = linkedEventInfo;

  let mergedDestinations: Destination[] = [];

  if (hasLinkedEventInfo) {
    const linkedLocation = linkedEventInfo.location.name || '';
    let linkedTimestamp = linkedEventInfo.start.setZone(timezone);

    const linkedDate = `${linkedTimestamp.toLocaleString({ month: 'short', day: 'numeric' })}`;

    const linkedStartTime = `${linkedTimestamp.toLocaleString(DateTime.TIME_SIMPLE)}`;

    linkedTimestamp = linkedEventInfo.end.setZone(timezone);

    const linkedFinalTime = `${linkedTimestamp.toLocaleString(DateTime.TIME_SIMPLE)}`;

    linkedInfoLocation = linkedLocation;
    linkedInfoDate = `${linkedDate}`;
    linkedInfoHours = `${linkedStartTime} - ${linkedFinalTime}`;

    if (destinations) {
      mergedDestinations = [...destinations];

      if (linkedEventInfo.destinations) {
        linkedEventInfo.destinations.forEach((destination) => {
          if (!mergedDestinations.some((item) => item.id === destination.id)) {
            mergedDestinations.push(destination);
          }
        });
      }
    }
  }

  const isAdmin = Store.User.useIsAdmin();
  const account = Store.Account.useAccount();
  const { webPlayer } = Scheduler.Account.WebPlayer.useView(account.id);
  let imageUrl = '';

  if (event.backgroundURL?.length) {
    imageUrl = event.backgroundURL;
  } else if (webPlayer?.background?.length) {
    imageUrl = webPlayer.background;
  } else {
    imageUrl = '/assets/Event-img.svg';
  }

  const viewport = Theme.useViewport();
  const isMobile = Device.isMobile(viewport) || Device.isTablet(viewport);
  const isDesktop = !isMobile;

  const isUpcoming = event.phase === 1;
  const isLive = event.phase === 2;

  const handleToggleClick = (): void => {
    if (!isDesktop) {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <EventDataRow
      className={cx(
        isUpcoming && 'is-upcoming',
        isMobile && 'is-mobile',
        isDesktop && 'is-desktop',
        isLive && 'is-live',
        isExpanded && !isDesktop && 'is-expanded'
      )}
      role='button'
      tabIndex={0}
      onClick={handleToggleClick}
      onKeyDown={(e): void => {
        if (e.key === 'Enter') {
          handleToggleClick();
        }
      }}
    >
      <div className='top-section'>
        <div className='image-section'>
          <div className='image' style={{ backgroundImage: `url(${imageUrl})` }} />
          {isLive && (
            <div className='live-label'>
              <div className='dot' />
              <div className='text'>Live</div>
            </div>
          )}
        </div>
        <div className='title-section'>
          
          <div className='icon lock' />
          {!isDesktop ? cutText(title, 75) : title}
        </div>

        {!isDesktop && (
          <>
            <div className='date-section'>
              <div className='date-icon icon' />
              <div className='date-data'>{date}</div>
            </div>
            <div className='toggle-icon-section'>
              <div className='toggle-icon icon' />
            </div>
          </>
        )}
      </div>

      {hasLinkedEventInfo && !isExpanded && isMobile && (
        <div className='top-section mt-12px'>
          <div className='title-section'>
            {!isDesktop ? cutText(linkedEventInfo.title, 75) : linkedEventInfo.title}
          </div>

          <div className='date-section'>
            <div className='date-icon icon' />
            <div className='date-data'>{linkedInfoDate}</div>
          </div>
        </div>
      )}

      <div className='event-content'>
        <div className='event-info-row'>
          <div className='event-info'>
            {isDesktop && (
              <div className='title-section'>
                <div className='d-flex align-items-center'>
                  {event.password && (<div className='icon lock' />) }
                  <div> {title}</div>
                </div>
                <div className='id-section'>EID: {id}</div>
              </div>
            )}

            <div className='location-section'>
              {eventLocation ? (
                <>
                  <div className='location-icon icon' />
                  <div className='location-name'>{location.name}</div>
                </>
              ) : null}
            </div>

            <div className='datetime-section'>
              <div className='datetime-icon icon' />
              <div className='datetime-data'>
                {date} {hours}
              </div>
              <div className='date-section'>
                <div className='date-icon icon' />
                <div className='date-data'>{date}</div>
              </div>
              <div className='time-section'>
                <div className='time-icon icon' />
                <div className='time-data'>{hours}</div>
              </div>
            </div>
            <div className='type-section font-size-13px text-primary text-capitalize font-weight-bold'>
              <div className='type'>{type}</div>
            </div>
          </div>
        </div>

        {hasLinkedEventInfo && (
          <div className='event-info-row'>
            <div className='event-info'>
              <div className='title-section'>
                {linkedEventInfo.title}
                {isDesktop && <div className='id-section'>EID: {linkedEventInfo.id}</div>}
              </div>

              <div className='location-section'>
                {eventLocation ? (
                  <>
                    <div className='location-icon icon' />
                    <div className='location-name'>{linkedInfoLocation}</div>
                  </>
                ) : null}
              </div>

              <div className='datetime-section'>
                <div className='datetime-icon icon' />
                <div className='datetime-data'>
                  {linkedInfoDate} {linkedInfoHours}
                </div>
                <div className='date-section'>
                  <div className='date-icon icon' />
                  <div className='date-data'>{linkedInfoDate}</div>
                </div>
                <div className='time-section'>
                  <div className='time-icon icon' />
                  <div className='time-data'>{linkedInfoHours}</div>
                </div>
              </div>
              <div className='type-section font-size-13px text-primary text-capitalize font-weight-bold'>
                <div className='type'>{linkedEventInfo.type}</div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className='bottom-section'>
        <div className='destinations-section'>
          {mergedDestinations.length > 0 ? (
            <Destinations destinations={mergedDestinations} />
          ) : (
            <Destinations destinations={destinations} />
          )}
        </div>

        <div className='option-button'>
          <EventOptionsButton
            event={event}
            isAdmin={isAdmin}
            linkedEvent={linkedEventInfo}
            showRequestBtn={showRequestBtn}
          />
        </div>
      </div>
    </EventDataRow>
  );
};
