import type { Asset } from '@livecontrol/scheduler/model';
import { Account, Event } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { DateTime } from 'luxon';
import type { HTMLAttributes, ReactElement } from 'react';
import { SectionSmallContainer } from '../../../components';
import { Store } from '../../../store';
import { AnalyticsProcessing } from './analytics-processing';
import { ConcurrrentViewsChart } from './concurrent-views-chart';
import { DevicesSection } from './devices-section';
import { LocationsSection } from './locations-section';
import { ViewershipSection } from './viewership-section';

type Props = HTMLAttributes<HTMLDivElement> & {
  asset: Asset;
  createdOn: DateTime;
};

export const Analytics = ({ asset, createdOn, ...props }: Props): ReactElement | null => {
  const account = Store.Account.useAccount();
  const eventId = Event.toId(asset.event);

  const eventSlug = Account.WebPlayer.makeEventSlug({
    eventId
  });

  const { loading, analytics } = Scheduler.Asset.useAnalytics({
    asset,
    account,
    eventSlug
  });

  const isBefore30Hours = DateTime.utc().diff(createdOn, 'hours').hours < 30;

  const { webPlayer } = analytics?.platforms ?? {};

  return isBefore30Hours ? (
    <SectionSmallContainer className='col-12 col-md-12 col-lg-4 col-xl-3'>
      <div {...props}>
        <AnalyticsProcessing />
      </div>
    </SectionSmallContainer>
  ) : (
    <div className='col-12 col-md-12 col-lg-4 col-xl-3'>
      <div {...props}>
        <SectionSmallContainer className='col-12'>
          <ViewershipSection
            asset={asset}
            analytics={webPlayer}
            viewers={analytics?.viewers}
            loading={loading}
          />
        </SectionSmallContainer>

        <SectionSmallContainer className='col-12'>
          {webPlayer?.engagement ? (
            <ConcurrrentViewsChart
              data={webPlayer.engagement.map(({ timestamp, viewers }) => ({
                x: timestamp.setZone(account.timezone).toFormat('hh:mm a'),
                y: viewers
              }))}
            />
          ) : (
            <ConcurrrentViewsChart data={[{ x: 0, y: 0 }]} />
          )}
        </SectionSmallContainer>

        <SectionSmallContainer className='col-12'>
          <DevicesSection analytics={webPlayer} loading={loading} />
        </SectionSmallContainer>

        {webPlayer?.locations && (
          <SectionSmallContainer className='col-12'>
            <LocationsSection analytics={webPlayer} loading={loading} />
          </SectionSmallContainer>
        )}
      </div>
    </div>
  );
};
