import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Obj } from '@livecontrol/core-utils';
import { Account } from '@livecontrol/scheduler/model';
import { useEffect, useState } from 'react';
import type { QueryResult } from '../graphql';

interface TVariables {
  id: string;
}

interface DestinationRecord {
  id: string;
  name: string;
  authorization_id: string;
  strategy: 'facebook' | 'youtube';
  type: string;
}

interface IntegrationRecord {
  destination_id: string;
}

export interface TData {
  integrations?: IntegrationRecord[];
  destinations?: DestinationRecord[];
}

export const QUERY = gql`
  query GetPendingDestinations($id: String!) {
    integrations(clientId: $id) {
      destination_id
    }
    destinations(clientId: $id) {
      id
      name
      authorization_id
      strategy
      type
    }
  }
`;

export const usePendingDestinations = (args: Account.Like): QueryResult<DestinationRecord[]> => {
  const [error, setError] = useState<Error | undefined>();
  const [destinations, setDestinations] = useState<DestinationRecord[]>();

  // Parse the input arguments
  const variables = {
    id: Account.toId(args)?.toString() // @fixme - API type mismatch
  };

  // Validate the input
  if (!Obj.isHydrated(variables)) {
    return { loading: false, error: Exception.KABOOM };
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  // Prepare the query
  const { loading, data } = useQuery<TData, TVariables>(QUERY, {
    variables,
    onError() {
      setError(Exception.KABOOM);
    }
  });

  // When available, parse server response
  useEffect(() => {
    if (data) {
      const filteredDestinations = data.destinations?.filter(
        (v) => !data.integrations?.find((i) => i.destination_id === v.id)
      );

      setDestinations(filteredDestinations);
    }
  }, [data]);

  return { loading, error, data: destinations };
};
