import { ApolloProvider } from '@apollo/client';
import { Modal } from '@livecontrol/scheduler/components';
import React from 'react';
import type { ReactElement } from 'react';
import { Trackers } from './components';
import type { Store } from './store';
import { store } from './store';
import { Provider as ThemeProvider } from './theme';

import PromptProvider = Modal.Prompt.Provider;

export const Providers = ({ children, ...rest }: Providers.Props): ReactElement => {
  const apollo = store((state: Store.State) => state.apollo);

  return (
    <ApolloProvider client={apollo}>
      <Trackers.Provider>
        <ThemeProvider {...rest}>
          <PromptProvider {...rest}>{children}</PromptProvider>
        </ThemeProvider>
      </Trackers.Provider>
    </ApolloProvider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Providers {
  export type Props = React.PropsWithChildren<PromptProvider.Props & ThemeProvider.Props>;
}
