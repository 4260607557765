import { Button } from '@livecontrol/core-ui';
import type { Account } from '@livecontrol/scheduler/model';
import { useState } from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import { UpsertUser } from './upsert-user';

export const AddUser = (
  props: HTMLAttributes<HTMLButtonElement> & {
    account: Account;
    redirectWithUserID?: (userId: number) => void;
  }
): ReactElement => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        {...props}
        className='btn-wide'
        size='md'
        onClick={(): void => {
          setShowModal(true);
        }}
      >
        <img className='mr-8px' src='assets/icons/User_Add.svg' alt='user' />
        Add User
      </Button>

      {showModal && (
        <UpsertUser
          account={props.account}
          onClose={(): void => {
            setShowModal(false);
          }}
          redirectWithUserID={props.redirectWithUserID}
        />
      )}
    </>
  );
};
