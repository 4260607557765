import type { Lazy } from '@livecontrol/async-utils';
import type { Asset, Event, Location } from '@livecontrol/scheduler/model';
import type { Draft as Draft_, Producer, Range } from '@livecontrol/store-utils';
import { Sorting as Sorting_ } from '@livecontrol/store-utils';

import type { Zone } from 'luxon';
import type { UseStore } from 'zustand';

export interface M_Period {
  value?: Period;
  update: (value?: Period) => void;
}

export interface M_Keyword {
  value?: string;
  update: (value?: string) => void;
}

export interface M_Location {
  available: Location[];
  selected?: Location | Location[];
  update: (value?: Location | Location[]) => void;
}

export interface M_Event {
  value?: EventType;
  update: (value?: EventType) => void;
}

export interface M_Filters {
  keyword: M_Keyword;
  period: M_Period;
  location: M_Location;
  event: M_Event;
  reset: () => void;
}

export interface M_Sorting {
  value: Sorting;
  update: (value: Sorting) => void;
}

export interface Model {
  assets: readonly Asset.withEvent[];

  filters: M_Filters;
  sorting: M_Sorting;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type State = {
  model: Lazy<Model>;
  timezone: Zone;
  produce: Producer<Draft>;
};

export type EventType = Pick<Sorting_<Event.EventType.Key>, 'key'>;
export type Sorting = Sorting_<Sorting.Key>;
export type Period = Range<Date>;
export type Draft = Draft_<State>;
export type Store = UseStore<State>;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Sorting {
  export const keys = {
    title: 'Title',
    timestamp: 'Date'
  };

  export type Key = keyof typeof keys;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export import Direction = Sorting_.Direction;
}

export type { Asset, Event, Location };
