import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';

export const useCountdown = (
  time: DateTime
): {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  complete: boolean;
} => {
  const [_, forceUpdate] = useState<number>();

  const isComplete = useCallback(() => time.diffNow().milliseconds < 0, [time]);

  useEffect(() => {
    let value = 0;

    const interval =
      !isComplete() &&
      setInterval(() => {
        forceUpdate(++value);
      }, 1000);

    return (): void => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isComplete, time]);

  const { days, hours, minutes, seconds } = time.diff(DateTime.now(), [
    'hours',
    'days',
    'minutes',
    'seconds'
  ]);

  return {
    days,
    hours,
    minutes,
    seconds: Math.round(seconds),
    complete: isComplete()
  };
};
