import { Form } from '@livecontrol/core-ui';
import cx from 'classnames';
import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import styled from 'styled-components';

import Control = Form.Control;

const Container = styled(InputGroup)`
  border-radius: var(--border-radius-sm);
  background-color: var(--tint-ø3);
  border: 1px solid var(--tint-ø1);
  width: 100%;

  :focus-within {
    background-color: var(--tint-ø2);
  }

  input,
  input:focus {
    background-color: transparent;
    border: none;
    height: auto;
    box-shadow: none !important;
    padding-left: var(--spacer-12px);
  }
`;

export const Keyword = React.forwardRef(
  ({ className, ...rest }: Keyword.Props, ref: React.Ref<HTMLInputElement>): React.ReactElement => (
    <Container className={cx('d-flex', 'align-items-center', className)}>
      <InputGroup.Prepend className='text-secondary pl-12px'>
        <img src="assets/icons/Search.svg" alt="Search" />
      </InputGroup.Prepend>
      <Control type='search' ref={ref} {...rest} />
    </Container>
  )
);

Keyword.displayName = 'Keyword';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Keyword {
  export type Props = Control.Props;
}
