import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import type { AddOn } from '@livecontrol/scheduler/model';
import { useEffect, useState } from 'react';
import type { QueryResult } from '../graphql';
import * as Dbx from './dbx';

interface TVariables {
  id: number;
}

interface TData {
    listAddOns?: Dbx.AddOnRecord[];   
}

const QUERY = gql`
  query ListAddOns {
    listAddOns {
      ...StandardAddOnResponse      
    }
  }
  ${Dbx.StandardAddOnResponse}
`;

export const useListAddOns = (): QueryResult<AddOn[], 'addOns'> => {
  const [error, setError] = useState<Error | undefined>();
  const [addOns, setAddOns] = useState<AddOn[] | undefined>();

  /* eslint-disable react-hooks/rules-of-hooks */

  // Prepare the query
  const { loading, data } = useQuery<TData, TVariables>(QUERY, {    
    onError() {
      setError(Exception.KABOOM);
    }
  });

  // When available, parse server response
  useEffect(() => {
    let addOns_;
    let error_;

    if (data) {
      addOns_ = (data.listAddOns ?? [])
        .map((record: Dbx.AddOnRecord) => Dbx.normalize(record)).filter(
            (addOnd: AddOn | undefined): addOnd is AddOn => !!addOnd
        );
    }

    setAddOns(addOns_);
    setError(error_);
  }, [data]);

  return { loading, error, addOns };
};
