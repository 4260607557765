import React, { useState } from 'react';
import { CropperModal } from './cropper-modal';
import { PreviewContainer } from './preview-container';

export const Uploader = ({
  aspectRatio,
  aspectRatioLabel,
  src,
  style,
  className,
  name = 'Image',
  onChange,
  onRemove,
  useAltStyle,
  displayPreviewText
}: {
  aspectRatio: number;
  aspectRatioLabel: string;
  src?: string;
  style?: React.CSSProperties;
  name?: string;
  className?: string;
  onChange?: (value: { blob: Blob; type: string; file: File }) => Promise<void> | void;
  onRemove?: () => void;
  useAltStyle?: boolean;
  displayPreviewText?: string;
}): JSX.Element => {
  const [fileToUpload, setFile] = useState<File | null>(null);
  const [inputDataSrc, setInputDataSrc] = useState<string | null>(null);

  const [imageUploading, setImageUploading] = useState(false);

  const uploadFile = async (blob: Blob): Promise<void> => {
    try {
      setImageUploading(true);

      if (!fileToUpload) {
        throw new Error('No file found.');
      }

      await onChange?.({
        blob,
        type: fileToUpload.type,
        file: fileToUpload
      });
    } finally {
      setImageUploading(false);
    }
  };

  return (
    <>
      {inputDataSrc && (
        <CropperModal
          show={!!inputDataSrc}
          src={inputDataSrc}
          imageName={name}
          aspectRatio={aspectRatio}
          aspectRatioLabel={aspectRatioLabel}
          onUpdate={(b): void => {
            void uploadFile(b);
          }}
          onHide={(): void => {
            setInputDataSrc(null);
          }}
        />
      )}
      <PreviewContainer
        className={className}
        name={name}
        style={style}
        aspectRatio={aspectRatio}
        isUploading={imageUploading}
        src={src}
        onUpdate={({ file, dataSrc }): void => {
          setFile(file);
          setInputDataSrc(dataSrc);
        }}
        onRemove={onRemove}
        useAltStyle={useAltStyle}
        displayPreviewText={displayPreviewText}
      />
    </>
  );
};
