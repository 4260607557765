import { gql, useMutation } from '@apollo/client';
import { Bool, Num, Str } from '@livecontrol/core-utils';
import { useCallback, useState } from 'react';
import type { MutationResult } from '../graphql';
import { Errors } from '../graphql';

const CREATE_EVENT_MUTATION = gql`
  mutation CreateLocation(
    $user: Float!
    $name: String!
    $timezone: String
    $simulatedLive: Boolean
    $isStatic: Boolean
    $isProduced: Boolean
    $isMobile: Boolean
    $isMobileKit: Boolean
    $isTest: Boolean
    $shippingZipCode: String
    $shippingStreetAddress2: String
    $shippingStreetAddress1: String
    $shippingState: String
    $shippingName: String
    $shippingCountry: String
    $shippingCity: String
    $physicalZipCode: String
    $physicalStreetAddress2: String
    $physicalStreetAddress1: String
    $physicalState: String
    $physicalCity: String
    $physicalCountry: String
    $addOns: [Float!]
    $addonNotes: String
    $equipmentNotes: String
    $isRefundableEquipment: Boolean
    $graphicsSoftware: String
    $muxStreamId: String
    $muxStreamKey: String
    $muxStreamUrl: String
    $isStaticPlus: Boolean
    $externalEncoder: Boolean
  ) {
    createLocation(
      location: {
        name: $name
        user: $user
        time_zone: $timezone
        shipping_zip_code: $shippingZipCode
        shipping_street_address_2: $shippingStreetAddress2
        shipping_street_address_1: $shippingStreetAddress1
        shipping_state: $shippingState
        shipping_name: $shippingName
        shipping_country: $shippingCountry
        shipping_city: $shippingCity
        physical_zip_code: $physicalZipCode
        physical_street_address_2: $physicalStreetAddress2
        physical_street_address_1: $physicalStreetAddress1
        physical_state: $physicalState
        physical_city: $physicalCity
        physical_country: $physicalCountry
        is_simulated_live: $simulatedLive
        is_mobile: $isMobile
        is_static: $isStatic
        is_produced: $isProduced
        is_mobile_kit: $isMobileKit
        is_static_plus: $isStaticPlus
        is_test: $isTest
        external_encoder: $externalEncoder
        equipment_notes: $equipmentNotes
        is_refundable_equipment: $isRefundableEquipment
        mux_stream_id: $muxStreamId
        mux_stream_key: $muxStreamKey
        mux_stream_url: $muxStreamUrl
        add_ons: $addOns
        addon_notes: $addonNotes
        graphics_software: $graphicsSoftware
      }
    ) {
      id
    }
  }
`;

interface T_Variables {
  user?: number;
  name?: string;
  timezone?: string;
  simulatedLive?: boolean;
  isStatic?: boolean;
  isProduced?: boolean;
  isClientBooking?: boolean;
  isMobile?: boolean;
  isMobileKit?: boolean;
  isTest?: boolean;
  shippingZipCode?: string;
  shippingStreetAddress2?: string;
  shippingStreetAddress1?: string;
  shippingState?: string;
  shippingName?: string;
  shippingCountry?: string;
  shippingCity?: string;
  physicalZipCode?: string;
  physicalStreetAddress2?: string;
  physicalStreetAddress1?: string;
  physicalState?: string;
  physicalCity?: string;
  physicalCountry?: string;
  addOns?: number[];
  addonNotes?: string;
  equipmentNotes?: string;
  isRefundableEquipment?: boolean;
  graphicsSoftware?: string;
  muxStreamId?: string;
  muxStreamKey?: string;
  muxStreamUrl?: string;
  isStaticPlus?: boolean;
  externalEncoder?: boolean;
}

interface T_Arguments {
  name?: string;
  user?: number;
  zoom?: boolean;
  time_zone?: string;
  is_simulated_live?: boolean;
  is_static?: boolean;
  is_produced?: boolean;
  is_mobile?: boolean;
  is_mobile_kit?: boolean;
  is_test?: boolean;
  shipping_zip_code?: string;
  shipping_street_address_2?: string;
  shipping_street_address_1?: string;
  shipping_state?: string;
  shipping_name?: string;
  shipping_country?: string;
  shipping_city?: string;
  physical_zip_code?: string;
  physical_street_address_2?: string;
  physical_street_address_1?: string;
  physical_state?: string;
  physical_city?: string;
  physical_country?: string;
  add_ons?: number[];
  addon_notes?: string;
  equipment_notes?: string;
  is_refundable_equipment?: boolean;
  graphics_software?: string;
  mux_stream_id?: string;
  mux_stream_key?: string;
  mux_stream_url?: string;
  is_static_plus?: boolean;
  external_encoder?: boolean;
}

interface T_Data {
  id: string;
}

export const useCreate = (): [
  (args: T_Arguments) => Promise<string | undefined>,
  MutationResult
] => {
  const [error, setError] = useState<Error | undefined>();

  const [mutation, result] = useMutation<{ createLocation: T_Data }, T_Variables>(
    CREATE_EVENT_MUTATION
  );

  return [
    useCallback(
      async (args: T_Arguments): Promise<string | undefined> => {
        const variables: Partial<T_Variables> = {
          name: Str.normalize(args.name),
          user: Num.normalize(args.user),
          timezone: Str.normalize(args.time_zone),
          simulatedLive: Bool.normalize(args.is_simulated_live),
          isStatic: Bool.normalize(args.is_static),
          isProduced: Bool.normalize(args.is_produced),
          isMobile: Bool.normalize(args.is_mobile),
          isMobileKit: Bool.normalize(args.is_mobile_kit),
          isTest: Bool.normalize(args.is_test),
          shippingZipCode: Str.normalize(args.shipping_zip_code),
          shippingStreetAddress2: Str.normalize(args.shipping_street_address_2),
          shippingStreetAddress1: Str.normalize(args.shipping_street_address_1),
          shippingState: Str.normalize(args.shipping_state),
          shippingName: Str.normalize(args.shipping_name),
          shippingCountry: Str.normalize(args.shipping_country),
          shippingCity: Str.normalize(args.shipping_city),
          physicalZipCode: Str.normalize(args.physical_zip_code),
          physicalStreetAddress2: Str.normalize(args.physical_street_address_2),
          physicalStreetAddress1: Str.normalize(args.physical_street_address_1),
          physicalState: Str.normalize(args.physical_state),
          physicalCity: Str.normalize(args.physical_city),
          physicalCountry: Str.normalize(args.physical_country),
          addOns: args.add_ons,
          addonNotes: Str.normalize(args.addon_notes),
          equipmentNotes: Str.normalize(args.equipment_notes),
          isRefundableEquipment: Bool.normalize(args.is_refundable_equipment),
          graphicsSoftware: Str.normalize(args.graphics_software),
          muxStreamId: Str.normalize(args.mux_stream_id),
          muxStreamKey: Str.normalize(args.mux_stream_key),
          muxStreamUrl: Str.normalize(args.mux_stream_url),
          isStaticPlus: Bool.normalize(args.is_static_plus),
          externalEncoder: Bool.normalize(args.external_encoder)
        };

        try {
          const response = (
            await mutation({
              variables
            })
          ).data?.createLocation;

          const id = response?.id ?? '';

          if (!id) {
            throw Errors.serverError();
          }

          return id;
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        return '';
      },
      [mutation]
    ),
    {
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
