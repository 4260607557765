import { Matrix } from '@livecontrol/scheduler/components';
import type { ReactElement } from 'react';
import { Attributes } from './attributes';

export const Content = ({ event, timezone, ...props }: Attributes.Props): ReactElement => (
  <Matrix.Stack.Card.Body {...props}>
    <Attributes event={event} timezone={timezone} />
  </Matrix.Stack.Card.Body>
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Content {
  export type Props = Attributes.Props;
}
