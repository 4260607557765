import { Modal } from '@livecontrol/scheduler/components';
import type { ReactElement } from 'react';

interface Props {
  show: boolean;
  title: string;
  icon?: string;
  loading?: boolean;
  content: string;
  onClose?: () => void;
  onDelete?: () => void;
}

export const DeleteModal = ({
  show = false,
  title,
  content,
  icon,
  loading,
  onClose,
  onDelete
}: Props): ReactElement => (
  <Modal show={show} onHide={onClose}>
    <Modal.Header className='text-center mb-0' closeButton>
      {icon ? <i className={icon} /> : <img src='assets/icons/trash.svg' alt='trash-red' />}
      <h1 className='font-size-20px font-weight-bold mt-16px mb-16px'> {title} </h1>
    </Modal.Header>
    <Modal.Body className='text-center mb-32px'>
      <div className='font-size-15px font-weight-bold text-gray-light'>{content}</div>
    </Modal.Body>
    <Modal.Footer>
      <Modal.Submit
        loading={loading}
        onClick={onDelete}
        variant='danger font-size-14px font-weight-bold pl-40px pr-40px'
      >
        Delete
      </Modal.Submit>
      <Modal.Button
        variant='bg-white text-tertiary font-size-14px font-weight-bold'
        onClick={onClose}
      >
        Cancel
      </Modal.Button>
    </Modal.Footer>
  </Modal>
);
