import { Bool, Str } from '@livecontrol/core-utils';
import _ from 'lodash';
import validator from 'validator';
import { EventValidator } from './types';

export const validateRedirectUrl = (
  newEventInfo: EventValidator.EventInfo
): EventValidator.Errors => {
  const errors: EventValidator.Errors = {};
  const enabled = Bool.normalize(newEventInfo.redirectUrlSettings?.enabled);
  const url = Str.normalize(newEventInfo.redirectUrlSettings?.url);

  if (enabled) {

    if (!url) {
      _.merge(errors, {
        redirectUrlSettings: {
          url: EventValidator.ErrorMessages.REDIRECT_URL_REQUIRED
        }
      });
    } else if (!validator.isURL(encodeURI(url))) {
      _.merge(errors, {
        redirectUrlSettings: {
          url: EventValidator.ErrorMessages.INVALID_REDIRECT_URL
        }
      });
    }
  }

  return errors;
};
