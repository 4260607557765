import { gql, useMutation } from '@apollo/client';
import type { User } from '@livecontrol/scheduler/model';
import type { MutationResult } from '@livecontrol/scheduler/store';
import { useCallback } from 'react';
import * as Dbx from './dbx';

interface T_Data {
  addEmailToUnsubscribeGroup: Dbx.Record;
}

interface T_Variables {
  groupId: number;
  email: string;
}

const MUTATION = gql`
  mutation UnsubscribeEmailFromGroup($groupId: Float!, $email: String!) {
    unsubscribeEmailFromGroup(unSubscribeGroupId: $groupId, email: $email) {
      id
      customer_feedback
      event_scheduling
      event_reminder
      connection_issues
    }
  }
`;

export const useUnsubscribe = (): [
  (
    groupId: User.NotificationPreference,
    email: string
  ) => Promise<Dbx.NotificationPreferences | undefined>,
  MutationResult
] => {
  const [fn, result] = useMutation<T_Data, T_Variables>(MUTATION);

  return [
    useCallback(
      async (
        groupId: User.NotificationPreference,
        email: string
      ): Promise<Dbx.NotificationPreferences | undefined> => {
        const mutation = await fn({
          variables: { email, groupId }
        });

        const data = mutation.data?.addEmailToUnsubscribeGroup;

        return data ? Dbx.normalize(data) : undefined;
      },
      [fn]
    ),
    result
  ];
};
