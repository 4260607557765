import { Device } from '@livecontrol/core-ui';
import type { Account } from '@livecontrol/scheduler/model';
import cx from 'classnames';
import { DateTime } from 'luxon';
import type { ReactElement } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-internal-modules
import { SubscriptionAccountInfo } from '../billing/types';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  font-size: var(--font-size-12px);
  font-weight: 600;
  color: #2e384d;
  margin-top: var(--spacer-12px);
  width: 800px;

  .x-${Device.MOBILE} &,
  .x-${Device.TABLET} & {
    width: 500px;
  }

  .item {
    width: 120px;
  }

  .alert {
    padding: 0px 4px;
    margin-right: 40px;
    opacity: 0.8;
    font-size: var(--font-size-10px) !important;
    text-transform: capitalize;
  }

  .name {
    width: 260px;
    font-weight: 400;
  }
`;

const Scrollpsy = styled.div`
  position: relative;

  .scrollable {
    overflow: auto;
  }
`;

export const InvoicesList = ({
  invoices,
  account
}: {
  invoices: SubscriptionAccountInfo.Invoice[];
  account: Account;
}): ReactElement => (
  <Scrollpsy>
    <div className={cx('scrollable')}>
      {invoices.map((invoice) => (
        <Container key={invoice.id}>
          <div className='item d-flex flex-row align-items-start'>
            <a
              href={invoice.hosted_url}
              target='_blank'
              rel='noreferrer'
              className='text-primary text-dark font-size-13px font-weight-bold d-flex flex-row align-items-center'
            >
              {DateTime.fromISO(invoice.date)
                .setZone(account.timezone)
                .toLocaleString(DateTime.DATE_MED)}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='12'
                height='12'
                fill='currentColor'
                className='bi bi-box-arrow-up-right ml-4px'
                viewBox='0 0 16 16'
              >
                <path
                  fillRule='evenodd'
                  d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z'
                />
                <path
                  fillRule='evenodd'
                  d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z'
                />
              </svg>
            </a>
          </div>
          <div className='item ml-16px font-size-13px'>
            {(invoice.price/100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}{' '}
          </div>
          <div className='status '>
            <div
              className={cx(
                'alert font-size-13px',
                invoice.status === SubscriptionAccountInfo.Invoice.InvoiceStatus.DRAFT
                  ? 'alert-primary'
                  : invoice.status === SubscriptionAccountInfo.Invoice.InvoiceStatus.OPEN
                  ? 'alert-secondary'
                  : invoice.status === SubscriptionAccountInfo.Invoice.InvoiceStatus.PAID
                  ? 'alert-success'
                  : invoice.status === SubscriptionAccountInfo.Invoice.InvoiceStatus.UNCOLLECTIBLE
                  ? 'alert-danger'
                  : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  invoice.status === SubscriptionAccountInfo.Invoice.InvoiceStatus.VOID
                  ? 'alert-warning'
                  : 'alert-info'
              )}
            >
              {invoice.status}
            </div>
          </div>
          <div className='name text-truncate font-size-13px'> {invoice.description} </div>
        </Container>
      ))}
    </div>
  </Scrollpsy>
);
