import type { Viewport } from '@livecontrol/core-ui';
import { Device, Theme } from '@livecontrol/core-ui';
import React from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ viewport: Viewport }>`
  padding: 10px;
  height: fit-content;
`;

const Container = styled.div<{ viewport: Viewport }>`
  background-color :var(--white);
  display: block;
  background-color: #ffffff;
  width: 100%;
  border-radius: var(--border-radius-lg);
  padding: ${({ viewport }): string =>
    `var(--spacer-${Device.isDesktop(viewport) ? 48 : 16}px)`};
  transition: padding linear var(--transition-duration);
`;

export const SectionContainer = ({
  className,
  children,
  ...rest
}: React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>): ReactElement => {
  const viewport = Theme.useViewport();

  return (
    <Wrapper viewport={viewport} className={className} {...rest}>
      <Container viewport={viewport} className="container-section">{children}</Container>
    </Wrapper>
  );
};
