import { Children, useEffect } from 'react';
import type { PropsWithChildren, ReactElement } from 'react';
import TagManager from 'react-gtm-module';
import useHotjar from 'react-use-hotjar';
import { IntercomProvider } from 'react-use-intercom';
import { store } from '../../store';

type Props = PropsWithChildren<unknown>;

const Inject = ({ children }: Props): ReactElement => {
  const { initHotjar } = useHotjar();

  useEffect(
    () => {
      initHotjar(2228048, 6);

      TagManager.initialize({
        gtmId: 'GTM-PGF29XL'
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <IntercomProvider appId='eg8i0dxv'>{children}</IntercomProvider>;
};

export const Provider = ({ children }: Props): ReactElement =>
  store.environment.production ? <Inject>{children}</Inject> : <>{Children.toArray(children)}</>;
