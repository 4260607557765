import type { Interval } from 'luxon';
import { EventValidator } from './types';

export const validateMaxRecurrences = (
  newEventInfo: EventValidator.EventInfo,
  recurrences: Interval[]
): EventValidator.Errors => {
  const errors: EventValidator.Errors = {};

  if (
    recurrences.length > 10 &&
    newEventInfo.repeatSettings?.repeatEndCondition ===
      EventValidator.EventInfo.RepeatEndCondition.AFTER_OCCURRENCES
  ) {
    errors.repeatSettings = {
      repeatUntil: {
        numRecurrences: EventValidator.ErrorMessages.TOO_MANY_RECURRENCES
      }
    };
  } else if (
    recurrences.length > 10 &&
    newEventInfo.repeatSettings?.repeatEndCondition ===
      EventValidator.EventInfo.RepeatEndCondition.ON_DATE
  ) {
    errors.repeatSettings = {
      repeatUntil: {
        stopRepeatingOn: EventValidator.ErrorMessages.TOO_MANY_RECURRENCES
      }
    };
  }

  return errors;
};
