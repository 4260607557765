/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Obj } from '@livecontrol/core-utils';
import type { ModuleInfo, Onboarding } from '@livecontrol/scheduler/model';
import { Account, Task } from '@livecontrol/scheduler/model';
import { useEffect, useState } from 'react';
import type { QueryResult } from '../graphql';

interface TVariables {
  userId: number;
}

interface TData {
  getOnboardingProgress: {
    result: {
      data: {
        onboarding_modules: onboardingModule[];
      };
    };
    success: boolean;
  };
}

interface anAsset {
  extension: string;
  file_name: string;
  type: string;
  url: string;
}

interface aTask {
  id: string;
  assets: anAsset[];
  description: string;
  is_required: boolean;
  is_visible_in_progress: boolean;
  task_name: string;
  task_type: string;
}

interface toolkit {
  id: string;
  toolkit_title: string;
  toolkit_items: toolkitItem[];
}

interface toolkitItem {
  task_id: string;
  toolkit_name: string;
  url: string;
}

interface onboardingModule {
  id: string;
  is_required: boolean;
  module_name: string;
  module_subtitle: string;
  description: string;
  completed_tasks: aTask[];
  tasks: aTask[];
  toolkits: toolkit[];
}

export const QUERY = gql`
  query GetOnboardingProgress($userId: Float!) {
    getOnboardingProgress(user_id: $userId) {
      result
      success
    }
  }
`;

function stringToAssetType(typeString: string): Task.Asset.AssetType {
  if (typeString === Task.Asset.AssetType.IMAGE) {
    return Task.Asset.AssetType.IMAGE;
  }

  if (typeString === Task.Asset.AssetType.VIDEO) {
    return Task.Asset.AssetType.VIDEO;
  }

  return Task.Asset.AssetType.IMAGE;
}

function convertToAsset(data: anAsset): Task.Asset {
  const type = stringToAssetType(data.type);

  const anAsset: Task.Asset = {
    name: data.file_name ?? '',
    url: data.url ?? '',
    type
  };

  return anAsset;
}

function convertToToolkitItem(data: toolkitItem): Task.Toolkit.ToolkitItem {
  const aToolkitItem: Task.Toolkit.ToolkitItem = {
    url: data.url ?? '',
    name: data.toolkit_name ?? ''
  };

  return aToolkitItem;
}

function convertToToolkit(data: toolkit): Task.Toolkit {
  const items = data.toolkit_items.map((element) => convertToToolkitItem(element));

  const aToolkit: Task.Toolkit = {
    id: data.id,
    title: data.toolkit_title,
    items
  };

  return aToolkit;
}

function stringToTaskType(typeString: string): Task.TaskType {
  if (typeString === Task.TaskType.TOOLKIT) {
    return Task.TaskType.TOOLKIT;
  }

  if (typeString === Task.TaskType.FORM) {
    return Task.TaskType.FORM;
  }

  if (typeString === Task.TaskType.URL) {
    return Task.TaskType.URL;
  }

  if (typeString === Task.TaskType.VIDEO) {
    return Task.TaskType.VIDEO;
  }

  return Task.TaskType.TOOLKIT;
}

function convertToTask(data: aTask, onboardindData: onboardingModule): Task {
  const type = stringToTaskType(data.task_type);
  const assets = data.assets.map((element) => convertToAsset(element));

  const toolkitFromTask = onboardindData.toolkits.find(
    (element) => element.toolkit_items[0].task_id === data.id
  );

  let toolkit;

  if (toolkitFromTask) {
    toolkit = convertToToolkit(toolkitFromTask);
  }

  const isCompletedTask = onboardindData.completed_tasks.findIndex(
    (element) => element.id === data.id
  );

  const parsedTask: Task = {
    id: data.id,
    isRequired: data.is_required,
    name: data.task_name,
    description: data.description,
    isVisibleInProgress: data.is_visible_in_progress,
    type,
    assets,
    isCompleted: isCompletedTask > -1,
    toolkit
  };

  return parsedTask;
}

function convertToTypes(data: TData): ModuleInfo[] {
  const parsedModules: ModuleInfo[] = [];

  data.getOnboardingProgress.result.data.onboarding_modules.forEach((module) => {
    const parsedtasks: Task[] = [];

    module.tasks.forEach((task) => {
      const parsedTask = convertToTask(task, module);

      parsedtasks.push(parsedTask);
    });

    const aModule: ModuleInfo = {
      id: module.id,
      isRequired: module.is_required,
      title: module.module_name,
      subtitle: module.module_subtitle,
      description: module.description,
      timeInMinutes: '5',
      tasks: parsedtasks
    };

    parsedModules.push(aModule);
  });

  return parsedModules;
}

export const useGetOnboardingInfo = (
  args: Account.Like
): QueryResult<Onboarding, 'onboardingInfo'> => {
  const [error, setError] = useState<Error | undefined>();
  const [onboardingInfo, setOnboardingInfo] = useState<Onboarding | undefined>();

  // Parse the input arguments
  const variables = {
    userId: Account.toId(args)
  };

  // Validate the input
  if (!Obj.isHydrated(variables)) {
    return { loading: false, error: Exception.KABOOM };
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  // Prepare the query
  const { loading, data } = useQuery<TData, TVariables>(QUERY, {
    variables,
    onError() {
      setError(Exception.KABOOM);
    }
  });

  // When available, parse server response
  useEffect(() => {
    let onboardingInfo_: Onboarding | undefined;
    let error_;

    if (data?.getOnboardingProgress.success) {
      onboardingInfo_ = {
        progress: 0,
        modules: convertToTypes(data)
      };
    }

    setOnboardingInfo(onboardingInfo_);
    setError(error_);
  }, [data]);

  return { loading, error, onboardingInfo };
};
