import { Loading } from '@livecontrol/core-ui';
import { User } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { assert } from '@sindresorhus/is';
import { Children, useEffect } from 'react';
import type { PropsWithChildren, ReactElement } from 'react';
import { XRef } from '../model';
import { store } from './factory';
import * as Storage from './storage';
import { Store } from './store';

const Acct = ({ xref, children }: PropsWithChildren<{ xref: XRef }>): ReactElement => {
  // Watch the state
  const actual = store((state: Store.State) => state.account);

  const { account, error } = Scheduler.Account.useFetchAccount(xref.account);

  // Did we determine the account?
  useEffect(() => {
    store.produce((draft: Store.Draft) => {
      draft.account = account;
    });
  }, [account]);

  // Did an error occur?
  useEffect(() => {
    if (error) {
      // Reset the store (will route back to `/login`)
      Store.User.logout();
    }
  }, [error]);

  // Do we have enough information to continue?
  return actual ? <>{Children.toArray(children)}</> : <Loading.Delay />;
};

const Thee = ({ xref, children }: PropsWithChildren<{ xref: XRef }>): ReactElement => {
  // Watch the state
  const actual = store((state: Store.State) => state.thee);

  // Look up the client for whom we are to be masquerading
  const { user, error } = Store.User.useUser(xref.user);

  // Did we find the specified user for whom we are to be masquerading?
  useEffect(() => {
    // Do not allow masquerading as anything but a client/subuser
    if (user && ![User.Role.Client, User.Role.SubUser].includes(user.role)) {
      // Reset the store (will route back to `/login`)
      Store.User.logout();

      return;
    }

    // Update the store with the client for whom we are masquerading
    store.produce((draft: Store.Draft) => {
      draft.thee = user;
    });
  }, [user]);

  // Did an error occur?
  useEffect(() => {
    if (error) {
      // Reset the store (will route back to `/login`)
      Store.User.logout();
    }
  }, [error]);

  // Do we have enough information to continue?
  return actual ? <Acct xref={xref}>{Children.toArray(children)}</Acct> : <Loading.Delay />;
};

const Me = ({ xref, children }: PropsWithChildren<{ xref: XRef }>): ReactElement => {
  const actual = store((state: Store.State) => state.me);

  // Look up the record for the authenticated user
  const { user, error } = Store.User.useUser(xref.user);

  // Did we determine who we are?
  useEffect(() => {
    store.produce((draft: Store.Draft) => {
      draft.me = user;
    });
  }, [user]);

  // Did an error occur?
  useEffect(() => {
    if (error) {
      // Reset the store (will route back to `/login`)
      Store.User.logout();
    }
  }, [error]);

  // Spin until we know who are
  if (!actual) {
    return <Loading.Delay />;
  }

  assert.object(actual);

  // We know who we are, but are we supposed to be masquerading as somebody else?
  const masquerade =
    actual.role === User.Role.Admin ? XRef.decode(Storage.getItem(Storage.MASQ_KEY)) : undefined;

  return masquerade && masquerade.user !== actual.id ? (
    <Thee xref={masquerade}>{children}</Thee>
  ) : (
    <Acct xref={xref}>{Children.toArray(children)}</Acct>
  );
};

export const Reactor = ({ children }: PropsWithChildren<unknown>): ReactElement => {
  // Get the authentication token
  const token = store((state: Store.State) => state.token);

  const { account, user } = token ?? {};

  // If we know the user's ID, we need to fetch the `me` record.  Otherwise render the children directly
  return user && account ? (
    <Me xref={{ user, account }}>{children}</Me>
  ) : (
    <>{Children.toArray(children)}</>
  );
};
