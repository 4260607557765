import type { ReactElement } from 'react';
import { SectionContainer } from '../../components';
import { Actions } from './actions';
import { Grid } from './grid';
import { Header } from './header';
import { NoAssets } from './no-assets';
import { useStore } from './store';


export const Layout = (): ReactElement => {
  const assets = useStore(({ model }) => model.value.assets);

  return (
    <SectionContainer className='col-12'>
      <Header />
      {assets.length ? (
        <>
          <Actions/>
          <Grid />
        </>
      ) : (
        <NoAssets />
      )}
    </SectionContainer>
  );
};
