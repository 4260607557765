import { Event } from '@livecontrol/scheduler/model';
import React from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import Nav from 'react-bootstrap/Nav';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import shallow from 'zustand/shallow';
import { TZ } from '../../components';
import { Tab, useStore } from './store';

const TabsContainer = styled.div`
  margin: 22px 0px;

  .nav-link {
    padding: 10px 8px;
    color: #9c9c9c;
    font-size: 14px;
    font-weight: 600;
    min-width: 100px;
    display: flex;
    justify-content: center;
    position: relative;
    border: none;

    .selected-border {
      display: none;
      height: 4px;
      background-color: blue;
      width: 100%;
      border-radius: 8px;
      position: absolute;
      top: calc(100% - 4px);
    }

    &.active {
      color: #2e384d;

      .selected-border {
        display: block;
      }
    }
  }
`;

type Props = HTMLAttributes<HTMLElement> & {
  tab: Tab;
};

export const Tabber = ({ className, tab }: Props): ReactElement => {
  const [tabset, live, timezone] = useStore((state) => {
    const model = state.model.value;

    return [model.tabset, model[Event.Phase.Live].events, state.timezone];
  }, shallow);

  const history = useHistory();

  const onSelect = React.useCallback(
    (key?: string | null): void => {
      if (key) {
        history.push(`/schedule/${key}`);
      }
    },
    [history]
  );

  return (
    <TabsContainer>
      <Nav className={className} variant='tabs' activeKey={tab} onSelect={onSelect}>
        {tabset.has(Tab.Pending) && (
          <Nav.Link key={Tab.Pending} eventKey={Tab.Pending}>
            {live.length ? `Live Events (${live.length})` : 'Upcoming Events'}
            <div className='selected-border' />
          </Nav.Link>
        )}
        {tabset.has(Tab.Past) && (
          <Nav.Link key={Tab.Past} eventKey={Tab.Past}>
            Past Events
            <div className='selected-border' />
          </Nav.Link>
        )}
      </Nav>
      <TZ className='flex-grow-1 align-self-center text-right mt-16px' timezone={timezone} />
    </TabsContainer>
  );
};
