import type { Viewport } from '@livecontrol/core-ui';
import React, { Component } from 'react';
import type { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { Header as Header_ } from './header';
import { Row as Row_ } from './row';

const Container = styled.ol<{ gridTemplateColumns: string; viewport: Viewport }>`
  display: grid;
  font-weight: var(--font-weight-light);
  line-height: var(--line-height-sm);
  grid-row-gap: var(--spacer-16px);
  grid-template-columns: ${({ gridTemplateColumns }): string => gridTemplateColumns};
`;

// eslint-disable-next-line react/prefer-stateless-function
export class Grid extends Component<Grid.Props> {
  public override render(): ReactNode {
    return React.createElement(Container, this.props);
  }
}

export namespace Grid {
  export type Props = PropsWithChildren<
    HTMLAttributes<HTMLDivElement> & { gridTemplateColumns: string; viewport: Viewport }
  >;

  /* eslint-disable @typescript-eslint/no-unused-vars */
  export import Header = Header_;
  export import Row = Row_;
}
