/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Filters } from '@livecontrol/scheduler/components';

import type { ReactElement } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import U = Filters.User;

export const User = ({ users, ...props }: Omit<U.Props, 'value'>): ReactElement | null => (
  <U value={users} {...props} onChange={users.update} as={ButtonGroup} />
);
