/* eslint-disable jsx-a11y/label-has-associated-control */
import type { Location } from '@livecontrol/scheduler/model';
import { useEffect, useState } from 'react';
import type { OptionProps } from 'react-select';
import Select, { components } from 'react-select';

const InputOption = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}: OptionProps): JSX.Element => {
  const [isActive, setIsActive] = useState(false);

  const onMouseDown = (): void  => {
    setIsActive(true);
  };

  const onMouseUp = (): void => {
    setIsActive(false);
  };

  const onMouseLeave = (): void=> {
    setIsActive(false);
  };

  // styles
  let bg = 'transparent';

  if (isFocused) {
    bg = '#eee';
  }

  if (isActive) {
    bg = '#B2D4FF';
  }

  const style = {
    alignItems: 'center',
    backgroundColor: bg,
    color: 'inherit',
    display: 'flex'
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type='checkbox' checked={isSelected} />
      {children}
    </components.Option>
  );
};


export const MultiselectDropdown = (
  props: {
    location: Location.DropdownLocation[];
    setLocations: React.Dispatch<React.SetStateAction<Location.DropdownLocation[]>>;
    selectedLocations: Location.DropdownLocation[];
}): JSX.Element => {

  const [selectedLocations, setValue] = useState(props.selectedLocations);

  useEffect(() => {
    setValue(props.selectedLocations);
  }, [props.selectedLocations]);

  return (
    <div className='mt-0 mb-0 mr-0 ml-0'>
      <label className='form-label'>Restrict to locations</label>
      <div className='MultiselectDropdown'>
        <Select
          defaultValue={selectedLocations}
          isMulti
          placeholder="Restricted to these locations"
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isDisabled={props.location.length === 0}
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          onChange={(options) => {
            if (Array.isArray(options)) {
              setValue(options);
              props.setLocations(options);
            }
          }}
          options={props.location.sort((a, b) => a.label.localeCompare(b.label)) as never}
          components={{
            Option: (InputOption as never)
          }}
        />
      </div>
    </div>
  );
};
