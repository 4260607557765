import { Obj } from '@livecontrol/core-utils';
import type { ReactElement } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Sorting as Component } from '../../../components';
import type { Phase } from '../store';
import { T_Sorting, useStore } from '../store';

export const Sorting = ({
  phase,
  ...props
}: Omit<Component.Props<T_Sorting.Key>, 'value'> & { phase: Phase }): ReactElement => {
  const sorting = useStore(({ model }) => model.value[phase].sorting);

  const { keys } = T_Sorting;

  const value = {
    selected: sorting.value,
    available: Obj.keys(keys).map((key) => ({
      key,
      label: keys[key]
    }))
  };

  return <Component value={value} {...props} onChange={sorting.update} as={ButtonGroup} />;
};
