import type { ApolloError, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Num, Obj, Str } from '@livecontrol/core-utils';
import { Errors } from '@livecontrol/scheduler/store';
import type { MutationResult } from '@livecontrol/scheduler/store';
import { useCallback, useState } from 'react';

interface TVariables {
  confirmation: string;
  password: string;
  token: string;
}

interface TData {
  resetPassword?: {
    id: number;
  };
}

type Args = TVariables;

const MUTATION = gql`
  mutation ResetPassword($token: String!, $password: String!, $confirmation: String!) {
    resetPassword(
      input: { newPassword: $password, newPasswordConfirmation: $confirmation, token: $token }
    ) {
      id
    }
  }
`;

export const useResetPassword = (): [
  (args: Args) => Promise<boolean>,
  MutationResult<boolean, 'success'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [success, setSuccess] = useState(false);

  return [
    useCallback(
      async (args: TVariables): Promise<boolean> => {
        let success_ = false;

        try {
          // Parse the input arguments
          const variables = {
            confirmation: Str.normalize(args.confirmation),
            password: Str.normalize(args.password),
            token: Str.normalize(args.token)
          };

          // Validate the input
          if (!Obj.isHydrated(variables)) {
            throw Errors.badRequest();
          }

          // Execute the GraphQL mutation
          const response = await mutation({ variables })
            .then(({ data }: FetchResult<TData>) => data?.resetPassword)
            .catch(({ networkError }: ApolloError) => {
              // Was this an unrecoverable Network error?
              if (networkError) {
                throw Errors.serverError();
              }

              // Make an assumption as to why we got a GraphQL error.
              throw new Error(
                'The link to setup your account has expired. Please contact the account owner and have them resend your invite.'
              );
            });

          // Parse the server response
          if (!Num.normalize(response?.id)) {
            throw Errors.serverError();
          }

          // Success!
          success_ = true;
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setSuccess(success_);

        return success_;
      },
      [mutation, setError, setSuccess]
    ),
    {
      success,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
