import {
  COUNTRIES_LIST as COUNTRIES_LIST_,
  FREQUENT_COUNTRIES_LIST as FREQUENT_COUNTRIES_LIST_
} from './countries-list';
import { setIntent as setIntent_ } from './set-intent';
import { useCountdown as useCountdown_ } from './use-countdown';

export namespace Utils {
  export const COUNTRIES_LIST = COUNTRIES_LIST_;
  export const FREQUENT_COUNTRIES_LIST = FREQUENT_COUNTRIES_LIST_;
  export const setIntent = setIntent_;
  export const useCountdown = useCountdown_;
}
