import { Nix } from '@livecontrol/core-utils';
import { Refs } from '@livecontrol/react-utils';
import cx from 'classnames';
import type { FieldMetaProps } from 'formik';
import React from 'react';
import type { ReactElement } from 'react';
import type { FormControlProps } from 'react-bootstrap/FormControl';
import FormControl from 'react-bootstrap/FormControl';

export const Control = React.forwardRef(
  (props: Control.Props, ref: React.Ref<HTMLInputElement>): ReactElement => {
    const { autoFocus, autoSelect, className, value, ...rest } = props;

    const localRef = React.useRef<HTMLInputElement>();

    // Auto-select/focus
    React.useEffect(
      () => {
        if (localRef.current) {
          const el: Partial<Pick<HTMLInputElement, 'focus' | 'select'>> = localRef.current;

          if (autoSelect && typeof el.select === 'function') {
            el.select();
          }

          if ((autoSelect || autoFocus) && typeof el.focus === 'function') {
            el.focus();
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    return (
      <FormControl
        className={cx(
          props.type !== 'password' && props.as !== 'select' && 'data-hj-allow',
          className
        )}
        ref={Refs.merge(ref, localRef)}
        value={Nix.isNotNil(value) ? value : ''}
        {...rest}
      />
    );
  }
);

Control.displayName = 'Control';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Control {
  export type Props = FormControlProps &
    Omit<React.ComponentPropsWithRef<'input'>, 'size'> &
    React.PropsWithChildren<{ autoSelect?: boolean; meta?: FieldMetaProps<string> }>;
}
