import type { Environment } from '@livecontrol/customer-dashboard/frontend';

export const environment: Readonly<Environment> = Object.freeze({
  production: true,
  ADMIN_URI: 'https://admin.internal.livecontrol.io/admin',
  GCLOUD_MAPS_API_KEY: 'AIzaSyCRx-7uHFg6QqtDvAAWOQFvlxCyajvYFSA',
  GRAPHQL_URI: 'https://scheduler-graphql.api.livecontrol.io',
  MAPBOX_ACCESS_TOKEN:
    'pk.eyJ1IjoibGl2ZWNvbnRyb2wiLCJhIjoiY2tsdTNlaGtjMXR3ajJvbHdobWVicWdsbyJ9.U2J9nIAX8kHKDBu_TyIjYQ',
  REST_URI: 'https://scheduler-rest.api.livecontrol.io',
  STRIPE_PUBLISHABLE_KEY: 'pk_live_xIyaZkLgswhrERp3YQTwoTXl00DCRm34Oo',
  VENUE_URI: 'https://{slug}.livecontrol.tv',
  CLIENT_BOOKING_URI: 'https://{slug}.upgrade.livecontrol.tv',
  VERSION: '1.3.4d',
  SENTRY_DNS: 'https://a9929bc82bfb4196ad095dc11524a65d@o441719.ingest.sentry.io/5540733',
  ALLOW_NEW_FEATURES_ACCOUNT_IDS: [2315, 1525, 1739, 2036, 2578, 2130, 2210, 1182]
});
