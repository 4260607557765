import { Modal } from '@livecontrol/scheduler/components';
import type { Account } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Form } from '../../../components';
import { Alert } from '../../../store';

const ModalPrompt = styled(Modal.Prompt)`
  .modal-header {
    margin-bottom: 0px !important;
  }
`;

interface Props {
  account: Account;
  subUsers: Account.SubUser[];
  onClose: () => void;
  onUsersDeleted: (users: Account.SubUser[]) => void;
}

export const DeleteUser = ({ account, subUsers, onClose, onUsersDeleted }: Props): ReactElement => {
  const [mutation, { loading, error }] = Scheduler.Account.UserManagement.useDelete();
  const alert = Alert.useAlert(error);

  const onOkay = async (): Promise<void> => {
    if (await mutation({ account, subUsers })) {
      onClose();
      onUsersDeleted(subUsers);
    }
  };

  return (
    <ModalPrompt
      {...{
        show: true,
        title: (
          <h1 className='font-size-20px font-weight-bold mt-16px'>
            Delete {subUsers.length} user(s)?
          </h1>
        ),
        icon: <img src='assets/icons/Trash-red.svg' alt='trash' style={{ width: 40 }} />,
        noButtons: true,
        onCancel: onClose
      }}
    >
      <Form.Alert show={alert.show} onClose={alert.hide}>
        {alert.message}
      </Form.Alert>

      <p className='text-center font-weight-bold text-gray-light font-size-13px'>
        This operation is permanent and cannot be undone.
      </p>

      <Modal.Footer>
        <Modal.Submit
          onClick={onOkay}
          loading={loading}
          variant='danger-light'
          className='pt-16px pb-16px pl-40px pr-40px'
        >
          Delete
        </Modal.Submit>
        <button
          type='button'
          className='btn bg-transparent font-weight-bold text-gray-light font-size-13px'
          onClick={onClose}
        >
          Cancel
        </button>
      </Modal.Footer>
    </ModalPrompt>
  );
};
