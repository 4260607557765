import type { Any, Nil } from '@livecontrol/core-utils';
import type React from 'react';

export namespace Refs {
  export const update = <T = Any>(ref: Nil | React.Ref<T>, value: T | null): void => {
    if (typeof ref === 'function') {
      ref(value);
    } else if (ref) {
      (<React.MutableRefObject<T | null>>ref).current = value;
    }
  };

  export const merge =
    <T = Any>(...refs: (Nil | React.Ref<T>)[]): React.RefCallback<T> =>
    (value: T | null): void => {
      refs.forEach((ref: Nil | React.Ref<T>) => {
        update(ref, value);
      });
    };
}
