import { gql, useMutation } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Bool, Num, Obj, Str } from '@livecontrol/core-utils';
import { Event } from '@livecontrol/scheduler/model';
import { useCallback } from 'react';
import type { MutationResult } from '../graphql';

interface TArgs {
  event_id?: number;
  camera_frame_issue?: boolean;
  camera_movement_issue?: boolean;
  graphics_and_screen_sharing_issue?: boolean;
  lighting_and_exposure_issue?: boolean;
  live_stream_quality_issue?: boolean;
  sound_quality_issue?: boolean;
  technical_extra_comment?: string;
  videographer_attention_issue?: boolean;
  videographer_extra_comment?: string;
  videographer_misunderstanding_issue?: boolean;
  videographer_notes_issue?: boolean;
  videographer_thumb?: boolean;
  technical_thumb?: boolean;
}

interface TData {
  createEventFeedback?: {
    camera_frame_issue?: boolean;
    camera_movement_issue?: boolean;
    graphics_and_screen_sharing_issue?: boolean;
    lighting_and_exposure_issue?: boolean;
    live_stream_quality_issue?: boolean;
    sound_quality_issue?: boolean;
    technical_extra_comment?: string;
    videographer_attention_issue?: boolean;
    videographer_extra_comment?: string;
    videographer_misunderstanding_issue?: boolean;
    videographer_notes_issue?: boolean;
    videographer_thumb: boolean;
    technical_thumb: boolean;
  };
}

interface TVariables {
  eventFeedback: {
    camera_frame_issue?: boolean;
    camera_movement_issue?: boolean;
    graphics_and_screen_sharing_issue?: boolean;
    lighting_and_exposure_issue?: boolean;
    live_stream_quality_issue?: boolean;
    sound_quality_issue?: boolean;
    technical_extra_comment?: string;
    videographer_attention_issue?: boolean;
    videographer_extra_comment?: string;
    videographer_thumb: boolean;
    technical_thumb: boolean;
    videographer_misunderstanding_issue?: boolean;
    videographer_notes_issue?: boolean;
  };
  eventId?: number;
}

interface TResult {
  camera_frame_issue?: boolean;
  camera_movement_issue?: boolean;
  graphics_and_screen_sharing_issue?: boolean;
  id: number;
  lighting_and_exposure_issue?: boolean;
  live_stream_quality_issue?: boolean;
  sound_quality_issue?: boolean;
  technical_extra_comment?: string;
  videographer_attention_issue?: boolean;
  videographer_extra_comment?: string;
  videographer_misunderstanding_issue?: boolean;
  videographer_notes_issue?: boolean;
  videographer_thumb: boolean;
  technical_thumb: boolean;
}

const MUTATION = gql`
  mutation createEventfeedback($eventFeedback: CreateEventFeedbackInput!, $eventId: Float!) {
    createEventFeedback(event_feedback: $eventFeedback, event_id: $eventId) {
      id
    }
  }
`;


export const useUpsertFeedback = (): [(args: TArgs) => Promise<void>, MutationResult<TResult>] => {
  const [mutate, { loading, error, called }] = useMutation<TData, TVariables>(MUTATION, {
    onError() {
      throw Exception.KABOOM;
    }
  });

  const upsertFeedback = useCallback(
    async (args: TArgs): Promise<void> => {

      const required = {
        event_id: Event.toId(args.event_id)
      };

      if (!Obj.isHydrated(required)) {
        throw new Error('EventID/ClientID is required.');
      }

      const variables = {
        videographer_thumb: Bool.normalize(args.videographer_thumb),
        technical_thumb: Bool.normalize(args.technical_thumb),
        camera_frame_issue: Bool.normalize(args.camera_frame_issue),
        camera_movement_issue: Bool.normalize(args.camera_movement_issue),
        graphics_and_screen_sharing_issue: Bool.normalize(args.graphics_and_screen_sharing_issue),
        lighting_and_exposure_issue: Bool.normalize(args.lighting_and_exposure_issue),
        live_stream_quality_issue: Bool.normalize(args.live_stream_quality_issue),
        sound_quality_issue: Bool.normalize(args.sound_quality_issue),
        technical_extra_comment: Str.normalize(args.technical_extra_comment),
        videographer_attention_issue: Bool.normalize(args.videographer_attention_issue),
        videographer_extra_comment: Str.normalize(args.videographer_extra_comment),
        videographer_misunderstanding_issue: Bool.normalize(args.videographer_misunderstanding_issue),
        videographer_notes_issue: Bool.normalize(args.videographer_notes_issue)
      };

      return void (await mutate({
        variables: {
          eventFeedback: {...variables},
          eventId: Num.normalize(args.event_id)
        }
      }));
    },
    [mutate]
  );

  return [upsertFeedback, { loading, error, called }];
};
