import { Viewport } from '@livecontrol/core-ui';
import type { Event } from '@livecontrol/scheduler/model';
import type { Zone } from 'luxon';
import type { ReactElement } from 'react';
import { Formatter } from '../../formatter';
import { Cell } from './cell';

export const DateTime = ({
  event,
  timezone,
  viewport
}: {
  event: Event;
  timezone: Zone;
  viewport: Viewport;
}): ReactElement => (
  <>
    <Cell>
      <span>
        {Formatter.date(event, timezone)}
        {viewport !== Viewport.XL && (
          <>
            <br />
            <span className='font-size-13px text-tertiary'>{Formatter.time(event, timezone)}</span>
          </>
        )}
      </span>
    </Cell>

    {viewport === Viewport.XL && <Cell>{Formatter.time(event, timezone)}</Cell>}
  </>
);
