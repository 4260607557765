import type { Location } from '@livecontrol/scheduler/model';
import { User } from '@livecontrol/scheduler/model';
import { assert } from '@sindresorhus/is';
import axios from 'axios';
import FormData from 'form-data';
import _ from 'lodash';
import shallow from 'zustand/shallow';
import type { Token } from '../../model';
import { store } from '../factory';
import * as Storage from '../storage';
import type { Store } from '../store';
import * as TokenStore from '../token-store';
import * as Dbx_ from './dbx';

/* eslint-disable @typescript-eslint/no-unused-vars */
export import Dbx = Dbx_;

/**
 * React hook that returns the authenticated user (AKA `me`) or the client for whom
 * an administrator is masquerading.
 *
 * @returns Authenticated user. Throws an exception if no user has been authenticated.
 * @throws An exception if no user has been authenticated.)
 */
export const useMe = (): User => {
  // Get both the actual user (`me`) and, potentially, the client for whom an adminstrator is masquerading (`thee`).
  const { me, thee } = store((state: Store.State) => _.pick(state, ['me', 'thee']), shallow);

  // If this is undefined, you've made a mistake.
  assert.object(me);

  // If an administrator is masquerading as a client, return the client.
  return thee && me.role === User.Role.Admin ? thee : me;
};

/**
 * React hook that returns the current state of the indentity parameters.
 *
 * @returns Identity parameters (token, user, client)
 */
export const useIdentity = (): {
  me?: User;
  thee?: User;
  token?: Token;
} => store((state: Store.State) => _.pick(state, ['me', 'thee', 'token']), shallow);

/**
 * React hook that returns the current admin status of the user.
 *
 * @returns Identity parameters (token, user, client)
 */
export const useIsAdmin = (): boolean =>
  store((state: Store.State) => !!state.thee && state.me?.role === User.Role.Admin);

/**
 * Uploads the profile picture for the specified user.
 *
 * @param user - User record.
 * @param image - Image data.
 */
export const uploadProfilePicture = async (user: User, image: string): Promise<void> => {
  const { environment, token } = store.getState();

  assert.object(user);
  assert.string(token);

  const formData = new FormData();

  formData.append('image', image);
  formData.append('id', user.id.toString());

  await axios
    .post(`${environment.REST_URI}/api/v1/upload-profile-picture/image`, formData, {
      headers: {
        Authorization: `bearer ${token}`
      }
    })
    .catch((error: Error) => {
      // @fixme
      // eslint-disable-next-line no-console
      console.log(error);
    });
};

/**
 * Logs in the user associated with the specified authentication token.
 *
 * @param token - Authentication token.
 * @param options - Storage options (persistent/local vs. transient/session)
 */
export const login = (token: Token, options?: Storage.Options): void => {
  TokenStore.setToken(token, options);
};

/**
 * Logs out the current user and resets the authentication store.
 */
export const logout = (): void => {
  Storage.removeItem(Storage.MASQ_KEY);

  TokenStore.setToken(undefined);
};

export const useLocationWithPermission = (
  permissionRequired: User.LocationPermissionsNames,
  locations?: Location[]
): Location[] => {
  // Get both the actual user (`me`) and, potentially, the client for whom an adminstrator is masquerading (`thee`).
  const { me, thee } = store((state: Store.State) => _.pick(state, ['me', 'thee']), shallow);

  // If this is undefined, you've made a mistake.
  assert.object(me);

  // If an administrator is masquerading as a client, return the client.
  const currentUser = thee && me.role === User.Role.Admin ? thee : me;

  if (!locations) {
    return [];
  }

  if (currentUser.permissions.admin) {
    return locations;
  }

  let locationsAvailable: User.LocationPermissions[] = [];

  if (currentUser.sub_user_locations) {
    locationsAvailable = currentUser.sub_user_locations.filter((permission) => {
      switch (permissionRequired) {
        case User.LocationPermissionsNames.EventManagment:
          return permission.eventManagment;
        case User.LocationPermissionsNames.ViewAndDownload:
          return permission.viewAndDownload;
        case User.LocationPermissionsNames.ContentManagment:
          return permission.contentManagment;
        case User.LocationPermissionsNames.ActivityLog:
          return permission.activityLog;
        case User.LocationPermissionsNames.ProductionNotes:
          return permission.productionNotes;
        default:
          return false;
      }
    });

    const availableLocationIds = new Set(
      locationsAvailable.map((permission) => permission.location.id)
    );

    const filteredLocations = locations.filter((location) => availableLocationIds.has(location.id));

    return filteredLocations;
  }

  return [];
};

export const hasEventPemissionForLocation = (locationId: string): boolean => {
  const { me, thee } = store((state: Store.State) => _.pick(state, ['me', 'thee']), shallow);

  assert.object(me);

  const currentUser = thee && me.role === User.Role.Admin ? thee : me;

  if (currentUser.permissions.admin) {
    return true;
  }

  const selectedLocation = currentUser.sub_user_locations?.find(
    (location) => location.location.id === locationId
  );

  if (selectedLocation?.eventManagment) {
    return true;
  }

  return false;
};

export const hasAnalitycsPemissionForLocation = (locationId: string): boolean => {
  const { me, thee } = store((state: Store.State) => _.pick(state, ['me', 'thee']), shallow);

  assert.object(me);

  const currentUser = thee && me.role === User.Role.Admin ? thee : me;

  if (currentUser.permissions.admin) {
    return true;
  }

  const selectedLocation = currentUser.sub_user_locations?.find(
    (location) => location.location.id === locationId
  );

  if (selectedLocation?.viewAndDownload) {
    return true;
  }

  return false;
};

export const hasContentPemissionForLocation = (locationId: string): boolean => {
  const { me, thee } = store((state: Store.State) => _.pick(state, ['me', 'thee']), shallow);

  assert.object(me);

  const currentUser = thee && me.role === User.Role.Admin ? thee : me;

  if (currentUser.permissions.admin) {
    return true;
  }

  const selectedLocation = currentUser.sub_user_locations?.find(
    (location) => location.location.id === locationId
  );

  if (selectedLocation?.contentManagment) {
    return true;
  }

  return false;
};

export { Preferences } from './preferences';
export { useAuthenticate } from './use-authenticate';
export { useEdit } from './use-edit';
export { useForgotPassword } from './use-forgot-password';
export { useResetPassword } from './use-reset-password';
export { useUpdatePassword } from './use-update-password';
export { useSignUp } from './use-sign-up';
export { useUser } from './use-user';
