import type { Location } from '@livecontrol/scheduler/model';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import type { Event, M_Filters, Phase, T_Period, T_Sorting } from '../store';
import { useStore, Utils } from '../store';

export const useTransform = (
  phase: Phase,
  events: readonly Event[],
  onChange?: (filters: {
    location?: Location | Location[];
    keyword?: string;
    period?: T_Period;
    sorting: T_Sorting;
  }) => void
): readonly Event[] => {
  const store = useStore();

  const [isChanged, setIsChanged] = useState(false);

  // Get the current sorting
  const sorting = store(({ model }) => model.value[phase].sorting.value);

  // Keep track of the latest filters
  const [filters, setFilters] = useState(
    store.getState().model.value[phase].filters // not reactive!
  );

  const [prevFilters, setPrevFilters] = useState<M_Filters>();
  const [prevSorting, setPrevSorting] = useState<T_Sorting>();

  // Subscribe to filter changes
  useEffect(
    () =>
      store.subscribe(
        _.debounce((f: M_Filters) => {
          setFilters(f);
          setIsChanged(true);
        }, 500),
        ({ model }) => model.value[phase].filters
      ),
    [store, phase]
  );

  // Subscribe to sorting changes
  useEffect(
    () =>
      store.subscribe(
        () => {
          setIsChanged(true);
        },
        ({ model }) => model.value[phase].sorting.value
      ),
    [store, phase]
  );

  useEffect(() => {
    if (isChanged) {
      const isDateFilterChanged = !!filters.period.value?.max !== !!prevFilters?.period.value?.max;
      const isSearchKeywordChanged = !!filters.keyword.value !== !!prevFilters?.keyword.value;

      let isSearchLocationChanged = false;

      const isSortingKeyChanged = sorting.key !== prevSorting?.key;
      const isSortingDirectionChanged = sorting.direction !== prevSorting?.direction;

      if (prevFilters) {
        if (Array.isArray(filters.location.selected)) {
          const haveLocationChange: boolean[] = [];

          filters.location.selected.forEach((location) => {
            if (Array.isArray(prevFilters.location.selected)) {
              prevFilters.location.selected.forEach((prevLocation: Location) => {
                if (!!location.id !== !!prevLocation.id) {
                  haveLocationChange.push(true);
                }
              });
            }
          });

          isSearchLocationChanged = haveLocationChange.length >= 1;
        } else if (
          typeof filters.location.selected === 'object' &&
          typeof prevFilters.location.selected === 'object' &&
          !Array.isArray(prevFilters.location.selected)
        ) {
          isSearchLocationChanged =
            !!filters.location.selected.id !== !!prevFilters.location.selected.id;
        }
      }

      // according to WEB-1215
      if (
        !isDateFilterChanged &&
        !isSortingKeyChanged &&
        !isSortingDirectionChanged &&
        !isSearchLocationChanged &&
        !isSearchKeywordChanged
      ) {
        // filters were not updated -> don't send mixpanel event
        return;
      }

      onChange?.({
        keyword: filters.keyword.value,
        location: filters.location.selected,
        period: filters.period.value,
        sorting
      });

      setPrevFilters(filters);
      setPrevSorting(sorting);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, isChanged, onChange, setPrevFilters, setPrevSorting, sorting]);

  // React to filter & sorting changes
  return useMemo((): readonly Event[] => {
    const { keyword, location, period } = filters;

    return Utils.sort(
      Utils.filterByKeyword(
        Utils.filterByLocation(Utils.filterByPeriod(events, period.value), location.selected),
        keyword.value
      ),
      sorting
    );
  }, [events, filters, sorting]);
};
