/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Matrix } from '@livecontrol/scheduler/components';
import type { Account, Location } from '@livecontrol/scheduler/model';
import type { FormikContextType } from 'formik';
import { useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Form } from '../../../../components';
import { Counter } from './counter';
import type { Values } from './form';

import G = Matrix.Grid;

const Row = styled(Matrix.Grid.Row)`
  position: relative;

  .users {
    position: sticky;
    left: 0;
    z-index: 2;
  }

  button {
    visibility: hidden;
    display: flex !important;
  }

  &:hover {
    .column {
      border-color: #2e5bff !important;
    }

    button {
      visibility: visible !important;
      display: flex !important;
    }
  }
`;

const CellNoBorder = styled(Matrix.Grid.Row.Cell)`
  border: 0px solid transparent;
  justify-content: center;
  padding: 14px 0px;

  &.transparent {
    background-color: transparent !important;
  }

  &.boder-bottom {
    border-bottom: 1px solid #e4e8f0 !important;
  }

  &:last-child {
    border-right: 0px solid transparent !important;
    padding: 5px 0px !important;
  }

  &:first-child {
    border-left: 0px solid transparent !important;
    position: sticky;
    left: 0;
  }
`;

const Cell = styled(Matrix.Grid.Row.Cell)`
  border-color: #d5daea;
  justify-content: center;
  padding: 14px 0px;

  &.height {
    height: 90px;
  }

  &:first-child {
    border-top-left-radius: var(--spacer-4px);
    border-bottom-left-radius: var(--spacer-4px);
  }
  &:last-child {
    border-top-right-radius: var(--spacer-4px);
    border-bottom-right-radius: var(--spacer-4px);
    padding: 14px 0px;
  }
`;

interface Props {
  formik: FormikContextType<Values>;
  subuser: Account.SubUser;
  locations?: Location[];
  roleContact: number;
}

enum ContactRole {
  ONBOARDING_MAIN = 1,
  ONBOARDING_TECHNICAL = 2,
  PRIMARY_BILLING = 3,
  SHIPPING = 4,
  ONSITE_TECHINCAL = 5
}

export const RowUser = ({ formik, locations, roleContact, subuser }: Props): ReactElement => {
  const [rowSelected, setRowSelected] = useState(false);

  const selectRow = (): void => {
    locations?.forEach((location): void => {
      const fieldNamePrefix = `${location.id}._${subuser.id}`;

      if (roleContact === ContactRole.ONSITE_TECHINCAL) {
        formik.setFieldValue(`${fieldNamePrefix}.priority`, 1);
        formik.setFieldValue(`${fieldNamePrefix}.value`, !rowSelected);
      } else {
        formik.setFieldValue(fieldNamePrefix, !rowSelected);
      }
    });
  };

  const checkSelectedAll = (): void => {
    const { values: locationsWithSelectedUser } = formik;
    let isSelectedAll = true;

    for (const location of locations ?? []) {
      const locationId = String(location.id);

      const locationWithSelectedUser = locationsWithSelectedUser[locationId];
      const subuserKey = `_${subuser.id}` as keyof typeof locationWithSelectedUser;
      const fieldValue = locationWithSelectedUser?.[subuserKey];

      let hasRole = !(fieldValue === undefined) && fieldValue;

      if (ContactRole.ONSITE_TECHINCAL === roleContact) {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        hasRole = hasRole && fieldValue?.['value'];
      }

      if (!hasRole) {
        isSelectedAll = false;

        break;
      }
    }

    setRowSelected(isSelectedAll);
  };

  useEffect(() => {
    checkSelectedAll();
  }, [formik.values]);

  return (
    <>
      <G.Row>
        <CellNoBorder className='column height-10px' />
        {roleContact === 1 || roleContact === 3 ? (
          <CellNoBorder className='column height-10px' />
        ) : (
          locations?.map((location) => (
            <CellNoBorder key={`${location.id}`} className='column height-10px' />
          ))
        )}
      </G.Row>
      <Row>
        <Cell className='column users justify-content-start'>
          <div className='d-flex flex-column pr-4px'>
            <div className='text-dark font-weight-bold font-size-12px'>
              {subuser.firstName} {subuser.lastName}
            </div>
            {roleContact === ContactRole.ONBOARDING_TECHNICAL ||
            roleContact === ContactRole.SHIPPING ||
            roleContact === ContactRole.ONSITE_TECHINCAL ? (
              <button
                onClick={(): void => {
                  selectRow();
                }}
                type='button'
                className='btn p-0 bg-transparent text-primary font-weight-bold font-size-12px'
              >
                {!rowSelected ? 'Select All' : 'Deselect All'}
              </button>
            ) : (
              <div className='d-none' />
            )}
          </div>
        </Cell>

        {roleContact === ContactRole.ONBOARDING_MAIN ||
        roleContact === ContactRole.PRIMARY_BILLING ? (
          <Cell key={`${subuser.id}`} className='column'>
            <Form.Group
              name={`${subuser.id}`}
              label=''
              className='mb-0'
              controlProps={{
                type: 'checkbox'
              }}
            />
          </Cell>
        ) : (
          locations?.map((location) =>
            roleContact === 5 ? (
              <Cell key={`${location.id}${subuser.id}`} className='column height'>
                <Counter formik={formik} formName={`${location.id}._${subuser.id}`} />
              </Cell>
            ) : (
              <Cell key={`${location.id}${subuser.id}`} className='column'>
                <Form.Group
                  name={`${location.id}._${subuser.id}`}
                  label=''
                  className='mb-0'
                  controlProps={{
                    type: 'checkbox'
                  }}
                />
              </Cell>
            )
          )
        )}
      </Row>
    </>
  );
};
