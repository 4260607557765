import type { ReactElement } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { SectionContainer } from '../../components';
import { Store } from '../../store';
import { DestinationsGrid } from './components';
import { AddIntegration } from './integrations';

export const ViewDestinations = (): ReactElement => {
  const me = Store.User.useMe();
  const history = useHistory();
  const { type: addIntegrationType } = useParams<{ type?: string }>();

  const showAddIntegrationModal = !!addIntegrationType;

  const canEditDestinations = me.permissions.destinations;

  if (!canEditDestinations) {
    return <Redirect to='/schedule' />;
  }

  return (
    <SectionContainer className='col-12'>
      <DestinationsGrid />
      <AddIntegration
        show={showAddIntegrationModal}
        handleClose={(): void => {
          history.push('/stream-settings/destinations');
        }}
        onDone={(): void => {
          history.push('/stream-settings/destinations');
        }}
        integrationType={addIntegrationType ?? 'facebook'}
      />
    </SectionContainer>
  );
};
