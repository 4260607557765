import { ClickableCard, Form } from '@livecontrol/core-ui';
import type { Any } from '@livecontrol/core-utils';
import { UUID } from '@livecontrol/core-utils';
import { Modal } from '@livecontrol/scheduler/components';
import { Destination, Event } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { EventValidator } from '@livecontrol/scheduler/validator';
import cx from 'classnames';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Storage } from '../../../store';
import { EventContextStore } from '../event-store';
import type { EventContext } from '../event-store';
import { CustomInputContainer, HeaderSection } from '../layout-sections';
import { FacebookPermissions, YoutubePermissions } from '../modals';

const StyledFormLayout = styled.div`
  width: 100%;
`;

const StyledBoxSelect = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .destination-card {
    margin: 11px;

    &.active {
      .fa-signal-stream {
        color: var(--primary) !important;
      }
    }

    .destination-icon-section {
      width: 36px;
      height: 36px;

      &.rtmp {
        width: auto;
        height: auto;
        font-size: 25px;
      }

      &.rtmp {
        width: auto;
        height: auto;
        font-size: 25px;
      }

      .destination-icon {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
      }
      .facebook {
        background-image: url(/assets/icons/destinations/facebook-destination.svg);
      }
      .livecontrol {
        background-image: url(/assets/icons/destinations/livecontrol-destination.svg);
      }
      .youtube {
        background-image: url(/assets/icons/destinations/youtube-destination.svg);
      }
      .private {
        background-image: url(/assets/icons/destinations/private-destination.svg);
      }
    }
    .active {
      .facebook {
        background-image: url(/assets/icons/destinations/facebook-destination-blue.svg);
      }
      .livecontrol {
        background-image: url(/assets/icons/destinations/livecontrol-destination-blue.svg);
      }
      .youtube {
        background-image: url(/assets/icons/destinations/youtube-destination-blue.svg);
      }
      .private {
        background-image: url(/assets/icons/destinations/private-destination-blue.svg);
      }
    }
  }
  .destination-label {
    max-width: calc(100% - 6.5rem);
  }

  .icon-card {
    width: 64px;
    height: 64px;
    border-radius: 6px;
    border: 2px solid #d5daea;
  }
  .active .icon-card {
    border: 2px solid var(--primary);
  }
  .destination-info-section {
    margin-top: 8px;
    width: 166px;

    .destination-provider {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #9c9c9c;
    }
    .destination-name {
      font-weight: 600;
      font-size: 9px;
      line-height: 18px;
      color: #9c9c9c;
    }
  }

  .active .destination-info-section {
    .destination-provider {
      color: #2e5bff;
    }
    .destination-name {
      color: #2e384d;
    }
  }
  .text-danger {
    white-space: normal;
  }
`;

export const DestinationsSection = ({
  destinations
}: // disabled = false
{
  destinations: Destination[];
  // disabled?: boolean;
}): React.ReactElement => {
  const { store }: EventContext = useContext<EventContext>(EventContextStore);
  const [showYoutubePermissionsModal, setShowYoutubePermissionsModal] = useState(false);
  const [showFacebookPermissionsModal, setShowFacebookPermissionsModal] = useState(false);
  const modalPrompt = Modal.usePrompt();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const uuid = useMemo(UUID.make, []);

  const destinationOptions = useMemo(
    (): Destination[] => [Scheduler.Destination.LC_WEBPLAYER, ...destinations],
    [destinations]
  );

  const onChangeSelection = useCallback(
    (selected: Destination): void => {
      if (!selected.deauthorized) {
        if (store.eventDestinations.some((destination) => destination.id === selected.id)) {
          store.setEventDestinations(
            store.eventDestinations.filter((destination) => destination.id !== selected.id)
          );
        } else if (store.eventDestinations.length === 0) {
          store.setEventDestinations(
            selected.service === Destination.Service.LiveControl
              ? [selected]
              : [Scheduler.Destination.LC_WEBPLAYER, selected]
          );
        } else if (store.eventDestinations.length <= Event.Constraints.MAX_DESTINATIONS) {
          store.setEventDestinations([...store.eventDestinations, selected]);
        } else {
          modalPrompt.error({
            title: 'Maximum Destinations',
            content: `Oops! We allow a maximum of ${Event.Constraints.MAX_DESTINATIONS} external destinations. To stream to this destination, you will need to deselect one of the others first.`
          });
        }
      } else {
        Storage.setItem(
          uuid,
          JSON.stringify(store, (_key, value: Any): Any => {
            if (typeof value === 'object' && value instanceof Set) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              return [...value];
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return value;
          }),
          { persistent: false }
        );

        if (selected.service === Destination.Service.YouTube) {
          setShowYoutubePermissionsModal(true);
        } else if (selected.service === Destination.Service.Facebook) {
          setShowFacebookPermissionsModal(true);
        }
      }
    },
    [modalPrompt, store, uuid]
  );

  return (
    <>
      <HeaderSection>
        <div className='divider' />
        <div className='title'>
          <div className='text'>Streaming Options </div>
        </div>
      </HeaderSection>

      <div>
        <CustomInputContainer>
          <Form.Descriptor
            title='Live Stream Destinations'
            titleClassName='title mr-lg-3 font-size-15px font-weight-bold text-dark pb-8px'
            description='Choose where you would like your event to be streamed. The LiveControl web player will always be selected. We allow a maximum of 4 additional destinations.'
            className='form-section-lateral d-lg-flex'
            descriptionClassName='font-size-13px text-secondary font-weight-bold text-gray-light mb-8px line-height-18px'
          >
            {/* <DestinationForm onChange={onChangeSelection} destinations={destinations ?? []} /> */}
            <StyledFormLayout>
              <StyledBoxSelect className='ml-12px'>
                {destinationOptions.map((destination, index) => {
                  const hasError = destination.deauthorized;

                  let isActive =
                    destination.service === Destination.Service.LiveControl
                      ? !!store.eventDestinations.length
                      : store.eventDestinations.some(
                          (eventDestination) => eventDestination.id === destination.id
                        );

                  if (
                    store.eventType === EventValidator.ProductionType.TEST &&
                    store.eventDestinations.length < 1 &&
                    index === 0
                  ) {
                    isActive = true;
                    onChangeSelection(destination);
                  }

                  return (
                    <div
                      key={destination.id}
                      className={cx(
                        hasError && 'has-error',
                        isActive && 'active',
                        'destination-card'
                      )}
                    >
                      <ClickableCard.Button
                        status={
                          hasError
                            ? ClickableCard.Status.Error
                            : isActive
                            ? ClickableCard.Status.Active
                            : undefined
                        }
                        className={cx(hasError && 'has-error', isActive && 'active', 'icon-card')}
                        disabled={false}
                        onClick={(): void => {
                          if (
                            destination.service !== Destination.Service.LiveControl ||
                            !store.eventDestinations.length
                          ) {
                            onChangeSelection(destination);
                          }
                        }}
                      >
                        <div className='d-flex align-items-center justify-content-center'>
                          <div
                            className={cx(
                              'destination-icon-section',
                              destination.service === Destination.Service.RTMP && 'rtmp'
                            )}
                          >
                            {destination.service === Destination.Service.LiveControl ? (
                              <div className='destination-icon livecontrol' />
                            ) : destination.service === Destination.Service.Facebook ? (
                              <div className='destination-icon facebook' />
                            ) : destination.service === Destination.Service.YouTube ? (
                              <div className='destination-icon youtube' />
                            ) : (
                              <div className='destination-icon'>
                                <i className='text-secondary fal fa-signal-stream' />
                              </div>
                            )}
                          </div>
                        </div>
                      </ClickableCard.Button>
                      <div className='destination-info-section'>
                        <div className='destination-provider'>
                          {Destination.Service[destination.service]}
                        </div>
                        {hasError ? (
                          <p className='mb-0 text-danger font-size-13px font-weight-normal'>
                            Connection Error
                          </p>
                        ) : (
                          <div className='destination-name'>{destination.name}</div>
                        )}
                      </div>
                    </div>
                  );
                })}
                {/* <div className='destination-card'>
                  <ClickableCard.Button
                    status={
                      undefined
                    }
                    className='icon-card'
                    variant='tint-ø2'
                    disabled={false}
                    onClick={(): void => {
                        onChangeSelection(destinations[0]);
                      }
                    }
                  >
                    <div className='d-flex align-items-center justify-content-center'>
                      <div className='destination-icon-section'>
                          <div className="destination-icon facebook" />
                      </div>
                    </div>
                  </ClickableCard.Button>
                  <div className='destination-info-section'>
                    <div className='destination-provider'>
                      Facebook Live
                    </div>
                    <div className='destination-name'>Maryville First United Methodist...</div>
                  </div>
                </div> */}
              </StyledBoxSelect>
            </StyledFormLayout>
          </Form.Descriptor>
        </CustomInputContainer>

        {store.eventType !== EventValidator.ProductionType.TEST && (
          <CustomInputContainer>
            <Form.Descriptor
              title='Private Event'
              titleClassName='title mr-lg-3 font-size-15px font-weight-bold text-dark pb-8px'
              description='This event will be recorded to your content library, but the stream will not be visible to the public.'
              className='form-section-lateral d-lg-flex align-items-center'
              descriptionClassName='font-size-13px text-secondary font-weight-bold text-gray-light mb-8px line-height-18px'
            >
              <StyledBoxSelect className='ml-12px'>
                <div
                  className={cx(
                    store.eventDestinations.length === 0 && 'active',
                    'destination-card'
                  )}
                >
                  <ClickableCard.Button
                    status={
                      store.eventDestinations.length === 0 ? ClickableCard.Status.Active : undefined
                    }
                    className={cx(store.eventDestinations.length === 0 && 'active', 'icon-card')}
                    variant='tint-ø2'
                    disabled={false}
                    onClick={(): void => {
                      store.setEventDestinations([]);
                    }}
                  >
                    <div className='d-flex align-items-center justify-content-center'>
                      <div className='destination-icon-section'>
                        <div className='destination-icon private' />
                      </div>
                    </div>
                  </ClickableCard.Button>
                  <div className='destination-info-section'>
                    <div className='destination-provider'>Record Only</div>
                    {store.eventInfo.passwordSettings?.enabled &&
                      store.eventDestinations.length === 0 && (
                        <p className='mb-0 text-danger font-size-13px font-weight-normal'>
                          This event will not be password protected, since it will not be
                          live-streamed online.
                        </p>
                      )}
                  </div>
                </div>
              </StyledBoxSelect>
            </Form.Descriptor>
          </CustomInputContainer>
        )}
      </div>
      {showYoutubePermissionsModal && (
        <YoutubePermissions
          uuid={uuid}
          onClose={(): void => {
            setShowYoutubePermissionsModal(false);
          }}
        />
      )}
      {showFacebookPermissionsModal && (
        <FacebookPermissions
          uuid={uuid}
          onClose={(): void => {
            setShowFacebookPermissionsModal(false);
          }}
        />
      )}
    </>
  );
};
