import { Device } from '@livecontrol/core-ui';
import cx from 'classnames';
import React from 'react';
import type { HTMLAttributes, PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';
import { Blueprint } from './blueprint';
import { Content } from './content';
import { Context, useStore } from './store';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  position: relative;

  div {
    transition: all var(--transition);
  }

  // .x-${Device.MOBILE} & {
  //   padding-top: ${Blueprint.Header.height[Device.MOBILE]}px;
  // }
`;

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 10px;
  justify-content: center;

  .x-${Device.MOBILE} & {
    padding: 16px 0px;
  }
`;

interface Props {
  header: React.ReactElement;
  sidebar: React.ReactElement;
  contentClassName?: string;
}

export const Scaffold = ({
  className,
  children,
  header,
  sidebar,
  ...rest
}: PropsWithChildren<HTMLAttributes<HTMLDivElement> & Props>): ReactElement => {
  const store = useStore();
  const { viewport, sidebar: sidebarStatus } = store;

  const paddingLeft = Device.isTablet(viewport)
    ? Blueprint.Sidebar.width.collapsed
    : Device.isDesktop(viewport)
    ? Blueprint.Sidebar.width[sidebarStatus.isExpanded ? 'expanded' : 'collapsed']
    : 0;

  return (
    <Context.Provider value={store}>
      <div
        className={cx(
          'd-flex',
          'flex-column',
          'min-vh-100',
          `x-${Device.from(viewport)}`,
          className
        )}
        {...rest}
      >
        {sidebar}

        <Container>
          <div className='d-flex flex-grow-1 w-100' style={{ paddingLeft, flexDirection: 'column' }}>
            {header}
            <MainContainer>
              <Content>{children}</Content>
            </MainContainer>
          </div>
        </Container>
      </div>
    </Context.Provider>
  );
};
