import cx from 'classnames';
import { Children } from 'react';
import type { HTMLAttributes, PropsWithChildren, ReactElement } from 'react';

const Separator = (): ReactElement => <span className='mx-4px text-light'>|</span>;

export const LinkBox = ({
  className,
  children,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>): ReactElement => (
  <div className={cx('font-size-13px', className)} {...props}>
    {Children.toArray(children).map((child, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <span key={index}>
        {index > 0 && <Separator />}
        {child}
      </span>
    ))}
  </div>
);
