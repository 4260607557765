import cx from 'classnames';
import _ from 'lodash';
import type { Zone } from 'luxon';
import { DateTime } from 'luxon';
import React from 'react';
import DatePicker from 'react-datepicker';
import type { ReactDatePickerProps } from 'react-datepicker';
import { Utils } from './utils';
import 'react-datepicker/dist/react-datepicker.css';

export const DateSelector = ({
  className,
  isInvalid,
  timezone,
  // Formatting Docs: https://date-fns.org/v2.16.1/docs/format
  value,
  ...rest
}: DateSelector.Props): React.ReactElement => {
  const field = Utils.useField(rest);

  const onSelectDate = (date: Date): void => {
    if (field) {
      field.helpers.setTouched(true);
      field.helpers.setValue(DateTime.fromJSDate(date, { zone: timezone }));
    }
  };

  return (
    <div className={cx(isInvalid && 'is-invalid')}>
      <DatePicker
        className={cx('form-control', isInvalid && 'is-invalid', className)}
        calendarClassName='lc-datepicker'
        onChange={onSelectDate}
        selected={value?.toJSDate()}
        {..._.omit(rest, 'onChange')}
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace DateSelector {
  export type Props = Omit<ReactDatePickerProps, 'value'> & {
    isInvalid: boolean;
    timezone: Zone;
    value?: DateTime;
  };
}
