import _ from 'lodash';
import { Nix } from './nix';
import type { Hydrated } from './types';

/* eslint-disable @typescript-eslint/ban-types */

// When you really want an object and want ESLint to shut up.
export type Obj = Object;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Obj {
  export type Rec = Record<string, unknown>;

  /**
   * Determines if the specified value is object-like.
   *
   * @param value - The value to be queried.
   * @returns A type assertion indicating whether or not the specified value is an object.
   */
  export const isObject = <T extends Obj = Obj>(value: unknown): value is T =>
    _.isObjectLike(value) && !Array.isArray(value);

  /**
   * Determines if the specified value is a plain object.
   *
   * @param value - The value to be queried.
   * @returns A type assertion indicating whether or not the specified value is a plain object.
   */
  export const isPlainObject = <T extends Obj = Obj>(value: unknown): value is T =>
    _.isPlainObject(value);

  /**
   * Converts the specified value to a plain object.
   *
   * @param value - The value to be converted.
   * @returns The converted object or `undefined`.
   */
  export const normalize = <T extends Obj>(value: unknown): T | undefined =>
    isObject<T>(value) ? <T>_.toPlainObject(value) : undefined;

  /**
   * Converts the specified value to a keyed record.
   *
   * @param value - The value to be converted.
   * @returns The converted object or `undefined`.
   */
  export const toRecord = <T extends Rec>(value: unknown): T | undefined => normalize<T>(value);

  /**
   * Determines if the specified object contains only non-nil values.
   *
   * @param value - The value to be queried.
   * @returns A type assertion indicating whether or not the specified value is hydrated.
   */
  export const isHydrated = <T extends Obj>(value?: Partial<T>): value is Hydrated<T> => {
    const obj = normalize(value);

    return !!obj && !Object.values(obj).some((µ: unknown) => Nix.isNil(µ));
  };

  /**
   * Remove all properties from the object whose values are `null` or `undefined`.
   *
   * @param value - The object to be winnowed.
   * @returns A copy of the original object with all `null | undefined` properties removed.
   */
  export const winnow = <T extends object>(value: Partial<T>): Hydrated<T> =>
    <Hydrated<T>>_.omitBy(value, Nix.isNil);

  /**
   * Wrapper around `Object.keys` that returns `typeof T[]`.  If you know what you are doing,
   * this function works correctly.  But if you are going to lie to the compiler, make sure
   * you understand the caveats:
   *
   * https://stackoverflow.com/questions/55012174/why-doesnt-object-keys-return-a-keyof-type-in-typescript
   *
   * @param obj - Object whose keys are to be returned.
   * @returns The object's keys.
   */
  export const keys = <T>(obj: T): (keyof T)[] => <(keyof T)[]>Object.keys(obj);
}
