import type { FormikContextType } from 'formik';
import { useFormik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';
import { Alert, Store } from '../../store';

interface Values {
  email: string;
  password: string;
  remember: boolean;
}

interface Form {
  formik: FormikContextType<Values>;
  loading: boolean;
  alert: Alert;
}

const validationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .required('Please enter a valid email address.')
    .email('Please enter a valid email address.'),
  password: Yup.string().trim().required('A valid password is required.')
});

export const useForm = (): Form => {
  const [authenticate, { loading, error }] = Store.User.useAuthenticate();
  const alert = Alert.useAlert(error);

  const formik = useFormik<Values>({
    initialValues: { email: '', password: '', remember: true },

    validationSchema,
    validateOnMount: true,

    validate(): void {
      alert.hide();
    },

    async onSubmit(data: Values): Promise<void> {
      alert.hide();

      if (!loading) {
        // Execute the mutation
        const token = await authenticate(_.omit(data, 'remember'));

        if (token) {
          // Houston, we have received authorization to proceed
          Store.User.login(token, { persistent: formik.values.remember });
        }
      }
    }
  });

  return {
    formik,
    loading,
    alert
  };
};
