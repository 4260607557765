import { Filters } from '@livecontrol/scheduler/components';
import { useCallback } from 'react';
import type { ReactElement } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import type { Phase } from '../store';
import { useStore } from '../store';

import P = Filters.Period;

export const Period = ({ phase, ...props }: P.Props & { phase: Phase }): ReactElement => {
  const { value, update } = useStore(({ model }) => model.value[phase].filters.period);

  const onChange = useCallback(
    (v?: P.Value): void => {
      update(v);
    },
    [update]
  );

  return <P value={value} {...props} onChange={onChange} as={ButtonGroup} />;
};
