import { Theme, Viewport } from '@livecontrol/core-ui';
import { Str } from '@livecontrol/core-utils';
import type { SubUser } from '@livecontrol/scheduler/model';
import type { ReactElement } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import type { DropdownButtonProps } from 'react-bootstrap/DropdownButton';
import DropdownButton from 'react-bootstrap/DropdownButton';
import styled from 'styled-components';

const FilterDropwdown = styled.div`
  position: relative;

  .btn-group > .btn:hover,
  .btn-group > .btn:focus {
    z-index: 0;
  }

  button.btn-outline-tertiary {
    color: #0d1438;
    border-color: #8798ad;
  }

  button.btn-outline-tertiary:hover {
    border-color: #8798ad;
  }

  .show > button.btn-outline-tertiary:hover {
    color: #fff !important;
    background-color: #8798ad;
  }

  .dropdown-menu.show {
    background-color: white;
  }

  button.dropdown-toggle {
    position: relative;
    padding: 8px 11px 8px 37px;
    color: #0d1438;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button.dropdown-toggle:after {
    display: none;
  }

  .icon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 8px;
    left: 11px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    margin-right: 6px;
  }

  .filter {
    background-image: url(/assets/icons/Filter-black.svg);
  }
`;

export function User(props: User.Props): ReactElement {
  const viewport = Theme.useViewport();

  const {
    value: { selected, users },
    onChange,
    ...rest
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const title = viewport === Viewport.XS ? 'Action' : selected?.fullName ?? 'All Users';

  return (
    <FilterDropwdown>
      <DropdownButton
        variant={selected ? 'tint' : 'outline-tertiary'}
        title={Str.truncate(title, { length: 30 })}
        {...rest}
      >
        <Dropdown.Item
          key='all'
          onClick={(): void => {
            onChange?.(undefined);
          }}
        >
          All Users
        </Dropdown.Item>

        {users.map(({ id, fullName }): ReactElement => {
          const active = id === selected?.id;

          return (
            <Dropdown.Item
              key={id}
              active={active}
              onClick={(): void => {
                onChange?.(!active ? { id } : undefined);
              }}
            >
              {fullName}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
      <div className='icon filter' />
    </FilterDropwdown>
  );
}

export namespace User {
  export interface Value {
    users: SubUser[];
    selected?: SubUser;
  }

  export type Props = Omit<DropdownButtonProps, 'onChange' | 'title'> & {
    value: Value;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    users?: any;
    onChange?: (value?: { id: number }) => void;
  };
}
