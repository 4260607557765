import { Button } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import { Asset } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { useState } from 'react';
import type { ReactElement } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Store } from '../../../../store';
import { Confirm } from './confirm';

import Viz = Asset.Visibility;

type Props = Button.Props & {
  asset: Asset;
};

const ButtonContainer = styled.div`
  button {
    width: 100%;
    background-color: rgba(255, 0, 0, 0.1) !important;
    color: #ff0000 !important;
    border: none !important;
    font-size: 12px;
    font-weight: 600;
    padding: 6px 11px !important;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    margin: 0px 0px 8px;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 6px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
    .trash {
      background-image: url(/assets/icons/Trash-red.svg);
    }
  }
`;

export const DeleteButton = ({ asset, ...props }: Props): ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const [deleteAsset, deleteResult] = Scheduler.Asset.useDelete();
  const [editAsset, editResult] = Scheduler.Asset.useEdit();
  const prompt = Modal.usePrompt();
  const history = useHistory();
  const account = Store.Account.useAccount();

  const loading = deleteResult.loading || editResult.loading;

  const doDelete = (commit: boolean, privitize: boolean = false): void => {
    // Close the modal window
    setShowModal(false);

    // Did the user confirm the change?
    if (commit && !loading) {
      deleteAsset({ account, asset })
        .then(() => {
          // Go back
          history.push('/content-library/library');
        })
        .catch((error: Error) => {
          prompt.error({ content: error.message });
        });
    } else if (
      privitize &&
      // Is this video public?
      asset.visibility === Viz.Public
    ) {
      editAsset({ asset, account, changes: { visibility: Viz.Private } }).catch((error: Error) => {
        prompt.error({ content: error.message });
      });
    }
  };

  return (
    <ButtonContainer>
      <Button
        {...props}
        variant='outline-danger'
        onClick={(): void => {
          // Show the confirmation modal (if not loading)
          if (!loading) {
            setShowModal(true);
          }
        }}
        disabled={loading}
      >
        <div className='icon trash' />
        <div className='text'>Delete Event</div>
      </Button>

      {showModal && <Confirm asset={asset} onConfirm={doDelete} />}
    </ButtonContainer>
  );
};
