import { Scheduler } from '@livecontrol/scheduler/store';
import { EventValidator } from '@livecontrol/scheduler/validator';
import type { FormikContextType } from 'formik';
import { useFormik } from 'formik';
import _ from 'lodash';
import { DateTime } from 'luxon';
import type { Zone } from 'luxon';
import moment from 'moment';
import { useCallback, useRef } from 'react';
import { Alert } from '../../../../store';
import type { Event } from '../../store';

export interface Props {
  event: Event;
  timezone: Zone;
  onClose: () => void;
  onSubmit?: (success: boolean, endTime?: DateTime) => void;
}

interface Values {
  end?: moment.Moment;
}

interface Form {
  formik: FormikContextType<Values>;
  loading: boolean;
  alert: Alert;
}

const FORMAT = 'hhmm a';

const translate = (event: Event, zone: Zone, value?: moment.Moment): DateTime | undefined => {
  let result;

  if (value) {
    const clone = _.cloneDeep(event.start);

    const time = DateTime.fromFormat(value.format(FORMAT), FORMAT, {
      zone
    }).toUTC();

    if (time.isValid) {
      result = clone.set({ hour: time.hour, minute: time.minute });

      if (result < event.start) {
        result = result.plus({ day: 1 });
      }
    }
  }

  return result;
};

export const useForm = ({ event, timezone, onSubmit }: Props): Form => {
  const [mutation, { loading, error }] = Scheduler.Event.useExtend();

  const alert = Alert.useAlert(error);

  const initialValues = useRef({
    end: moment(event.end.setZone(timezone).toFormat(FORMAT), FORMAT)
  }).current;

  const validate = useCallback(
    (values: Values): Record<string, string> => {
      alert.hide();

      const errs: Record<string, string> = {};

      // Validate the end time
      const value = translate(event, timezone, values.end);

      if (value) {
        if (value <= DateTime.utc()) {
          errs.end = 'Please specify a time in the future.';
        }
      } else {
        errs.end = EventValidator.ErrorMessages.INVALID_TIME_FORMAT;
      }

      return errs;
    },
    [alert, event, timezone]
  );

  const formik = useFormik<Values>({
    initialValues,
    validate,
    validateOnMount: true,
    validateOnBlur: true,

    async onSubmit(values: Values) {
      // If not loading, execute the mutation
      if (!loading) {
        const end = translate(event, timezone, values.end);

        if (end && (await mutation({ event, end }))) {
          onSubmit?.(true, end);
        } else {
          onSubmit?.(false);
        }
      }
    }
  });

  return {
    formik,
    loading,
    alert
  };
};
