import { Obj } from '@livecontrol/core-utils';
import { Filters } from '@livecontrol/scheduler/components';
import { Event as Event_ } from '@livecontrol/scheduler/model';
import type { ReactElement } from 'react';
import { useStore } from '../store';

import L = Filters.Event;

export const Event = ({
  ...props
}: Omit<L.Props<Event_.EventType.Key>, 'value'> ): ReactElement | null => {

  const event = useStore(({ model }) => model.value.filters.event);

  const { keys } = Event_.EventType;

  const value = {
    selected: event.value,
    available: Obj.keys(keys).map((key) => ({
      key,
      label: keys[key]
    }))
  };

  return <L value={value} {...props} onChange={event.update} />;
};
