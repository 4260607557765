import { Viewport } from '@livecontrol/core-ui';

export const Column = {
  DESTINATION: 'fit-content(60px)',
  DATETIME: (viewport: Viewport): string =>
    viewport === Viewport.XL ? 'repeat(2, max-content)' : 'max-content',
  TITLE: 'minmax(auto, 2fr)',
  LOCATION: 'minmax(auto, 1fr)',
  TYPE: (viewport: Viewport): string => (viewport === Viewport.MD ? 'min-content' : 'auto')
};
