import _ from 'lodash';
import type { Zone } from 'luxon';
import { DateTime } from 'luxon';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import moment from 'moment';
import { Num } from './num';
import { Str } from './str';

export namespace Time {
  export const UTC = 'utc';

  export namespace Encoding {
    export type ISO_8601 = string;
    export type EPOCH = number;
    export type EPOCH_MILLIS = number;
    export type SECONDS = number;
    export type MILLISECONDS = number;
  }

  export const now = (): {
    timestamp: DateTime;
    iso: Encoding.ISO_8601;
    epoch: Encoding.EPOCH;
    epochMillis: Encoding.EPOCH_MILLIS;
  } => {
    const timestamp = DateTime.utc();

    return {
      timestamp,
      iso: timestamp.toISO(),
      epoch: Math.round(timestamp.toSeconds()),
      epochMillis: timestamp.toMillis()
    };
  };

  export const normalize = (
    value: unknown,
    options?: { zone?: Zone | string }
  ): DateTime | undefined => {
    const opts = _.defaults({}, options, { zone: UTC });

    // Is this already a Luxon DateTime?
    if (DateTime.isDateTime(value)) {
      const dt = value.setZone(opts.zone);

      return dt.isValid ? dt : undefined;
    }

    // Is this a JS Date?
    if (value instanceof Date) {
      const dt = DateTime.fromJSDate(value, opts);

      return dt.isValid ? dt : undefined;
    }

    // Is this a Moment date?
    if (moment.isMoment(value)) {
      const dt = DateTime.fromISO(value.toISOString(), opts);

      return dt.isValid ? dt : undefined;
    }

    // Is it a unix timestamp/number?
    const numValue = Num.normalize(value);

    if (numValue) {
      const dt = DateTime.fromSeconds(numValue, opts);

      if (dt.isValid) {
        return dt;
      }
    }

    // Is it a string?
    const strValue = Str.normalize(value);

    if (strValue) {
      // Is it an ISO string
      try {
        const dt = DateTime.fromISO(strValue, opts);

        if (dt.isValid) {
          return dt;
        }
      } catch {
        // ignore
      }
    }

    return undefined;
  };

  export type Parts = Map<string, Intl.RelativeTimeFormatPart>;

  export const parts = (value: DateTime, options?: Intl.DateTimeFormatOptions): Parts =>
    new Map(
      value.toLocaleParts(options).map((part: Intl.RelativeTimeFormatPart) => [part.type, part])
    );

  export const deprecatedZones: ReadonlyMap<string, string> = new Map<string, string>([
    ['US/Eastern', 'America/New_York'],
    ['US/Central', 'America/Chicago'],
    ['US/Mountain', 'America/Denver'],
    ['US/Pacific', 'America/Los_Angeles'],
    ['US/Arizona', 'America/Phoenix'],
    ['US/Alaska', 'America/Anchorage'],
    ['US/Hawaii', 'Pacific/Honolulu']
  ]);
}
