import { Modal } from '@livecontrol/scheduler/components';
import { Asset } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import cx from 'classnames';
import { useState } from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components';
import { Store } from '../../../../store';
import { Confirm } from './confirm';

import Viz = Asset.Visibility;

type Props = HTMLAttributes<HTMLDivElement> & {
  asset: Asset;
};

const ButtonContainer = styled.div`
  .dropdown {
  }
  .show.dropdown {
    button {
      background-color: rgba(46, 91, 255, 0.1);
      color: #2e5bff;
    }
    &:hover .eye {
      background-image: url(/assets/icons/Eye-blue.svg);
    }
    &:hover .eye-slash {
      background-image: url(/assets/icons/Eye-slash-blue.svg);
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(46, 91, 255, 0.1);
    border: none;
    color: #2e5bff;
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 6px 11px !important;
    border-radius: 4px;
    margin-right: 8px;

    &:hover {
      background-color: #2e5bff;
      color: #ffffff;
    }

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 6px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }

    .eye {
      background-image: url(/assets/icons/Eye-blue.svg);
    }

    .eye-slash {
      background-image: url(/assets/icons/Eye-slash-blue.svg);
    }

    &:hover .eye {
      background-image: url(/assets/icons/Eye.svg);
    }
    &:hover .eye-slash {
      background-image: url(/assets/icons/Eye-slash.svg);
    }
  }

  .dropdown-menu.show {
    min-height: 50px;
    min-width: 120px;
    padding: 12px 16px;
    background-color: white;
    border-radius: 8px;
    transform: none !important;
    inset: unset !important;
    top: calc(100% + 8px) !important;

    a.dropdown-item {
      border: 1px solid #cbcbcb;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 600;
      color: #0d1438;
    }

    .dropdown-item {
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        width: 20px;
        height: 20px;
        margin-right: 6px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }

      .eye {
        background-image: url(/assets/icons/Eye-black.svg);
      }

      .eye-slash {
        background-image: url(/assets/icons/Eye-slash-black.svg);
      }
    }
  }
`;

export const VisibilityButton = ({ asset, className, ...props }: Props): ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const [editAsset, { loading }] = Scheduler.Asset.useEdit();
  const account = Store.Account.useAccount();
  const prompt = Modal.usePrompt();
  const me = Store.User.useMe();

  const { visibility } = asset;

  const opposite = visibility === Viz.Public ? Viz.Private : Viz.Public;

  const doChangeVisibility = (commit: boolean): void => {
    // Close the modal window
    setShowModal(false);

    // Did the user confirm the change?
    if (commit && !loading) {
      editAsset({ asset, account, changes: { ...asset, visibility: opposite } }).catch(
        (error: Error) => {
          prompt.error({ content: error.message });
        }
      );
    }
  };

  return (
    <ButtonContainer {...props}>
      <Dropdown
        onSelect={(): void => {
          // Show the confirmation modal (if not loading)
          if (!loading) {
            setShowModal(true);
          }
        }}
      >
        <Dropdown.Toggle
          className={cx('py-0', className)}
          size='sm'
          variant='outline-dark'
          disabled={loading || (!me.permissions.admin && me.permissions.readOnlyArchive)}
        >
          {visibility === Viz.Public ? (
            <div className='icon eye' />
          ) : (
            <div className='icon eye-slash' />
          )}
          <span>{Viz[visibility]}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item>
            {opposite === Viz.Public ? (
              <div className='icon eye' />
            ) : (
              <div className='icon eye-slash' />
            )}
            {Viz[opposite]}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {showModal && <Confirm visibility={opposite} onConfirm={doChangeVisibility} />}
    </ButtonContainer>
  );
};
