/* eslint-disable react-hooks/rules-of-hooks */
import { Loading } from '@livecontrol/core-ui';
import type { Destination } from '@livecontrol/scheduler/model';
import { User } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Store } from '../../store';
import { EditEventWorkflow } from './edit-event-workflow';
import { EventContextProvider } from './event-store';

export const EditEvent = (): React.ReactElement => {
  const me = Store.User.useMe();
  const account = Store.Account.useAccount();
  let { locations } = Scheduler.Location.useLocations(account);

  locations = Store.User.useLocationWithPermission(
    User.LocationPermissionsNames.EventManagment,
    locations
  );

  if (!me.permissions.someLocationHasEventManagmentEnabled) {
    return <Redirect to='/schedule' />;
  }

  const { destinations } = Scheduler.Destination.useDestinations(account);

  const { event, loading } = Scheduler.Event.useEvent({
    event: useParams<{ id: string }>().id,
    account
  });

  if (event?.phase === 3 || event?.phase === 2) {
    return <Redirect to='/schedule' />;
  }

  if (event?.linkedEventId && !event.isMobileKit) {
    const redirectString = `/schedule/edit-produce-mobile/${event.id}/${event.linkedEventId}`;

    return <Redirect to={redirectString} />;
  }

  if (loading || !event || !destinations) {
    return <Loading.Delay />;
  }

  if(!me.permissions.admin && !locations.some((location) => location.id === event.location.id)) {
    return <Redirect to='/schedule' />;
  }

  return (
    <EventContextProvider
      existingEvent={{
        ...event,
        destinations: event.destinations
          ?.map(
            (eventDestination) =>
              destinations.find((destination) => eventDestination.name === destination.id) ??
              eventDestination
          )
          .filter((destination): destination is Destination => !!destination)
      }}
    >
      <EditEventWorkflow />
    </EventContextProvider>
  );
};
