import { Button } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import { Asset } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import cx from 'classnames';
import { useState } from 'react';
import type { ReactElement } from 'react';
import { useHistory } from 'react-router';
import { Store } from '../../../../store';
import { Confirm } from './confirm';

import Viz = Asset.Visibility;

type Props = Button.Props & {
  asset: Asset;
};

export const Delete = ({ asset, ...props }: Props): ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const [deleteAsset, deleteResult] = Scheduler.Asset.useDelete();
  const [editAsset, editResult] = Scheduler.Asset.useEdit();
  const prompt = Modal.usePrompt();
  const history = useHistory();
  const account = Store.Account.useAccount();
  const me = Store.User.useMe();

  const loading = deleteResult.loading || editResult.loading;

  const icon = loading ? 'fad fa-spinner-third' : 'far fa-trash-alt';

  const doDelete = (commit: boolean, privitize: boolean = false): void => {
    // Close the modal window
    setShowModal(false);

    // Did the user confirm the change?
    if (commit && !loading) {
      deleteAsset({ account, asset })
        .then(() => {
          // Go back
          history.push('/archive');
        })
        .catch((error: Error) => {
          prompt.error({ content: error.message });
        });
    } else if (
      privitize &&
      // Is this video public?
      asset.visibility === Viz.Public
    ) {
      editAsset({ asset, account, changes: { visibility: Viz.Private } }).catch((error: Error) => {
        prompt.error({ content: error.message });
      });
    }
  };

  return (
    <>
      <Button
        {...props}
        variant='outline-danger'
        onClick={(): void => {
          // Show the confirmation modal (if not loading)
          if (!loading) {
            setShowModal(true);
          }
        }}
        disabled={loading || (!me.permissions.admin && me.permissions.readOnlyArchive)}
      >
        <span key={icon}>
          <i className={cx(icon, 'fa-fw', loading && 'fa-spin')} />
        </span>
        Delete
      </Button>

      {showModal && <Confirm asset={asset} onConfirm={doDelete} />}
    </>
  );
};
