import { Form } from '@livecontrol/core-ui';
import type { Account } from '@livecontrol/scheduler/model';
import React, { useCallback, useState } from 'react';
import { Create, useUpdateEnableButton } from '../customize-layout';

export const DonationSection = ({
  webplayer
}: {
  webplayer: Account.WebPlayer;
}): React.ReactElement => {
  const [updateEnableButton, { loading: isDonationUpdating }] = useUpdateEnableButton();
  const [showCreateModal, setShowCreateModal] = useState(false);

  const onDonationToggle = useCallback(
    async (value: boolean) => {
      if (value) {
        setShowCreateModal(true);

        return true;
      }

      await updateEnableButton(false);

      return false;
    },
    [updateEnableButton]
  );

  const label =
    webplayer.isCustomButtonEnabled && webplayer.customButton?.title
      ? webplayer.customButton.title
      : webplayer.donation?.label;

  const link =
    webplayer.isCustomButtonEnabled && webplayer.customButton?.url
      ? webplayer.customButton.url
      : webplayer.donation?.link;

  const isInputEnabled: boolean =
    webplayer.isCustomButtonEnabled ||
    (!!webplayer.donation && webplayer.donation.label !== '' && webplayer.donation.link !== '');

  const openDonationModal = useCallback(() => {
    setShowCreateModal(true);
  }, []);

  return (
    <div>
      <Create
        show={showCreateModal}
        onHide={(): void => {
          setShowCreateModal(false);
        }}
        currentLabel={label}
        currentLink={link}
      />
      <Form.SwitchField
        abledDescription={false}
        className='mt-24px'
        title='Custom Button'
        titleClassName='title text-dark mb-4px mr-lg-3 font-size-15px font-weight-bold'
        description='Show a button (i.e. “Donate” or “Subscribe”) on your Web Player & Profile Page'
        descriptionClassName='text-gray-light font-size-13px text-secondary font-weight-bold'
        onChange={onDonationToggle}
        checked={isInputEnabled}
        loading={isDonationUpdating}
      />
      <button
        className='open-donation-modal-btn'
        type='button'
        style={{ display: 'none' }}
        onClick={openDonationModal}
      />
    </div>
  );
};
