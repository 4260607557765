/* eslint-disable @typescript-eslint/no-unused-vars */
import * as AccountStore from './account';
import * as ActivityLogStore from './activity-log';
import * as AddOnsStore from './add-ons';
import * as AssetStore from './asset';
import * as BillingStore from './billing';
import * as ContactStore from './contact';
import * as DestinationStore from './destination';
import * as EventStore from './event';
import * as LinkStore from './link';
import * as LocationStore from './location';
import * as OnboardingStore from './onboarding';
import * as ProductionNoteStore from './production-notes';

export namespace Scheduler {
  export import Account = AccountStore;
  export import Log = ActivityLogStore;
  export import Asset = AssetStore;
  export import Contact = ContactStore;
  export import Destination = DestinationStore;
  export import Event = EventStore;
  export import Location = LocationStore;
  export import LinkCode = LinkStore;
  export import Billing = BillingStore;
  export import ProductionNote = ProductionNoteStore;
  export import Onboarding = OnboardingStore;
  export import AddOns = AddOnsStore;
}
