import React, { useCallback } from 'react';
import { SwitchField } from './switch-field';
import { Utils } from './utils';

export const SwitchCustomField = ({
  title,
  description,
  titleClassName,
  descriptionClassName,
  abledDescription,
  className,
  name,
  defaultValue,
  noSaveIndicator,
  spaceInBetween = true
}: SwitchCustomField.SwitchCustomFieldProps): React.ReactElement => {
  const field = Utils.useField<boolean>(name);

  const onChange = useCallback(
    (v: boolean): void => {
      field?.helpers.setValue(v);
    },
    [field?.helpers]
  );

  const checked =
    field?.meta.value !== undefined ? field.meta.value : defaultValue ? defaultValue : false;

  return (
    <SwitchField
      className={className}
      abledDescription={abledDescription}
      title={title}
      titleClassName={titleClassName}
      description={description}
      descriptionClassName={descriptionClassName}
      noSaveIndicator={noSaveIndicator}
      checked={checked}
      onChange={onChange}
      spaceInBetween={spaceInBetween}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace SwitchCustomField {
  export interface SwitchCustomFieldProps {
    title: React.ReactNode;
    description?: React.ReactNode;
    abledDescription?: boolean;
    titleClassName?: string;
    descriptionClassName?: string;
    defaultValue?: boolean;
    className?: string;
    name: string;
    noSaveIndicator?: boolean;
    spaceInBetween?: boolean;
  }
}
