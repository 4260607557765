import React from 'react';
import type { PageProps } from './page';
import { Page } from './page';

export const NotFound = (props: Pick<PageProps, 'className' | 'reset'>): React.ReactElement => (
  <Page
    code={404}
    heading='Page Not Found'
    message='Lights. Camera. Action!<br />Oops! We can’t find the page you are looking for.'
    {...props}
  />
);
