import { Account } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { useState } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Store, store } from '../../../../store';
import { EmbedPlayerModal } from '../../page-design';
import { EmbedPlayerButton } from './embed-player-button';
import { ViewProfileButton } from './view-profile-button';

const ActionsBar = styled.div`
  flex-wrap: wrap;
`;

export const Actions = (): ReactElement => {
  const account = Store.Account.useAccount();
  const { webPlayer } = Scheduler.Account.WebPlayer.useView(account.id);
  const venueURI = store.getState().environment.VENUE_URI;
  const endpoint = webPlayer?.endpoint;

  let webPlayerUrl = '';

  if (endpoint) {
    webPlayerUrl = `${Account.WebPlayer.makeURL({ uri: venueURI, slug: endpoint })}`;
  }

  const [showEmbedModal, setShowEmbedModal] = useState(false);

  return (
    <ActionsBar className='d-flex align-items-center justify-content-xl-between flex-lg-row flex-column'>
      {endpoint && webPlayerUrl.length > 0 && <ViewProfileButton webPlayerUrl={webPlayerUrl} />}
      {endpoint && (
        <EmbedPlayerModal
          show={showEmbedModal}
          onClose={(): void => {
            setShowEmbedModal(false);
          }}
          endpoint={endpoint}
        />
      )}
      <EmbedPlayerButton setShowEmbedModal={setShowEmbedModal} />
    </ActionsBar>
  );
};
