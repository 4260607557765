import { Scaffold } from '@livecontrol/scheduler/components';
import { User } from '@livecontrol/scheduler/model';
import qs from 'qs';
import type { ReactElement } from 'react';
import { useEffect } from 'react';
import type { RouteProps } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Header, NotFound, Sidebar, Trackers } from '../components';
import { Store, store } from '../store';
import {
  Account,
  ActivityLog,
  AddAch,
  AddCard,
  Organization,
  Unsubscribe,
  UserManagement
} from './account';
// import { Analytics } from './analytics';
import { Archive } from './archive';
import { CreateEvent, ReconnectDestination } from './create-event';
import { CreateIntegration, ViewDestinations } from './destinations';
import { EditEvent, EditProduceMobileKitEvent } from './edit-event';
import { ForgotPassword } from './forgot-password';
import { Link } from './link';
import { Login } from './login';
import { Logout } from './logout';
import { Masquerade } from './masquerade';
import { MasqueradeClient } from './masquerade-client';
// import { OnboardingSection } from './onboarding';
import { ProductionNotes } from './production-notes';
import { ResetPassword } from './reset-password';
import { Sandbox } from './sandbox';
import { Schedule } from './schedule';
import { SignUp } from './sign-up';
import { SubscriptionBilling, SubscriptionPlan } from './subscription';
import { Support } from './support';
import { CustomerList } from './to-admin/customer-list';
import { ViewAsset } from './view-asset';
import { Preferences } from './webplayer';

const AdminRedirect = (): ReactElement => {
  useEffect(() => {
    window.location.href = store.getState().environment.ADMIN_URI;
  }, []);

  return <div>redirecting...</div>;
};

interface ProtectedRouteProps extends RouteProps {
  userPermissions: User.Permissions;
  requiredPermissions: Partial<User.Permissions>;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  // eslint-disable-next-line react/prop-types
  userPermissions,
  // eslint-disable-next-line react/prop-types
  requiredPermissions,
  ...rest
}) => {
  const isAdmin = Store.User.useIsAdmin();

  if (isAdmin) {
    return (<Route {...rest} />);
  }

  const hasRequiredPermissions = Object.keys(requiredPermissions).every(
    (key) =>
      userPermissions[key as keyof User.Permissions] ===
      requiredPermissions[key as keyof User.Permissions]
  );

  return hasRequiredPermissions ? <Route {...rest} /> : <Redirect to='/' />;
};

const AuthGuard = (): ReactElement => {
  const { me, thee, token } = Store.User.useIdentity();
  const role = (thee ?? me)?.role;
  const isAdmin = Store.User.useIsAdmin();

  /*
   * If there is no authentication token, or the apparent user is not a
   * `Role.Client`, redirect to the `/login` route.
   */
  if (!token || !role || ![User.Role.Client, User.Role.SubUser].includes(role)) {
    const queryParams = qs.stringify({
      redirect: `${location.pathname}${location.search}${location.hash}`
    });

    return <Redirect to={`/login?${queryParams}`} />;
  }

  // Otherwise, build the frontend routes
  return (
    <Trackers>
      <Scaffold header={<Header />} sidebar={<Sidebar />}>
        <Switch>
          <Route path='/schedule/create/reconnect' component={ReconnectDestination} />
          <Route path='/schedule/create/:uuid?' component={CreateEvent} />
          <Route path='/schedule/edit/reconnect' component={ReconnectDestination} />
          <Route path='/schedule/edit/:id/:uuid?' component={EditEvent} />
          <Route
            path='/schedule/edit-produce-mobile/:id/:mobile/:uuid?'
            component={EditProduceMobileKitEvent}
          />
          <Route path='/schedule(s)?/:slug?' component={Schedule} />

          {/* <Route path='/onboarding/' component={OnboardingSection} /> */}

          <Route path='/content-library/library/view/:id' component={ViewAsset} />
          <Route path='/content-library/library' component={Archive} />
          {/* <Route path='/content-library/analytics' component={Analytics} /> */}

          <Route path='/subscription/plan' component={SubscriptionPlan} />

          {(isAdmin || me?.permissions.admin || me?.permissions.billing) && (
            <ProtectedRoute
              path='/subscription/billing'
              component={SubscriptionBilling}
              userPermissions={me!.permissions}
              requiredPermissions={{ billing: true }}
            />
          )}

          <Route path='/facebook/callback' component={CreateIntegration} />
          <Route path='/youtube/callback' component={CreateIntegration} />
          <Route path='/destinations/add/:type' component={ViewDestinations} />

          <Route path='/stream-settings/destinations' component={ViewDestinations} />

          {me && (
            <ProtectedRoute
              path='/stream-settings/production-notes'
              component={ProductionNotes}
              userPermissions={me.permissions}
              requiredPermissions={{ someLocationHasCustomizeProductionNotesEnabled: true }}
            />
          )}

          <Route path='/stream-settings/web-player' component={Preferences} />

          <Route path='/account/billing/ach/new' component={AddAch} />
          <Route path='/account/billing/card/new' component={AddCard} />
          <Route path='/account/profile' component={Account} />

          {(isAdmin || me?.permissions.admin) && (
            <ProtectedRoute
              path='/account/users'
              component={UserManagement}
              userPermissions={me!.permissions}
              requiredPermissions={{ admin: true }}
            />
          )}

          <Route path='/account/organization' component={Organization} />

          {(isAdmin || me?.permissions.admin || me?.permissions.billing) && (
            <Route path='/account/activity-log' component={ActivityLog} />
          )}

          <Route path='/account(s)?/:slug?' component={UserManagement} />
          <Route path='/account' component={Account} />
          <Route path='/admin-test(s)?/:slug?' component={CustomerList} />

          <Route path='/support/contact-us' component={Support} />
          <Route path='/link' component={Link} />

          <Route exact path={['/', '/home']}>
            <Redirect to='/schedule' />
          </Route>

          <Route component={NotFound} />
        </Switch>
      </Scaffold>
    </Trackers>
  );
};

export const Router = (): ReactElement => (
  <Switch>
    <Route path='/signup' component={SignUp} />
    <Route path='/(login|signin)' component={Login} />
    <Route path='/logout' component={Logout} />

    <Route path='/admin' component={AdminRedirect} />
    <Route path='/masquerade' component={Masquerade} />
    <Route path='/masquerade-client' component={MasqueradeClient} />

    <Route path='/sandbox' component={Sandbox} />

    <Route path='/forgot-pass(word)?' component={ForgotPassword} />
    <Route path='/unsubscribe' component={Unsubscribe} />
    <Route path='/reset-pass(word)?/:token' component={ResetPassword} />

    <Route component={AuthGuard} />
  </Switch>
);
