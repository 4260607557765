import { gql, useQuery } from '@apollo/client';
import type { QueryResult as ApolloQueryResult } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Obj } from '@livecontrol/core-utils';
import type { Location } from '@livecontrol/scheduler/model';
import { Account } from '@livecontrol/scheduler/model';
import { useEffect, useState } from 'react';
import type { QueryResult } from '../graphql';
import * as Dbx from './dbx';

interface TVariables {
  id: number;
}

interface TData {
  getUser?: {
    locations: Dbx.LocationRecord[];
  };
}

export const QUERY = gql`
  query GetLocations($id: Float!) {
    getUser(input: { id: $id }) {
      id
      locations {
        ...StandardLocationResponse
      }
    }
  }
  ${Dbx.StandardLocationResponseWithOnboarding}
`;

export const useLocations = (
  args: Account.Like
): Partial<Pick<ApolloQueryResult, 'refetch'>> & QueryResult<Location[], 'locations'> => {
  const [error, setError] = useState<Error | undefined>();
  const [locations, setLocations] = useState<Location[] | undefined>();

  // Parse the input arguments
  const variables = {
    id: Account.toId(args)
  };

  // Validate the input
  if (!Obj.isHydrated(variables)) {
    return { loading: false, error: Exception.KABOOM };
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  // Prepare the query
  const { loading, data, refetch } = useQuery<TData, TVariables>(QUERY, {
    variables,
    fetchPolicy: 'no-cache',
    onError() {
      setError(Exception.KABOOM);
    }
  });

  // When available, parse server response
  useEffect(() => {
    let locations_;
    let error_;

    if (data) {
      locations_ = (data.getUser?.locations ?? [])
        .map((record: Dbx.RecordWithOnboarding) => Dbx.normalizeWithOnboarding(record))
        .filter((µ: Location | undefined): µ is Location => !!µ)
        .filter((µ: Location) => !µ.isDisabled);
    }

    setLocations(locations_);
    setError(error_);
  }, [data]);

  return { loading, error, locations, refetch };
};
