import cx from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Descriptor } from './descriptor';

const StyledRadio = styled.div`
  border-radius: 50%;
  border: 5px solid var(--tertiary);
  opacity: 0.8;

  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    padding: 5px;
    background-color: var(--tertiary);
  }

  &.active {
    opacity: 1;
    border-color: var(--primary);
    &::after {
      background-color: var(--white);
    }
  }
`;

export const RadioAlt = ({
  title,
  titleClassName,
  description,
  descriptionClassName,
  className,
  onClick,
  selected
}: RadioAlt.Props): React.ReactElement => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  <div className={cx('d-flex align-items-start cursor-pointer', className)} onClick={onClick}>
    <StyledRadio className={cx({ active: selected }, 'radio-button')} />
    <Descriptor
      className='flex-grow-1 ml-12px descriptor'
      title={title}
      titleClassName={cx(titleClassName, 'radio-title')}
      description={description}
      descriptionClassName={cx(descriptionClassName, 'radio-desc')}
    />
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace RadioAlt {
  export interface Props {
    title: string;
    titleClassName?: string;
    description?: string;
    descriptionClassName?: string;
    className?: string;
    onClick?: () => void;
    selected?: boolean;
  }
}
