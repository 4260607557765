import React from 'react';
import { Form } from '../../../../components';

export const OperatorNotes = (): React.ReactElement => (
  <Form.Descriptor
    title='Videographer Event Notes'
    titleClassName='title mr-lg-3 font-size-15px font-weight-bold text-dark'
    description={
      <>
        Provide specific direction to your videographer for this particular event. To update your
        general Production Notes, go to the Stream Settings tab.
      </>
    }
    descriptionClassName='font-size-13px text-secondary font-weight-bold text-gray-light line-height-18px'
    className='form-section-lateral d-lg-flex'
  >
    <Form.Group
      name='operatorNotes'
      label={false}
      className='flex-grow-1'
      controlProps={{
        as: 'textarea',
        rows: 6,
        placeholder:
          'Use this section to give any specific direction for the remote operator. (Example: there will be a full band. Please show all the musical players.)'
      }}
    />
  </Form.Descriptor>
);
