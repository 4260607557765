import cx from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Descriptor } from './descriptor';

const StyledRadio = styled.div`
  border-radius: 50%;
  border: 5px solid var(--tertiary);
  opacity: 0.8;

  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    padding: 5px;
    background-color: var(--tertiary);
  }

  &.active {
    opacity: 1;
    border-color: var(--primary);
    &::after {
      background-color: var(--white);
    }
  }
`;

export const Radio = ({
  title,
  description,
  className,
  onClick,
  selected
}: Radio.Props): React.ReactElement => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  <div className={cx('d-flex align-items-start cursor-pointer', className)} onClick={onClick}>
    <StyledRadio className={cx({ active: selected })} />
    <Descriptor className='flex-grow-1 ml-12px' title={title} description={description} />
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Radio {
  export interface Props {
    title: string;
    description?: string;
    className?: string;
    onClick?: () => void;
    selected?: boolean;
  }
}
