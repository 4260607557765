import { Overflow } from '@livecontrol/core-ui';
import type { ReactElement } from 'react';
import styled from 'styled-components';

const Wrapper = styled(Overflow)`
  font-weight: var(--font-weight-bold);
`;

export const Title = ({ title, length, ...rest }: Title.Props): ReactElement => (
  <Wrapper value={title} length={length ?? 40} {...rest} />
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Title {
  export type Props = Omit<Overflow.Props, 'value'> & { title: string };
}
