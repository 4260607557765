import { Str } from '@livecontrol/core-utils';
import { EventValidator } from './types';

export const validateEventTitle = (
  newEventInfo: EventValidator.EventInfo
): EventValidator.Errors => {
  const errors: EventValidator.Errors = {};
  const title = Str.normalize(newEventInfo.title);

  if (!title) {
    errors.title = EventValidator.ErrorMessages.TITLE_MISSING;
  } else if (title.length < 3) {
    errors.title = EventValidator.ErrorMessages.TITLE_TOO_SHORT;
  } else if (title.length > 100) {
    errors.title = EventValidator.ErrorMessages.TITLE_TOO_LONG;
  }

  return errors;
};
