/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Phone } from '@livecontrol/locale-us';
import type { Account, Location } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { assert } from '@sindresorhus/is';
import type { FormikContextType } from 'formik';
import { useFormik } from 'formik';
import { useRef } from 'react';
import * as Yup from 'yup';
import { Alert } from '../../../../store';

export interface Props {
  account: Account;
  subUser?: Account.SubUser;
  onClose: () => void;
  locations?: Location[];
  redirect?: boolean;
  deleteUser?: (user: Account.SubUser) => void;
  setNoPermissionsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setAddPermissionsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  redirectWithUserID?: (userId: number) => void;
}

export interface Values {
  // This is for useUpsertSubUser
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | undefined;
  landline: string | undefined;
  landlineExtension: string | undefined;
  preferredContactMethod: string;
  additionalInformation: string;
  // This is for useUpsertPermissions
  destinations: boolean;
  webplayer: boolean;
  accountInfo: boolean;
  billing: boolean;
  admin: boolean;
  // Checar el tipado para los objetos de las locaciones de los permisos
  [index: string]: Record<string, boolean> | boolean | string | undefined;
}

interface LocationPermissions {
  activity_log?: boolean;
  content_managment: boolean;
  event_managment: boolean;
  location_id: string;
  customize_production_notes: boolean;
  view_and_download: boolean;
}

interface Form {
  formik: FormikContextType<Values>;
  loading: boolean;
  alert: Alert;
}

const validationSchema = Yup.object({
  firstName: Yup.string().trim().required("Please provide the user's first name."),
  lastName: Yup.string().trim().required("Please provide the user's last name."),
  email: Yup.string()
    .trim()
    .required('Please enter valid email address')
    .email('Please enter valid email address.'),
  phoneNumber: Yup.string()
    .trim()
    .test({
      message: 'Please provide a valid phone number.',
      test: (value) => (value ? value.length > 0 && !!Phone.normalize(value) : true)
    })
    .test({
      message: 'Please provide at least one phone number',
      test: (value, context) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (!value && !context.parent.landline) {
          return false;
        }

        return true;
      }
    }),
  landline: Yup.string()
    .trim()
    .test({
      message: 'Please provide a valid phone number.',
      test: (value) => (value ? value.length > 0 && !!Phone.normalize(value) : true)
    })
    .test({
      message: 'Please provide at least one phone number',
      test: (value, context) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (!value && !context.parent.phoneNumber) {
          return false;
        }

        return true;
      }
    }),
  landlineExtension: Yup.string().trim().optional(),
  preferredContactMethod: Yup.string().trim().required('Please provide a contact method'),
  additionalInformation: Yup.string().trim()
});

export const useForm = ({
  account,
  subUser,
  onClose,
  locations,
  redirect,
  setAddPermissionsLoading,
  setNoPermissionsLoading,
  redirectWithUserID
}: Props): Form => {
  const [permissionMutation, { loading: permissionLoading, error: permissionError }] =
    Scheduler.Account.UserManagement.useUpsertPermissions();

  const [subUserMutation, { loading: subUserLoading }] =
    Scheduler.Account.UserManagement.useUpsertSubUser();

  const [subLocationsMutation, { loading: subLocationsLoading }] =
    Scheduler.Account.UserManagement.useCreateSubUserLocations(account);

  const alert = Alert.useAlert(permissionError);

  const initialValues: Values = useRef({
    // This is for useUpsertSubUser
    firstName: subUser?.firstName ?? '',
    lastName: subUser?.lastName ?? '',
    email: subUser?.email ?? '',
    phoneNumber: Phone.normalize(subUser?.phone)?.format({ separator: '' }) ?? '',
    landline: Phone.normalize(subUser?.landline)?.format({ separator: '' }) ?? '',
    landlineExtension: subUser?.landlineExtension ?? '',
    preferredContactMethod: subUser?.preferredContactMethod ?? '',
    additionalInformation: subUser?.additionalInformation ?? '',
    // This is for useUpsertPermissions
    destinations: subUser?.permissions.destinations ?? false,
    webplayer: subUser?.permissions.webplayer ?? false,
    accountInfo: subUser?.permissions.accountInfo ?? false,
    billing: subUser?.permissions.billing ?? false,
    admin: subUser?.permissions.admin ?? false
  }).current;

  const formik = useFormik<Values>({
    initialValues,
    validationSchema,
    validateOnMount: true,

    validate(): void {
      alert.hide();
    },

    async onSubmit(data: Values): Promise<void> {
      alert.hide();

      // Checar si se quedaesta forma de filtar las locaciones por permiso

      if (!permissionLoading && !subUserLoading && !subLocationsLoading) {
        const permissionArgs = {
          id: subUser?.permissions.id,
          account: account.id,
          email: data.email,
          destinations: data.destinations,
          customize_webplayer: data.webplayer,
          organization_account_info: data.accountInfo,
          billing: data.billing,
          admin_access: data.admin
        };

        const permissionResult = await permissionMutation(permissionArgs);

        assert.number(permissionResult?.id);

        const subUserArgs = {
          id: subUser?.id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNumber: data.phoneNumber,
          landline: data.landline,
          landlineExtension: data.landlineExtension,
          preferredContactMethod: data.preferredContactMethod,
          additionalInformation: data.additionalInformation,
          role: 7,
          subUserPermissionId: permissionResult.id
        };

        const subUserResult = await subUserMutation(subUserArgs);

        if (subUserResult) {
          const locationPermissionsSubuser: LocationPermissions[] = [];

          locations?.forEach((location) => {
            const permissionsLocations: LocationPermissions = {
              location_id: location.id,
              content_managment: false,
              customize_production_notes: false,
              event_managment: false,
              view_and_download: false
            };

            locationPermissionsSubuser.push(permissionsLocations);
          });

          const subLocationsResult = await subLocationsMutation({
            subUserRestrictedLocations: locationPermissionsSubuser,
            subUserId: subUserResult.id
          });

          // eslint-disable-next-line require-atomic-updates
          subUserResult.sub_user_locations = subLocationsResult;
        }

        if (subUserResult) {
          const { id: _id } = permissionResult;

          if (redirect) {
            // setAddPermissionsLoading?.(false);

            if (redirectWithUserID) {
              redirectWithUserID(subUserResult.id);
            }
          } else {
            setNoPermissionsLoading?.(false);
            onClose();
          }
        } else {
          setAddPermissionsLoading?.(false);
          setAddPermissionsLoading?.(false);
        }
      }
    }
  });

  return {
    formik,
    loading: permissionLoading || subUserLoading,
    alert
  };
};
