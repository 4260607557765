export { ReactComponent as BrowserCodeIcon } from './browser-code.svg';
export { ReactComponent as LayerCheckIcon } from './layer-check.svg';
export { ReactComponent as CalendarStarredIcon } from './calendar-starred.svg';
export { ReactComponent as DollarCoinIcon } from './dollar-coin.svg';
export { ReactComponent as ForwardEmailIcon } from './forward-email.svg';
export { ReactComponent as UserCircleIcon } from './user-circle.svg';
export { ReactComponent as HelpIcon } from './help.svg';
export { ReactComponent as HistoryIcon } from './history.svg';
export { ReactComponent as NoteIcon } from './note.svg';
export { ReactComponent as PaymentCardIcon } from './payment-card.svg';
export { ReactComponent as SettingsIcon } from './settings.svg';
export { ReactComponent as SuitCaseIcon } from './suit-case.svg';
export { ReactComponent as SupportIcon } from './support.svg';
export { ReactComponent as TravelBagIcon } from './travel-bag.svg';
export { ReactComponent as UploadLaptopIcon } from './upload-laptop.svg';
export { ReactComponent as UserGroupIcon } from './user-group.svg';
export { ReactComponent as VideoPlaylistIcon } from './video-playlist.svg';
export { ReactComponent as VideoIcon } from './video.svg';
export { ReactComponent as BarChartIcon } from './bar-chart.svg';
export { ReactComponent as VectorIcon } from './vector.svg';
