import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Obj, Str } from '@livecontrol/core-utils';
import { Account } from '@livecontrol/scheduler/model';
import { useEffect, useState } from 'react';
import type { QueryResult } from '../graphql';

interface TVariables {
  clientId: string;
  callbackPath?: string;
}

export interface TData {
  addYoutubeAuthorizations: {
    oauth_url?: string;
  };
}

interface Args {
  account: Account.Like;
  callbackPath?: string;
}

export const QUERY = gql`
  query AddYouTubeAuthorization($clientId: String!, $callbackPath: String) {
    addYoutubeAuthorizations(clientId: $clientId, callbackPath: $callbackPath) {
      oauth_url
    }
  }
`;

export const useYouTubeAuthorization = (args: Args): QueryResult<string> => {
  const [error, setError] = useState<Error | undefined>();
  const [oauthURL, setOAuthURL] = useState<string | undefined>();

  // Parse the input arguments
  const required = {
    clientId: Account.toId(args.account)?.toString()
  };

  // Validate the input
  if (!Obj.isHydrated(required)) {
    return { loading: false, error: Exception.KABOOM };
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  // Prepare the query
  const { loading, data } = useQuery<TData, TVariables>(QUERY, {
    variables: {
      ...required,
      callbackPath: Str.normalize(args.callbackPath)
    },
    onError() {
      setError(Exception.KABOOM);
    }
  });

  // When available, parse server response
  useEffect(() => {
    setOAuthURL(data?.addYoutubeAuthorizations.oauth_url);
  }, [data]);

  return { loading, error, data: oauthURL };
};
