import { gql, useMutation } from '@apollo/client';
import { Account } from '@livecontrol/scheduler/model';
import { useCallback, useState } from 'react';
import { Errors } from '../graphql';
// import type { Onboarding } from '../../../../customer-dashboard/frontend/src/routes/onboarding';
import type { MutationResult } from '../graphql';

interface TVariables {
  userId: number;
  taskId: string;
  moduleId: string;
}

interface TData {
  updateOnboardingUserProgress: {
    result: JSON;
    success: boolean;
  };
}

export const MUTATION = gql`
  mutation UpdateOnboardingUserProgress($userId: Float!, $taskId: String!, $moduleId: String!) {
    updateOnboardingUserProgress(user_id: $userId, task_id: $taskId, module_id: $moduleId) {
      result
      success
    }
  }
`;

interface Args {
  accountId: Account.Like;
  taskId: string;
  moduleId: string;
}

export const useUpdateOnboardingProgress = (): [
  (args: Args) => Promise<boolean>,
  MutationResult<boolean, 'success'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [success, setSuccess] = useState<boolean>();

  return [
    useCallback(
      async (args: Args): Promise<boolean> => {
        let success_ = false;

        try {
          // Parse the input arguments
          const userId = Account.toId(args.accountId);
          const { taskId, moduleId } = args;

          if (!moduleId || !taskId || !userId) {
            throw Errors.badRequest();
          }

          // Execute the GraphQL mutation
          const { data } = await mutation({
            variables: { userId, taskId, moduleId }
          });

          success_ = data?.updateOnboardingUserProgress.success ?? false;
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setSuccess(success_);

        return success_;
      },
      [mutation, setError, setSuccess]
    ),
    {
      success,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
