import { Filters } from '@livecontrol/scheduler/components';
import type { ReactElement } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import type { Phase } from '../store';
import { useStore } from '../store';

import L = Filters.Location;

export const Location = ({
  phase,
  ...props
}: Omit<L.Props, 'value'> & { phase: Phase }): ReactElement | null => {
  const location = useStore(({ model }) => model.value[phase].filters.location);

  return <L value={location} {...props} onChange={location.update} as={ButtonGroup} />;
};
