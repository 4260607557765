import cx from 'classnames';
import _ from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';
import type { TimePickerProps } from 'rc-time-picker';
import TimePicker from 'rc-time-picker';
import type { ReactElement } from 'react';
import { Utils } from './utils';

// eslint-disable-next-line import/no-internal-modules
import 'rc-time-picker/assets/index.css';

export const Time = ({
  className,
  isInvalid,
  format: _unused,
  ...props
}: Time.Props): ReactElement => {
  const field = Utils.useField(props);

  return (
    <TimePicker
      showSecond={false}
      className={cx('form-control', isInvalid && 'is-invalid', className)}
      onChange={(v: Moment): void => {
        if (field) {
          field.helpers.setValue(v);
        }
      }}
      defaultValue={moment().hour(0).minute(0)}
      format='h:mm a'
      use12Hours
      inputReadOnly
      {..._.omit(props, 'onChange')}
    />
  );
};

Time.displayName = 'Time';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Time {
  export type Props = TimePickerProps & {
    isInvalid?: boolean;
  };
}
