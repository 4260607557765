import type { DateTime } from 'luxon';

export interface Log {
  performedBy?: string;
  message?: string;
  date?: DateTime;
  action?: string;
  credits_used?: number;
  current_balance?: number;
  location_id?: string;
}

export namespace Log {
  export enum ActionType {
    Created = 'Event - Created',
    Stopped = 'Event - Stopped',
    Deleted = 'Event - Deleted',
    Updated = 'Event - Updated',
    Started = 'Event - Started',
    UpdatedManualCredits = 'Credits - Manual Update',
    UpdatedCredits = 'Credits - Updated',
    PaidInvoice = 'Invoice - Paid'
  }

  export namespace ActionType {
    export const keys = {
      Created : 'Event - Created',
      Stopped : 'Event - Stopped',
      Deleted : 'Event - Deleted',
      Updated : 'Event - Updated',
      Started : 'Event - Started',
      UpdatedManualCredits : 'Credits - Manual Update',
      UpdatedCredits : 'Credits - Updated',
      PaidInvoice : 'Invoice - Paid'
    };

    export type Key = keyof typeof keys;
  }
}
