import cx from 'classnames';
import { FormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';
import { Button } from '../button';

/* eslint-disable @typescript-eslint/no-unnecessary-condition */

export const Submit = React.forwardRef(
  (
    { children, className, icon, loading, ...rest }: Submit.Props,
    ref: React.Ref<HTMLButtonElement>
  ): React.ReactElement => {
    const formik = React.useContext(FormikContext);

    const disabled = !!loading || (formik && !formik?.isValid);

    return (
      <Button
        className={cx(disabled && 'disabled', className)}
        {..._.defaults(rest, {
          type: 'submit',
          onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
            if (disabled) {
              // Run form validation, if applicable
              if (formik) {
                void formik.validateForm();
              }

              event.stopPropagation();
            }
          }
        })}
        ref={ref}
      >
        {loading && (
          <span>
            <i className={cx(icon ?? 'fad fa-spinner-third', 'fa-spin mr-8px')} />
          </span>
        )}
        <span>{children}</span>
      </Button>
    );
  }
);

Submit.displayName = 'Submit';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Submit {
  export type Props = Button.Props & {
    className?: string;
    loading?: boolean;
    icon?: string;
  };
}
