import type {
  EventHandler,
  HTMLAttributes,
  PropsWithChildren,
  ReactElement,
  SyntheticEvent
} from 'react';

const capture = (event: SyntheticEvent): void => {
  event.stopPropagation();
};

export const StopEvent = ({
  children,
  events = ['click'],
  ...props
}: StopEvent.Props): ReactElement => {
  const stoppers: Partial<Record<`on${Capitalize<StopEvent.Type>}`, EventHandler<SyntheticEvent>>> =
    {};

  if (events.includes('click')) {
    stoppers.onClick = capture;
  }

  if (events.includes('keyup')) {
    stoppers.onKeyup = capture;
  }

  if (events.includes('keydown')) {
    stoppers.onKeydown = capture;
  }

  return (
    <div {...props} {...stoppers}>
      {children}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace StopEvent {
  export type Type = 'click' | 'keydown' | 'keyup';

  export type Props = PropsWithChildren<HTMLAttributes<HTMLElement> & { events?: Type[] }>;
}
