import type { Any } from '@livecontrol/core-utils';
import type { ElementType, ReactElement } from 'react';
import { createContext } from 'react';

interface T_Context {
  addPrompt: (key: string, el: ElementType | ReactElement) => void;
  removePrompt: (key: string) => void;
}

export const Context = createContext<T_Context>(<Any>undefined);
