import { Filters } from '@livecontrol/scheduler/components';
import { useCallback } from 'react';
import type { ReactElement } from 'react';
import { useStore } from '../store';

import P = Filters.Period;

export const Period = (props: P.Props): ReactElement => {
  const { value, update } = useStore(({ model }) => model.value.filters.period);

  const onChange = useCallback(
    (v?: P.Value): void => {
      update(v);
    },
    [update]
  );

  return <P onChange={onChange} value={value} {...props} />;
};
