import type { Location, User } from '@livecontrol/scheduler/model';
import type { ReactElement } from 'react';
import { LocationInfo } from './location';

interface Props {
  locations?: Location[];
  me: User;
}

export const Locations = ({ locations, me }: Props): ReactElement => (
  <div>
    {locations?.map((location) => (
      <LocationInfo me={me} location={location} key={location.id} />
    ))}
  </div>
);
