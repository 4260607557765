import { Obj } from '@livecontrol/core-utils';
import { Coordinates } from '@livecontrol/gis-utils';
import type { Asset } from '@livecontrol/scheduler/model';
import { DateTime, Duration } from 'luxon';

interface Raw {
  asset: {
    id: number;
    title: string;
    start: string;
    end: string;
    duration: string;
  };

  activity: {
    mux?: Raw.Mux;
    faceboook?: Raw.Facebook;
    youtube?: Raw.YouTube;
  };
}

namespace Raw {
  export interface Mux {
    views: {
      total: number;
      unique: number;
    };

    retention: {
      watchTime: {
        average: string; // ISO duration
      };
    };

    engagement: {
      intervals: {
        timestamp: string;
        value: number;
      }[];
    };

    locations: {
      city: string;
      country: string;
      coordinates: {
        latitude: number;
        longitude: number;
      };
      total: number;
    }[];

    clients: {
      categories: Record<'desktop' | 'other' | 'phone' | 'tablet' | 'tv', number>;
      oss: Record<'android' | 'chrome' | 'ios' | 'linux' | 'osx' | 'other' | 'windows', number>;
    };
  }

  export interface Metrics {
    capturedEmails: number;
    registeredViewers: number;
    users?: {
      name?: string;
      last_name?: string;
      email?: string;
    }[];
  }

  export type Facebook = unknown;
  export type YouTube = unknown;
}

export const toWebPlayer = (
  asset: Raw['asset'],
  mux: Raw.Mux
): Asset.Analytics.WebPlayer => ({
  views: mux.views,

  retention: ((): Asset.Analytics.WebPlayer['retention'] => {
    const duration = Duration.fromISO(asset.duration);
    const average = Duration.fromISO(mux.retention.watchTime.average);

    const avg = average.as('second');
    const total = duration.as('seconds');

    const percent = total > 0 ? Math.min(Math.ceil((avg / total) * 100), 100) : 0;

    return {
      average,
      percent
    };
  })(),

  engagement: mux.engagement.intervals.map((el) => ({
    timestamp: DateTime.fromISO(el.timestamp, { zone: 'utc' }),
    viewers: el.value
  })),

  category: ((): Asset.Analytics.WebPlayer['category'] => {
    const { categories } = mux.clients;

    const total = Object.values(categories).reduce((sum, i) => sum + i, 0);

    let desktop = 0;
    let tablet = 0;
    let mobile = 0;
    let other = 0;

    if (total) {
      desktop = Math.min((categories.desktop / total) * 100, 100);
      tablet = Math.min((categories.tablet / total) * 100, 100);
      mobile = Math.min((categories.phone / total) * 100, 100);
      other = Math.max(100 - desktop - tablet - mobile);
    }

    return { desktop, tablet, mobile, other };
  })(),

  os: ((): Asset.Analytics.WebPlayer['os'] | undefined => {
    const { oss } = mux.clients;

    const total = oss.android + oss.ios;

    if (!total) {
      return undefined;
    }

    const android = Math.min(Math.round((oss.android / total) * 100), 100);
    const ios = Math.max(100 - android, 0);

    return { android, ios };
  })(),

  locations: [...mux.locations]
    .sort((a, z) => {
      const r = z.total - a.total;

      // Sort by viewers, then alphabetically by city
      return r !== 0 ? r : a.city.localeCompare(z.city);
    })
    .slice(0, 5)
    .map(({ city, country, coordinates, total: views }) => ({
      coordinates: Coordinates.normalize(coordinates)!,
      name: `${city}, ${country}`,
      views
    }))
});

export const toAnalytics = (
  analytics: unknown
): Asset.Analytics | undefined => {
  const analyticsObj = Obj.normalize<Raw>(analytics);

  const { asset, activity } = analyticsObj ?? {};

  if (!asset) {
    return undefined;
  }

  if(!activity?.mux){
    return undefined;
  }else{
    const webPlayer = toWebPlayer(asset, activity.mux);

    return {
      views: webPlayer.views?.total,

      platforms: {
        webPlayer
      }
    };
  }  
};

export const toViewers = (data: unknown): Asset.Analytics.Viewers | undefined => {
  const obj = Obj.normalize<Raw.Metrics>(data);
  const { capturedEmails, registeredViewers, users } = obj ?? {};

  if (!users) {
    return undefined;
  }

  return {
    capturedEmails: capturedEmails ?? 0,
    registeredViewers: registeredViewers ?? 0,
    users: users
      .map((user) => ({
        name: user.name,
        lastName: user.last_name,
        email: user.email ? user.email : ' '
      }))
      .filter((v): v is Asset.Analytics.Viewers['users'][0] => !!(v.email && v.lastName && v.name))
  };
};
