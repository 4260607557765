import type { ApolloCache, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Num } from '@livecontrol/core-utils';
import { Account, User } from '@livecontrol/scheduler/model';
import { useCallback, useState } from 'react';
import type { MutationResult } from '../../graphql';
import { Errors } from '../../graphql';

interface TVariables {
  accountId: number;
  subUsersToDelete: DeleteSubUser[];
}

interface DeleteSubUser {
  id: number;
  permissionId: number;
}

interface TData {
  deleteSubUser: {
    id: number;
    result: {
      id: number;
      email: string;
    }[];
  };
}

const MUTATION = gql`
  mutation DeleteSubUser($accountId: Float!, $subUsersToDelete: [DeleteSubUser!]!) {
    deleteSubUsers(accountId: $accountId, subUsersToDelete: $subUsersToDelete) {
      success
      result {
        id
      }
    }
  }
`;

interface Args {
  account: Account;
  subUsers: Account.SubUser[];
}

export const useDelete = (): [
  (args: Args) => Promise<boolean>,
  MutationResult<boolean, 'success'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [success, setSuccess] = useState<boolean>();

  return [
    useCallback(
      async (args: Args): Promise<boolean> => {
        let success_ = false;

        try {
          // Parse the input arguments
          const accountId = Account.toId(args.account);

          const subUsersToDelete = args.subUsers.map((subUser) => ({
            id: User.toId(subUser.id)!,
            permissionId: Num.normalize(subUser.permissions.id, { positive: true })!
          }));

          if (!accountId) {
            throw Errors.badRequest();
          }

          // Execute the GraphQL mutation
          const { data } = await mutation({
            variables: { accountId, subUsersToDelete },
            update(cache: ApolloCache<unknown>, { data: fetchData }: FetchResult<TData>): void {
              const orphan = fetchData?.deleteSubUser;

              if (orphan) {
                const errs: number[] = [];

                data?.deleteSubUser.result.forEach((subuser) => {
                  const identity = cache.identify({ id: subuser.id, __typename: 'SubUser' });

                  if (!identity || !cache.evict({ id: identity })) {
                    errs.push(subuser.id);
                  }
                });

                if (errs.length) {
                  // eslint-disable-next-line no-console
                  console.error(`Unable to evict events: ${errs.join(', ')}`);
                }
              }
            }
          });

          success_ = true;
        } catch (error_: unknown) {
          success_ = false;
          setError(<Error>error_);
        }

        setSuccess(success_);

        return success_;
      },
      [mutation, setError, setSuccess]
    ),
    {
      success,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
