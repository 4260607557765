import { Tooltip } from '@livecontrol/core-ui';
import React from 'react';
import type { HTMLAttributes, ReactElement } from 'react';
import Nav from 'react-bootstrap/Nav';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const TabsContainer = styled.div`
  margin: 22px 0px;

  .nav-link {
    padding: 15px 16px;
    font-size: 14px;
    font-weight: 600;
    min-width: 100px;
    display: flex;
    justify-content: center;
    position: relative;
    border: none;
    color: black !important;

    .selected-border {
      display: none;
      height: 4px;
      background-color: var(--primary);
      width: 100%;
      border-radius: 8px;
      position: absolute;
      top: calc(100% - 4px);
    }

    &.active {
      color: #2e384d !important;

      .selected-border {
        display: block;
      }
    }
  }
`;

export enum Tab {
  AllUsers = 'users',
  AssignedContacts = 'assigned-contacts',
  PermissionByUser = 'permissions-user',
  PermissionByLocation = 'permissions-location'
}

type Props = HTMLAttributes<HTMLElement> & {
  tab: Tab;
};

export const Tabber = ({ className, tab }: Props): ReactElement => {
  const history = useHistory();

  const onSelect = React.useCallback(
    (key?: string | null): void => {
      if (key) {
        history.push(`/account/${key !== 'users' ? 'users' : ''}/${key}`);
      }
    },
    [history]
  );

  return (
    <TabsContainer>
      <Nav className={className} variant='tabs' activeKey={tab} onSelect={onSelect}>
        <Nav.Link key={Tab.AllUsers} eventKey={Tab.AllUsers}>
          All Users
          <div className='selected-border' />
        </Nav.Link>
        <Nav.Link key={Tab.PermissionByUser} eventKey={Tab.PermissionByUser}>
          <Tooltip backgroundColor='#0C3192' tip='Manage account access levels for your users.'>
            <span>Permissions (by User)</span>
          </Tooltip>
          <div className='selected-border' />
        </Nav.Link>
        <Nav.Link key={Tab.PermissionByLocation} eventKey={Tab.PermissionByLocation}>
          <Tooltip backgroundColor='#0C3192' tip='Manage account access levels for your users.'>
            <span>Permissions (by Location)</span>
          </Tooltip>
          <div className='selected-border' />
        </Nav.Link>
        <Nav.Link key={Tab.AssignedContacts} eventKey={Tab.AssignedContacts}>
          <Tooltip
            backgroundColor='#0C3192'
            tip='Manage who you would like us to contact for different aspects of your account.'
          >
            <span>Assigned Contacts</span>
          </Tooltip>
          <div className='selected-border' />
        </Nav.Link>
      </Nav>
    </TabsContainer>
  );
};
