import { Loading } from '@livecontrol/core-ui';
import type { ReactElement } from 'react';
import { Layout } from './layout';
import { Provider, useReady } from './store';

const Loader = (): ReactElement => (useReady() ? <Layout /> : <Loading.Delay />);

export const Schedule = (): ReactElement => (
  <Provider>
    <Loader />
  </Provider>
);
