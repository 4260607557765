import { gql, useMutation } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { useCallback } from 'react';
import type { MutationResult } from '../graphql';

interface TVariables {
    input: {
      location_id?: string;
      client_id?: number;
      notes: UpdateProductionNotes[];
    };
}


type TArgs = TVariables;

interface TData {
    updateProductionNote?: {
      camera_movements_preference?: number;
      audience_shots?: number;
      close_up_shots?: number;
      wide_venue_shots?: number;
      detailed_venue_shots?: number;
      event_ending_preference?: number;
    };
}

interface UpdateProductionNotes{
  category: string;
  content: string;
  option: number;
}

interface TResult {
  id: number;
  loction: {
    name: string;
    id: number;
  };
  notes: UpdateProductionNotes[];
}

  const MUTATION = gql`
    mutation UpsertProductionNotes($input: UpsertProductionNotes!) {
      upsertProductionNotes(input: $input) {
        location{
          name
          id
        }
        id
        notes {
          option
          content
          category
        }
      }
    }
`;

  const MUTATIONCLIENTID = gql`
    mutation UpsertClientProductionNotes($input: UpsertClientProductionNotes!) {
      upsertClientProductionNotes(input: $input) {
        location{
          name
          id
        }
        id
        notes {
          option
          content
          category
        }
      }
    }
`;

export const useProductionNotes = (): [(args: TArgs) => Promise<void>, MutationResult<TResult>] => {
  const [mutate, { loading, error, called }] = useMutation<TData, TVariables>(MUTATION, {
    onError() {
      throw Exception.KABOOM;
    }
  });

  const createProductionNotes = useCallback(
    async (args: TArgs): Promise<void> => {

      const data = args;

      return void (await mutate({
        variables: data,
        fetchPolicy: 'network-only'
      }));
    },
    [mutate]
  );

  return [createProductionNotes, { loading, error, called }];
};

export const useProductionNotesClientId = (): [(args: TArgs) => Promise<void>, MutationResult<TResult>] => {
  const [mutate, { loading, error, called }] = useMutation<TData, TVariables>(MUTATIONCLIENTID, {
    onError() {
      throw Exception.KABOOM;
    }
  });

  const createProductionNotes = useCallback(
    async (args: TArgs): Promise<void> => {

      const data = args;

      return void (await mutate({
        variables: data,
        fetchPolicy: 'network-only'
      }));
    },
    [mutate]
  );

  return [createProductionNotes, { loading, error, called }];
};
