import { Str } from '@livecontrol/core-utils';
import { assert } from '@sindresorhus/is';

export class ZipCode {
  public readonly zip5: ZipCode.Zip5;
  public readonly plus4: ZipCode.Plus4 | undefined;

  public constructor(args: ZipCode.Constructor) {
    this.zip5 = Str.normalize(args.zip5)!;
    this.plus4 = Str.normalize(args.plus4);

    assert.string(this.zip5);
  }

  public format(options: ZipCode.Format = { withPlus4: true }): string {
    let rval = this.zip5;

    if (this.plus4 && options.withPlus4) {
      rval += `${options.separator ?? '-'}${this.plus4}`;
    }

    return rval;
  }

  public equals(value: ZipCode.Like): boolean {
    const v = ZipCode.normalize(value);

    return !!v && this.toString() === v.toString();
  }

  public toString(): string {
    return this.format();
  }

  public static normalize(value: unknown): ZipCode | undefined {
    let zip: ZipCode | undefined;

    if (value) {
      if (value instanceof ZipCode) {
        zip = value;
      } else {
        const strValue = Str.normalize(value);

        if (strValue) {
          const canonical = strValue.replace(/[\s-]/g, '');

          // eslint-disable-next-line unicorn/no-unsafe-regex
          if (/^\d{5}(\d{4})?$/.test(canonical)) {
            const zip5 = canonical.slice(0, 5);

            const plus4 = canonical.length === 9 ? canonical.slice(5, 9) : undefined;

            zip = new ZipCode({
              zip5,
              plus4
            });
          }
        }
      }
    }

    return zip;
  }
}

export namespace ZipCode {
  export type Zip5 = string;
  export type Plus4 = string;

  export interface Constructor {
    readonly zip5: Zip5;
    readonly plus4?: Plus4 | undefined;
  }

  export interface Format {
    readonly separator?: string | undefined;
    readonly withPlus4?: boolean | undefined;
  }

  export type Like = Zip5 | ZipCode;
}
