import { Form } from '@livecontrol/core-ui';
import { ImageUploader } from '@livecontrol/scheduler/components';
import React, { useCallback } from 'react';

export const CustomBackground = (): React.ReactElement => {
  const field = Form.Utils.useField('backgroundURL');
  const upload = ImageUploader.Helper.useUploadFile();

  const onBlob = useCallback(
    async ({ blob, type, file }: { blob: Blob; type: string; file: File }) => {
      try {
        field?.helpers.setValue('loading', false);

        const url = await upload({ blob, name: file.name, type, path: 'event-background' });

        field?.helpers.setValue(url);
      } catch {
        field?.helpers.setValue(undefined);
      }
    },
    [field?.helpers, upload]
  );

  return (
    <Form.Descriptor
      className='mb-16px'
      descriptionClassName='text-secondary'
      title={<span className='font-weight-normal'>Custom Thumbnail (Optional)</span>}
      description='This image will be the background for this particular event in the schedule and archive.'
    >
      <ImageUploader.Uploader
        style={{ maxWidth: 350 }}
        src={field?.meta.value as string}
        aspectRatio={16 / 9}
        aspectRatioLabel='16:9'
        onChange={onBlob}
        onRemove={(): void => {
          field?.helpers.setValue('');
        }}
      />
      {field?.meta.error && <p className='text-danger font-size-12px'>{field.meta.error}</p>}
    </Form.Descriptor>
  );
};
