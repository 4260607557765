import validator from 'validator';

import { EventValidator } from './types';

export const validateCuesheetURL = (
  cueSheetURL?: string
): Pick<EventValidator.Errors, 'cueSheetURL'> => {
  const errors: EventValidator.Errors = {};

  if (!cueSheetURL || cueSheetURL.length === 0) {
    return errors;
  }

  if (!validator.isURL(encodeURI(cueSheetURL))) {
    errors.cueSheetURL = EventValidator.ErrorMessages.INVALID_CUE_SHEET_URL;
  }

  return errors;
};
