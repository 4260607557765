import { Device, Theme } from '@livecontrol/core-ui';
import type { Account } from '@livecontrol/scheduler/model';
import cx from 'classnames';
import { DateTime } from 'luxon';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Panel } from '../../../components';
import { Detail } from '../components';
import type { SubscriptionAccountInfo } from './types';

const Container = styled.div`
  display: flex;
  padding: 0px;
  justify-content: space-between;

  a {
    font-size: 13px;
  }

  .renewal-details {
    min-width: 184px;
  }
  .contract-details {
    min-width: 170px;
  }
  .credit-details {
    min-width: 140px;
  }
  .additional-details {
    min-width: 170px;
  }
  .payment-details {
    min-width: 140px;
  }

  @media (max-width: 1349px) {
    justify-content: flex-start;

    .contract-details {
      min-width: 184px;
    }
    .credit-details {
      min-width: 184px;
    }
    .additional-details {
      min-width: 184px;
    }
    .payment-details {
      min-width: 184px;
    }
  }
`;

const Button = styled.a`
  max-height: 36px;
`;

export const Information = ({
  plan,
  account,
  billing
}: {
  plan?: SubscriptionAccountInfo.Plan;
  account: Account;
  billing: Account.Billing | undefined;
}): ReactElement => {
  const viewport = Theme.useViewport();

  const requestRenewalInfoLink =
    'https://mylivecontrol.zendesk.com/hc/en-us/requests/new?ticket_form_id=12641114845207';

  return (
    <Panel
      className={cx(
        'd-flex row-gap-16px',
        'custom-panel',
        Device.isMobile(viewport) || Device.isTablet(viewport)
          ? 'flex-column align-items-center'
          : 'flex-row'
      )}
    >
      <Container className='col-lg-9 col-xl-10 flex-lg-wrap flex-column flex-lg-row row-gap-16px'>
        <Detail className='col-lg-2 renewal-details' title='Renewal Date'>
          <div className='mt-8px'>
            <div>
              <div className='text-dark'>
                {plan?.renewal_date
                  ? DateTime.fromISO(plan.renewal_date)
                      .setZone(account.timezone)
                      .toLocaleString(DateTime.DATE_FULL)
                  : '--'}
              </div>
            </div>
            {plan?.renewal_date ? (
              <div className='mt-8px font-weight-bold d-flex flex-row align-items-start'>
                <a href={requestRenewalInfoLink} target='_blank' rel='noreferrer'>
                  Request Renewal Info{' '}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='9'
                    height='9'
                    fill='currentColor'
                    className='bi bi-box-arrow-up-right'
                    viewBox='0 0 16 16'
                  >
                    <path
                      fillRule='evenodd'
                      d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z'
                    />
                    <path
                      fillRule='evenodd'
                      d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z'
                    />
                  </svg>
                </a>
              </div>
            ) : null}
          </div>
        </Detail>

        {plan ? (
          <Detail className='col-lg-2 contract-details' title='View Signed Contract'>
            <div className='mt-8px'>
              {/*
               * <a
               *   className='text-primary'
               *   href={plan.contractFile}
               *   target='_blank'
               *   rel='noreferrer noopener'
               * >
               *   Document
               * </a>
               */}
              <div>Coming Soon</div>
            </div>
          </Detail>
        ) : null}

        <Detail className='col-lg-2 credit-details' title='Credit Length'>
          <div className='mt-8px'>
            <div className='text-dark'>
              {billing ? `${billing.credits.first_credit_time_limit} minutes/credit` : '-----'}
            </div>
          </div>
        </Detail>

        <Detail className='col-lg-2 additional-details' title='Additional Credit Price'>
          <div className='mt-8px'>
            <div className='text-dark'>{billing ? `$${billing.credits.price}/credit` : '--'}</div>
          </div>
        </Detail>

        <Detail className='col-lg-2 payment-details' title='Current Annual Plan'>
          <div className='mt-8px'>
            <div className='text-dark text-capitalize'>
              {billing
                ? `${billing.credits.recurrence_amount} Credits/${billing.credits.recurrence.toLowerCase()}`
                : '--'}
            </div>
          </div>
        </Detail>

        {!plan && (
          <Detail className='col-lg-12' title=''>
            <div className='mt-8px'>
              <div className='text- font-size-13px'>
                There is no subscription plan information for your account
              </div>
            </div>
          </Detail>
        )}
      </Container>

      <div className='col-lg-3 col-xl-2' style={{ paddingRight: '0px' }}>
        <Button
          href='https://mylivecontrol.zendesk.com/hc/en-us/articles/20653040755351'
          type='button'
          className='btn btn-primary'
          style={{
            marginLeft: Device.isMobile(viewport) || Device.isTablet(viewport) ? '0' : '20'
          }}
          target='_blank '
          rel='noopener noreferrer'
        >
          <span role='img' aria-label='roket' className='mr-8px'>
            🚀
          </span>
          Latest Features
        </Button>
      </div>
    </Panel>
  );
};
