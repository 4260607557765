/* eslint-disable @typescript-eslint/no-use-before-define */
import { Loading } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import type { ReactElement } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Store } from '../../store';
import { useFacebookAuth, useYouTubeAuth } from './auth';

// STEP1
export function CreateIntegration(): ReactElement | null {
  const me = Store.User.useMe();
  const { pathname: pageLocation } = useLocation();

  if (!me.permissions.destinations) {
    return <Redirect to='/schedule' />;
  }

  const authorizationCode = new URLSearchParams(window.location.href.split('callback')[1]).get(
    'code'
  );

  const facebookCode = pageLocation === '/facebook/callback' ? authorizationCode : null;
  const youtubeCode = pageLocation === '/youtube/callback' ? authorizationCode : null;

  if (facebookCode || youtubeCode) {
    return <AuthenticateFacebookYouTube facebookCode={facebookCode} youtubeCode={youtubeCode} />;
  }

  return null;
}

// STEP2
export function AuthenticateFacebookYouTube({
  facebookCode,
  youtubeCode
}: {
  facebookCode: string | null;
  youtubeCode: string | null;
}): ReactElement {
  const { error: facebookError } = useFacebookAuth(facebookCode);
  const { error: youtubeError } = useYouTubeAuth(youtubeCode);

  const history = useHistory();

  const modalPrompt = Modal.usePrompt();

  if (facebookError || youtubeError) {
    modalPrompt.error({
      title: 'Connection Failed',
      content: `Oops! Something went wrong and there was an issue in adding your ${
        facebookError ? 'Facebook' : 'YouTube'
      } account. Please try again.`,
      onClose() {
        history.push('/destinations');
      }
    });
  }

  return <Loading />;
}
