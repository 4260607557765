import { gql, useMutation } from '@apollo/client';
import { Num, Obj } from '@livecontrol/core-utils';
import type { Account, Location, User } from '@livecontrol/scheduler/model';
import { useCallback, useState } from 'react';
import type { MutationResult } from '../../graphql';
import { Errors } from '../../graphql';
import { QUERY } from '../use-account';

interface TVariables {
  subUserId: number;
  locations: LocationPermissions[];
}

interface LocationPermissions {
  activity_log?: boolean;
  content_managment: boolean;
  event_managment: boolean;
  location_id: string;
  view_and_download: boolean;
  customize_production_notes: boolean;
}

interface TData {
  substituteSubUserRestrictedLocations?: {
    success: boolean;
    result?: {
      id: string;
      view_and_download: boolean;
      event_managment: boolean;
      content_managment: boolean;
      activity_log: boolean;
      location: Location;
      customize_production_notes: boolean;
    }[];
    error?: {
      code: string;
      message: string;
    };
  };
}

const MUTATION = gql`
  mutation SubstituteSubUserRestrictedLocations(
    $locations: [CreateSubUserRestrictedLocations!]!
    $subUserId: Float!
  ) {
    substituteSubUserRestrictedLocationsV3(locations: $locations, subUserId: $subUserId) {
      result {
        id
      }
      error {
        message
        code
      }
      success
    }
  }
`;

interface Args {
  subUserId: number;
  subUserRestrictedLocations: LocationPermissions[];
}

export const useCreateSubUserLocations = (
  account: Account
): [
  (args: Args) => Promise<User.LocationPermissions[] | undefined>,
  MutationResult<User.LocationPermissions[], 'locations'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const createdLocations: User.LocationPermissions[] = [];

  return [
    useCallback(
      async (args: Args): Promise<User.LocationPermissions[] | undefined> => {
        try {
          const variables = {
            subUserId: Num.normalize(args.subUserId),
            locations: args.subUserRestrictedLocations
          };

          if (!Obj.isHydrated(variables)) {
            throw Errors.badRequest();
          }

          const { data } = await mutation({
            variables,
            refetchQueries: [
              {
                query: QUERY,
                variables: {
                  id: account.id
                }
              }
            ]
          });

          if (data?.substituteSubUserRestrictedLocations?.result) {
            data.substituteSubUserRestrictedLocations.result.forEach((createdLocation) => {
              const dataResult: User.LocationPermissions = {
                id: createdLocation.id,
                location: createdLocation.location,
                eventManagment: createdLocation.event_managment,
                productionNotes: createdLocation.customize_production_notes,
                viewAndDownload: createdLocation.view_and_download,
                contentManagment: createdLocation.content_managment,
                activityLog: createdLocation.activity_log
              };

              createdLocations.push(dataResult);
            });
          }
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        return createdLocations;
      },
      [account.id, createdLocations, mutation]
    ),
    {
      locations: createdLocations,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
