import { gql, useMutation } from '@apollo/client';
import { Bool, Num, Str } from '@livecontrol/core-utils';
import { Account } from '@livecontrol/scheduler/model';
import { assert } from '@sindresorhus/is';
import { useCallback } from 'react';
import type { MutationResult } from '../graphql';

interface TVariables {
  userId: number;
  quantity: number;
}

interface TData {
  payOneTimeCreditInvoice?: {
    id?: string;
    paid: boolean;
    lines: {
      data: { quantity: number }[];
    };
  };
}

const MUTATION = gql`
  mutation PayOneTimeCreditInvoice($userId: Float!, $quantity: Float!) {
    payOneTimeCreditInvoice(userId: $userId, quantity: $quantity) {
      id
      paid
      lines {
        data {
          quantity
        }
      }
    }
  }
`;

export const usePurchase = (): [
  (account: Account.Like, amount: number) => Promise<Account.Purchase>,
  MutationResult
] => {
  const [fn, result] = useMutation<TData, TVariables>(MUTATION);

  return [
    useCallback(
      async (account: Account.Like, amount: number): Promise<Account.Purchase> => {
        const canonicalId = Account.toId(account);
        const normalizeAmount = Num.normalize(amount, { positive: true });

        assert.number(canonicalId);
        assert.number(normalizeAmount);

        const mutation = await fn({
          variables: {
            userId: canonicalId,
            quantity: normalizeAmount
          }
        });

        const userPurchase: Account.Purchase = {
          paid: Bool.normalize(mutation.data?.payOneTimeCreditInvoice?.paid),
          amount:
            Num.normalize(mutation.data?.payOneTimeCreditInvoice?.lines.data[0].quantity, {
              positive: true
            }) ?? 0
        };

        if (mutation.data?.payOneTimeCreditInvoice?.id) {
          userPurchase.stripeInvoiceId = Str.normalize(mutation.data.payOneTimeCreditInvoice.id);
        }

        assert.plainObject(userPurchase);

        return userPurchase;
      },
      [fn]
    ),
    result
  ];
};
