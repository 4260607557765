import React from 'react';
import { Form } from '../../../../components';

export const Description = (): React.ReactElement => (
  <Form.Descriptor
    title='Event Description'
    titleClassName='title mr-lg-3 font-size-15px font-weight-bold text-dark'
    description='Tell viewers about your event. This displays publicly on your live stream.'
    descriptionClassName='font-size-13px text-secondary font-weight-bold text-gray-light line-height-18px'
    className='form-section-lateral d-lg-flex'
  >
    <Form.Group
      name='description'
      label={false}
      className='flex-grow-1'
      controlProps={{
        as: 'textarea',
        rows: 3,
        placeholder: 'Tell viewers about your event'
      }}
    />
  </Form.Descriptor>
);
