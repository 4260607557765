import type { ApolloError, FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Obj, Str } from '@livecontrol/core-utils';
import { User } from '@livecontrol/scheduler/model';
import { Errors } from '@livecontrol/scheduler/store';
import type { MutationResult } from '@livecontrol/scheduler/store';
import { useCallback, useState } from 'react';
import { Token } from '../../model';

interface TVariables {
  email: string;
  password: string;
}

interface TData {
  authenticate?: {
    token: Token.JWT;
    zendesk_token: Token.JWT;
    user: {
      role: {
        id: unknown;
      };
      status: {
        id: unknown;
      };
    };
  };
}

type Args = TVariables;

const MUTATION = gql`
  mutation Authenticate($email: String!, $password: String!) {
    authenticate(input: { email: $email, password: $password }) {
      token
      zendesk_token
      user {
        role {
          id
        }
        status {
          id
        }
      }
    }
  }
`;

export const useAuthenticate = (): [
  (args: Args) => Promise<Token | undefined>,
  MutationResult<Token, 'token'>
] => {
  const [mutation, result] = useMutation<TData, TVariables>(MUTATION);

  const [error, setError] = useState<Error | undefined>();
  const [token, setToken] = useState<Token | undefined>();

  return [
    useCallback(
      async (args: Args): Promise<Token | undefined> => {
        let token_: Token | undefined;

        try {
          // Parse the input arguments
          const variables = {
            email: Str.normalize(args.email),
            password: Str.normalize(args.password)
          };

          // Validate the input
          if (!Obj.isHydrated(variables)) {
            throw Errors.badRequest();
          }

          // Execute the GraphQL mutation
          const response = await mutation({ variables })
            .then(({ data }: FetchResult<TData>) => data?.authenticate)
            .catch(({ networkError }: ApolloError) => {
              // Was this an unrecoverable Network error?
              if (networkError) {
                throw Errors.serverError();
              }

              // Make an assumption as to why we got a GraphQL error.
              throw new Error('Either your email address or password is incorrect.');
            });

          // Parse the server response
          const role = User.Role.normalize(response?.user.role.id);
          const status = User.Status.normalize(response?.user.status.id);
          const zendesk_token = Str.normalize(response?.zendesk_token);

          const candidate = Token.decode(response?.token);

          if (!role || !status || !candidate) {
            throw Errors.serverError();
          }

          if (role !== User.Role.Client && role !== User.Role.SubUser) {
            throw new Error('Only clients can login to this application.');
          } else if (status !== User.Status.Active) {
            throw new Error('Your account is not active.');
          }

          // This token is good
          token_ = candidate;
          token_.zendesk_token = zendesk_token;
        } catch (error_: unknown) {
          setError(<Error>error_);
        }

        setToken(token_);

        return token_;
      },
      [mutation, setError, setToken]
    ),
    {
      token,
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
