import { gql, useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import type { MutationResult } from '../../graphql';

interface TData {
  input: locationPermision[];
}

interface locationPermision {
  locationId: string;
  permissions: PerissionsData[];
}

interface PerissionsData {
  content_managment: boolean;
  event_managment: boolean;
  view_and_download: boolean;
  customize_production_notes: boolean;
  user_id: number;
  id?: number;
}

const MUTATION = gql`
  mutation SubstituteSubUserRestrictedLocationsForManyUSers(
    $input: [CreateSubUserRestrictedLocationsForManyUsers!]!
  ) {
    substituteSubUserRestrictedLocationsForManyUSers(input: $input) {
      location_id
      permissions {
        activity_log
        content_managment
        event_managment
        view_and_download
        customize_production_notes
        id
      }
    }
  }
`;

interface Args {
  location_id: string;
  permissions: PerissionsData[];
}

export const useUpdateForManySubUserLocations = (): [
  (args: Args[]) => Promise<void>,
  MutationResult<TData, 'locations'>
] => {
  const [mutation, result] = useMutation<TData>(MUTATION);

  const [error, setError] = useState<Error | undefined>();

  return [
    useCallback(
      async (args: Args[]): Promise<void> => {
        try {
          await mutation({
            variables: { input: args }
          });
        } catch (error_: unknown) {
          setError(<Error>error_);
        }
      },
      [mutation]
    ),
    {
      error,
      called: result.called,
      loading: result.loading
    }
  ];
};
