// Review whether the file should be maintained

import { gql, useQuery } from '@apollo/client';
import { Exception } from '@livecontrol/core-ui';
import { Arr, Num, Obj, Str } from '@livecontrol/core-utils';
import { Event } from '@livecontrol/scheduler/model';
import { useMemo } from 'react';
import type { QueryResult } from '../graphql';

interface TVariables {
  eventID: Event.Like;
}

interface TData {
  getArchiveFeedbackFromAirtable?: {
    feedbackID?: string;
    fields?: {
      satisfaction: number;
      issues: string[];
      other: string;
    };
  };
}

interface TResult {
  feedbackId?: string;
  satisfaction?: number;
  issues?: string[];
  other?: string;
}

const QUERY = gql`
  query getEventFeedback($eventID: Float!) {
    getArchiveFeedbackFromAirtable(eventID: $eventID) {
      id
      feedbackID
      fields {
        eventID
        clientID
        assetID
        satisfaction
        issues
        other
      }
    }
  }
`;

export const useFeedback = (event: Event.Like): QueryResult<TResult, 'feedback'> => {
  // Parse the input arguments
  const variables = {
    eventID: Event.toId(event)
  };

  // Validate the input
  if (!Obj.isHydrated(variables)) {
    return { loading: false, error: Exception.KABOOM };
  }

  /* eslint-disable react-hooks/rules-of-hooks */
  const { loading, error, data } = useQuery<TData, TVariables>(QUERY, {
    variables,
    errorPolicy: 'ignore'
  });

  const feedback = useMemo((): TResult | undefined => {
    const { issues, other, satisfaction } = data?.getArchiveFeedbackFromAirtable?.fields ?? {};

    const obj = {
      issues: [...Arr.normalize(issues)],
      satisfaction: Num.normalize(satisfaction),
      other: Str.normalize(other),
      feedbackId: Str.normalize(data?.getArchiveFeedbackFromAirtable?.feedbackID)
    };

    return obj;
  }, [data]);

  return { loading, error, feedback };
};
