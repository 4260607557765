import { Enum } from '@livecontrol/core-utils';

export interface Sorting<K extends string> {
  key: K;
  direction: Sorting.Direction;
}

export namespace Sorting {
  export enum Direction {
    ASCENDING = 'asc',
    DESCENDING = 'desc'
  }

  export namespace Direction {
    export const normalize = (value: unknown): Direction | undefined =>
      Enum.normalize(Direction, value);

    export const flip = (value: Direction): Direction =>
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-qualifier
      value === Direction.ASCENDING ? Direction.DESCENDING : Direction.ASCENDING;
  }
}
