import { UUID } from '@livecontrol/core-utils';
import _ from 'lodash';
import React, { useMemo, useRef } from 'react';
import type { ElementType, HTMLAttributes, ReactElement } from 'react';
import type { FormGroupProps } from 'react-bootstrap/FormGroup';
import FormGroup from 'react-bootstrap/FormGroup';
import type { FormLabelProps } from 'react-bootstrap/FormLabel';
import FormLabel from 'react-bootstrap/FormLabel';
import { isElement, isValidElementType } from 'react-is';
import { Control } from './control';
import { Feedback } from './feedback';
import { Utils } from './utils';

export const Group = React.forwardRef(
  (props: Group.Props, ref: React.Ref<unknown>): React.ReactElement => {
    const { name, control, controlProps, children, error, errorProps, label, labelProps, wrapperClass, rightIconClass, ...rest } =
      props;

    const uuid = useRef(UUID.prefix(UUID.make())).current;

    // Compute the label
    const theLabel = useMemo(
      (): ReactElement | null =>
        label === false ? null : isElement(label) ? (
          label
        ) : (
          <FormLabel {...labelProps}>{label ?? _.upperFirst(name)}</FormLabel>
        ),
      [name, label, labelProps]
    );

    // Compute the control
    const WrappedControl = useRef<React.ElementType | null>(null);

    const theControl = useMemo((): ReactElement | null => {
      let el: ReactElement | null = null;

      if (isElement(control)) {
        el = control;
      } else {
        const C = isValidElementType(control) ? control : Control;

        if (!WrappedControl.current) {
          WrappedControl.current = Utils.injectFormik(C);
        }

        el = React.createElement(WrappedControl.current, { name, ...controlProps }, children);
      }

      return el;
    }, [name, control, controlProps, children]);

    // Compute the error
    const theError = useMemo(
      (): ReactElement | null =>
        error === false ? null : isElement(error) ? (
          error
        ) : (
          <Feedback name={name} {...errorProps} />
        ),
      [name, error, errorProps]
    );

    const groupProps = _.defaults(rest, {
      controlId: props.controlId ?? `${name}-${uuid}`
    });

    const hasWrapper = wrapperClass?.length;

    if (hasWrapper) {
      return (
        <FormGroup ref={ref} {...groupProps}>
          {theLabel}
          <div className={wrapperClass}>
            {theControl}
            {
              rightIconClass && (
                <div className="icon-section">
                  <div className={rightIconClass} />
                </div>
              )
            }
          </div>
          {theError}
        </FormGroup>
      );
    }

    return (
      <FormGroup ref={ref} {...groupProps}>
        {theLabel}
        {theControl}
        {theError}
      </FormGroup>
    );
  }
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Group {
  export type Props = FormGroupProps &
    HTMLAttributes<HTMLDivElement> & {
      name: string;

      label?: ReactElement | string | false;
      labelProps?: FormLabelProps;

      control?: ElementType | ReactElement | false;
      controlProps?: Utils.InjectProps;
      controlId?: string;

      error?: ReactElement | string | false;
      errorProps?: Feedback.Props;

      wrapperClass?: string;
      rightIconClass?: string;
    };
}
