import cx from 'classnames';
import React from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Panel as Panel_ } from '../panel';

const Container = styled(Panel_)`
  max-width: calc(var(--content-width) / 2);
`;

export const Panel = ({ className, children, title, ...rest }: Panel.Props): ReactElement => (
  <Container className={cx('mx-auto', 'px-24px', 'py-32px', className)} {...rest}>
    {title && <h1 className='text-center pb-8px'>{title}</h1>}
    {children}
  </Container>
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Panel {
  export type Props = React.PropsWithChildren<{
    className?: string;
    title?: string;
  }>;
}
