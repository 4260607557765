import type { Asset } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import type { FormikConfig } from 'formik';
import * as Yup from 'yup';
import { Alert, Store } from '../../../../store';

export interface Props {
  asset: Asset;
  onClose: () => void;
}

interface Values {
  enablePassword: boolean;
  password?: string;
}

interface Form {
  formik: FormikConfig<Values>;
  loading: boolean;
  alert: Alert;
}

const validationSchema = Yup.object({
  enablePassword: Yup.boolean(),
  password: Yup.string().when('enablePassword', {
    is: true,
    then: Yup.string().trim().required('Please specify a password for this event.')
  })
});

export const useForm = ({ asset, onClose }: Props): Form => {
  const account = Store.Account.useAccount();

  const [mutation, { loading, error }] = Scheduler.Asset.useEdit();

  const alert = Alert.useAlert(error);

  const initialValues = {
    enablePassword: !!asset.eventPassword,
    password: asset.eventPassword
  };

  const formik = {
    initialValues,
    validationSchema,
    validateOnMount: true,

    async onSubmit({ enablePassword, password }: Values): Promise<void> {
      alert.hide();

      // If not loading, execute the mutation
      if (
        !loading &&
        (await mutation({
          asset,
          account,
          changes: {
            eventPassword: enablePassword ? password : undefined
          }
        }))
      ) {
        onClose();
      }
    }
  };

  return {
    formik,
    loading,
    alert
  };
};
