/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Device, Theme, Tooltip } from '@livecontrol/core-ui';
import { Matrix } from '@livecontrol/scheduler/components';
import type { Location } from '@livecontrol/scheduler/model';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { HeaderLocation } from './header-location';

import G = Matrix.Grid;
import H = G.Header;

const TooltipContainer = styled(Tooltip)`
  &.tooltip {
    z-index: 1 !important;
  }

  .tooltip-inner {
    max-width: 140px;
  }
`;

const CellNoBorder = styled(Matrix.Grid.Row.Cell)`
  border: 0px solid transparent;
  justify-content: center;
  padding: 14px 0px;

  &.transparent {
    background-color: transparent !important;
  }

  &.boder-bottom {
    border-bottom: 1px solid #e4e8f0 !important;
  }

  &:last-child {
    border-right: 0px solid transparent !important;
    padding: 5px 0px !important;
  }

  &:first-child {
    border-left: 0px solid transparent !important;
    position: sticky;
    left: 0;
  }
`;

interface Props {
  locations?: Location[];
  errors: number[];
  errorsMessages: string[];
  roleContact: number;
}

export const Header = ({ roleContact, locations, errorsMessages, errors }: Props): ReactElement => {
  const viewport = Theme.useViewport();

  return (
    <>
      <G.Row className='position-sticky header d-flex'>
        {/* Header */}
        <H
          className='p-0 bg-white column font-weight-bold'
          viewport={viewport}
          style={{
            zIndex: 5,
            minWidth: Device.isMobile(viewport) ? '191px' : '280px',
            minHeight: 100
          }}
        />
        {roleContact === 1 || roleContact === 3 ? (
          <H
            style={{ minWidth: 141, minHeight: 100 }}
            className='column text-center text-dark font-weight-bold'
            viewport={viewport}
          >
            {errors[0] === 2 ? (
              <TooltipContainer
                show
                backgroundColor='#FF505F'
                tip={
                  <div>
                    <div className='font-weight-bold font-size-11px'>
                      You have to choose 1 contact
                    </div>
                  </div>
                }
              >
                <div>Account</div>
              </TooltipContainer>
            ) : (
              <div>Account</div>
            )}
            <div className='font-size-10px text-gray-light font-weight-normal'>
              Permissions only available globally
            </div>
          </H>
        ) : (
          locations?.map((location, index) => (
            <HeaderLocation
              key={`${location.id}-${Math.floor(Math.random() * 100000)}`}
              location={location}
              errors={errors}
              roleContact={roleContact}
              index={index}
              errorsMessages={errorsMessages}
            />
          ))
        )}
      </G.Row>
      <G.Row>
        {roleContact === 1 || roleContact === 3 ? (
          <CellNoBorder className='column height-10px' style={{ position: 'unset' }} />
        ) : (
          locations?.map((location) => (
            <CellNoBorder
              key={`${location.id}`}
              className='column height-10px'
              style={{ position: 'unset' }}
            />
          ))
        )}
      </G.Row>
    </>
  );
};
