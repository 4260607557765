import { ClickableCard, Device, Loading } from '@livecontrol/core-ui';
import { Modal } from '@livecontrol/scheduler/components';
import { Event, User } from '@livecontrol/scheduler/model';
import { Scheduler } from '@livecontrol/scheduler/store';
import { EventValidator } from '@livecontrol/scheduler/validator';
import cx from 'classnames';
import React, { useContext, useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Store } from '../../../store';
import { EventDetailsUtils } from '../event-details';
import type { EventContext } from '../event-store';
import { EventContextStore } from '../event-store';
import { FooterSection, HeaderSection } from '../layout-sections';

const EventTypesSection = styled.div`
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;

  .x-${Device.MOBILE} & {
    .event-types-container {
      justify-content: center;
    }
  }

  .event-types-container {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    column-gap: 30px;
    row-gap: 15px;

    & > button {
      border-radius: 5px;
      border: 1px solid #d5daea;
      outline: 1px solid transparent;
      box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.07);
    }

    & > button.active {
      border: 1px solid var(--primary);
      outline: 1px solid var(--primary);
    }

    .event-type-card {
      width: 230px;
      padding: 20px 15px;
      min-height: 265px;

      .icon-section {
        width: 100%;
        height: 122px;
        background-color: #ebefff;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon-container {
          width: 44px;
          height: 44px;
          border-radius: 5px;
          background-color: #2e5bff;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            width: 34px;
            height: 34px;
            background-size: cover;
            background-position: center;
          }
        }

        .icons-divider {
          color: #2e384d;
          font-weight: 600;
          font-size: 15px;
          margin: 0px 7px;
        }
      }

      .title-section {
        height: 32px;
        display: flex;
        margin-top: 14px;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: baseline;
        align-items: center;

        .produced-mobile-kit-title {
          width: 123px;
          text-align: start;
          line-height: 16px;
        }

        .title {
          font-size: 15px;
          color: #2e384d;
          font-weight: 600;
          margin-right: 5px;
          text-transform: capitalize;
        }

        .credits {
          font-size: 13px;
          color: #8798ad;
          font-weight: 700;
          text-align: right;
          flex-grow: 1;
        }

        .credits.hidden-credits {
          text-align: left;
          margin-top: 8px;
        }

        .credits.active {
          color: #2e5bff;
        }
      }

      .description {
        height: 85px;
        margin-top: 14px;
        text-align: start;
        line-height: 18px;
        font-size: 13px;
        color: #9c9c9c;
        font-weight: 600;
      }
    }
  }

  .video-camera {
    background-image: url(/assets/icons/Video-camera-white.svg);
  }

  .web-camera {
    background-image: url(/assets/icons/Web-camera-white.svg);
  }

  .calendar {
    background-image: url(/assets/icons/Calendar-white.svg);
  }

  .settings {
    background-image: url(/assets/icons/Settings-white.svg);
  }

  .wifi {
    background-image: url(/assets/icons/Wifi-white.svg);
  }
`;

interface Option {
  disabled?: boolean;
  value: EventValidator.ProductionType;
  icon: string;
  secondaryIcon?: string;
  label: string;
  credits?: string;
  hiddenCredits?: boolean;
  description: string;
}

export const ChooseEventType = ({
  onContinue,
  isAdmin
}: ChooseEventType.Props): React.ReactElement => {
  const account = Store.Account.useAccount();
  const { leadTime } = account;
  let { locations } = Scheduler.Location.useLocations(account);
  const { billing } = Scheduler.Account.useBilling(account);
  const { store, existingEvent }: EventContext = useContext<EventContext>(EventContextStore);
  const prompt = Modal.usePrompt();

  locations = Store.User.useLocationWithPermission(
    User.LocationPermissionsNames.EventManagment,
    locations
  );

  const insufficientCredits =
    (billing?.credits.total ?? 0) <= 0 && existingEvent?.production !== Event.Production.Produced;

  const insideLeadTime =
    !isAdmin &&
    existingEvent?.production !== Event.Production.Produced &&
    existingEvent?.start &&
    existingEvent.start.diffNow().as('days') < account.leadTime;

  const isProducedEnabled = useMemo(
    (): boolean => !!locations?.some((location) => !location.simulatedLive && location.isProduced),
    [locations]
  );

  const isStaticEnabled = useMemo(
    (): boolean => !!locations?.some((location) => !location.simulatedLive && location.isStatic),
    [locations]
  );

  const isMobileEnabled = useMemo(
    (): boolean => !!locations?.some((location) => !location.simulatedLive && location.isMobile),
    [locations]
  );

  const isMobileKitEnabled = useMemo(
    (): boolean => !!locations?.some((location) => !location.simulatedLive && location.isMobileKit),
    [locations]
  );

  const isSimulatedLiveEnabled = useMemo(
    (): boolean => !!locations?.some((location) => location.simulatedLive),
    [locations]
  );

  const isTestEnabled = useMemo(
    (): boolean =>
      EventDetailsUtils.getAvailableLocations(locations, EventValidator.ProductionType.TEST)
        .length > 0,
    [locations]
  );

  if (!locations) {
    return <Loading.Delay />;
  }

  const options: Option[] = [];

  if (isProducedEnabled) {
    const option: Option = {
      disabled:
        insufficientCredits ||
        insideLeadTime ||
        (existingEvent && existingEvent.production === Event.Production.Simulated_Live),
      value: EventValidator.ProductionType.PRODUCED,
      icon: 'video-camera',
      label: 'Produced',
      credits:
        existingEvent && existingEvent.production === Event.Production.Simulated_Live
          ? 'Cannot Change Event Type'
          : insufficientCredits
          ? 'Requires 1 Credit'
          : insideLeadTime
          ? 'Not Enough Lead Time'
          : '1 Credit',
      description: 'Let our highly trained camera operators fully produce your live event.'
    };

    option.hiddenCredits =
      !option.credits?.includes('1 Credit') || option.credits.includes('Requires 1 Credit');

    options.push(option);
  }

  if (isStaticEnabled) {
    options.push({
      disabled: existingEvent && existingEvent.production === Event.Production.Simulated_Live,
      value: EventValidator.ProductionType.STATIC,
      icon: 'web-camera',
      label: 'Static',
      credits:
        existingEvent && existingEvent.production === Event.Production.Simulated_Live
          ? 'Cannot Change Event Type'
          : '0 Credits',
      hiddenCredits: existingEvent && existingEvent.production === Event.Production.Simulated_Live,
      description:
        'Choose a time and your camera will turn on to record your live event. These events are not monitored.'
    });
  }

  if (isSimulatedLiveEnabled) {
    options.push({
      disabled: existingEvent && existingEvent.production !== Event.Production.Simulated_Live,
      value: EventValidator.ProductionType.SIMULATED,
      icon: 'calendar',
      label: 'Simulated Live',
      credits:
        existingEvent && existingEvent.production !== Event.Production.Simulated_Live
          ? 'Cannot Change to Simulated Live'
          : '0 Credits',
      hiddenCredits: existingEvent && existingEvent.production !== Event.Production.Simulated_Live,
      description: 'Upload a video or select from a past live stream and simulate a live event.'
    });
  }

  if (isTestEnabled) {
    options.push({
      disabled: existingEvent && existingEvent.production === Event.Production.Simulated_Live,
      value: EventValidator.ProductionType.TEST,
      icon: 'settings',
      label: 'Test',
      credits:
        existingEvent && existingEvent.production === Event.Production.Simulated_Live
          ? 'Cannot Change Event Type'
          : '0 Credits',
      hiddenCredits: existingEvent && existingEvent.production === Event.Production.Simulated_Live,
      description:
        'Verify everything is working (audio, lighting, etc!) and preview your setup internally before your stream.'
    });
  }

  if (isProducedEnabled && isMobileKitEnabled) {
    options.push({
      disabled: existingEvent !== undefined,
      value: EventValidator.ProductionType.PRODUCED_AND_MOBILE_KIT,
      icon: 'video-camera',
      secondaryIcon: 'wifi',
      label: 'Produced + Mobile kit',
      credits:
        existingEvent !== undefined
          ? 'Cannot Change Event Type'
          : insufficientCredits
          ? 'Requires 1 Credit'
          : insideLeadTime
          ? 'Not Enough Lead Time'
          : '2 Credits',
      description:
        'For your event that moves to a second location, schedule together & send one link to viewers.'
    });
  }

  if (isMobileKitEnabled) {
    options.push({
      disabled: existingEvent && existingEvent.production === Event.Production.Simulated_Live,
      value: EventValidator.ProductionType.MOBILE_KIT,
      icon: 'wifi',
      label: 'Mobile Kit',
      credits:
        existingEvent && existingEvent.production === Event.Production.Simulated_Live
          ? 'Cannot Change Event Type'
          : '1 Credit',
      hiddenCredits: existingEvent && existingEvent.production === Event.Production.Simulated_Live,
      description: 'Take your live stream outdoors using our Mobile Kit.'
    });
  }

  if (isMobileEnabled) {
    options.push({
      disabled: existingEvent && existingEvent.production === Event.Production.Simulated_Live,
      value: EventValidator.ProductionType.MOBILE,
      icon: 'wifi',
      label: 'Mobile App',
      credits:
        existingEvent && existingEvent.production === Event.Production.Simulated_Live
          ? 'Cannot Change Event Type'
          : '0 Credits',
      hiddenCredits: existingEvent && existingEvent.production === Event.Production.Simulated_Live,
      description: 'Take your live stream outdoors using our Mobile iOS App.'
    });
  }

  const handleTypeSelection = (optionSelected: EventValidator.ProductionType): void => {
    if (store.eventType !== optionSelected) {
      if (optionSelected !== EventValidator.ProductionType.PRODUCED) {
        delete store.eventInfo.redirectUrlSettings;
        delete store.eventInfo.operatorNotes;

        if (optionSelected !== EventValidator.ProductionType.MOBILE_KIT) {
          delete store.eventInfo.technicalContactSettings;

          delete store.eventInfo.chatSettings?.registration;
          delete store.eventInfo.chatSettings?.requireRegistration;
          delete store.eventInfo.useIntroAndOutroSlides;

          if (store.eventInfo.chatSettings && !store.eventInfo.chatSettings.liveChat) {
            delete store.eventInfo.chatSettings.showViewers;
          }
        }
      }

      if (
        optionSelected !== EventValidator.ProductionType.PRODUCED &&
        optionSelected !== EventValidator.ProductionType.STATIC
      ) {
        delete store.eventInfo.repeatSettings;
      }

      if (optionSelected === EventValidator.ProductionType.TEST) {
        delete store.eventInfo.passwordSettings;
        delete store.eventInfo.operatorNotes;
      }

      if (optionSelected === EventValidator.ProductionType.MOBILE) {
        delete store.eventInfo.cueSheetURL;
        delete store.eventInfo.zoomDetails;
        delete store.eventInfo.receiveIsoRecordings;
      }

      if (
        existingEvent?.production === Event.Production.Produced &&
        optionSelected !== EventValidator.ProductionType.PRODUCED &&
        existingEvent.start.diffNow('hours').hours < 24
      ) {
        prompt.confirm({
          title: 'Change to a static event?',
          content: (
            <div className='text-center'>
              <p>
                Heads up — since you’re within a 24 hour window of the event and have already been
                assigned an operator, you will not receive a refund for your credits.
              </p>
              <p>
                Are you sure that you want to change your event to a static event? You will not be
                able to go back to a Produced Event.
              </p>
            </div>
          ),
          okay: 'Make Event Static',
          onAnswer: (value) => {
            if (value) {
              store.setEventType(optionSelected);
            }
          }
        });
      } else {
        store.setEventType(optionSelected);
      }
    }
  };

  return (
    <>
      <HeaderSection>
        <div className='title'>
          <div className='text'>Choose an event type </div>
          <div className='lead-time'>Lead Time: {leadTime * 24} Hours Notice</div>
        </div>
      </HeaderSection>

      <EventTypesSection>
        <div className='event-types-container'>
          {options.map((option) => (
            <ClickableCard.Button
              key={option.value}
              disabled={option.disabled}
              status={store.eventType === option.value ? ClickableCard.Status.Active : undefined}
              variant='#D5DAEA'
              className={`${store.eventType === option.value ? 'active' : ''} `}
              onClick={(): void => {
                if (!option.disabled) {
                  handleTypeSelection(option.value);
                }
              }}
            >
              <div className='event-type-card' key={option.value}>
                <div className='icon-section'>
                  <div className='icon-container'>
                    <div className={`icon ${option.icon}`} />
                  </div>
                  {option.secondaryIcon && option.secondaryIcon.length > 0 && (
                    <>
                      <div className='icons-divider'>+</div>
                      <div className='icon-container'>
                        <div className={`icon ${option.secondaryIcon}`} />
                      </div>
                    </>
                  )}
                </div>

                <div className='title-section'>
                  <div
                    className={cx(
                      'title',
                      option.label.includes('Produced + Mobile kit') && 'produced-mobile-kit-title'
                    )}
                  >
                    {option.label}
                  </div>
                  <div
                    className={cx(
                      'credits',
                      store.eventType === option.value && 'active',
                      option.hiddenCredits && 'hidden-credits'
                    )}
                  >
                    {option.credits} {option.hiddenCredits}
                  </div>
                </div>
                <div className='description'>{option.description}</div>
              </div>
            </ClickableCard.Button>
          ))}
        </div>
      </EventTypesSection>

      <FooterSection>
        <Button className='btn custom-primary-btn' disabled={!store.eventType} onClick={onContinue}>
          Next
        </Button>
        <Link className='text-secondary custom-primary-link' to='/schedule'>
          Go back
        </Link>
      </FooterSection>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace ChooseEventType {
  export interface Props {
    title?: string;
    isAdmin?: boolean;
    onContinue: () => void;
  }
}
